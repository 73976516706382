import { MessageFormatElement } from "react-intl";

export const CONNECTION_TYPES = [
  { label: "Supplier", value: "supplier" },
  { label: "Customer", value: "customer" },
  { label: "Distributor", value: "distributor" },
];

export const INVITE_CONTACT_VALUES = {
  EXISTING: "EXISTING",
  NEW: "NEW",
};

export const INVITE_CONTACT_OPTIONS = (
  messages: Record<string, string> | Record<string, MessageFormatElement[]>,
) => [
  {
    heading: messages?.connections["single"].customerPortal.inviteContact.new,
    value: INVITE_CONTACT_VALUES.NEW,
  },
  {
    heading:
      messages?.connections["single"].customerPortal.inviteContact.existing,
    value: INVITE_CONTACT_VALUES.EXISTING,
  },
];

export const CONTACT_ACCESS_STATUS = {
  NONE: "NONE",
  PENDING: "PENDING",
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
};

export const CONTACT_ACCESS_COLORS = {
  [CONTACT_ACCESS_STATUS.PENDING]: {
    bg: "bg-warning-lightest",
    color: "text-warning",
  },
  [CONTACT_ACCESS_STATUS.ACTIVE]: {
    bg: "bg-success-lightest",
    color: "text-success",
  },
  [CONTACT_ACCESS_STATUS.EXPIRED]: {
    bg: "bg-accent",
    color: "text-secondary",
  },
};

import { mapColorsFromPalette } from "@shared/utils/mapColorsFromPalette";

import { COLOR } from "../constants";

export const hexToRgb = (hex, alpha) => {
  const x = [];
  hex = hex?.replace("#", "");
  x.push(parseInt(hex?.slice(0, 2), 16));
  x.push(parseInt(hex?.slice(2, 4), 16));
  x.push(parseInt(hex?.slice(4, 6), 16));
  return "rgb(" + x?.toString() + `, ${alpha})`;
};

const PRIMARY_SECONDARY_COLOR_MAP = mapColorsFromPalette();
export const generateStatusBackgroundColor = (color) => {
  return PRIMARY_SECONDARY_COLOR_MAP[color?.toLowerCase() || ""];
};

export const hexToHSL = (hex) => {
  // Convert hex to RGB first
  let r = 0;
  let g = 0;
  let b = 0;
  if (hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`;
    g = `0x${hex[2]}${hex[2]}`;
    b = `0x${hex[3]}${hex[3]}`;
  } else if (hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`;
    g = `0x${hex[3]}${hex[4]}`;
    b = `0x${hex[5]}${hex[6]}`;
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

export const hexToHsb = (hexCode) => {
  // Remove the hash character from the hex code if present
  const hex = hexCode.replace("#", "");

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find the maximum and minimum values among R, G, B
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculate the brightness
  const brightness = max;

  // Calculate the saturation
  const saturation = max === 0 ? 0 : (max - min) / max;

  // Calculate the hue
  let hue;
  if (max === min) {
    hue = 0;
  } else if (max === r) {
    hue = (60 * ((g - b) / (max - min)) + 360) % 360;
  } else if (max === g) {
    hue = 60 * ((b - r) / (max - min)) + 120;
  } else {
    hue = 60 * ((r - g) / (max - min)) + 240;
  }

  return { h: hue, s: saturation * 100, b: brightness * 100 };
};

export const isLightColor = (color) => {
  const { l } = hexToHSL(color);
  const { s } = hexToHsb(color);

  let isLighter = false;

  if (l > 85 || s < 30) {
    isLighter = true;
  }

  return isLighter;
};

export const generateBrandingButtonStyle = (color) => ({
  backgroundColor: color,
  border: `1px solid ${COLOR.$gray_v2_100}`,
  color: COLOR.$gray_v2_100,
});

import * as React from "react";
const SvgSharedAssetsActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7.126 8.428a2.223 2.223 0 1 1-3.143 3.143 2.223 2.223 0 0 1 3.143-3.143M16.017 3.984a2.223 2.223 0 1 1-3.144 3.144 2.223 2.223 0 0 1 3.144-3.144M16.017 12.873a2.223 2.223 0 1 1-3.144 3.143 2.223 2.223 0 0 1 3.144-3.143M7.535 9.008l4.934-2.466M7.535 10.992l4.934 2.466",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgSharedAssetsActive;

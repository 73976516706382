import { useMutation } from "@apollo/client";
import AiIcon from "@shared/svg/ai.svg?react";
import MakulaLogo from "@shared/svg/makula.svg?react";
import AppHeader from "@shared/ui/AppHeader";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Breadcrumbs from "@shared/ui/Breadcrumbs";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import { Input } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import AppModal from "@shared/ui/Modal";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { Fragment, useMemo, useState } from "react";
import { useIntl as useInternationalization } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import getEnums from "$/settings/enums";
import paidFeatures from "$/settings/paid-features.json";
import { GET_GENERAL_SIGNUP_TOKEN_MUTATION } from "~/api";
import { NotFoundComponent } from "~/components/_notFoundMessage";
import SingleAiNote from "~/components/aiNotetaker/AiNote";
import useAuth from "~/components/general/_use-auth";
import { MAX_TEXT_DISPLAY_LENGTH } from "~/constants/global";
import { NoUserAssignedIcon } from "~/icons/icons";
import { useGetOwnAiNote } from "~/services/ai-note";
import { UserType } from "~/types/user";

const paidFeaturesEnum = getEnums(paidFeatures, "reference");

const AiNote = () => {
  const { user } = useAuth() as { user: UserType };
  const { messages } = useInternationalization();
  const { id } = useParams() as { id: string };

  const [getSignupLink] = useMutation(GET_GENERAL_SIGNUP_TOKEN_MUTATION);

  const [state, setState] = useState({
    email: "",
    error: null,
    isAppModalOpen: false,
  });

  const { aiNote, loading: aiNoteLoading } = useGetOwnAiNote(id, user);

  const breadCrumbs = useMemo(
    () => [
      {
        label: messages.aiNotetaker["ai-notetaker-heading"],
        link: "/app/ai/notetaker",
      },
      { label: messages.aiNotetaker["my-notes"] ?? "" },
      { label: aiNote?.title },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aiNote],
  );

  const resetState = () =>
    setState({ email: "", error: null, isAppModalOpen: false });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setState((prev) => ({ ...prev, email: newEmail }));

    if (!isValidEmail(newEmail)) {
      setState((prev) => ({
        ...prev,
        error: messages.common["get-started-modal"]["error-invalid-email"],
      }));
    } else if (isPublicEmail(newEmail)) {
      setState((prev) => ({
        ...prev,
        error: messages.common["get-started-modal"]["error-work-email"],
      }));
    } else {
      setState((prev) => ({ ...prev, error: null }));
    }
  };

  const isPublicEmail = (email: string) => {
    const publicDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
    ];
    const domain = email.split("@")[1];
    return publicDomains.includes(domain);
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    getSignupLink({
      variables: {
        input: {
          email: state.email,
          feature: paidFeaturesEnum.aiNotetaker,
          redirectUrl: `/app/ai/notetaker/${aiNote._id}`,
        },
      },
    })
      .then(() => {
        toast(
          <Toast
            message={messages["common"]["get-started-modal"]["success"]}
          />,
          {
            closeButton: false,
          },
        );
        resetState();
      })
      .catch((error) => {
        toast(
          <Toast
            message={
              error?.message ||
              messages["common"]["get-started-modal"]["failure"]
            }
            type={TOAST_TYPES.ERROR}
          />,
          {
            closeButton: false,
          },
        );
      });
  };

  if (aiNoteLoading) return null;

  if (!aiNote)
    return (
      <NotFoundComponent
        className="no-tickets-wrapper full-page h-full justify-center"
        icon={<NoUserAssignedIcon />}
        title={messages.aiNotetaker["not-found"]}
      />
    );

  return (
    <div className="w-full h-full flex flex-col">
      <AppHeader padding="py-lg mx-2xl">
        {user && aiNote.createdBy._id === user._id ? (
          <Breadcrumbs
            options={breadCrumbs}
            textLimit={MAX_TEXT_DISPLAY_LENGTH}
          />
        ) : (
          <Fragment>
            {user ? (
              // @ts-ignore
              <NavLink className="flex items-center" to="/">
                <MakulaLogo />
              </NavLink>
            ) : (
              <MakulaLogo />
            )}
            {!user && (
              <div className="flex items-center space-x-2xl">
                <Label
                  className="hidden lg:flex"
                  color="text-secondary"
                  size={LABEL_SIZES.LARGE}
                >
                  {messages.aiNotetaker["boost-your-productivity"]}
                </Label>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  text={messages.aiNotetaker["try-makula-ai-notetaker"]}
                  leadingIcon={<AiIcon className="flex-shrink-0 w-2xl h-2xl" />}
                  onClick={() =>
                    setState((prev) => ({ ...prev, isAppModalOpen: true }))
                  }
                />
              </div>
            )}
          </Fragment>
        )}
      </AppHeader>
      <SingleAiNote aiNote={aiNote} />

      {!user && (
        <AppModal
          isOpen={state.isAppModalOpen}
          handleClose={() => resetState()}
          title={messages.common["get-started-modal"]["title"]}
          hideCancelButton
          updateButtonText={messages.common["linkInsert"]["confirm"]}
          disableUpdate={!state.email.length}
          handleSubmit={handleSubmit}
          content={
            <div className="flex flex-col space-y-sm">
              <div className="flex flex-col space-y-md">
                <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                  {messages.common["get-started-modal"]["input"]["label"]}
                </BodyText>
                <Input
                  placeholder={
                    messages.common["get-started-modal"]["input"]["placeholder"]
                  }
                  value={state.email}
                  onChange={(e) => handleEmailChange(e)}
                  errorMessage={state.error}
                />
              </div>
              {!state.error && (
                <BodyText color="text-secondary" size={BODY_TEXT_SIZES.X_SMALL}>
                  {messages.common["get-started-modal"]["note"]}
                </BodyText>
              )}
            </div>
          }
        />
      )}
    </div>
  );
};

export default AiNote;

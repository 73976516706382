import Tabs, { TAB_VARIANTS } from "@shared/ui/Tabs";
import React from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { NotFoundComponent } from "../../components/_notFoundMessage";
import ContentLoading from "../../components/ContentLoading";
import { DeleteTrashImage } from "../../icons/icons";

import BoxUIElement from "~/components/boxUIElement";
import { DOCUMENTS_TABS } from "~/constants";

const PartDocuments = ({
  userFolderToken,
  part,
  allowModification = true,
  isSharedAssetsPage = false,
}) => {
  const { messages } = useIntl();
  const location = useLocation();
  const isRequestPage = location.pathname.includes("app/asset-hub/requests");
  const [isExternalDocument, setIsExternalDocument] = React.useState(
    isSharedAssetsPage || isRequestPage,
  );

  const hasNoPartDocuments =
    !part?.documentFolders?.internalId && !part?.documentFolders?.externalId;

  const showDocumentsForPart =
    part?.documentFolders?.internalId && part?.documentFolders?.externalId;

  const isCreatingBoxFolderIds = () => {
    return (
      !part?.documentFolders?.internalId || !part?.documentFolders?.externalId
    );
  };

  return (
    <>
      {isCreatingBoxFolderIds() ? (
        <ContentLoading />
      ) : (
        <>
          <div className="u-flex u-margin-b-3 template-doc__buttons">
            {!isSharedAssetsPage && !isRequestPage && (
              <Tabs
                variant={TAB_VARIANTS.SEGMENT}
                tabs={[
                  {
                    id: DOCUMENTS_TABS.INTERNAL,
                    label: messages?.inventory?.internalDocuments,
                    isActive: !isExternalDocument,
                  },
                  {
                    id: DOCUMENTS_TABS.EXTERNAL,
                    label: messages?.inventory?.externalDocuments,
                    isActive: isExternalDocument,
                  },
                ]}
                onTabChange={(tabId) => {
                  if (tabId === DOCUMENTS_TABS.EXTERNAL) {
                    setIsExternalDocument(true);
                  } else {
                    setIsExternalDocument(false);
                  }
                }}
              />
            )}
          </div>

          {showDocumentsForPart ? (
            <>
              {isExternalDocument ? (
                <BoxUIElement
                  key={part?.documentFolders?.externalId}
                  token={userFolderToken}
                  entityId={part?.documentFolders?.externalId}
                  rootFolderId={part?.documentFolders?.externalId}
                  entityName={part?.name}
                  canUpload={allowModification}
                  canRename={allowModification}
                  canDelete={allowModification}
                  canCreateNewFolder={allowModification}
                />
              ) : (
                <BoxUIElement
                  key={part?.documentFolders?.internalId}
                  token={userFolderToken}
                  entityId={part?.documentFolders?.internalId}
                  rootFolderId={part?.documentFolders?.internalId}
                  entityName={part?.name}
                  canUpload={allowModification}
                  canRename={allowModification}
                  canDelete={allowModification}
                  canCreateNewFolder={allowModification}
                />
              )}
            </>
          ) : null}

          {hasNoPartDocuments ? (
            <NotFoundComponent
              key="not-found-any-machine-doc"
              icon={<DeleteTrashImage />}
              title={
                messages?.tickets?.ticketDetails.ticketOverviewTab.noDocuments
              }
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default PartDocuments;

import Assignee from "~/components/filters/_assignee";
import DateRange from "~/components/filters/_dateRange";
import FacilityName from "~/components/filters/_facilityName";
import MachineAssigned from "~/components/filters/_machineAssigned";
import MachineName from "~/components/filters/_machineName";
import MachineQREnabled from "~/components/filters/_machineQREnabled";
import NumberFilter from "~/components/filters/_numberFilter";
import ScheduleFilter from "~/components/filters/_schedule";
import TeamFilter from "~/components/filters/_teamFilter";
import TextFilter from "~/components/filters/_textFilter";
import TicketStatus from "~/components/filters/_ticketStatus";
import TicketType from "~/components/filters/_ticketType";
import AssetType from "~/components/filters/assetType";
import SingleSelectFilter from "~/components/filters/singleSelect";
import { fieldType } from "~/constants/filters/customFieldTypes";
import {
  ASSIGNEE,
  DATE_RANGE,
  FACILITY_NAME,
  SCHEDULED,
  TEAMS,
  TICKET_STATUS,
  TICKET_TYPE,
  ASSET_ASSIGNED,
  ASSET_NAME,
  ASSET_QR_CODE_ACCESS,
  ASSET_TYPE,
  CREATED_BY,
  DUE_DATE,
  CONNECTION_NAME,
} from "~/constants/filters/filters";

export const getFilterItemsDropdown = (_filterId) => {
  switch (_filterId) {
    case TICKET_STATUS:
      return TicketStatus;
    case ASSIGNEE:
      return Assignee;
    case FACILITY_NAME:
      return FacilityName;
    case CONNECTION_NAME:
      return FacilityName;
    case TEAMS:
      return TeamFilter;
    case DATE_RANGE:
      return DateRange;
    case TICKET_TYPE:
      return TicketType;
    case SCHEDULED:
      return ScheduleFilter;
    case fieldType.date:
      return DateRange;
    case fieldType.singleSelect:
      return SingleSelectFilter;
    case fieldType.text:
      return TextFilter;
    case fieldType.number:
      return NumberFilter;
    case ASSET_ASSIGNED:
      return MachineAssigned;
    case ASSET_NAME:
      return MachineName;
    case ASSET_QR_CODE_ACCESS:
      return MachineQREnabled;
    case ASSET_TYPE:
      return AssetType;
    case CREATED_BY:
      return Assignee;
    case DUE_DATE:
      return DateRange;

    default:
      return null;
  }
};

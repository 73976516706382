import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { Checkbox } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";

type AssignItemListItemPropsType = {
  isChecked: boolean;
  name: string;
  itemId: string;
  thumbnail?: string;
};

const AssignItemListItem = ({
  isChecked,
  name,
  itemId,
  thumbnail = "",
}: AssignItemListItemPropsType) => {
  return (
    <div className="flex items-center justify-between cursor-pointer mb-lg h-16 px-md border border-solid border-primary bg-primary rounded-lg">
      <div className="flex items-center gap-lg">
        <div className="flex items-center justify-center">
          <Checkbox checked={isChecked} />
        </div>

        {thumbnail && (
          <img
            src={thumbnail}
            alt={name}
            className="w-5xl h-4xl object-cover object-center rounded-md"
          />
        )}

        <Label
          size={LABEL_SIZES.SMALL}
          className="truncate max-w-60"
          title={name}
        >
          {name}
        </Label>
      </div>

      <BodyText
        size={BODY_TEXT_SIZES.X_SMALL}
        color="text-secondary"
        className="relative truncate w-1/3"
        title={itemId}
      >
        {itemId}
      </BodyText>
    </div>
  );
};

export default AssignItemListItem;

import React, { createContext, useContext } from "react";

const MessageContext = createContext();

export const PMEMessageProvider = ({ children, messages }) => {
  const cachedMessages = React.useMemo(() => messages, []);
  return (
    <MessageContext.Provider value={cachedMessages}>
      {children}
    </MessageContext.Provider>
  );
};

export const usePMEMessages = () => useContext(MessageContext);

import {
  LIMIT_TYPES,
  PRODUCT_TYPES,
  TIER_TYPES,
} from "@shared/constants/paidFeaturesHierarchy";

import { AppConfigType } from "../types/appConfig";
import { UserType } from "../types/user";

import {
  SEARCH_RESULTS_DEFAULT_COLOR_CLASSES,
  SEARCH_RESULTS_NUMBER_DATA_ATTRIBUTE,
} from "~/components/aiAssistant/Chat/constants";

export const convertStorageBytesToReadableForm = (
  storageBytes: number,
  toFixed: number = 2,
) => {
  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;

  let quotient = storageBytes / GB;
  let quotientFloored = Math.floor(quotient);

  if (quotientFloored < 1) {
    quotient = storageBytes / MB;
    quotientFloored = Math.floor(quotient);

    return quotientFloored < 1
      ? `${(storageBytes / KB).toFixed(toFixed)} KB`
      : `${(storageBytes / MB).toFixed(toFixed)} MB`;
  } else return `${quotient.toFixed(toFixed)} GB`;
};

export const sanitizeAnswer = (answer: string) => {
  const foundSearchResults = new Set<number>();
  return {
    answer: answer.replace(/\[([0-9](,\s*[0-9])*?)\]/g, (_, match: string) =>
      match
        .split(",")
        .map((index) => {
          const resultNumber = Number(index.trim());
          foundSearchResults.add(Number(resultNumber));
          return `<span class="${SEARCH_RESULTS_DEFAULT_COLOR_CLASSES} ml-2xs cursor-pointer px-1" ${SEARCH_RESULTS_NUMBER_DATA_ATTRIBUTE}="${resultNumber}">${resultNumber}</span>`;
        })
        .join(""),
    ),
    foundSearchResults,
  };
};

export const isAIAssistantCreationAllowed = (
  appConfig: AppConfigType,
  user: UserType,
) => {
  const installedProduct = user?.oem?.installedProducts?.find(
    (product) => product.type === PRODUCT_TYPES.INDUSTRIAL_AI,
  );

  if (installedProduct?.tier === TIER_TYPES.ENTERPRISE) return true;

  const allowedStorage =
    appConfig?.plans
      ?.find(({ type }) => type === PRODUCT_TYPES.INDUSTRIAL_AI)
      ?.tiers.find(
        ({ type }) =>
          type ===
          user?.oem?.installedProducts?.find(
            ({ type }) => type === PRODUCT_TYPES.INDUSTRIAL_AI,
          )?.tier,
      )
      ?.limits.find(({ type }) => type === LIMIT_TYPES.AI_STORAGE)?.value || 0;

  if (user?.aiAssistantConfiguration?.consumedStorage >= allowedStorage)
    return false;

  return true;
};

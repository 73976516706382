// libs
import { lowerCase, upperFirst } from "lodash";
import React from "react";
import { useIntl } from "react-intl";

// assets
import DropdownFooter from "./_dropdownFooter";
import DropdownHeader from "./_dropdownHeader";
import { NoUserAssignedIcon, TickIcon } from "../../icons/icons";

// utils

// components
import Dropdown from "../_dropdown";
import { NotFoundComponent } from "../_notFoundMessage";

const SingleSelectFilter = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  activeFilters,
  selectValues,
  handleSelectDeleteAll,
}) => {
  const { messages: msg } = useIntl();
  // states
  const [searchTerm, setSearchTerm] = React.useState("");
  const [list, setList] = React.useState(filterItem.options);

  // search funcitonality
  React.useEffect(() => {
    if (searchTerm === "") {
      setList(filterItem.options);
    } else {
      const newList = filterItem?.options?.filter((i) =>
        i?.value?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
      );
      setList(newList);
    }
  }, [searchTerm]);

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
    >
      <DropdownHeader
        title={upperFirst(lowerCase(title))}
        searchTerm={searchTerm}
        onSearchTermChange={setSearchTerm}
        unselectFilter={unselectFilter}
      />
      <div className="filter-facility-name filter-items">
        {!list?.length ? (
          <NotFoundComponent
            icon={<NoUserAssignedIcon />}
            title={`${msg?.common?.filters.thereIsNo} ${title}.`}
          />
        ) : (
          list?.map((item, index) => (
            <div
              className="filter-facility-name-item-container"
              key={index}
              onClick={() => selectValues(filterItem, item?.value)}
            >
              <span
                className={`filter-facility-name-item ${
                  activeFilters[filterItem?.id || filterItem?._id]?.includes(
                    item?.value,
                  )
                    ? "active"
                    : ""
                }`}
              >
                {item?.value}
              </span>
              {activeFilters[filterItem?.id || filterItem?._id]?.includes(
                item?.value,
              ) && (
                <span className="active selected-icon-container">
                  <TickIcon />
                </span>
              )}
            </div>
          ))
        )}
      </div>
      <DropdownFooter
        deleteAll={() => handleSelectDeleteAll(filterItem, false)}
        disabled={!activeFilters[filterItem.id || filterItem?._id]?.length}
      />
    </Dropdown>
  );
};

export default SingleSelectFilter;

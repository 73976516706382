import Dropdown, { POSITIONS } from "@shared/ui/DropDown";
import Tag from "@shared/ui/Tag";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { NotFoundComponent } from "~/components/_notFoundMessage";
import DropdownFooter from "~/components/filters/_dropdownFooter";
import DropdownHeader from "~/components/filters/_dropdownHeader";
import { useAuth } from "~/components/general";
import { COLOR } from "~/constants";
import { NoUserAssignedIcon, TickIcon } from "~/icons/icons";
import { generateStatusBackgroundColor } from "~/utils/_colors";

const TicketStatus = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  activeFilters,
  selectValues,
  handleSelectDeleteAll,
  dropDownPosition = POSITIONS.LEFT,
}) => {
  const { messages: msg } = useIntl();
  const { user } = useAuth();

  // states
  const [searchTerm, setSearchTerm] = React.useState("");

  const statusList = useMemo(
    () => user?.oem?.statuses || [],
    [user?.oem?.statuses],
  );

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
      position={dropDownPosition}
    >
      <DropdownHeader
        title={title}
        searchTerm={searchTerm}
        onSearchTermChange={setSearchTerm}
        unselectFilter={unselectFilter}
      />
      <div className="filter-ticket-status filter-items mb-lg">
        {!statusList?.length ? (
          <NotFoundComponent
            icon={<NoUserAssignedIcon />}
            title={msg?.common?.filters.noTicketStatus}
          />
        ) : (
          statusList
            ?.filter((status) =>
              status.label.toLowerCase().includes(searchTerm.toLowerCase()),
            )
            ?.map((status) => {
              return (
                <StatusItem
                  key={status._id}
                  selectValues={selectValues}
                  status={status}
                  activeFilters={activeFilters}
                  filterItem={filterItem}
                />
              );
            })
        )}
      </div>
      <DropdownFooter
        deleteAll={() => handleSelectDeleteAll(filterItem, false)}
        disabled={!activeFilters[filterItem.id]?.length}
      />
    </Dropdown>
  );
};

export default TicketStatus;

const StatusItem = ({ selectValues, status, activeFilters, filterItem }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(() => true);
  };
  const handleMouseLeave = () => {
    setIsHovered(() => false);
  };

  return (
    <div
      className="filter-ticket-status-item-container"
      onClick={() => selectValues(filterItem, status._id)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tag
        label={status?.label}
        style={{
          color: isHovered ? COLOR.$white_v2 : status.color,
          backgroundColor: isHovered
            ? status?.color
            : generateStatusBackgroundColor(status?.color),
        }}
        isHoverable={false}
      />
      {activeFilters[filterItem.id].includes(status._id) && (
        <span className="active">
          <TickIcon />
        </span>
      )}
    </div>
  );
};

import Tooltip from "@shared/ui/Tooltip";
import { DotsThree, MinusCircle, Trash } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";

import { getPartThumbnail, trimText } from "../../utils";

import { PopupMenuV2 } from "~/components/_popupMenu";
import { useAuth } from "~/components/general";
import { useRoleManager } from "~/hooks/_useRoleManager";

export const PartRow = ({
  part,
  handlers,
  showEdit = true,
  onlyDelete = false,
  minusCircleClassname = "",
  wrapperClassname = "",
  imageClassname = "",
  isSharedAssetsPage = false,
}) => {
  const { messages } = useIntl();
  const [showActionMenu, setShowActionMenu] = React.useState(false);

  const handleActionClick = (e) => {
    setShowActionMenu(true);
  };

  const { user } = useAuth();

  const { isOemTechnician } = useRoleManager(user?.role);

  const thumbnailToShow = React.useMemo(() => {
    return getPartThumbnail(part);
  }, [part]);

  const clickPreventDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <div
        className={`parts-table__row u-flex u-items-center u-cursor-pointer ${wrapperClassname}`}
        onClick={(e) => {
          handlers.handleNameClick(part._id);
        }}
      >
        <div className="parts-table__col u-width-3">
          <div className="u-flex u-items-center parts-table__col--name">
            <div
              className={`parts-table-item__thumb-container u-flex-shrink-0 ${imageClassname}`}
            >
              <img className="parts-table-item__thumb" src={thumbnailToShow} />
            </div>
            <div
              className="parts-table-item__name u-overflow-hidden u-text-overflow-ellipsis u-whitespace-nowrap"
              title={part.name}
            >
              {part.name}
            </div>
          </div>
        </div>
        <div className="parts-table__col u-width-5 u-overflow-hidden u-text-overflow-ellipsis u-whitespace-nowrap">
          {trimText(part.description) || "-"}
        </div>
        <div
          className="parts-table__col u-width-3 u-overflow-hidden u-text-overflow-ellipsis u-whitespace-nowrap"
          title={part.articleNumber}
        >
          {part.articleNumber}
        </div>
        {!onlyDelete ? (
          <div className="parts-table__col u-width-1 u-flex u-relative u-text-center u-justify-center machine-part-action">
            <span
              data-tip={messages?.inventory?.unassignTooltipText}
              onClick={(e) => {
                clickPreventDefault(e);
                if (showEdit) {
                  handleActionClick();
                } else {
                  handlers?.handleDeleteClick({
                    name: part?.name,
                    id: part._id,
                  });
                }
              }}
              className="u-cursor-pointer u-flex u-items-center"
            >
              {showEdit ? (
                <DotsThree size={16} className="parts-table-item__action" />
              ) : (
                !isOemTechnician &&
                !isSharedAssetsPage && (
                  <>
                    <MinusCircle className={minusCircleClassname} size={16} />
                    <Tooltip />
                  </>
                )
              )}
            </span>

            {showActionMenu && (
              <div className="u-relative">
                <span className="parts-popup__container">
                  <PopupMenuV2
                    showEditButton={showEdit}
                    editLabel={messages?.inventory?.editPart}
                    deleteLabel={messages?.inventory?.deletePart}
                    onEditClick={(e) => {
                      clickPreventDefault(e);
                      handlers?.handleEditClick(part._id);
                      setShowActionMenu(false);
                    }}
                    onDeleteClick={(e) => {
                      clickPreventDefault(e);
                      handlers?.handleDeleteClick(part._id);
                      setShowActionMenu(false);
                    }}
                    close={() => {
                      setShowActionMenu(false);
                    }}
                  />
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className="parts-table__col u-width-1 u-flex u-relative u-text-center u-justify-center machine-part-action">
            <Trash
              size={16}
              className="u-text-color-gray-v2-60"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handlers?.handleDeleteClick(part._id);
                setShowActionMenu(false);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

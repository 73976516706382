import {
  DEFAULT_PART_IMAGE_SRC,
  LARGE_THUMBNAIL_DIMENSIONS,
  THUMBNAIL_DIMENSIONS,
} from "../constants";

export const getPartThumbnail = (part, isPreview = false) => {
  const thumbnailDimensions = isPreview
    ? LARGE_THUMBNAIL_DIMENSIONS
    : THUMBNAIL_DIMENSIONS;
  if (part?.image) {
    return `${part?.image}${thumbnailDimensions}`;
  } else {
    return DEFAULT_PART_IMAGE_SRC;
  }
};

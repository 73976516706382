import { useMutation } from "@apollo/client";

import {
  CREATE_OEM_MUTATION,
  DELETE_OEM_SUPPORT_ACCOUNT_MUTATION,
  RAISE_OEM_SUPPORT_QUERY_MUTATION,
  UPDATE_OWN_OEM_MUTATION,
} from "~/api";
import { Oem } from "~/api/_fragments";
import { updateCacheOnTeamChange } from "~/services";
import { errorsHandler } from "~/transformers/_errorsHandler";

export const useUpdateOwnOem = () => {
  const [updateOem, { loading, error }] = useMutation(UPDATE_OWN_OEM_MUTATION);

  return {
    loading,
    error,
    updateOwnOem: async (input) =>
      updateOem({
        variables: { input },
        update(cache, { data: { updateOwnOem: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Oem", id: ref._id }),
            fields: {
              getOwnOem() {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Oem.fragments.OemBasicData,
                  fragmentName: "OemBasicData",
                });

                return newRef;
              },
            },
          });
        },
      }),
  };
};

export const useDeleteOemSupportAccout = () => {
  const [remove, { loading, error }] = useMutation(
    DELETE_OEM_SUPPORT_ACCOUNT_MUTATION,
  );

  return {
    loading,
    error,
    deleteOemSupportAccount: async (id) =>
      remove({
        variables: { id },
        update(cache) {
          cache.modify({
            fields: {
              listOwnOemSupportAccounts(existingRefs = [], { readField }) {
                return [...existingRefs].filter((ref) => {
                  return readField("_id", ref) !== id;
                });
              },
            },
          });
          updateCacheOnTeamChange();
        },
      }),
  };
};

export const useRaiseOemSupportQuery = () => {
  const [raiseQuery, { loading, error }] = useMutation(
    RAISE_OEM_SUPPORT_QUERY_MUTATION,
  );

  return {
    loading,
    error,
    raiseOemSupportQuery: async (
      companySize,
      contact,
      isDelete,
      query,
      productType,
    ) =>
      raiseQuery({
        variables: { companySize, contact, isDelete, query, productType },
      }),
  };
};

export const useCreateOem = () => {
  const [mutate, { loading }] = useMutation(CREATE_OEM_MUTATION);

  return {
    isCreating: loading,
    createOem: async (input) =>
      new Promise((resolve, reject) => {
        return mutate({
          variables: { input },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

import * as React from "react";
const SvgDisconnect = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M12.2871 9.71334H14.0004",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.71322 14V12.2867",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12.9263 12.9267L11.6396 11.64",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M3.71333 6.28667H2",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M6.28646 2V3.71333",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M3.07324 3.07333L4.35991 4.36",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.42827 10.6753L7.00627 12.0973C6.1636 12.94 4.79693 12.94 3.95427 12.0973V12.0973C3.1116 11.2547 3.1116 9.88799 3.95427 9.04532L5.37627 7.62332",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.6084 5.34001L9.0304 3.91801C9.87307 3.07534 11.2397 3.07534 12.0824 3.91801V3.91801C12.9251 4.76068 12.9251 6.12734 12.0824 6.97001L10.6604 8.39201",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgDisconnect;

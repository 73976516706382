import React from "react";

import { FACILITES } from "../constants/filters/scopes";

import getEnums from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features.json";
import { useAuth } from "~/components/general";
import { customFieldTypes } from "~/constants/filters/customFieldTypes";
import {
  ASSIGNEE,
  AssigneeFilterObj,
  DATE_RANGE,
  DateRangeFilterObj,
  FACILITY_NAME,
  getFacilityFilterObj,
  TeamsFilterObj,
  SCHEDULED,
  ScheduleFilterObj,
  TEAMS,
  TICKET_STATUS,
  TICKET_TYPE,
  TicketStatusFilterObj,
  TicketTypeFilterObj,
  ASSET_ASSIGNED,
  ASSET_NAME,
  ASSET_QR_CODE_ACCESS,
  ASSET_TYPE,
  AssetAssignedFilterObj,
  AssetFilterObj,
  AssetQRAccessFilterObj,
  AssetTypeFilterObj,
  CreatedByFilterObj,
  CREATED_BY,
  DueDateFilterObj,
  DUE_DATE,
  CONNECTION_NAME,
} from "~/constants/filters/filters";
import {
  MACHINES,
  MY_WORK_ORDER,
  WORK_ORDER,
  PME,
} from "~/constants/filters/scopes";
import { useListOwnOemCustomFields } from "~/services";
import {
  useGetAppConfigRecoilValue,
  useIsGrowthLoopEnabled,
} from "~/utils/appFeatures";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

const SELECTED_FILTERS_SUFIX = "_SELECTED_FILTERS";
const ACTIVE_FILTERS_SUFIX = "_ACTIVE_FILTERS";

const getDefaultFilters = (
  scope = WORK_ORDER,
  isTeamsPaid = false,
  isGrowthLoopEnabled = false,
) => {
  switch (scope) {
    case WORK_ORDER:
    case MY_WORK_ORDER:
      return [
        TicketStatusFilterObj,
        AssigneeFilterObj,
        ScheduleFilterObj,
        TicketTypeFilterObj,
        getFacilityFilterObj(isGrowthLoopEnabled),
        AssetFilterObj,
        DateRangeFilterObj,
        ...(isTeamsPaid ? [TeamsFilterObj] : []),
      ];

    case MACHINES:
      return [
        AssetAssignedFilterObj,
        getFacilityFilterObj(isGrowthLoopEnabled),
        ...(!isGrowthLoopEnabled ? [AssetQRAccessFilterObj] : []),
        AssetTypeFilterObj,
        ...(isTeamsPaid ? [TeamsFilterObj] : []),
      ];

    case FACILITES:
      return isTeamsPaid ? [TeamsFilterObj] : [];

    case PME:
      return [
        AssetFilterObj,
        getFacilityFilterObj(isGrowthLoopEnabled),
        CreatedByFilterObj,
        DueDateFilterObj,
      ];

    default:
      return [];
  }
};

const getInitialActiveFilters = (scope = WORK_ORDER, isGrowthLoopEnabled) => {
  switch (scope) {
    case WORK_ORDER:
    case MY_WORK_ORDER:
      return {
        [TICKET_STATUS]: [],
        [ASSIGNEE]: [],
        [TICKET_TYPE]: [],
        [isGrowthLoopEnabled ? CONNECTION_NAME : FACILITY_NAME]: [],
        [ASSET_NAME]: [],
        [DATE_RANGE]: [],
        [SCHEDULED]: [],
        [TEAMS]: [],
      };

    case MACHINES:
      return {
        [ASSET_ASSIGNED]: [],
        [isGrowthLoopEnabled ? CONNECTION_NAME : FACILITY_NAME]: [],
        [ASSET_QR_CODE_ACCESS]: [],
        [ASSET_TYPE]: [],
        [TEAMS]: [],
      };

    case FACILITES:
      return {
        [ASSET_ASSIGNED]: [],
        [TEAMS]: [],
      };

    case PME:
      return {
        [ASSET_NAME]: [],
        [isGrowthLoopEnabled ? CONNECTION_NAME : FACILITY_NAME]: [],
        [CREATED_BY]: [],
        [DUE_DATE]: [],
      };

    default:
      return {};
  }
};

const getInitialToggleFilters = (scope = WORK_ORDER, isGrowthLoopEnabled) => {
  switch (scope) {
    case WORK_ORDER:
    case MY_WORK_ORDER:
      return {
        [TICKET_STATUS]: {
          open: false,
        },
        [ASSIGNEE]: {
          open: false,
        },
        [TICKET_TYPE]: {
          open: false,
        },
        ...(isGrowthLoopEnabled
          ? {
              [CONNECTION_NAME]: {
                open: false,
              },
            }
          : {
              [FACILITY_NAME]: {
                open: false,
              },
            }),
        [ASSET_NAME]: { open: false },
        [DATE_RANGE]: {
          open: false,
        },
        [SCHEDULED]: {
          open: false,
        },
        [TEAMS]: {
          open: false,
        },
      };

    case MACHINES:
      return {
        [ASSET_ASSIGNED]: { open: false },
        ...(isGrowthLoopEnabled
          ? {
              [CONNECTION_NAME]: {
                open: false,
              },
            }
          : {
              [FACILITY_NAME]: {
                open: false,
              },
            }),
        [ASSET_QR_CODE_ACCESS]: { open: false },
        [ASSET_TYPE]: { open: false },
        [TEAMS]: {
          open: false,
        },
      };

    case FACILITES:
      return {
        [ASSET_ASSIGNED]: { open: false },
        [TEAMS]: {
          open: false,
        },
      };

    case PME:
      return {
        [ASSET_NAME]: { open: false },
        ...(isGrowthLoopEnabled
          ? {
              [CONNECTION_NAME]: {
                open: false,
              },
            }
          : {
              [FACILITY_NAME]: {
                open: false,
              },
            }),
        [CREATED_BY]: { open: false },
        [DUE_DATE]: { open: false },
      };

    default:
      return {};
  }
};

const determineFilterObj = (id, isGrowthLoopEnabled) => {
  switch (id) {
    case TICKET_STATUS:
      return TicketStatusFilterObj;
    case ASSIGNEE:
      return AssigneeFilterObj;
    case SCHEDULED:
      return ScheduleFilterObj;
    case TICKET_TYPE:
      return TicketTypeFilterObj;
    case FACILITY_NAME:
      return getFacilityFilterObj(isGrowthLoopEnabled);
    case CONNECTION_NAME:
      return getFacilityFilterObj(isGrowthLoopEnabled);
    case DATE_RANGE:
      return DateRangeFilterObj;
    case TEAMS:
      return TeamsFilterObj;
    case ASSET_NAME:
      return AssetFilterObj;
    case ASSET_ASSIGNED:
      return AssetAssignedFilterObj;
    case ASSET_QR_CODE_ACCESS:
      return AssetQRAccessFilterObj;
    case ASSET_TYPE:
      return AssetTypeFilterObj;
    case CREATED_BY:
      return CreatedByFilterObj;
    case DUE_DATE:
      return DueDateFilterObj;
    default:
      return null;
  }
};

const useFilter = ({
  scope = WORK_ORDER,
  baseFilters = null,
  customFieldsFilterScope = Object.values(customFieldTypes),
}) => {
  const { user } = useAuth();
  const appConfig = useGetAppConfigRecoilValue();
  const isTeamsPaid = isPaidFeatureAvailable(
    paidFeatures.teams,
    user,
    appConfig,
  );
  const isGrowthLoopEnabled = useIsGrowthLoopEnabled();
  const selectedFilterKey = React.useMemo(
    () => scope + SELECTED_FILTERS_SUFIX,
    [],
  );
  const activeFilterKey = React.useMemo(() => scope + ACTIVE_FILTERS_SUFIX, []);

  const [selectedFilters, setSelectedFilters] = React.useState({ value: [] });
  const [unselectedFilters, setUnselectedFilters] = React.useState(() => {
    if (!baseFilters) {
      baseFilters = getDefaultFilters(scope, isTeamsPaid, isGrowthLoopEnabled);
    }
    return {
      value: baseFilters,
    };
  });
  const [activeFilters, setActiveFilters] = React.useState(() =>
    getInitialActiveFilters(scope, isGrowthLoopEnabled),
  );

  const [toggleFilters, setToggleFilters] = React.useState(() =>
    getInitialToggleFilters(scope, isGrowthLoopEnabled),
  );

  const {
    customAdditionalFields = [],
    loading: customAdditionalFieldsLoading,
  } = useListOwnOemCustomFields();

  const machinesCustomAdditionalFields = [];
  const facilitiesCustomAdditionalFields = [];
  const ticketsCustomAdditionalFields = [];
  const knowledgeBaseCustomAdditionalFields = [];

  customAdditionalFields?.forEach((field) => {
    if (field.type === customFieldTypes.ticket) {
      ticketsCustomAdditionalFields.push(field);
    }
    if (field.type === customFieldTypes.facilities) {
      facilitiesCustomAdditionalFields.push(field);
    }
    if (field.type === customFieldTypes.machines) {
      machinesCustomAdditionalFields.push(field);
    }
    if (field.type === customFieldTypes.knowledgeBase) {
      knowledgeBaseCustomAdditionalFields.push(field);
    }
  });

  const updateSelectedFilters = (customFieldFilters) => {
    const selectedFilterIds = JSON.parse(
      localStorage.getItem(selectedFilterKey) || "[]",
    );

    if (selectedFilterIds.length > 0) {
      const newSelectedFilters = [];

      for (const id of selectedFilterIds) {
        const filterObj =
          determineFilterObj(id, isGrowthLoopEnabled) ||
          customFieldFilters.find((i) => i._id === id);

        if (filterObj) {
          newSelectedFilters.push(filterObj);
        }
      }

      if (newSelectedFilters.length > 0) {
        setSelectedFilters(() => ({ value: newSelectedFilters }));
      }
    }
    return selectedFilterIds;
  };

  const updateUnselectedFilters = (
    allCustomFieldFilters,
    selectedFilterIds,
  ) => {
    let newUnselectedFilters = [
      ...(unselectedFilters?.value || []),
      ...allCustomFieldFilters,
    ];

    if (selectedFilterIds.length > 0) {
      newUnselectedFilters = newUnselectedFilters.filter(
        (filter) => !selectedFilterIds.includes(filter._id || filter.id),
      );
    }

    setUnselectedFilters({
      value: newUnselectedFilters,
    });
  };

  const updateActiveFilters = (allCustomFieldFilters) => {
    const active = {};
    const persistentActiveFilters = JSON.parse(
      localStorage.getItem(activeFilterKey) ?? "{}",
    );

    allCustomFieldFilters.forEach((e) => {
      active[e._id] = [];
    });

    const newActiveFilters = {
      ...activeFilters,
      ...active,
      ...persistentActiveFilters,
    };
    setActiveFilters(newActiveFilters);
  };

  const updateDropdownToggles = (allCustomFieldFilters) => {
    const dropdownToggle = {};

    allCustomFieldFilters.forEach((e) => {
      dropdownToggle[e._id] = { open: false };
    });

    setToggleFilters((prev) => ({ ...prev, ...dropdownToggle }));
  };

  const handleSelectFilter = (value) => {
    let filterIds = [];

    for (const item of value.value) {
      filterIds.push(item._id ?? item.id);
    }

    localStorage.setItem(selectedFilterKey, JSON.stringify(filterIds));
    setSelectedFilters(value);
  };

  const handleUnselectFilter = (value) => {
    setUnselectedFilters(value);
  };

  const handleActiveFilter = (activeFilter) => {
    const value = {};

    for (const id of Object.keys(activeFilter)) {
      if (
        activeFilter[id].length > 0 ||
        typeof activeFilter[id] === "boolean"
      ) {
        value[id] = activeFilter[id];
      }
    }

    localStorage.setItem(activeFilterKey, JSON.stringify(value));
    setActiveFilters(activeFilter);
  };

  // custom and additional fields work
  React.useEffect(() => {
    let isMount = true;

    const filteredFacilitiesCustomAdditionalFields =
      facilitiesCustomAdditionalFields?.filter(
        (field) => field?.fieldType !== "address",
      );

    if (
      (machinesCustomAdditionalFields.length > 0 ||
        filteredFacilitiesCustomAdditionalFields.length > 0 ||
        ticketsCustomAdditionalFields.length > 0) &&
      !customAdditionalFieldsLoading &&
      isMount
    ) {
      const machineCF = Array.isArray(machinesCustomAdditionalFields)
        ? machinesCustomAdditionalFields
        : [];
      const facilityCF = Array.isArray(filteredFacilitiesCustomAdditionalFields)
        ? filteredFacilitiesCustomAdditionalFields
        : [];
      const ticketCF = Array.isArray(ticketsCustomAdditionalFields)
        ? ticketsCustomAdditionalFields
        : [];
      const knowledgeBaseCF = Array.isArray(knowledgeBaseCustomAdditionalFields)
        ? knowledgeBaseCustomAdditionalFields
        : [];

      let allCustomFieldFilters = [];
      if (customFieldsFilterScope.includes(customFieldTypes.machines)) {
        allCustomFieldFilters = [...allCustomFieldFilters, ...machineCF];
      }
      if (customFieldsFilterScope.includes(customFieldTypes.facilities)) {
        allCustomFieldFilters = [...allCustomFieldFilters, ...facilityCF];
      }
      if (customFieldsFilterScope.includes(customFieldTypes.ticket)) {
        allCustomFieldFilters = [...allCustomFieldFilters, ...ticketCF];
      }
      if (customFieldsFilterScope.includes(customFieldTypes.knowledgeBase)) {
        allCustomFieldFilters = [...allCustomFieldFilters, ...knowledgeBaseCF];
      }

      const selectedFilterIds = updateSelectedFilters(allCustomFieldFilters);

      updateUnselectedFilters(allCustomFieldFilters, selectedFilterIds);
      updateActiveFilters(allCustomFieldFilters);
      updateDropdownToggles(allCustomFieldFilters);
    }

    return () => {
      isMount = false;
    };
  }, [
    machinesCustomAdditionalFields.length,
    facilitiesCustomAdditionalFields.length,
    ticketsCustomAdditionalFields.length,
    knowledgeBaseCustomAdditionalFields.length,
  ]);

  return {
    selectedFilters,
    unselectedFilters,
    activeFilters,
    toggleFilters,
    handleSelectFilter,
    handleUnselectFilter,
    handleActiveFilter,
    setToggleFilters,
    scope,
  };
};

export default useFilter;

import { POSITIONS } from "@shared/ui/DropDown";
import Tooltip from "@shared/ui/Tooltip";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";

import FilterItem from "~/components/filters/_filterItem";
import UnselectedFilters from "~/components/filters/_unselectedFilters";
import { BtnPlusIcon } from "~/icons/icons";
import { getFilterItemsDropdown } from "~/utils/filter";

export const FILTER_VARIANTS = {
  CONTAINS: "contains",
  EXACTLY_MATCHES: "exactly_matches",
};

const Filter = ({
  filterStates,
  className = "",
  maxActiveFilters = 5,
  maxFilterAlertText = "",
  registerOpenEvent = () => {},
  registerSelectEvent = () => {},
  dropDownPosition = POSITIONS.LEFT,
  showFilterTitle = true,
  filterVariant = FILTER_VARIANTS.EXACTLY_MATCHES,
}) => {
  const { messages } = useIntl();
  const {
    selectedFilters,
    handleSelectFilter,
    unselectedFilters,
    handleUnselectFilter,
    activeFilters,
    handleActiveFilter,
    toggleFilters,
    setToggleFilters,
    scope,
  } = filterStates || {};

  const [filtersDropdown, setFiltersDropdown] = React.useState(false);

  // open unselected filters dropdown
  const openDropdown = useCallback(() => {
    if ((selectedFilters?.value || [])?.length >= maxActiveFilters) return;
    registerOpenEvent();
    setFiltersDropdown(true);
  }, [selectedFilters?.value?.length]);

  // close unselected filters dropdown
  const closeDropdown = useCallback(() => {
    setFiltersDropdown(false);
  }, []);

  // select filter
  const selectFilter = (_filter) => {
    registerSelectEvent();
    const newUnselectedFilters = (unselectedFilters?.value || [])?.filter(
      (item) =>
        _filter.id ? item.id !== _filter.id : item._id !== _filter._id,
    );
    // remove filter from unselected list
    handleUnselectFilter({
      value: newUnselectedFilters,
    });
    // add filter from unselected list to selected list
    handleSelectFilter({
      value: [...(selectedFilters?.value || []), _filter],
    });

    handleFilterDropdown(_filter, true);

    // close unselected filters list
    closeDropdown();
  };

  // unselect filter
  const unselectFilter = (_filter) => {
    // change open and active status
    handleFilterDropdown(_filter, false);
    handleSelectDeleteAll(_filter, false);

    // remove filter from selected list
    const newSelectedFilters = (selectedFilters?.value || [])?.filter((item) =>
      item.id ? item.id !== _filter.id : item._id !== _filter._id,
    );
    handleSelectFilter({
      value: newSelectedFilters,
    });

    // add filter from selected list to unselected list
    handleUnselectFilter({
      value: [...(unselectedFilters?.value || []), _filter],
    });
  };

  // handle filter dropdown open and close
  const handleFilterDropdown = (_item, _openStatus) => {
    setToggleFilters((p) => ({
      ...p,
      [_item.id ?? _item._id]: {
        open: _openStatus,
      },
    }));
  };

  // handle select filter values
  const selectValues = (_filter, value, notMutliValues) => {
    if (!notMutliValues) {
      const valueExists =
        activeFilters[_filter.id ?? _filter._id]?.includes(value);
      if (!valueExists) {
        const newActiveFilters = {
          ...activeFilters,
          [_filter.id ?? _filter._id]: [
            ...activeFilters[_filter.id ?? _filter._id],
            value,
          ],
        };

        handleActiveFilter(newActiveFilters);
      } else {
        const newValues = activeFilters[_filter.id ?? _filter._id].filter(
          (i) => i !== value,
        );
        const newActiveFilters = {
          ...activeFilters,
          [_filter.id ?? _filter._id]: newValues,
        };

        handleActiveFilter(newActiveFilters);
      }
    } else {
      handleActiveFilter({
        ...activeFilters,
        [_filter.id ?? _filter._id]: value,
      });
    }
  };

  // handle select all or delete all values
  const handleSelectDeleteAll = (_filter, _select, _selectValues = []) => {
    const newActiveFilters = {
      ...activeFilters,
      [_filter._id ?? _filter.id]: _select ? _selectValues : [],
    };
    if (_select) {
      handleActiveFilter(newActiveFilters);
    } else {
      handleActiveFilter(newActiveFilters);
    }
  };

  return (
    <>
      <span className={`filters-container ${className}`}>
        <span className="filter-text">{messages.filters?.filterBy}:</span>
        <div className="filters-container-inner">
          {(selectedFilters?.value || []).map((item, index) => (
            <FilterItem
              key={index}
              item={item}
              DropdownItem={getFilterItemsDropdown(item.id ?? item.fieldType)}
              handleFilterDropdown={handleFilterDropdown}
              showDropdown={toggleFilters[item.id ?? item._id].open}
              filterActive={activeFilters[item.id ?? item._id].length > 0}
              activeFilters={activeFilters}
              scope={scope}
              unselectFilter={unselectFilter}
              selectValues={selectValues}
              handleSelectDeleteAll={handleSelectDeleteAll}
              dropDownPosition={dropDownPosition}
              showTitle={showFilterTitle}
              filterVariant={filterVariant}
            />
          ))}
          {(unselectedFilters?.value || [])?.length > 0 && (
            <span
              className={`filter-add-container ${
                dropDownPosition === POSITIONS.RIGHT ? "static" : "relative"
              }`}
            >
              <span
                className={`filter-add ${
                  (selectedFilters?.value || [])?.length > maxActiveFilters - 1
                    ? "disabled"
                    : ""
                }`}
                onClick={openDropdown}
                data-for="max-filter-tooltip"
                data-tip={
                  (selectedFilters?.value || [])?.length >= maxActiveFilters
                    ? maxFilterAlertText
                    : ""
                }
              >
                <BtnPlusIcon />
              </span>
              <UnselectedFilters
                unselectedFilters={unselectedFilters?.value || []}
                show={filtersDropdown}
                onClose={closeDropdown}
                selectFilter={selectFilter}
                dropDownPosition={dropDownPosition}
              />
            </span>
          )}
        </div>
      </span>
      <Tooltip
        id="max-filter-tooltip"
        place="bottom"
        className="after:!top-3xs after:!right-2 after:!bottom-auto"
        offset={{ bottom: 33, left: -42 }}
      />
    </>
  );
};

export default Filter;

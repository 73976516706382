import { Fragment, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Waypoint } from "react-waypoint";

import ContentLoading from "~/components/ContentLoading";
import { ITEMS_BEFORE_PAGE, ITEMS_PER_PAGE } from "~/constants/global";
import SingleMachineListItem from "~/containers/machine/SingleMachineListItem";
import { useListAllOwnOemAssets } from "~/services/asset";
import { Asset } from "~/types/asset";
import { WaypointEventType } from "~/types/waypointEvent";

const AssetRow = ({
  anyFilterActive = false,
  asset,
  handleEditClick,
  handleQRCodeClick,
  handleUnassignClick,
  isAssetQuery = false,
  isHierarchyPaid = false,
  isListView = false,
  isTeamsPaid = false,
  onDeleteClick,
  onDeleteSuccess,
  showQRCode = true,
  isSharedAssetPage = false,
}: {
  anyFilterActive?: boolean;
  asset: Asset;
  handleEditClick?: (asset: Asset) => void;
  handleQRCodeClick?: (asset: Asset) => void;
  handleUnassignClick?: () => void;
  isAssetQuery?: boolean;
  isHierarchyPaid?: boolean;
  isListView?: boolean;
  isTeamsPaid?: boolean;
  onDeleteClick?: () => void;
  onDeleteSuccess?: () => void;
  showQRCode?: boolean;
  isSharedAssetPage?: boolean;
}) => {
  const { pathname } = useLocation();
  const [expandChildren, setExpandChildren] = useState(false);
  const parentAssetId = pathname.split("/").at(-1);
  const showUnassign = useMemo(
    () =>
      isHierarchyPaid &&
      parentAssetId &&
      asset.hierarchy?.[0]?.id === parentAssetId,
    [asset.hierarchy, isHierarchyPaid, parentAssetId],
  );

  const {
    assets: subAssets,
    assetsListLoading: subAssetsLoading,
    handleFetchMore: handleFetchMoreSubAssets,
    totalCount: subAssetsTotalCount,
  } = useListAllOwnOemAssets({
    where: { directParentId: asset._id, isSharedAsset: isSharedAssetPage },
    limit: ITEMS_PER_PAGE,
    skip: 0,
    skipCondition: !expandChildren || !asset.childrenCount,
  });

  const handleScrollBottom = (event: WaypointEventType) => {
    if (
      subAssets.length >= ITEMS_PER_PAGE &&
      !subAssetsLoading &&
      event?.previousPosition !== Waypoint.above
    ) {
      handleFetchMoreSubAssets({
        limit: ITEMS_PER_PAGE,
        skip: subAssets.length,
      });
    }
  };

  return (
    <>
      <SingleMachineListItem
        expanded={expandChildren}
        // @ts-ignore
        handleEditClick={handleEditClick}
        // @ts-ignore
        handleQRCodeClick={handleQRCodeClick}
        handleUnassignClick={handleUnassignClick}
        isAssetQuery={isAssetQuery}
        isTeamsPaid={isTeamsPaid}
        machine={asset}
        onDeleteClick={onDeleteClick}
        onDeleteSuccess={onDeleteSuccess}
        showQRCode={showQRCode}
        inlineSerialNumber
        setExpanded={() => setExpandChildren((prev) => !prev)}
        showExpandIcon={!!asset.childrenCount && !anyFilterActive}
        showRemoveAndUnassignButton={showUnassign || isListView}
        showUnassign={showUnassign}
        isSharedAssetPage={isSharedAssetPage}
      />

      {!anyFilterActive &&
        !isAssetQuery &&
        !!asset.childrenCount &&
        expandChildren &&
        (subAssetsLoading ? (
          <div className="mt-sm flex justify-center">
            <ContentLoading />
          </div>
        ) : (
          <div className="mt-sm ml-lg flex">
            <div className="w-px bg-tertiary flex-shrink-0" />
            <div className="ml-lg flex flex-col w-full space-y-sm">
              {subAssets.map((subAsset: Asset, index: number) => (
                <Fragment key={`${asset._id}-${subAsset._id}`}>
                  <AssetRow
                    key={subAsset._id}
                    asset={subAsset}
                    handleEditClick={handleEditClick}
                    handleQRCodeClick={handleQRCodeClick}
                    handleUnassignClick={handleUnassignClick}
                    isAssetQuery={isAssetQuery}
                    isListView={isListView}
                    isTeamsPaid={isTeamsPaid}
                    onDeleteClick={onDeleteClick}
                    onDeleteSuccess={onDeleteSuccess}
                    isSharedAssetPage={isSharedAssetPage}
                    showQRCode={showQRCode}
                  />

                  {!subAssetsLoading &&
                    subAssetsTotalCount > subAssets.length &&
                    index === subAssets.length - ITEMS_BEFORE_PAGE && (
                      // @ts-ignore
                      <Waypoint onEnter={handleScrollBottom} />
                    )}
                </Fragment>
              ))}
            </div>
          </div>
        ))}
    </>
  );
};

export default AssetRow;

import * as React from "react";
const SvgInternalMachineHistoryNote = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 48,
  height: 48,
  viewBox: "0 0 48 48",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("circle", {
  cx: 24,
  cy: 24,
  r: 24,
  fill: "#D1D5DB"
}), /* @__PURE__ */ React.createElement("rect", {
  x: 17.3359,
  y: 16.5664,
  width: 13.3333,
  height: 15,
  rx: 3,
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M30.6771 24.0664L27.3438 25.7331",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M30.6771 27.3984L27.3438 29.0651",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M27.3438 31.5658V23.4291C27.3438 22.7977 27.7006 22.2205 28.2654 21.9383L30.6771 20.7324",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16.5 19.8991H19",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16.5 23.2311H19",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16.5 26.5651H19",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgInternalMachineHistoryNote;

import LeaveByDoorIcon from "@shared/svg/leave-by-door.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Breadcrumbs from "@shared/ui/Breadcrumbs";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import { Input, RadioButtonInput } from "@shared/ui/Inputs";
import AppModal from "@shared/ui/Modal";
import RouteLeavingGuard from "@shared/ui/RouteLeavingGuard";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { useState, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Webvis from "~/components/3d/Webvis";
import AppHeaderWithSidebarOption from "~/components/AppHeaderWithSidebarOption";
import { useAuth } from "~/components/general";
import { DEMO_3D_URL, SAVING_GUIDE_TOAST_ID } from "~/constants";
import {
  DEMO_GUIDE,
  GUIDE_SAVE_OPTIONS,
  GUIDE_SAVE_VALUES,
} from "~/constants/guides";
import { DEMO_MACHINE } from "~/constants/machine";
import { useUploadFiles } from "~/hooks";
import useUpdatableToast from "~/hooks/_useToast";
import { useCreateGuide, useUpdateGuide } from "~/services/guides";
import type { WebVisRefType } from "~/types/3d";
import { Asset } from "~/types/asset";
import type { GuideType } from "~/types/guide";
import type { UserType } from "~/types/user";
import {
  getModelScreenshot,
  getSnapshotAttachment,
  getSnapshotsLength,
  saveSession,
} from "~/utils/3d";

const Machine3DTab = ({
  machine,
  is3DPaid,
  is3DPageView,
  guide,
  isDemoLaunched = false,
  isGuide = false,
}: {
  machine: Asset;
  is3DPaid: boolean;
  is3DPageView?: boolean;
  guide?: GuideType;
  isDemoLaunched?: boolean;
  isGuide?: boolean;
}) => {
  const webvisComponent = useRef<WebVisRefType>(null);
  const { user } = useAuth() as { user: UserType };
  const history = useHistory();
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showGuideNamePage, setShowGuideNamePage] = useState(false);

  const [guideImage, setGuideImage] = useState(guide?.image);
  const [guideNameError, setGuideNameError] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const { messages } = useIntl();
  const { createGuide } = useCreateGuide();
  const { updateGuide } = useUpdateGuide();
  const [guideSaveOption, setGuideSaveOption] = useState(
    GUIDE_SAVE_VALUES.EXISTING,
  );
  const [creatingGuide, setCreatingGuide] = useState(false);
  const [guideName, setGuideName] = useState("");
  const { startToast, endToast } = useUpdatableToast();
  const fileUploader = useUploadFiles();

  const breadCrumbs = useMemo(
    () => [
      ...(isGuide
        ? [
            {
              label: messages?.menus?.["3d"]?.SUB_MENUS?.GUIDES,
              link: "/app/3d/guides",
            },
          ]
        : [
            {
              label: messages?.menus?.["3d"]?.["title"],
              link: "/app/3d/models",
            },
          ]),
      {
        label:
          (isGuide && guide ? guide?.name : machine?.name) ??
          (isGuide ? DEMO_GUIDE.name : DEMO_MACHINE.name),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [machine?.name],
  );

  return (
    <>
      <RouteLeavingGuard
        when={
          !creatingGuide &&
          isGuide &&
          !isDemoLaunched &&
          isDirty &&
          !is3DPageView
        }
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() =>
          !creatingGuide &&
          isGuide &&
          !isDemoLaunched &&
          isDirty &&
          !is3DPageView
        }
        renderBlockComponent={(
          modalVisible: boolean,
          setConfirmNavigation: (val: boolean) => void,
          setModalVisible: (val: boolean) => void,
        ) => (
          <AlertBox
            title={messages?.tickets?.["ticketQuitAlertTitle"]}
            description={messages?.machines?.["newGuideUnsavedChangesTitle"]}
            isOpen={modalVisible}
            acceptButtonText={messages?.common?.["acceptFormExitText"]}
            cancelButtonText={messages?.common?.["cancelFormExitText"]}
            image={<LeaveByDoorIcon width="130" height="130" />}
            onCancel={() => setModalVisible(false)}
            onAccept={() => setConfirmNavigation(true)}
          />
        )}
      />
      {is3DPageView && (
        <AppHeaderWithSidebarOption padding="py-lg">
          <Breadcrumbs options={breadCrumbs} />
          {isGuide && !isDemoLaunched && (
            <div className="space-x-md flex">
              <Button
                variant={BUTTON_VARIANTS.OUTLINE}
                onClick={() => history.push("/app/3d/guides")}
                className="flex-shrink-0"
                text={messages.common["cancel"]}
              />
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                onClick={async () => setShowSaveModal(true)}
                className="flex-shrink-0"
                text={messages.common["save"]}
                disabled={!isDirty}
              />
            </div>
          )}
        </AppHeaderWithSidebarOption>
      )}
      {isDemoLaunched ? (
        <Webvis
          url={DEMO_3D_URL}
          is3DModelPaid={is3DPaid}
          machine={machine}
          is3DPageView={is3DPageView}
          isDemoModel
          isGuide={isGuide}
          webvisComponent={webvisComponent}
        />
      ) : machine._3dModelUrl ? (
        <Webvis
          url={machine._3dModelUrl}
          machine={machine}
          is3DPageView={is3DPageView}
          isGuide={isGuide}
          webvisComponent={webvisComponent}
          guideSessionId={guide?.sessionId}
          onChange={() => setIsDirty(true)}
          onFirstSnapshot={async () => {
            const { file: thumbnailFile, snapshotName: snapName } =
              await getModelScreenshot(webvisComponent, guideName, machine._id);
            const uploadData = {
              folder: `oem/${user.oem.slug}/guide/${(
                guide?._id ?? machine._id
              ).replaceAll(" ", "-")}/${snapName.replace(
                /[^a-z0-9]+/gi,
                "",
              )}/image`,
              files: [thumbnailFile],
              forceCustomPath: true,
            };
            const { urls } = await fileUploader(uploadData, false);
            setGuideImage(urls?.[0] ?? "");
          }}
        />
      ) : null}
      <AppModal
        isOpen={showSaveModal}
        contentClassName="!p-0"
        disableUpdate={
          creatingGuide ||
          (!guide && !guideName) ||
          (showGuideNamePage && !guideName)
        }
        title={messages.machines?.["saveGuide"]}
        cancelButtonText={messages.common?.["cancel"]}
        updateButtonText={
          guideSaveOption === GUIDE_SAVE_VALUES.EXISTING
            ? messages.common?.["save"]
            : messages.common?.["continue"]
        }
        handleClose={() => {
          setShowSaveModal(false);
          setShowGuideNamePage(false);
        }}
        maxWidth="!max-w-[520px]"
        handleSubmit={async () => {
          if (
            guide &&
            guideSaveOption === GUIDE_SAVE_VALUES.NEW &&
            !showGuideNamePage
          ) {
            setShowGuideNamePage(true);
            return;
          } else if (
            !guide ||
            showGuideNamePage ||
            (guide && guideSaveOption === GUIDE_SAVE_VALUES.EXISTING)
          ) {
            setCreatingGuide(true);
            startToast(
              messages?.machines?.["savingGuide"],
              TOAST_TYPES.PROCESSING,
              false,
              {
                toastId: SAVING_GUIDE_TOAST_ID,
              },
            );
            const res = await saveSession(webvisComponent);
            let imageUrl = guideImage;
            const snapshotLength = await getSnapshotsLength(webvisComponent);
            if (!guideImage && snapshotLength) {
              const thumbnailFile = await getSnapshotAttachment(
                webvisComponent,
                guideName,
                machine._id,
              );
              const uploadData = {
                folder: `oem/${user.oem.slug}/guide/${
                  guide?._id ?? machine._id
                }/image`,
                files: [thumbnailFile],
                forceCustomPath: true,
              };
              const { urls } = await fileUploader(uploadData, false);
              imageUrl = urls?.[0] ?? "";
            }
            if (!guide || guideSaveOption === GUIDE_SAVE_VALUES.NEW)
              createGuide({
                variables: {
                  input: {
                    name: guideName,
                    image: snapshotLength ? imageUrl : "",
                    sessionId: res,
                    machineId: machine._id,
                  },
                },
              });
            else
              updateGuide({
                variables: {
                  input: {
                    _id: guide._id,
                    image: snapshotLength ? imageUrl : "",
                    sessionId: res,
                  },
                },
              });
            endToast();
            toast(
              <Toast message={messages?.machines?.["guideCreationSuccess"]} />,
              {
                closeButton: false,
              },
            );
            history.push("/app/3d/guides");
          }
        }}
        content={
          <div className="flex flex-col">
            <div className="p-2xl gap-y-2xl flex flex-col">
              {!guide || showGuideNamePage ? (
                <Input
                  placeholder={messages.machines?.["guideNamePlaceholder"]}
                  label={`${messages.machines?.["guideNameLabel"]} *`}
                  errorMessage={guideNameError}
                  value={guideName}
                  onChange={(e) => {
                    const nameLength = e.target.value.length;
                    if (nameLength > 75) {
                      return setGuideNameError(
                        messages?.forms?.["newMachineForm"].name.lengthError,
                      );
                    } else {
                      setGuideNameError("");
                      setGuideName(e.target.value);
                    }
                  }}
                />
              ) : (
                <>
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    color="text-secondary"
                  >
                    {messages.machines?.["guideSaveMessage"]}
                  </BodyText>
                  <RadioButtonInput
                    keyId="guideSaveOption"
                    options={GUIDE_SAVE_OPTIONS(messages)}
                    value={guideSaveOption}
                    onChange={(e) => {
                      const value = e?.target?.value;
                      setGuideSaveOption(value);
                    }}
                    vertical
                  />
                </>
              )}
            </div>
          </div>
        }
      />
    </>
  );
};

export default Machine3DTab;

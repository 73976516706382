import React, { useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import useOnClickOutside from "use-onclickoutside";

import { Select3 } from "~/components/form/_inputs";

const CustomSelectField = ({
  field,
  handleSelectFieldSave,
  isEditable = true,
  selectFieldValueClassname = "",
  selectFieldLabelClassname = "",
  dropDownPosition = "",
}) => {
  const { messages } = useIntl();
  const ref = useRef();
  const cTextField = useRef({});

  useOnClickOutside(ref, () => {
    setIsOpen(false);
    cTextField.current[field?._id] = {};
  });

  const optionList = useMemo(() => {
    return field?.options?.map((item) => {
      return {
        label: item?.value,
        value: item?.value,
        color: item?.color,
      };
    });
  }, [field?.options]);

  return (
    <>
      <div className="form-group form-group--inline custom-field-row u-padding-b-1">
        <Select3
          selectedTextTransform="uppercase"
          disabled={!isEditable}
          buttonModifier={`c-dropdown-button--uppercase ${
            field?.value ? "has-value" : ""
          }`}
          search={true}
          position={dropDownPosition}
          dropdownTitle={`${field?.label}:`}
          labelModifier={` ${selectFieldLabelClassname}`}
          customColor={true}
          name={field?.label}
          label={`${field?.label}:`}
          placeholder={messages?.settings?.companyTabs?.customizeFields?.empty}
          defaultValue={field?.value || ""}
          options={optionList}
          getValues={() => {
            return (
              field?.options?.find((item) => field?.value === item?.value)
                ?.value || ""
            );
          }}
          onChange={(val) => {
            handleSelectFieldSave(field?._id, val);
          }}
          valueClassname={selectFieldValueClassname}
        ></Select3>
      </div>
    </>
  );
};

export default CustomSelectField;

import MakulaLogo from "@shared/svg/makula-logo-dark.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import React from "react";
import { useIntl } from "react-intl";

const SplashScreen = () => {
  const { messages } = useIntl();

  return (
    <div className="w-screen h-screen space-y-2xl flex flex-col items-center justify-center">
      <MakulaLogo className="h-[104px] w-fit animate-bounce" />
      <div className="space-y-sm flex flex-col items-center">
        <Headline size={HEADLINE_SIZES.SMALL}>
          {messages?.common?.["splashScreen"].title}
        </Headline>
        <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
          {messages?.common?.["splashScreen"].subTitle}
        </BodyText>
      </div>
    </div>
  );
};

export default SplashScreen;

import { useRecoilValue } from "recoil";

import APP_FEATURES from "$/settings/app-features.json";
import getEnums from "$/settings/enums";
import { appConfigAtom } from "~/atoms/appConfig";

const AppFeatures = getEnums(APP_FEATURES, "reference");

export const useIsGrowthLoopEnabled = () => {
  const appConfig = useRecoilValue(appConfigAtom);
  return appConfig.features?.includes(AppFeatures.growthLoop);
};

export const useGetAppConfigRecoilValue = () => {
  const appConfig = useRecoilValue(appConfigAtom);
  return appConfig;
};

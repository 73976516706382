import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Tag, { TAG_COLORS } from "@shared/ui/Tag";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { getOptionBackgroundColor } from "~/utils";

const AssignedTeamsWithPreview = ({
  hidePreview = false,
  teams = [],
  showAllWIthoutPreview = false,
  tagClassName = "",
  countOfTeamsInTable = 2,
  containerClassName = "",
}) => {
  const { messages } = useIntl();
  const [showPreview, setShowPreview] = useState(false);

  const openPreview = (event) => {
    event.stopPropagation();
    setShowPreview(true);
  };

  const closePreview = (event) => {
    event.stopPropagation();
    setShowPreview(false);
  };

  return (
    <>
      <div
        className={`assigned-teams-with-preview-container u-relative ${containerClassName}`}
      >
        {!teams.length && "-"}
        {teams
          .slice(0, showAllWIthoutPreview ? teams.length : countOfTeamsInTable)
          .map((team) => (
            <Tag
              key={team._id}
              label={team.name}
              className={tagClassName}
              style={{
                color: team.teamColor,
                background: getOptionBackgroundColor(team.teamColor),
              }}
            />
          ))}
        {teams.length > countOfTeamsInTable && !showAllWIthoutPreview && (
          <>
            <div
              onMouseEnter={openPreview}
              onMouseLeave={closePreview}
              onFocus={openPreview}
              onBlur={closePreview}
            >
              <Tag
                label={`+${teams.length - countOfTeamsInTable} ${
                  messages?.settings?.companyTabs.userAccounts.moreTeams
                }`}
                color={TAG_COLORS.BLUE}
                className={`truncate ${tagClassName}`}
                isUpperCase={false}
                isHoverable={false}
              />
            </div>
          </>
        )}
        {showPreview && !hidePreview && (
          <div className="assigned-teams-with-preview-popup z-10">
            <div className="assigned-teams-with-preview-popup-header">
              <Headline size={HEADLINE_SIZES.X_SMALL}>
                {
                  messages?.settings?.companyTabs.userAccounts
                    .assignedTeamsPopupTitle
                }
              </Headline>
            </div>
            <div className="divider divider--gray u-margin-0" />
            <div className="assigned-teams-with-preview-popup-body">
              {teams.slice(countOfTeamsInTable, teams.length).map((team) => (
                <Tag
                  key={team._id}
                  className="mb-lg"
                  label={team.name}
                  style={{
                    color: team.teamColor,
                    background: getOptionBackgroundColor(team.teamColor),
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AssignedTeamsWithPreview;

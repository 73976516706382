import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import AppModal from "@shared/ui/Modal";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

export const LoginAlert = ({ onClose }: { onClose: () => void }) => {
  const intl = useIntl();
  const { messages } = intl;
  const history = useHistory();

  return (
    <AppModal
      isOpen={true}
      handleClose={onClose}
      cancelButtonText={messages?.common?.["okay"]}
      handleSubmit={() => history.push("/oem")}
      updateButtonText={messages?.common?.["login"]}
      title={messages?.common?.["login"]}
      content={
        <>
          <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
            {messages?.["hierarchy"]?.["assets"]?.["restrictedAssetLogin"]}
          </BodyText>
        </>
      }
    />
  );
};

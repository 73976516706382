import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { InputSaveAndDiscard } from "@shared/ui/Inputs";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React from "react";
import { useIntl as useInternationalization } from "react-intl";
import { toast } from "react-toastify";

import { MAX_TEXT_INPUT_LENGTH } from "~/constants";
import { useUpdateAiNoteTitle } from "~/services";

const EditTitleText = ({
  isSelfCreated,
  noteId,
  title,
}: {
  isSelfCreated: boolean;
  noteId: string;
  title: string;
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [error, setError] = React.useState("");
  const [newTitle, setNewTitle] = React.useState(title);

  const resetValue = () => {
    setIsEditing(false);
    setError("");
    setNewTitle(title);
  };

  const { updateTitle } = useUpdateAiNoteTitle();

  const { messages } = useInternationalization();

  const handleUpdateTitle = () => {
    updateTitle({
      id: noteId,
      title: newTitle,
    })
      .then(() => {
        toast(<Toast message={messages["aiNotetaker"]["note-updated"]} />, {
          closeButton: false,
        });
      })
      .catch((error) => {
        toast(
          <Toast
            message={
              error?.message || messages["aiNotetaker"]["note-update-failed"]
            }
            type={TOAST_TYPES.ERROR}
          />,
          {
            closeButton: false,
          },
        );
      })
      .finally(() => {
        setIsEditing(false);
      });
  };

  const handleSubmit = () => {
    if (newTitle.trim() !== "") {
      if (title !== newTitle) handleUpdateTitle();
      setIsEditing(false);
      setError("");
    }
  };

  return (
    <div className="flex flex-col flex-grow space-y-sm">
      {isEditing ? (
        <InputSaveAndDiscard
          placeholder={messages["aiNotetaker"]["drawer-title-placeholder"]}
          value={newTitle}
          onChange={(e) => {
            const value = e.target.value;

            if (!value.trim())
              setError(messages["aiNotetaker"]["drawer-title-required"]);
            else if (value.length > MAX_TEXT_INPUT_LENGTH) {
              setError(messages["aiNotetaker"]["drawer-title-length-error"]);
              return;
            } else setError("");

            setNewTitle(e.target.value);
          }}
          errorMessage={error}
          onDiscard={resetValue}
          saveDisabled={!newTitle.trim().length}
          clickOutsideEnabled
          handleClickOutside={resetValue}
          onSave={() => {
            setIsEditing(false);
            handleSubmit();
          }}
        />
      ) : (
        <Headline
          onClick={() => isSelfCreated && setIsEditing(true)}
          size={HEADLINE_SIZES.X_SMALL}
        >
          {title}
        </Headline>
      )}
    </div>
  );
};

export default EditTitleText;

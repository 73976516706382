import React from "react";
import { useIntl } from "react-intl";

const NotFoundMessage = ({ className, text, variant = "" }) => {
  const classes = [
    `c-notFoundMessage ${className}`,
    variant ? `c-notFoundMessage--${variant}` : "",
  ].join(" ");

  return (
    <div className={classes}>
      <p className="c-notFoundMessageText text-sm">{text}</p>
    </div>
  );
};

export const NotFoundComponent = ({
  title = null,
  icon,
  showTitle = true,
  className = "no-tickets-wrapper full-page",
}) => {
  const { messages: msg } = useIntl();

  return (
    <div className={className}>
      <span className="no-tickets-icon">{icon || <NoTicketsLargeIcon />}</span>

      {showTitle && (
        <p className="no-tickets-title">{title || `${msg.common?.notFound}`}</p>
      )}
    </div>
  );
};

export default NotFoundMessage;

// libs
import React from "react";
import { useIntl } from "react-intl";

// assets
import DropdownHeader from "./_dropdownHeader";
import { NoUserAssignedIcon, TickIcon } from "../../icons/icons";

// utils
import { capitalizeFirstLetter } from "../../utils";

// components
import Dropdown from "../_dropdown";
import { NotFoundComponent } from "../_notFoundMessage";

const ScheduleFilter = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  activeFilters,
  selectValues,
  handleSelectDeleteAll,
}) => {
  const { messages: msg } = useIntl();
  // states
  const [searchTerm, setSearchTerm] = React.useState("");
  const [list, setList] = React.useState(filterItem.options);

  // search funcitonality
  React.useEffect(() => {
    if (searchTerm === "") {
      setList(filterItem.options);
    } else {
      const newList = filterItem?.options?.filter((i) =>
        i?.value?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
      );
      setList(newList);
    }
  }, [searchTerm]);

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
    >
      <DropdownHeader
        title={capitalizeFirstLetter(title)}
        searchTerm={searchTerm}
        onSearchTermChange={setSearchTerm}
        unselectFilter={unselectFilter}
      />
      <div className="filter-facility-name filter-items">
        {!list?.length ? (
          <NotFoundComponent
            icon={<NoUserAssignedIcon />}
            title={`${msg?.common?.filters.thereIsNo} ${title}.`}
          />
        ) : (
          list?.map((item, index) => (
            <div
              className="filter-facility-name-item-container"
              key={index}
              onClick={() => {
                if (
                  activeFilters[filterItem?.id || filterItem?._id]?.includes(
                    item?.value,
                  )
                ) {
                  handleSelectDeleteAll(filterItem, false);
                  return;
                }
                selectValues(filterItem, item?.value, true);
              }}
            >
              <span
                className={`filter-facility-name-item ${
                  activeFilters[filterItem?.id || filterItem?._id]?.includes(
                    item?.value,
                  )
                    ? "active"
                    : ""
                }`}
              >
                {item?.value}
              </span>
              {activeFilters[filterItem?.id || filterItem?._id]?.includes(
                item?.value,
              ) && (
                <span className="active">
                  <TickIcon />
                </span>
              )}
            </div>
          ))
        )}
      </div>
    </Dropdown>
  );
};

export default ScheduleFilter;

import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Drawer from "@shared/ui/Drawer";
import { differenceBy } from "lodash";
import React from "react";
import { useIntl } from "react-intl";

import ContentLoading from "~/components/ContentLoading";
import CustomFieldsAccordion from "~/components/customFields/_customFieldsAccordion";
import { useAuth } from "~/components/general";
import UnderlineTabsWrapper from "~/components/UnderlineTabsWrapper";
import PartDocuments from "~/containers/inventory/PartDocuments";
import { useRoleManager } from "~/hooks/_useRoleManager";
import { getInventoryPart, updateParts } from "~/services";
import type { UserType } from "~/types/user";
import { getPartThumbnail, getSortedCustomFields } from "~/utils";

type PartPreviewProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  onClose: () => void;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customAdditionalFields: any[];
  showCustomFields?: boolean;
  isSharedAssetsPage?: boolean;
};

const PartPreview = ({
  data,
  onClose,
  isOpen,
  customAdditionalFields,
  showCustomFields,
  isSharedAssetsPage = false,
}: PartPreviewProps) => {
  const { inventoryPart, loading } = getInventoryPart(data?._id);

  const { messages } = useIntl();
  const {
    user: { foldersAccessToken, role },
  } = useAuth() as { user: UserType };
  const { isOemTechnician } = useRoleManager(role);
  const { update } = updateParts();

  const allowModification = !isOemTechnician;
  const [customFieldData, setCustomFieldData] = React.useState({});

  const [customFieldList, setCustomFieldList] = React.useState([]);

  React.useEffect(() => {
    if (inventoryPart && customAdditionalFields?.length > 0) {
      const customFieldsObject = {};
      const customFieldsWithValue =
        inventoryPart?.customFields
          ?.map((item) => {
            if (item?.fieldId?.enabled) {
              customFieldsObject[item?.fieldId?._id] = item.value;
              const getOptionValue = item?.fieldId?.options?.find(
                (d) => d?.value === item.value,
              );

              return {
                _id: item?.fieldId?._id,
                label: item?.fieldId?.label,
                type: item?.fieldId?.fieldType,
                fieldType: item?.fieldId?.fieldType,
                isAdditionalField: item?.fieldId?.isAdditionalField,
                slug: item?.fieldId?.slug,
                options: item?.fieldId?.options,
                order: item?.fieldId.order,
                value:
                  item?.fieldId?.fieldType === "singleSelect"
                    ? getOptionValue?.value
                    : item?.value
                    ? item?.value
                    : "",
                color: getOptionValue?.color || "",
                created_at: customAdditionalFields?.find(
                  (f) => f?._id === item?.fieldId?._id,
                )?.created_at,
              };
            } else return false;
          })
          ?.filter((item) => Boolean(item)) || [];
      setCustomFieldData(customFieldsObject);
      const arrDiff = differenceBy(
        customAdditionalFields,
        customFieldsWithValue,
        "_id",
      );
      setCustomFieldList(
        getSortedCustomFields([...customFieldsWithValue, ...arrDiff]),
      );
    }
  }, [inventoryPart, customAdditionalFields]);

  const handleTextFieldSave = (e, textFields, fieldId, updateTextFields) => {
    e.preventDefault();

    const prepareNewCustomFieldData = {
      ...customFieldData,
      [fieldId]: textFields[fieldId],
    };

    const customFieldsArray = [];
    for (const [key, value] of Object.entries(prepareNewCustomFieldData)) {
      if (key && key !== "undefined") {
        customFieldsArray.push({ fieldId: key, value: value });
      }
    }

    update({
      _id: inventoryPart?._id,
      customFields: customFieldsArray,
    })
      .then(() => {
        delete textFields[fieldId];
        updateTextFields(textFields);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleSelectFieldSave = (fieldId, newValue) => {
    const prepareNewCustomFieldData = {
      ...customFieldData,
      [fieldId]: newValue,
    };

    const customFieldsArray = [];
    for (const [key, value] of Object.entries(prepareNewCustomFieldData)) {
      if (key && key !== "undefined") {
        customFieldsArray.push({
          fieldId: key,
          value: value,
        });
      }
    }

    update({
      _id: inventoryPart?._id,
      customFields: customFieldsArray,
    }).catch((err) => {
      console.error(err);
    });
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={data?.name}
      contentClassName="!px-0 pt-md"
      hideFooter
    >
      <UnderlineTabsWrapper
        className="pl-2xl"
        useHistoryState={false}
        tabs={[
          {
            id: "part-details",
            label: messages?.inventory["editPartDetailsTitle"],
            content: (
              <>
                <div className="flex flex-col w-full px-2xl gap-2xl">
                  <div className="w-24 h-9xl rounded-md overflow-hidden">
                    <img
                      src={getPartThumbnail(data, true)}
                      className="w-full h-full object-center object-none rounded-xs"
                    />
                  </div>
                  <div className="flex gap-lg w-full justify-evenly">
                    <div className="flex flex-col gap-xs w-6/12 break-words">
                      <BodyText
                        size={BODY_TEXT_SIZES.X_SMALL}
                        color="text-secondary"
                      >
                        {messages?.inventory["labels"].name}
                      </BodyText>
                      <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                        {data?.name}
                      </BodyText>
                    </div>
                    <div className="flex flex-col gap-xs w-6/12 break-words">
                      <BodyText
                        size={BODY_TEXT_SIZES.X_SMALL}
                        color="text-secondary"
                      >
                        {messages?.inventory["labels"].articleNumber}
                      </BodyText>
                      <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                        {data?.articleNumber}
                      </BodyText>
                    </div>
                  </div>
                  <div className="flex flex-col gap-xs w-full">
                    <BodyText
                      size={BODY_TEXT_SIZES.X_SMALL}
                      color="text-secondary"
                    >
                      {messages?.inventory["labels"].description}
                    </BodyText>
                    <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                      {data?.description || "-"}
                    </BodyText>
                  </div>
                  {showCustomFields &&
                    (loading ? (
                      <div className="w-full flex items-center justify-center">
                        <ContentLoading />
                      </div>
                    ) : (
                      <CustomFieldsAccordion
                        className="edit-part-custom-fields-accordion"
                        numberFieldDropdownClassName="property-userDropdownMenu-edit-part-number"
                        dateFieldDropdownClassName="property-userDropdownMenu-edit-part-date"
                        textFieldDropdownClassName="property-userDropdownMenu-edit-part-text"
                        selectFieldClassName="edit-part-custom-select-field"
                        customFieldList={customFieldList}
                        //@ts-ignore
                        handleTextFieldSave={handleTextFieldSave}
                        //@ts-ignore
                        handleSelectFieldSave={handleSelectFieldSave}
                        isEditable={allowModification}
                        hideEmptyFields
                        showEmptyFieldsButton
                      />
                    ))}
                </div>
              </>
            ),
          },
          {
            id: "part-documentation",
            label: messages?.inventory["documentation"],
            content: (
              <div className="inventory-activity-log-container">
                <>
                  {loading && <ContentLoading />}
                  {!loading && inventoryPart && (
                    <PartDocuments
                      isSharedAssetsPage={isSharedAssetsPage}
                      userFolderToken={
                        inventoryPart?.accessToken || foldersAccessToken
                      }
                      part={inventoryPart}
                      allowModification={false}
                    />
                  )}
                </>
              </div>
            ),
          },
        ]}
      />
    </Drawer>
  );
};

export default PartPreview;

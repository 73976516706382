import activityLog from "~/lang/en-US/activityLog.json";
import aiAssistant from "~/lang/en-US/ai-assistant.json";
import aiNotetaker from "~/lang/en-US/ai-notetaker.json";
import analytics from "~/lang/en-US/analytics";
import branding from "~/lang/en-US/branding";
import chat from "~/lang/en-US/chat";
import common from "~/lang/en-US/common";
import components from "~/lang/en-US/components.json";
import connections from "~/lang/en-US/connections.json";
import dataManagement from "~/lang/en-US/dataManagement";
import documents from "~/lang/en-US/documents";
import emails from "~/lang/en-US/emails.json";
import facilities from "~/lang/en-US/facilities";
import filters from "~/lang/en-US/filters";
import forms from "~/lang/en-US/forms";
import hierarchy from "~/lang/en-US/hierarchy.json";
import inventory from "~/lang/en-US/inventory";
import knowledgeBase from "~/lang/en-US/knowledgeBase.json";
import login from "~/lang/en-US/login";
import machines from "~/lang/en-US/machines";
import maintenanceDocuments from "~/lang/en-US/maintenanceDocuments";
import menus from "~/lang/en-US/menus";
import plans from "~/lang/en-US/plans.json";
import preventiveMaintenance from "~/lang/en-US/preventiveMaintenance";
import procedures from "~/lang/en-US/procedures";
import productionLines from "~/lang/en-US/productionLines.json";
import requests from "~/lang/en-US/requests.json";
import resourcePlanning from "~/lang/en-US/resourcePlanning";
import resources from "~/lang/en-US/resources.json";
import scheduling from "~/lang/en-US/scheduling.json";
import settings from "~/lang/en-US/settings";
import sharedAssets from "~/lang/en-US/sharedAssets.json";
import teams from "~/lang/en-US/teams.json";
import tickets from "~/lang/en-US/tickets";
import workOrderFormConfiguration from "~/lang/en-US/workOrderFormConfiguration.json";
import workOrderTypes from "~/lang/en-US/workOrderTypes.json";
import LOCALES from "~/lang/locales";

export default {
  [LOCALES[0].locale]: {
    analytics,
    chat,
    common,
    connections,
    dataManagement,
    documents,
    facilities,
    forms,
    inventory,
    login,
    machines,
    maintenanceDocuments,
    menus,
    preventiveMaintenance,
    plans,
    resourcePlanning,
    settings,
    tickets,
    branding,
    procedures,
    filters,
    productionLines,
    knowledgeBase,
    components,
    teams,
    emails,
    workOrderTypes,
    workOrderFormConfiguration,
    activityLog,
    aiAssistant,
    aiNotetaker,
    hierarchy,
    scheduling,
    resources,
    sharedAssets,
    requests,
  },
};

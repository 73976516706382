// libs
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import CalendarComponent from "@shared/ui/Calendar";
import Dropdown, { POSITIONS } from "@shared/ui/DropDown";
import { lowerCase, upperFirst } from "lodash";
import { Trash } from "phosphor-react";
import React, { useState } from "react";
import { useIntl } from "react-intl";

const DateRange = ({
  show,
  onClose,
  dropdownRef,
  filterItem,
  activeFilters,
  selectValues,
  unselectFilter,
  scope,
  title,
  dropDownPosition = POSITIONS.LEFT,
}) => {
  const { messages: msg } = useIntl();
  // states
  const [date, setDate] = useState(
    activeFilters[filterItem.id]?.length
      ? [
          new Date(activeFilters[filterItem.id]?.[0]),
          new Date(activeFilters[filterItem.id]?.[1]),
        ]
      : new Date(),
  );

  // handle date change
  const handleDateChange = (v) => {
    setDate(v);
  };

  // on clicking apply dates
  const applyDates = () => {
    selectValues(filterItem, date, true);
    onClose();
  };

  // on remove dates
  const removeDates = () => {
    setDate(new Date());
    selectValues(filterItem, [], true);
  };

  return (
    <Dropdown
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
      position={dropDownPosition}
    >
      <div className="filter-date-range">
        <div className="filter-date-range-header">
          <span>
            {upperFirst(
              lowerCase(
                msg?.filters.DATE_RANGE?.labelStart +
                  ` ${title} ` +
                  msg?.filters.DATE_RANGE?.labelJoiner +
                  ` ${filterItem?.type || msg?.filters?.[scope]}`,
              ),
            )}
          </span>
          <span className="delete-icon" onClick={unselectFilter}>
            <Trash
              size={20}
              className="u-text-color-gray-v2-60"
              onClick={unselectFilter}
            />
          </span>
        </div>
        <CalendarComponent
          onChange={handleDateChange}
          value={date}
          showDoubleView
          selectRange
          allowPartialRange
        />
        <div className="flex items-center pt-md border-0 border-t border-solid border-gray-5">
          <Button
            variant={BUTTON_VARIANTS.LINK}
            text={msg?.common?.filters.removeDates}
            className="capitalize"
            onClick={removeDates}
          />
          <Button
            variant={BUTTON_VARIANTS.LINK}
            text={msg.common?.filters.applyDates}
            disabled={!date.length || date?.length < 2}
            className="capitalize"
            onClick={applyDates}
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default DateRange;

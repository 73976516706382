import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ContentExplorer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [boxScriptLoaded, setBoxScriptLoaded] = React.useState(false);
  React.useEffect(() => {
    const boxScriptLoadedInterval = setInterval(() => {
      if (
        typeof Box != typeof undefined &&
        "ContentExplorer" in (Box || {}) &&
        "Preview" in (Box || {})
      ) {
        setBoxScriptLoaded(true);
        clearInterval(boxScriptLoadedInterval);
      }
    }, 500);
    return () => {
      clearInterval(boxScriptLoadedInterval);
    };
  }, []);

  const machineId = queryParams.get("machineId");
  const machineName = queryParams.get("machineName");
  const folderAccessToken = queryParams.get("folderAccessToken");
  const folderId = queryParams.get("folderId");

  useEffect(() => {
    if (!boxScriptLoaded) return;

    const contentExplorer = new Box.ContentExplorer();
    contentExplorer.show(folderId, folderAccessToken, {
      container: ".container",
      canPreview: true,
      canUpload: false,
      canShare: false,
      canCreateNewFolder: false,
      canDelete: false,
      canRename: false,
      canDownload: false,
      isTouch: false,
      responseInterceptor: (response) => {
        const { data } = response;
        if (data.name && !data.parent?.etag && !data.parent?.sequence_id) {
          data.name = machineName;
        }

        if (data.modified_by) {
          data.modified_by = {};
        }

        if (data?.item_collection?.entries) {
          data.item_collection.entries = data.item_collection.entries.map(
            (item) => {
              if (item.modified_by) {
                item.modified_by = {};
              }

              if (item.parent.name) {
                item.parent.name = machineName;
              }

              if (item.path_collection?.entries[1]?.name) {
                item.path_collection.entries[1].name = machineName;
              }

              return item;
            },
          );
        }

        if (data.entries) {
          data.entries = data.entries.map((item) => {
            if (item.modified_by) {
              item.modified_by = {};
            }

            if (item.parent.name) {
              item.parent.name = machineName;
            }

            if (item.path_collection?.entries[1]?.name) {
              item.path_collection.entries[1].name = machineName;
            }

            return item;
          });
        }

        if (data.path_collection?.entries[1]?.name) {
          data.path_collection.entries[1].name = machineName;
        }

        return response;
      },
    });
  }, [machineId, machineName, folderAccessToken, boxScriptLoaded]);

  return (
    <div>
      <div className="mobile-box-container container [&_.be]:!w-screen" />
    </div>
  );
};

export default ContentExplorer;

import { useLazyQuery, useQuery } from "@apollo/client";

import {
  GET_CURRENT_USER_BOX_ACCESS_TOKEN_QUERY,
  GET_CURRENT_USER_CHAT_ACCESS_TOKEN_QUERY,
  GET_CALENDAR_SYNC_AUTH_LINK_QUERY,
  CURRENT_USER,
  GET_CURRENT_USER_AI_ASSISTANT_USAGE_QUERY,
} from "~/api";
import { UserAiAssistantConfiguration } from "~/types/user";

export const getBoxAccessToken = ({ skip }) => {
  const { data: { currentUser: user = {} } = {}, loading } = useQuery(
    GET_CURRENT_USER_BOX_ACCESS_TOKEN_QUERY,
    {
      skip: skip,
      fetchPolicy: "network-only",
      pollInterval: 1000 * 60 * 30, // poll every half an hour
    },
  );
  return { user, loading };
};

export const getChatAccessToken = ({ skip }) => {
  const { data: { currentUser: user = {} } = {}, loading } = useQuery(
    GET_CURRENT_USER_CHAT_ACCESS_TOKEN_QUERY,
    {
      skip: skip,
      fetchPolicy: "network-only",
      pollInterval: 1000 * 60 * 15, // poll every 15 minutes
    },
  );
  return { user, loading };
};

export const useGetCalendarSyncAuthLink = () => {
  const [
    getUrl,
    { data: { getCalendarSyncAuthUrl = "" } = {}, loading, error },
  ] = useLazyQuery(GET_CALENDAR_SYNC_AUTH_LINK_QUERY);
  return {
    loading,
    error,
    url: getCalendarSyncAuthUrl,
    getAuthUrl: async () => getUrl(),
  };
};

export const useGetCurrentUserAiAssistantUsage = () => {
  const { data, loading, refetch } = useQuery<{
    [CURRENT_USER]: {
      consumedRecordingSeconds: number;
      aiAssistantConfiguration: UserAiAssistantConfiguration;
    };
  }>(GET_CURRENT_USER_AI_ASSISTANT_USAGE_QUERY);

  return { data: data?.[CURRENT_USER], loading, refetch };
};

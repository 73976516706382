import React from "react";

const Dropdown = ({
  children,
  show,
  onClose,
  dropdownRef,
  center,
  className = "",
}) => {
  // click outside the dropdown, closes it
  React.useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        show &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [show]);

  return (
    <div
      ref={dropdownRef}
      className={`dropdown ${show ? "show-dropdown" : ""} ${
        center ? "show-center-dropdown" : ""
      } ${className}`}
    >
      {children}
    </div>
  );
};

export default Dropdown;

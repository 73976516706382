import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { AssignItemListItem } from "@shared/ui/ListItemCards";
import AppModal from "@shared/ui/Modal";
import { Info } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";

import { NotFoundComponent } from "./_notFoundMessage";
import { ITEMS_BEFORE_PAGE } from "../constants";
import { NoUserAssignedIcon } from "../icons/icons";

const AssignItemModal = ({
  handleSubmit,
  isOpen,
  handleClose,
  updateBtnText,
  disableUpdate,
  selectedItems,
  setSelectedItems,
  getFilteredItems,
  getThumbnail,
  getTitle,
  getId,
  headerText,
  nameHeading,
  idHeading,
  infoMessage = "",
  searchQuery,
  setSearchQuery,
  itemsLoading = false,
  onScrollToBottom = () => {},
  loaderComponent = () => {},
  contentClassName = "",
}) => {
  const { messages } = useIntl();
  const [searchValue, setSearchValue] = React.useState("");

  const filteredItems = React.useMemo(
    () => getFilteredItems(searchValue) ?? [],

    [getFilteredItems, searchValue],
  );

  const handleRowClick = (event, id) => {
    event.stopPropagation();
    const index = selectedItems.findIndex((item) => item === id);
    if (index >= 0) {
      setSelectedItems((prev) => {
        const newIds = [...prev];
        newIds.splice(index, 1);

        return newIds;
      });
    } else {
      setSelectedItems((prev) => [...prev, id]);
    }
  };

  return (
    <AppModal
      contentClassName={contentClassName}
      handleSubmit={handleSubmit}
      isOpen={isOpen}
      handleClose={handleClose}
      updateButtonText={updateBtnText}
      disableUpdate={disableUpdate}
      disableCancel={false}
      title={headerText}
      content={
        <>
          <section>
            <input
              onChange={(e) => {
                setSearchValue(e.target.value);
                setSearchQuery(e.target.value);
              }}
              placeholder={
                messages?.productionLines?.assignMachineToProductionLineModal
                  .serachMachinePlaceHolder
              }
              className="c-input machine-parts-search"
              value={searchQuery ?? searchValue}
            />
          </section>
          {!filteredItems.length && !itemsLoading && (
            <NotFoundComponent icon={<NoUserAssignedIcon />} />
          )}
          {filteredItems.length > 0 && (
            <>
              {infoMessage && (
                <div className="assign-item-modal-info-container u-items-center u-flex u-align-center u-width-100 u-padding-2 u-margin-y-5">
                  <Info size={16} />
                  <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="flex-1">
                    {infoMessage}
                  </BodyText>
                </div>
              )}

              <section>
                <div className="u-flex assign-item-modal-header u-margin-b-2 px-md">
                  <div className="w-3xl py-xs" />
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    color="text-secondary"
                    className="flex-1 py-xs"
                  >
                    {nameHeading}
                  </BodyText>
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    color="text-secondary"
                    className="w-4/12 py-xs"
                  >
                    {idHeading}
                  </BodyText>
                </div>

                {filteredItems.map((item, index) => {
                  const name = getTitle(item);
                  const itemId = getId(item);
                  const thumbnail = getThumbnail(item);
                  return (
                    <React.Fragment key={index + "-assign-item"}>
                      <div onClick={(e) => handleRowClick(e, item._id)}>
                        <AssignItemListItem
                          isChecked={selectedItems.includes(item._id)}
                          name={name}
                          itemId={itemId}
                          thumbnail={thumbnail ? getThumbnail(item) : null}
                        />
                      </div>
                      {!itemsLoading &&
                      index === filteredItems.length - ITEMS_BEFORE_PAGE ? (
                        <Waypoint onEnter={onScrollToBottom} />
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </section>
            </>
          )}
          {itemsLoading ? loaderComponent() : null}
        </>
      }
    />
  );
};

export default AssignItemModal;

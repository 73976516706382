import { CalendarBlank } from "phosphor-react";
import React, { useState } from "react";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

/***
 * DatePickerV2 is wrapper component of "react-modern-calendar-datepicker"
 * We have added few custom style or props to this but all props from the package still usable
 * Props References: https://kiarash-z.github.io/react-modern-calendar-datepicker/docs/props-list
 */

export const DatePickerV2 = ({
  defaultValue,
  onChange,
  label,
  errorMessage,
  inputPlaceholder,
  uppercaseLabel,
  dateFromToday,
  icon = true,
  ...props
}) => {
  const [hasValue, setHasValue] = useState(false);
  const [showCalender, setShowCalendar] = useState(false);
  const ref = React.useRef(null);

  const classes = [
    "input-v2",
    errorMessage ? "input-v2-error" : "",
    hasValue ? "input-v2-has-value" : "",
  ].join(" ");

  React.useEffect(() => {
    if (defaultValue) {
      setHasValue(true);
    } else {
      setHasValue(false);
    }
  }, [defaultValue]);

  const formatDateInput = (val) => {
    if (!val) return "";
    const year = val.year.toString();
    const month = val.month.toString().padStart(2, 0);
    const day = val.day.toString().padStart(2, 0);
    return `${day}-${month}-${year}`;
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const CalendarInput = () => {
    return (
      <div className="input-v2-wrapper">
        <div className="calendar-input-wrapper">
          <input
            value={formatDateInput(defaultValue)}
            className={classes}
            placeholder={inputPlaceholder}
            readOnly
            onClick={() => setShowCalendar(true)}
          />
          {icon && (
            <span className="icon" onClick={() => setShowCalendar(true)}>
              <CalendarBlank />
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="input-v2-wrapper datepicker-v2" ref={ref}>
      {label && (
        <label
          className={`input-v2-label c-label ${
            uppercaseLabel ? "c-label--uppercase" : ""
          }`}
        >
          {label}
        </label>
      )}

      <CalendarInput />

      {showCalender && (
        <div className="datepicker-v2-calendar-wrapper">
          <Calendar
            onChange={(v) => {
              setShowCalendar(false);
              return onChange(v);
            }}
            value={defaultValue}
            colorPrimary="#0517F8"
            minimumDate={dateFromToday ? utils().getToday() : false}
          />
        </div>
      )}

      {errorMessage && (
        <p className="input-v2-error-label">
          <WarningCircle className="input-v2-error-icon" size={16} />
          {errorMessage}
        </p>
      )}
    </div>
  );
};

import { useQuery } from "@apollo/client";

import { ITEMS_PER_PAGE } from "#/src/constants";
import { LIST_OEM_INVENTORY_PARTS_QUERY, GET_INVENTORY_PART } from "~/api";

// all parts under OEM

export const getAllParts = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery,
  where = null,
  sort = [],
  skipCondition = false,
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      sort,
      where: {
        searchQuery,
        ...(where || {}),
      },
    },
  };

  const {
    data: { listOwnOemInventoryPart = [] } = {},
    loading: fetchingParts,
    refetch: refetchParts,
    fetchMore,
  } = useQuery(LIST_OEM_INVENTORY_PARTS_QUERY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    parts = [],
  } = listOwnOemInventoryPart;

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          sort,
          where: {
            searchQuery,
            ...(where || {}),
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedParts = [
          ...previousResult.listOwnOemInventoryPart.parts,
          ...fetchMoreResult.listOwnOemInventoryPart.parts,
        ];

        const updates = {
          ...previousResult,
          listOwnOemInventoryPart: {
            ...previousResult.listOwnOemInventoryPart,
            ...fetchMoreResult.listOwnOemInventoryPart,
            parts: mergedParts,
          },
        };

        return updates;
      },
    });
  };

  return {
    parts,
    handleFetchMore,
    refetchParts,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    fetchingParts,
  };
};

export const getInventoryPart = (id) => {
  const { data: { getInventoryPart } = {}, loading } = useQuery(
    GET_INVENTORY_PART,
    {
      variables: {
        id,
      },
      skip: !id,
    },
  );

  return {
    loading,
    inventoryPart: getInventoryPart,
  };
};

import * as React from "react";
const SvgMyAssets = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("rect", {
  width: 24,
  height: 24,
  rx: 6,
  fill: "#FF7A00"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M13.103 10.896a1.56 1.56 0 1 1-2.207 2.208 1.56 1.56 0 0 1 2.207-2.208Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M13.103 10.896a1.56 1.56 0 1 1-2.207 2.208 1.56 1.56 0 0 1 2.207-2.208",
  stroke: "#fff",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M18.3 11.007a1.405 1.405 0 1 1-1.987 1.986 1.405 1.405 0 0 1 1.986-1.986Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M18.3 11.007a1.405 1.405 0 1 1-1.987 1.986 1.405 1.405 0 0 1 1.986-1.986",
  stroke: "#fff",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M7.687 11.007a1.405 1.405 0 1 1-1.986 1.986 1.405 1.405 0 0 1 1.986-1.986Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.687 11.007a1.405 1.405 0 1 1-1.986 1.986 1.405 1.405 0 0 1 1.986-1.986",
  stroke: "#fff",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M15.646 6.411a1.405 1.405 0 1 1-1.987 1.987 1.405 1.405 0 0 1 1.987-1.987Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M15.646 6.411a1.405 1.405 0 1 1-1.987 1.987 1.405 1.405 0 0 1 1.987-1.987",
  stroke: "#fff",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M10.34 15.602a1.405 1.405 0 1 1-1.986 1.987 1.405 1.405 0 0 1 1.987-1.987Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.34 15.602a1.405 1.405 0 1 1-1.986 1.987 1.405 1.405 0 0 1 1.987-1.987",
  stroke: "#fff",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M10.34 6.411a1.405 1.405 0 1 1-1.986 1.987 1.405 1.405 0 0 1 1.987-1.987Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.34 6.411a1.405 1.405 0 1 1-1.986 1.987 1.405 1.405 0 0 1 1.987-1.987",
  stroke: "#fff",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M15.646 15.602a1.405 1.405 0 1 1-1.987 1.987 1.405 1.405 0 0 1 1.987-1.987Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M15.646 15.602a1.405 1.405 0 1 1-1.987 1.987 1.405 1.405 0 0 1 1.987-1.987M10.047 15.38l1.173-2.033M10.047 8.62l1.173 2.033M15.9 12h-2.34",
  stroke: "#fff",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgMyAssets;

import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import EmptySpace from "~/components/_emptySpace";
import AssignedTeamsWithPreview from "#/src/components/_assignedTeamsWithPreview";

export const KanbanMachineCard = React.memo(
  React.forwardRef(
    (
      { item, listeners, attributes, isDragging, isDragOverlay, isTeamsPaid },
      ref,
    ) => {
      const { messages } = useIntl();
      return (
        <div
          className={`kanban-card-container ${
            isDragOverlay ? "kanban-drag-overlay" : ""
          } ${isDragging ? "dragging" : ""}`}
          ref={ref}
          {...listeners}
          {...attributes}
        >
          <Link
            to={`/app/assets/machines/${item?._id}`}
            className="card-ticket-container u-flex-column"
          >
            <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
              <span className="text-secondary">
                {messages?.machines?.kanbanSerialNum}:
              </span>{" "}
              {item?.serialNumber}
            </BodyText>
            <EmptySpace height="12px" />
            <Label size={LABEL_SIZES.MEDIUM}>{item?.name}</Label>
            {isTeamsPaid && (
              <>
                <EmptySpace height="12px" />
                <AssignedTeamsWithPreview
                  teams={item.teams ?? []}
                  hidePreview
                />
              </>
            )}
          </Link>
        </div>
      );
    },
  ),
  () => true,
);

import React from "react";
import ContentLoader from "react-content-loader";

export const FacilityLoaderComponent = ({
  total = 2,
  width = 1102,
  height = 61,
}) => {
  const loaders = [];
  for (let i = 0; i < total; i++) {
    loaders.push(
      <ContentLoader key={i} width={width} height={height}>
        <rect x="0" y="0" rx="8" ry="8" width="162" height="40" />
        <rect x="180" y="5" rx="0" ry="0" width="200" height="12" />
        <rect x="180" y="25" rx="0" ry="0" width="150" height="10" />
        <rect x="1010" y="5" rx="0" ry="0" width="100" height="12" />
        <rect x="990" y="25" rx="0" ry="0" width="120" height="10" />
        <rect x="0" y="51" rx="0" ry="0" width="1102" height="1" />
      </ContentLoader>,
    );
  }

  return loaders;
};

import { useMutation } from "@apollo/client";

import {
  AiNote,
  DELETE_AI_NOTE_MUTATION,
  PUBLISH_AI_NOTE_MUTATION,
  TRANSLATE_AI_NOTE_SUMMARY_MUTATION,
  UPDATE_AI_NOTE_SUMMARY_MUTATION,
  UPDATE_AI_NOTE_TITLE_MUTATION,
} from "~/api";
import { errorsHandler } from "~/transformers";

export const useDeleteAiNote = () => {
  const [deleteAiNote, { loading, error }] = useMutation(
    DELETE_AI_NOTE_MUTATION,
  );

  return {
    isDeleting: loading,
    error,
    deleteNote: async (input) =>
      new Promise((resolve, reject) => {
        return deleteAiNote({
          variables: input,
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnAiNotes",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

export const usePublishAiNote = () => {
  const [publishAiNote, { loading, error }] = useMutation(
    PUBLISH_AI_NOTE_MUTATION,
  );

  return {
    isPublishing: loading,
    error,
    publishNote: async (input) =>
      new Promise((resolve, reject) => {
        return publishAiNote({
          variables: { input },
          update(cache, { data: { publishAiNote: ref } }) {
            cache.modify({
              id: cache.identify({ __typename: "AiNote", id: ref._id }),
              fields: {
                getOwnAiNote(existingRefs = []) {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: AiNote.fragments.AiNoteData,
                    fragmentName: "AiNoteData",
                  });

                  return [...existingRefs, newRef];
                },
              },
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemMachineHistory",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemActivityLogs",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

export const useTranslateAiNoteSummary = () => {
  const [translateAiNoteSummary, { loading, error }] = useMutation(
    TRANSLATE_AI_NOTE_SUMMARY_MUTATION,
  );

  return {
    isTranslating: loading,
    error,
    translateSummary: async (input) =>
      new Promise((resolve, reject) => {
        return translateAiNoteSummary({
          variables: { input },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

export const useUpdateAiNoteSummary = () => {
  const [updateAiNoteSummary, { loading, error }] = useMutation(
    UPDATE_AI_NOTE_SUMMARY_MUTATION,
  );

  return {
    isUpdating: loading,
    error,
    updateSummary: async (input) =>
      new Promise((resolve, reject) => {
        return updateAiNoteSummary({
          variables: { input },
          update(cache, { data: { updateAiNoteSummary: ref } }) {
            cache.modify({
              id: cache.identify({ __typename: "AiNote", id: ref._id }),
              fields: {
                getOwnAiNote(existingRefs = []) {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: AiNote.fragments.AiNoteData,
                    fragmentName: "AiNoteData",
                  });

                  return [...existingRefs, newRef];
                },
              },
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

export const useUpdateAiNoteTitle = () => {
  const [updateAiNoteTitle, { loading, error }] = useMutation(
    UPDATE_AI_NOTE_TITLE_MUTATION,
  );

  return {
    isUpdating: loading,
    error,
    updateTitle: async (input) =>
      new Promise((resolve, reject) => {
        return updateAiNoteTitle({
          variables: { input },
          update(cache, { data: { updateAiNoteTitle: ref } }) {
            cache.modify({
              id: cache.identify({ __typename: "AiNote", id: ref._id }),
              fields: {
                getOwnAiNote(existingRefs = []) {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: AiNote.fragments.AiNoteData,
                    fragmentName: "AiNoteData",
                  });

                  return [...existingRefs, newRef];
                },
              },
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

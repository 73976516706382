export const SCROLL_SCOPE = {
  WORK_ORDERS: {
    LIST: "/app/work-orders",
    BOARD: "/app/work-orders-kanban",
    CALENDAR: "/app/work-orders-calendar",
  },
  MY_WO_LIST: "/app/my-work-orders",
  MACHINE_LIST_PAGE: "/app/assets/machines",
  MACHINE_TEMPLATE_LIST_PAGE: "/app/assets/machines/templates",
  PORDUCTION_LINE_LIST_PAGE: "/app/assets/production-lines",
  FACILITY_LIST_PAGE: "/app/facilities",
  INVENTORY_LIST_PAGE: "/app/inventory",
  DOCUMENTS: {
    SUBMISSIONS: "/app/documents",
    TEMPLATES: "/app/documents/templates",
  },
};

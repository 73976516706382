export const base64ToFile = (
  base64String: string,
  {
    fileName,
    lastModified = Date.now(),
    mimeType,
  }: { fileName: string; lastModified?: number; mimeType?: string },
): File => {
  if (!base64String || !fileName) {
    throw new Error("Base64 string and file name are required");
  }

  try {
    const fileMimeType = mimeType;
    let base64Data = base64String;
    if (!fileMimeType) {
      const base64DataChunks = base64String.split(";base64,");
      base64Data = base64DataChunks[1];
      mimeType = base64DataChunks[0];
    }

    const binaryString = atob(base64Data);
    const bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));

    const blob = new Blob([bytes], { type: mimeType });

    return new File([blob], fileName, {
      type: fileMimeType,
      lastModified,
    });
  } catch (error) {
    throw new Error(
      `Failed to convert base64 to file: ${(error as Error).message}`,
    );
  }
};

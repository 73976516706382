import { useQuery } from "@apollo/client";

import {
  GET_OWN_OEM_CUSTOMER_BY_ID_QUERY,
  LIST_OWN_OEM_FACILITY_USERS_QUERY,
} from "../../api";
import {
  LIST_ALL_OWN_OEM_CUSTOMERS_QUERY,
  LIST_OWN_OEM_KANBAN_CUSTOMERS,
} from "../../api/_queries";

import { ITEMS_PER_PAGE } from "#/src/constants";
import { ticketTransformer } from "#/src/transformers";
import {
  defaultFilters,
  generateFacilitiesQueryWhereCond,
} from "~/utils/filter/_facilitiesQueryCond";

export const useListAllOwnOemCustomers = ({
  activeFilters = defaultFilters,
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery,
  sort = [],
  where = {},
  skipCondition = false,
} = {}) => {
  const filterConditions = generateFacilitiesQueryWhereCond(activeFilters);
  const hasFilter = Object.keys(filterConditions)?.length;
  const variables = {
    ...(hasFilter
      ? {
          params: {
            where: {
              ...filterConditions,
              searchQuery,
              ...(where || {}),
            },
            limit,
            skip,
            sort,
          },
        }
      : {
          params: {
            limit,
            skip,
            sort,
            where: {
              searchQuery,
              ...(where || {}),
            },
          },
        }),
  };

  const {
    data: { listAllOwnOemCustomers = [] } = {},
    loading,
    refetch: facilitiesRefetch,
    fetchMore,
  } = useQuery(LIST_ALL_OWN_OEM_CUSTOMERS_QUERY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    customers = [],
  } = listAllOwnOemCustomers;

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        ...(hasFilter
          ? {
              params: {
                where: {
                  ...filterConditions,
                  searchQuery,
                  ...(where || {}),
                },
                limit,
                skip,
                sort,
              },
            }
          : {
              params: {
                limit,
                skip,
                sort,
                where: {
                  searchQuery,
                  ...(where || {}),
                },
              },
            }),
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedFacilities = [
          ...previousResult.listAllOwnOemCustomers.customers,
          ...fetchMoreResult.listAllOwnOemCustomers.customers,
        ];

        const updates = {
          ...previousResult,
          listAllOwnOemCustomers: {
            ...previousResult.listAllOwnOemCustomers,
            ...fetchMoreResult.listAllOwnOemCustomers,
            customers: mergedFacilities,
          },
        };

        return updates;
      },
    });
  };

  return {
    customers,
    handleFetchMore,
    facilitiesRefetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
  };
};

export const useGetFacilityById = (id) => {
  const {
    data: { getOwnOemCustomerById = {} } = {},
    loading,
    error,
  } = useQuery(GET_OWN_OEM_CUSTOMER_BY_ID_QUERY, {
    variables: { id },
    skip: !id,
  });

  return {
    loading,
    error,
    facility: getOwnOemCustomerById,
  };
};

export const useListOwnOemFacilityUsers = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery,
  where,
  skipCondition = false,
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      where: {
        searchQuery,
        ...(where || {}),
      },
    },
  };

  const {
    data: { listOwnOemFacilityUsers = [] } = {},
    loading,
    fetchMore,
  } = useQuery(LIST_OWN_OEM_FACILITY_USERS_QUERY, {
    variables,
    skip: skipCondition,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    users = [],
  } = listOwnOemFacilityUsers ?? {
    currentPage: 0,
    itemLimit: 0,
    skip: 0,
    totalCount: 0,
    users: [],
  };

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          where: {
            searchQuery,
            ...(where || {}),
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedUsers = [
          ...previousResult.listOwnOemFacilityUsers.users,
          ...fetchMoreResult.listOwnOemFacilityUsers.users,
        ];

        const updates = {
          ...previousResult,
          listOwnOemFacilityUsers: {
            ...previousResult.listOwnOemFacilityUsers,
            ...fetchMoreResult.listOwnOemFacilityUsers,
            users: mergedUsers,
          },
        };

        return updates;
      },
    });
  };

  return {
    userOptions: ticketTransformer.makeOptions(users),
    users,
    handleFetchMore,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
  };
};

export const useListOwnOemKanbanCustomers = ({
  activeFilters,
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery,
  sort,
  where,
  skipCondition = false,
} = {}) => {
  const filterConditions = generateFacilitiesQueryWhereCond(activeFilters);
  const hasFilter = Object.keys(filterConditions)?.length;
  const variables = {
    ...(hasFilter
      ? {
          params: {
            where: {
              ...filterConditions,
              searchQuery,
              ...(where || {}),
            },
            limit,
            skip,
            ...(sort ? { sort } : {}),
          },
        }
      : {
          params: {
            limit,
            skip,
            ...(sort ? { sort } : {}),
            where: {
              searchQuery,
              ...(where || {}),
            },
          },
        }),
  };

  const {
    data: { listOwnOemKanbanCustomers = {} } = {},
    loading,
    refetch: customersRefetch,
    fetchMore,
  } = useQuery(LIST_OWN_OEM_KANBAN_CUSTOMERS, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    columns = [],
  } = listOwnOemKanbanCustomers;

  return {
    columns,
    fetchMore,
    customersRefetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
  };
};

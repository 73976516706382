import * as React from "react";
const SvgUser = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#user-fill)",
  stroke: "currentColor",
  strokeWidth: 1.2
}, /* @__PURE__ */ React.createElement("path", {
  d: "M8 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z",
  strokeMiterlimit: 10
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1.938 13.5a7 7 0 0 1 12.124 0",
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "user-fill"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h16v16H0z"
}))));
export default SvgUser;

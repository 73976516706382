import Accordion, { ACCORDION_VARIANTS } from "@shared/ui/Accordion";
import AppHeader from "@shared/ui/AppHeader";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Breadcrumbs from "@shared/ui/Breadcrumbs";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import TextEditor from "@shared/ui/TextEditor";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import getEnum from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features.json";
import Machine3DTab from "~/components/3d/Machine3D";
import AppHeaderWithSidebarOption from "~/components/AppHeaderWithSidebarOption";
import SubAssets from "~/components/assets/SubAssets";
import ContentLoading from "~/components/ContentLoading";
import { Loading } from "~/components/general";
import { LoginAlert } from "~/components/LoginAlert";
import MachineParts from "~/components/machines/_machineParts";
import MachineListItem from "~/components/machines/MachineListItem";
import QRCodeModal from "~/components/machines/QRCodeModal";
import PreventiveMaintenance from "~/components/preventiveMaintenance";
import UnderlineTabsWrapper from "~/components/UnderlineTabsWrapper";
import {
  SHARED_ASSETS_GI,
  SHARED_ASSETS_INITIATE_REQUEST,
  SHARED_ASSETS_VIEW,
  SHARED_ASSETS_VIEW_3D,
  SHARED_ASSETS_VIEW_DOCS,
  SHARED_ASSETS_VIEW_HISTORY,
  SHARED_ASSETS_VIEW_PARTS,
  SHARED_ASSETS_VIEW_PME,
  SHARED_ASSETS_VIEW_QR,
  SHARED_ASSETS_VIEW_SUB_ASSETS,
} from "~/constants";
import MachineDocuments from "~/containers/machine/MachineDocuments";
import { MachineHistory } from "~/containers/machine/MachineHistory";
import { PMEMessageProvider } from "~/containers/plannedEvents/helpers";
import Create from "~/containers/Requests/Create";
import { useListSharedPreventiveMaintenanceEvents } from "~/services";
import {
  useGetAssetFolderTokens,
  useGetAssetsPartsById,
  useGetAssetWithOrgById,
} from "~/services/asset";
import { AssetType } from "~/types/assetType";
import { getMachineThumbnail, handleUrl } from "~/utils";
import { generateBrandingButtonStyle, isLightColor } from "~/utils/_colors";
import { registerMixpanelEvent } from "~/utils/_mixpanel";
import { useGetAppConfigRecoilValue } from "~/utils/appFeatures";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const paidFeatures = getEnum(PAID_FEATURES, "reference");

const SingleSharedAsset = ({
  asset,
  assetParts,
  isQRCodeFlow,
}: {
  asset: AssetType;
  assetParts;
  isQRCodeFlow: boolean;
}) => {
  const { connectionId, assetId } = useParams() as {
    connectionId: string;
    assetId: string;
  };
  const { messages } = useIntl();

  const [showQRModal, setShowQRModal] = React.useState(false);
  const [qrCodeMachine, setQRCodeMachine] = React.useState(null);
  const [showLoginAlert, setShowLoginAlert] = React.useState(false);
  const appConfig = useGetAppConfigRecoilValue();

  // eslint-disable-next-line prefer-const
  let { loading: assetDataLoading, asset: assetData } = useGetAssetWithOrgById({
    id: assetId,
    skipCondition: !!asset?._id,
  });
  const [showCreateDrawer, setShowCreateDrawer] = React.useState(false);

  assetData = assetData || asset;

  const sharedOrganization = assetData?.oem;

  const assetAccess = assetData?.sharedAssetAccess;

  const isSharedOrgPMEPaid = isPaidFeatureAvailable(
    paidFeatures.preventiveMaintenance,
    // @ts-ignore
    { oem: sharedOrganization },
    appConfig,
    false,
  );

  const isSharedOrg3DPaid = isPaidFeatureAvailable(
    paidFeatures._3DModels,
    // @ts-ignore
    { oem: sharedOrganization },
    appConfig,
    false,
  );

  useEffect(() => {
    Boolean(assetData?.oem?.slug) &&
      registerMixpanelEvent(SHARED_ASSETS_VIEW, {
        sharerOrgUrl: assetData?.oem?.slug,
      });
  }, [assetData?.oem?.slug]);

  const assetTypeIdToLabelMap = React.useMemo(() => {
    const map = {};
    sharedOrganization?.assetTypes.forEach((type) => {
      map[type._id] = type.name;
    });
    return map;
  }, [sharedOrganization?.assetTypes, assetData?._id]);

  const onChangeTab = (title) => {
    let event = null;
    if (
      title.includes(messages?.machines?.["machineDetails"].titleTabs["_3D"])
    ) {
      event = SHARED_ASSETS_VIEW_3D;
    } else if (
      title.includes(
        messages?.machines?.["machineDetails"].titleTabs.generalInfo,
      )
    ) {
      event = SHARED_ASSETS_GI;
    } else if (
      title.includes(
        messages?.machines?.["machineDetails"].titleTabs.documentation,
      )
    ) {
      event = SHARED_ASSETS_VIEW_DOCS;
    } else if (
      title.includes(messages["machines"]["machineDetails"].titleTabs.subAssets)
    ) {
      event = SHARED_ASSETS_VIEW_SUB_ASSETS;
    } else if (
      title.includes(messages?.machines?.["machineDetails"].titleTabs.parts)
    ) {
      event = SHARED_ASSETS_VIEW_PARTS;
    } else if (
      title.includes(
        messages?.machines?.["machineDetails"].titleTabs.preventiveMaintenance,
      )
    ) {
      event = SHARED_ASSETS_VIEW_PME;
    } else if (messages["machines"]["machineDetails"].titleTabs.history) {
      event = SHARED_ASSETS_VIEW_HISTORY;
    }

    if (event !== null) {
      registerMixpanelEvent(event, {
        sharerOrgUrl: assetData?.oem?.slug,
      });
    }
  };

  // eslint-disable-next-line prefer-const
  let { inventoryParts, inventoryPartsLoading } = useGetAssetsPartsById({
    id: assetId,
    isSharedAsset: true,
    skipCondition: !assetAccess?.parts || isQRCodeFlow,
  });

  inventoryParts = inventoryParts || assetParts;

  const { token, loading: folderTokenLoading } = useGetAssetFolderTokens({
    id: assetId,
    skipCondition: !assetAccess?.documentation || isQRCodeFlow,
  });

  const { events, loading: loadingEvents } =
    useListSharedPreventiveMaintenanceEvents(
      {
        where: {
          oem_in: assetData?.oem,
          machine_in: assetId,
        },
      },
      !assetData?.oem || !assetAccess?.preventiveMaintenance,
    );

  const isLightBackground = isLightColor(
    sharedOrganization?.backgroundColor || "",
  );

  const buttonStyle = React.useMemo(() => {
    const lightColorButtonStyle = generateBrandingButtonStyle(
      sharedOrganization?.backgroundColor,
    );

    return isLightBackground
      ? lightColorButtonStyle
      : { backgroundColor: sharedOrganization?.backgroundColor };
  }, [sharedOrganization?.backgroundColor, isLightBackground]);

  const breadCrumbs = React.useMemo(
    () => [
      {
        label: messages["sharedAssets"]["title"],
        link: "/app/asset-hub/shared-assets",
      },
      {
        label: sharedOrganization?.name,
        link: `/app/asset-hub/shared-assets/${connectionId}`,
      },
      {
        label: assetData?.name,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sharedOrganization?.name, assetData?.name],
  );

  if (
    !sharedOrganization?.name ||
    !assetData?.name ||
    (!isQRCodeFlow && assetDataLoading)
  ) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col">
      {isQRCodeFlow ? (
        <AppHeader padding="p-2xl">
          <img
            src="https://workloads-alpha-makula-technology-gmbh.s3.eu-central-1.amazonaws.com/public/makula-logo-email.png"
            alt="company logo"
            className="max-h-3xl"
          />
        </AppHeader>
      ) : (
        <AppHeaderWithSidebarOption padding="p-2xl">
          <div className={isQRCodeFlow ? "pointer-events-none" : ""}>
            <Breadcrumbs options={breadCrumbs} />
          </div>
        </AppHeaderWithSidebarOption>
      )}
      <div className="flex flex-col">
        <img
          src={
            (sharedOrganization?.coverPhoto || null) ??
            "/images/default/cover-photo.png"
          }
          className="object-cover"
          style={{ height: "208px" }}
        />
        <div
          className="border border-solid border-primary rounded-lg p-2xl space-y-2xl bg-inverse mx-auto"
          style={{ marginTop: "-6%", width: "97%" }}
        >
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-lg relative w-1/2">
              <img
                src={getMachineThumbnail(assetData, false, true)}
                className="rounded-lg object-cover"
                style={{ width: "64px", height: "64px" }}
              />
              {!!sharedOrganization?.brandLogo && (
                <div className="absolute -bottom-4 left-7">
                  <img
                    src={sharedOrganization?.brandLogo}
                    className="object-cover w-3xl h-3xl rounded-full"
                  />
                </div>
              )}
              <div className="flex flex-col space-y-2xs">
                <Headline size={HEADLINE_SIZES.SMALL}>
                  {assetData?.name}
                </Headline>
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                  SN# {assetData?.serialNumber}
                </BodyText>
              </div>
            </div>
            <div className="flex space-x-lg items-center w-1/2 justify-end">
              {!isQRCodeFlow && (
                <>
                  {assetAccess.qrCodes && (
                    <>
                      <MachineListItem.QRCode
                        widthClass="w-fit"
                        handleQRCodeClick={() => {
                          setShowQRModal(true);
                          registerMixpanelEvent(SHARED_ASSETS_VIEW_QR, {
                            sharerOrgUrl: assetData?.oem?.slug,
                          });
                        }}
                      />
                      <QRCodeModal
                        machine={qrCodeMachine || assetData}
                        onClose={() => setQRCodeMachine(null)}
                        showQRModal={showQRModal}
                        setShowQRModal={setShowQRModal}
                        sharedBy={sharedOrganization?.name}
                        sharedByLogo={sharedOrganization?.brandLogo}
                      />
                    </>
                  )}
                  <Button
                    variant={
                      sharedOrganization?.backgroundColor
                        ? BUTTON_VARIANTS.PRIMARY
                        : BUTTON_VARIANTS.SECONDARY
                    }
                    style={buttonStyle}
                    text={messages["sharedAssets"]["createRequest"]}
                    onClick={() => {
                      setShowCreateDrawer(true);
                      registerMixpanelEvent(SHARED_ASSETS_INITIATE_REQUEST, {
                        sharerOrgUrl: assetData?.oem?.slug,
                      });
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="flex justify-between space-x-lg">
            <div className="flex flex-col space-y-xs w-1/3">
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                {messages["sharedAssets"]["assetType"]}
              </BodyText>
              <Label size={LABEL_SIZES.SMALL}>
                {assetTypeIdToLabelMap[assetData?.assetType] || "--"}
              </Label>
            </div>
            <div className="flex flex-col space-y-xs w-1/3">
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                {messages["sharedAssets"]["lastUpdated"]}
              </BodyText>
              <Label size={LABEL_SIZES.SMALL}>
                {new Date(assetData?.updatedAt ?? assetData?.createdAt)
                  .toLocaleDateString("en-GB")
                  .replace(/\//g, "-")}
              </Label>
            </div>
            <div className="flex flex-col space-y-xs w-1/3">
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                {messages["sharedAssets"]["manufacturer"]}
              </BodyText>
              <Label size={LABEL_SIZES.SMALL} color="text-brand cursor-pointer">
                {sharedOrganization?.website?.trim() ? (
                  <a
                    href={handleUrl(sharedOrganization?.website)}
                    className="text-brand"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {sharedOrganization?.name}
                  </a>
                ) : (
                  sharedOrganization?.name
                )}
              </Label>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "97%" }} className="mx-auto">
        <UnderlineTabsWrapper
          onChangeTab={onChangeTab}
          onRestrictedTabChange={() => setShowLoginAlert(true)}
          tabs={[
            {
              id: "general-information",
              label:
                messages?.machines?.["machineDetails"].titleTabs.generalInfo,
              content: (
                <div className="u-margin-b-0">
                  <Accordion
                    defaultExpanded
                    variant={ACCORDION_VARIANTS.DESCRIPTION}
                    title={messages?.common?.["description"]}
                  >
                    <TextEditor
                      keyId="machine-desc"
                      className="p-sm hover:bg-accent"
                      content={assetData?.description}
                      readOnly
                    />
                  </Accordion>
                </div>
              ),
            },
            ...(assetAccess?.documentation
              ? [
                  {
                    id: "documentation",
                    label:
                      messages?.machines?.["machineDetails"].titleTabs
                        .documentation,
                    content: folderTokenLoading ? (
                      <Loading />
                    ) : (
                      <MachineDocuments
                        userFolderToken={token}
                        machine={assetData}
                        isSharedAssetsPage
                      />
                    ),
                    restricted: isQRCodeFlow,
                  },
                ]
              : []),
            ...(assetAccess?.history
              ? [
                  {
                    id: "history",
                    label:
                      messages["machines"]["machineDetails"].titleTabs.history,
                    content: (
                      <MachineHistory machine={assetData} isSharedAsset />
                    ),
                    restricted: isQRCodeFlow,
                  },
                ]
              : []),
            {
              id: "sub-assets",
              label: `${
                messages["machines"]["machineDetails"].titleTabs.subAssets
              } (${assetData.totalChildrenCount || 0})`,
              content: (
                <SubAssets
                  asset={assetData}
                  isSharedAssetsPage
                  sharedAssetsOrg={sharedOrganization?._id}
                  isQRCodeEnabled={assetAccess?.qrCodes}
                  handleQRCodeClick={(asset) => {
                    setQRCodeMachine(asset);
                    setShowQRModal(true);
                  }}
                />
              ),
              restricted: isQRCodeFlow,
            },
            ...(assetAccess?.parts
              ? [
                  {
                    id: "parts",
                    label:
                      messages?.machines?.["machineDetails"].titleTabs.parts,
                    rightContent: inventoryPartsLoading ? (
                      <div className="loader" />
                    ) : (
                      `(${inventoryParts?.length || 0})`
                    ),
                    content: (
                      <>
                        {inventoryPartsLoading ? (
                          <ContentLoading />
                        ) : (
                          <MachineParts
                            parts={inventoryParts}
                            isDetached={
                              assetData?.["detachedFromTemplate"]
                                ?.inventoryParts
                            }
                            showDetachAlert={Boolean(assetData?.template)}
                            isSharedAssetsPage
                          />
                        )}
                      </>
                    ),
                    restricted: isQRCodeFlow,
                  },
                ]
              : []),
            ...(assetAccess?._3dModel &&
            isSharedOrg3DPaid &&
            assetData?._3dModelUrl
              ? [
                  {
                    id: "3d-model",
                    label:
                      messages?.machines?.["machineDetails"].titleTabs["_3D"],
                    content: <Machine3DTab is3DPaid machine={assetData} />,
                    restricted: isQRCodeFlow,
                  },
                ]
              : []),
            ...(assetAccess?.preventiveMaintenance && isSharedOrgPMEPaid
              ? [
                  {
                    id: "preventive-maintenance",
                    label: `${messages?.machines?.["machineDetails"].titleTabs
                      .preventiveMaintenance} (${events?.length || 0})`,
                    content: (
                      <PMEMessageProvider
                        messages={{
                          title:
                            messages?.preventiveMaintenance?.[
                              "noPreventiveMaintenanceTitle"
                            ],
                          description:
                            messages?.preventiveMaintenance?.[
                              "noPreventiveMaintenanceContent"
                            ],
                        }}
                      >
                        <PreventiveMaintenance
                          machineId={assetId}
                          events={events}
                          isLoadingEvents={loadingEvents}
                          isSharedAssetsPage
                        />
                      </PMEMessageProvider>
                    ),
                    restricted: isQRCodeFlow,
                  },
                ]
              : []),
          ]}
        />
      </div>
      {showLoginAlert && (
        <LoginAlert onClose={() => setShowLoginAlert(false)} />
      )}
      {showCreateDrawer && (
        <Create
          onClose={() => setShowCreateDrawer(false)}
          selectedAsset={assetData}
        />
      )}
    </div>
  );
};

export default SingleSharedAsset;

import { useMutation, useQuery } from "@apollo/client";

import { ticketTransformer } from "#/src/transformers";
import {
  CREATE_CONTACT_MUTATION,
  DELETE_CONTACT_MUTATION,
  INVITE_CONTACTS_MUTATION,
  LIST_CONTACTS_QUERY,
  REMOVE_CONTACT_INVITE_MUTATION,
  RESEND_CONTACT_INVITE_MUTATION,
  UPDATE_CONTACT_MUTATION,
} from "~/api";
import { ITEMS_PER_PAGE } from "~/constants";
import {
  CREATE_CONTACT,
  DELETE_CONTACT,
  INVITE_CONTACTS,
  LIST_CONTACTS,
  REMOVE_CONTACT_INVITE,
  RESEND_CONTACT_INVITE,
  UPDATE_CONTACT,
} from "~/services/contact/constants";
import { ContactType } from "~/types/contact";

export const useCreateContact = () => {
  const [create, { error, loading }] = useMutation<
    { [CREATE_CONTACT]: ContactType["_id"] },
    {
      input: ContactType;
    }
  >(CREATE_CONTACT_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_CONTACTS,
      });
      cache.gc();
    },
  });

  return {
    createContact: create,
    error,
    loading,
  };
};

export const useInviteContacts = () => {
  const [invite, { error, loading }] = useMutation<
    { [INVITE_CONTACTS]: ContactType["_id"][] },
    {
      input: {
        connectionId: string;
        contactIds: string[];
      };
    }
  >(INVITE_CONTACTS_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_CONTACTS,
      });
      cache.gc();
    },
  });

  return {
    inviteContacts: invite,
    error,
    loading,
  };
};

export const useRemoveContactInvite = () => {
  const [removeInvite, { error, loading }] = useMutation<
    { [REMOVE_CONTACT_INVITE]: ContactType["_id"][] },
    {
      id: string;
    }
  >(REMOVE_CONTACT_INVITE_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_CONTACTS,
      });
      cache.gc();
    },
  });

  return {
    removeInvite: removeInvite,
    error,
    loading,
  };
};

export const useResendContactInvite = () => {
  const [resendInvite, { error, loading }] = useMutation<
    { [RESEND_CONTACT_INVITE]: ContactType["_id"][] },
    {
      id: string;
    }
  >(RESEND_CONTACT_INVITE_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_CONTACTS,
      });
      cache.gc();
    },
  });

  return {
    resendInvite: resendInvite,
    error,
    loading,
  };
};

export const useUpdateContact = () => {
  const [update, { error, loading }] = useMutation<
    { [UPDATE_CONTACT]: ContactType },
    {
      input: {
        _id: string;
        name: string;
        email: string;
        jobTitle: string;
        landline: string;
        phoneNumber: string;
      };
    }
  >(UPDATE_CONTACT_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_CONTACTS,
      });
      cache.gc();
    },
  });

  return {
    updateContact: update,
    error,
    loading,
  };
};

export const useDeleteContact = () => {
  const [deleteContact, { error, loading }] = useMutation<
    { [DELETE_CONTACT]: ContactType["_id"] },
    {
      id: string;
    }
  >(DELETE_CONTACT_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_CONTACTS,
      });
      cache.gc();
    },
  });

  return {
    deleteContact,
    error,
    loading,
  };
};

export const useListContacts = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery = "",
  connectionId,
  skipCondition = false,
  withDeleted = false,
}: {
  limit?: number;
  skip?: number;
  searchQuery?: string;
  connectionId?: string;
  skipCondition?: boolean;
  withDeleted?: boolean;
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      where: {
        searchQuery,
        connection: connectionId,
        withDeleted: withDeleted,
      },
    },
  };

  const { data, loading, refetch, fetchMore } = useQuery<
    {
      [LIST_CONTACTS]: {
        currentPage: number;
        limit: number;
        skip: number;
        totalCount: number;
        contacts: ContactType[];
      };
    },
    {
      params: {
        limit: number;
        skip: number;
        where: {
          searchQuery: string;
        };
      };
    }
  >(LIST_CONTACTS_QUERY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    contacts = [],
  } = data?.[LIST_CONTACTS] ?? {
    currentPage: 0,
    limit: 0,
    skip: 0,
    totalCount: 0,
    contacts: [],
  };

  const handleFetchMore = ({
    limit,
    skip,
  }: {
    limit: number;
    skip: number;
  }): void => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          where: {
            searchQuery,
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedContacts = [
          ...previousResult[LIST_CONTACTS].contacts,
          ...fetchMoreResult[LIST_CONTACTS].contacts,
        ];

        return {
          ...previousResult,
          listContacts: {
            ...previousResult[LIST_CONTACTS],
            ...fetchMoreResult[LIST_CONTACTS],
            contacts: mergedContacts,
          },
        };
      },
    });
  };

  return {
    contactOptions: ticketTransformer.makeOptions(contacts),
    contacts,
    handleFetchMore,
    refetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
  };
};

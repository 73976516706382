import { ASSET_ASSIGNED, TEAMS } from "~/constants/filters/filters";

export const defaultFilters = {
  [ASSET_ASSIGNED]: [],
  [TEAMS]: [],
};

const applyFilters = (activeFilters = defaultFilters, _filter) => {
  if (
    activeFilters[_filter]?.length > 0 ||
    typeof activeFilters[_filter] === "boolean"
  ) {
    return activeFilters[_filter];
  } else {
    return undefined;
  }
};

export const generateFacilitiesQueryWhereCond = (
  activeFilters = defaultFilters,
  searchValue = "",
) => {
  const customFields = [];

  for (const filter of Object.keys(activeFilters)) {
    if (!defaultFilters[filter] && activeFilters[filter]?.length) {
      customFields.push({
        fieldId: filter,
        value: activeFilters[filter],
      });
    }
  }

  const machine_assigned = applyFilters(activeFilters, ASSET_ASSIGNED);

  const teams_in = applyFilters(activeFilters, TEAMS);

  return {
    ...(machine_assigned !== undefined ? { machine_assigned } : {}),
    ...{ name_contains: searchValue },
    ...(teams_in ? { teams_in } : {}),
    ...(customFields?.length ? { customFields } : {}),
  };
};

import React from "react";
import useOnClickOutside from "use-onclickoutside";

type Props = {
  actions: Array<
    Partial<{
      className: string;
      label: React.ReactNode;
      onClick: (_: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
      style: React.CSSProperties;
    }>
  >;
  close: () => void;
  position?: "bottom" | "top" | "below-parent";
  style?: React.CSSProperties;
  width?: `w-${number}`;
};

const ActionPopup = ({
  actions = [],
  close = () => {},
  position = "bottom",
  style = {},
  width = "w-44",
}: Props) => {
  const ref = React.useRef(null);

  useOnClickOutside(ref, close);

  const positionClass = {
    bottom: "top-2",
    top: "bottom-2",
    "below-parent": "top-full",
  };

  return (
    <div
      className={`absolute bg-white py-3 right-0 rounded-lg shadow-lg ${width} ${positionClass[position]}`}
      ref={ref}
      style={style}
    >
      <div className="flex flex-col">
        {actions.map((action, index) => {
          return (
            <span
              className={`capitalize cursor-pointer font-medium hover:bg-gray-5 px-4 py-2 text-gray-1000 text-xs ${action.className}`}
              key={`action-${index}`}
              onClick={action.onClick}
              style={action.style}
            >
              {action.label}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default ActionPopup;

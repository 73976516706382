import NoSkillsIcon from "@shared/svg/no-skill.svg?react";
import AppHeader from "@shared/ui/AppHeader";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import React from "react";
import { useHistory } from "react-router-dom";

const ExpiredSignup = () => {
  const history = useHistory();
  return (
    <div className="w-screen h-screen px-2xl">
      <AppHeader padding="py-2xl">
        <img
          src="https://workloads-alpha-makula-technology-gmbh.s3.eu-central-1.amazonaws.com/public/makula-logo-email.png"
          alt="company logo"
          className="max-h-3xl"
        />
        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          onClick={() => history.push("/app")}
          className="flex-shrink-0"
          text="Login"
        />
      </AppHeader>
      <div className="flex m-auto w-full h-full items-center justify-center flex-col">
        <NoSkillsIcon className="w-[220px] h-[220px]" />
        <div className="flex space-y-lg flex-col text-center max-w-[322px]">
          <Headline size={HEADLINE_SIZES.X_SMALL}>
            Oops! Your Link Has Expired
          </Headline>
          <BodyText size={BODY_TEXT_SIZES.SMALL} color="text-secondary">
            The sign-up link you used has expired. If you still want to join you
            can reach out for help.
          </BodyText>
        </div>
      </div>
    </div>
  );
};

export default ExpiredSignup;

export const DOCUMENT_STATUS = {
  PENDING_INDEXING: 1000,
  PENDING_SCANNING: 1001,
  SCANNING: 1002,
  PENDING_APPROVAL: 1003,
  INDEXING: 1004,
  INDEXED: 1005,
  FAILED_INDEXING: 1006,
  FAILED_SCANNING: 1007,
};

export const DOCUMENT_STATUS_COLORS = {
  [DOCUMENT_STATUS.PENDING_INDEXING]: "text-warning",
  [DOCUMENT_STATUS.PENDING_SCANNING]: "text-warning",
  [DOCUMENT_STATUS.SCANNING]: "text-brand",
  [DOCUMENT_STATUS.PENDING_APPROVAL]: "text-brown",
  [DOCUMENT_STATUS.INDEXING]: "text-brand",
  [DOCUMENT_STATUS.INDEXED]: "text-success",
  [DOCUMENT_STATUS.FAILED_INDEXING]: "text-danger",
  [DOCUMENT_STATUS.FAILED_SCANNING]: "text-danger",
};

export const DOCUMENT_SORTING_KEY = {
  NAME: "name",
  STATUS: "status",
};

import { Avatar, AvatarGroup, AVATAR_SIZES } from "@shared/ui/Avatar";
import Tooltip from "@shared/ui/Tooltip";
import React from "react";
import { useIntl } from "react-intl";

import { ASSIGNEES_AVATARS_MAX_LENGTH } from "#/src/constants";

const TicketItemAssigneesList = ({
  assignees = [],
  tooltipPlace = "top",
  maxLength = ASSIGNEES_AVATARS_MAX_LENGTH,
  className = "",
  avatarSize = AVATAR_SIZES.SMALL,
}) => {
  const { messages } = useIntl();

  if (assignees.length <= 1)
    return (
      <div className="single-assignee-user-avatar-container">
        <Avatar
          size={avatarSize}
          hasNoUser={!assignees[0]}
          name={
            assignees[0]?.name || assignees[0] || messages?.common?.noAssignee
          }
          showName
          nameClassName="no-styles whitespace-nowrap break-words max-w-[calc(100%-32px)] truncate"
          containerClassName={className || "mb-xs"}
        />
      </div>
    );

  const dataTip =
    assignees.length <= maxLength
      ? []
      : assignees
          .slice(maxLength, assignees.length)
          .map((assignee) => assignee?.name || assignee)
          .join(", ");

  return (
    <div className="u-flex">
      <AvatarGroup
        names={assignees
          .slice(0, maxLength)
          .map(
            (assignee) =>
              assignee?.name || assignee || `${messages.common?.noName}`,
          )}
        size={avatarSize}
      />
      <>
        <div
          className={`assignee-circle more-assignees-circle ${
            assignees.length > maxLength ? "flex" : "hidden"
          }`}
          data-tip={dataTip}
        >
          {`+${assignees.length - maxLength}`}
        </div>
      </>

      <Tooltip place={tooltipPlace} />
    </div>
  );
};

export default TicketItemAssigneesList;

import * as React from "react";
const SvgTabsBoardIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 16 16",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  d: "M5.167 6.5H3.333A1.333 1.333 0 0 1 2 5.167V3.333C2 2.597 2.597 2 3.333 2h1.834C5.903 2 6.5 2.597 6.5 3.333v1.834c0 .736-.597 1.333-1.333 1.333Zm7.5 0h-1.834A1.333 1.333 0 0 1 9.5 5.167V3.333C9.5 2.597 10.097 2 10.833 2h1.834C13.403 2 14 2.597 14 3.333v1.834c0 .736-.597 1.333-1.333 1.333Zm-7.5 7.5H3.333A1.333 1.333 0 0 1 2 12.667v-1.834c0-.736.597-1.333 1.333-1.333h1.834c.736 0 1.333.597 1.333 1.333v1.834C6.5 13.403 5.903 14 5.167 14Zm7.5 0h-1.834A1.333 1.333 0 0 1 9.5 12.667v-1.834c0-.736.597-1.333 1.333-1.333h1.834c.736 0 1.333.597 1.333 1.333v1.834c0 .736-.597 1.333-1.333 1.333Z",
  clipRule: "evenodd"
}));
export default SvgTabsBoardIcon;

export const sortCollection = (collection, sortBy) => {
  return collection?.sort((a, b) => {
    const makeLowercase =
      sortBy?.key !== "createdAt" && sortBy?.key !== "updatedAt";

    const valueA = !makeLowercase
      ? a[sortBy?.key]
      : a[sortBy?.key]?.toLowerCase();
    const valueB = !makeLowercase
      ? b[sortBy?.key]
      : b[sortBy?.key]?.toLowerCase();

    return valueA > valueB ? sortBy?.value : sortBy?.value * -1;
  });
};

import { debounce } from "lodash";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import { searchAtom } from "~/atoms/_search";

export default () => {
  const location = useLocation();
  const [searchOptions, setSearchOptions] = useRecoilState(searchAtom);

  const handleSearch = (e) => {
    setSearchOptions({
      ...searchOptions,
      value: e.target.value,
    });
  };

  const updateSearchTerm = (value) => {
    setSearchOptions({
      ...searchOptions,
      value: value,
    });
  };

  useEffect(() => {
    if (location.pathname !== searchOptions.scope) {
      setSearchOptions({
        ...searchOptions,
        value: "",
        scope: location.pathname,
      });
    }
  }, [location.pathname, searchOptions, setSearchOptions]);

  return {
    handleSearch,
    updateSearchTerm,
    searchValue: searchOptions?.value,
  };
};

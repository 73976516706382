import * as React from "react";
const SvgProductStore = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M10.167 17.665v0C9.246 17.665 8.5 16.92 8.5 16v0c0-.921.746-1.667 1.667-1.667v0c.92 0 1.666.746 1.666 1.667v0c0 .92-.745 1.666-1.666 1.666ZM15.999 17.665v0c-.921 0-1.667-.745-1.667-1.666v0c0-.921.746-1.667 1.667-1.667v0c.92 0 1.666.746 1.666 1.667v0c0 .92-.745 1.666-1.666 1.666ZM21.835 17.665v0c-.921 0-1.667-.745-1.667-1.666v0c0-.921.746-1.667 1.667-1.667v0c.92 0 1.666.746 1.666 1.667v0c0 .92-.745 1.666-1.666 1.666ZM10.167 23.501v0c-.921 0-1.667-.745-1.667-1.666v0c0-.921.746-1.667 1.667-1.667v0c.92 0 1.666.746 1.666 1.667v0c0 .92-.745 1.666-1.666 1.666ZM15.999 23.501v0c-.921 0-1.667-.745-1.667-1.666v0c0-.921.746-1.667 1.667-1.667v0c.92 0 1.666.746 1.666 1.667v0c0 .92-.745 1.666-1.666 1.666ZM21.835 23.501v0c-.921 0-1.667-.745-1.667-1.666v0c0-.921.746-1.667 1.667-1.667v0c.92 0 1.666.746 1.666 1.667v0c0 .92-.745 1.666-1.666 1.666ZM10.167 11.833v0c-.921 0-1.667-.745-1.667-1.666v0c0-.921.746-1.667 1.667-1.667v0c.92 0 1.666.746 1.666 1.667v0c0 .92-.745 1.666-1.666 1.666ZM15.999 11.833v0c-.921 0-1.667-.745-1.667-1.666v0c0-.921.746-1.667 1.667-1.667v0c.92 0 1.666.746 1.666 1.667v0c0 .92-.745 1.666-1.666 1.666ZM21.835 11.833v0c-.921 0-1.667-.745-1.667-1.666v0c0-.921.746-1.667 1.667-1.667v0c.92 0 1.666.746 1.666 1.667v0c0 .92-.745 1.666-1.666 1.666Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgProductStore;

import ReloadPageImage from "@shared/svg/reload-page.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import Tooltip from "@shared/ui/Tooltip";
import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";
import { Suspense, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useIntercom } from "react-use-intercom";
import { useRecoilState, useRecoilValue } from "recoil";

import { useRemoveFirstSignInRedirectUrl } from "../services";

import getEnum from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features.json";
import { imagePreviewAtom } from "~/atoms/_chat";
import { globalErrorAtom } from "~/atoms/_global";
import { appConfigAtom } from "~/atoms/appConfig";
import { sidebarAtom } from "~/atoms/sidebar";
import ConfirmationModal from "~/components/_confirmationModal";
import ImagePreview from "~/components/_imagePreview";
import { Loading } from "~/components/general";
import useAuth from "~/components/general/_use-auth";
import SplashScreen from "~/components/general/SplashScreen";
import Navigation from "~/components/header/Navigation";
import Main from "~/layout/_main";
import Routes from "~/layout/_routes";
import { useGetAppConfig } from "~/services/appConfig/_query";
import { isLogRocketEnabled } from "~/utils/_logRocket";
import { isMixpanelEnabled } from "~/utils/_mixpanel";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

let userIdentified;
const paidFeaturesRef = getEnum(PAID_FEATURES, "reference");

const Layout = (props) => {
  const { messages } = useIntl();
  const { logout, user = {}, loading } = useAuth() || {};
  const [routes, setRoutes] = useState([]);
  const [defaultRoute, setDefaultRoute] = useState(null);
  const { isErrorAlertOpen } = useRecoilValue(globalErrorAtom);
  const { update } = useIntercom();
  const sidebarExpanded = useRecoilValue(sidebarAtom);
  const [, setAppConfigState] = useRecoilState(appConfigAtom);

  const { loading: appConfigLoading, appConfig } = useGetAppConfig();
  const { removeFirstSignInRedirectUrl } = useRemoveFirstSignInRedirectUrl();

  const [imagePreviewOptions, setImagePreview] =
    useRecoilState(imagePreviewAtom);

  const closeImagePreview = () => {
    setImagePreview({
      ...imagePreviewOptions,
      isOpen: false,
    });
  };

  useEffect(() => {
    if (isLogRocketEnabled()) {
      if (user?._id && !userIdentified && user?.oem?.usageTracking?.logRocket) {
        LogRocket.init(import.meta.env.VITE_APP_LOGOROCKET_ID, {
          release: `v${import.meta.env.PACKAGE_VERSION}`,
        });
        LogRocket.identify(user?._id, {
          name: user?.name,
          email: user?.email,
          role: user?.role,
        });
        userIdentified = true;
      }
    }
  }, [user]);

  useEffect(() => {
    if (!isMixpanelEnabled() || !user?._id) return;
    mixpanel.identify(user?._id);
    mixpanel.people.set({
      $name: user?.name,
      $email: user?.email,
      role: user?.role,
      oem: user?.oem?.slug,
      oemName: user?.oem?.name,
    });
  }, [user]);

  useEffect(() => {
    if (
      !routes.length &&
      Object.keys(user || {}).length &&
      !appConfigLoading &&
      appConfig?.features
    ) {
      const routes = Routes(user, appConfig.features);
      setRoutes(routes);
      setDefaultRoute(
        user.firstSignInRedirectUrl
          ? { path: user.firstSignInRedirectUrl }
          : routes.find((route) => {
              return (
                (route.path === "/app/work/work-orders" &&
                  isPaidFeatureAvailable(
                    paidFeaturesRef.workManagement,
                    user,
                    appConfig,
                  )) ||
                route.path === "/app/assets" ||
                route.path === "/app/asset-hub/my-assets"
              );
            }),
      );

      if (user.firstSignInRedirectUrl) {
        removeFirstSignInRedirectUrl();
      }
    }
  }, [user, loading, appConfigLoading]);

  useEffect(() => {
    if (import.meta.env.VITE_APP_INTERCOM_APP_ID && user?.intercomHash) {
      update({
        customLauncherSelector: ".help-link",
        hideDefaultLauncher: true,
        userHash: user?.intercomHash,
        name: user?.name,
        email: user?.email,
        company: {
          companyId: user?.oem?.slug,
          name: user?.oem?.name,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (!appConfigLoading && appConfig?.features) {
      setAppConfigState({
        features: appConfig.features,
        plans: appConfig.plans,
      });
    }
  }, [appConfigLoading]);

  if (!routes.length || appConfigLoading || !defaultRoute)
    return <SplashScreen />;

  return (
    <div
      className={`flex new-navigation ${sidebarExpanded ? "" : "collapsed"}`}
    >
      <Navigation
        logout={logout}
        user={user}
        routes={routes}
        {...props}
        appConfigLoading={appConfigLoading}
      />
      <div className="navigation-s-overlay" />
      <Suspense fallback={<Loading />}>
        <Main
          routes={routes}
          DefaultRoute={defaultRoute}
          user={user}
          appConfig={appConfig}
          {...props}
        />
      </Suspense>
      <ConfirmationModal></ConfirmationModal>
      <ImagePreview
        intl={props.intl}
        closeModal={closeImagePreview}
        openedImage={imagePreviewOptions?.openedImage}
        isModalOpen={imagePreviewOptions?.isOpen}
      />

      {isErrorAlertOpen && (
        <AlertBox
          isOpen={isErrorAlertOpen}
          portalSelector=".error-alert-box-modal-portal"
          overlay
          image={<ReloadPageImage width="130" height="130" />}
          title={messages?.common?.reloadAppMessage}
          acceptButtonText={messages?.common?.refresh}
          onAccept={() => {
            window.location.reload();
          }}
        />
      )}
      <Tooltip
        id="navigation-hover-tooltip"
        className="!left-14"
        place="right"
      />
    </div>
  );
};

export default Layout;

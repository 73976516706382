import ThreeDIconFilled from "@shared/svg/3d-machine-active.svg?react";
import ThreeDIcon from "@shared/svg/3d-machine.svg?react";
import AiAssistantIconFilled from "@shared/svg/ai-assistant-active.svg?react";
import AiAssistantIcon from "@shared/svg/ai-assistant.svg?react";
import AIKnowledgeBaseIconFilled from "@shared/svg/ai-knowledgebase-active.svg?react";
import AIKnowledgeBaseIcon from "@shared/svg/ai-knowledgebase.svg?react";
import AiNotetakerIconFilled from "@shared/svg/ai-notetaker-active.svg?react";
import AiNotetakerIcon from "@shared/svg/ai-notetaker.svg?react";
import AIOCRIconFilled from "@shared/svg/ai-ocr-active.svg?react";
import AIOCRIcon from "@shared/svg/ai-ocr.svg?react";
import AISearchIconFilled from "@shared/svg/ai-search-active.svg?react";
import AISearchIcon from "@shared/svg/ai-search.svg?react";
import AnalyticsIconFilled from "@shared/svg/analytics-sub-menu-active.svg?react";
import AnalyticsIcon from "@shared/svg/analytics-sub-menu.svg?react";
import CalendarBlankFilled from "@shared/svg/calendar-blank-filled.svg?react";
import CalendarBlank from "@shared/svg/calendar-blank.svg?react";
import DocumentIconFilled from "@shared/svg/documents-filled.svg?react";
import DocumentIcon from "@shared/svg/documents.svg?react";
import EmailIconFilled from "@shared/svg/email-filled.svg?react";
import EmailIcon from "@shared/svg/email.svg?react";
import FacilityIconFilled from "@shared/svg/facilities-sub-menu-active.svg?react";
import FacilityIcon from "@shared/svg/facilities-sub-menu.svg?react";
import FolderIconFilled from "@shared/svg/folder-filled.svg?react";
import FolderIcon from "@shared/svg/folder.svg?react";
import GuidesIconFilled from "@shared/svg/guides-active.svg?react";
import GuidesIcon from "@shared/svg/guides.svg?react";
import HourGlassIconFilled from "@shared/svg/hour-glass-active.svg?react";
import HourGlassIcon from "@shared/svg/hour-glass.svg?react";
import InventoryIconFilled from "@shared/svg/inventory-filled.svg?react";
import InventoryIcon from "@shared/svg/inventory.svg?react";
import MachineTemplatesIconFilled from "@shared/svg/machine-templates-active.svg?react";
import MachineTemplatesIcon from "@shared/svg/machine-templates.svg?react";
import MachineIconFilled from "@shared/svg/machines-filled.svg?react";
import MachineIcon from "@shared/svg/machines.svg?react";
import ProcedureIconFilled from "@shared/svg/procedures-filled.svg?react";
import ProcedureIcon from "@shared/svg/procedures.svg?react";
import RequestsIconFilled from "@shared/svg/requests-active.svg?react";
import RequestsIcon from "@shared/svg/requests.svg?react";
import SharedAssetsIconFilled from "@shared/svg/shared-assets-active.svg?react";
import SharedAssetsIcon from "@shared/svg/shared-assets.svg?react";
import { getAllRoles } from "@shared/utils/getAllRoles";
import { useIntl } from "react-intl";

import APP_FEATURES from "$/settings/app-features.json";
import PAID_FEATURES from "$/settings/paid-features.json";
import { isOemAdmin } from "~/hooks/_useRoleManager";
import { AppConfigType } from "~/types/appConfig";
import type { SubRouteType } from "~/types/route";
import type { UserType } from "~/types/user";
import { getEnums } from "~/utils";
import { useGetAppConfigRecoilValue } from "~/utils/appFeatures";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const paidFeatures = getEnums(PAID_FEATURES, "reference");
const AppFeatures = getEnums(APP_FEATURES, "reference");

const ALL_ROLES = getAllRoles({
  getOEM: true,
  getCRM: false,
  getFacility: false,
});

export const ROUTE_TO_KEY_MAP: { [key: string]: string } = {
  "/app/work/work-orders": "cmms",
  "/app/assets": "machines",
  "/app/asset-hub": "asset-hub",
  "/app/3d/models": "3d",
  "/app/ai": "industrial-ai",
};

export const ROUTE_KEY_TO_NOTIFICATION_MAP: { [key: string]: string[] } = {
  cmms: ["openTickets", "myTickets"],
  assetHub: ["requests"],
};

export const SUB_ROUTES: {
  [key: string]: {
    header: string;
    enableIf: (appFeatures: string[]) => boolean;
    routes: SubRouteType[];
  };
} = {
  "/app/work/work-orders": {
    enableIf: () => true,
    header: "",
    routes: [
      {
        route: "/app/work/work-orders",
        icon: FolderIcon,
        enableIf: () => true,
        iconActive: FolderIconFilled,
        titleKey: "WORK_ORDERS",
        routeKey: "openTickets",
        title: "Work Orders",
      },
      {
        route: "/app/work/my-work-orders",
        icon: DocumentIcon,
        enableIf: () => true,
        iconActive: DocumentIconFilled,
        titleKey: "MY_WORK_ORDERS",
        routeKey: "myTickets",
        title: "My Work Orders",
      },
      {
        route: "/app/work/scheduler",
        icon: CalendarBlank,
        enableIf: (user: UserType) => user.role !== ALL_ROLES.TECHNICIAN.VALUE,
        iconActive: CalendarBlankFilled,
        titleKey: "SCHEDULING",
        title: "Scheduler",
      },
      {
        route: "/app/work/planned-events",
        icon: HourGlassIcon,
        enableIf: () => true,
        iconActive: HourGlassIconFilled,
        titleKey: "PLANNED_EVENTS",
        routeKey: "myPlannedEvents",
        title: "Planned Events",
      },
      {
        route: "/app/work/procedures",
        icon: ProcedureIcon,
        enableIf: () => true,
        iconActive: ProcedureIconFilled,
        titleKey: "PROCEDURES",
        title: "Procedures",
      },
      {
        route: "/app/work/emails",
        icon: EmailIcon,
        enableIf: (user: UserType) => user.role !== ALL_ROLES.TECHNICIAN.VALUE,
        iconActive: EmailIconFilled,
        titleKey: "EMAILS",
        title: "Emails",
      },
      {
        route: "/app/work/analytics",
        icon: AnalyticsIcon,
        enableIf: (user: UserType, appFeatures: string[]) =>
          isOemAdmin(user?.role) &&
          appFeatures?.includes(AppFeatures.growthLoop),
        iconActive: AnalyticsIconFilled,
        titleKey: "ANALYTICS",
        title: "Analytics",
      },
    ],
  },
  "/app/assets": {
    header: "",
    enableIf: (appFeatures) => !appFeatures?.includes(AppFeatures.growthLoop),
    routes: [
      {
        route: "/app/assets/all",
        icon: MachineIcon,
        enableIf: () => true,
        iconActive: MachineIconFilled,
        titleKey: "ASSETS",
        title: "Assets",
      },
      {
        route: "/app/assets/templates",
        icon: MachineTemplatesIcon,
        enableIf: () => true,
        iconActive: MachineTemplatesIconFilled,
        titleKey: "ASSET_TEMPLATES",
        title: "Asset Templates",
      },
      {
        route: "/app/assets/inventory",
        icon: InventoryIcon,
        enableIf: () => true,
        iconActive: InventoryIconFilled,
        titleKey: "INVENTORY",
        title: "Inventory",
      },
    ],
  },
  "/app/asset-hub": {
    header: "",
    enableIf: (appFeatures) => appFeatures?.includes(AppFeatures.growthLoop),
    routes: [
      {
        route: "/app/asset-hub/my-assets",
        icon: MachineIcon,
        enableIf: () => true,
        iconActive: MachineIconFilled,
        titleKey: "OWN_ASSETS",
        title: "My Assets",
      },
      {
        route: "/app/asset-hub/shared-assets",
        icon: SharedAssetsIcon,
        enableIf: () => true,
        iconActive: SharedAssetsIconFilled,
        titleKey: "SHARED_ASSETS",
        title: "Shared Assets",
      },
      {
        route: "/app/asset-hub/requests",
        icon: RequestsIcon,
        enableIf: () => true,
        iconActive: RequestsIconFilled,
        titleKey: "REQUESTS",
        title: "Requests",
        routeKey: "requests",
      },
      {
        route: "/app/asset-hub/connections",
        icon: FacilityIcon,
        enableIf: () => true,
        iconActive: FacilityIconFilled,
        titleKey: "CONNECTIONS",
        title: "Connections",
      },
      {
        route: "/app/asset-hub/inventory",
        icon: InventoryIcon,
        enableIf: () => true,
        iconActive: InventoryIconFilled,
        titleKey: "INVENTORY",
        title: "Inventory",
      },
      {
        route: "/app/asset-hub/asset-templates",
        icon: MachineTemplatesIcon,
        enableIf: () => true,
        iconActive: MachineTemplatesIconFilled,
        titleKey: "ASSET_TEMPLATES",
        title: "Asset Templates",
      },
    ],
  },
  "/app/3d/models": {
    header: "",
    enableIf: () => true,
    routes: [
      {
        route: "/app/3d/models",
        icon: ThreeDIcon,
        enableIf: () => true,
        iconActive: ThreeDIconFilled,
        titleKey: "MODELS",
        title: "Models",
      },
      {
        route: "/app/3d/guides",
        icon: GuidesIcon,
        enableIf: () => true,
        iconActive: GuidesIconFilled,
        titleKey: "GUIDES",
        title: "Guides",
      },
    ],
  },
  "/app/ai": {
    header: "",
    enableIf: () => true,
    routes: [
      {
        route: "/app/ai/assistant",
        icon: AiAssistantIcon,
        enableIf: () => true,
        iconActive: AiAssistantIconFilled,
        titleKey: "AI_ASSISTANT",
        title: "AI Assistant",
      },
      {
        route: "/app/ai/notetaker",
        icon: AiNotetakerIcon,
        enableIf: () => true,
        iconActive: AiNotetakerIconFilled,
        titleKey: "AI_NOTETAKER",
        title: "AI Notetaker",
      },
      {
        route: "/app/ai/search",
        icon: AISearchIcon,
        enableIf: (user: UserType, _, appConfig: AppConfigType) =>
          isPaidFeatureAvailable(paidFeatures.aiSearch, user, appConfig),
        iconActive: AISearchIconFilled,
        titleKey: "AI_SEARCH",
        title: "AI Search",
      },
      {
        route: "/app/ai/knowledge-base",
        icon: AIKnowledgeBaseIcon,
        enableIf: (user: UserType, _, appConfig: AppConfigType) =>
          isPaidFeatureAvailable(paidFeatures.aiSearch, user, appConfig),
        iconActive: AIKnowledgeBaseIconFilled,
        titleKey: "KNOWLEDGE_BASE",
        title: "Knowledge Base",
      },
      {
        route: "/app/ai/ocr",
        icon: AIOCRIcon,
        enableIf: (user: UserType, _, appConfig: AppConfigType) =>
          isPaidFeatureAvailable(paidFeatures.aiSearch, user, appConfig),
        iconActive: AIOCRIconFilled,
        titleKey: "AI_OCR",
        title: "AI OCR",
      },
    ],
  },
};

export const routeHasSubRoutes = (routeKey: string) => routeKey in SUB_ROUTES;

export const useSubRoutes = (
  routeKey: string,
  user?: UserType,
  appFeatures?: string[],
) => {
  const { messages } = useIntl();
  const appConfig = useGetAppConfigRecoilValue();

  const subRoutes = SUB_ROUTES[routeKey];
  if (subRoutes) {
    const subMenus = messages?.menus?.[routeKey]?.SUB_MENUS;
    return {
      ...subRoutes,
      routes: subRoutes.routes
        .map((subRoute: SubRouteType) => ({
          ...subRoute,
          title: subMenus?.[subRoute.titleKey] || subRoute.title,
        }))
        .filter((subRoute) => subRoute.enableIf(user, appFeatures, appConfig)),
    };
  }
  return subRoutes;
};

import { left } from "@popperjs/core";
import { PRODUCT_TYPES } from "@shared/constants/paidFeaturesHierarchy";
import ThreeDIconActive from "@shared/svg/3d-active.svg?react";
import AssetHubIconActive from "@shared/svg/asset-hub-active.svg?react";
import CMMSIconActive from "@shared/svg/cmms-active.svg?react";
import FieldServiceIconActive from "@shared/svg/field-service-active.svg?react";
import IndustrialAiIconActive from "@shared/svg/industrial-ai-active.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Drawer from "@shared/ui/Drawer";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import React from "react";
import { MessageFormatElement, useIntl } from "react-intl";

import { useGetAppConfigRecoilValue } from "#/src/utils/appFeatures";
import ContactSalesModal from "~/components/ContactSalesModal";
import { useAuth } from "~/components/general";
import {
  INITIATE_CONTACT_SALES_3D_STREAMS,
  INITIATE_CONTACT_SALES_ASSET_HUB,
  INITIATE_CONTACT_SALES_CMMS,
  INITIATE_CONTACT_SALES_FIELD_SERVICE,
  INITIATE_CONTACT_SALES_INDUSTRIAL_AI,
  VIEW_PRODUCT_STORE,
} from "~/constants";
import type { AppConfigType } from "~/types/appConfig";
import type { UserType } from "~/types/user";
import { registerMixpanelEvent } from "~/utils/_mixpanel";

export const getProducts = (
  messages: Record<string, string> | Record<string, MessageFormatElement[]>,
  user?: UserType,
  appConfig?: AppConfigType,
) => {
  const productStoreMenuMessages = messages?.menus?.["productStore"];
  const installedProducts = (user?.oem?.installedProducts ?? []).map(
    (product) => product.type,
  );

  const conflictingPurchases = {};

  appConfig?.plans.forEach((plan) => {
    conflictingPurchases[plan.type] = plan.conflictingPurchases;
  });

  const ALL_PRODUCTS = [
    {
      icon: AssetHubIconActive,
      title: productStoreMenuMessages?.assetHub?.title,
      type: PRODUCT_TYPES.ASSET_HUB,
      subTitle: productStoreMenuMessages?.assetHub?.subTitle,
    },
    {
      icon: IndustrialAiIconActive,
      title: productStoreMenuMessages?.ai?.title,
      type: PRODUCT_TYPES.INDUSTRIAL_AI,
      subTitle: productStoreMenuMessages?.ai?.subTitle,
    },
    {
      icon: ThreeDIconActive,
      title: productStoreMenuMessages?.["3d"]?.title,
      type: PRODUCT_TYPES.THREE_D,
      subTitle: productStoreMenuMessages?.["3d"]?.subTitle,
    },
    {
      icon: CMMSIconActive,
      title: productStoreMenuMessages?.cmms?.title,
      type: PRODUCT_TYPES.CMMS,
      subTitle: productStoreMenuMessages?.cmms?.subTitle,
    },
    {
      icon: FieldServiceIconActive,
      title: productStoreMenuMessages?.["field-service"]?.title,
      type: PRODUCT_TYPES.FIELD_SERVICE,
      subTitle: productStoreMenuMessages?.["field-service"]?.subTitle,
    },
  ];

  const SANITIZED_PRODUCTS = ALL_PRODUCTS;

  installedProducts.forEach((product) => {
    if (conflictingPurchases[product]) {
      SANITIZED_PRODUCTS.forEach((prod) => {
        if (conflictingPurchases[product].includes(prod.type)) {
          SANITIZED_PRODUCTS.splice(SANITIZED_PRODUCTS.indexOf(prod), 1);
        }
      });
    }
  });

  const products = {
    installed: SANITIZED_PRODUCTS.filter((product) =>
      installedProducts.includes(product.type),
    ),
    notInstalled: SANITIZED_PRODUCTS.filter(
      (product) => !installedProducts.includes(product.type),
    ),
    available: SANITIZED_PRODUCTS,
  };
  return products;
};

const ProductStoreMenu = ({ onClose }: { onClose: () => void }) => {
  const { messages } = useIntl();
  const productStoreMenuMessages = messages?.menus?.["productStore"];
  const [showContactSalesModal, setShowContactSalesModal] = React.useState("");
  const [showMenu, setShowMenu] = React.useState(true);
  const { user } = useAuth() as { user: UserType };
  const appConfig = useGetAppConfigRecoilValue();
  const products = getProducts(messages, user, appConfig);

  React.useEffect(() => {
    registerMixpanelEvent(VIEW_PRODUCT_STORE);
  }, []);

  return (
    <>
      <Drawer
        title={productStoreMenuMessages.title}
        isOpen={showMenu}
        onClose={onClose}
        hideFooter
        position={left}
      >
        <div className="flex flex-col space-y-lg">
          <div className="flex flex-col space-y-2xs">
            <Label size={LABEL_SIZES.MEDIUM}>
              {productStoreMenuMessages.installedProducts}
            </Label>
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
              {productStoreMenuMessages.installedProductsMessage.replace(
                "{}",
                products.installed.length,
              )}
            </BodyText>
          </div>
          <div className="space-y-lg">
            {products.installed.map((product) => (
              <div className="flex items-center space-x-sm">
                <div className="flex items-center space-x-sm">
                  <product.icon className="w-4xl h-4xl" />
                  <div className="flex flex-col space-y-3xs">
                    <Label size={LABEL_SIZES.MEDIUM}>{product.title}</Label>
                    <BodyText
                      size={BODY_TEXT_SIZES.X_SMALL}
                      color="text-secondary"
                    >
                      {product.subTitle}
                    </BodyText>
                  </div>
                </div>
              </div>
            ))}
            {!!products.notInstalled.length && (
              <>
                <Label size={LABEL_SIZES.MEDIUM} className="!mt-3xl">
                  {productStoreMenuMessages.other}
                </Label>
                {products.notInstalled.map((product) => (
                  <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-sm">
                      <div className="flex items-center space-x-sm">
                        <product.icon className="w-4xl h-4xl" />
                        <div className="flex flex-col space-y-3xs">
                          <Label size={LABEL_SIZES.MEDIUM}>
                            {product.title}
                          </Label>
                          <BodyText
                            size={BODY_TEXT_SIZES.X_SMALL}
                            color="text-secondary"
                          >
                            {product.subTitle}
                          </BodyText>
                        </div>
                      </div>
                    </div>
                    <Button
                      variant={BUTTON_VARIANTS.SECONDARY}
                      text={productStoreMenuMessages.contactSales}
                      onClick={() => {
                        setShowContactSalesModal(product.type);
                        setShowMenu(false);
                        switch (product.type) {
                          case PRODUCT_TYPES.THREE_D:
                            registerMixpanelEvent(
                              INITIATE_CONTACT_SALES_3D_STREAMS,
                            );
                            break;
                          case PRODUCT_TYPES.CMMS:
                            registerMixpanelEvent(INITIATE_CONTACT_SALES_CMMS);
                            break;
                          case PRODUCT_TYPES.FIELD_SERVICE:
                            registerMixpanelEvent(
                              INITIATE_CONTACT_SALES_FIELD_SERVICE,
                            );
                            break;
                          case PRODUCT_TYPES.INDUSTRIAL_AI:
                            registerMixpanelEvent(
                              INITIATE_CONTACT_SALES_INDUSTRIAL_AI,
                            );
                            break;
                          case PRODUCT_TYPES.ASSET_HUB:
                            registerMixpanelEvent(
                              INITIATE_CONTACT_SALES_ASSET_HUB,
                            );
                            break;
                          default:
                            break;
                        }
                      }}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </Drawer>
      {showContactSalesModal && (
        <ContactSalesModal
          onClose={(showMenu = true) => {
            setShowContactSalesModal("");
            setShowMenu(!!showMenu);
          }}
          productType={showContactSalesModal}
        />
      )}
    </>
  );
};

export default ProductStoreMenu;

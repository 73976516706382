import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { mapColorsFromPalette } from "@shared/utils/mapColorsFromPalette";
import { kebabCase } from "lodash";
import React, { useMemo } from "react";

import SortableCard from "./card/_sortableCard";

import { KanbanCardLoaderComponent } from "#/src/components/skeletonLoaders/_kanbanCardLoader";
import getEnum from "$/settings/enums";
import BoardTypes from "$/settings/enums/kanban/_type.json";
import { getRandomEntry, sortCollection } from "~/utils";

const boardTypesEnum = getEnum(BoardTypes, "reference");
const KANBAN_BOARD_BACKGROUND = mapColorsFromPalette("primary", "lighter");

const KanbanColumn = ({
  column,
  fieldColor,
  sortingValue,
  boardType,
  isLoading,
  isTeamsPaid,
  isAssetBoard = false,
}) => {
  const { isOver, setNodeRef } = useDroppable({
    id: column._id,
    data: {
      accepts: [column?.boardFor],
      columnValue:
        column.type === boardTypesEnum.status
          ? column.status?._id
          : column.column,
      columnCardsLength: column?.cards?.length,
    },
  });

  const columnsDataIds = useMemo(
    () => column?.cards?.map((card) => card?._id),
    [column?.cards],
  );

  const sortedCards = useMemo(
    () => sortCollection(column?.cards, sortingValue),
    [boardType, sortingValue, column?.cards],
  );

  const kanbanBoardColor = useMemo(
    () => getRandomEntry(KANBAN_BOARD_BACKGROUND),
    [],
  );

  return (
    <SortableContext
      id={column._id}
      items={columnsDataIds}
      strategy={verticalListSortingStrategy}
    >
      <div className="kanban-column">
        <div
          className={`kanban-droppable-container ${isOver ? "hovered" : ""}`}
          style={{
            background: fieldColor
              ? KANBAN_BOARD_BACKGROUND[fieldColor?.color?.toLowerCase()]
              : isAssetBoard
              ? kanbanBoardColor[Object.keys(kanbanBoardColor)[0]]
              : KANBAN_BOARD_BACKGROUND[column?.status?.color?.toLowerCase()],
          }}
        >
          <div
            className={`kanban-column-title ${
              column.type === boardTypesEnum.status ? "status" : ""
            }`}
          >
            <button
              className={`status-bar ${kebabCase(column.column)} break-all`}
              style={
                column.type === boardTypesEnum.status
                  ? {
                      backgroundColor: column?.status?.color,
                    }
                  : column.type === boardTypesEnum.assetType
                  ? {
                      backgroundColor: Object.keys(kanbanBoardColor)[0],
                    }
                  : { backgroundColor: fieldColor?.color }
              }
            >
              {column.column}
              <span>({column?.totalCount ?? column?.cards?.length ?? 0})</span>
            </button>
          </div>

          <div className="kanban-droppable-content" ref={setNodeRef}>
            <div className="kanban-draggables-container">
              {sortedCards?.map((item, index) => {
                return (
                  <div key={item?._id || index}>
                    <SortableCard
                      item={item}
                      column={column}
                      boardType={column?.boardFor}
                      index={index}
                      isTeamsPaid={isTeamsPaid}
                    />
                  </div>
                );
              })}
              {isLoading && <KanbanCardLoaderComponent total={1} />}
            </div>
          </div>
        </div>
      </div>
    </SortableContext>
  );
};

export default React.memo(KanbanColumn);

import PAID_FEATURES from "$/settings/paid-features.json";
import { APP_FEATURES_ENUM } from "~/constants";
import { isOemAdmin } from "~/hooks/_useRoleManager";
import type { AppConfigType } from "~/types/appConfig";
import type { SubSettingRouteType } from "~/types/route";
import type { UserType } from "~/types/user";
import { getEnums } from "~/utils";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

export const settings: {
  titleKey: string;
  enableIf: (user?: UserType, appConfig?: AppConfigType) => boolean;
  subSettings: SubSettingRouteType[];
}[] = [
  {
    titleKey: "general",
    enableIf: (user: UserType) => isOemAdmin(user.role),
    subSettings: [
      {
        titleKey: "branding",
        enableIf: (_, appConfig) =>
          !appConfig?.features?.includes(APP_FEATURES_ENUM.growthLoop),
        route: "/app/settings/company/branding",
      },
      {
        titleKey: "companyInfo",
        enableIf: () => true,
        route: "/app/settings/company/general",
      },
      {
        titleKey: "assetTypes",
        enableIf: () => true,
        route: "/app/settings/company/asset-types",
      },
      {
        titleKey: "fields",
        enableIf: () => true,
        route: "/app/settings/company/customize-fields",
      },
      {
        titleKey: "dataManagement",
        enableIf: () => true,
        route: "/app/settings/company/data-management",
      },
      {
        titleKey: "plans",
        enableIf: (_: UserType, appConfig: AppConfigType) =>
          appConfig?.features?.includes(APP_FEATURES_ENUM.growthLoop),
        route: "/app/settings/company/plans",
      },
      {
        titleKey: "userAccounts",
        enableIf: () => true,
        route: "/app/settings/company/users/accounts",
      },
    ],
  },
  {
    titleKey: "workManagement",
    enableIf: (user: UserType, appConfig: AppConfigType) =>
      isOemAdmin(user.role) &&
      isPaidFeatureAvailable(paidFeatures.workManagement, user, appConfig),
    subSettings: [
      {
        titleKey: "accessPermissions",
        enableIf: () => true,
        route: "/app/settings/company/access-permissions",
      },
      {
        titleKey: "emailConfiguration",
        enableIf: () => true,
        route: "/app/settings/company/email-configuration",
      },
      {
        titleKey: "notifications",
        enableIf: () => true,
        route: "/app/settings/company/notifications",
      },
      {
        titleKey: "resourceManagement",
        enableIf: (user: UserType, appConfig: AppConfigType) =>
          isPaidFeatureAvailable(paidFeatures.scheduler, user, appConfig),
        route: "/app/settings/company/resource-management",
      },
      {
        titleKey: "timeTracker",
        enableIf: () => true,
        route: "/app/settings/company/time-tracker",
      },
      {
        titleKey: "statusCustomization",
        enableIf: () => true,
        route: "/app/settings/company/status-customization",
      },
      {
        titleKey: "workOrderCustomization",
        enableIf: () => true,
        route: "/app/settings/company/work-order-customization",
      },
    ],
  },
  {
    titleKey: "personalTitle",
    enableIf: () => true,
    subSettings: [
      {
        titleKey: "calendarSync",
        enableIf: () => true,
        route: "/app/settings/personal/calendar-sync",
      },
      {
        titleKey: "emailSignature",
        enableIf: (user: UserType, appConfig: AppConfigType) =>
          isPaidFeatureAvailable(paidFeatures.emails, user, appConfig),
        route: "/app/settings/personal/email-signature",
      },
      {
        titleKey: "general",
        enableIf: () => true,
        route: "/app/settings/personal/general",
      },
      {
        titleKey: "notifications",
        enableIf: (user: UserType, appConfig: AppConfigType) =>
          isPaidFeatureAvailable(paidFeatures.workManagement, user, appConfig),
        route: "/app/settings/personal/notifications",
      },
    ],
  },
];

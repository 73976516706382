import CalendarIcon from "@shared/svg/calendar-filled.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { ExternalIcon, EXTERNAL_ICON_SIZES } from "@shared/ui/Indicators";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import Tag from "@shared/ui/Tag";
import moment from "moment-timezone";
import { CalendarCheck, FolderOpen, Wrench } from "phosphor-react";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import TicketItemAssigneesList from "./ticketItemAssigneesList";
import WorkOrderTypeIcons from "./WorkOrderTypeIcons";
import { COLOR } from "../../constants/colors";
import useCheckPaidFeatureAvailable from "../../hooks/_useCheckPaidFeatureAvailable";
import { SidebarProceduresIcon } from "../../icons/icons";
import { useAuth } from "../general";

import { generateStatusBackgroundColor } from "#/src/utils/_colors";
import PAID_FEATURES from "$/settings/paid-features.json";
import { HourglassIcon, PDFIcon } from "~/icons/icons";
import { getEnums } from "~/utils";

const PaidFeatureEnums = getEnums(PAID_FEATURES, "reference");

export const TicketIcons = {
  ServiceRequest: <FolderOpen size={14} className="u-text-color-blue-v2-100" />,
  SparePartOrder: <Wrench size={14} />,
  PreventiveMaintenance: <HourglassIcon />,
  FormSubmission: <PDFIcon />,
  Procedure: <SidebarProceduresIcon strokeColor={COLOR.$red_v2_50} />,
};

const TicketCard = ({
  ticket,
  showStatus = false,
  showScheduledIcon = false,
}) => {
  const { messages } = useIntl();
  const { user } = useAuth();

  const status = useMemo(() => {
    if (!user?.oem?.statuses) return {};

    return user.oem.statuses.find((item) => item._id === ticket?.status);
  }, [user, ticket?._id]);

  const isScheduled = useMemo(() => !!ticket?.schedule, [ticket?._id]);
  const totalProcedures = useMemo(() => (ticket?.procedures || [])?.length);

  const iconWrapperStyles = useMemo(
    () => ({
      backgroundColor: generateStatusBackgroundColor(ticket?.ticketType?.color),
    }),
    [ticket?.ticketType?.color],
  );

  const hasProcedureFeature = useCheckPaidFeatureAvailable(
    PaidFeatureEnums.procedures,
  );

  const scheduledDateText = ticket?.schedule?.startTime
    ? moment(ticket?.schedule?.startTime).format("YYYY-MM-DD")
    : "";

  return (
    <Link
      to={`/app/work/work-orders/${ticket?._id}`}
      className="card-ticket-container"
    >
      <div className="card-ticket-content">
        <div className="ticket-title-wrapper">
          <div className="ticket-title">
            <span
              className={`icon ${ticket?.ticketType}`}
              style={iconWrapperStyles}
              onMouseEnter={() => {
                ReactTooltip.rebuild();
              }}
              data-tip={
                ticket?.ticketType?.isInternal
                  ? ""
                  : messages?.workOrderTypes?.externalWorkOrderTooltip
              }
              data-for="global-tooltip"
              data-event="mouseenter"
              data-event-off="mouseleave"
            >
              <WorkOrderTypeIcons
                icon={ticket?.ticketType?.icon}
                strokeColor={ticket?.ticketType?.color}
                size={16}
              />

              {ticket?.ticketType.isInternal ? null : (
                <ExternalIcon
                  size={EXTERNAL_ICON_SIZES.SMALL}
                  className="absolute top-0 -right-3xs"
                />
              )}
            </span>
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
              {ticket?.ticketId}
            </BodyText>
          </div>
          <div className="card-ticket-title-icons">
            {hasProcedureFeature && (
              <div className="procedure-count">
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                  {totalProcedures}
                </BodyText>
                <SidebarProceduresIcon width={20} />
              </div>
            )}
            {showScheduledIcon && isScheduled && (
              <div className="schedule-indicator">
                <CalendarCheck size={20} />
              </div>
            )}
          </div>
        </div>

        <Label size={LABEL_SIZES.MEDIUM}>{ticket?.title}</Label>
        <div className="ticket-meta">
          {ticket?.facility?.name && (
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
              {ticket?.facility?.name}&nbsp;&nbsp;
            </BodyText>
          )}
          {ticket?.machine?.name && (
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
              &#8226;&nbsp;&nbsp;{ticket?.machine?.name}&nbsp;&nbsp;
            </BodyText>
          )}
          {ticket?.machine?.serialNumber && (
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
              &#8226;&nbsp;&nbsp;{ticket?.machine?.serialNumber}
            </BodyText>
          )}
        </div>

        <div className="u-flex u-justify-between u-items-center">
          <div className="ticket-assignee u-layer-0">
            <TicketItemAssigneesList assignees={ticket?.assignees} />
          </div>
          {showStatus && (
            <div className="ticket-status-comment">
              <Tag
                label={status?.label}
                style={{
                  color: status?.color,
                  backgroundColor: generateStatusBackgroundColor(status?.color),
                }}
              />
            </div>
          )}
          {scheduledDateText && (
            <span
              className={`icon ${ticket?.ticketType}`}
              onMouseEnter={() => {
                ReactTooltip.rebuild();
              }}
              data-tip={
                messages?.common?.scheduleDate + ": " + scheduledDateText
              }
              data-for="global-tooltip"
              data-event="mouseenter"
              data-event-off="mouseleave"
            >
              <div className="flex items-center group">
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-tertiary"
                  className="no-styles font-semibold bg-warning-lightest text-warning whitespace-nowrap py-2xs px-xs rounded-md flex items-center group-hover:bg-warning group-hover:text-white"
                >
                  <span className="mr-2xs leading-none">
                    <CalendarIcon className="stroke-current" />
                  </span>
                  {scheduledDateText}
                </BodyText>
              </div>
            </span>
          )}
        </div>
      </div>
    </Link>
  );
};

export default TicketCard;

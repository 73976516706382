import * as React from "react";
const SvgIndustrialAi = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M10.999 8.496a3.335 3.335 0 0 1-3.335 3.335A3.335 3.335 0 0 1 11 15.166a3.335 3.335 0 0 1 3.335-3.335A3.335 3.335 0 0 1 11 8.496ZM20.168 18.5A4.168 4.168 0 0 0 16 14.333a4.168 4.168 0 0 0 4.168-4.168 4.168 4.168 0 0 0 4.169 4.168 4.168 4.168 0 0 0-4.169 4.169Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20.167 18.5v.834m0-9.168v-.834m-5.832 14.172v.834m0-6.672v-.834m-3.336-1.664v.834m0-8.338v.834",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M14.335 17.25A3.335 3.335 0 0 1 11 20.585a3.335 3.335 0 0 1 3.335 3.334 3.335 3.335 0 0 1 3.334-3.334 3.335 3.335 0 0 1-3.334-3.335Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgIndustrialAi;

import * as React from "react";
const SvgAnalyticsSubMenu = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 22 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M3.882 12.155c.66.65.66 1.706 0 2.357-.66.65-1.728.65-2.388 0a1.652 1.652 0 0 1 0-2.357c.66-.651 1.729-.651 2.388 0",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M12.113 13.333c0-.92.756-1.667 1.688-1.667.933 0 1.688.746 1.688 1.667S14.734 15 13.801 15a1.67 1.67 0 0 1-1.688-1.653v-.014Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.483 5.488c.66.651.66 1.706 0 2.357-.659.651-1.728.651-2.387 0a1.652 1.652 0 0 1 0-2.357c.66-.65 1.728-.65 2.387 0M20.507 5.488c.66.651.66 1.706 0 2.357-.66.651-1.728.651-2.388 0a1.652 1.652 0 0 1 0-2.357c.66-.65 1.729-.65 2.388 0M18.179 8.098l-3.011 3.576M4.047 11.87 7.182 8.12M12.698 11.674 9.656 8.12",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgAnalyticsSubMenu;

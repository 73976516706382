import { CaretLeft } from "phosphor-react";
import React from "react";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";

export const PartDetailModalHeader = ({ title, onBack }) => {
  return (
    <div className="u-flex u-items-center">
      <button
        className="btn-v2 ghost-btn-v2 part-modal-preview-header-back-btn u-margin-r-2"
        onClick={onBack}
      >
        <CaretLeft size={16} />
      </button>
      <Headline size={HEADLINE_SIZES.X_SMALL}>{title}</Headline>
    </div>
  );
};

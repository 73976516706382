import * as React from "react";
const SvgAi = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M16.804 19a.558.558 0 0 1-.533-.395l-.227-.737a.167.167 0 0 0-.159-.118h-2.012a.167.167 0 0 0-.16.118l-.225.738a.558.558 0 0 1-1.068-.327l1.433-4.69c.107-.35.43-.589.797-.589h.458c.367 0 .69.24.797.59l1.433 4.689a.558.558 0 0 1-.534.721Zm-2.672-2.523a.167.167 0 0 0 .16.215h1.165a.167.167 0 0 0 .16-.216l-.59-1.9a.167.167 0 0 0-.318.002l-.577 1.899ZM18.955 19a.567.567 0 0 1-.566-.567v-4.866a.567.567 0 1 1 1.133 0v4.866a.567.567 0 0 1-.567.567Z",
  fill: "currentColor"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M23.503 16A7.503 7.503 0 1 1 16 8.497",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M21.836 12.665a2.501 2.501 0 0 1 2.5-2.5 2.501 2.501 0 0 1-2.5-2.502 2.501 2.501 0 0 1-2.501 2.501 2.501 2.501 0 0 1 2.5 2.501Z",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgAi;

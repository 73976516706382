import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { NOTIFICATION_IDENTIFIERS } from "#/src/constants/notification-identifiers";
import {
  APPROVE_OCR_DOCUMENTS_MUTATION,
  CREATE_DOCUMENTS_MUTATION,
  DELETE_DOCUMENTS_MUTATION,
  GET_DOCUMENT_BY_ID_QUERY,
  INDEX_DOCUMENTS_MUTATION,
  LIST_DOCUMENTS_QUERY,
  QUERY_DOCUMENTS_QUERY,
  SCAN_DOCUMENTS_MUTATION,
  UPDATE_DOCUMENT_MUTATION,
} from "~/api";
import { Document as DocumentFragment } from "~/api/_fragments";
import client from "~/apollo/_client";
import { ITEMS_PER_PAGE } from "~/constants";
import { DOCUMENT_STATUS } from "~/constants/knowledgebase";
import {
  APPROVE_OCR_DOCUMENTS,
  CREATE_DOCUMENTS,
  DELETE_DOCUMENTS,
  GET_DOCUMENT_BY_ID,
  INDEX_DOCUMENTS,
  LIST_DOCUMENTS,
  QUERY_DOCUMENTS,
  SCAN_DOCUMENTS,
  UPDATE_DOCUMENT,
} from "~/services/document/constants";
import type { Document, SearchResult } from "~/types/document";

export const useCreateDocuments = () => {
  const [create, { error, loading }] = useMutation<
    { [CREATE_DOCUMENTS]: string },
    {
      input: {
        documents: { title: string; size: string; documentId: string }[];
        runOCR?: boolean;
      };
    }
  >(CREATE_DOCUMENTS_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_DOCUMENTS,
      });
      cache.gc();
    },
  });

  return {
    createDocuments: create,
    error,
    loading,
  };
};

export const useListDocuments = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery = "",
  skipCondition = false,
  ocrDocuments,
  sort = [],
}: {
  limit?: number;
  skip?: number;
  searchQuery?: string;
  skipCondition?: boolean;
  ocrDocuments?: boolean;
  sort?: string[];
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      sort,
      where: {
        ocrDocuments,
      },
    },
  };

  const { data, loading, refetch, fetchMore } = useQuery<
    {
      [LIST_DOCUMENTS]: {
        currentPage: number;
        limit: number;
        skip: number;
        sort: string[];
        totalCount: number;
        documents: Document[];
      };
    },
    {
      params: {
        limit: number;
        skip: number;
        sort: string[];
        where: {
          ocrDocuments?: boolean;
        };
      };
    }
  >(LIST_DOCUMENTS_QUERY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    documents = [],
  } = data?.[LIST_DOCUMENTS] ?? {
    currentPage: 0,
    limit: 0,
    skip: 0,
    totalCount: 0,
    documents: [],
  };

  const handleFetchMore = ({
    limit,
    skip,
  }: {
    limit: number;
    skip: number;
  }): void => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          sort,
          where: {
            searchQuery,
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedDocuments = [
          ...previousResult[LIST_DOCUMENTS].documents,
          ...fetchMoreResult[LIST_DOCUMENTS].documents,
        ];

        return {
          ...previousResult,
          listDocuments: {
            ...previousResult[LIST_DOCUMENTS],
            ...fetchMoreResult[LIST_DOCUMENTS],
            documents: mergedDocuments,
          },
        };
      },
    });
  };

  return {
    documents,
    handleFetchMore,
    refetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
  };
};

export const useDeleteDocuments = () => {
  const [deleteDocuments, { error, loading }] = useMutation<
    { [DELETE_DOCUMENTS]: string },
    {
      documentsToDelete: string[];
    }
  >(DELETE_DOCUMENTS_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_DOCUMENTS,
      });
      cache.gc();
    },
  });

  return {
    deleteDocuments,
    error,
    loading,
  };
};

export const useUpdateDocument = () => {
  const [updateDocument, { error, loading }] = useMutation<
    { [UPDATE_DOCUMENT]: Document },
    {
      input: {
        _id: string;
        customFields: {
          fieldId: string;
          value: string | number;
        }[];
      };
    }
  >(UPDATE_DOCUMENT_MUTATION, {
    update(cache, data) {
      const updatedDocument = data.data?.[UPDATE_DOCUMENT];
      if (updatedDocument) {
        const cacheId = cache.identify({
          __typename: "Document",
          id: updatedDocument._id,
        });
        const documentRef: Document = cache.readFragment({
          id: cacheId,
          fragment: DocumentFragment.fragments.DocumentData,
          fragmentName: "DocumentData",
        });

        if (!documentRef) return;

        cache.writeFragment({
          id: cacheId,
          fragment: DocumentFragment.fragments.DocumentData,
          fragmentName: "DocumentData",
          data: updateDocument,
        });
      }
    },
  });

  return {
    updateDocument,
    error,
    loading,
  };
};

export const useScanDocuments = () => {
  const [scanDocuments, { error, loading }] = useMutation<
    { [SCAN_DOCUMENTS]: string },
    {
      documentsToScan: string[];
    }
  >(SCAN_DOCUMENTS_MUTATION);

  return {
    scanDocuments,
    error,
    loading,
  };
};

export const useQueryDocuments = () => {
  const [_query, { data, error, loading }] = useLazyQuery<
    {
      [QUERY_DOCUMENTS]: {
        results: SearchResult[];
        summary: string;
      };
    },
    { query: string }
  >(QUERY_DOCUMENTS_QUERY, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const performQuery = (query, activeFilters = {}) => {
    const customFields = [];

    for (const filter of Object.keys(activeFilters)) {
      if (activeFilters[filter]?.length) {
        customFields.push({
          fieldId: filter,
          value: activeFilters[filter],
        });
      }
    }
    const variables = {
      query,
      activeFilters: { customFields: customFields },
    };

    _query({ variables });
  };

  return {
    data: data?.[QUERY_DOCUMENTS],
    loading,
    error,
    performQuery,
  };
};

export const useApproveOCRDocuments = () => {
  const [approveDocuments, { error, loading }] = useMutation<
    { [APPROVE_OCR_DOCUMENTS]: string },
    {
      documentsToApprove: string[];
    }
  >(APPROVE_OCR_DOCUMENTS_MUTATION, {
    update(cache, data, payload) {
      payload.variables.documentsToApprove.forEach((documentId) => {
        const cacheId = cache.identify({
          __typename: "Document",
          id: documentId,
        });
        const documentRef: Document = cache.readFragment({
          id: cacheId,
          fragment: DocumentFragment.fragments.DocumentData,
          fragmentName: "DocumentData",
        });

        if (!documentRef) return;

        cache.writeFragment({
          id: cacheId,
          fragment: DocumentFragment.fragments.DocumentData,
          fragmentName: "DocumentData",
          data: { ...documentRef, status: DOCUMENT_STATUS.PENDING_INDEXING },
        });

        cache.evict({
          id: "ROOT_QUERY",
          fieldName: LIST_DOCUMENTS,
        });
        cache.gc();
      });
    },
  });

  return {
    approveDocuments,
    error,
    loading,
  };
};

export const useIndexDocuments = () => {
  const [indexDocuments, { error, loading }] = useMutation<
    { [INDEX_DOCUMENTS]: string },
    {
      documentsToIndex: string[];
    }
  >(INDEX_DOCUMENTS_MUTATION);

  return {
    indexDocuments,
    error,
    loading,
  };
};

const useGetDocumentById = () => {
  const [getDocumentById, { data, error, loading }] = useLazyQuery<
    { [GET_DOCUMENT_BY_ID]: Document },
    { documentId: string }
  >(GET_DOCUMENT_BY_ID_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  return { getDocumentById, data, error, loading };
};

export const useHandleDocumentCache = () => {
  const { getDocumentById } = useGetDocumentById();

  const handleUpdateDocumentCache = async ({ payload }) => {
    const { documentId } = payload;
    const cacheId = client.cache.identify({
      __typename: "Document",
      id: documentId,
    });
    const documentRef: Document = client.cache.readFragment({
      id: cacheId,
      fragment: DocumentFragment.fragments.DocumentData,
      fragmentName: "DocumentData",
    });

    const isOCRDone = payload.status === DOCUMENT_STATUS.PENDING_APPROVAL;
    const areCustomFieldsUpdated =
      payload.text ===
      NOTIFICATION_IDENTIFIERS.KNOWLEDGE_BASE_DOCUMENT_CUSTOM_FIELDS_UPDATE;
    let ocrContent = documentRef?.ocrGeneratedContent;
    let customFields = documentRef?.customFields;

    if (isOCRDone || areCustomFieldsUpdated) {
      const { data } = await getDocumentById({
        variables: {
          documentId,
        },
      });
      ocrContent = data[GET_DOCUMENT_BY_ID].ocrGeneratedContent;
      customFields = data[GET_DOCUMENT_BY_ID].customFields;
    }

    const updatedDoc = {
      ...documentRef,
      ocrGeneratedContent: ocrContent,
      customFields: customFields,
      status: payload.status,
    };

    updatedDoc.status = payload.status;
    client.cache.writeFragment({
      id: cacheId,
      fragment: DocumentFragment.fragments.DocumentData,
      fragmentName: "DocumentData",
      data: updatedDoc,
    });
  };

  return { handleUpdateDocumentCache };
};

import AssetsManagement from "@shared/svg/assets-tile-icon.svg?react";
import PartIcon from "@shared/svg/part.svg?react";
import ProcedureIcon from "@shared/svg/procedures.svg?react";
import {
  CalendarBlank,
  FolderOpen,
  PencilSimple,
  Repeat,
  Trash,
} from "phosphor-react";
import React, { useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";

import { HorizontalBarsIcon } from "../../icons/icons";
import { formatDate } from "../../utils";

import { useAuth } from "~/components/general";
import { useRoleManager } from "~/hooks/_useRoleManager";

const PreviewEvent = ({
  title,
  repeatIn,
  repeatInNumber,
  eventDate,
  description,
  ticketCreationIn,
  ticketCreationNumber,
  nextCreationDate,
  onOutsideClick = () => {},
  onEditButtonClick,
  onDeleteButtonClick,
  createdBy,
  procedureCount,
  inventoryPartsCount,
  tileVariant = false,
  machineName = "",
}) => {
  const ref = useRef(null);
  const {
    messages: { preventiveMaintenance: PM },
  } = useIntl();
  const { user } = useAuth();
  const { isOemAdmin, isOemTechnician } = useRoleManager(user?.role);

  const { pathname } = useLocation();

  const isSharedAssetsPage = useMemo(
    () => pathname.includes("/app/asset-hub/shared-assets"),
    [pathname],
  );

  const isSelfCreated = createdBy?._id === user?._id;

  const allowDeletion =
    (isOemAdmin || (isSelfCreated && !isOemTechnician)) && !isSharedAssetsPage;

  useOnClickOutside(ref, onOutsideClick);

  return (
    <section className="preview-event" ref={ref}>
      <div className="preview-event-header">
        <div className="pe-header-left">
          <p className="event-id"></p>
        </div>

        <div className="pe-header-right">
          {!isOemTechnician && !isSharedAssetsPage && (
            <button className="edit-btn" onClick={onEditButtonClick}>
              <span>
                <PencilSimple size={20} />
              </span>
              {PM?.previewEvent.buttonEdit}
            </button>
          )}
          {allowDeletion && (
            <button className="delete-btn" onClick={onDeleteButtonClick}>
              <span>
                <Trash size={20} />
              </span>
              {PM?.previewEvent.buttonDelete}
            </button>
          )}
        </div>
      </div>

      <div className="preview-event-body">
        <div className="preview-event-title">
          <h3>{title}</h3>
        </div>
        {tileVariant && machineName && (
          <div className="meta-data">
            <p className="repeat">
              <span className="peb-icon h-lg w-lg">
                <AssetsManagement size={16} />
              </span>
              <span className="flex whitespace-nowrap">
                {PM?.assetTitle}&nbsp; • &nbsp;
              </span>
              <span className="frequency-time text-black">{machineName}</span>
            </p>
          </div>
        )}

        <div className="meta-data">
          <p className="date-time-progress">
            <span className="peb-icon">
              <CalendarBlank size={16} />
            </span>
            {PM?.maintenanceOn}&nbsp; • &nbsp;
            <span className="date text-black">{formatDate(eventDate)}</span>
          </p>
        </div>
        <div className="meta-data">
          <p className="repeat">
            <span className="peb-icon">
              <Repeat size={16} />
            </span>
            {PM?.repeatEvery}&nbsp; • &nbsp;
            <span className="frequency-time text-black">
              {repeatInNumber !== 0 ? (
                <>
                  {repeatInNumber} {PM?.frequency[repeatIn.toLowerCase()]}
                </>
              ) : (
                <>{PM?.form.frequencyNoRepeat}</>
              )}
            </span>
          </p>
        </div>
        {ticketCreationIn && (
          <div className="meta-data">
            <p className="repeat">
              <span className="peb-icon">
                <FolderOpen size={16} />
              </span>
              {PM?.previewEvent.ticketCreationText} &nbsp; • &nbsp;
              <span className="event-creation-time text-black">
                {ticketCreationNumber +
                  " " +
                  PM?.frequency[ticketCreationIn.toLowerCase()] +
                  " "}
                {PM?.previewEvent.ticketCreationEnd}
              </span>
            </p>

            <p className="sub-meta">
              {new Date().getTime() > new Date(nextCreationDate)?.getTime?.()
                ? PM?.previewEvent.ticketBeforeCreationDate
                : PM?.previewEvent.ticketCreationDate}{" "}
              {formatDate(nextCreationDate)}
            </p>
          </div>
        )}
        {!!description && (
          <div className="meta-data">
            <p className="pe-description !text-black">
              <span className="peb-icon">
                <HorizontalBarsIcon />
              </span>
              {description}
            </p>
          </div>
        )}
        {procedureCount > 0 && (
          <div className="meta-data flex items-center">
            <span className="peb-icon">
              <ProcedureIcon className="icon h-4 w-4 text-secondary" />
            </span>
            <p>
              {" "}
              <span className="text-secondary">
                {PM?.procedures?.title}
              </span>{" "}
              &nbsp; • &nbsp;
              <span className="text-primary">{procedureCount}</span>{" "}
            </p>
          </div>
        )}
        {inventoryPartsCount > 0 && (
          <div className="meta-data flex items-center">
            <span className="peb-icon">
              <PartIcon className="icon h-4 w-4 text-secondary" />
            </span>
            <p>
              {" "}
              <span className="text-secondary">{PM?.parts?.title}</span> &nbsp;
              • &nbsp;
              <span className="text-primary">{inventoryPartsCount}</span>{" "}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default PreviewEvent;

import activityLog from "~/lang/es-ES/activityLog.json";
import aiAssistant from "~/lang/es-ES/ai-assistant.json";
import aiNotetaker from "~/lang/es-ES/ai-notetaker.json";
import analytics from "~/lang/es-ES/analytics";
import branding from "~/lang/es-ES/branding";
import chat from "~/lang/es-ES/chat";
import common from "~/lang/es-ES/common";
import components from "~/lang/es-ES/components.json";
import connections from "~/lang/es-ES/connections.json";
import dataManagement from "~/lang/es-ES/dataManagement";
import documents from "~/lang/es-ES/documents";
import emails from "~/lang/es-ES/emails.json";
import facilities from "~/lang/es-ES/facilities";
import filters from "~/lang/es-ES/filters";
import forms from "~/lang/es-ES/forms";
import hierarchy from "~/lang/es-ES/hierarchy.json";
import inventory from "~/lang/es-ES/inventory";
import knowledgeBase from "~/lang/es-ES/knowledgeBase.json";
import login from "~/lang/es-ES/login";
import machines from "~/lang/es-ES/machines";
import maintenanceDocuments from "~/lang/es-ES/maintenanceDocuments";
import menus from "~/lang/es-ES/menus";
import plans from "~/lang/es-ES/plans";
import preventiveMaintenance from "~/lang/es-ES/preventiveMaintenance";
import procedures from "~/lang/es-ES/procedures";
import productionLines from "~/lang/es-ES/productionLines.json";
import requests from "~/lang/es-ES/requests.json";
import resourcePlanning from "~/lang/es-ES/resourcePlanning";
import resources from "~/lang/es-ES/resources.json";
import scheduling from "~/lang/es-ES/scheduling.json";
import settings from "~/lang/es-ES/settings";
import sharedAssets from "~/lang/es-ES/sharedAssets.json";
import teams from "~/lang/es-ES/teams.json";
import tickets from "~/lang/es-ES/tickets";
import workOrderFormConfiguration from "~/lang/es-ES/workOrderFormConfiguration.json";
import workOrderTypes from "~/lang/es-ES/workOrderTypes.json";
import LOCALES from "~/lang/locales";

export default {
  [LOCALES[2].locale]: {
    analytics,
    chat,
    common,
    connections,
    dataManagement,
    documents,
    facilities,
    forms,
    inventory,
    login,
    machines,
    maintenanceDocuments,
    menus,
    preventiveMaintenance,
    resourcePlanning,
    settings,
    tickets,
    branding,
    procedures,
    filters,
    productionLines,
    knowledgeBase,
    components,
    teams,
    emails,
    workOrderTypes,
    workOrderFormConfiguration,
    activityLog,
    aiAssistant,
    aiNotetaker,
    hierarchy,
    scheduling,
    resources,
    sharedAssets,
    requests,
    plans,
  },
};

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Loading } from "~/components/general";
import SingleSharedAsset from "~/containers/Assets/Shared/SingleSharedAsset";
import { useGetAssetByUuid, useGetAssetPartsByUuid } from "~/services/asset";

const MachineQRCode = () => {
  const { machineUuid: uuid } = useParams() as { machineUuid: string };
  const [assetRedirected, setAssetRedirected] = useState(false);
  const history = useHistory();

  const { asset, loading } = useGetAssetByUuid({
    uuid,
  });
  const { assetParts, loading: assetPartsLoading } = useGetAssetPartsByUuid({
    uuid,
  });

  useEffect(() => {
    if (!asset && !loading) history.push("/oem");
    else if (asset && !loading) {
      if (asset.isOwnAsset)
        history.push(`/app/asset-hub/my-assets/${asset._id}`);
      else if (!asset.isQRCodeFlowEnabled) history.push("/error-page/404");
      else if (asset.isSharedAsset)
        history.push(
          `/app/asset-hub/shared-assets/${asset.customer?._id}/${asset._id}`,
        );
      setAssetRedirected(true);
    }
  }, [asset, assetParts, loading, assetPartsLoading]);

  if (loading || assetPartsLoading) return <Loading />;

  return assetRedirected ? (
    <SingleSharedAsset asset={asset} assetParts={assetParts} isQRCodeFlow />
  ) : null;
};

export default MachineQRCode;

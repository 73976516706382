import Dropdown, { POSITIONS } from "@shared/ui/DropDown";
import React from "react";
import { useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";

import { NotFoundComponent } from "~/components/_notFoundMessage";
import DropdownFooter from "~/components/filters/_dropdownFooter";
import DropdownHeader from "~/components/filters/_dropdownHeader";
import { ITEMS_BEFORE_PAGE, ITEMS_PER_PAGE } from "~/constants";
import { NoUserAssignedIcon, TickIcon } from "~/icons/icons";
import { useListAllOwnOemAssets } from "~/services/asset";

const MachineName = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  activeFilters,
  selectValues,
  handleSelectDeleteAll,
  dropDownPosition = POSITIONS.LEFT,
}) => {
  const { messages: msg } = useIntl();
  const [searchTerm, setSearchTerm] = React.useState("");

  const { assets, handleFetchMore, assetsListLoading } = useListAllOwnOemAssets(
    {
      searchQuery: searchTerm,
      limit: ITEMS_PER_PAGE,
      skip: 0,
    },
  );

  const handleScrollBottom = (event) => {
    if (
      assets.length >= ITEMS_PER_PAGE &&
      !assetsListLoading &&
      event?.previousPosition !== "above"
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: assets.length,
      });
    }
  };

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
      position={dropDownPosition}
    >
      <DropdownHeader
        title={title}
        searchTerm={searchTerm}
        onSearchTermChange={setSearchTerm}
        unselectFilter={unselectFilter}
      />
      <div className="filter-facility-name filter-items">
        {assetsListLoading && !assets?.length ? null : !assets?.length ? (
          <NotFoundComponent
            icon={<NoUserAssignedIcon />}
            title={msg?.common?.filters.noAsset}
          />
        ) : (
          assets?.map((item, index) => (
            <div
              className="filter-facility-name-item-container"
              key={index}
              onClick={() => selectValues(filterItem, item?._id)}
            >
              <span
                className={`filter-facility-name-item ${
                  activeFilters[filterItem.id].includes(item?._id)
                    ? "active"
                    : ""
                }`}
              >
                {item.name} • {item.serialNumber}
                {index === assets.length - ITEMS_BEFORE_PAGE ? (
                  <Waypoint onEnter={handleScrollBottom} />
                ) : null}
              </span>
              {activeFilters[filterItem.id].includes(item?._id) && (
                <span className="active">
                  <TickIcon />
                </span>
              )}
            </div>
          ))
        )}
        {assetsListLoading ? (
          <div className="no-value filter-facility-item-loading">
            {msg?.common?.loading}
          </div>
        ) : null}
      </div>
      <DropdownFooter
        deleteAll={() => handleSelectDeleteAll(filterItem, false)}
        disabled={!activeFilters[filterItem.id]?.length}
      />
    </Dropdown>
  );
};

export default MachineName;

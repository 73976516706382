import RemoveGhostIcon from "@shared/svg/remove-ghost.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import { SearchInput, SEARCH_INPUT_SIZES } from "@shared/ui/Inputs";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { Plus } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { Waypoint } from "react-waypoint";

import getEnums from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features.json";
import { NotFoundComponent } from "~/components/_notFoundMessage";
import AssetRow from "~/components/assets/AssetRow";
import AssignAssetsToParent from "~/components/assets/AssignAssetsToParent";
import { useAuth } from "~/components/general";
import { ITEMS_BEFORE_PAGE, ITEMS_PER_PAGE } from "~/constants";
import { useRoleManager } from "~/hooks/_useRoleManager";
import { NoUserAssignedIcon } from "~/icons/icons";
import { useUnassignAssetFromParent } from "~/services/asset/mutation";
import { useListAllOwnOemAssets } from "~/services/asset/query";
import { Asset } from "~/types/asset";
import { UserType } from "~/types/user";
import { WaypointEventType } from "~/types/waypointEvent";
import { useGetAppConfigRecoilValue } from "~/utils/appFeatures";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

const SubAssets = ({
  asset,
  isSharedAssetsPage = false,
  sharedAssetsOrg = "",
  isQRCodeEnabled = true,
  handleQRCodeClick = () => {},
}: {
  asset: Asset;
  isSharedAssetsPage?: boolean;
  sharedAssetsOrg?: string;
  isQRCodeEnabled?: boolean;
  handleQRCodeClick?: (asset: Asset) => void;
}) => {
  const { messages } = useIntl();
  const [showAssignAsset, setShowAssignAsset] = React.useState(false);
  const [subAssetSearchQuery, setSubAssetSearchQuery] = React.useState("");
  const [assignAssetSearchQuery, setAssignAssetSearchQuery] =
    React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);

  const {
    assets: allSubAssets,
    assetsListLoading: allSubAssetsLoading,
    handleFetchMore: handleFetchMoreSubAssets,
    totalCount: allSubAssetsTotalCount,
  } = useListAllOwnOemAssets({
    where: {
      ...(subAssetSearchQuery
        ? { parentId: asset._id }
        : { directParentId: asset._id }),
      isSharedAsset: isSharedAssetsPage,
      ...(sharedAssetsOrg ? { oemId: sharedAssetsOrg } : {}),
    },
    searchQuery: subAssetSearchQuery,
    limit: ITEMS_PER_PAGE,
    skip: 0,
  });
  const {
    assets: allAssets,
    assetsListLoading: allAssetsLoading,
    handleFetchMore: handleFetchMoreAssets,
    totalCount: allAssetsTotalCount,
  } = useListAllOwnOemAssets({
    where: {
      filterAssetAssigned: false,
      id_nin: [asset._id, ...(asset.hierarchy?.map((item) => item.id) || [])],
      ...(sharedAssetsOrg ? { oemId: sharedAssetsOrg } : {}),
    },
    searchQuery: assignAssetSearchQuery,
    limit: ITEMS_PER_PAGE,
    skip: 0,
  });

  const { mutation: unassignAsset } = useUnassignAssetFromParent();

  const selectedAsset = React.useRef(null);

  const { user } = useAuth() as { user: UserType };
  const { isOemTechnician } = useRoleManager(user?.role);
  const appConfig = useGetAppConfigRecoilValue();
  const isTeamsPaid = isPaidFeatureAvailable(
    paidFeatures.teams,
    user,
    appConfig,
  );
  const isHierarchyPaid = isPaidFeatureAvailable(
    paidFeatures.hierarchy,
    user,
    appConfig,
  );

  const handleScrollBottom = (event: WaypointEventType) => {
    if (
      allSubAssets.length >= ITEMS_PER_PAGE &&
      !allSubAssetsLoading &&
      event?.previousPosition !== Waypoint.above
    ) {
      handleFetchMoreSubAssets({
        limit: ITEMS_PER_PAGE,
        skip: allSubAssets.length,
      });
    }
  };

  const handleScrollBottomAllAssets = (event: WaypointEventType) => {
    if (
      allAssets.length >= ITEMS_PER_PAGE &&
      !allAssetsLoading &&
      event?.previousPosition !== Waypoint.above &&
      allAssetsTotalCount > allAssets.length
    ) {
      handleFetchMoreAssets({
        limit: ITEMS_PER_PAGE,
        skip: allAssets.length,
      });
    }
  };

  const handleSearch = (value: string) => {
    setSubAssetSearchQuery(value);
  };

  const handleUnassignClick = (assetId: string) => {
    selectedAsset.current = assetId;
    setShowAlert(true);
  };

  const handleUnassignCancel = () => {
    setShowAlert(false);
    selectedAsset.current = null;
  };

  const handleUnassignAccept = () => {
    setShowAlert(false);
    try {
      unassignAsset({
        assetId: selectedAsset.current,
        parentId: asset._id,
      });
      toast(
        <Toast
          message={messages["hierarchy"]["assets"].unassign.successMessage}
        />,
        {
          closeButton: false,
        },
      );
    } catch (error) {
      toast(
        <Toast
          type={TOAST_TYPES.ERROR}
          message={
            error?.message ||
            messages["hierarchy"]["assets"].unassign.failureMessage
          }
        />,
        {
          closeButton: false,
        },
      );
    } finally {
      selectedAsset.current = null;
    }
  };

  return (
    <>
      <div
        className={`flex items-center mb-md -mt-md ${
          !isHierarchyPaid || isOemTechnician
            ? "justify-end"
            : "justify-between"
        }`}
      >
        {isHierarchyPaid && !isOemTechnician && !isSharedAssetsPage && (
          <Button
            variant={BUTTON_VARIANTS.LINK}
            text={messages["hierarchy"]["assets"].assign.linkButtonText}
            // @ts-ignore
            leadingIcon={<Plus size={14} weight="bold" />}
            onClick={() => {
              setShowAssignAsset(true);
            }}
          />
        )}

        <SearchInput
          size={SEARCH_INPUT_SIZES.SMALL}
          onChange={handleSearch}
          value={subAssetSearchQuery}
          placeholder={
            messages["hierarchy"]["assets"].assign.subAssetsSearchPlaceholder
          }
        />
      </div>

      {(!asset?.childrenCount ||
        (subAssetSearchQuery && !allSubAssets?.length)) && (
        <NotFoundComponent
          icon={<NoUserAssignedIcon />}
          title={
            asset?.childrenCount && subAssetSearchQuery && !allSubAssets?.length
              ? messages["common"]["noResultFound"]
              : messages["hierarchy"]["assets"].noSubAssets
          }
        />
      )}

      {!allSubAssetsLoading && !!allSubAssets?.length && (
        <div className="flex flex-col space-y-sm">
          {allSubAssets?.map((subAsset: Asset, index: number) => (
            <React.Fragment key={subAsset._id}>
              <AssetRow
                asset={subAsset}
                handleUnassignClick={() => handleUnassignClick(subAsset._id)}
                isAssetQuery={!!subAssetSearchQuery}
                handleQRCodeClick={(asset) => handleQRCodeClick(asset)}
                isHierarchyPaid={isHierarchyPaid}
                isTeamsPaid={isTeamsPaid}
                isSharedAssetPage={isSharedAssetsPage}
                showQRCode={isQRCodeEnabled}
              />

              {subAssetSearchQuery &&
                !allSubAssetsLoading &&
                allSubAssetsTotalCount > allSubAssets.length &&
                index === allSubAssets.length - ITEMS_BEFORE_PAGE && (
                  // @ts-ignore
                  <Waypoint onEnter={handleScrollBottom} />
                )}
            </React.Fragment>
          ))}
        </div>
      )}

      <AssignAssetsToParent
        isOpen={showAssignAsset}
        allAssets={allAssets}
        handleClose={() => setShowAssignAsset(false)}
        handleScrollBottomAllAssets={handleScrollBottomAllAssets}
        allAssetsLoading={allAssetsLoading}
        searchQuery={assignAssetSearchQuery}
        setSearchQuery={setAssignAssetSearchQuery}
      />
      <AlertBox
        title={messages["hierarchy"]["assets"].unassign.alertTitle}
        isOpen={showAlert}
        cancelButtonText={
          messages["facilities"]["facilityDetails"].machinesTab.edit.changes
            .unassignWindow.no
        }
        acceptButtonText={
          messages["facilities"]["facilityDetails"].machinesTab.edit.changes
            .unassignWindow.yes
        }
        image={<RemoveGhostIcon width="130" height="130" />}
        onCancel={handleUnassignCancel}
        onAccept={handleUnassignAccept}
        overlay
      />
    </>
  );
};

export default SubAssets;

import { useLazyQuery, useQuery } from "@apollo/client";

import {
  GET_OWN_OEM_PROCEDURE_INSTANCE,
  LIST_OEM_PROCEDURE_TEMPLATES_QUERY,
} from "~/api/_queries";
import {
  DOWNLOAD_PROCEDURE_PDF_BY_ID,
  GET_OWN_OEM_PROCEDURE_TEMPLATE,
} from "../../api/_queries";

export const useGetAllProcedureTemplates = () => {
  const {
    data: { listOwnOemProcedureTemplates = [] } = {},
    loading,
    error,
    refetch,
  } = useQuery(LIST_OEM_PROCEDURE_TEMPLATES_QUERY, {
    variables: {
      params: {},
    },
  });

  return {
    allProcedures: listOwnOemProcedureTemplates,
    fetchingProcedures: loading,
    errorFetchingProcedures: error,
    refetch,
  };
};

export const useGetProcedureTemplateById = (id) => {
  const {
    data: { getOwnOemProcedureTemplate = {} } = {},
    loading,
    error,
  } = useQuery(GET_OWN_OEM_PROCEDURE_TEMPLATE, {
    variables: {
      id,
    },
  });

  return {
    procedure: getOwnOemProcedureTemplate,
    loading,
    error,
  };
};

export const useGetProcedureInstanceById = (id) => {
  const {
    data: { getOwnOemProcedureById = {} } = {},
    loading,
    error,
  } = useQuery(GET_OWN_OEM_PROCEDURE_INSTANCE, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  return {
    procedureData: getOwnOemProcedureById,
    loading,
    error,
  };
};

export const useGetProcedurePdfById = () => {
  const [
    download,
    { data: { downloadProcedurePDF = "" } = {}, loading, error },
  ] = useLazyQuery(DOWNLOAD_PROCEDURE_PDF_BY_ID);

  return {
    downloadingPdf: loading,
    pdfDownloadError: error,
    url: downloadProcedurePDF,
    getProcedurePdf: async (id, uuid) =>
      download({
        variables: {
          id,
          uuid,
        },
        fetchPolicy: "network-only",
      }),
  };
};

import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { uniqBy } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import KanbanBoard from "./kanban/_kanbanBoard";
import { KanbanTicketCard } from "./kanban/card/_ticketCard";
import {
  CREATE_WORK_ORDERS_BOARD,
  INITIATE_CREATE_WORK_ORDERS_BOARD,
  INITIATE_SORT_WORK_ORDERS,
  ITEMS_PER_PAGE,
  REMOVE_WORK_ORDERS_BOARD,
  SORT_WORK_ORDERS,
} from "../constants";
import { registerMixpanelEvent } from "../utils/_mixpanel";
import { generateOpenTicketQueryWhereCond } from "../utils/filter/_openTicketQueryCond";

import getEnum from "$/settings/enums";
import CUSTOM_FIELD_TYPES from "$/settings/enums/customAdditionalField/_type.json";
import BoardFor from "$/settings/enums/kanban/_boardFor.json";
import PAID_FEATURES from "$/settings/paid-features.json";
import { BoardFilterTabs } from "~/components/_pageViewTabs";
import { useAuth } from "~/components/general";
import {
  updateTicket,
  useRemoveBoard,
  useAddBoard,
  useListOwnOemKanbanTickets,
} from "~/services";
import { useListOwnOemCustomFields } from "~/services/customFields";
import { useGetAppConfigRecoilValue } from "~/utils/appFeatures";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const CustomFieldTypes = getEnum(CUSTOM_FIELD_TYPES, "reference");
const boardForEnum = getEnum(BoardFor, "reference");
const paidFeaturesRef = getEnum(PAID_FEATURES, "reference");
const SORTBY = {
  1: "asc",
  "-1": "des",
};

const TicketsKanbanView = ({
  activeFilters,
  searchQuery,
  activeFilter,
  setActiveFilter,
  fetchingFirstRef,
}) => {
  const { user } = useAuth();
  const { messages } = useIntl();
  const oem = user?.oem;
  const boards = user?.boards;
  const appConfig = useGetAppConfigRecoilValue();
  const isKanbanViewPaid = isPaidFeatureAvailable(
    paidFeaturesRef.workManagement,
    user,
    appConfig,
  );
  const [kanbanColumns, setKanbanColumns] = useState([]);

  const fetchingMoreRef = useRef(false);

  const [sortingValue, setSortingValue] = useState({
    key: "lastUpdatedAt",
    value: -1,
  });

  const { totalCount, columns, loading, fetchMore } =
    useListOwnOemKanbanTickets({
      activeFilters,
      limit: ITEMS_PER_PAGE,
      skip: 0,
      where: {
        kanbanBoard: activeFilter?._id,
      },
      sort: [`${sortingValue.key}:${SORTBY[sortingValue.value]}`],
      searchQuery,
      skipCondition: !isKanbanViewPaid,
    });

  useEffect(() => {
    if (!isKanbanViewPaid) return;
    if (fetchingFirstRef.current && !loading) {
      setKanbanColumns(columns);
      fetchingFirstRef.current = false;
    }
  }, [columns]);

  const isFetchable = useMemo(() => {
    let totalCards = 0;
    kanbanColumns.forEach((column) => (totalCards += column.cards.length));
    return totalCount > totalCards;
  }, [kanbanColumns]);

  useEffect(() => {
    fetchingFirstRef.current = true;
  }, [searchQuery, activeFilters]);

  const handleScrollBottom = async (event) => {
    if (isFetchable && !loading && event?.previousPosition !== "above") {
      fetchingMoreRef.current = true;
      const filterConditions =
        generateOpenTicketQueryWhereCond(activeFilters) ?? {};
      await fetchMore({
        variables: {
          params: {
            limit: ITEMS_PER_PAGE,
            skip: kanbanColumns?.[0]?.currentPage * kanbanColumns?.[0]?.limit,
            sort: [`${sortingValue.key}:${SORTBY[sortingValue.value]}`],
            where: {
              kanbanBoard: activeFilter?._id,
              searchQuery,
              ...filterConditions,
            },
          },
        },
      })
        .then((res) => {
          if (!res.data.listOwnOemKanbanTickets.columns.length) {
            return;
          }
          const updatedColumns = kanbanColumns.map((column) => {
            const newColumn = res.data.listOwnOemKanbanTickets.columns.find(
              (c) => column._id === c._id,
            );
            if (newColumn) {
              const mergedCards = uniqBy(
                column.cards.concat(newColumn.cards),
                "_id",
              );
              return {
                ...column,
                ...newColumn,
                cards: mergedCards,
              };
            }

            return column;
          });
          setKanbanColumns(updatedColumns);
        })
        .finally(() => (fetchingMoreRef.current = false));
    }
  };

  const updateSortingValue = (newValue) => {
    registerMixpanelEvent(SORT_WORK_ORDERS);
    if (
      !(
        newValue.key === sortingValue.key &&
        newValue.value === sortingValue.value
      )
    ) {
      fetchingFirstRef.current = true;
      setSortingValue(newValue);
    }
  };

  const { mutation: workOrderMutation } = updateTicket();
  const { customAdditionalFields } = useListOwnOemCustomFields({
    type: CustomFieldTypes.tickets,
  });
  const { addBoard } = useAddBoard();
  const { removeBoard } = useRemoveBoard();

  const selectedBoards = useMemo(() => {
    if (!boards || typeof boards !== "object") return [];

    const ticketBoards = boards.find(
      (board) => board.boardFor === boardForEnum.ticket,
    );

    if (!ticketBoards) return [];

    return ticketBoards.customFields || [];
  }, [boards]);

  const handleBoardSelect = async (selectedItem, isRemoving = false) => {
    const board = { boardFor: boardForEnum.ticket, fieldId: selectedItem };

    if (isRemoving) {
      await removeBoard(board);
    } else {
      registerMixpanelEvent(CREATE_WORK_ORDERS_BOARD);
      await addBoard(board);
    }
  };

  const workOrderKanbanMutation = async (
    activeFilter,
    activeCard,
    overColumn,
    prevData,
  ) => {
    let updatedObj = {};
    if (activeFilter === "ticket") {
      updatedObj = {
        ticketId: activeCard?.id,
        status: overColumn,
      };
    } else {
      updatedObj = {
        ticketId: activeCard?.id,
        customFields: [
          {
            fieldId: activeFilter?._id,
            value: overColumn,
          },
        ],
      };
    }
    await workOrderMutation(updatedObj).catch(() => {
      setKanbanColumns(prevData);
      toast(
        <Toast
          message={messages?.common?.errors?.pageSubtitle}
          type={TOAST_TYPES.ERROR}
        />,
        {
          closeButton: false,
        },
      );
      setBoardData(clonedBoardData);
    });
  };

  const singleSelectFields = useMemo(() => {
    return customAdditionalFields?.filter(
      (item) => item.fieldType === "singleSelect",
    );
  }, [customAdditionalFields]);

  useEffect(() => {
    const calcKanbanTitle = () => {
      const scroller = document.querySelector(".kanban-columns-title");
      const getTopOffset = scroller.getBoundingClientRect().top;
      if (getTopOffset < 1) {
        scroller.classList.add("fixed");
      } else {
        scroller.classList.remove("fixed");
      }
    };
    window.addEventListener("scroll", calcKanbanTitle);
    return () => {
      removeEventListener("scroll", calcKanbanTitle);
    };
  }, []);

  useEffect(() => {
    if (selectedBoards?.length && !activeFilter) {
      fetchingFirstRef.current = true;
      setActiveFilter(selectedBoards[0]);
    }
  }, [selectedBoards]);

  return (
    <>
      <div className="kanban-data-filter">
        <BoardFilterTabs
          isTicketBoard
          data={selectedBoards}
          boards={singleSelectFields}
          activeFilter={activeFilter}
          setActiveFilter={(val) => {
            if (val?._id !== activeFilter?._id) {
              fetchingFirstRef.current = true;
              setActiveFilter(val);
            }
          }}
          sorting={true}
          sortValue={sortingValue}
          updateSortValue={updateSortingValue}
          onCreateBoardUpdate={handleBoardSelect}
          handleCreateBoardClick={() =>
            registerMixpanelEvent(INITIATE_CREATE_WORK_ORDERS_BOARD)
          }
          onOpenSortingDropdown={() =>
            registerMixpanelEvent(INITIATE_SORT_WORK_ORDERS)
          }
          onRemoveBoardClick={() =>
            registerMixpanelEvent(REMOVE_WORK_ORDERS_BOARD)
          }
          sortKeys={{
            name: "title",
            createdAt: "createdAt",
            updatedAt: "lastUpdatedAt",
          }}
        />
      </div>
      <KanbanBoard
        boardType={boardForEnum.ticket}
        data={kanbanColumns}
        activeFilter={activeFilter}
        singleSelectFields={singleSelectFields}
        kanbanMutation={workOrderKanbanMutation}
        overlayCard={KanbanTicketCard}
        sortingValue={sortingValue}
        handleScrollBottom={handleScrollBottom}
        setKanbanColumns={setKanbanColumns}
        isLoading={loading}
        isFetchingMore={fetchingMoreRef.current}
        coulmnsSkeletonCount={
          activeFilter !== "ticket"
            ? activeFilter?.options?.length ?? 0
            : oem.statuses.length ?? 0
        }
      />
    </>
  );
};

export default TicketsKanbanView;

import React from "react";
import ContentLoader from "react-content-loader";
import EmptySpace from "~/components/_emptySpace";
export const MachineHistoryLoading = (props) => (
  <ContentLoader
    speed={1.5}
    width={800}
    height={172}
    viewBox="0 0 800 172"
    backgroundcolor="#ededed"
    foregroundcolor="#dbdbdb"
    {...props}
  >
    <circle cx="20" cy="20" r="20" />
    <rect x="50" y="0" rx="0" ry="0" width="100%" height="60" />
    <EmptySpace />
    <circle cx="20" cy="100" r="20" />
    <rect x="50" y="80" rx="0" ry="0" width="100%" height="60" />
  </ContentLoader>
);

import { ITEMS_BEFORE_PAGE } from "@shared/constants/index";
import Check from "@shared/svg/check.svg?react";
import ChevronDownIcon from "@shared/svg/chevron-down.svg?react";
import { Avatar } from "@shared/ui/Avatar";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import { Input, InputLabel } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { findIndex } from "@shared/utils/arrayUtils";
import { trimText } from "@shared/utils/textFormatting";
import { WarningCircle, MinusCircle } from "phosphor-react";
import React, { useEffect, useRef, useState, Fragment } from "react";
import { useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";
import useOnClickOutside from "use-onclickoutside";

import DropdownItem from "../DropdownItem";
import { WaypointEventType } from "../SingleSelect/SingleSelectAsyncItem";

type AsyncMultiSelectDropdownPropsType = {
  defaultSelectedOptions?: any[];
  setSelectedData?: (data: any[]) => void;
  hideSelectedOptions?: boolean;
  selectedOptions?: any[];
  setSearchQuery?: (value: string) => void;
  onCloseRequest?: (data: any[]) => void;
  allowMulti?: boolean;
  onChange?: (data: any[]) => void;
  onOpen?: (val: boolean) => void;
  label?: string;
  keyId?: string;
  uppercaseLabel?: boolean;
  infoMessage?: string;
  showDropdownClassName?: string;
  showOnlyPlaceHolder?: boolean;
  trimCharCount?: number;
  placeholder?: string;
  dropdownTitle?: string;
  showSearchInput?: boolean;
  searchPlaceholder?: string;
  textAllSelect?: string;
  options?: any[];
  maxOptionLabelWidth?: string;
  showAvatar?: boolean;
  onScrollToBottom?: (event: WaypointEventType) => void;
  isLoading?: boolean;
  customOptionComponent?: (
    option: any,
    onClick: (option: any) => void,
    isSelected: boolean,
  ) => React.JSX.Element;
  showSelectedOptionsPreviewList?: boolean;
  customPreviewItemComponent?: (
    option: any,
    onClick: (option: any) => void,
  ) => React.JSX.Element;
  optionsContainerClassName?: string;
  showRemoveAllButton?: boolean;
};

const AsyncMultiSelectDropdown = ({
  defaultSelectedOptions = [],
  setSelectedData = () => {},
  hideSelectedOptions = false,
  selectedOptions = [],
  setSearchQuery = () => {},
  onCloseRequest = () => {},
  allowMulti = true,
  onChange = () => {},
  onOpen = () => {},
  keyId,
  label = "",
  uppercaseLabel = false,
  infoMessage = "",
  showDropdownClassName = "",
  showOnlyPlaceHolder = false,
  trimCharCount = 20,
  placeholder = "",
  dropdownTitle = "",
  showSearchInput = false,
  searchPlaceholder = "",
  textAllSelect = "",
  options = [],
  maxOptionLabelWidth = "",
  showAvatar = false,
  onScrollToBottom = () => {},
  isLoading = false,
  customOptionComponent = null,
  showSelectedOptionsPreviewList = false,
  customPreviewItemComponent = null,
  optionsContainerClassName = "",
  showRemoveAllButton = false,
}: AsyncMultiSelectDropdownPropsType) => {
  const mounted = useRef();
  const containerRef = useRef();
  const [selectedItems, setSelectedItems] = useState<any[]>(
    defaultSelectedOptions,
  );
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [showInfoMessage, setShowInfoMessage] = useState<boolean>(false);
  const { messages } = useIntl();

  useEffect(() => {
    setSelectedData(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (hideSelectedOptions && selectedOptions.length !== selectedItems.length)
      setSelectedItems(selectedOptions);
  }, [selectedOptions]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!mounted.current) return;

    setSearchText(e.target.value);
    setSearchQuery(e.target.value);
  };

  const handleCloseRequest = () => {
    onCloseRequest(selectedItems);
  };

  const handleOptionClick = (option: any) => {
    if (!mounted.current) return;

    if (defaultSelectedOptions.length) {
      const indexOfDefaultSelected = findIndex(defaultSelectedOptions, {
        value: option.value,
      });
      if (indexOfDefaultSelected >= 0) return;
    }

    if (!allowMulti) {
      onChange([option]);
      setSelectedItems([option]);
      setShowDropdown(false);
      onOpen(false);
      return;
    }
    const index = findIndex(selectedItems, { value: option.value });
    if (index < 0) {
      setSelectedItems((prev) => {
        const updatedList = [...prev, option];
        onChange(updatedList);
        return updatedList;
      });
    } else {
      const clone = [...selectedItems];
      clone.splice(index, 1);
      onChange(clone);
      setSelectedItems(clone);
    }
  };

  useOnClickOutside(containerRef, () => {
    if (mounted.current) {
      if (showDropdown) {
        handleCloseRequest();
        setShowDropdown(false);
        onOpen(false);
        setSearchText("");
        setSearchQuery("");
      }
    }
  });

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const renderOption = (option: any) => {
    const isSelected = findIndex(selectedItems, { value: option.value }) >= 0;
    return customOptionComponent ? (
      customOptionComponent(option, handleOptionClick, isSelected)
    ) : (
      <DropdownItem
        key={option.value}
        option={option}
        onClick={() => handleOptionClick(option)}
        found={isSelected}
        showAvatar={showAvatar}
        maxLabelWidth={maxOptionLabelWidth}
      />
    );
  };

  const renderPreviewItem = (item: any) => {
    return customPreviewItemComponent ? (
      customPreviewItemComponent(item, handleOptionClick)
    ) : (
      <div className="flex items-center justify-between w-full py-md px-0 border-t-0 border-x-0 border-b border-solid border-primary">
        <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="truncate">
          {item.label}
        </BodyText>
        <Button
          variant={BUTTON_VARIANTS.LINK}
          leadingIcon={<MinusCircle className="text-secondary" size={20} />}
          onClick={() => handleOptionClick(item)}
        />
      </div>
    );
  };

  return (
    <>
      <div className="flex w-full items-center justify-between">
        {label && (
          <div className="w-fit">
            <InputLabel
              label={label}
              keyId={keyId}
              uppercaseLabel={uppercaseLabel}
            />
          </div>
        )}
        {infoMessage && (
          <Button
            variant={BUTTON_VARIANTS.LINK}
            leadingIcon={
              <WarningCircle
                className="text-secondary"
                width={20}
                height={20}
              />
            }
            onClick={() => setShowInfoMessage(!showInfoMessage)}
          />
        )}
      </div>
      {showInfoMessage && (
        <div className="w-full p-md mb-sm text-xs font-medium text-primary rounded-lg bg-brand-lightest/50 mt-sm">
          {infoMessage}
        </div>
      )}

      <div
        className={`relative flex items-center justify-between w-full p-md mt-sm font-manrope cursor-pointer border border-solid rounded-lg ${
          showDropdown
            ? `bg-primary border-brand ${showDropdownClassName}`
            : "bg-accent border-transparent"
        }`}
        ref={containerRef}
        onClick={(e) => {
          e.stopPropagation();
          if (!mounted.current) return;

          setShowDropdown((prev) => {
            if (prev) {
              handleCloseRequest();
            }
            onOpen(!prev);
            return !prev;
          });
          setSearchText("");
          setSearchQuery("");
        }}
      >
        <div
          className={`text-xs ${
            showOnlyPlaceHolder || !selectedItems.length
              ? "font-medium text-secondary"
              : "font-bold text-primary"
          }`}
        >
          {selectedItems.length && !showOnlyPlaceHolder
            ? trimText(
                selectedItems.map((item) => item.label).join(", "),
                trimCharCount,
              )
            : placeholder}
        </div>
        <ChevronDownIcon
          width={16}
          height={16}
          className={`cursor-pointer text-secondary ${
            showDropdown ? "rotate-180" : ""
          }`}
        />

        <div
          className={`absolute top-full left-0 z-[1] flex-col w-full max-w-full py-sm px-0 overflow-hidden bg-primary rounded-md shadow-dropdown mt-xs ${
            showDropdown ? "flex" : "hidden"
          } ${showRemoveAllButton ? "max-h-[270px]" : "max-h-[240px]"}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {dropdownTitle && (
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="px-sm pt-sm z-[1]"
            >
              {dropdownTitle}
            </BodyText>
          )}

          {showSearchInput && (
            <div className="m-sm">
              <Input
                placeholder={searchPlaceholder}
                onChange={onSearch}
                value={searchText}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </div>
          )}

          <div
            className={`overflow-y-auto ${optionsContainerClassName}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {allowMulti && textAllSelect && (
              <div
                className="flex justify-between items-center py-sm px-md cursor-pointer"
                onClick={() =>
                  setSelectedItems((prev) =>
                    prev.length === options.length ? [] : options,
                  )
                }
              >
                <div className="flex items-center">
                  {showAvatar && (
                    <Avatar className="mr-sm" name={textAllSelect} />
                  )}
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    className={`${maxOptionLabelWidth} truncate`}
                  >
                    {textAllSelect}
                  </BodyText>
                </div>
                {selectedItems.length === options.length && (
                  <span className="flex items-center justify-center ml-sm">
                    <Check width={16} height={16} className="text-brand" />
                  </span>
                )}
              </div>
            )}
            {options.map((option, index) => (
              <Fragment key={index}>
                {renderOption(option)}
                {index === options.length - ITEMS_BEFORE_PAGE ? (
                  <Waypoint onEnter={onScrollToBottom} />
                ) : null}
              </Fragment>
            ))}
            {isLoading ? (
              <span className="px-lg py-sm font-manrope text-xs font-medium text-primary">
                {messages?.common?.loading}
              </span>
            ) : null}

            {!options?.length && !isLoading && (
              <span className="px-lg py-sm font-manrope text-xs font-medium text-primary">
                {messages?.common?.noOption}
              </span>
            )}
          </div>

          {showRemoveAllButton && (
            <div className="flex w-full border-t border-b-0 border-x-0 border-solid border-primary px-md pt-md pb-2xs">
              <Label
                className={`${
                  selectedItems.length ? "cursor-pointer" : "cursor-default"
                }`}
                color={selectedItems.length ? "text-brand" : "text-disabled"}
                onClick={(e) => {
                  e.stopPropagation();

                  if (selectedItems.length) setSelectedItems([]);
                }}
                size={LABEL_SIZES.SMALL}
              >
                {messages["common"]["filters"]["removeAll"]}
              </Label>
            </div>
          )}
        </div>
      </div>
      {showSelectedOptionsPreviewList &&
        selectedItems.map((option) => (
          <Fragment key={option.value}>{renderPreviewItem(option)}</Fragment>
        ))}
    </>
  );
};

export default AsyncMultiSelectDropdown;

import { useMutation } from "@apollo/client";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { Input } from "@shared/ui/Inputs";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import PAID_FEATURES from "$/settings/paid-features.json";
import { VERIFY_GENERAL_SIGNUP_TOKEN_MUTATION } from "~/api";
import AuthPageLayout from "~/components/AuthPageLayout";
import MessageSpinner from "~/components/chat/_messageSpinner";
import { Loading, useAuth } from "~/components/general";
import { useCreateOem } from "~/services/oem";
import { UserType } from "~/types/user";

const Signup = () => {
  const { messages } = useIntl();
  const location = useLocation();
  const history = useHistory();
  const token = new URLSearchParams(location.search).get("token");

  const [credentialsSubmitted, setCredentialsSubmitted] = useState(false);
  // const { oemSignUp, signingUp } = useOemSignUp();
  const [tokenVerified, setTokenVerified] = useState(false);
  const [signupData, setSignupData] = useState({
    companyName: "",
    email: "",
    fullName: "",
    redirectUrl: null,
  });
  const { user, loading: userLoading } = useAuth() as {
    user: UserType;
    loading: boolean;
  };

  const { createOem, isCreating } = useCreateOem();
  const [verifySignupToken] = useMutation(VERIFY_GENERAL_SIGNUP_TOKEN_MUTATION);

  if (!token) history.push("/oem");

  const onCredentialsSubmit = () => {
    createOem({
      name: signupData.companyName,
      oemEmail: signupData.email,
      oemName: signupData.fullName,
      slug: signupData.companyName
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9\s-]/g, "")
        .replace(/\s+/g, "-")
        .replace(/-+/g, "-"),
      firstSignInRedirectUrl: signupData.redirectUrl,
      signupSource: PAID_FEATURES.aiNotetaker,
    })
      .then(() => {
        setCredentialsSubmitted(true);
      })
      .catch((error) => {
        toast(
          <Toast
            type={TOAST_TYPES.ERROR}
            message={error?.message || messages["common"]["signUp"]["failure"]}
          />,
          {
            closeButton: false,
          },
        );
      });
  };

  React.useEffect(() => {
    if (!token || userLoading || user) return;

    verifySignupToken({
      variables: { token },
    })
      .then((res) => {
        const response = res.data.verifyGeneralSignUpToken;
        setSignupData((prev) => ({
          ...prev,
          email: response.email,
          redirectUrl: response.redirectUrl,
        }));
        setTokenVerified(true);
      })
      .catch(() => {
        history.push("/oem");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  useEffect(() => {
    if (userLoading) return;
    if (user) history.push("/oem/app/work-orders");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading, user]);

  if (!tokenVerified) return <Loading />;

  return (
    <AuthPageLayout>
      <div className="flex flex-col m-auto space-y-3xl">
        {!credentialsSubmitted ? (
          <>
            <div>
              <Headline size={HEADLINE_SIZES.MEDIUM}>
                {messages["common"]["signUp"]["title"]}
              </Headline>
              <BodyText size={BODY_TEXT_SIZES.SMALL} color="text-secondary">
                {messages["common"]["signUp"]["subTitle"]}
              </BodyText>
            </div>
            <div className="space-y-lg">
              <Input
                keyId="company-name"
                label={messages["common"]["signUp"]["companyName"]["label"]}
                onChange={({ target: { value } }) =>
                  setSignupData((prev) => ({
                    ...prev,
                    companyName: value,
                  }))
                }
                placeholder={
                  messages["common"]["signUp"]["companyName"]["placeholder"]
                }
                value={signupData.companyName}
              />
              <Input
                keyId="full-name"
                label={messages["common"]["signUp"]["fullName"]["label"]}
                onChange={({ target: { value } }) =>
                  setSignupData((prev) => ({
                    ...prev,
                    fullName: value,
                  }))
                }
                placeholder={
                  messages["common"]["signUp"]["fullName"]["placeholder"]
                }
                value={signupData.fullName}
              />
              <Input
                keyId="email-address"
                label={messages["common"]["signUp"]["email"]["label"]}
                type="email"
                disabled
                value={signupData.email}
              />
              {isCreating ? (
                <MessageSpinner />
              ) : (
                <Button
                  text={messages["common"]["signUp"]["createAccountButton"]}
                  onClick={() => onCredentialsSubmit()}
                  disabled={
                    !signupData.companyName.trim() ||
                    !signupData.fullName.trim()
                  }
                  className="p-2xs w-full"
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div>
              <Headline size={HEADLINE_SIZES.MEDIUM}>
                {messages["common"]["signUp"]["successTitle"]}
              </Headline>
              <BodyText
                size={BODY_TEXT_SIZES.SMALL}
                color="text-secondary"
                className="mt-sm"
              >
                <div>{messages["common"]["signUp"]["successMessage1"]}</div>
                <div>{messages["common"]["signUp"]["successMessage2"]}</div>
              </BodyText>
            </div>
            <div className="space-y-2xl">
              <Input
                keyId="email-address"
                label={messages["common"]["signUp"]["email"]["label"]}
                type="email"
                disabled
                value={signupData.email}
              />
              <Button
                text={messages["common"]["signUp"]["signInButton"]}
                onClick={() => history.push("/app")}
                className="p-2xs w-full"
              />
            </div>
          </>
        )}
      </div>
    </AuthPageLayout>
  );
};

export default Signup;

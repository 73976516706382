import Tabs, { type TabType } from "@shared/ui/Tabs";
import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

type UnderlineTabType = {
  id: string;
  restricted?: boolean;
  label: string;
  rightContent?: React.ReactNode;
  className?: string;
  content: React.JSX.Element;
};

type UnderlineTabsWrapperPropsType = {
  tabs: UnderlineTabType[];
  currentTab?: string;
  className?: string;
  tabsClassName?: string;
  onChangeTab?: (tabTitle: string) => void;
  onRestrictedTabChange?: (tabTitle: string) => void;
  headerComponent?: () => React.JSX.Element;
  useHistoryState?: boolean;
  hideOverflow?: boolean;
};

const UnderlineTabsWrapper = ({
  tabs,
  currentTab = "",
  className = "",
  tabsClassName = "",
  onChangeTab = () => {},
  onRestrictedTabChange = () => {},
  headerComponent = () => null,
  useHistoryState = true,
  hideOverflow = true,
}: UnderlineTabsWrapperPropsType) => {
  const history = useHistory();

  const validTabs: UnderlineTabType[] = useMemo(
    () => tabs.filter((item) => !!item),
    [tabs],
  );

  const getActiveTab = (initialActiveTab: string): string => {
    if (useHistoryState) {
      const validIds: string[] = validTabs.map((tab) => tab.id);
      if (
        history.location.state?.activeTab &&
        validIds.includes(history.location.state?.activeTab)
      ) {
        return history.location.state?.activeTab;
      }
    }
    return initialActiveTab || validTabs[0].id;
  };

  const [activeTab, setActiveTab] = useState<string>(getActiveTab(currentTab));

  const onClickTabTitle = (tabId: string) => {
    if (tabId !== activeTab) {
      const selectedTab = validTabs.find((tab) => tab.id === tabId);

      if (selectedTab?.restricted) {
        onRestrictedTabChange(selectedTab.label);
        return;
      }
      setActiveTab(tabId);
      useHistoryState &&
        history.replace({
          ...history,
          state: { ...history.location.state, activeTab: tabId },
        });

      onChangeTab(selectedTab.label);
    }
  };

  useEffect(() => {
    useHistoryState && setActiveTab(getActiveTab(""));
  }, [history.location.state?.activeTab, validTabs]);

  const tabsHeader: TabType[] = validTabs.map(
    (tab) =>
      ({
        id: tab.id,
        label: tab.label,
        isActive: tab.id === activeTab,
        rightContent: tab.rightContent,
      }) as TabType,
  );

  const selectedTab: UnderlineTabType = useMemo(
    () => validTabs.find((tab) => tab.id === activeTab),
    [validTabs, activeTab],
  );

  return (
    <div className={`mt-xs ${tabsClassName || ""}`}>
      <div
        className={`flex items-center justify-between mb-2xl ${
          hideOverflow ? "overflow-hidden" : ""
        }`}
      >
        <Tabs
          tabs={tabsHeader}
          onTabChange={onClickTabTitle}
          className={`max-md:px-xl overflow-y-scroll no-scrollbar ${className}`}
        />
        {headerComponent()}
      </div>
      <div className={`max-md:px-xl ${selectedTab?.className || ""}`}>
        {selectedTab?.content}
      </div>
    </div>
  );
};

export default UnderlineTabsWrapper;

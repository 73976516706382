import * as React from "react";
const SvgNoTickets = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 220 220",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#no-tickets-svg)"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#A2AAB8",
  d: "M90.169 190.774c8.37-4.899 93.194-54.596 96.71-57.726 2.205-1.976 2.096-3.797 2.096-3.797a3.324 3.324 0 0 0-.536-1.575c-1.185-1.9-5.998-9.817-12.584-20.661"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M89.51 192a5.5 5.5 0 0 1-3.895-1.608l-14.353-14.353.952-.926a20.033 20.033 0 1 0-28.327-28.327l-.926.952-14.35-14.349a5.519 5.519 0 0 1 0-7.804l96.974-96.97A5.495 5.495 0 0 1 129.481 27a5.502 5.502 0 0 1 3.897 1.616l14.349 14.349-.949.926a20.047 20.047 0 0 0-4.484 6.502 20.04 20.04 0 0 0 4.244 22.062 20.029 20.029 0 0 0 28.564-.237l.929-.952 14.35 14.349a5.525 5.525 0 0 1 0 7.797l-96.973 96.98A5.506 5.506 0 0 1 89.509 192Zm-14.53-15.965 12.498 12.501a2.897 2.897 0 0 0 4.086 0l96.969-96.98a2.9 2.9 0 0 0 0-4.086l-12.502-12.49a22.447 22.447 0 0 1-15.256 5.93 22.705 22.705 0 0 1-22.674-22.678 22.457 22.457 0 0 1 5.926-15.264L131.53 30.471a2.888 2.888 0 0 0-3.146-.627 2.867 2.867 0 0 0-.936.627l-96.98 96.988a2.89 2.89 0 0 0 0 4.082l12.497 12.497a22.454 22.454 0 0 1 15.256-5.93 22.707 22.707 0 0 1 22.678 22.679 22.447 22.447 0 0 1-5.919 15.248Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M72.888 108.04 61.621 96.772a1.312 1.312 0 0 0-1.855 1.855l11.267 11.268a1.313 1.313 0 0 0 1.855-1.855Zm48.685 48.685-11.267-11.268a1.312 1.312 0 0 0-1.856 1.855l11.268 11.268a1.312 1.312 0 0 0 1.855-1.855ZM97.25 132.401l-11.268-11.268a1.313 1.313 0 0 0-1.855 1.856l11.267 11.267a1.31 1.31 0 1 0 1.856-1.855Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#E6E8FE",
  d: "M103.01 83.837c9.338-9.338 9.338-24.478 0-33.816-9.338-9.338-24.478-9.338-33.816 0-9.338 9.338-9.338 24.478 0 33.816 9.338 9.338 24.478 9.338 33.816 0Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M86.102 92.156a25.227 25.227 0 1 1 25.238-25.227 25.257 25.257 0 0 1-25.238 25.227Zm0-47.827a22.603 22.603 0 1 0 22.614 22.6 22.626 22.626 0 0 0-22.614-22.6Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#0517F8",
  d: "M95.978 74.023 80.088 59.09a1.402 1.402 0 1 0-1.92 2.043l15.89 14.932a1.402 1.402 0 1 0 1.92-2.043Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#0517F8",
  d: "M93.832 58.294 78.456 73.542a1.476 1.476 0 0 0 2.079 2.097L95.91 60.39a1.476 1.476 0 1 0-2.079-2.096Z"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "no-tickets-svg"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M27 27h165v165H27z"
}))));
export default SvgNoTickets;

const hasAllKeyValuePairs = (obj: any, subset: any) => {
  return Object.entries(subset).every(
    ([key, value]) => obj.hasOwnProperty(key) && obj[key] === value,
  );
};

export const findIndex = (array: any[], item: any) => {
  for (let i = 0; i < array.length; i++) {
    if (hasAllKeyValuePairs(array[i], item)) return i;
  }
  return -1;
};

import {
  PRODUCT_TYPES,
  TIER_TYPES,
  LIMIT_TYPES,
} from "@shared/constants/paidFeaturesHierarchy";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import { Progress } from "@shared/ui/Indicators";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { convertSecondsToReadableForm } from "#/src/utils/aiNotetaker";
import { appConfigAtom } from "~/atoms/appConfig";
import { useAuth } from "~/components/general";
import PlanPricingModal from "~/components/PlanPricingModal";
import {
  VIEW_ASSET_HUB_PRICING_PLAN,
  VIEW_INDUSTRIAL_AI_PRICING_PLAN,
} from "~/constants";
import { useGetCurrentUserAiAssistantUsage } from "~/services";
import { useListAllOwnOemAssets } from "~/services/asset";
import type { UserType } from "~/types/user";
import { registerMixpanelEvent } from "~/utils/_mixpanel";
import { convertStorageBytesToReadableForm } from "~/utils/aiAssistant";

const LIMITS_TO_SHOW = [
  LIMIT_TYPES.ASSET,
  LIMIT_TYPES.AI_STORAGE,
  LIMIT_TYPES.AI_NOTETAKER_RECORDING,
];

const preProcessLimit = (limit: number, limitType?: string) => {
  if (limitType === LIMIT_TYPES.AI_STORAGE) {
    return convertStorageBytesToReadableForm(limit, 0);
  }
  if (limitType === LIMIT_TYPES.AI_NOTETAKER_RECORDING) {
    return convertSecondsToReadableForm(limit, true, 0);
  }
  return limit;
};

const PlanLimit = ({ featureKey }: { featureKey: string }) => {
  const { messages } = useIntl();
  const appConfig = useRecoilValue(appConfigAtom);
  const [showPlanPricingModal, setShowPlanPricingModal] = useState(false);
  const feature = appConfig.plans.find(
    (feature) => feature.type === featureKey,
  );

  const location = useLocation();
  const isAINotetaker = location.pathname.startsWith("/app/ai/notetaker");

  const { data: userAiAssistantUsage } = useGetCurrentUserAiAssistantUsage();
  const consumedStorage =
    userAiAssistantUsage?.aiAssistantConfiguration.consumedStorage;

  const consumedRecordingSeconds =
    userAiAssistantUsage?.consumedRecordingSeconds;

  const { user } = useAuth() as { user: UserType };
  const relevantProduct = user?.oem?.installedProducts.find(
    (product) => product.type === featureKey,
  );
  const installedTier = relevantProduct?.tier;

  const tierConfig = feature?.tiers.find((tier) => tier.type === installedTier);

  const { totalCount: assetsTotalCount, error: assetError } =
    useListAllOwnOemAssets({
      limit: 1,
      skip: 0,
      skipCondition: relevantProduct?.type !== PRODUCT_TYPES.ASSET_HUB,
    });

  if (
    installedTier !== TIER_TYPES.FREE ||
    !installedTier ||
    tierConfig?.limits.length === 0
  )
    return null;

  const LIMIT_MAP = {
    [LIMIT_TYPES.ASSET]: {
      count: assetsTotalCount,
      error: assetError,
      showIf: true,
    },
    [LIMIT_TYPES.AI_STORAGE]: {
      count: consumedStorage,
      error: "",
      showIf: !isAINotetaker,
    },
    [LIMIT_TYPES.AI_NOTETAKER_RECORDING]: {
      count: consumedRecordingSeconds,
      error: "",
      showIf: isAINotetaker,
    },
  };

  return (
    <div className="flex flex-none border-0 border-primary border-y border-r border-solid w-56xl">
      <div className="flex flex-col px-lg py-2xl space-y-lg w-full">
        <div className="space-y-md flex flex-col">
          <Label size={LABEL_SIZES.MEDIUM}>
            {`${tierConfig?.name} ${messages.common["plan"]}`}
          </Label>
          {tierConfig?.limits.map(
            (limit) =>
              LIMITS_TO_SHOW.includes(limit.type) &&
              !LIMIT_MAP[limit.type]?.error &&
              LIMIT_MAP[limit.type]?.showIf && (
                <>
                  <Progress
                    value={LIMIT_MAP[limit.type]?.count}
                    max={limit.value}
                  />
                  <div className="flex justify-between">
                    <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                      {messages.plans["limits"][limit.type]}{" "}
                    </BodyText>
                    <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                      {preProcessLimit(
                        LIMIT_MAP[limit.type]?.count,
                        limit.type,
                      )}
                      /{preProcessLimit(limit.value, limit.type)}
                    </BodyText>
                  </div>
                </>
              ),
          )}
          <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
            {messages.plans["upgrade"][featureKey]}
          </BodyText>
          <Button
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={() => {
              setShowPlanPricingModal(true);
              switch (featureKey) {
                case PRODUCT_TYPES.INDUSTRIAL_AI:
                  registerMixpanelEvent(VIEW_INDUSTRIAL_AI_PRICING_PLAN);
                  break;
                case PRODUCT_TYPES.ASSET_HUB:
                  registerMixpanelEvent(VIEW_ASSET_HUB_PRICING_PLAN);
                  break;
                default:
                  break;
              }
            }}
            text={messages.plans["upgradePlan"]}
          />
        </div>
      </div>
      {showPlanPricingModal && (
        <PlanPricingModal
          productType={relevantProduct?.type}
          onClose={() => setShowPlanPricingModal(null)}
          installedTier={relevantProduct?.tier}
        />
      )}
    </div>
  );
};

export default PlanLimit;

import * as React from "react";
const Svg3DActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("rect", {
  width: 32,
  height: 32,
  rx: 6,
  fill: "#ECB800"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M18.275 17.9c.732 0 1.325-.593 1.326-1.325v-1.15c0-.733-.594-1.327-1.326-1.327h-.81v3.803h.81Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M24 19.818V12.18c0-.784-.408-1.496-1.043-1.82l-6.222-3.182a1.599 1.599 0 0 0-1.471 0L9.04 10.36C8.407 10.685 8 11.397 8 12.18v7.637c0 .784.408 1.496 1.042 1.82l6.223 3.182a1.6 1.6 0 0 0 1.47 0l6.223-3.181c.635-.325 1.042-1.037 1.042-1.821Zm-10.959-6.92h.004c1.06 0 1.92.86 1.92 1.92 0 .453-.17.867-.449 1.182.28.314.448.728.448 1.181 0 1.06-.859 1.92-1.92 1.92h-.003a1.9 1.9 0 0 1-1.823-1.404.6.6 0 1 1 1.159-.314.7.7 0 0 0 .67.518.72.72 0 0 0 .717-.72.581.581 0 0 0-.581-.581.6.6 0 0 1 0-1.2c.321 0 .581-.26.581-.582a.72.72 0 0 0-.717-.72.7.7 0 0 0-.67.518.6.6 0 1 1-1.159-.314 1.9 1.9 0 0 1 1.823-1.403Zm3.224.6a.6.6 0 0 1 .6-.6h1.41a2.526 2.526 0 0 1 2.526 2.526v1.152a2.527 2.527 0 0 1-2.526 2.525h-1.41a.6.6 0 0 1-.6-.6v-5.003Z",
  fill: "#fff"
}));
export default Svg3DActive;

import { RecoilState, atom } from "recoil";

import type { AppConfigType } from "~/types/appConfig";

export const appConfigAtom: RecoilState<AppConfigType> = atom({
  key: "appConfig",
  default: {
    _id: null,
    features: [],
    plans: [],
  },
});

import * as React from "react";
const SvgLeaveByDoor = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 130,
  height: 130,
  viewBox: "0 0 130 130",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_5180_146410)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M114.246 75.2417C114.104 75.2417 113.964 75.1975 113.848 75.1151C113.731 75.0327 113.643 74.9161 113.595 74.7815L111.649 69.2136C111.588 69.0409 111.598 68.8511 111.677 68.6858C111.756 68.5206 111.897 68.3934 112.07 68.3324C112.243 68.2714 112.432 68.2815 112.598 68.3604C112.763 68.4394 112.89 68.5808 112.951 68.7535L114.9 74.319C114.93 74.4048 114.943 74.4957 114.938 74.5865C114.933 74.6773 114.91 74.7662 114.87 74.8481C114.831 74.93 114.775 75.0033 114.707 75.0637C114.639 75.1241 114.56 75.1705 114.474 75.2002C114.401 75.2264 114.324 75.2404 114.246 75.2417Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M121.458 75.2418C121.38 75.2416 121.302 75.2284 121.228 75.2027C121.143 75.1727 121.064 75.1262 120.996 75.0656C120.928 75.0051 120.873 74.9318 120.834 74.85C120.794 74.7681 120.772 74.6793 120.767 74.5886C120.762 74.4979 120.775 74.4071 120.805 74.3215L122.751 68.7559C122.812 68.5832 122.94 68.4418 123.105 68.3629C123.27 68.2839 123.46 68.2738 123.633 68.3349C123.805 68.3959 123.947 68.523 124.026 68.6883C124.105 68.8535 124.115 69.0434 124.054 69.2161L122.103 74.7793C122.056 74.9133 121.969 75.0296 121.854 75.1123C121.738 75.1951 121.6 75.2403 121.458 75.2418Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M118.073 73.0239C117.89 73.0239 117.714 72.9512 117.585 72.8218C117.456 72.6923 117.383 72.5168 117.383 72.3337L117.406 66.4368C117.406 66.2538 117.479 66.0782 117.608 65.9487C117.737 65.8193 117.913 65.7466 118.096 65.7466C118.279 65.7466 118.455 65.8193 118.584 65.9487C118.714 66.0782 118.786 66.2538 118.786 66.4368L118.763 72.336C118.763 72.5187 118.69 72.6936 118.56 72.8226C118.431 72.9515 118.256 73.0239 118.073 73.0239Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M112.878 92.4812C112.695 92.4934 112.515 92.4324 112.377 92.3116C112.239 92.1908 112.154 92.0201 112.142 91.837C112.13 91.6539 112.191 91.4735 112.312 91.3355C112.433 91.1974 112.603 91.113 112.786 91.1008L115.925 90.6728C116.106 90.6481 116.29 90.6965 116.436 90.8074C116.582 90.9183 116.678 91.0826 116.702 91.2641C116.727 91.4456 116.679 91.6296 116.568 91.7754C116.457 91.9212 116.293 92.0171 116.111 92.0418L112.973 92.4812C112.941 92.4835 112.91 92.4835 112.878 92.4812Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M112.387 89.8496C112.235 89.8499 112.088 89.8002 111.967 89.7081C111.846 89.6161 111.759 89.4869 111.719 89.3404C111.68 89.194 111.689 89.0385 111.747 88.8981C111.804 88.7576 111.906 88.64 112.037 88.5634L114.775 86.9529C114.854 86.9 114.942 86.8638 115.035 86.8465C115.128 86.8293 115.224 86.8313 115.316 86.8526C115.408 86.8738 115.495 86.9137 115.571 86.97C115.647 87.0262 115.711 87.0974 115.758 87.1793C115.806 87.2611 115.836 87.3519 115.847 87.4459C115.858 87.5399 115.849 87.6351 115.822 87.7256C115.794 87.8161 115.748 87.9001 115.687 87.9721C115.626 88.0442 115.55 88.103 115.465 88.1447L112.727 89.7552C112.624 89.8157 112.507 89.8482 112.387 89.8496Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M76.7525 121.895C76.6265 121.895 76.5005 121.888 76.3752 121.874L26.9844 116.338V10.5875L75.9173 8.27294C76.3728 8.2483 76.8285 8.31725 77.2562 8.47554C77.684 8.63382 78.0748 8.87809 78.4045 9.19325C78.7365 9.50611 79.0013 9.88326 79.1829 10.3018C79.3644 10.7202 79.4589 11.1713 79.4606 11.6275L80.137 118.48C80.1394 118.926 80.0537 119.369 79.8847 119.781C79.7157 120.194 79.4669 120.57 79.1524 120.886C78.8379 121.203 78.464 121.454 78.0521 121.625C77.6403 121.797 77.1986 121.885 76.7525 121.885V121.895ZM28.3648 115.105L76.5294 120.503C76.8108 120.534 77.0956 120.505 77.3651 120.418C77.6346 120.332 77.8827 120.189 78.0931 119.999C78.3034 119.81 78.4713 119.578 78.5857 119.319C78.7 119.06 78.7582 118.779 78.7565 118.496L78.0801 11.6459C78.0785 11.3757 78.0224 11.1085 77.915 10.8605C77.8076 10.6125 77.6513 10.3888 77.4553 10.2027C77.2594 10.0166 77.0278 9.87205 76.7746 9.77766C76.5214 9.68327 76.2517 9.64101 75.9818 9.65341L28.3556 11.9013L28.3648 115.105Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M78.7812 13.6567L88.1454 14.1169C88.5023 14.1343 88.8392 14.2865 89.0882 14.5427C89.3372 14.799 89.4796 15.1402 89.4868 15.4974C90.0067 47.5426 90.5275 79.5894 91.049 111.638C91.0519 111.829 91.0161 112.018 90.9437 112.194C90.8714 112.371 90.7641 112.531 90.6282 112.665C90.4923 112.799 90.3307 112.904 90.1531 112.974C89.9755 113.044 89.7856 113.077 89.5949 113.071L79.4094 112.763",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M89.6364 114.007H89.5673L79.3818 113.697L79.437 111.856L89.6226 112.164C89.689 112.166 89.7551 112.155 89.8169 112.13C89.8787 112.106 89.9349 112.069 89.9822 112.023C90.0295 111.976 90.0668 111.92 90.092 111.859C90.1172 111.797 90.1297 111.732 90.1287 111.665L88.5665 15.5271C88.5639 15.4037 88.5153 15.2857 88.4302 15.1963C88.345 15.1069 88.2295 15.0525 88.1064 15.0439L78.7422 14.5838L78.8342 12.7432L88.1984 13.2033C88.7905 13.2347 89.3486 13.4898 89.7599 13.9168C90.1711 14.3439 90.405 14.9112 90.414 15.5041L91.9763 111.644C91.9811 111.954 91.9244 112.261 91.8094 112.549C91.6943 112.836 91.5232 113.097 91.3061 113.318C91.089 113.539 90.8301 113.714 90.5447 113.833C90.2592 113.953 89.9528 114.014 89.6433 114.014L89.6364 114.007Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M70.3469 69.6715C68.6213 69.6715 67.2109 68.0402 67.2109 66.0455C67.2109 64.0507 68.6213 62.4194 70.3469 62.4194C72.0725 62.4194 73.4829 64.0507 73.4829 66.0455C73.4829 68.0402 72.0725 69.6715 70.3469 69.6715Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M80.5703 72.2944C82.2364 74.2111 84.2041 75.8431 86.3959 77.1261C87.8707 77.9889 90.7144 79.627 94.6603 80.025C96.846 80.2551 98.6222 80.0389 101.102 79.749C102.69 79.564 104.268 79.2983 105.828 78.9529C105.537 82.5421 105.248 86.1313 104.961 89.7205C103.661 90.432 102.295 91.0153 100.882 91.4622C98.8431 92.1182 96.7206 92.4763 94.5798 92.5252C92.1012 92.5358 89.6409 92.1009 87.3162 91.2413C86.332 90.8519 85.369 90.4111 84.431 89.9207C82.8366 89.1315 81.5435 88.3907 80.6301 87.85C80.6117 82.6548 80.5918 77.4697 80.5703 72.2944Z",
  fill: "#0517F8"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M94.4227 93.096C91.9269 93.0852 89.4522 92.6374 87.1108 91.7731C86.1917 91.414 85.2923 91.0061 84.4166 90.5513L84.1704 90.4294C82.8613 89.7806 81.5682 89.0719 80.3281 88.3242L80.052 88.1562L79.9922 70.7485L81.0068 71.9173C82.6309 73.7896 84.5497 75.3843 86.6875 76.6385C88.0357 77.4277 90.845 79.0704 94.7195 79.4593C96.8431 79.6733 98.5986 79.4593 101.028 79.1855C102.597 79.0023 104.155 78.7396 105.696 78.3986L106.456 78.2284L105.51 90.0705L105.239 90.22C103.905 90.948 102.504 91.5459 101.056 92.0054C98.9627 92.6771 96.7837 93.0436 94.586 93.0937L94.4227 93.096ZM81.2001 87.5051C82.3275 88.1769 83.5009 88.812 84.6812 89.3987L84.9297 89.5229C85.7695 89.9579 86.6312 90.3495 87.5111 90.6963C89.7708 91.5299 92.1614 91.9523 94.5699 91.9433C96.6529 91.8948 98.7181 91.5461 100.702 90.908C101.98 90.503 103.22 89.984 104.406 89.3572L105.179 79.6825C103.851 79.9539 102.503 80.1702 101.155 80.329C98.6538 80.6189 96.8454 80.8305 94.5975 80.6051C90.4722 80.1886 87.518 78.4607 86.0985 77.6302C84.2833 76.5676 82.6169 75.2695 81.1426 73.7695L81.2001 87.5051Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M105.618 81.417L109.844 82.1072C110.449 82.2191 110.998 82.5293 111.406 82.9888C111.814 83.4483 112.057 84.0308 112.097 84.6441C112.137 85.2573 111.971 85.8662 111.625 86.3744C111.28 86.8826 110.774 87.261 110.19 87.4496L105.07 88.3561C105.25 86.0446 105.432 83.7316 105.618 81.417Z",
  fill: "#00A5BC"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M104.438 89.0511L105.1 80.7476L109.982 81.5482C110.703 81.6943 111.355 82.0736 111.838 82.6275C112.321 83.1814 112.608 83.8791 112.654 84.6125C112.701 85.3459 112.505 86.0743 112.096 86.6849C111.687 87.2955 111.088 87.7543 110.392 87.9904L110.295 88.0157L104.438 89.0511ZM106.147 82.0866L105.703 87.6614L110.045 86.8975C110.499 86.7384 110.889 86.434 111.153 86.0312C111.418 85.6285 111.542 85.15 111.507 84.6695C111.472 84.189 111.28 83.7334 110.961 83.373C110.641 83.0126 110.211 82.7675 109.739 82.6756L106.147 82.0866Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M117.116 97.7734C115.931 97.7734 114.967 97.5088 114.243 96.9819C112.903 96.0064 112.593 94.3383 112.124 91.8121C112.075 91.5406 112.034 91.2875 111.995 91.0459C111.831 90.0405 111.725 89.3755 111.166 88.7727C110.837 88.4254 110.433 88.1585 109.984 87.9928L110.398 86.9206C111.009 87.1495 111.56 87.5155 112.008 87.9905C112.802 88.8487 112.954 89.7828 113.127 90.8641C113.166 91.0942 113.205 91.3404 113.253 91.6004C113.686 93.938 113.943 95.3461 114.917 96.0524C115.607 96.5471 116.635 96.7219 117.997 96.5724C118.397 96.5285 118.79 96.4422 119.171 96.3147L116.357 80.0965H115.789C114.873 80.0699 113.958 80.1277 113.053 80.2691C112.377 80.4163 111.355 80.911 110.32 82.4387L109.367 81.7922C110.614 79.9515 111.928 79.3349 112.818 79.144C113.803 78.9846 114.801 78.9183 115.798 78.9461C116.258 78.9461 116.617 78.9461 116.865 78.9576H117.326L120.462 97.0325L120.038 97.2097C119.426 97.4686 118.78 97.6368 118.119 97.709C117.786 97.7489 117.452 97.7704 117.116 97.7734Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M109.191 93.4018C108.675 93.402 108.177 93.2157 107.788 92.8772C107.44 92.5666 107.042 91.9822 107.109 90.9561C107.203 89.4974 108.512 88.2389 109.693 87.6867C110.447 87.3324 111.062 87.3002 111.469 87.597C112.212 88.1376 111.869 89.2535 111.301 91.1033C110.806 92.7139 110.328 93.0912 109.92 93.2614C109.689 93.3552 109.441 93.4029 109.191 93.4018ZM110.746 88.5472C110.309 88.6337 109.9 88.8247 109.552 89.104C108.782 89.6676 108.298 90.3855 108.257 91.0297C108.227 91.4899 108.328 91.8166 108.554 92.019C108.678 92.1272 108.828 92.2007 108.99 92.2322C109.151 92.2638 109.319 92.2521 109.474 92.1985C109.628 92.1341 109.897 91.7245 110.194 90.7651C110.454 89.9299 110.765 88.9199 110.746 88.538V88.5472Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M121.033 98.1761C120.704 98.1687 120.381 98.0873 120.088 97.938C119.795 97.7886 119.539 97.5751 119.34 97.3133L120.246 96.6047C120.568 97.0165 121.065 97.1155 121.325 96.9498C121.402 96.8869 121.465 96.8094 121.512 96.7216C121.558 96.6339 121.586 96.5378 121.595 96.439L118.631 78.7759C118.405 78.737 118.172 78.756 117.955 78.8312C117.762 78.9096 117.588 79.0281 117.444 79.1789C117.301 79.3296 117.191 79.5091 117.122 79.7055L116.031 79.3419C116.155 78.9805 116.357 78.6507 116.622 78.3756C116.887 78.1005 117.209 77.8869 117.566 77.7498C118.147 77.5479 118.782 77.5626 119.354 77.7912L119.653 77.9132L122.75 96.393V96.4597C122.735 96.748 122.656 97.0292 122.517 97.282C122.378 97.5347 122.183 97.7524 121.947 97.9184C121.673 98.0898 121.356 98.1792 121.033 98.1761Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M68.5685 67.9482C66.9971 67.9482 65.7109 66.4619 65.7109 64.6442C65.7109 62.8266 66.9971 61.3403 68.5685 61.3403C70.1399 61.3403 71.4261 62.8266 71.4261 64.6442C71.4261 66.4619 70.1399 67.9482 68.5685 67.9482Z",
  fill: "#0517F8"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M68.5734 68.5234C66.6799 68.5234 65.1406 66.784 65.1406 64.6443C65.1406 62.5045 66.6799 60.7651 68.5734 60.7651C70.4669 60.7651 72.0062 62.5045 72.0062 64.6443C72.0062 66.784 70.4646 68.5234 68.5734 68.5234ZM68.5734 61.9155C67.3149 61.9155 66.291 63.1395 66.291 64.6443C66.291 66.149 67.3149 67.373 68.5734 67.373C69.8319 67.373 70.8558 66.149 70.8558 64.6443C70.8558 63.1395 69.8319 61.9155 68.5734 61.9155Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M79.6866 113.203L79.6719 114.583L114.49 114.954L114.505 113.574L79.6866 113.203Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M5.92485 112.421L5.91016 113.801L27.297 114.029L27.3117 112.649L5.92485 112.421Z",
  fill: "#172B4D"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_5180_146410"
}, /* @__PURE__ */ React.createElement("rect", {
  width: 118.182,
  height: 113.622,
  fill: "white",
  transform: "translate(5.91016 8.27295)"
}))));
export default SvgLeaveByDoor;

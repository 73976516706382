import React from "react";
import KanbanCardRenderer from "../_kanbanCardRenderer";
import { useDraggable } from "@dnd-kit/core";

const SortableCard = React.memo(
  ({ item, column, boardType, index, isTeamsPaid }) => {
    const {
      setNodeRef,
      listeners,
      isDragging,
      isSorting,
      over,
      overIndex,
      attributes,
    } = useDraggable({
      id: item?._id,
      data: {
        type: column?.boardFor,
        accept: column?.boardFor,
        item,
        columnId: column?._id,
        columnValue: column?.column,
        index,
      },
    });
    return KanbanCardRenderer(boardType, isTeamsPaid, {
      item,
      setNodeRef,
      listeners,
      attributes,
      isDragging,
      isSorting,
      over,
      overIndex,
    });
  },
);

export default SortableCard;

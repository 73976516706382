import { CalendarBlank, DotsThree, Repeat } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";

import ProgressBar from "~/components/form/_progressBar";
import EventTile from "~/components/preventiveMaintenance/EventTile";
import { HourglassIconSmall, ProgressLoadingIcon } from "~/icons/icons";
import { formatDate } from "~/utils";

const MainTile = ({
  tileVariant,
  title,
  machineName,
  eventId,
  eventDate,
  handleEventPreview,
  containerRef,
  repeatInNumber,
  repeatIn,
  totalProgress,
  progressColor,
  createdBy,
  machineFacilityName,
  machineFacilityId,
  machineId,
}: {
  tileVariant: boolean;
  title: string;
  machineName: string;
  eventId: string;
  eventDate: string;
  handleEventPreview: () => {};
  containerRef: React.RefObject<HTMLElement>;
  repeatInNumber: number;
  repeatIn: string;
  totalProgress: string;
  progressColor: string;
  machineFacilityName: string;
  machineFacilityId: string;
  machineId: string;
  createdBy: { name: string };
}) => {
  const {
    messages: { preventiveMaintenance: PM },
  } = useIntl();

  if (tileVariant) {
    return (
      <EventTile className="flex items-center">
        <EventTile.EventName
          eventName={title}
          machineName={machineName}
          machineFacilityName={machineFacilityName}
          machineFacilityId={machineFacilityId}
          machineId={machineId}
        />
        <div className="flex items-center u-flex-grow-1 justify-between pl-36">
          <EventTile.EvenDetail
            eventId={eventDate}
            label={"Due"}
            isDate={true}
          />
          <EventTile.EventCreator creator={createdBy} />
          <div className="flex items-center leading-none hover:cursor-pointer">
            <span onClick={handleEventPreview} ref={containerRef}>
              {/* @ts-ignore */}
              <DotsThree size={20} />
            </span>
          </div>
        </div>
      </EventTile>
    );
  }

  return (
    <div className="preventive-event-card">
      <div className="preventive-event-card-body">
        <div className="preventive-event-card-title-icon">
          <span className="pe-icon">
            <HourglassIconSmall />
          </span>

          <h3 className="title">{title}</h3>
        </div>
        <div className="meta-data">
          <div className="pe-row repeat">
            <span>
              <span className="pe-icon">
                <Repeat size={16} />
              </span>
              {PM?.repeatEvery}&nbsp; • &nbsp;
            </span>
            <span className="frequency-time">
              {repeatInNumber !== 0 ? (
                <>
                  {repeatInNumber} {repeatIn}
                </>
              ) : (
                <>{PM?.form.frequencyNoRepeat}</>
              )}
            </span>
          </div>

          <div className="pe-row date-time-progress">
            <span className="pe-icon">
              <CalendarBlank size={16} />
            </span>
            {PM?.maintenanceOn}&nbsp; • &nbsp;
            <span className="date">{formatDate(eventDate)}</span>
          </div>
          <div className="pe-row">
            <span className="pe-icon">
              <ProgressLoadingIcon />
            </span>
            {PM?.eventProgressText} &nbsp; • &nbsp;
            <span className="progress-text">{totalProgress}%</span>
            <span className="bar-wrapper">
              <ProgressBar completed={totalProgress} bgcolor={progressColor} />
            </span>
          </div>
        </div>
      </div>

      <div className="event-option-button">
        <span onClick={handleEventPreview} ref={containerRef}>
          <DotsThree size={20} />
        </span>
      </div>
    </div>
  );
};

export default MainTile;

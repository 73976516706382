import { TOAST_AUTO_CLOSE_DURATION } from "@shared/constants/index";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { v4 as UUID } from "uuid";

import { CSV_DOWNLOADING_TOAST_ID, CSV_RESOURCES } from "~/constants";
import useUpdatableToast from "~/hooks/_useToast";
import { useGetDataCsv } from "~/services";
import { generateFacilitiesQueryWhereCond } from "~/utils/filter/_facilitiesQueryCond";
import { generateOpenTicketQueryWhereCond } from "~/utils/filter/_openTicketQueryCond";
import { generateAssetsQueryWhereCond } from "~/utils/filter/assetsQueryCond";

interface ExportCSV {
  resource: (typeof CSV_RESOURCES)[string];
  searchQuery?: string;
  where?: any;
  activeFilters?: any;
}

const useCreateCsvExporter = () => {
  const { messages } = useIntl();
  const { startToast } = useUpdatableToast();
  const { fetchCsvData } = useGetDataCsv();

  const exportCsv = async ({
    resource,
    searchQuery = "",
    where = {},
    activeFilters = {},
  }: ExportCSV) => {
    const uuid = UUID();
    sessionStorage.setItem(uuid, uuid);

    startToast(
      messages?.common["downloadingCsv"],
      TOAST_TYPES.PROCESSING,
      false,
      {
        toastId: CSV_DOWNLOADING_TOAST_ID,
      },
    );

    const getFilteredConditions = (): Record<string, any> => {
      switch (resource) {
        case CSV_RESOURCES.TICKET:
          return generateOpenTicketQueryWhereCond(activeFilters);
        case CSV_RESOURCES.MACHINE:
          return generateAssetsQueryWhereCond(activeFilters);
        case CSV_RESOURCES.CUSTOMER:
          return generateFacilitiesQueryWhereCond(activeFilters);
        default:
          return {};
      }
    };

    const filterConditions = getFilteredConditions();
    const hasFilter = Object.keys(filterConditions)?.length;

    const variables = {
      params: {
        where: {
          ...(hasFilter ? filterConditions : {}),
          searchQuery,
          resource,
          uuid,
          ...where,
        },
      },
    };
    try {
      await fetchCsvData(variables);
      toast.update(CSV_DOWNLOADING_TOAST_ID, {
        autoClose: TOAST_AUTO_CLOSE_DURATION,
        render: (
          <Toast
            type={TOAST_TYPES.SUCCESS}
            message={messages.common["downloadCompleted"]}
          />
        ),
      });
    } catch (error) {
      toast.update(CSV_DOWNLOADING_TOAST_ID, {
        autoClose: TOAST_AUTO_CLOSE_DURATION,
        render: (
          <Toast
            type={TOAST_TYPES.ERROR}
            message={messages.common["csvDownloadFailed"]}
          />
        ),
      });
    }
  };
  return { exportCsv };
};

export default useCreateCsvExporter;

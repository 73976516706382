import DeleteIcon from "@shared/svg/delete-document.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import { BUTTON_VARIANTS } from "@shared/ui/Button";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import usePopper from "react-overlays/usePopper";
import { toast } from "react-toastify";

import PreviewEvent from "~/components/preventiveMaintenance/_eventPreview";
import MainTile from "~/components/preventiveMaintenance/MainTile";
import { deletePreventiveMaintenanceEvent } from "~/services/preventiveMaintenance";

const PreventiveEvent = ({
  eventId,
  title,
  startDate,
  repeatIn,
  repeatInNumber,
  eventDate,
  description,
  frequency,
  ticketCreationNumber,
  ticketCreationIn,
  nextCreationDate,
  onEditButtonClick,
  machineId,
  machineName,
  createdBy,
  refetchEvents,
  tileVariant = false,
  procedures = 0,
  inventoryParts = 0,
  machineFacilityName = "",
  machineFacilityId = "",
}) => {
  const containerRef = useRef();
  const popupRef = useRef();

  const [showPreview, setShowPreview] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // TODO: add machine ID
  const { deleteEvent } = deletePreventiveMaintenanceEvent(machineId);

  const { styles, attributes } = usePopper(
    containerRef.current,
    popupRef.current,
    {
      placement: "bottom-end",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 0],
          },
        },
      ],
    },
  );

  const {
    messages: { preventiveMaintenance: PM, common },
  } = useIntl();

  const calculateProgress = () => {
    const totalTime = Math.ceil(
      Math.abs(new Date(eventDate).getTime() - new Date(startDate).getTime()),
    );

    let timeElappsed = Math.ceil(
      Math.abs(new Date().getTime() - new Date(eventDate).getTime()),
    );
    if (timeElappsed > totalTime) timeElappsed = 0;
    const result = Math.abs(1 - timeElappsed / totalTime) * 100;

    return parseFloat(result.toFixed(2));
  };

  const progressColor =
    calculateProgress() < 74 ? "#00B09B" /* green */ : "#EE0064"; /* red */

  const handleEventDelete = async () => {
    return setShowDeleteModal(true);
  };

  const handleRemoveEventAcceptClick = async () => {
    try {
      await deleteEvent(eventId);
      setShowPreview(false);
      refetchEvents();
      toast(
        <Toast type={TOAST_TYPES.SUCCESS} message={PM?.successDeletion} />,
        {
          closeButton: false,
        },
      );
      return setShowDeleteModal(false);
    } catch (exception) {
      if (exception?.message === "Invalid Id") {
        setShowDeleteModal(false);
        return toast(
          <Toast type={TOAST_TYPES.ERROR} message={PM?.error?.eventNotFound} />,
          {
            closeButton: false,
          },
        );
      }
      toast(
        <Toast
          type={TOAST_TYPES.ERROR}
          message={common?.errors?.somethingWrong}
        />,
        {
          closeButton: false,
        },
      );
    }
  };

  const handleRemoveEventCancelClick = () => {
    setShowDeleteModal(false);
  };

  const handleEventEdit = () => {
    const date = new Date(eventDate);
    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth() + 1;

    const rin = repeatInNumber === 0 ? "" : repeatInNumber;
    const tcn = ticketCreationNumber === 0 ? "" : ticketCreationNumber;

    onEditButtonClick({
      _id: eventId,
      title,
      repeatIn,
      repeatInNumber: rin?.toString() || "",
      eventDate: {
        day,
        month,
        year,
      },
      description,
      frequency,
      ticketCreationNumber: tcn?.toString() || "",
      ticketCreationIn,
      machine: machineId,
      procedures,
      inventoryParts,
    });
    return setShowPreview(false);
  };

  const totalProgress = calculateProgress();
  const handleEventPreview = () => {
    setShowPreview(true);
  };
  const handlePreviewOutsideClick = () => {
    setShowPreview(false);
  };

  return (
    <>
      <MainTile
        tileVariant={tileVariant}
        title={title}
        machineName={machineName || ""}
        eventId={eventId}
        eventDate={eventDate}
        handleEventPreview={handleEventPreview}
        containerRef={containerRef}
        repeatInNumber={repeatInNumber}
        repeatIn={repeatIn}
        totalProgress={totalProgress}
        progressColor={progressColor}
        createdBy={createdBy}
        machineFacilityName={machineFacilityName}
        machineId={machineId}
        machineFacilityId={machineFacilityId}
      />
      <div
        ref={popupRef}
        style={{
          visibility: showPreview ? "visible" : "hidden",
          pointerEvents: showPreview ? "initial" : "none",
          ...styles.popper,
        }}
        {...attributes.popper}
        className="z-10"
      >
        <PreviewEvent
          isVisible={showPreview}
          eventId={eventId}
          onOutsideClick={handlePreviewOutsideClick}
          title={title}
          repeatIn={repeatIn}
          repeatInNumber={repeatInNumber}
          eventDate={eventDate}
          description={description}
          frequency={frequency}
          nextCreationDate={nextCreationDate}
          onDeleteButtonClick={handleEventDelete}
          onEditButtonClick={handleEventEdit}
          ticketCreationIn={ticketCreationIn}
          ticketCreationNumber={ticketCreationNumber}
          createdBy={createdBy}
          procedureCount={procedures?.length || 0}
          inventoryPartsCount={
            inventoryParts?.filter((part) => part?.quantity > 0).length || 0
          }
          tileVariant={tileVariant}
          machineName={machineName}
        />
      </div>

      <AlertBox
        title={PM?.deleteModalTitle}
        isOpen={showDeleteModal}
        acceptButtonText={PM?.deleteModalAcceptBtnText}
        acceptButtonVariant={BUTTON_VARIANTS.DANGER}
        cancelButtonText={PM?.deleteModalCancelBtnText}
        image={<DeleteIcon width="130" height="130" />}
        onAccept={handleRemoveEventAcceptClick}
        onCancel={handleRemoveEventCancelClick}
        overlay={true}
      />
    </>
  );
};

export default PreventiveEvent;

import * as React from "react";
const SvgSidebar = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7.499 2.5v15",
  stroke: "#A2AAB8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("rect", {
  x: 2.5,
  y: 2.5,
  width: 15,
  height: 15,
  rx: 4,
  stroke: "#A2AAB8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgSidebar;

import ConnectionLinkIcon from "@shared/svg/connection-link.svg?react";
import LeaveByDoorIcon from "@shared/svg/leave-by-door.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import { Avatar } from "@shared/ui/Avatar";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button from "@shared/ui/Button";
import Drawer from "@shared/ui/Drawer";
import { SingleSelectDropdown, IconDropdown } from "@shared/ui/Dropdowns";
import { ExternalIcon, EXTERNAL_ICON_SIZES } from "@shared/ui/Indicators";
import { Input } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import AppModal from "@shared/ui/Modal";
import TextEditor, { serialize } from "@shared/ui/TextEditor";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { Plus } from "phosphor-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import "leaflet/dist/leaflet.css";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import { CONTACT_ACCESS_STATUS } from "#/src/constants/connections";
import getEnums from "$/settings/enums";
import CUSTOM_FIELD_TYPES from "$/settings/enums/customAdditionalField/_type.json";
import WORK_ORDER_TYPES from "$/settings/enums/ticket/_types.json";
import { globalOpenTicketsAtom } from "~/atoms/_global";
import { PartDetailModalHeader } from "~/components/_partDetailModalComponents";
import useAuth from "~/components/general/_use-auth";
import {
  AddPartComp,
  CreateTicketAddedPartsComp,
} from "~/components/tickets/_spareParts";
import CustomFormElements from "~/components/tickets/CustomFormElements";
import WorkOrderTypeIcons from "~/components/tickets/WorkOrderTypeIcons";
import { COLOR, ITEMS_PER_PAGE } from "~/constants";
import { InfoIcon } from "~/icons/icons";
import {
  createTicket,
  useListAllOwnOemCustomers,
  useListOwnOemFacilityUsers,
  useListOwnOemCustomFields,
  useGetWorkOrderTypes,
} from "~/services";
import {
  useListOwnOemMachineHistory,
  useGetAssetsPartsById,
  useListAllOwnOemAssets,
} from "~/services/asset";
import { useListContacts } from "~/services/contact";
import { errorsHandler } from "~/transformers/_errorsHandler";
import { capitalizeFirstLetter, getMachineThumbnail } from "~/utils";
import { generateStatusBackgroundColor } from "~/utils/_colors";
import { useIsGrowthLoopEnabled } from "~/utils/appFeatures";

const CustomFieldTypes = getEnums(CUSTOM_FIELD_TYPES, "reference");

const Create = ({
  btnLabel = "",
  emailThreadId = null,
  btnModifiers = null,
  onCreate = () => {},
  onOpenDrawer = () => {},
  onDrawerClose = () => {},
  onSubmitSuccess = () => {},
  showButton = true,
  button = null,
}) => {
  const { messages } = useIntl();
  const { user } = useAuth() || {};
  const { teams = [] } = user;
  const workOrderTypes = useGetWorkOrderTypes();

  const filteredWorkOrderTypes = useMemo(() => {
    const disallowedTicketTypes = teams?.reduce((acc, team) => {
      return acc.concat(team.disallowedTicketTypes);
    }, []);
    return workOrderTypes.filter(
      (type) =>
        !disallowedTicketTypes.map((type) => type._id).includes(type._id),
    );
  }, [teams, workOrderTypes]);

  const isGrowthLoopEnabled = useIsGrowthLoopEnabled();

  const assetTypeOptions = useMemo(
    () =>
      user?.oem?.assetTypes?.map((type) => ({
        label: type.name,
        value: type._id,
      })) || [],
    [user?.oem?.assetTypes],
  );

  const [ticketCoil, setTicketCoil] = useRecoilState(globalOpenTicketsAtom);

  const { isPartsRequest = false, isDemoModel = false } = ticketCoil;
  const [showAddPartModal, setShowAddPartModal] = useState(false);
  const [tempSelectedPartsCount, setTempSelectedPartsCount] = useState(0);
  const [addedParts, setAddedParts] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const [searchFacilityQuery, setSearchFacilityQuery] = useState("");
  const [selectedWorkOrderType, setSelectedWorkType] = useState(null);
  const [customFieldsData, setCustomFieldsData] = useState({});
  const [customFieldsErrors, setCustomFieldsErrors] = useState({});
  const [selectedAssetType, setSelectedAssetType] = useState(null);

  const isExternalChange = useRef(false);
  const tempSelectedParts = useRef({});

  const { newTicket } = ticketCoil;
  const isMachineSelected = Boolean(newTicket?.selectedMachine?.length);
  const [partForModalPreview, setPartForModalPreview] = useState(null);

  const { customAdditionalFields: inventoryCustomAdditionalFields } =
    useListOwnOemCustomFields({ type: CustomFieldTypes.parts });

  const initialNewTicketData = {
    errors: {
      facilityError: "",
      machineError: "",
      reporterError: "",
      titleError: "",
      ticketTypeError: "",
      descriptionError: "",
    },
    selectedFacility: [],
    selectedMachine: [],
    selectedReporter: [],
    ticketDescription: "",
    ticketTitle: "",
    ticketType: null,
  };
  const {
    selectedFacility,
    selectedMachine,
    selectedReporter,
    assetType: defaultSelectedAssetType,
    ticketDescription,
    ticketTitle,
    ticketType,
  } = newTicket;

  useEffect(() => {
    if (defaultSelectedAssetType?.value) {
      isExternalChange.current = true;
      setSelectedAssetType(defaultSelectedAssetType);
      setTimeout(() => {
        isExternalChange.current = false;
      }, 0);
    }
  }, [defaultSelectedAssetType]);

  const {
    mutation: createTicketMutation,
    loading: createTicketLoading,
    serverErrors,
  } = createTicket();
  const { refetch } = useListOwnOemMachineHistory();

  const {
    users,
    handleFetchMore: handleFetchMoreUsers,
    loading: usersLoading,
    totalCount: usersTotalCount,
    userOptions,
  } = useListOwnOemFacilityUsers({
    where: {
      facility: selectedFacility?.[0]?.value,
    },
    limit: ITEMS_PER_PAGE,
    skip: 0,
    searchQuery: userSearchQuery,
    skipCondition: isGrowthLoopEnabled,
  });

  const {
    contacts,
    handleFetchMore: handleFetchMoreContacts,
    loading: contactsLoading,
    totalCount: contactsTotalCount,
    contactOptions,
  } = useListContacts({
    connectionId: selectedFacility?.[0]?.value,
    limit: ITEMS_PER_PAGE,
    skip: 0,
    searchQuery: userSearchQuery,
    skipCondition: !isGrowthLoopEnabled,
  });

  const handleScrollBottomUsers = (event) => {
    if (
      users.length >= ITEMS_PER_PAGE &&
      !usersLoading &&
      event?.previousPosition !== "above" &&
      usersTotalCount > users.length
    ) {
      handleFetchMoreUsers({
        limit: ITEMS_PER_PAGE,
        skip: users.length,
      });
    }
  };

  const handleScrollBottomContacts = (event) => {
    if (
      contacts.length >= ITEMS_PER_PAGE &&
      !contactsLoading &&
      event?.previousPosition !== "above" &&
      contactsTotalCount > contacts.length
    ) {
      handleFetchMoreContacts({
        limit: ITEMS_PER_PAGE,
        skip: contacts.length,
      });
    }
  };

  const {
    customers: facilities = [],
    loading: facilitiesLoading,
    handleFetchMore: handleFetchMoreFacilities,
    totalCount: totalFacilitiesCount,
  } = useListAllOwnOemCustomers({
    limit: ITEMS_PER_PAGE,
    skip: 0,
    searchQuery: searchFacilityQuery,
  });

  const customerOptions = React.useMemo(
    () =>
      facilities?.map((facility) => ({
        ...facility,
        label: facility?.name,
        value: facility._id,
        description: facility?.facilityId,
      })),
    [facilities],
  );

  const handleScrollBottomFacilities = (event) => {
    if (
      facilities.length >= ITEMS_PER_PAGE &&
      !facilitiesLoading &&
      event?.previousPosition !== "above" &&
      totalFacilitiesCount > facilities.length
    ) {
      handleFetchMoreFacilities({
        limit: ITEMS_PER_PAGE,
        skip: facilities.length,
      });
    }
  };

  const handleSearchCustomerQuery = (value) => {
    if (searchFacilityQuery !== value) {
      setSearchFacilityQuery(value);
    }
  };

  const {
    assets = [],
    handleFetchMore: handleFetchMoreAssets,
    assetsListLoading,
    totalCount: assetsTotalCount,
  } = useListAllOwnOemAssets({
    where: {
      ...(selectedAssetType ? { assetTypes: [selectedAssetType?.value] } : {}),
      ...(selectedFacility?.[0]?.value
        ? {
            customer: selectedFacility?.[0]?.value,
          }
        : {}),
    },
    limit: ITEMS_PER_PAGE,
    skip: 0,
    searchQuery,
  });

  const handleScrollBottom = (event) => {
    if (
      assets.length >= ITEMS_PER_PAGE &&
      !assetsListLoading &&
      event?.previousPosition !== "above" &&
      assetsTotalCount > assets.length
    ) {
      handleFetchMoreAssets({
        limit: ITEMS_PER_PAGE,
        skip: assets.length,
      });
    }
  };

  const handleSearchQuery = (value) => {
    if (searchQuery !== value) {
      setSearchQuery(value);
    }
  };

  const allMachineOptions = assets?.map((machine) => {
    return {
      ...machine,
      value: machine._id,
      label: machine.name,
      description: machine?.serialNumber,
      thumbnail: getMachineThumbnail({
        oem: machine.oem,
        image: machine.image,
      }),
    };
  });

  const { inventoryParts } = useGetAssetsPartsById({
    id: selectedMachine?.[0]?.value,
  });

  const resourceParts = inventoryParts;

  const formatedSpareParts = React.useMemo(() => {
    const list = [];
    const addedIds = Object.keys(addedParts);

    addedIds.map((id) => {
      const item = resourceParts?.find(({ part }) => part._id === id);

      if (!item) return;

      list.push({
        part: item.part,
        quantity: addedParts[id],
      });
    });

    return list;
  }, [addedParts, resourceParts]);

  const anyFieldsHasValue =
    selectedFacility.length ||
    selectedMachine.length ||
    ticketTitle.length ||
    ticketDescription.length ||
    selectedReporter.length ||
    ticketType;

  const handleCreateTicketSubmit = () => {
    const requiredFields = {};

    if (!ticketType || !ticketType?.value) {
      requiredFields.ticketTypeError = `${messages?.tickets?.errors.ticketTypeRequired}`;
    }
    if (!selectedFacility.length) {
      requiredFields.facilityError = `${messages?.tickets?.errors.facilityRequired}`;
    }
    if (!selectedMachine.length) {
      requiredFields.machineError = `${messages?.tickets?.errors.machineRequired}`;
    }
    if (!ticketTitle.length) {
      requiredFields.ticketTitleError = `${messages?.tickets?.errors.titleRequired}`;
    }

    // check for parts error
    if (
      selectedWorkOrderType?.partsConfig?.show &&
      selectedWorkOrderType?.partsConfig?.isRequired &&
      !Object.keys(addedParts).length
    ) {
      requiredFields.ticketPartsError = `${messages?.tickets?.errors.fieldRequired}`;
    }

    const customFieldErrors = {};
    selectedWorkOrderType?.customFields?.map((field) => {
      if (
        field.isRequired &&
        !customFieldsData[field.customAdditionalField._id]
      ) {
        customFieldErrors[field.customAdditionalField._id] =
          messages?.tickets?.errors.fieldRequired;
      }
    });
    setCustomFieldsErrors(customFieldErrors);

    if (
      Object.keys(requiredFields).length ||
      Object.keys(customFieldErrors).length
    ) {
      return setTicketCoil({
        ...ticketCoil,
        newTicket: {
          ...newTicket,
          errors: {
            ...newTicket.errors,
            ...requiredFields,
          },
        },
      });
    }

    const newData = {
      customerId: selectedFacility?.[0]?.value,
      machineId: selectedMachine?.[0]?.value,
      userId: selectedReporter?.[0]?.value,
      title: ticketTitle,
      ticketType: ticketType.value,
      description: selectedWorkOrderType?.descriptionConfig?.show
        ? ticketDescription
        : "",
      threadId: emailThreadId || null,
    };

    if (
      Object.keys(addedParts).length &&
      selectedWorkOrderType?.partsConfig?.show
    ) {
      const partData = Object.entries(addedParts);
      const inventoryParts = [];

      for (const part of partData) {
        inventoryParts.push({
          part: part[0],
          quantity: part[1],
          addedBy: user._id,
        });
      }

      newData.inventoryParts = inventoryParts;
    }

    if (Array.isArray(selectedWorkOrderType?.customFields)) {
      for (const customField of selectedWorkOrderType.customFields) {
        if (
          customFieldsData[customField.customAdditionalField._id] !== undefined
        ) {
          newData.customFields = newData.customFields || [];
          newData.customFields.push({
            _id: customField._id,
            fieldId: customField.customAdditionalField._id,
            value: customFieldsData[customField.customAdditionalField._id],
          });
        }
      }
    }

    createTicketMutation(newData)
      .then(({ data: { createOwnOemTicket } }) => {
        onSubmitSuccess();
        onCreate(createOwnOemTicket?._id, createOwnOemTicket?.ticketId);
        toast(
          <Toast
            message={`${
              WORK_ORDER_TYPES[newTicket.ticketType?.value] ===
              WORK_ORDER_TYPES.SparePartOrder
                ? `${messages?.tickets?.openTicketsPage.sparePartOrderCreated}`
                : `${messages?.tickets?.openTicketsPage.ticketCreated}`
            }`}
          />,
          { closeButton: false },
        );

        refetch({
          params: {
            limit: ITEMS_PER_PAGE,
            skip: 0,
            where: {
              machine: createOwnOemTicket?.machine?._id ?? null,
            },
          },
        });
        setTicketCoil({
          ...ticketCoil,
          newTicket: initialNewTicketData,
          isDrawerOpen: false,
          isPartsRequest: false,
        });

        setSearchQuery("");
        setSelectedWorkType(null);
        setSelectedAssetType(null);
        resetCustomFieldsData();
      })
      .catch((error) => {
        const e = errorsHandler(error);
        const resError = {};
        if (e?.customerId) resError.ticketTypeError = e.ticketType.message;
        if (e?.customerId) resError.facilityError = e.customerId.message;
        if (e?.machineId) resError.machineError = e.machineId.message;
        if (e?.userId) resError.reporterError = e.userId.message;
        if (e?.title) resError.titleError = e.title.message;
        if (e?.description) resError.descriptionError = e.description.message;

        if (Object.keys(resError).length) {
          setTicketCoil({
            ...ticketCoil,
            newTicket: {
              ...newTicket,
              errors: {
                ...newTicket.errors,
                ...resError,
              },
            },
          });
        } else {
          toast(<Toast message={e?.message} type={TOAST_TYPES.ERROR} />, {
            closeButton: false,
          });
        }
      });
  };

  const TICKET_TYPE_OPTIONS = filteredWorkOrderTypes.map((type) => ({
    label: type.name,
    itemId: type._id,
    value: type._id,
    icon: (
      <div
        className="work-order-type-icon"
        style={{ backgroundColor: generateStatusBackgroundColor(type.color) }}
      >
        <WorkOrderTypeIcons
          icon={type.icon}
          strokeColor={type.color}
          size={12}
        />
        {type.isInternal ? null : (
          <ExternalIcon
            size={EXTERNAL_ICON_SIZES.SMALL}
            className="absolute -top-[5px] -right-[5px]"
          />
        )}
      </div>
    ),
  }));

  const resetCustomFieldsData = () => {
    setCustomFieldsData({});
    setCustomFieldsErrors({});
  };

  const isAllCustomRequiredFilled = () => {
    if (!selectedWorkOrderType) return false;

    // check only if type is not default
    if (
      selectedWorkOrderType.partsConfig.show &&
      selectedWorkOrderType.partsConfig.isRequired &&
      !Object.keys(addedParts).length
    ) {
      return false;
    }

    // check only if type is not default
    if (
      selectedWorkOrderType.descriptionConfig.show &&
      selectedWorkOrderType.descriptionConfig.isRequired &&
      (ticketDescription === "" || !serialize(ticketDescription).length)
    ) {
      return false;
    }

    if (Array.isArray(selectedWorkOrderType?.customFields)) {
      for (const field of selectedWorkOrderType.customFields) {
        if (
          field.isRequired &&
          !customFieldsData[field.customAdditionalField._id]
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const shouldDisableSubmit = () => {
    let disabled = true;
    const allCustomFieldFilled = isAllCustomRequiredFilled();

    if (
      selectedFacility.length &&
      selectedMachine.length &&
      ticketTitle.length &&
      newTicket.ticketType &&
      allCustomFieldFilled
    ) {
      disabled = false;
    }

    return disabled;
  };

  const handleDrawerClose = () => {
    if (anyFieldsHasValue) {
      setTicketCoil({ ...ticketCoil, showWithoutSaveAlert: true });
    } else {
      setTicketCoil({
        ...ticketCoil,
        newTicket: initialNewTicketData,
        isDrawerOpen: false,
        isPartsRequest: false,
        isDemoModel: false,
      });
      setSelectedWorkType(null);
      setSelectedAssetType(null);
      resetCustomFieldsData();
    }

    setSearchQuery("");
    onDrawerClose?.();
  };

  const handleAlertCancel = () => {
    setTicketCoil({ ...ticketCoil, showWithoutSaveAlert: false });
  };

  const handleAlertAccept = () => {
    setTicketCoil({
      ...ticketCoil,
      newTicket: initialNewTicketData,
      showWithoutSaveAlert: false,
      isDrawerOpen: false,
      isPartsRequest: false,
      isDemoModel: false,
    });

    setSearchQuery("");
    setSelectedWorkType(null);
    setSelectedAssetType(null);
    resetCustomFieldsData();
  };

  const handleAddPart = (partId, qty) => {
    if (qty <= 0) {
      delete tempSelectedParts.current[partId];
    } else {
      tempSelectedParts.current[partId] = qty;
    }

    handleTempSelectedPartsCount();
  };

  const handleClosePartsModal = () => {
    setShowAddPartModal(false);
    tempSelectedParts.current = {};
    setTempSelectedPartsCount(0);
  };

  const handleTempSelectedPartsCount = () => {
    setTempSelectedPartsCount(Object.keys(tempSelectedParts.current).length);
  };

  const getSelectedPartsCount = () => Object.keys(addedParts).length;

  const handleAcceptPartRemove = (partId) => {
    setAddedParts((prev) => {
      const ref = { ...prev };

      delete ref[partId];

      return ref;
    });
  };

  const handleAddedPartQuantity = (partId, qty) => {
    if (qty <= 0) {
      handleAcceptPartRemove(partId);
    } else {
      setAddedParts((prev) => {
        let ref = { ...prev };

        ref[partId] = qty;

        return ref;
      });
    }
  };

  React.useEffect(() => {
    setAddedParts({});
  }, [newTicket.selectedMachine]);

  if (serverErrors) {
    toast(<Toast message={serverErrors.message} type={TOAST_TYPES.ERROR} />, {
      closeButton: false,
    });
  }

  const onClickAddTicket = () => {
    setTicketCoil({ ...ticketCoil, isDrawerOpen: true });
    onOpenDrawer();
  };

  return (
    <>
      {showButton ? (
        button ? (
          button(onClickAddTicket)
        ) : (
          <Button
            text={btnLabel || messages?.tickets?.addTicketButton}
            className={`${btnModifiers || "ml-sm"}`}
            onClick={onClickAddTicket}
          />
        )
      ) : null}
      <Drawer
        title={
          isPartsRequest
            ? messages.tickets.partsRequest
            : messages?.forms?.newTicketForm.addTicketTitle
        }
        isOpen={ticketCoil?.isDrawerOpen}
        onClose={handleDrawerClose}
        onSubmit={handleCreateTicketSubmit}
        submitButtonDisabled={shouldDisableSubmit() || createTicketLoading}
        submitButtonText={messages?.forms?.newTicketForm.submit}
      >
        <div className="add-new-ticket-content">
          <div>
            <IconDropdown
              options={TICKET_TYPE_OPTIONS}
              className={`work-types-dropdown ${
                newTicket.errors.ticketTypeError ? "mb-0" : ""
              }`}
              onChange={(item) => {
                const workOrderType = user?.oem?.ticketTypes?.find(
                  (type) => type._id === item.value,
                );
                setSelectedWorkType(workOrderType || null);
                setTicketCoil({
                  ...ticketCoil,
                  newTicket: {
                    ...newTicket,
                    ticketType: item,
                    errors: {
                      ...newTicket.errors,
                      ticketTypeError: "",
                    },
                  },
                });
              }}
              defaultValue={newTicket.ticketType}
              label={capitalizeFirstLetter(
                messages?.forms?.newTicketForm.type.label + " *",
              )}
              placeholder={messages?.forms?.newTicketForm.type.label}
              errorMessage={newTicket.errors.ticketTypeError}
            />
            {selectedWorkOrderType?.isInternal ? (
              <div className="info-content-container">
                <InfoIcon
                  className="info-icon"
                  width={16}
                  height={16}
                  strokeColor={COLOR.$gray_v2_60}
                />
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="no-styles">
                  {messages?.tickets?.createWorkOrderInternalTypeInfo}
                </BodyText>
              </div>
            ) : null}
          </div>

          <SingleSelectDropdown
            keyId="select-facility"
            label={capitalizeFirstLetter(
              messages?.forms?.newTicketForm[
                isGrowthLoopEnabled ? "connection" : "facility"
              ].label,
            )}
            placeholder={
              messages?.forms?.newTicketForm[
                isGrowthLoopEnabled ? "connection" : "facility"
              ].placeholder
            }
            options={customerOptions}
            onChange={(selected) => {
              const currentFacility = selected[0]?.value;
              const selectedMachineFacility =
                ticketCoil?.newTicket?.selectedMachine?.[0]?.customer?._id;
              let selectMachine = [];

              if (
                selectedMachineFacility &&
                selectedMachineFacility === currentFacility
              ) {
                selectMachine = ticketCoil?.newTicket?.selectedMachine;
              } else {
                setSelectedAssetType(null);
              }

              setSearchQuery("");
              setTicketCoil({
                ...ticketCoil,
                newTicket: {
                  ...newTicket,
                  selectedFacility: selected,
                  selectedMachine: selectMachine,
                },
              });
            }}
            searchable
            searchBy="label"
            isAsync={true}
            asyncProps={{
              isLoading: facilitiesLoading,
              onScrollToBottom: handleScrollBottomFacilities,
              onSearch: handleSearchCustomerQuery,
            }}
            errorMessage={newTicket.errors.facilityError}
            values={selectedFacility}
            disabled={isPartsRequest && !isDemoModel}
            onDropdownOpen={() => {
              setTicketCoil({
                ...ticketCoil,
                newTicket: {
                  ...newTicket,
                  errors: {
                    ...newTicket.errors,
                    facilityError: "",
                  },
                },
              });
            }}
          />

          <SingleSelectDropdown
            keyId="asset-type"
            label={capitalizeFirstLetter(
              messages["hierarchy"]["assetTypes"]["dropDown"]["label"],
            )}
            placeholder={
              messages["hierarchy"]["assetTypes"]["dropDown"]["placeholder"]
            }
            options={assetTypeOptions}
            onChange={(selected) => {
              if (isExternalChange.current) return;

              setSelectedAssetType(selected?.[0]);

              setTicketCoil({
                ...ticketCoil,
                newTicket: {
                  ...newTicket,
                  selectedMachine: [],
                },
              });
            }}
            searchable
            disabled={isPartsRequest && !isDemoModel}
            // @ts-ignore
            searchBy="label"
            values={selectedAssetType ? [selectedAssetType] : []}
            renderRemoveButton={!(isPartsRequest && !isDemoModel)}
            onRemove={() => {
              isExternalChange.current = true;

              setSelectedAssetType(null);
              setTicketCoil({
                ...ticketCoil,
                newTicket: {
                  ...newTicket,
                  selectedMachine: [],
                },
              });

              setTimeout(() => {
                isExternalChange.current = false;
              }, 0);
            }}
          />

          <SingleSelectDropdown
            keyId="select-machine"
            label={capitalizeFirstLetter(
              `${messages["hierarchy"]["assets"]["dropDown"]["label"]} *`,
            )}
            placeholder={
              messages["hierarchy"]["assets"]["dropDown"]["placeholder"]
            }
            options={allMachineOptions}
            searchable={true}
            searchBy={"label"}
            isAsync={true}
            asyncProps={{
              isLoading: assetsListLoading,
              onScrollToBottom: handleScrollBottom,
              onSearch: handleSearchQuery,
            }}
            disabled={isPartsRequest && !isDemoModel}
            onChange={(selected) => {
              if (isExternalChange.current) return;

              isExternalChange.current = true;

              if (!selected.length) {
                setTicketCoil({
                  ...ticketCoil,
                  newTicket: {
                    ...newTicket,
                    selectedMachine: [],
                  },
                });
                setTimeout(() => {
                  isExternalChange.current = false;
                }, 0);
                return;
              }

              const assetType = assetTypeOptions?.find(
                (type) => type.value === selected[0].assetType,
              );
              if (assetType) {
                setSelectedAssetType(assetType);
              } else {
                setSelectedAssetType(null);
              }

              let selectFacility = [];
              const currentMachineCustomer = selected?.[0]?.customer;

              if (!currentMachineCustomer) {
                selectFacility = [];
              } else if (currentMachineCustomer) {
                selectFacility = customerOptions?.filter(
                  (customer) => customer.value === currentMachineCustomer._id,
                );
                if (!selectFacility.length) {
                  selectFacility = [
                    {
                      ...currentMachineCustomer,
                      label: currentMachineCustomer.name,
                      value: currentMachineCustomer._id,
                    },
                  ];
                }
              }

              setTicketCoil({
                ...ticketCoil,
                newTicket: {
                  ...newTicket,
                  selectedMachine: selected,
                  selectedFacility: selectFacility,
                },
              });

              setSearchQuery("");

              setTimeout(() => {
                isExternalChange.current = false;
              }, 0);
            }}
            errorMessage={newTicket.errors.machineError}
            values={selectedMachine}
            onDropdownOpen={() => {
              setTicketCoil({
                ...ticketCoil,
                newTicket: {
                  ...newTicket,
                  errors: {
                    ...newTicket.errors,
                    machineError: "",
                  },
                },
              });
            }}
          />

          <SingleSelectDropdown
            keyId="select-contact"
            label={capitalizeFirstLetter(
              messages?.forms?.newTicketForm[
                isGrowthLoopEnabled ? "contact" : "reporter"
              ].label,
            )}
            placeholder={
              messages?.forms?.newTicketForm[
                isGrowthLoopEnabled ? "contact" : "reporter"
              ].placeholder
            }
            options={isGrowthLoopEnabled ? contactOptions : userOptions}
            {...(isGrowthLoopEnabled
              ? {
                  customOptionItem: (option) => (
                    <div className="flex items-center space-x-lg relative">
                      <Avatar className="w-3xl h-3xl" name={option.label} />
                      {option.accessStatus === CONTACT_ACCESS_STATUS.ACTIVE && (
                        <ConnectionLinkIcon className="w-md h-md absolute -top-3xs left-xs" />
                      )}
                      <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                        {option.label}
                      </BodyText>
                    </div>
                  ),
                }
              : {})}
            disabled={!selectedFacility?.length}
            searchable
            onChange={(selected) => {
              setTicketCoil({
                ...ticketCoil,
                newTicket: {
                  ...newTicket,
                  selectedReporter: selected,
                },
              });
            }}
            isAsync={true}
            asyncProps={{
              isLoading: isGrowthLoopEnabled ? contactsLoading : usersLoading,
              onScrollToBottom: isGrowthLoopEnabled
                ? handleScrollBottomContacts
                : handleScrollBottomUsers,
              onSearch: setUserSearchQuery,
            }}
            showAvatar
            errorMessage={newTicket.errors.reporterError}
            values={selectedReporter}
            onDropdownOpen={() => {
              setTicketCoil({
                ...ticketCoil,
                newTicket: {
                  ...newTicket,
                  errors: {
                    ...newTicket.errors,
                    reporterError: "",
                  },
                },
              });
            }}
          />

          <Input
            label={capitalizeFirstLetter(
              messages?.forms?.newTicketForm.title.label,
            )}
            keyId="add-ticket-title"
            placeholder={messages?.forms?.newTicketForm.title.placeholder}
            value={ticketTitle}
            errorMessage={newTicket.errors.ticketTitleError}
            onChange={(e) => {
              const titleLength = e.target.value.length;

              if (titleLength > 75) {
                return setTicketCoil({
                  ...ticketCoil,
                  newTicket: {
                    ...newTicket,
                    errors: {
                      ...newTicket.errors,
                      ticketTitleError:
                        messages?.forms?.newTicketForm.title.lengthError,
                    },
                  },
                });
              } else {
                setTicketCoil({
                  ...ticketCoil,
                  newTicket: {
                    ...newTicket,
                    ticketTitle: e.target.value,
                    errors: {
                      ...newTicket.errors,
                      ticketTitleError: "",
                    },
                  },
                });
              }
            }}
          />

          {!selectedWorkOrderType?.descriptionConfig?.show ? null : (
            <div>
              {/* description field is optional for default work order types */}
              <TextEditor
                keyId="work-order-description"
                label={capitalizeFirstLetter(
                  `${
                    selectedWorkOrderType?.descriptionConfig?.fieldName ||
                    messages?.forms?.newTicketForm.description.label
                  } ${
                    selectedWorkOrderType?.descriptionConfig?.isRequired
                      ? "*"
                      : ""
                  }`,
                )}
                content={ticketDescription}
                placeholder={
                  messages?.forms?.newTicketForm.description.placeholder
                }
                autoFocus={false}
                onUpdate={(newValue) => {
                  setTicketCoil({
                    ...ticketCoil,
                    newTicket: {
                      ...newTicket,
                      ticketDescription: JSON.stringify(newValue),
                    },
                  });
                }}
              />
            </div>
          )}

          {!selectedWorkOrderType?.partsConfig?.show ? null : (
            <>
              <div className="u-flex u-width-100 u-justify-between new-tickets-spare-parts-div">
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="no-styles"
                >
                  {selectedWorkOrderType?.partsConfig?.fieldName ||
                    messages.tickets.parts}{" "}
                  {/* parts field is optional for default work order types */}
                  {selectedWorkOrderType?.partsConfig?.isRequired ? "*" : ""}
                </BodyText>
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="no-styles"
                >
                  <span
                    className={
                      getSelectedPartsCount() > 0
                        ? "text-brand"
                        : "text-secondary"
                    }
                  >
                    {getSelectedPartsCount()}
                  </span>
                  {` ${messages.tickets.partsAdded}`}
                </BodyText>
              </div>

              <div
                className={`new-ticket-add-spare-parts new-tickets-spare-parts-div ${
                  isMachineSelected ? "" : "new-ticket-add-spare-parts-disabled"
                }`}
                onClick={(e) => {
                  if (!isMachineSelected) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  setShowAddPartModal(true);
                }}
              >
                <span className="u-flex u-justify-center u-items-center">
                  <Plus size={12} weight="bold" />
                </span>
                <Label
                  size={LABEL_SIZES.SMALL}
                  color={isMachineSelected ? "text-brand" : "text-tertiary"}
                  className="ml-xs"
                >
                  {messages?.inventory?.addParts}
                </Label>
              </div>

              {Object.keys(addedParts).length > 0 && (
                <CreateTicketAddedPartsComp
                  addedParts={formatedSpareParts}
                  handlePartRemove={handleAcceptPartRemove}
                  handlePartQuantity={handleAddedPartQuantity}
                />
              )}
            </>
          )}

          {/* custom configured form elements */}
          <CustomFormElements
            fields={selectedWorkOrderType?.customFields || []}
            formData={customFieldsData}
            errorData={customFieldsErrors}
            onChange={setCustomFieldsData}
            updateErrorData={setCustomFieldsErrors}
          />
        </div>
      </Drawer>

      <AlertBox
        title={messages?.tickets?.ticketQuitAlertTitle}
        description={messages?.tickets?.ticketQuitAlertMessage}
        isOpen={ticketCoil?.showWithoutSaveAlert}
        acceptButtonText={messages?.common?.quit}
        cancelButtonText={messages?.common?.cancel}
        image={<LeaveByDoorIcon width="130" height="130" />}
        onAccept={handleAlertAccept}
        onCancel={handleAlertCancel}
        overlay={false}
      />

      <AppModal
        isOpen={showAddPartModal}
        className="!w-[75vw]"
        overlayClasses="!z-20"
        maxWidth="lg:!max-w-[650px]"
        disableUpdate={!tempSelectedPartsCount}
        disableCancel={false}
        hideUpdateButton={!resourceParts?.length}
        updateButtonText={
          <>
            {tempSelectedPartsCount > 0 && (
              <div className="selected-parts-count-in-button">
                {tempSelectedPartsCount}
              </div>
            )}
            {messages?.inventory?.addSelectedParts}
          </>
        }
        handleClose={handleClosePartsModal}
        handleSubmit={() => {
          setAddedParts({
            ...addedParts,
            ...tempSelectedParts.current,
          });
          handleClosePartsModal();
        }}
        title={partForModalPreview ? null : messages.inventory.addParts || ""}
        header={
          partForModalPreview ? (
            <div className="flex items-center py-xl px-2xl border-solid border-b border-t-0 border-x-0 border-primary">
              <PartDetailModalHeader
                title={messages.inventory.workOrderPartDetails}
                onBack={() => setPartForModalPreview(null)}
              />
            </div>
          ) : null
        }
        showFooter={!partForModalPreview}
        contentClassName={
          partForModalPreview ? "part-modal-preview-content-wrapper" : ""
        }
        content={
          <AddPartComp
            addedParts={addedParts}
            allParts={resourceParts}
            handleAddPart={handleAddPart}
            onClickDetails={(part) => setPartForModalPreview(part)}
            partForModalPreview={partForModalPreview}
            inventoryCustomAdditionalFields={inventoryCustomAdditionalFields}
          />
        }
      />
    </>
  );
};

export default Create;

import GlobeIcon from "@shared/svg/globe-icon.svg?react";

export const EXTERNAL_ICON_SIZES = {
  X_LARGE: "xLarge",
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small",
  X_SMALL: "xSmall",
} as const;

export type ExternalIconSizeType =
  (typeof EXTERNAL_ICON_SIZES)[keyof typeof EXTERNAL_ICON_SIZES];

const SIZE_STYLES: Record<ExternalIconSizeType, string> = {
  [EXTERNAL_ICON_SIZES.X_LARGE]: "w-2xl h-2xl rounded-md",
  [EXTERNAL_ICON_SIZES.LARGE]: "w-xl h-xl rounded",
  [EXTERNAL_ICON_SIZES.MEDIUM]: "w-lg h-lg rounded",
  [EXTERNAL_ICON_SIZES.SMALL]: "w-md h-md rounded-sm",
  [EXTERNAL_ICON_SIZES.X_SMALL]: "w-sm h-sm rounded-sm",
};

const getIconSize = (size: ExternalIconSizeType) => {
  switch (size) {
    case EXTERNAL_ICON_SIZES.X_LARGE:
      return 16;
    case EXTERNAL_ICON_SIZES.LARGE:
      return 14;
    case EXTERNAL_ICON_SIZES.MEDIUM:
      return 12;
    case EXTERNAL_ICON_SIZES.SMALL:
      return 8;
    case EXTERNAL_ICON_SIZES.X_SMALL:
      return 6;
  }
};

type ExternalIconPropsType = {
  size?: ExternalIconSizeType;
  className?: string;
};

const ExternalIcon = ({
  size = EXTERNAL_ICON_SIZES.X_LARGE,
  className = "",
}: ExternalIconPropsType) => (
  <div
    className={`flex items-center justify-center bg-dark ${SIZE_STYLES[size]} ${className}`}
  >
    <GlobeIcon
      width={getIconSize(size)}
      height={getIconSize(size)}
      className="text-inverse no-icon-styles"
    />
  </div>
);

export default ExternalIcon;

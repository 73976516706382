import { useMutation } from "@apollo/client";

import {
  CURRENT_USER_QUERY,
  GET_EMAIL_THREAD_QUERY,
  LINK_EMAIL_ACCOUNT_MUTATION,
  LIST_EMAIL_FOLDERS_QUERY,
  SEND_EMAIL_MUTATION,
  UNLINK_EMAIL_ACCOUNT_MUTATION,
  UPDATE_EMAIL_THREAD_MUTATION,
  MOVE_EMAIL_TO_FOLDER_MUTATION,
  GET_EMAIL_THREAD_NYLAS_QUERY,
} from "#/src/api";
import client from "#/src/apollo/_client";

const updateCurrentUserCache = (cache, oem) => {
  const currentUserCache = cache.readQuery({
    query: CURRENT_USER_QUERY,
    variables: {},
  });
  // eslint-disable-next-line no-console
  if (!currentUserCache) return;

  const cloned = JSON.parse(JSON.stringify(currentUserCache.currentUser));
  cloned.oem = {
    ...cloned.oem,
    emailAccountLinked: oem.emailAccountLinked,
    emailAccounts: oem.emailAccounts,
    isSynced: oem.isSynced,
    maximumAllowedEmailAddresses: oem.maximumAllowedEmailAddresses,
  };

  cache.writeQuery({
    query: CURRENT_USER_QUERY,
    variables: {},
    data: { currentUser: cloned },
  });
};

export const useLinkEmailAccount = () => {
  const [link, { loading, error }] = useMutation(LINK_EMAIL_ACCOUNT_MUTATION);

  return {
    loading,
    error,
    linkEmail: async (code) =>
      link({
        variables: {
          code,
        },
        update(cache, { data: { linkEmailAccount: ref } }) {
          try {
            updateCurrentUserCache(cache, ref);
          } catch (error) {
            //
          }
        },
      }),

    handleEmailAccountSync: ({ payload }) => {
      const { currentUser } =
        client.cache.readQuery({
          query: CURRENT_USER_QUERY,
        }) || {};

      const emailConfigs = currentUser.oem.emailAccounts?.map((emailAccount) =>
        emailAccount._id === payload?.emailAccountId
          ? { ...emailAccount, isSynced: true }
          : { ...emailAccount, isSynced: !!emailAccount.isSynced },
      );

      client.cache.writeQuery({
        query: CURRENT_USER_QUERY,
        data: {
          currentUser: {
            ...currentUser,
            oem: {
              ...currentUser.oem,
              emailAccounts: emailConfigs,
            },
          },
        },
      });
    },
  };
};

export const useUnlinkEmailAccount = () => {
  const [unlink, { loading, error }] = useMutation(
    UNLINK_EMAIL_ACCOUNT_MUTATION,
  );

  return {
    loading,
    error,
    unlinkEmail: async (emailAddressId) =>
      unlink({
        variables: {
          emailAddressId: emailAddressId,
        },
        update(cache, { data: { unlinkEmailAccount: ref } }) {
          try {
            updateCurrentUserCache(cache, ref);
          } catch (error) {
            //
          }
        },
      }),
  };
};

export const useUpdateEmailThread = () => {
  const [updateEmail, { loading, error }] = useMutation(
    UPDATE_EMAIL_THREAD_MUTATION,
  );

  return {
    loading,
    error,
    updateThread: async (input) =>
      updateEmail({
        variables: { input },
        update(cache, { data: { updateEmailThread: ref } }) {
          const cachedThread = cache.readQuery({
            query: GET_EMAIL_THREAD_QUERY,
            variables: {
              threadId: input.threadId,
              emailAddressId: input.emailAddressId,
            },
          });

          if (!cachedThread) return;

          const cloned = JSON.parse(
            JSON.stringify(cachedThread.getEmailThread),
          );

          cloned.thread.unread = ref.thread.unread;
          cloned.ticket = ref.ticket;

          cache.writeQuery({
            query: GET_EMAIL_THREAD_QUERY,
            variables: { threadId: input.threadId },
            data: { getEmailThread: cloned },
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listEmailThreadsByFolder",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listEmailThreadsByTicket",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listEmailThreadsByFolderNylas",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listEmailThreadsByTicketNylas",
          });
          cache.gc();
        },
      }),
  };
};

export const useMoveEmailToFolder = (isSynced) => {
  const [moveEmail, { loading, error }] = useMutation(
    MOVE_EMAIL_TO_FOLDER_MUTATION,
  );

  return {
    loading,
    error,
    moveEmailToFolder: async (input) =>
      moveEmail({
        variables: { input },
        update(cache) {
          isSynced
            ? cache.evict({
                id: "ROOT_QUERY",
                fieldName: "listEmailThreadsByFolder",
              })
            : cache.evict({
                id: "ROOT_QUERY",
                fieldName: "listEmailThreadsByFolderNylas",
              });
          cache.gc();
        },
      }),
  };
};

export const useSendEmail = () => {
  const [send, { loading, error }] = useMutation(SEND_EMAIL_MUTATION);

  return {
    loading,
    error,
    sendEmail: async (input) =>
      send({
        variables: { input },
      }),

    handleUpdateEmailCache: ({ payload }) => {
      if (payload?.replied) {
        const { getEmailThread } = client.cache.readQuery({
          query: GET_EMAIL_THREAD_QUERY,
          variables: {
            threadId: payload?.threadId,
            emailAddressId: payload?.accountId,
          },
        });
        const ticketId = getEmailThread?.ticket?._id;
        if (
          window.location.pathname.includes(payload?.threadId) ||
          (ticketId && window.location.pathname.includes(ticketId))
        ) {
          client.cache.writeQuery({
            query: GET_EMAIL_THREAD_QUERY,
            variables: {
              threadId: payload?.threadId,
              emailAddressId: payload?.accountId,
            },
            data: {
              getEmailThread: {
                ...getEmailThread,
                thread: {
                  ...getEmailThread.thread,
                  messageIds: [
                    ...getEmailThread.thread.messageIds,
                    payload?.message?.id,
                  ],
                  messages: [
                    ...getEmailThread.thread.messages,
                    payload?.message,
                  ],
                },
              },
            },
          });
        }
      } else {
        client.cache.evict({
          id: "ROOT_QUERY",
          fieldName: "listEmailThreadsByTicket",
        });

        client.cache.evict({
          id: "ROOT_QUERY",
          fieldName: "listEmailThreadsByFolder",
        });

        client.cache.evict({
          id: "ROOT_QUERY",
          fieldName: "listDrafts",
        });

        client.cache.evict({
          id: "ROOT_QUERY",
          fieldName: "getDraftAndJunkEmailCount",
        });

        client.cache.gc();
      }
    },
  };
};

export const updateFolderUnreadCount = (folderToCountMap, emailAddressId) => {
  const folders = client.readQuery({
    query: LIST_EMAIL_FOLDERS_QUERY,
    variables: {
      params: {
        limit: 100,
        skip: 0,
        where: {
          emailAddressId,
        },
      },
    },
  });

  const updatedFolders = folders.listEmailFolders.folders.map((folder) => ({
    ...folder,
    unreadCount: folderToCountMap[folder.id],
  }));

  client.writeQuery({
    query: LIST_EMAIL_FOLDERS_QUERY,
    variables: {
      params: {
        limit: 100,
        skip: 0,
        where: {
          emailAddressId,
        },
      },
    },
    data: {
      listEmailFolders: { folders: updatedFolders },
    },
  });
};

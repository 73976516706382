import * as React from "react";
const SvgAssetsTileIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_66_3335)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M2.77815 8.0359C3.86289 8.0359 4.74225 8.91523 4.74225 9.99994C4.74225 11.0846 3.86289 11.964 2.77815 11.964C1.6934 11.964 0.814047 11.0846 0.814047 9.99994C0.814047 8.91523 1.6934 8.0359 2.77815 8.0359",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M17.222 8.0359C18.3067 8.0359 19.1861 8.91523 19.1861 9.99994C19.1861 11.0846 18.3067 11.964 17.222 11.964C16.1373 11.964 15.2579 11.0846 15.2579 9.99994C15.2579 8.91523 16.1373 8.0359 17.222 8.0359",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M5.55584 13.3344L6.66691 14.4454",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M13.3332 5.55549L14.4443 6.66652",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.99983 15.2583C11.0846 15.2583 11.9639 16.1376 11.9639 17.2224C11.9639 18.3071 11.0846 19.1864 9.99983 19.1864C8.91508 19.1864 8.03573 18.3071 8.03573 17.2224C8.03573 16.1376 8.91508 15.2583 9.99983 15.2583",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.99983 0.81349C11.0846 0.81349 11.9639 1.69282 11.9639 2.77753C11.9639 3.86224 11.0846 4.74156 9.99983 4.74156C8.91508 4.74156 8.03573 3.86224 8.03573 2.77753C8.03573 1.69282 8.91508 0.813489 9.99983 0.81349",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M13.3332 14.4444L14.4443 13.3334",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M5.55584 6.66551L6.66691 5.55449",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
})));
export default SvgAssetsTileIcon;

import ChevronRight from "@shared/svg/chevron-right.svg?react";
import DeleteIcon from "@shared/svg/delete-document.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { MinusCircle } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import AssignedTeamsWithPreview from "#/src/components/_assignedTeamsWithPreview";
import { DELETE_MACHINE } from "#/src/constants";
import { registerMixpanelEvent } from "#/src/utils/_mixpanel";
import { useAuth } from "~/components/general";
import MachineListItem from "~/components/machines/MachineListItem";
import { useRoleManager } from "~/hooks/_useRoleManager";
import { useDeleteAsset } from "~/services/asset";

const SingleMachineListItem = ({
  machine,
  showUnassign = false,
  inlineSerialNumber = false,
  handleUnassignClick = () => {},
  serialNumberWrapperClassName = "",
  onDeleteClick = () => {},
  isTeamsPaid,
  showInlineTag = false,
  showTag = true,
  showRemoveAndUnassignButton = true,
  handleEditClick = () => {},
  handleQRCodeClick = () => {},
  showExpandIcon = false,
  expanded = false,
  setExpanded = () => {},
  isAssetQuery = false,
  showQRCode = true,
  isSharedAssetPage = false,
}) => {
  const { messages } = useIntl();
  const { removeAsset } = useDeleteAsset();
  const { user } = useAuth();
  const params = useParams();
  const { pathname } = useLocation();
  const { isOemAdmin, isOemTechnician } = useRoleManager(user?.role);
  const isSelfCreated = machine.createdBy === user?._id;
  const allowDeletion = isOemAdmin || (isSelfCreated && !isOemTechnician);
  const [showRemoveMachineAlert, setShowRemoveMachineAlert] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const isSharedAssetsPage = useMemo(
    () => pathname.includes("/app/asset-hub/shared-assets"),
    [pathname],
  );

  const handleRemoveMachineCancelClick = async () => {
    setShowRemoveMachineAlert(false);
  };

  const handleRemoveMachineAcceptClick = async () => {
    try {
      setIsDeleting(true);
      await removeAsset(machine._id);
      registerMixpanelEvent(DELETE_MACHINE);
      toast(
        <Toast message={messages["hierarchy"]["assets"]["deleteSuccess"]} />,
        {
          closeButton: false,
        },
      );
      setShowRemoveMachineAlert(false);
    } catch (error) {
      toast(<Toast message={error.message} type={TOAST_TYPES.ERROR} />, {
        closeButton: false,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <React.Fragment key={machine?._id}>
      <Link
        to={
          isSharedAssetPage
            ? `/app/asset-hub/shared-assets/${params.connectionId}/${machine?._id}`
            : `/app/assets/machines/${machine?._id}`
        }
      >
        <MachineListItem className="flex items-center !mb-0">
          {showExpandIcon && !isAssetQuery && (
            <ChevronRight
              className={`flex-shrink-0 w-lg h-lg duration-300 ease-in-out transition-transform stroke-secondary mr-sm ${
                expanded ? "rotate-90" : ""
              }`}
              width={16}
              height={16}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setExpanded();
              }}
            />
          )}
          <div className="flex items-center grow">
            <MachineListItem.Thumbnail machine={machine} />
            <div className="flex flex-col">
              <MachineListItem.MachineName
                machine={machine}
                facilityName={machine?.customer?.name}
                showInlineTag={showInlineTag}
                showTag={showTag}
                isAssetQuery={isAssetQuery}
              />
            </div>
            {isTeamsPaid && (
              <div className="u-margin-l-auto mr-4">
                <AssignedTeamsWithPreview
                  teams={machine.teams ?? []}
                  hidePreview
                  tagClassName="responsive-team-tag"
                />
              </div>
            )}
          </div>

          <div
            className={`flex items-center justify-between
              ${serialNumberWrapperClassName} !w-96`}
          >
            {!!machine?.totalChildrenCount && (
              <div className="flex items-center space-x-xs justify-between mr-lg w-1/5">
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-primary">
                  {machine.totalChildrenCount}
                </BodyText>
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="truncate"
                >
                  {
                    messages["hierarchy"]["assets"][
                      machine.totalChildrenCount > 1 ? "subAssets" : "subAsset"
                    ]
                  }
                </BodyText>
              </div>
            )}
            <div className="w-2/5 mr-lg inline-serial-number">
              <MachineListItem.SerialNumber
                serialNumber={machine?.serialNumber}
                inline={inlineSerialNumber}
              />
            </div>
            {machine.isQRCodeEnabled && showQRCode && (
              <MachineListItem.QRCode
                handleQRCodeClick={() => handleQRCodeClick(machine)}
              />
            )}
            {showRemoveAndUnassignButton &&
            showUnassign &&
            !isOemTechnician &&
            !isSharedAssetsPage ? (
              <Button
                variant={BUTTON_VARIANTS.LINK}
                className="w-1/5 justify-end"
                leadingIcon={
                  <MinusCircle className="u-text-color-gray-v2-60" size={20} />
                }
                tooltipId="unassign-machine"
                tooltipText={
                  messages?.facilities?.facilityDetails.machinesTab.edit.changes
                    .unassignWindow.yes
                }
                onClick={(e) => {
                  e.preventDefault();
                  handleUnassignClick(machine);
                }}
              />
            ) : (
              showRemoveAndUnassignButton &&
              !showUnassign &&
              !isOemTechnician &&
              !isSharedAssetsPage && (
                <MachineListItem.PopUp
                  handleEditClick={() => handleEditClick(machine)}
                  handleDeleteClick={() => {
                    onDeleteClick();
                    setShowRemoveMachineAlert(true);
                  }}
                  showDeleteButton={allowDeletion}
                  showEditButton={!isOemTechnician}
                  isDelete
                />
              )
            )}
          </div>
        </MachineListItem>
      </Link>
      <AlertBox
        title={`${messages["hierarchy"]["assets"]["deleteButtonText"]}?`}
        description={`${messages["hierarchy"]["assets"]["deleteAlert"]["title"]} ${messages["hierarchy"]["assets"]["deleteAlert"]["description"]}`}
        isOpen={showRemoveMachineAlert}
        acceptButtonText={messages?.common?.delete}
        acceptButtonVariant={BUTTON_VARIANTS.DANGER}
        cancelButtonText={messages?.common?.cancel}
        disableAcceptButton={isDeleting}
        image={<DeleteIcon width="130" height="130" />}
        onAccept={handleRemoveMachineAcceptClick}
        onCancel={handleRemoveMachineCancelClick}
        overlay={true}
      />
    </React.Fragment>
  );
};

export default React.memo(SingleMachineListItem);

import { MessageFormatElement } from "react-intl";

import { DEMO_MACHINE } from "~/constants/machine";

export const DEMO_GUIDE = {
  _id: "demo",
  name: "Demo Guide",
  thumbnail: "/images/twin-engine.png",
  machine: DEMO_MACHINE,
};

export const GUIDE_SAVE_VALUES = {
  EXISTING: "EXISTING",
  NEW: "NEW",
};

export const NODE_CONTEXT_OPTION_ID = "%node";
export const ADD_ANNOTATION_CONTEXT_OPTION_ID = "add_annotation";

export const ENTRIES_TO_HIDE = ["%volume"];
export const ENTRIES_TO_SHOW_SHARED_ASSETS = [
  "%cM_Set_Pivot",
  "%cM_Fly_To",
  "%cM_Create_Explosion",
];

export const TOOLBAR_OPTIONS_TO_REMOVE = [
  "Share",
  "Drawing Mode",
  "Settings",
  "Clip Plane from geometry",
  "Translation",
  "About ...",
];

export const TOOLBAR_OPTIONS_TO_REMOVE_GUIDES = [
  "Share",
  "Print",
  "Settings",
  "About ...",
];

export const TOOLBAR_OPTIONS_TO_REMOVE_GUIDES_SHARED_ASSETS = [
  "Share",
  "Drawing Mode",
  "Measurement",
  "AUX Mode",
  "Settings",
  "3-Color Comparison",
  "Clip Plane from geometry",
  "Translation",
  "About ...",
];

export const TOOLBAR_SPACERS_TO_RETAIN = 2;
export const TOOLBAR_SPACER_TAG_NAME = "webvis-toolbar-spacer";

export const GUIDE_SAVE_OPTIONS = (
  messages: Record<string, string> | Record<string, MessageFormatElement[]>,
) => [
  {
    heading: messages?.["machines"]?.["guideSaveExisting"],
    value: GUIDE_SAVE_VALUES.EXISTING,
  },
  {
    heading: messages?.["machines"]?.["guideCreateNew"],
    value: GUIDE_SAVE_VALUES.NEW,
  },
];

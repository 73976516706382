import activityLog from "~/lang/it-IT/activityLog.json";
import aiAssistant from "~/lang/it-IT/ai-assistant.json";
import aiNotetaker from "~/lang/it-IT/ai-notetaker.json";
import analytics from "~/lang/it-IT/analytics";
import branding from "~/lang/it-IT/branding";
import chat from "~/lang/it-IT/chat";
import common from "~/lang/it-IT/common";
import components from "~/lang/it-IT/components.json";
import connections from "~/lang/it-IT/connections.json";
import dataManagement from "~/lang/it-IT/dataManagement";
import documents from "~/lang/it-IT/documents";
import emails from "~/lang/it-IT/emails.json";
import facilities from "~/lang/it-IT/facilities";
import filters from "~/lang/it-IT/filters";
import forms from "~/lang/it-IT/forms";
import hierarchy from "~/lang/it-IT/hierarchy.json";
import inventory from "~/lang/it-IT/inventory";
import knowledgeBase from "~/lang/it-IT/knowledgeBase.json";
import login from "~/lang/it-IT/login";
import machines from "~/lang/it-IT/machines";
import maintenanceDocuments from "~/lang/it-IT/maintenanceDocuments";
import menus from "~/lang/it-IT/menus";
import plans from "~/lang/it-IT/plans";
import preventiveMaintenance from "~/lang/it-IT/preventiveMaintenance";
import procedures from "~/lang/it-IT/procedures";
import productionLines from "~/lang/it-IT/productionLines.json";
import requests from "~/lang/it-IT/requests.json";
import resourcePlanning from "~/lang/it-IT/resourcePlanning";
import resources from "~/lang/it-IT/resources.json";
import scheduling from "~/lang/it-IT/scheduling.json";
import settings from "~/lang/it-IT/settings";
import sharedAssets from "~/lang/it-IT/sharedAssets.json";
import teams from "~/lang/it-IT/teams.json";
import tickets from "~/lang/it-IT/tickets";
import workOrderFormConfiguration from "~/lang/it-IT/workOrderFormConfiguration.json";
import workOrderTypes from "~/lang/it-IT/workOrderTypes.json";
import LOCALES from "~/lang/locales";

export default {
  [LOCALES[3].locale]: {
    analytics,
    chat,
    common,
    connections,
    dataManagement,
    documents,
    facilities,
    forms,
    inventory,
    login,
    machines,
    maintenanceDocuments,
    menus,
    preventiveMaintenance,
    resourcePlanning,
    settings,
    tickets,
    branding,
    procedures,
    filters,
    productionLines,
    knowledgeBase,
    components,
    teams,
    emails,
    workOrderTypes,
    workOrderFormConfiguration,
    activityLog,
    aiAssistant,
    aiNotetaker,
    hierarchy,
    scheduling,
    resources,
    sharedAssets,
    requests,
    plans,
  },
};

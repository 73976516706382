import MakulaLogo from "@shared/svg/makula-logo.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import AppModal from "@shared/ui/Modal";
import ToggleSwitch from "@shared/ui/ToggleSwitch";
import Tooltip from "@shared/ui/Tooltip";
import React from "react";
import { useIntl } from "react-intl";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import ReactTooltip from "react-tooltip";

import { HelperText } from "~/components/_typography";
import { useAuth } from "~/components/general";

const QRCodeModal = ({
  machine,
  setShowQRModal,
  showQRModal,
  sharedBy = "",
  sharedByLogo = "",
  onClose = () => {},
}) => {
  const { messages } = useIntl();
  const tooltipRef = React.useRef(null);
  const qrCodeContainerRef = React.useRef(null);
  const [showNameAndNumber, setShowNameAndNumber] = React.useState(true);
  const { user } = useAuth();
  const ownOemLogo = user.oem.brandLogo;

  const handlePrint = useReactToPrint({
    content: () => qrCodeContainerRef.current,
  });

  return (
    <AppModal
      isOpen={showQRModal}
      maxWidth="!max-w-[494px]"
      title={messages["hierarchy"]["assets"]["useQrCode"]}
      handleClose={(e) => {
        e.preventDefault();
        setShowQRModal(false);
        setShowNameAndNumber(true);
        onClose();
      }}
      content={
        <div className="facility-details-content-qr-code-container">
          <HelperText className="u-margin-b-5">
            {messages["hierarchy"]["assets"]["qrCodeModalMessage"]}
          </HelperText>

          <div className="switcher-right u-flex u-items-center u-margin-b-4">
            <ToggleSwitch
              name="show-name-and-serial-number"
              isOn={showNameAndNumber}
              onChange={() => setShowNameAndNumber((prevState) => !prevState)}
            />
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="ml-md">
              {messages["hierarchy"]["assets"]["qrCodeNameAndNumber"]}
            </BodyText>
          </div>
          {machine?.generalAccessUrl && (
            <div
              className={`qr-code-container flex ${
                showNameAndNumber ? "" : "justify-center"
              }`}
              ref={qrCodeContainerRef}
            >
              <div className="qr-code-wrapper relative">
                <QRCode size={152} value={machine?.generalAccessUrl} />
                {sharedByLogo || ownOemLogo || null ? (
                  <img
                    src={sharedByLogo || ownOemLogo}
                    className="absolute w-4xl h-4xl rounded-full border border-solid border-inverse object-cover"
                  />
                ) : (
                  <div className="bg-secondary mx-auto flex items-center justify-center rounded-full w-4xl h-4xl absolute border border-solid border-inverse">
                    <MakulaLogo className="w-fit h-xl" />
                  </div>
                )}
              </div>
              {showNameAndNumber && (
                <div className="u-flex u-flex-column u-margin-l-3">
                  <div className="u-flex u-justify-between u-flex-column u-height-100">
                    <div>
                      {sharedBy && (
                        <div>
                          <BodyText
                            size={BODY_TEXT_SIZES.X_SMALL}
                            color="text-secondary"
                          >
                            {messages?.["sharedAssets"]["sharedBy"]}
                          </BodyText>
                          <BodyText
                            size={BODY_TEXT_SIZES.X_SMALL}
                            className="break-words"
                          >
                            {sharedBy}
                          </BodyText>
                        </div>
                      )}
                      <div className={`${sharedBy ? "mt-xl" : ""}`}>
                        <BodyText
                          size={BODY_TEXT_SIZES.X_SMALL}
                          color="text-secondary"
                        >
                          {messages["hierarchy"]["assets"]["qrCodeAssetName"]}
                        </BodyText>
                        <BodyText
                          size={BODY_TEXT_SIZES.X_SMALL}
                          className="break-words"
                        >
                          {machine.name}
                        </BodyText>
                      </div>
                      <div className="mt-xl">
                        <BodyText
                          size={BODY_TEXT_SIZES.X_SMALL}
                          color="text-secondary"
                        >
                          {
                            messages?.machines?.machineDetails.detailsTab.qrCode
                              .serialNumber
                          }
                        </BodyText>
                        <BodyText
                          size={BODY_TEXT_SIZES.X_SMALL}
                          className="break-words"
                        >
                          {machine.serialNumber}
                        </BodyText>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      }
      footer={
        <div className="flex items-center justify-end py-lg px-2xl border-solid border-t border-b-0 border-x-0 border-primary gap-sm">
          <Button
            variant={BUTTON_VARIANTS.OUTLINE}
            text={
              messages?.machines?.machineDetails.detailsTab.qrCode.printQRCode
            }
            className="mr-xs"
            onClick={(e) => {
              e.preventDefault();
              handlePrint();
            }}
          />

          <div>
            <Button
              text={
                messages?.machines?.machineDetails.detailsTab.qrCode.copyUrl
              }
              data-copy={machine?.generalAccessUrl}
              data-event="click"
              ref={tooltipRef}
              data-for="copy"
              data-tip={
                messages?.machines?.machineDetails.detailsTab.qrCode.copyTooltip
              }
            />
            <Tooltip
              afterShow={() => {
                const el = document.createElement("textarea");
                el.value = machine?.generalAccessUrl;
                document.body.appendChild(el);
                el.select();
                document.execCommand("copy");
                document.body.removeChild(el);

                setTimeout(() => {
                  ReactTooltip.hide(tooltipRef.current);
                }, 1000);
              }}
              id="copy"
              place="top"
            />
          </div>
        </div>
      }
    />
  );
};

export default QRCodeModal;

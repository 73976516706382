const MINUTE = 60;

export const convertSecondsToReadableForm = (
  recordingSeconds: number,
  smallUnit: boolean = true,
  toFixed: number = 2,
) => {
  const quotient = recordingSeconds / MINUTE;
  const quotientFloored = Math.floor(quotient);

  if (quotientFloored < 1) {
    return `${recordingSeconds}${smallUnit ? " s" : "secs"}`;
  } else return `${quotient.toFixed(toFixed)}${smallUnit ? " m" : "mins"}`;
};

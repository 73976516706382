import React, { useState, useRef } from "react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import useOnClickOutside from "use-onclickoutside";
import EmptySpace from "~/components/_emptySpace";
import { useIntl } from "react-intl";
import {
  AscendingSortIcon,
  DescendingSortIcon,
  AtoZSortIcon,
  ZtoASortIcon,
} from "~/icons/icons";
const SORT_ICON_COLOR = "#748094";
const defaultSortKeys = {
  name: "name",
  createdAt: "createdAt",
  updatedAt: "updatedAt",
};

const SortingDropdown = ({
  onChange,
  className,
  value,
  sortKeys = defaultSortKeys,
  onOpenSortingDropdown = () => {},
}) => {
  const { messages } = useIntl();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  const renderSortIcon = () => {
    switch (true) {
      case value?.key === sortKeys?.name && value?.value === 1:
        return <AtoZSortIcon strokeColor={SORT_ICON_COLOR} />;
      case value?.key === sortKeys?.name && value?.value === -1:
        return <ZtoASortIcon strokeColor={SORT_ICON_COLOR} />;
      case value?.key === sortKeys?.createdAt && value?.value === -1:
        return <AscendingSortIcon strokeColor={SORT_ICON_COLOR} />;
      case value?.key === sortKeys?.createdAt && value?.value === 1:
        return <DescendingSortIcon strokeColor={SORT_ICON_COLOR} />;
      case value?.key === sortKeys?.updatedAt && value?.value === -1:
        return <AscendingSortIcon strokeColor={SORT_ICON_COLOR} />;
      case value?.key === sortKeys?.updatedAt && value?.value === 1:
        return <DescendingSortIcon strokeColor={SORT_ICON_COLOR} />;
    }
  };
  const renderSortText = () => {
    switch (true) {
      case value?.key === sortKeys?.name && value?.value === 1:
        return messages?.common?.sortByName?.ascendingValue;
      case value?.key === sortKeys?.name && value?.value === -1:
        return messages?.common?.sortByName?.descendingValue;
      case value?.key === sortKeys?.createdAt && value?.value === -1:
        return messages?.common?.sortByCreatedAt?.ascendingValue;
      case value?.key === sortKeys?.createdAt && value?.value === 1:
        return messages?.common?.sortByCreatedAt?.descendingValue;
      case value?.key === sortKeys?.updatedAt && value?.value === -1:
        return messages?.common?.sortByUpdatedAt?.ascendingValue;
      case value?.key === sortKeys?.updatedAt && value?.value === 1:
        return messages?.common?.sortByUpdatedAt?.descendingValue;
    }
  };

  return (
    <div
      className={`sorting-dropdown-wrapper u-inline-flex ${className || ""}`}
    >
      <div
        className={`sort-dropdown u-inline-flex u-items-center ${
          isDropdownOpen ? "open" : ""
        }`}
        onClick={() => {
          onOpenSortingDropdown();
          setIsDropdownOpen(true);
        }}
      >
        <span className="u-margin-r-2 u-font-bold u-text-color-gray-v2-100">
          {messages.filters?.sortBy}:
        </span>
        <span className="icon">{renderSortIcon()}</span>
        <span className="u-text-color-gray-v2-60">{renderSortText()}</span>
      </div>
      {isDropdownOpen && (
        <div className="sort-dropdown-options" ref={dropdownRef}>
          <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
            {messages?.common?.sortByName?.title}:
          </BodyText>
          <EmptySpace height="8px" />
          <div className={"u-flex u-justify-between u-width-100"}>
            <div
              className={`sorting-item u-flex u-items-center ${
                value?.key === sortKeys?.name && value?.value === 1
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                onChange({
                  key: sortKeys?.name || defaultSortKeys?.name,
                  value: 1,
                })
              }
            >
              <span>
                <AtoZSortIcon />
              </span>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                className="overflow-hidden text-ellipsis ml-sm"
              >
                {messages?.common?.sortByName?.ascendingValue}
              </BodyText>
            </div>
            <div
              className={`sorting-item u-flex u-items-center ${
                value?.key === sortKeys?.name && value?.value === -1
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                onChange({
                  key: sortKeys?.name || defaultSortKeys?.name,
                  value: -1,
                })
              }
            >
              <span>
                <ZtoASortIcon />
              </span>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                className="overflow-hidden text-ellipsis ml-sm"
              >
                {messages?.common?.sortByName?.descendingValue}
              </BodyText>
            </div>
          </div>
          <EmptySpace height="16px" />
          <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
            {messages?.common?.sortByCreatedAt?.title}:
          </BodyText>
          <EmptySpace height="8px" />
          <div className={"u-flex u-justify-between u-width-100"}>
            <div
              className={`sorting-item u-flex u-items-center ${
                value?.key === sortKeys?.createdAt && value?.value === -1
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                onChange({
                  key: sortKeys?.createdAt || defaultSortKeys?.createdAt,
                  value: -1,
                })
              }
            >
              <span>
                <AscendingSortIcon />
              </span>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                className="overflow-hidden text-ellipsis ml-sm"
              >
                {messages?.common?.sortByCreatedAt?.ascendingValue}
              </BodyText>
            </div>
            <div
              className={`sorting-item u-flex u-items-center ${
                value?.key === sortKeys?.createdAt && value?.value === 1
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                onChange({
                  key: sortKeys?.createdAt || defaultSortKeys?.createdAt,
                  value: 1,
                })
              }
            >
              <span>
                <DescendingSortIcon />
              </span>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                className="overflow-hidden text-ellipsis ml-sm"
              >
                {messages?.common?.sortByCreatedAt?.descendingValue}
              </BodyText>
            </div>
          </div>
          <EmptySpace height="16px" />
          <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
            {messages?.common?.sortByUpdatedAt?.title}:
          </BodyText>
          <EmptySpace height="8px" />
          <div className={"u-flex u-justify-between u-width-100"}>
            <div
              className={`sorting-item u-flex u-items-center ${
                value?.key === sortKeys?.updatedAt && value?.value === -1
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                onChange({
                  key: sortKeys?.updatedAt || defaultSortKeys?.updatedAt,
                  value: -1,
                })
              }
            >
              <span>
                <AscendingSortIcon />
              </span>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                className="overflow-hidden text-ellipsis ml-sm"
              >
                {messages?.common?.sortByUpdatedAt?.ascendingValue}
              </BodyText>
            </div>
            <div
              className={`sorting-item u-flex u-items-center ${
                value?.key === sortKeys?.updatedAt && value?.value === 1
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                onChange({
                  key: sortKeys?.updatedAt || defaultSortKeys?.updatedAt,
                  value: 1,
                })
              }
            >
              <span>
                <DescendingSortIcon />
              </span>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                className="overflow-hidden text-ellipsis ml-sm"
              >
                {messages?.common?.sortByUpdatedAt?.descendingValue}
              </BodyText>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortingDropdown;

import React from "react";
import ContentLoader from "react-content-loader";

export const MapCardLoaderComponent = ({ width = 292, height = 90 }) => {
  return (
    <>
      <ContentLoader width={215} height={12} className="u-margin-b-2">
        <rect x="0" y="0" rx="2" ry="2" width="150" height="10" />
      </ContentLoader>
      <div className="ticket-map-card map-box map-card-box u-margin-b-2 ticket-map-card-loader">
        <ContentLoader width={width} height={height}>
          <circle cx="16" cy="16" r="16" />

          <rect x="0" y="48" rx="2" ry="2" width="292" height="19" />
          <rect x="0" y="78" rx="2" ry="2" width="130" height="10" />
        </ContentLoader>
      </div>
      <ContentLoader width={168} height={11}>
        <rect x="0" y="0" rx="2" ry="2" width="30" height="8" />
      </ContentLoader>
    </>
  );
};

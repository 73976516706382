import { gql } from "@apollo/client";

import {
  AiAssistant,
  Customer,
  CustomizeField,
  InventoryPart,
  Oem,
  PreventiveMaintenance,
  Ticket,
  TimeTracker,
  ProcedureTemplate,
  ProcedureInstance,
  User,
  Resource,
  Team,
  WorkOrderType,
  Reports,
  MachineHistory,
  ActivityLog,
  TicketScheduleDraft,
  UserSchedule,
  AiNote,
  AssetTemplate,
  Asset,
  Document,
} from "~/api/_fragments";
import { LIST_CONTACTS } from "~/services/contact/constants";
import { GET_SHARED_ORGANIZATION_DETAILS } from "~/services/customer/constants";
import {
  GET_DOCUMENT_BY_ID,
  LIST_DOCUMENTS,
  QUERY_DOCUMENTS,
} from "~/services/document/constants";
import { GET_GUIDE_BY_ID, LIST_GUIDES } from "~/services/guides/constants";
import { GET_KNOWLEDGE_BASE } from "~/services/knowledgeBase/constants";
import { GET_REQUEST, LIST_REQUESTS } from "~/services/request/constants";

export const GET_AI_ASSISTANT = "getAiAssistant";
export const GET_OWN_OEM = "getOwnOem";
export const LIST_OWN_OEM_PLANS = "listOwnOemPlans";
export const LIST_AI_ASSISTANTS = "listAiAssistants";
export const QUERY_AI_ASSISTANT = "queryAiAssistant";
export const CURRENT_USER = "currentUser";

// REMOTE
export const CONENCTION_QUERY = gql`
  query connection {
    connection
  }
`;

export const AUTH_QUERY = gql`
  query authenticate {
    _checkAuth
  }
`;

export const CURRENT_USER_QUERY = gql`
  query ${CURRENT_USER} {
    ${CURRENT_USER} {
      oem {
        _id
        brandLogo
        urlOem
        logo
        name
        slug
        paidFeatures
        allowHourlyPMEOption
        allowFollowersMyWorkOrders
        allowAssigneesAcrossTeams
        emailAccountLinked
        emailAccounts {
          emailAccountStatus
          emailAddress
          isSynced
          _id
        }
        installedProducts {
          type
          tier
        }
        maximumAllowedEmailAddresses
        calendarSyncConfiguration {
          allowedConnectedAccounts
          consumedConnectedAccounts
        }
        skills {
          _id
          name
        }
        statuses {
          _id
          label
          color
        }
        skills {
          _id
          name
        }
        ticketTypes {
          ...WorkOrderTypeData
        }
        usageTracking {
          logRocket
        }
        assetTypes {
          _id
          name
        }
      }
      skills {
        _id
      }
      teams {
        _id
        name
        teamColor
        disallowedTicketTypes {
          _id
        }
      }
      ...UserAuthData
    }
  }
  ${User.fragments.UserAuthData}
  ${WorkOrderType.fragments.WorkOrderTypeData}
`;
export const GET_CURRENT_USER_BOX_ACCESS_TOKEN_QUERY = gql`
  query ${CURRENT_USER} {
    ${CURRENT_USER} {
      _id
      foldersAccessToken
    }
  }
`;

export const GET_CURRENT_USER_CHAT_ACCESS_TOKEN_QUERY = gql`
  query ${CURRENT_USER} {
    ${CURRENT_USER} {
      _id
      chatToken
      chatKeys
      chatUUID
      callChannels
    }
  }
`;

export const GET_CURRENT_USER_AI_ASSISTANT_USAGE_QUERY = gql`
  query ${CURRENT_USER} {
    ${CURRENT_USER} {
      _id
      consumedRecordingSeconds
      aiAssistantConfiguration {
        consumedQueries
        consumedStorage
      }
    }
  }
`;

export const GET_OEM_BY_QUERY = gql`
  query getOemBySlug($slug: String!) {
    getOemBySlug(slug: $slug) {
      ...OemBasicData
    }
  }
  ${Oem.fragments.OemBasicData}
`;

export const GET_OWN_OEM_QUERY = gql`
  query ${GET_OWN_OEM} {
    ${GET_OWN_OEM} {
      ...OemBasicData
    }
  }
  ${Oem.fragments.OemBasicData}
`;

export const LIST_OWN_OEM_PLANS_QUERY = gql`
  query ${LIST_OWN_OEM_PLANS} {
    ${LIST_OWN_OEM_PLANS} {
      type
      tier
      users
    }
  }
`;

export const GET_OWN_OEM_AI_ASSISTANT_USAGE_QUERY = gql`
  query ${GET_OWN_OEM} {
    ${GET_OWN_OEM} {
      ...AiAssistantUsage
      ...AiAssistantConfiguration
    }
  }
  ${Oem.fragments.AiAssistantConfiguration}
  ${Oem.fragments.AiAssistantUsage}
`;

export const LIST_OWN_OEM_ASSIGNEES_QUERY = gql`
  query listOwnOemSupportAccounts {
    listOwnOemSupportAccounts {
      _id
      name
    }
  }
`;

export const LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY = gql`
  query listOwnOemSupportAccounts(
    $team: String
    $excludeTeam: String
    $withoutTeam: Boolean
    $getAllUsers: Boolean
    $useAssetTeams: Boolean
  ) {
    listOwnOemSupportAccounts(
      team: $team
      excludeTeam: $excludeTeam
      withoutTeam: $withoutTeam
      getAllUsers: $getAllUsers
      useAssetTeams: $useAssetTeams
    ) {
      productAccess
      ...UserBasicData
    }
  }
  ${User.fragments.UserBasicData}
`;

export const LIST_OWN_TICKET_MENTIONABLE_USERS_QUERY = gql`
  query listOwnTicketMentionableUsers($ticketId: ID!) {
    listOwnTicketMentionableUsers(ticketId: $ticketId) {
      oemUsers {
        _id
        name
        teams {
          _id
          name
          teamColor
        }
      }
      facilityUsers {
        _id
        name
        contactId
        contactName
        teams {
          _id
          name
          teamColor
        }
      }
    }
  }
`;

export const GET_OWN_OEM_CUSTOMER_BY_ID_QUERY = gql`
  query getOwnOemCustomerById($id: ID!) {
    getOwnOemCustomerById(id: $id) {
      ...CustomerFullData
    }
  }
  ${Customer.fragments.CustomerFullData}
`;

export const LIST_OWN_OEM_FACILITY_USERS_QUERY = gql`
  query listOwnOemFacilityUsers($params: InputQueryParams) {
    listOwnOemFacilityUsers(params: $params) {
      currentPage
      limit
      skip
      totalCount
      users {
        ...UserBasicData
      }
    }
  }
  ${User.fragments.UserBasicData}
`;

export const LIST_OWN_OEM_CLOSED_TICKETS_QUERY = gql`
  query listOwnOemClosedTickets {
    listOwnOemClosedTickets {
      ...TicketListData
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const LIST_OWN_OEM_USER_CLOSED_TICKETS_QUERY = gql`
  query listOwnOemUserClosedTickets {
    listOwnOemUserClosedTickets {
      ...TicketListData
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const LIST_OWN_OEM_OPEN_TICKETS_QUERY = gql`
  query listOwnOemOpenTickets($params: InputQueryParams) {
    listOwnOemOpenTickets(params: $params) {
      ...TicketListData
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const LIST_OWN_OEM_ALL_TICKETS_QUERY = gql`
  query listOwnOemAllTickets($params: InputQueryParams) {
    listOwnOemAllTickets(params: $params) {
      ...TicketListData
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const LIST_OWN_OEM_TICKETS_QUERY = gql`
  query listOwnOemTickets($params: InputQueryParams) {
    listOwnOemTickets(params: $params) {
      currentPage
      limit
      skip
      totalCount
      tickets {
        ...TicketListData
      }
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const LIST_REQUESTS_QUERY = gql`
  query ${LIST_REQUESTS}($params: InputQueryParams) {
    ${LIST_REQUESTS}(params: $params) {
      currentPage
      limit
      skip
      totalCount
      requests {
        ...TicketListData
      }
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const LIST_OWN_OEM_KANBAN_TICKETS = gql`
  query listOwnOemKanbanTickets($params: InputQueryParams) {
    listOwnOemKanbanTickets(params: $params) {
      totalCount
      columns {
        _id
        boardFor
        type
        customField
        column
        status {
          _id
          label
          color
        }
        columnIndex
        cards {
          ...TicketListData
        }
        totalCount
        limit
        skip
        currentPage
      }
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const LIST_OEM_CUSTOMERS_IN_AREA = gql`
  query listOemCustomersInArea($params: InputQueryParams) {
    listOemCustomersInArea(params: $params) {
      customerAddress {
        coordinates
      }
      _id
    }
  }
`;

export const LIST_OWN_OEM_USER_OPEN_TICKETS_QUERY = gql`
  query listOwnOemUserOpenTickets {
    listOwnOemUserOpenTickets {
      ...TicketListData
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const GET_OWN_OEM_TICKET_BY_ID = gql`
  query getOwnOemTicketById($id: ID!) {
    getOwnOemTicketById(id: $id) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const GET_REQUEST_QUERY = gql`
  query ${GET_REQUEST}($requestId: ID!) {
    ${GET_REQUEST}(requestId: $requestId) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const LIST_ALL_OEM_CUSTOMERS_IN_AREA = gql`
  query listAllOemCustomersInArea($params: InputQueryParams) {
    listAllOemCustomersInArea(params: $params) {
      customerAddress {
        coordinates
      }
      _id
    }
  }
`;

export const LIST_ADDITIONAL_FIELDS_QUERY = gql`
  query listAdditionalFields($type: Types) {
    listAdditionalFields(type: $type) {
      ...AdditionFieldData
    }
  }
  ${CustomizeField.fragments.AdditionFieldData}
`;

export const LIST_OWN_OEM_CUSTOM_ADDITIONAL_FIELDS_QUERY = gql`
  query listOwnOemCustomFields($type: Types) {
    listOwnOemCustomFields(type: $type) {
      ...CustomAdditionFieldData
    }
  }
  ${CustomizeField.fragments.CustomAdditionFieldData}
`;

export const LIST_PREVENTIVE_MAINTENANCE_EVENTS = gql`
  query listPreventiveMaintenanceEvents($params: InputQueryParams!) {
    listPreventiveMaintenanceEvents(params: $params) {
      ...PreventiveEventData
    }
  }
  ${PreventiveMaintenance.fragments.PreventiveEventData}
`;

export const LIST_SHARED_PREVENTIVE_MAINTENANCE_EVENTS = gql`
  query listSharedPreventiveMaintenanceEvents($params: InputQueryParams!) {
    listSharedPreventiveMaintenanceEvents(params: $params) {
      ...PreventiveEventData
    }
  }
  ${PreventiveMaintenance.fragments.PreventiveEventData}
`;

export const LIST_ALL_PREVENTIVE_MAINTENANCE_EVENTS = gql`
  query listAllPreventiveMaintenanceEvents($params: InputQueryParams!) {
    listAllPreventiveMaintenanceEvents(params: $params) {
      totalCount
      events {
        ...PreventiveEventDetailedData
      }
    }
  }
  ${PreventiveMaintenance.fragments.PreventiveEventDetailedData}
`;

export const GET_PREVENTIVE_MAINTENANCE_EVENT_BY_ID = gql`
  query getPreventiveMaintenanceEventByID($id: ID!) {
    getPreventiveMaintenanceEventByID(id: $id) {
      ...PreventiveEventData
    }
  }
  ${PreventiveMaintenance.fragments.PreventiveEventData}
`;

export const GET_OWN_OEM_REPORT_BY_ID_QUERY = gql`
  query getOwnOemReportByID($id: ID!) {
    getOwnOemReportByID(id: $id) {
      _id
      title
      entity
      segment {
        label
        value
      }
      xAxis {
        label
        value
      }
      table {
        label
        value
      }
      chartType
      filters {
        filterType
        field
        condition
        value {
          label
          value
        }
      }
      inputs {
        xAxis {
          label
          value
        }
        segment {
          label
          value
        }
        filter {
          filterType
          field
          condition
          value {
            label
            value
          }
        }
        table {
          label
          value
        }
      }
    }
  }
`;

export const LIST_OEM_INVENTORY_PARTS_QUERY = gql`
  query ListOwnOemInventoryPart($params: InputInventoryQueryParams) {
    listOwnOemInventoryPart(params: $params) {
      currentPage
      limit
      skip
      totalCount
      parts {
        ...InventoryPartBasicData
      }
    }
  }
  ${InventoryPart.fragments.InventoryPartBasicData}
`;

export const GET_INVENTORY_PART = gql`
  query getInventoryPart($id: ID!) {
    getInventoryPart(id: $id) {
      ...InventoryPartFullData
    }
  }
  ${InventoryPart.fragments.InventoryPartFullData}
`;

export const LIST_OWN_OEM_TIME_TRACKERS_QUERY = gql`
  query ListOwnOemTimeTrackers($type: Types!, $fieldType: FieldTypes!) {
    listOwnOemTimeTrackers(type: $type, fieldType: $fieldType) {
      ...TimeTrackerBasicData
    }
  }
  ${TimeTracker.fragments.TimeTrackerBasicData}
`;

export const LIST_OWN_OEM_CUSTOMERS_MISSING_CLIENT_IDS_QUERY = gql`
  query listOwnOemCustomersMissingClientIds($clientIds: [String!]!) {
    listOwnOemCustomersMissingClientIds(clientIds: $clientIds)
  }
`;

export const LIST_OWN_OEM_DUPLICATE_TICKETS = gql`
  query listOwnOemDuplicateTickets($ticketIds: [String!]!) {
    listOwnOemDuplicateTickets(ticketIds: $ticketIds)
  }
`;

export const LIST_ASSETS_WITH_LINKED_TEMPLATES = gql`
  query listAssetsWithLinkedTemplates($serialNumbers: [String!]!) {
    listAssetsWithLinkedTemplates(serialNumbers: $serialNumbers)
  }
`;

export const LIST_USERS_DUPLICATED_EMAILS_QUERY = gql`
  query listUsersDuplicatedEmails($emails: [String!]!) {
    listUsersDuplicatedEmails(emails: $emails)
  }
`;

export const LIST_OEM_PROCEDURE_TEMPLATES_QUERY = gql`
  query listOwnOemProcedureTemplates($params: InputQueryParams!) {
    listOwnOemProcedureTemplates(params: $params) {
      _id
      name
      createdAt
      updatedAt
      createdBy {
        _id
        name
      }
    }
  }
`;

export const LIST_ALL_OWN_OEM_REPORTS_QUERY = gql`
  query listAllOwnOemReports($params: InputQueryParams) {
    listAllOwnOemReports(params: $params) {
      currentPage
      limit
      skip
      totalCount
      reports {
        ...ReportData
      }
    }
  }
  ${Reports.fragments.ReportData}
`;

export const GET_OWN_OEM_PROCEDURE_TEMPLATE = gql`
  query GetOwnOemProcedureTemplate($id: String) {
    getOwnOemProcedureTemplate(id: $id) {
      ...ProcedureTemplateFullData
    }
  }
  ${ProcedureTemplate.fragments.ProcedureTemplateFullData}
`;
export const GET_OWN_OEM_PROCEDURE_INSTANCE = gql`
  query getOwnOemProcedureById($id: ID!) {
    getOwnOemProcedureById(id: $id) {
      ...ProcedureInstanceFullData
    }
  }
  ${ProcedureInstance.fragments.ProcedureInstanceFullData}
`;

export const DOWNLOAD_PROCEDURE_PDF_BY_ID = gql`
  query downloadProcedurePDF($id: ID!, $uuid: String!) {
    downloadProcedurePDF(id: $id, uuid: $uuid)
  }
`;

export const DOWNLOAD_WO_PDF_BY_ID = gql`
  query downloadWOPDF($input: InputDownloadWOPDF) {
    downloadWOPDF(input: $input)
  }
`;

export const GET_APP_CONFIG = gql`
  query getAppConfig {
    getAppConfig {
      _id
      features
      plans {
        type
        name
        tiers {
          type
          name
          price
          allowedFeatures
          limits {
            type
            value
          }
        }
        conflictingPurchases
      }
    }
  }
`;

export const LIST_ALL_OWN_OEM_CUSTOMERS_QUERY = gql`
  query listAllOwnOemCustomers($params: InputQueryParams) {
    listAllOwnOemCustomers(params: $params) {
      currentPage
      limit
      skip
      totalCount
      customers {
        ...CustomerBasicData
      }
    }
  }
  ${Customer.fragments.CustomerBasicData}
`;

export const LIST_OWN_OEM_RESOURCES_QUERY = gql`
  query listOwnOemResources($params: InputQueryParams) {
    listOwnOemResources(params: $params) {
      currentPage
      limit
      resources {
        ...ResourceListData
      }
      skip
      totalCount
    }
  }
  ${Resource.fragments.ResourceListData}
`;

export const GET_OWN_OEM_TICKETS_BY_STATUS = gql`
  query checkTicketHasStatus($statusId: ID!) {
    checkTicketHasStatus(statusId: $statusId)
  }
`;

export const LIST_OWN_OEM_KANBAN_CUSTOMERS = gql`
  query listOwnOemKanbanCustomers($params: InputQueryParams) {
    listOwnOemKanbanCustomers(params: $params) {
      totalCount
      columns {
        _id
        boardFor
        type
        customField
        column
        columnIndex
        cards {
          ...CustomerBasicData
        }
        totalCount
        limit
        skip
        currentPage
      }
    }
  }
  ${Customer.fragments.CustomerBasicData}
`;

export const GET_EMAIL_AUTH_LINK_QUERY = gql`
  query getEmailAuthUrl {
    getEmailAuthUrl
  }
`;

export const LIST_EMAIL_FOLDERS_QUERY = gql`
  query listEmailFolders($params: InputQueryParams) {
    listEmailFolders(params: $params) {
      folders {
        _id
        id
        grantId
        name
        attributes
        totalCount
        unreadCount
        parentId
        childCount
      }
    }
  }
`;

export const LIST_EMAIL_FOLDERS_NYLAS_QUERY = gql`
  query listEmailFoldersNylas($pageToken: String, $emailAddressId: ID!) {
    listEmailFoldersNylas(
      pageToken: $pageToken
      emailAddressId: $emailAddressId
    ) {
      folders {
        _id
        id
        grantId
        name
        attributes
        totalCount
        unreadCount
        parentId
        childCount
      }
    }
  }
`;

export const LIST_EMAIL_THREADS_BY_FOLDER_QUERY = gql`
  query listEmailThreadsByFolder($params: InputQueryParams) {
    listEmailThreadsByFolder(params: $params) {
      threads {
        _id
        id
        grantId
        hasAttachments
        latestMessageReceivedDate
        latestMessageSentDate
        messageIds
        folders
        participants {
          email
          name
        }
        snippet
        subject
        unread
      }
      totalCount
      currentPage
    }
  }
`;

export const LIST_EMAIL_THREADS_BY_FOLDER_NYLAS_QUERY = gql`
  query listEmailThreadsByFolderNylas(
    $pageToken: String
    $folderId: String!
    $search: String!
    $emailAddressId: ID!
  ) {
    listEmailThreadsByFolderNylas(
      pageToken: $pageToken
      folder: $folderId
      search: $search
      emailAddressId: $emailAddressId
    ) {
      threads {
        _id
        id
        grantId
        hasAttachments
        latestMessageReceivedDate
        latestMessageSentDate
        messageIds
        folders
        participants {
          email
          name
        }
        snippet
        subject
        unread
      }
      pageToken
    }
  }
`;

export const LIST_EMAIL_THREADS_BY_TICKET_QUERY = gql`
  query listEmailThreadsByTicket($ticketId: ID!, $emailAddressId: ID!) {
    listEmailThreadsByTicket(
      ticketId: $ticketId
      emailAddressId: $emailAddressId
    ) {
      _id
      id
      grantId
      earliestMessageDate
      hasAttachments
      latestMessageReceivedDate
      latestMessageSentDate
      messageIds
      messages {
        bcc {
          name
          email
        }
        cc {
          name
          email
        }
        date
        from {
          name
          email
        }
        id
        replyTo {
          name
          email
        }
        subject
        to {
          name
          email
        }
        unread
      }
      folders
      participants {
        name
        email
      }
      snippet
      subject
      unread
    }
  }
`;

export const LIST_EMAIL_THREADS_BY_TICKET_NYLAS_QUERY = gql`
  query listEmailThreadsByTicketNylas($ticketId: ID!, $emailAddressId: ID!) {
    listEmailThreadsByTicketNylas(
      ticketId: $ticketId
      emailAddressId: $emailAddressId
    )
  }
`;

export const GET_EMAIL_THREAD_QUERY = gql`
  query getEmailThread($threadId: String!, $emailAddressId: ID!) {
    getEmailThread(threadId: $threadId, emailAddressId: $emailAddressId) {
      thread {
        id
        grantId
        earliestMessageDate
        hasAttachments
        latestMessageReceivedDate
        latestMessageSentDate
        messageIds
        messages {
          bcc {
            name
            email
          }
          cc {
            name
            email
          }
          date
          from {
            name
            email
          }
          id
          replyTo {
            name
            email
          }
          subject
          to {
            name
            email
          }
          unread
        }
        folders
        participants {
          name
          email
        }
        snippet
        subject
        unread
      }
      ticket {
        _id
        ticketId
      }
    }
  }
`;

export const GET_EMAIL_THREAD_NYLAS_QUERY = gql`
  query getEmailThreadNylas($threadId: String!, $emailAddressId: ID!) {
    getEmailThreadNylas(threadId: $threadId, emailAddressId: $emailAddressId) {
      thread {
        id
        grantId
        earliestMessageDate
        hasAttachments
        latestMessageReceivedDate
        latestMessageSentDate
        messageIds
        messages {
          bcc {
            name
            email
          }
          cc {
            name
            email
          }
          date
          from {
            name
            email
          }
          id
          replyTo {
            name
            email
          }
          subject
          to {
            name
            email
          }
          unread
        }
        folders
        participants {
          name
          email
        }
        snippet
        subject
        unread
      }
      ticket {
        _id
        ticketId
      }
    }
  }
`;

export const GET_EMAIL_DRAFT_QUERY = gql`
  query getDraft($emailAddressId: ID!, $draftId: String!) {
    getDraft(emailAddressId: $emailAddressId, draftId: $draftId) {
      draft
      fileUrl
    }
  }
`;

export const GET_EMAIL_MESSAGE_QUERY = gql`
  query getEmailMessage($messageId: String!, $emailAddressId: ID!) {
    getEmailMessage(messageId: $messageId, emailAddressId: $emailAddressId) {
      message {
        _id
        id
        grantId
        starred
        unread
        folders
        subject
        threadId
        body
        snippet
        bcc {
          email
          name
        }
        cc {
          email
          name
        }
        attachments {
          contentId
          contentType
          filename
          grantId
          id
          isInline
          size
        }
        from {
          email
          name
        }
        replyTo {
          email
          name
        }
        to {
          email
          name
        }
        date
      }
      fileUrl
    }
  }
`;

export const GET_EMAIL_MESSAGE_NYLAS_QUERY = gql`
  query getEmailMessageNylas($messageId: String!, $emailAddressId: ID!) {
    getEmailMessageNylas(
      messageId: $messageId
      emailAddressId: $emailAddressId
    ) {
      message {
        _id
        id
        grantId
        starred
        unread
        folders
        subject
        threadId
        body
        snippet
        bcc {
          email
          name
        }
        cc {
          email
          name
        }
        attachments {
          contentId
          contentType
          filename
          grantId
          id
          isInline
          size
        }
        from {
          email
          name
        }
        replyTo {
          email
          name
        }
        to {
          email
          name
        }
        date
      }
      fileUrl
    }
  }
`;

export const DOWNLOAD_EMAIL_FILE_QUERY = gql`
  query downloadEmailFile(
    $fileId: String!
    $uuid: String!
    $messageId: String!
    $emailAddressId: ID!
  ) {
    downloadEmailFile(
      fileId: $fileId
      uuid: $uuid
      messageId: $messageId
      emailAddressId: $emailAddressId
    )
  }
`;

export const GET_EMAIL_FILE_UPLOAD_URL_QUERY = gql`
  query getEmailFileUploadUrl {
    getEmailFileUploadUrl
  }
`;

export const LIST_EMAIL_CONTACTS_QUERY = gql`
  query listEmailContacts(
    $pageToken: String
    $search: String
    $emailAddressId: ID!
  ) {
    listEmailContacts(
      pageToken: $pageToken
      search: $search
      emailAddressId: $emailAddressId
    ) {
      pageToken
      contacts
    }
  }
`;

export const LIST_DRAFT_JUNK_COUNT = gql`
  query getDraftAndJunkEmailCount($emailAddressId: ID!) {
    getDraftAndJunkEmailCount(emailAddressId: $emailAddressId) {
      drafts
      junk
    }
  }
`;

export const LIST_EMAIL_DRAFTS_QUERY = gql`
  query listDrafts($pageToken: String, $search: String, $emailAddressId: ID!) {
    listDrafts(
      pageToken: $pageToken
      search: $search
      emailAddressId: $emailAddressId
    ) {
      pageToken
      drafts
    }
  }
`;

export const GET_OWN_OEM_ANALYTICS = gql`
  query getOwnOemAnalytics($input: InputGetAnalytics!) {
    getOwnOemAnalytics(input: $input) {
      data
      inputTableOptions {
        value
        label
      }
    }
  }
`;

export const LIST_TEAMS_QUERY = gql`
  query listTeams($params: InputQueryParams) {
    listTeams(params: $params) {
      currentPage
      limit
      skip
      totalCount
      teams {
        _id
        name
        description
        numberOfMembers
        teamColor
      }
    }
  }
`;

export const GET_TEAM_QUERY = gql`
  query getTeam($id: ID!) {
    getTeam(id: $id) {
      ...TeamData
    }
  }
  ${Team.fragments.TeamData}
`;

export const GET_OWN_OEM_TICKETS_BY_TYPES_QUERY = gql`
  query checkIfTicketsExistForType($ticketTypeId: ID!) {
    checkIfTicketsExistForType(ticketTypeId: $ticketTypeId)
  }
`;

export const GET_SINGLE_LIST_TICKET_BY_ID_QUERY = gql`
  query getOwnOemTicketById($id: ID!) {
    getOwnOemTicketById(id: $id) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const LIST_OWN_OEM_MACHINE_HISTORY = gql`
  query listOwnOemMachineHistory($params: InputQueryParams!) {
    listOwnOemMachineHistory(params: $params) {
      totalCount
      skip
      limit
      currentPage
      ticketCount
      history {
        ...MachineHistoryData
      }
    }
  }
  ${MachineHistory.fragments.MachineHistoryData}
`;

export const LIST_OWN_OEM_SHARED_ASSET_HISTORY = gql`
  query listOwnOemSharedAssetHistory($params: InputQueryParams!) {
    listOwnOemSharedAssetHistory(params: $params) {
      totalCount
      skip
      limit
      currentPage
      ticketCount
      history {
        ...MachineHistoryData
      }
    }
  }
  ${MachineHistory.fragments.MachineHistoryData}
`;

export const LIST_OWN_OEM_ACTIVITY_LOGS = gql`
  query listOwnOemActivityLogs($params: InputQueryParams) {
    listOwnOemActivityLogs(params: $params) {
      totalCount
      skip
      limit
      currentPage
      logs {
        ...ActivityLogData
      }
    }
  }
  ${ActivityLog.fragments.ActivityLogData}
`;

export const GET_MACHINE_3D_AUTH_TOKEN_QUERY = gql`
  query getMachine3DAuthToken($id: ID!) {
    getMachine3DAuthToken(id: $id)
  }
`;

export const GET_DATA_CSV_QUERY = gql`
  query getDataCsv($params: InputQueryParams!) {
    getDataCsv(params: $params)
  }
`;

export const GET_AI_ASSISTANT_DOCUMENTS_STATUS_QUERY = gql`
  query ${GET_AI_ASSISTANT}($id: ID!) {
    ${GET_AI_ASSISTANT}(id: $id) {
      ...AiAssistantDocumentsData
    }
  }
  ${AiAssistant.Documents}
`;

export const LIST_AI_ASSISTANTS_QUERY = gql`
  query ${LIST_AI_ASSISTANTS}($limit: Int) {
    ${LIST_AI_ASSISTANTS}(limit: $limit) {
      aiAssistants {  
        ...BasicData
      }
      totalCount
    }
  }
  ${AiAssistant.BasicData}
`;

export const QUERY_AI_ASSISTANT_QUERY = gql`
  query ${QUERY_AI_ASSISTANT}($input: InputQueryAiAssistant!) {
    ${QUERY_AI_ASSISTANT}(input: $input) {
      ...AiAssistantQueryResponseFragment
    }
  }
  ${AiAssistant.Query}
`;

export const GET_OWN_TICKET_SCHEDULE_DRAFT_QUERY = gql`
  query getOwnTicketScheduleDraft {
    getOwnTicketScheduleDraft {
      ...TicketScheduleDraftData
    }
  }
  ${TicketScheduleDraft.fragments.TicketScheduleDraftData}
`;

export const LIST_OWN_USER_SCHEDULES_QUERY = gql`
  query listOwnUserSchedules($params: InputQueryParams) {
    listOwnUserSchedules(params: $params) {
      currentPage
      limit
      skip
      totalCount
      userSchedules {
        ...UserScheduleData
      }
    }
  }
  ${UserSchedule.fragments.UserScheduleData}
`;

export const GET_CALENDAR_SYNC_AUTH_LINK_QUERY = gql`
  query getCalendarSyncAuthUrl {
    getCalendarSyncAuthUrl
  }
`;

export const LIST_OWN_CALENDAR_EVENTS_QUERY = gql`
  query listUsersCalendarEvents($startDate: DateTime!, $endDate: DateTime!) {
    listUsersCalendarEvents(startDate: $startDate, endDate: $endDate) {
      id
      when
      assignees
    }
  }
`;

export const LIST_GUIDES_QUERY = gql`
  query ${LIST_GUIDES}($params: InputQueryParams!) {
    ${LIST_GUIDES}(params: $params) {
      totalCount
      skip
      limit
      currentPage
      guides {
        _id
        machine {
          ...AssetFullData
        }
        oem
        sessionId
        name
        image
        createdBy {
          _id
          name
        }
      }
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const GET_GUIDE_BY_ID_QUERY = gql`
  query ${GET_GUIDE_BY_ID}($id: ID!) {
    ${GET_GUIDE_BY_ID}(id: $id) {
      _id
      machine {
        ...AssetFullData
      }
      oem
      sessionId
      name
      image
      createdBy {
        _id
        name
      }
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const GET_SHARED_ORGANIZATION_DETAILS_QUERY = gql`
  query ${GET_SHARED_ORGANIZATION_DETAILS}($connectionId: ID!) {
    ${GET_SHARED_ORGANIZATION_DETAILS}(connectionId: $connectionId) {
        oem {
          ...OemBasicData
          assetTypes {
            name
            _id
          }
          paidFeatures
        }
        assetAccess {
          documentation
          parts
          preventiveMaintenance
          _3dModel
          qrCodes
          history
        }
    }
  }
  ${Oem.fragments.OemBasicData}
`;

export const GET_OWN_AI_NOTE_QUERY = gql`
  query getOwnAiNote($id: ID!) {
    getOwnAiNote(id: $id) {
      ...AiNoteData
    }
  }
  ${AiNote.fragments.AiNoteData}
`;

export const GET_AI_NOTE_QUERY = gql`
  query getOwnAiNote($id: ID!) {
    getOwnAiNote(id: $id) {
      ...AiNotePublicData
    }
  }
  ${AiNote.fragments.AiNotePublicData}
`;

export const LIST_OWN_AI_NOTES_QUERY = gql`
  query listOwnAiNotes($params: InputQueryParams) {
    listOwnAiNotes(params: $params) {
      currentPage
      limit
      skip
      totalCount
      aiNotes {
        ...AiNoteBasicData
      }
    }
  }
  ${AiNote.fragments.AiNoteBasicData}
`;

export const GET_OWN_OEM_ASSET_TEMPLATE_BY_ID = gql`
  query getOwnOemAssetTemplate($templateId: ID!) {
    getOwnOemAssetTemplate(templateId: $templateId) {
      ...AssetTemplateFullData
    }
  }
  ${AssetTemplate.fragments.AssetTemplateFullData}
`;

export const LIST_OWN_OEM_ASSET_TEMPLATE_QUERY = gql`
  query listOwnOemAssetTemplates($params: InputQueryParams!) {
    listOwnOemAssetTemplates(params: $params) {
      ...AssetTemplateData
    }
  }
  ${AssetTemplate.fragments.AssetTemplateData}
`;

export const GET_OWN_OEM_ASSET_BY_ID_QUERY = gql`
  query getOwnOemAssetById($id: ID!, $isSharedAsset: Boolean) {
    getOwnOemAssetById(id: $id, isSharedAsset: $isSharedAsset) {
      ...AssetFullData
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const GET_SHARED_OEM_ASSET_BY_ID_QUERY = gql`
  query getOwnOemAssetById($id: ID!, $isSharedAsset: Boolean) {
    getOwnOemAssetById(id: $id, isSharedAsset: $isSharedAsset) {
      ...AssetFullData
      sharedAssetAccess {
        documentation
        parts
        preventiveMaintenance
        _3dModel
        qrCodes
        history
      }
      oem {
        _id
        name
        slug
        statuses {
          _id
          label
          color
        }
        assetTypes {
          _id
          name
        }
        ticketTypes {
          ...WorkOrderTypeData
        }
        website
        backgroundColor
        textColor
        logo
        thumbnail
        brandLogo
        coverPhoto
        slug
        paidFeatures
        installedProducts {
          type
          tier
        }
      }
    }
  }
  ${Asset.fragments.AssetFullData}
  ${WorkOrderType.fragments.WorkOrderTypeData}
`;

export const GET_OWN_OEM_ASSET_PARTS_BY_ID_QUERY = gql`
  query getOwnOemAssetById($id: ID!, $isSharedAsset: Boolean) {
    getOwnOemAssetById(id: $id, isSharedAsset: $isSharedAsset) {
      ...AssetPartsData
    }
  }
  ${Asset.fragments.AssetPartsData}
`;

export const GET_BOX_FOLDER_ACCESS_TOKEN_QUERY = gql`
  query getBoxFolderAccessToken($assetId: ID!) {
    getBoxFolderAccessToken(assetId: $assetId)
  }
`;

export const LIST_ALL_OWN_OEM_ASSETS_QUERY = gql`
  query listAllOwnOemAssets($params: InputQueryParams) {
    listAllOwnOemAssets(params: $params) {
      currentPage
      limit
      skip
      totalCount
      assets {
        ...AssetListData
      }
    }
  }
  ${Asset.fragments.AssetListData}
`;

export const LIST_REQUESTABLE_ASSETS_QUERY = gql`
  query listRequestableAssets($params: InputQueryParams) {
    listRequestableAssets(params: $params) {
      currentPage
      limit
      skip
      totalCount
      assets {
        ...AssetListData
        oem {
          ...OemBasicData
        }
      }
    }
  }
  ${Asset.fragments.AssetListData}
  ${Oem.fragments.OemBasicData}
`;

export const LIST_ALL_OWN_OEM_ASSETS_SEARCH_QUERY = gql`
  query listAllOwnOemAssets($params: InputQueryParams) {
    listAllOwnOemAssets(params: $params) {
      currentPage
      limit
      skip
      totalCount
      assets {
        ...AssetSearchListData
      }
    }
  }
  ${Asset.fragments.AssetSearchListData}
`;

export const LIST_OWN_OEM_ASSETS_MISSING_SERIAL_NUMBERS_QUERY = gql`
  query listOwnOemAssetsMissingSerialNumbers($serialNumbers: [String!]!) {
    listOwnOemAssetsMissingSerialNumbers(serialNumbers: $serialNumbers)
  }
`;

export const LIST_OWN_OEM_ASSET_TEMPLATES_MISSING_TEMPLATE_IDS_QUERY = gql`
  query listOwnOemAssetTemplatesMissingTemplateIds($templateIds: [String!]!) {
    listOwnOemAssetTemplatesMissingTemplateIds(templateIds: $templateIds)
  }
`;

export const LIST_OWN_OEM_KANBAN_ASSETS = gql`
  query listOwnOemKanbanAssets($params: InputQueryParams) {
    listOwnOemKanbanAssets(params: $params) {
      totalCount
      columns {
        _id
        boardFor
        type
        customField
        column
        assetType {
          _id
          label
        }
        columnIndex
        cards {
          ...AssetListData
        }
        totalCount
        limit
        skip
        currentPage
      }
    }
  }
  ${Asset.fragments.AssetListData}
`;

export const TRANSLATE_TEXT_QUERY = gql`
  query translateText($input: InputTranslateText!) {
    translateText(input: $input) {
      language
      name
    }
  }
`;

export const LIST_OWN_SHARED_ASSETS_OEM = gql`
  query listSharedAssetsOrganizations($params: InputQueryParams) {
    listSharedAssetsOrganizations(params: $params) {
      totalCount
      oems {
        assetsCount
        linkedConnection
        ...OemBasicData
      }
    }
  }
  ${Oem.fragments.OemBasicData}
`;

export const LIST_CONTACTS_QUERY = gql`
  query ${LIST_CONTACTS}($params: InputQueryParams!) {
    ${LIST_CONTACTS}(params: $params) {
      totalCount
      skip
      limit
      currentPage
      contacts {
        _id
        name
        email
        deleted
        jobTitle
        landline
        phoneNumber
        oem
        user
        connection
        accessStatus
        canResendInvite
      }
    }
  }
`;

export const LIST_DOCUMENTS_QUERY = gql`
  query ${LIST_DOCUMENTS}($params: InputQueryParams!) {
    ${LIST_DOCUMENTS}(params: $params) {
      totalCount
      skip
      limit
      currentPage
      documents {
        ...DocumentData
      }
    }
  }
  ${Document.fragments.DocumentData}
`;

export const GET_DOCUMENT_BY_ID_QUERY = gql`
  query ${GET_DOCUMENT_BY_ID}($documentId: ID!) {
    ${GET_DOCUMENT_BY_ID}(documentId: $documentId) {
      ...DocumentData
    }
  }
  ${Document.fragments.DocumentData}
`;

export const QUERY_DOCUMENTS_QUERY = gql`
  query ${QUERY_DOCUMENTS}($query: String!, $activeFilters: JSON) {
    ${QUERY_DOCUMENTS}(query: $query, activeFilters: $activeFilters) {
      summary
      results {
        _id
        boxDocumentId
        chunkIndex
        content
        isOcrDocument
        ocrGeneratedContent
        documentCustomFields {
          fieldId {
            _id
            label
            options {
              _id
              value
              color
            }
          }
          value
        }
        documentTitle
      }
    }
  }
`;

export const GET_OWN_CUSTOMER_ASSET_BY_UUID_QUERY = gql`
  query getOwnCustomerAssetByUuid($uuid: ID!) {
    getOwnCustomerAssetByUuid(uuid: $uuid) {
      ...AssetFullData
      isOwnAsset
      isSharedAsset
      isQRCodeFlowEnabled
      sharedAssetAccess {
        documentation
        parts
        preventiveMaintenance
        _3dModel
        qrCodes
        history
      }
      oem {
        _id
        name
        slug
        statuses {
          _id
          label
          color
        }
        assetTypes {
          _id
          name
        }
        logo
        thumbnail
        brandLogo
        coverPhoto
        slug
        website
        paidFeatures
      }
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const GET_OWN_CUSTOMER_ASSET_PARTS_BY_UUID_QUERY = gql`
  query getOwnCustomerAssetByUuid($uuid: ID!) {
    getOwnCustomerAssetByUuid(uuid: $uuid) {
      ...AssetPartsData
      isOwnAsset
      isSharedAsset
    }
  }
  ${Asset.fragments.AssetPartsData}
`;

export const GET_KNOWLEDGE_BASE_QUERY = gql`
  query ${GET_KNOWLEDGE_BASE} {
    ${GET_KNOWLEDGE_BASE} {
      _id
      folderId
    }
  }
`;

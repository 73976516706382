import React from "react";
import getEnum from "$/settings/enums";
import BoardFor from "$/settings/enums/kanban/_boardFor.json";
import { KanbanTicketCard } from "./card/_ticketCard";
import { KanbanMachineCard } from "./card/_machineCard";
import { KanbanFacilityCard } from "./card/_facilityCard";
const boardForEnum = getEnum(BoardFor, "reference");

const KanbanCardRenderer = (
  boardType,
  isTeamsPaid,
  {
    item,
    setNodeRef,
    style,
    listeners,
    attributes,
    isDragging,
    isSorting,
    over,
    overIndex,
  },
) => {
  switch (boardType) {
    case boardForEnum?.ticket: {
      return (
        <KanbanTicketCard
          boardType={boardType}
          item={item}
          ref={setNodeRef}
          style={style}
          listeners={listeners}
          attributes={attributes}
          isDragging={isDragging}
          isSorting={isSorting}
          over={over}
          overIndex={overIndex}
        />
      );
    }
    case boardForEnum?.machine: {
      return (
        <KanbanMachineCard
          boardType={boardType}
          item={item}
          ref={setNodeRef}
          style={style}
          listeners={listeners}
          attributes={attributes}
          isDragging={isDragging}
          isSorting={isSorting}
          over={over}
          overIndex={overIndex}
          isTeamsPaid={isTeamsPaid}
        />
      );
    }
    case boardForEnum?.facility: {
      return (
        <KanbanFacilityCard
          boardType={boardType}
          item={item}
          ref={setNodeRef}
          style={style}
          listeners={listeners}
          attributes={attributes}
          isDragging={isDragging}
          isSorting={isSorting}
          over={over}
          overIndex={overIndex}
          isTeamsPaid={isTeamsPaid}
        />
      );
    }
    default:
      return null;
  }
};
export default KanbanCardRenderer;

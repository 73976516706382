import { useApolloClient } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import PubNub from "pubnub";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { APP } from "~/apollo/_config";
import { calendarViewAtom, DEFAULT_VIEW } from "~/atoms/_calendarView";
import { allMembershipChannelsAtom } from "~/atoms/_chat";
import { sortAtom } from "~/atoms/_sort";
import { sidebarAtom } from "~/atoms/sidebar";
import { languageAtom } from "~/lang/provider";
import { useGetCurrentUser, useUserLogin } from "~/services";
import { base64String, isTrackingDisabled, saveLocaleToStorage } from "~/utils";
import { isMixpanelEnabled } from "~/utils/_mixpanel";

const { ROUTES: { OEM_LOGIN: LOGIN } = {} } = APP;

const isFunction = (functionToCheck) =>
  functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";

let chat;

const useAuth = () => {
  const history = useHistory();
  const { messages: msg } = useIntl();

  const languageCoil = useRecoilValue(languageAtom);
  const [, setSidebarExpanded] = useRecoilState(sidebarAtom);
  const [, setCalendarView] = useRecoilState(calendarViewAtom);
  const setAllMembershipChannelsCoil = useSetRecoilState(
    allMembershipChannelsAtom,
  );
  const [, setSort] = useRecoilState(sortAtom);
  const { currentUser, loading, refetch } = useGetCurrentUser();
  const { submitLogin } = useUserLogin();
  const client = useApolloClient();
  const { update } = useIntercom();

  React.useEffect(() => {
    if (!chat && currentUser?.chatKeys && currentUser?.chatUUID) {
      chat = new PubNub({
        ...currentUser.chatKeys,
        uuid: currentUser.chatUUID,
        suppressLeaveEvents: true,
      });
    }
    const { chatToken } = currentUser || {};
    const validToken = chat?._tokenManager?._token === chatToken;

    if (!validToken && chatToken) {
      chat?.setToken?.(chatToken);
    }
  }, [
    currentUser?.chatKeys,
    loading,
    currentUser?.chatUUID,
    currentUser?.chatToken,
  ]);

  if (loading) return <p>{msg.common?.loading}</p>;

  const oemUrl = currentUser?.oem?.urlOem?.split("/");
  let loginURL = LOGIN;

  if (oemUrl) {
    loginURL = `${LOGIN}/${oemUrl[oemUrl?.length - 1]}`;
  }

  const logout = () => {
    const trackingDisabled = isTrackingDisabled();
    localStorage.clear();

    if (isMixpanelEnabled()) {
      mixpanel.reset();
    }

    sessionStorage.clear();

    setSidebarExpanded(false);
    setCalendarView({ currentView: DEFAULT_VIEW });

    if (import.meta.env.VITE_APP_INTERCOM_APP_ID) {
      update({
        hideDefaultLauncher: false,
      });
    }

    client.clearStore().then(() => {
      chat = undefined;
      if (languageCoil?.lang) saveLocaleToStorage(languageCoil?.lang);
      if (trackingDisabled) window.location.assign(loginURL);
      else history.push(loginURL);
    });
    setSort({
      tickets: [],
      customers: [],
      inventory: [],
      machines: [],
    });
    setAllMembershipChannelsCoil({});
  };

  const login = ({ email, username, password, organizationId }, callback) => {
    submitLogin({
      userCredentials: {
        username: email?.toLowerCase() || username?.toLowerCase(),
        password: base64String(password),
        organization: organizationId,
      },
    })
      .then((res) => {
        if (!!callback && isFunction(callback)) {
          callback(null, res);
        } else history.push("/");
      })
      .catch((e) => {
        if (!!callback && isFunction(callback)) callback(e);
      });
  };

  return {
    user: currentUser,
    refetch,
    chat,
    loading,
    login,
    logout,
    isLoggedIn: !!currentUser && !!Object.keys(currentUser).length,
  };
};

export default useAuth;

import * as React from "react";
const SvgRequestsActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M2.5 9.41c0 2.235 1.183 4.203 3 5.444-.002.713 0 1.673 0 2.68l2.943-1.456c.503.097 1.023.15 1.557.15 4.127 0 7.5-3.036 7.5-6.819 0-3.782-3.373-6.818-7.5-6.818S2.5 5.627 2.5 9.409Z",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.918 10.274V10.1c0-.565.349-.87.699-1.106.341-.23.684-.53.684-1.083a1.382 1.382 0 0 0-2.766-.001M9.916 12.222a.124.124 0 0 0-.124.125.125.125 0 1 0 .25 0 .125.125 0 0 0-.126-.125",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgRequestsActive;

import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React from "react";
import { useIntl } from "react-intl";
import { Redirect, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import AuthPageLayout from "~/components/AuthPageLayout";
import { ForgotPasswordSuccessForm as Form } from "~/components/form";
import { forgotPassword } from "~/services";
import { useGetOemById } from "~/services/auth";

const ForgotPasswordSuccess = () => {
  const { messages } = useIntl();
  const { modifier } = useParams();
  const { mutation } = forgotPassword();

  const { error } = useGetOemById(modifier);

  if (error) {
    if (typeof modifier !== "undefined") {
      return (
        <Redirect
          to={{
            pathname: "/error-page/404",
          }}
        />
      );
    }
  }

  var renderPage = () => {
    return (
      <AuthPageLayout>
        <div className="auth">
          <div className="auth-main auth-main--full">
            <div className="o-column">
              <div className="auth-box">
                <div className="form-authHead form-authHead--success form-authHead--center">
                  <Headline
                    size={HEADLINE_SIZES.MEDIUM}
                    className="mb-sm text-left"
                  >
                    {messages?.login?.forgotPassword.resetSuccess.title}
                  </Headline>
                  <BodyText
                    size={BODY_TEXT_SIZES.SMALL}
                    className="opacity-70 text-left"
                  >
                    {messages?.login?.forgotPassword.resetSuccess.message}
                  </BodyText>
                </div>

                <Form
                  callback={(values) => {
                    mutation(values)
                      .then(() => {
                        toast(
                          <Toast
                            message={
                              messages?.login?.forgotPassword.resetSuccess
                                ?.resend?.resendEmailSuccess
                            }
                          />,
                          {
                            closeButton: false,
                          },
                        );
                      })
                      .catch((e) => {
                        toast(
                          <Toast
                            message={
                              e?.message || `${messages?.login?.wrongCreds}`
                            }
                            type={TOAST_TYPES.ERROR}
                          />,
                          { closeButton: false },
                        );
                      });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </AuthPageLayout>
    );
  };

  return renderPage();
};

export default ForgotPasswordSuccess;

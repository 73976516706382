import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React from "react";
import { useIntl } from "react-intl";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import enumLoginColors from "$/settings/enums/colors/_login.json";
import AuthPageLayout from "~/components/AuthPageLayout";
import { LoginForm as Form } from "~/components/form";
import { useAuth } from "~/components/general";
import { LOGIN, LOGIN_FAILED } from "~/constants";
import { useGetOemById } from "~/services/auth";
import { registerMixpanelEvent } from "~/utils/_mixpanel";

const Login = () => {
  const { messages: msg } = useIntl();
  const { modifier } = useParams();
  const { search } = useLocation();
  const { oem, error } = useGetOemById(modifier);

  const auth = useAuth();
  const [redirect, setRedirect] = React.useState(false);

  if (redirect) {
    const redirectTo = new URLSearchParams(search).get("redirect");
    const pathname = redirectTo ? decodeURIComponent(redirectTo) : "/app";

    return (
      <Redirect
        to={{
          pathname,
          state: {
            welcome: true,
          },
        }}
      />
    );
  }

  if (error) {
    if (typeof modifier !== "undefined") {
      return (
        <Redirect
          to={{
            pathname: "/error-page/404",
          }}
        />
      );
    }
  }

  var renderPage = () => {
    return (
      <AuthPageLayout>
        <div className="o-column">
          <article className="auth-box">
            <Form
              modifier={modifier}
              color={oem?.textColor || enumLoginColors.text_color}
              callback={(values, setError) => {
                const { email: userEmail } = values;
                registerMixpanelEvent(LOGIN, {
                  oem: window.location.pathname
                    .split("/")
                    .slice(-1)
                    .join("")
                    .split("?")[0],
                  userEmail,
                });
                auth.login(
                  {
                    ...values,
                    organizationId: oem?._id,
                  },
                  (e) => {
                    if (e) {
                      toast(
                        <Toast
                          message={`${msg.login?.wrongCreds}`}
                          type={TOAST_TYPES.ERROR}
                        />,
                        { closeButton: false },
                      );
                      setError("root.random", {
                        type: "random",
                        message: e?.message,
                      });
                      registerMixpanelEvent(LOGIN_FAILED, {
                        oem: window.location.pathname
                          .split("/")
                          .slice(-1)
                          .join("")
                          .split("?")[0],
                        userEmail,
                      });
                    } else {
                      setRedirect(true);
                    }
                  },
                );
              }}
            />
          </article>
        </div>
      </AuthPageLayout>
      // <div className="lg:flex-row flex flex-col h-screen">
      //   <div className="lg:w-1/2 w-full bg-secondary flex flex-col items-center relative justify-center lg:h-screen h-1/4">
      //     <div className="flex flex-col lg:items-start items-center lg:absolute lg:right-0">
      //       <MakulaLogo className="w-fit h-3xl lg:ml-3xl mb-2xl" />
      //       <Headline
      //         size={HEADLINE_SIZES.SMALL}
      //         color="text-inverse"
      //         className="lg:mx-3xl"
      //       >
      //         {msg.login?.heroTitleV2}
      //       </Headline>
      //       <BodyText
      //         size={BODY_TEXT_SIZES.SMALL}
      //         color="text-tertiary"
      //         className="mt-2xs lg:mx-3xl"
      //       >
      //         {msg.login?.heroSubtitleV2}
      //       </BodyText>
      //       <img
      //         src="/images/auth/login.png"
      //         className="overflow-hidden lg:block hidden"
      //         style={{ width: "100%" }}
      //       />
      //     </div>
      //   </div>
      //   <div className="flex flex-col lg:items-center lg:justify-center mt-4xl lg:mt-0 lg:h-screen mx-auto w-full lg:w-1/2 h-3/4">
      //     <div className="o-column">
      //       <article className="auth-box">
      //         <Form
      //           modifier={modifier}
      //           color={oem?.textColor || enumLoginColors.text_color}
      //           callback={(values, setError) => {
      //             const { email: userEmail } = values;
      //             registerMixpanelEvent(LOGIN, {
      //               oem: window.location.pathname
      //                 .split("/")
      //                 .slice(-1)
      //                 .join("")
      //                 .split("?")[0],
      //               userEmail,
      //             });
      //             auth.login(
      //               {
      //                 ...values,
      //                 organizationId: oem?._id,
      //               },
      //               (e) => {
      //                 if (e) {
      //                   toast(
      //                     <Toast
      //                       message={`${msg.login?.wrongCreds}`}
      //                       type={TOAST_TYPES.ERROR}
      //                     />,
      //                     { closeButton: false },
      //                   );
      //                   setError("root.random", {
      //                     type: "random",
      //                     message: e?.message,
      //                   });
      //                   registerMixpanelEvent(LOGIN_FAILED, {
      //                     oem: window.location.pathname
      //                       .split("/")
      //                       .slice(-1)
      //                       .join("")
      //                       .split("?")[0],
      //                     userEmail,
      //                   });
      //                 } else {
      //                   setRedirect(true);
      //                 }
      //               },
      //             );
      //           }}
      //         />
      //       </article>
      //     </div>
      //   </div>
      // </div>
    );
  };

  return renderPage();
};

const ToastLogin = () => <Login />;

export default ToastLogin;

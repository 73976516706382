import { useMutation } from "@apollo/client";

import {
  ATTACH_OWN_OEM_PROCEDURE_TO_WORK_ORDER,
  DELETE_OWN_OEM_PROCEDURE_TEMPLATE,
  DETACH_OWN_OEM_PROCEDURE_TO_WORK_ORDER,
  DUPLICATE_OWN_OEM_PROCEDURE_TEMPLATE,
  FINALIZE_OWN_OEM_PROCEDURE_INSTANCE,
  GET_OWN_OEM_PROCEDURE_INSTANCE,
  GET_OWN_OEM_TICKET_BY_ID,
  LIST_OEM_PROCEDURE_TEMPLATES_QUERY,
  ProcedureTemplate,
  SAVE_OWN_OEM_PROCEDURE_INSTANCE,
  SAVE_OWN_OEM_PROCEDURE_TEMPLATE,
  ATTACH_PROCEDURE_TO_PM,
} from "../../api";
import client from "../../apollo/_client";

export const useCreateProcedure = () => {
  const [saveData, { loading, error }] = useMutation(
    SAVE_OWN_OEM_PROCEDURE_TEMPLATE,
  );
  return {
    saveProcedureTemplate: async (input) =>
      saveData({
        variables: { input },
        update(cache, { data: { saveOwnOemProcedureTemplate: ref } }) {
          cache.modify({
            fields: {
              listOwnOemProcedureTemplates(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment:
                    ProcedureTemplate.fragments.ProcedureTemplateFullData,
                  fragmentName: "ProcedureTemplateFullData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
    savingProcedureTemplate: loading,
    errorSavingProcedureTemplate: error,
  };
};

export const useDeleteProcedureTemplate = () => {
  const [deleteOwnOemProcedureTemplate, { loading, error }] = useMutation(
    DELETE_OWN_OEM_PROCEDURE_TEMPLATE,
  );
  return {
    loading,
    error,
    removeProcedureTemplate: async (id) =>
      new Promise((resolve, reject) =>
        deleteOwnOemProcedureTemplate({
          variables: { id },
          update(cache) {
            cache.modify({
              fields: {
                listOwnOemProcedureTemplates(existingRefs = [], { readField }) {
                  return [...existingRefs].filter((ref) => {
                    return readField("_id", ref) !== id;
                  });
                },
              },
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          }),
      ),
  };
};

export const useDuplicateProcedureTemplate = () => {
  const [duplicate, { loading, error }] = useMutation(
    DUPLICATE_OWN_OEM_PROCEDURE_TEMPLATE,
  );
  return {
    loading,
    error,
    duplicateProcedureTemplate: async (id) =>
      new Promise((resolve, reject) =>
        duplicate({
          variables: { id },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          }),
      ),
  };
};

export const useSaveProcedure = () => {
  const [saveData, { loading, error }] = useMutation(
    SAVE_OWN_OEM_PROCEDURE_TEMPLATE,
  );
  return {
    saveProcedureTemplate: async (input) =>
      saveData({
        variables: { input },
        update(cache, { data: { saveOwnOemProcedureTemplate: ref } }) {
          cache.modify({
            id: cache.identify({
              __typename: "ProcedureTemplate",
              id: input._id,
            }),
            fields: {
              listOwnOemProcedureTemplates(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment:
                    ProcedureTemplate.fragments.ProcedureTemplateFullData,
                  fragmentName: "ProcedureTemplateFullData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
    savingProcedureTemplate: loading,
    errorSavingProcedureTemplate: error,
  };
};

export const useAttachProcedure = () => {
  const [attach, { loading, error }] = useMutation(
    ATTACH_OWN_OEM_PROCEDURE_TO_WORK_ORDER,
  );
  return {
    attachProcedure: async (workOrderId, templateId) =>
      attach({
        variables: {
          input: { workOrderId, templateId },
        },
        skip: !workOrderId || !templateId,
      }),
    attachingProcedure: loading,
    errorAttachProcedure: error,
  };
};

export const useAttachProcedureToPM = () => {
  const [attach, { loading, error }] = useMutation(ATTACH_PROCEDURE_TO_PM);

  return {
    attachProcedureToPM: async (preventiveMaintenanceId, templateId) => {
      if (!preventiveMaintenanceId || !templateId) {
        throw new Error("preventiveMaintenanceId and templateId are required");
      }

      return attach({
        variables: {
          input: { preventiveMaintenanceId, templateId },
        },
      });
    },
    attachingProcedure: loading,
    errorAttachProcedure: error,
  };
};

export const useDetachProcedure = () => {
  const [detach, { loading, error }] = useMutation(
    DETACH_OWN_OEM_PROCEDURE_TO_WORK_ORDER,
  );

  return {
    detachingProcedure: loading,
    detachProcedureError: error,
    detachProcedure: async (workOrderId, procedureId) =>
      detach({
        variables: {
          input: { workOrderId, procedureId },
        },
        update(cache) {
          try {
            const { getOwnOemTicketById } = cache.readQuery({
              query: GET_OWN_OEM_TICKET_BY_ID,
              variables: {
                id: workOrderId,
              },
            });

            if (!getOwnOemTicketById) return;

            const cloned = { ...getOwnOemTicketById };
            cloned.procedures = cloned.procedures.filter(
              (item) => item.procedure._id !== procedureId,
            );

            cache.writeQuery({
              query: GET_OWN_OEM_TICKET_BY_ID,
              variables: {
                id: workOrderId,
              },
              data: {
                getOwnOemTicketById: cloned,
              },
            });

            cache.gc();
          } catch (error) {
            //
          }
        },
      }),
  };
};

export const useSaveProcedureInstance = () => {
  const [save, { loading, error }] = useMutation(
    SAVE_OWN_OEM_PROCEDURE_INSTANCE,
  );

  return {
    loading,
    error,
    saveInstance: async (input) =>
      save({
        variables: { input },
        skip: !input?._id,
        update(cache, { data: { saveOwnOemProcedure: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Procedure", id: ref._id }),
            fields: {
              getOwnOemProcedureById() {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment:
                    ProcedureInstance.fragments.ProcedureInstanceFullData,
                  fragmentName: "ProcedureInstanceFullData",
                });

                return newRef;
              },
            },
          });
        },
      }),
  };
};

export const useFinalizeProcedureInstance = () => {
  const [save, { loading, error }] = useMutation(
    FINALIZE_OWN_OEM_PROCEDURE_INSTANCE,
  );

  return {
    loading,
    error,
    finalize: async (input) =>
      save({
        variables: { input },
        update(cache, { data: { finalizeOwnOemProcedure: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Procedure", id: input._id }),
            fields: {
              getOwnOemProcedureById() {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment:
                    ProcedureInstance.fragments.ProcedureInstanceFullData,
                  fragmentName: "ProcedureInstanceFullData",
                });

                return newRef;
              },
            },
          });
        },
      }),
  };
};

export const updateProcedureInstanceCacheOnFinalized = async (payload) => {
  client.refetchQueries({
    include: [GET_OWN_OEM_PROCEDURE_INSTANCE],
    updateCache(cache) {
      cache.evict({ fieldName: "listOwnOemMachineAgreggatedHistoryById" });
    },
  });
};

export const updateProcedureTemplateCacheOnDuplicate = async (payload) => {
  const procedureTemplate = client.readQuery({
    query: LIST_OEM_PROCEDURE_TEMPLATES_QUERY,
    variables: {
      params: {},
    },
  });
  if (!procedureTemplate) return;

  const newItem = {
    __typename: "ProcedureTemplate",
    _id: payload?._id,
    name: payload?.name,
    createdAt: payload?.createdAt,
    updatedAt: payload?.updatedAt,
    createdBy: {
      __typename: "User",
      _id: payload?.createdBy?._id,
      name: payload?.createdBy?.name,
    },
  };

  const { listOwnOemProcedureTemplates } = procedureTemplate;

  const newData = [...listOwnOemProcedureTemplates, newItem];

  client.writeQuery({
    query: LIST_OEM_PROCEDURE_TEMPLATES_QUERY,
    variables: {
      params: {},
    },
    data: {
      listOwnOemProcedureTemplates: newData,
    },
  });
};

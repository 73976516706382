import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { getBoxPreviewInstance } from "~/components/aiAssistant/BoxPreview";

const FILE_PREVIEW_CONTAINER = "preview-box-file";

const Preview = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const page = queryParams.get("page");
  const folderAccessToken = queryParams.get("folderAccessToken");
  const fileId = queryParams.get("fileId");

  const [boxScriptLoaded, setBoxScriptLoaded] = useState(false);

  const boxPreviewRef = useRef(getBoxPreviewInstance());

  useEffect(() => {
    const boxScriptLoadedInterval = setInterval(() => {
      if (boxPreviewRef.current) {
        setBoxScriptLoaded(true);
        clearInterval(boxScriptLoadedInterval);
      } else boxPreviewRef.current = getBoxPreviewInstance();
    }, 500);
    return () => {
      clearInterval(boxScriptLoadedInterval);
    };
  }, []);

  useEffect(() => {
    if (fileId === null || !boxScriptLoaded) return;

    const BoxPreview = boxPreviewRef.current;

    if (fileId) {
      if (page)
        BoxPreview.addListener("load", () => {
          // Locate the specific page based on data-page-number
          const pageContainer = document
            .querySelector(`.${FILE_PREVIEW_CONTAINER}`)
            .querySelector(`[data-page-number="${page}"]`);
          if (!pageContainer) return;

          pageContainer.scrollIntoView({ behavior: "smooth" });
        });
      BoxPreview.show(fileId, folderAccessToken, {
        container: `.${FILE_PREVIEW_CONTAINER}`,
        header: "none",
        showDownload: true,
      });
    } else {
      BoxPreview.removeAllListeners();
      BoxPreview.hide();
    }
  }, [page, fileId, folderAccessToken, boxScriptLoaded]);

  return (
    <div>
      <div
        className={`mobile-box-container container h-screen ${FILE_PREVIEW_CONTAINER} [&_.bp-container]:!w-screen`}
      />
    </div>
  );
};

export default Preview;

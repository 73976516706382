// libs
import { Input } from "@shared/ui/Inputs";
import { lowerCase, upperFirst } from "lodash";
import { Trash } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";

const DropdownHeader = ({
  title,
  searchTerm = "",
  onSearchTermChange = () => {},
  unselectFilter,
  searchable = true,
  showPropsTitleOnly = false,
}) => {
  const { messages: msg } = useIntl();

  return (
    <div className="filter-dropdown-header w-full">
      <div className="head">
        <span className="title">
          {upperFirst(lowerCase(title))}
          {showPropsTitleOnly ? "" : ` ${msg.common?.filters.is}`}:
        </span>
        <span className="delete-icon" onClick={unselectFilter}>
          <Trash size={20} className="u-text-color-gray-v2-60" />
        </span>
      </div>
      {searchable && (
        <Input
          type="text"
          placeholder={upperFirst(
            lowerCase(msg.common?.filters.searchForOption),
          )}
          value={searchTerm}
          onChange={(e) => onSearchTermChange(e.target.value)}
          name="ticket-status-filter"
          id="ticket-status-filter"
        />
      )}
    </div>
  );
};

export default DropdownHeader;

import React from "react";
import { useIntl } from "react-intl";
import { useRecoilValue } from "recoil";

import { unreadChannelMessagesAtom } from "~/atoms/_chat.jsx";
import { TicketItemOpen } from "~/components/_listItem";
import { NoTicketsLarge } from "~/components/general/_no-tickets";

const ListView = ({
  tickets = [],
  intl,
  onScrollToBottom,
  totalCount,
  loading,
}) => {
  const { messages } = useIntl();
  const unreadChannelMessages = useRecoilValue(unreadChannelMessagesAtom);

  return (
    <>
      {tickets?.length > 0 ? (
        <div className={"other-tickets-group-wrapper"}>
          <TicketItemOpen
            intl={intl}
            channels={unreadChannelMessages?.channels}
            data={tickets}
            onScrollToBottom={onScrollToBottom}
            totalCount={totalCount}
            loading={loading}
          />
        </div>
      ) : (
        <NoTicketsLarge
          intl={intl}
          title={messages?.tickets?.openTicketsPage.noTickets}
        />
      )}
    </>
  );
};

export default ListView;

import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import PAID_FEATURES from "$/settings/paid-features.json";
import { JWT } from "~/apollo/_config";
import { globalOpenTicketsAtom } from "~/atoms/_global";
import EmptySpace from "~/components/_emptySpace";
import { useAuth } from "~/components/general";
import CreateTicketDrawer from "~/components/tickets/Create";
import { COLOR, INITITATE_3D_SPARE_PART_REQUEST } from "~/constants";
import {
  ADD_ANNOTATION_CONTEXT_OPTION_ID,
  ENTRIES_TO_HIDE,
  ENTRIES_TO_SHOW_SHARED_ASSETS,
  NODE_CONTEXT_OPTION_ID,
  TOOLBAR_OPTIONS_TO_REMOVE,
  TOOLBAR_OPTIONS_TO_REMOVE_GUIDES,
  TOOLBAR_OPTIONS_TO_REMOVE_GUIDES_SHARED_ASSETS,
  TOOLBAR_SPACERS_TO_RETAIN,
  TOOLBAR_SPACER_TAG_NAME,
} from "~/constants/guides";
import Create from "~/containers/Requests/Create";
import { HourglassIcon } from "~/icons/icons";
import { useGetMachine3DAuthToken } from "~/services/asset";
import type { WebVisRefType } from "~/types/3d";
import type { Asset } from "~/types/asset";
import type { UserType } from "~/types/user";
import { getEnums } from "~/utils";
import { registerMixpanelEvent } from "~/utils/_mixpanel";
import {
  useGetAppConfigRecoilValue,
  useIsGrowthLoopEnabled,
} from "~/utils/appFeatures";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

const Webvis = ({
  url,
  is3DModelPaid = true,
  is3DPageView = false,
  isDemoModel = false,
  isGuide = false,
  machine,
  webvisComponent = null,
  guideSessionId = null,
  onFirstSnapshot = () => {},
  onChange = () => {},
}: {
  url: string;
  is3DModelPaid?: boolean;
  is3DPageView: boolean;
  isDemoModel?: boolean;
  isGuide: boolean;
  machine: Asset;
  webvisComponent: React.RefObject<WebVisRefType>;
  guideSessionId?: string;
  onFirstSnapshot?: () => void;
  onChange?: () => void;
}) => {
  const initilize = useRef(false);
  const [removeUnnecessaryUI, setRemoveUnnecessaryUI] = useState(false);
  const { messages } = useIntl();
  const { token } = useGetMachine3DAuthToken(machine?._id);
  const [ticketCoil, setTicketCoil] = useRecoilState(globalOpenTicketsAtom);
  const [showLoadingAlert, setShowLoadingAlert] = useState(true);
  const { user } = useAuth() as { user: UserType };
  const appConfig = useGetAppConfigRecoilValue();
  const [showRequestMenu, setShowRequestMenu] = useState(false);

  const location = useLocation();

  const isSharedAssetPage = location.pathname.includes("shared-assets");
  const isWorkManagementPaid = isPaidFeatureAvailable(
    paidFeatures.workManagement,
    // @ts-ignore
    machine?.oem?.paidFeatures ? { oem: machine?.oem } : user,
    appConfig,
    !isSharedAssetPage,
  );

  const onRequestPart = (label) => {
    registerMixpanelEvent(INITITATE_3D_SPARE_PART_REQUEST);
    const customer = machine?.customer;
    const assetType = user?.oem?.assetTypes?.find(
      // @ts-ignore
      (type) => type._id === machine.assetType,
    );

    setTicketCoil({
      ...ticketCoil,
      isDrawerOpen: true,
      isPartsRequest: true,
      isDemoModel: isDemoModel,
      newTicket: {
        ...ticketCoil.newTicket,
        assetType:
          machine && !isDemoModel
            ? {
                ...assetType,
                label: assetType?.name,
                value: assetType?._id,
              }
            : null,
        selectedMachine:
          machine && !isDemoModel
            ? [
                {
                  ...machine,
                  label: machine?.name + " • " + machine?.serialNumber,
                  value: machine?._id,
                },
              ]
            : [],
        selectedFacility: customer
          ? [
              {
                ...customer,
                label: customer.name,
                value: customer._id,
              },
            ]
          : [],
        ticketDescription: label,
      },
    });
  };

  useEffect(() => {
    const saveButton = document
      ?.querySelector("webvis-full")
      ?.shadowRoot?.querySelector("#bottomdiv")
      ?.shadowRoot?.querySelector("webvis-snapshots")
      ?.shadowRoot?.querySelector("webvis-tools-icon-button");

    const toolbarContainer = document
      ?.querySelector("webvis-full")
      ?.shadowRoot?.querySelector("webvis-toolbar")
      ?.shadowRoot?.querySelector("webvis-toolbar-trigger:nth-child(1)")
      ?.parentElement;

    const printElement = document
      ?.querySelector("webvis-full")
      ?.shadowRoot?.querySelector("webvis-toolbar")
      ?.shadowRoot?.querySelector("webvis-toolbar-trigger:nth-child(3)");

    let spacersRetained = 0;

    for (let index = 0; index < toolbarContainer?.children?.length; index++) {
      const element = toolbarContainer.children[index];
      const elementTitle = element.getAttribute("title");
      if (element.tagName.toLowerCase() === TOOLBAR_SPACER_TAG_NAME) {
        if (spacersRetained >= TOOLBAR_SPACERS_TO_RETAIN)
          // @ts-ignore
          element.style.display = "none";
        else spacersRetained += 1;
      }
      if (isGuide) {
        if (TOOLBAR_OPTIONS_TO_REMOVE_GUIDES.includes(elementTitle)) {
          // @ts-ignore
          element.style.display = "none";
        }
      } else if (isSharedAssetPage) {
        if (
          TOOLBAR_OPTIONS_TO_REMOVE_GUIDES_SHARED_ASSETS.includes(elementTitle)
        )
          // @ts-ignore
          element.style.display = "none";
      } else {
        if (TOOLBAR_OPTIONS_TO_REMOVE.includes(elementTitle))
          // @ts-ignore
          element.style.display = "none";
      }
    }

    if (saveButton) {
      // @ts-ignore
      saveButton.style.display = "none";
    }
    if (printElement) {
      // @ts-ignore
      printElement.style.display = "none";
    }
  }, [removeUnnecessaryUI]);

  async function init() {
    if (!webvisComponent.current) return;

    const myContext = await webvisComponent.current.requestContext();

    webvisComponent.current.hideAddButton();
    if (!isGuide) {
      webvisComponent.current.hideBottomBar();
    }
    setRemoveUnnecessaryUI(true);

    if (myContext !== null) {
      await myContext?.clear();
      myContext?.changeSetting(
        webvis.SettingStrings.ADDITIONAL_REQUEST_HEADERS,
        {
          [JWT.HEADER.TOKEN.NAME]: token || null,
        },
      );
      myContext?.changeSetting("shareSessionEnabled", false);
      await myContext.add({
        autoExpand: true,
        label: machine?.name || "",
        dataURI: url,
        initialProperties: {
          enabled: true,
        },
      });

      const children = await myContext.getProperty(0, webvis.Property.CHILDREN);

      await myContext.registerListener(
        [webvis.EventType.NODE_CHANGED],
        function (event) {
          if (event?.newValue >= 3000) {
            setShowLoadingAlert(false);
          }
        },
        children?.[0] ?? 0,
      );

      await myContext.registerListener(
        [webvis.EventType.SNAPSHOT_CREATED],
        // @ts-ignore
        async (e: { properties: { order: number; name: string } }) => {
          if (e?.properties?.order === 0) {
            onFirstSnapshot?.();
          }
        },
      );

      webvisUI.setSetting(
        webvisUI.UISetting.CONTEXT_MENU_FUNCTION,
        (entries) => {
          const newEntries = [];
          if (isWorkManagementPaid && !isGuide)
            newEntries.push({
              label: isSharedAssetPage
                ? messages?.requests?.["createRequest"]
                : messages?.forms?.["SparePartOrder"],
              command: async (nodeId) => {
                let label = "";
                try {
                  label = (await myContext.getProperty(
                    nodeId,
                    webvis.Property.LABEL,
                  )) as string;
                } catch (error) {
                  //
                } finally {
                  if (isSharedAssetPage) setShowRequestMenu(true);
                  else onRequestPart(label || "");
                }
              },
            });
          return [
            ...entries
              .filter((entry) =>
                isSharedAssetPage
                  ? ENTRIES_TO_SHOW_SHARED_ASSETS.includes(entry?.label)
                  : !ENTRIES_TO_HIDE.includes(entry?.label?.toLowerCase()) ||
                    isGuide,
              )
              .map((entry) =>
                entry?.id?.toLowerCase()?.includes(NODE_CONTEXT_OPTION_ID)
                  ? {
                      ...entry,
                      subEntries: entry.subEntries.filter(
                        (subEntry) =>
                          !subEntry?.id
                            ?.toLowerCase()
                            ?.includes(ADD_ANNOTATION_CONTEXT_OPTION_ID) ||
                          isGuide,
                      ),
                    }
                  : entry,
              ),
            ...newEntries,
          ];
        },
      );
    }
    webvisUI.setSetting(webvisUI.UISetting.SHARE_SESSION_ENABLED, false);
    initilize.current = true;
    if (guideSessionId) await myContext.restoreSession(guideSessionId);
    await myContext.registerListener(
      [
        webvis.EventType.ANNOTATION_CREATED,
        webvis.EventType.ANNOTATION_CHANGED,
        webvis.EventType.ANNOTATION_REMOVED,
        webvis.EventType.SNAPSHOT_CREATED,
        webvis.EventType.SNAPSHOT_CREATION_STARTED,
        webvis.EventType.SNAPSHOT_RESTORED,
        webvis.EventType.SNAPSHOT_CHANGED,
        webvis.EventType.SNAPSHOT_REMOVED,
        webvis.EventType.SNAPSHOT_RESTORE_STARTED,
        webvis.EventType.CLIPPLANE_CREATED,
        webvis.EventType.CLIPPLANE_CHANGED,
        webvis.EventType.CLIPPLANE_REMOVED,
        webvis.EventType.CLIPPING_ROOM_CREATED,
        webvis.EventType.CLIPPING_ROOM_CHANGED,
        webvis.EventType.CLIPPING_ROOM_REMOVED,
        webvis.EventType.MEASUREMENT_CREATED,
        webvis.EventType.MEASUREMENT_CHANGED,
        webvis.EventType.MEASUREMENT_REMOVED,
        webvis.EventType.DRAWING_CREATED,
        webvis.EventType.DRAWING_CHANGED,
        webvis.EventType.DRAWING_REMOVED,
        webvis.EventType.DRAWING_PLANE_CREATED,
        webvis.EventType.DRAWING_PLANE_REMOVED,
      ],
      () => {
        onChange?.();
      },
    );
  }
  useEffect(() => {
    if (!token && is3DModelPaid && !isDemoModel) return;
    const script = document.createElement("script");
    script.src = (
      import.meta as ImportMeta & { env: { VITE_APP_3D_SERVER_URL: string } }
    ).env.VITE_APP_3D_SERVER_URL;

    // Append the script element to the document body
    document.body.appendChild(script);
    script.onload = () => {
      init();
    };

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!token && is3DModelPaid && !isDemoModel) return null;

  return (
    <>
      <CreateTicketDrawer showButton={false} />
      <div
        className="vis-wrapper"
        style={{
          height: is3DPageView ? "100%" : "calc(100vh - 210px)",
        }}
      >
        <div className="vis-view">
          {/* @ts-ignore */}
          <webvis-full
            ref={webvisComponent}
            context="default_context"
            viewer="default_viewer"
            style={{
              "--webvis-toolbar-background": "#9c9c9c",
              "--webvis-toolbar-color": "#5e5e5e",
            }}
          />
        </div>
        {showRequestMenu && (
          <Create
            onClose={() => setShowRequestMenu(false)}
            selectedAsset={machine}
          />
        )}
        {showLoadingAlert && (
          <div className={"alert-modal-overlay active u-absolute"}>
            <div className={"alert-box-wrapper small-alert"}>
              <div className="u-flex u-width-100 u-flex-column u-items-center">
                <HourglassIcon
                  width={"18"}
                  height={"20"}
                  fillColor={COLOR.$blue_v2_100}
                />
                <EmptySpace height="16px" />
                <Headline size={HEADLINE_SIZES.X_SMALL}>
                  {messages.machines?.["_3DModelLoadingAlert"]?.title}
                </Headline>
                <EmptySpace height="4px" />
                <Label
                  size={LABEL_SIZES.X_SMALL}
                  color="text-secondary"
                  className="text-center font-medium"
                >
                  {messages.machines?.["_3DModelLoadingAlert"]?.message}
                </Label>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Webvis;

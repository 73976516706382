import * as React from "react";
const SvgAiKnowledgebase = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M16.666 9.168H3.335a1.667 1.667 0 0 0-1.627 2.028l.926 4.167c.169.762.845 1.305 1.627 1.305h11.371c.766 0 1.433-.522 1.618-1.265l1.034-4.167a1.667 1.667 0 0 0-1.617-2.068Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M17.5 9.393V7.451c0-.92-.746-1.667-1.667-1.667h-5.392a.833.833 0 0 1-.689-.365L8.582 3.7a.833.833 0 0 0-.69-.365H4.167c-.92 0-1.667.746-1.667 1.667v4.392",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgAiKnowledgebase;

import { arrayOf, elementType, func, shape, string } from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import useOnClickOutside from "use-onclickoutside";

export const PopupMenuV2 = ({
  editLabel,
  deleteLabel,
  onEditClick,
  onDeleteClick,
  close,
  isDeletedDisabled = false,
  isEditDisabled = false,
  deleteLeadingIcon = null,
  editLeadingIcon = null,
  showEditButton = true,
  showDeleteButton = true,
  labelClassName = "",
}) => {
  const { messages } = useIntl();
  const ref = React.useRef(null);

  useOnClickOutside(ref, close);

  return (
    <div className="popup-menu-v2" ref={ref}>
      <div className="popup-menu-v2-item-list">
        {showEditButton && (
          <span
            className={`popup-menu-v2-item-label edit ${labelClassName} ${
              isEditDisabled
                ? "pointer-events-none opacity-30"
                : "cursor-pointer"
            }`}
            onClick={onEditClick}
          >
            {editLeadingIcon}
            {editLabel || messages?.common?.edit}
          </span>
        )}
        {showDeleteButton && (
          <span
            className={`popup-menu-v2-item-label delete ${labelClassName} ${
              isDeletedDisabled
                ? "pointer-events-none opacity-30"
                : "cursor-pointer"
            }`}
            onClick={onDeleteClick}
          >
            {deleteLeadingIcon}
            {deleteLabel || messages?.common?.delete}
          </span>
        )}
      </div>
    </div>
  );
};

export const ActionPopup = ({ actions, close, position = "bottom" }) => {
  const ref = React.useRef(null);

  useOnClickOutside(ref, close);

  return (
    <div className={`popup-menu-v2 ${position}`} ref={ref}>
      <div className="popup-menu-v2-item-list">
        {actions?.map((action, index) => {
          return (
            <span
              key={`action-${index}`}
              className={`popup-menu-v2-item-label ${action.className}`}
              onClick={action.onClick}
            >
              {action.label}
            </span>
          );
        })}
      </div>
    </div>
  );
};

const ActionsType = shape({
  label: elementType,
  onClick: func,
  className: string,
});

ActionPopup.propTypes = {
  actions: arrayOf(ActionsType).isRequired,
  close: func.isRequired,
};

ActionPopup.defaultProps = {
  actions: [],
  close: () => {},
};

import { useQuery } from "@apollo/client";

import { LIST_OWN_OEM_RESOURCES_QUERY } from "#/src/api";
import { ITEMS_PER_PAGE } from "#/src/constants";

export const useListOwnOemResources = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery,
  skipCondition = false,
  where,
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      where: {
        searchQuery,
        ...(where || {}),
      },
    },
  };

  const {
    data: { listOwnOemResources = [] } = {},
    loading: resourceListLoading,
    refetch: resourcesRefetch,
    fetchMore,
  } = useQuery(LIST_OWN_OEM_RESOURCES_QUERY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    resources = [],
  } = listOwnOemResources;

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          where: {
            searchQuery,
            ...(where || {}),
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedResources = [
          ...previousResult.listOwnOemResources.resources,
          ...fetchMoreResult.listOwnOemResources.resources,
        ];

        const updates = {
          ...previousResult,
          listOwnOemResources: {
            ...previousResult.listOwnOemResources,
            ...fetchMoreResult.listOwnOemResources,
            resources: mergedResources,
          },
        };

        return updates;
      },
    });
  };

  return {
    resources,
    handleFetchMore,
    resourcesRefetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    resourceListLoading,
  };
};

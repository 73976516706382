import { atom } from "recoil";

export const SIDEBAR_STATE_STORAGE_KEY = "SIDEBAR_EXPANDED";

export const setSidebarStateInStorage = (sidebarState: boolean) =>
  localStorage.setItem(SIDEBAR_STATE_STORAGE_KEY, `${sidebarState}`);

export const sidebarAtom = atom({
  key: "sidebarExpanded",
  default:
    localStorage.getItem(SIDEBAR_STATE_STORAGE_KEY) === "false" ? false : true,
});

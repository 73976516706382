import { useMutation } from "@apollo/client";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { Input } from "@shared/ui/Inputs";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { registerMixpanelEvent } from "#/src/utils/_mixpanel";
import { VERIFY_SIGNUP_TOKEN_MUTATION } from "~/api";
import { APP } from "~/apollo/_config";
import AuthPageLayout from "~/components/AuthPageLayout";
import MessageSpinner from "~/components/chat/_messageSpinner";
import { Loading, useAuth } from "~/components/general";
import { SIGN_UP, SIGN_UP_FAILED } from "~/constants";
import { useCustomerPortalSignup } from "~/services";
import { UserType } from "~/types/user";

const { ROUTES: { OEM_LOGIN: LOGIN } = {} } = APP;

const Signup = () => {
  const [verifySignupToken] = useMutation(VERIFY_SIGNUP_TOKEN_MUTATION);
  const location = useLocation();
  const history = useHistory();
  const token =
    new URLSearchParams(location.search).get("token") ||
    sessionStorage.getItem("signupToken");
  const [credentialsSubmitted, setCredentialsSubmitted] = useState(false);
  const { customerPortalSignUp, signingUp } = useCustomerPortalSignup();
  const [tokenVerified, setTokenVerified] = useState(false);
  const [signupData, setSignupData] = useState({
    companyName: "",
    fullName: "",
    email: "",
    contactId: "",
  });
  const { user, loading: userLoading } = useAuth() as {
    user: UserType;
    loading: boolean;
  };
  const { messages } = useIntl();

  if (!token) history.push("/oem");

  const onCredentialsSubmit = () => {
    customerPortalSignUp({
      name: signupData.fullName,
      contactId: signupData.contactId,
      companyName: signupData.companyName,
    })
      .then(() => {
        registerMixpanelEvent(SIGN_UP);
        setCredentialsSubmitted(true);
      })
      .catch(() => {
        registerMixpanelEvent(SIGN_UP_FAILED);
        // TODO: SHOW TOAST
      });
  };

  React.useEffect(() => {
    if (!token || user === undefined) return;
    verifySignupToken({
      variables: { token },
    })
      .then((res) => {
        const response = res.data.verifySignUpToken;
        if (response.userExists) {
          let event = SIGN_UP;
          if (response.emailMismatch && user) {
            toast(
              <Toast
                type={TOAST_TYPES.ERROR}
                message={messages.common?.["signup"].emailMismatch}
              />,
              {
                closeButton: false,
              },
            );
            event = SIGN_UP_FAILED;
          } else if (response.userAdded) {
            toast(
              <Toast
                type={TOAST_TYPES.SUCCESS}
                message={messages.common?.["signup"].acceptedInvite}
              />,
              {
                closeButton: false,
              },
            );
          } else if (response.isInvitationExpired) {
            toast(
              <Toast
                type={TOAST_TYPES.ERROR}
                message={messages.common?.["signup"].expiredInvitation}
              />,
              {
                closeButton: false,
              },
            );
            event = SIGN_UP_FAILED;
          }

          sessionStorage.setItem("signupToken", token);

          history.push(`${LOGIN}?redirect=${encodeURIComponent(`/signup`)}`);
          registerMixpanelEvent(event);
          return;
        }
        if (!response.email || response.isInvitationExpired) {
          sessionStorage.removeItem("signupToken");
          history.push("/expired");
          registerMixpanelEvent(SIGN_UP_FAILED);
          return;
        }
        setSignupData((prev) => ({
          ...prev,
          email: response.email,
          contactId: response.id,
        }));
        sessionStorage.removeItem("signupToken");
        setTokenVerified(true);
        registerMixpanelEvent(SIGN_UP);
      })
      .catch(() => {
        sessionStorage.removeItem("signupToken");
        history.push("/expired");
      });
  }, [token, userLoading]);

  if (!tokenVerified) return <Loading />;

  return (
    <AuthPageLayout>
      <div className="flex flex-col m-auto space-y-3xl">
        {!credentialsSubmitted ? (
          <div style={{ maxWidth: "363px" }} className="space-y-3xl">
            <div>
              <Headline size={HEADLINE_SIZES.MEDIUM}>
                {messages.common?.["signup"].header}
              </Headline>
              <BodyText
                size={BODY_TEXT_SIZES.SMALL}
                className="mt-sm"
                color="text-secondary"
              >
                {messages.common?.["signup"].subHeader}
              </BodyText>
            </div>
            <div className="space-y-lg">
              <Input
                keyId="company-name"
                label="Company Name"
                onChange={({ target: { value } }) =>
                  setSignupData((prev) => ({
                    ...prev,
                    companyName: value,
                  }))
                }
                placeholder="Enter your company name"
                value={signupData.companyName}
              />
              <Input
                keyId="full-name"
                label="Full Name"
                onChange={({ target: { value } }) =>
                  setSignupData((prev) => ({
                    ...prev,
                    fullName: value,
                  }))
                }
                placeholder="Enter your full name"
                value={signupData.fullName}
              />
              <Input
                keyId="email-address"
                label="Email Address"
                type="email"
                disabled
                placeholder="Enter your company name"
                value={signupData.email}
              />
              {signingUp ? (
                <MessageSpinner />
              ) : (
                <Button
                  text="Create Account"
                  onClick={() => onCredentialsSubmit()}
                  disabled={
                    !signupData.companyName?.trim() ||
                    !signupData.fullName?.trim()
                  }
                  className="p-2xs w-full"
                />
              )}
            </div>
          </div>
        ) : (
          <div style={{ maxWidth: "363px" }} className="space-y-3xl">
            <div>
              <Headline size={HEADLINE_SIZES.MEDIUM}>
                {messages.common?.["signup"].almostThere}
              </Headline>
              <BodyText
                size={BODY_TEXT_SIZES.SMALL}
                color="text-secondary"
                className="mt-sm"
              >
                {messages.common?.["signup"].emailSent}
              </BodyText>
            </div>
            <div className="space-y-2xl">
              <Input
                keyId="email-address"
                label="Email Address"
                type="email"
                disabled
                placeholder="Enter your company name"
                value={signupData.email}
              />
              <div className="space-y-lg">
                <Button
                  text="Sign in"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  onClick={() => history.push("/app")}
                  className="p-2xs w-full"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </AuthPageLayout>
  );
};

export default Signup;

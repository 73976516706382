import * as React from "react";
const SvgCustomField = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 16 16",
  ...props
}, /* @__PURE__ */ React.createElement("rect", {
  width: 12.133,
  height: 6.8,
  x: 1.933,
  y: 4.6,
  stroke: "currentColor",
  strokeWidth: 1.2,
  rx: 2.4
}), /* @__PURE__ */ React.createElement("rect", {
  width: 4.667,
  height: 0.96,
  x: 4,
  y: 7.333,
  fill: "currentColor",
  rx: 0.48
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeWidth: 1.2,
  d: "m10 7.333.872 1.047c.067.08.19.08.256 0L12 7.333"
}));
export default SvgCustomField;

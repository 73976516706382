export const WORK_ORDER_TYPE_ICONS = {
  SERVICE_REQUEST: "service-request",
  SPARE_PART_ORDER: "spare-part-order",
  PREVENTIVE_MAINTENANCE: "preventive-maintenance",
  OPEN_FOLDER: "open-folder",
  BROKEN_LINK: "broken-link",
  ENRERGY: "energy",
  INSPECTION: "inspection",
  PREVENTIVE: "preventive",
  SPANNERS: "spanners",
  ICE_CRYSTAL: "ice-crystal",
  LIGHT_BULB: "light-bulb",
  SHIELD: "shield",
  PROCEDURE: "procedure",
  FORM_SUBMISSION: "form-submission",
};

export const WORK_ORDER_RESTRICTED_TYPE_ICONS = {
  SERVICE_REQUEST: "service-request",
  SPARE_PART_ORDER: "spare-part-order",
  FORM_SUBMISSION: "form-submission",
  PREVENTIVE_MAINTENANCE: "preventive-maintenance",
};

export const WORK_ORDER_TYPE_VARIANT = {
  DEFAULT: "default",
  CUSTOM: "custom",
};

export const WORK_ORDER_TYPE_NAME_MAX_LENGTH = 30;

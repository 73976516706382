import { type } from "os";

import LeaveByDoorIcon from "@shared/svg/leave-by-door.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Drawer from "@shared/ui/Drawer";
import { IconDropdown, SingleSelectDropdown } from "@shared/ui/Dropdowns";
import { ExternalIcon, EXTERNAL_ICON_SIZES } from "@shared/ui/Indicators";
import { Input } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import AppModal from "@shared/ui/Modal";
import TextEditor, { serialize } from "@shared/ui/TextEditor";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { Plus } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import WorkOrderTypeIcons from "#/src/components/tickets/WorkOrderTypeIcons";
import { generateStatusBackgroundColor } from "#/src/utils/_colors";
import { PartDetailModalHeader } from "~/components/_partDetailModalComponents";
import { useAuth } from "~/components/general";
import {
  AddPartComp,
  CreateTicketAddedPartsComp,
} from "~/components/tickets/_spareParts";
import CustomFormElements from "~/components/tickets/CustomFormElements";
import { ITEMS_PER_PAGE, SHARED_ASSETS_CREATE_REQUEST } from "~/constants";
import {
  useGetAssetsPartsById,
  useListRequestableAssets,
} from "~/services/asset";
import { useCreateRequest } from "~/services/request";
import type { Asset } from "~/types/asset";
import type { UserType } from "~/types/user";
import { capitalizeFirstLetter, getMachineThumbnail } from "~/utils";
import { registerMixpanelEvent } from "~/utils/_mixpanel";

const Create = ({
  onClose,
  selectedAsset,
  selectedOrg,
}: {
  onClose: () => void;
  selectedAsset?: Asset;
  selectedOrg?: string;
}) => {
  const { messages } = useIntl();

  const [isQuitAlertOpen, setIsQuitAlertOpen] = React.useState(false);
  const [assetData, setAssetData] = React.useState<Asset>(
    selectedAsset
      ? {
          // @ts-ignore
          description: `${selectedAsset?.serialNumber} • ${selectedAsset?.oem?.name}`,
          label: selectedAsset?.name,
          oem: selectedAsset?.oem,
          _id: selectedAsset?._id,
          value: selectedAsset?._id,
          thumbnail: getMachineThumbnail(selectedAsset),
        }
      : null,
  );

  const [selectedRequestType, setSelectedRequestType] =
    React.useState<string>("");
  const [requestTitle, setRequestTitle] = React.useState<string>("");
  const [selectedWorkOrderType, setSelectedWorkType] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [addedParts, setAddedParts] = React.useState({});
  const [showAddPartModal, setShowAddPartModal] = React.useState(false);
  const [customFieldsData, setCustomFieldsData] = React.useState({});
  const [customFieldsErrors, setCustomFieldsErrors] = React.useState({});
  const [tempSelectedPartsCount, setTempSelectedPartsCount] = React.useState(0);
  const [ticketDescription, setTicketDescription] = React.useState("");
  const [partForModalPreview, setPartForModalPreview] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);

  const { user } = useAuth() as { user: UserType };

  const tempSelectedParts = React.useRef({});

  const { createRequest, loading } = useCreateRequest();

  const {
    assets = [],
    assetsListLoading,
    handleFetchMore,
    totalCount: assetsTotalCount,
  } = useListRequestableAssets({
    searchQuery: searchQuery,
    limit: ITEMS_PER_PAGE,
    skip: 0,
    where: {
      ...(searchQuery ? {} : { assetAssigned: false }),
      ...(selectedOrg ? { oem: selectedOrg } : {}),
    },
  });

  useEffect(() => {
    if (!assetsListLoading && !firstLoad) {
      setFirstLoad(true);
    }
  }, [assetsListLoading]);

  const { inventoryParts } = useGetAssetsPartsById({
    id: assetData?._id,
    isSharedAsset: true,
    skipCondition: !assetData?._id,
  });

  const handleTempSelectedPartsCount = () => {
    setTempSelectedPartsCount(Object.keys(tempSelectedParts.current).length);
  };

  const handleAddPart = (partId, qty) => {
    if (qty <= 0) {
      delete tempSelectedParts.current[partId];
    } else {
      tempSelectedParts.current[partId] = qty;
    }

    handleTempSelectedPartsCount();
  };

  const resourceParts = inventoryParts;

  const formatedSpareParts = React.useMemo(() => {
    const list = [];
    const addedIds = Object.keys(addedParts);

    addedIds.map((id) => {
      const item = resourceParts?.find(({ part }) => part._id === id);

      if (!item) return;

      list.push({
        part: item.part,
        quantity: addedParts[id],
      });
    });

    return list;
  }, [addedParts, resourceParts]);

  const handleAcceptPartRemove = (partId) => {
    setAddedParts((prev) => {
      const ref = { ...prev };

      delete ref[partId];

      return ref;
    });
  };

  const handleScrollBottom = () =>
    assets.length >= ITEMS_PER_PAGE &&
    !assetsListLoading &&
    assetsTotalCount > assets.length &&
    handleFetchMore({
      limit: ITEMS_PER_PAGE,
      skip: assets.length,
    });

  const getSelectedPartsCount = () => Object.keys(addedParts).length;

  const handleAddedPartQuantity = (partId, qty) => {
    if (qty <= 0) {
      handleAcceptPartRemove(partId);
    } else {
      setAddedParts((prev) => {
        const ref = { ...prev };

        ref[partId] = qty;

        return ref;
      });
    }
  };

  const handleClosePartsModal = () => {
    setShowAddPartModal(false);
    tempSelectedParts.current = {};
    setTempSelectedPartsCount(0);
  };

  React.useEffect(() => {
    setAddedParts({});
  }, [assetData?.[0]?.value]);

  const isAllCustomRequiredFilled = () => {
    if (!selectedWorkOrderType) return false;

    // check only if type is not default
    if (
      selectedWorkOrderType?.partsConfig?.show &&
      selectedWorkOrderType?.partsConfig?.isRequired &&
      !Object.keys(addedParts).length
    ) {
      return false;
    }

    // check only if type is not default
    if (
      selectedWorkOrderType?.descriptionConfig?.show &&
      selectedWorkOrderType?.descriptionConfig?.isRequired &&
      (ticketDescription === "" || !serialize(ticketDescription).length)
    ) {
      return false;
    }

    if (Array.isArray(selectedWorkOrderType?.customFields)) {
      for (const field of selectedWorkOrderType.customFields) {
        if (
          field.isRequired &&
          !customFieldsData[field.customAdditionalField._id]
        ) {
          return false;
        }
      }
    }

    return true;
  };

  return (
    <>
      <Drawer
        title={messages["requests"]["createRequest"]}
        isOpen
        onClose={() => setIsQuitAlertOpen(true)}
        onSubmit={async () => {
          try {
            const newData: {
              assetId: string;
              ticketType: string;
              title: string;
              inventoryParts?: {
                part: string;
                quantity: number;
                addedBy: string;
              }[];
              customFields?: { _id: string; fieldId: string; value: string }[];
              description: string;
            } = {
              assetId: assetData?._id,
              ticketType: selectedRequestType,
              title: requestTitle,
              description: selectedWorkOrderType?.descriptionConfig?.show
                ? ticketDescription
                : "",
            };
            if (
              Object.keys(addedParts).length &&
              selectedWorkOrderType?.partsConfig?.show
            ) {
              const partData = Object.entries(addedParts);
              const inventoryParts = [];

              for (const part of partData) {
                inventoryParts.push({
                  part: part[0],
                  quantity: part[1],
                  addedBy: user._id,
                });
              }

              newData.inventoryParts = inventoryParts;
            }

            if (Array.isArray(selectedWorkOrderType?.customFields)) {
              for (const customField of selectedWorkOrderType.customFields) {
                if (
                  customFieldsData[customField.customAdditionalField._id] !==
                  undefined
                ) {
                  newData.customFields = newData.customFields || [];
                  newData.customFields.push({
                    _id: customField._id,
                    fieldId: customField.customAdditionalField._id,
                    value:
                      customFieldsData[customField.customAdditionalField._id],
                  });
                }
              }
            }
            await createRequest({
              variables: {
                input: {
                  ...newData,
                },
              },
            });
            toast(<Toast message={messages?.requests?.["requestCreated"]} />, {
              closeButton: false,
            });
            onClose();
            registerMixpanelEvent(SHARED_ASSETS_CREATE_REQUEST, {
              sharerOrgUrl: assetData?.oem?.slug,
            });
          } catch (err) {
            toast(
              <Toast
                type={TOAST_TYPES.ERROR}
                message={
                  err?.message ||
                  `${messages?.common?.["errors"].somethingWrong}`
                }
              />,
              {
                closeButton: false,
              },
            );
          }
        }}
        submitButtonDisabled={
          !assetData ||
          !requestTitle ||
          !selectedRequestType ||
          !isAllCustomRequiredFilled() ||
          loading
        }
        submitButtonText={messages["requests"]["createRequest"]}
      >
        <div className="space-y-2xl flex flex-col">
          <div className="flex flex-col space-y-sm">
            <SingleSelectDropdown
              disabled={!firstLoad || !!selectedAsset?._id}
              isAsync={true}
              asyncProps={{
                isLoading: !firstLoad,
                onScrollToBottom: handleScrollBottom,
                onSearch: (val) => setSearchQuery(val),
              }}
              keyId="select-machine"
              label={capitalizeFirstLetter(
                `${messages["hierarchy"]["assets"]["dropDown"]["label"]} *`,
              )}
              placeholder={
                messages["hierarchy"]["assets"]["dropDown"]["placeholder"]
              }
              onChange={(value: Array<Asset>) =>
                setAssetData(() => value.find(Boolean))
              }
              options={assets.map(
                ({ _id, name, serialNumber, oem, image }) => ({
                  label: name,
                  description: `${serialNumber} • ${oem.name}`,
                  _id: _id,
                  oem: oem,
                  value: _id,
                  thumbnail: getMachineThumbnail({ oem, image }),
                }),
              )}
              searchable
              // @ts-ignore
              searchBy="label, serialNumber"
              values={assetData ? [assetData] : []}
            />
          </div>
          <div className="flex flex-col space-y-sm">
            <SingleSelectDropdown
              disabled={!assetData}
              keyId="request-type"
              label={messages["requests"]["requestType"]}
              onChange={(value: Array<{ _id: string; name: string }>) => {
                setSelectedRequestType((value || [])?.find(Boolean)?._id);
                setSelectedWorkType((value || [])?.find(Boolean));
              }}
              options={assetData?.oem?.ticketTypes
                ?.filter(
                  (ticketType) =>
                    !ticketType.isInternal && !ticketType.isSystem,
                )
                .map(({ _id, name, color, icon, isInternal, ...rest }) => ({
                  label: name,
                  _id: _id,
                  oem: assetData?.oem,
                  value: _id,
                  icon: (
                    <div
                      className="work-order-type-icon relative w-xl h-xl rounded-full flex items-center justify-center mr-sm"
                      style={{
                        backgroundColor: generateStatusBackgroundColor(color),
                      }}
                    >
                      <WorkOrderTypeIcons
                        icon={icon}
                        strokeColor={color}
                        size={12}
                      />
                      {isInternal ? null : (
                        <ExternalIcon
                          size={EXTERNAL_ICON_SIZES.SMALL}
                          className="absolute -top-[5px] -right-[5px]"
                        />
                      )}
                    </div>
                  ),
                  ...rest,
                }))}
              placeholder={messages["requests"]["requestType"]}
              searchable
              // @ts-ignore
              searchBy="label"
              // @ts-ignore
              values={selectedWorkOrderType ? [selectedWorkOrderType] : []}
            />
          </div>
          <div className="flex flex-col space-y-sm">
            <Input
              keyId="request-title"
              label={messages["requests"]["requestTitle"]}
              placeholder={messages["requests"]["addRequestTitle"]}
              onChange={(e) => {
                setRequestTitle(e.target.value);
              }}
              value={requestTitle}
              errorMessage={""}
            />
          </div>
          {!selectedWorkOrderType?.descriptionConfig?.show ? null : (
            <div>
              <TextEditor
                keyId="work-order-description"
                label={capitalizeFirstLetter(
                  `${
                    selectedWorkOrderType?.descriptionConfig?.fieldName ||
                    messages?.forms?.["newTicketForm"].description.label
                  } ${
                    selectedWorkOrderType?.descriptionConfig?.isRequired
                      ? "*"
                      : ""
                  }`,
                )}
                content={ticketDescription}
                placeholder={
                  messages?.forms?.["newTicketForm"].description.placeholder
                }
                autoFocus={false}
                onUpdate={(newValue) => {
                  setTicketDescription(JSON.stringify(newValue));
                }}
              />
            </div>
          )}

          {!selectedWorkOrderType?.partsConfig?.show ? null : (
            <>
              <div className="u-flex u-width-100 u-justify-between new-tickets-spare-parts-div">
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="no-styles"
                >
                  {selectedWorkOrderType?.partsConfig?.fieldName ||
                    messages.tickets["parts"]}{" "}
                  {selectedWorkOrderType?.partsConfig?.isRequired ? "*" : ""}
                </BodyText>
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="no-styles"
                >
                  <span
                    className={
                      getSelectedPartsCount() > 0
                        ? "text-brand"
                        : "text-secondary"
                    }
                  >
                    {getSelectedPartsCount()}
                  </span>
                  {` ${messages.tickets["partsAdded"]}`}
                </BodyText>
              </div>

              <div
                className="new-ticket-add-spare-parts new-tickets-spare-parts-div"
                onClick={(e) => {
                  setShowAddPartModal(true);
                }}
              >
                <span className="u-flex u-justify-center u-items-center">
                  <Plus size={12} weight="bold" />
                </span>
                <Label
                  size={LABEL_SIZES.SMALL}
                  color="text-brand"
                  className="ml-xs"
                >
                  {messages?.inventory?.["addParts"]}
                </Label>
              </div>

              {Object.keys(addedParts).length > 0 && (
                <CreateTicketAddedPartsComp
                  addedParts={formatedSpareParts}
                  handlePartRemove={handleAcceptPartRemove}
                  handlePartQuantity={handleAddedPartQuantity}
                />
              )}
            </>
          )}

          {/* custom configured form elements */}
          <CustomFormElements
            fields={selectedWorkOrderType?.customFields || []}
            formData={customFieldsData}
            errorData={customFieldsErrors}
            onChange={setCustomFieldsData}
            updateErrorData={setCustomFieldsErrors}
          />
        </div>
      </Drawer>
      <AppModal
        isOpen={showAddPartModal}
        className="!w-[75vw]"
        overlayClasses="!z-20"
        maxWidth="lg:!max-w-[650px]"
        disableUpdate={!tempSelectedPartsCount}
        disableCancel={false}
        hideUpdateButton={!resourceParts?.length}
        updateButtonText={
          <>
            {tempSelectedPartsCount > 0 && (
              <div className="selected-parts-count-in-button">
                {tempSelectedPartsCount}
              </div>
            )}
            {messages?.inventory?.["addSelectedParts"]}
          </>
        }
        handleClose={handleClosePartsModal}
        handleSubmit={() => {
          setAddedParts({
            ...addedParts,
            ...tempSelectedParts.current,
          });
          handleClosePartsModal();
        }}
        title={
          partForModalPreview ? null : messages.inventory?.["addParts"] || ""
        }
        header={
          partForModalPreview ? (
            <div className="flex items-center py-xl px-2xl border-solid border-b border-t-0 border-x-0 border-primary">
              <PartDetailModalHeader
                title={messages.inventory?.["workOrderPartDetails"]}
                onBack={() => setPartForModalPreview(null)}
              />
            </div>
          ) : null
        }
        showFooter={!partForModalPreview}
        contentClassName={
          partForModalPreview ? "part-modal-preview-content-wrapper" : ""
        }
        content={
          <AddPartComp
            addedParts={addedParts}
            allParts={resourceParts}
            onClickDetails={(part) => setPartForModalPreview(part)}
            partForModalPreview={partForModalPreview}
            handleAddPart={handleAddPart}
          />
        }
      />
      <AlertBox
        title={messages["tickets"]["ticketQuitAlertTitle"]}
        description={messages["tickets"]["ticketQuitAlertMessage"]}
        isOpen={isQuitAlertOpen}
        acceptButtonText={messages["common"]["quit"]}
        cancelButtonText={messages["common"]["cancel"]}
        image={<LeaveByDoorIcon width="130" height="130" />}
        onAccept={() => onClose()}
        onCancel={() => setIsQuitAlertOpen(false)}
        overlay={false}
      />
    </>
  );
};

export default Create;

import de from "~/lang/de-DE";
import en from "~/lang/en-US";
import es from "~/lang/es-ES";
import it from "~/lang/it-IT";

export default {
  ...en,
  ...de,
  ...es,
  ...it,
};

import React from "react";

const POSITION_TO_CLASS_MAP = {
  center: "left-auto right-[-280px]",
  left: "right-0",
  right: "top-auto right-auto",
};

export const POSITIONS = {
  CENTER: "center",
  LEFT: "left",
  RIGHT: "right",
} as const;

export type POSITION_TYPE = (typeof POSITIONS)[keyof typeof POSITIONS];

const Dropdown = ({
  children,
  show,
  onClose,
  dropdownRef,
  position = POSITIONS.LEFT,
  className = "",
}: {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
  dropdownRef: React.RefObject<HTMLInputElement>;
  position: POSITION_TYPE;
  className?: string;
}) => {
  // click outside the dropdown, closes it
  React.useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        show &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <div
      ref={dropdownRef}
      className={`absolute ${
        position === POSITIONS.RIGHT ? "max-h-96" : "max-h-[80vh]"
      } overflow-x-hidden no-scrollbar overflow-y-auto z-10 py-3.5 px-4 bg-white rounded-lg shadow-md ${
        show ? "block" : "hidden"
      } ${POSITION_TO_CLASS_MAP[position]} ${className}`}
    >
      {children}
    </div>
  );
};

export default Dropdown;

import * as React from "react";
const SvgAssetHub = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M17.381 14.622a1.95 1.95 0 1 1-2.76 2.76 1.95 1.95 0 0 1 2.76-2.76M23.876 14.76a1.756 1.756 0 1 1-2.483 2.484 1.756 1.756 0 0 1 2.483-2.484M10.61 14.76a1.756 1.756 0 1 1-2.482 2.484 1.756 1.756 0 0 1 2.483-2.484M20.56 9.014a1.756 1.756 0 1 1-2.483 2.483 1.756 1.756 0 0 1 2.483-2.483M13.927 20.503a1.756 1.756 0 1 1-2.483 2.483 1.756 1.756 0 0 1 2.483-2.483M13.927 9.014a1.756 1.756 0 1 1-2.483 2.483 1.756 1.756 0 0 1 2.483-2.483M20.56 20.503a1.756 1.756 0 1 1-2.483 2.483 1.756 1.756 0 0 1 2.483-2.483M13.559 20.225l1.466-2.541M13.559 11.773l1.466 2.542M20.878 15.999h-2.925",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgAssetHub;

import { Location } from "history";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  renderBlockComponent: (
    _: boolean,
    __: (val: boolean) => void,
    ___: (val: boolean) => void,
  ) => React.ReactNode;
}

const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  renderBlockComponent,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {renderBlockComponent(
        modalVisible,
        setConfirmedNavigation,
        setModalVisible,
      )}
    </>
  );
};
export default RouteLeavingGuard;

import RemoveGhostIcon from "@shared/svg/remove-ghost.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { Counter } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { PartListItem } from "@shared/ui/ListItemCards";
import Tooltip from "@shared/ui/Tooltip";
import _ from "lodash";
import { MinusCircle, Trash } from "phosphor-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import {
  getMappedCustomFields,
  getPartThumbnail,
  getSortedCustomFields,
} from "#/src/utils";
import { NotFoundComponent } from "~/components/_notFoundMessage";
import CustomFieldsAccordion from "~/components/customFields/_customFieldsAccordion";
import { useAuth } from "~/components/general";
import PartPreview from "~/components/inventory/PartPreview";
import { NoUserAssignedIcon } from "~/icons/icons";

export const AddPartComp = ({
  addedParts,
  handleAddPart,
  allParts = [],
  onClickDetails,
  partForModalPreview,
  inventoryCustomAdditionalFields = [],
  syncStates = false,
}) => {
  const { messages } = useIntl();
  const addedPartsIds = Object.keys(addedParts);

  const [selectedParts, setSelectedParts] = useState(() => {
    const obj = {};
    allParts.map(({ part }) => {
      let qty = 0;
      if (syncStates && addedParts) {
        qty = addedParts?.[part._id] || 0;
      }
      obj[part._id] = qty;
      return part;
    });
    return obj;
  });
  const [searchValue, setSearchValue] = useState("");
  const [partForModalPreviewCustomFields, setPartForModalPreviewCustomFields] =
    useState([]);
  const hasPartForPreviewQuantity =
    partForModalPreview && selectedParts[partForModalPreview?._id] > 0;

  useEffect(() => {
    if (partForModalPreview && inventoryCustomAdditionalFields?.length > 0) {
      const { customFieldArr } = getMappedCustomFields(
        partForModalPreview?.customFields ?? [],
        inventoryCustomAdditionalFields ?? [],
      );

      const arrDiff = _.differenceBy(
        inventoryCustomAdditionalFields,
        customFieldArr,
        "label",
      );

      setPartForModalPreviewCustomFields(
        getSortedCustomFields([...customFieldArr, ...arrDiff]),
      );
    }
  }, [partForModalPreview, inventoryCustomAdditionalFields]);

  const availableParts = useMemo(() => {
    if (!addedPartsIds.length || syncStates) return allParts;

    return allParts.filter(({ part }) => !addedPartsIds.includes(part?._id));
  }, [addedParts, searchValue]);

  const handleDec = (partId) => {
    setSelectedParts((prev) => {
      if (prev[partId] === 0) {
        handleAddPart(partId, 0);
        return { ...prev, [partId]: 0 };
      }

      const newQty = prev[partId] - 1;

      handleAddPart(partId, newQty);
      return { ...prev, [partId]: newQty };
    });
  };

  const handleRemovePartQuantity = (partId) => {
    setSelectedParts((prev) => {
      if (prev[partId] === 0) {
        handleAddPart(partId, 0);
        return { ...prev, [partId]: 0 };
      }

      const newQty = 0;

      handleAddPart(partId, newQty);
      return { ...prev, [partId]: newQty };
    });
  };

  const handleInc = (partId) => {
    setSelectedParts((prev) => {
      const newQty = prev[partId] + 1;

      handleAddPart(partId, newQty);
      return { ...prev, [partId]: newQty };
    });
  };

  const handleInput = (value, partId) => {
    handleAddPart(partId, value);
    setSelectedParts((prev) => ({ ...prev, [partId]: value }));
  };

  return partForModalPreview ? (
    <>
      <div className="u-width-100 u-padding-x-5">
        <img
          className="part-modal-preview-image"
          src={getPartThumbnail(partForModalPreview, true)}
        />
        <div className="u-width-100 u-flex u-margin-b-4">
          <div className="u-width-6 u-margin-r-1 u-overflow-hidden">
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
              {messages?.inventory.partName + ":"}
            </BodyText>
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="truncate">
              {partForModalPreview.name}
            </BodyText>
          </div>
          <div className="u-width-6 u-overflow-hidden">
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
              {messages?.inventory.labels.articleNumber + ":"}
            </BodyText>
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="truncate">
              {partForModalPreview.articleNumber}
            </BodyText>
          </div>
        </div>
        <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
          {messages?.common.description + ":"}
        </BodyText>
        <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="mb-md">
          {partForModalPreview?.description || "-"}
        </BodyText>
        {partForModalPreviewCustomFields.length > 0 && (
          <div className="u-margin-b-6">
            <div className="divider divider--gray u-margin-b-2" />
            <CustomFieldsAccordion
              hasAccordion={false}
              isEditable={false}
              customFieldList={partForModalPreviewCustomFields}
              selectFieldValueClassname="part-modal-preview-select-custom-field-value"
              selectFieldLabelClassname="part-modal-preview-select-custom-field-label"
            />
          </div>
        )}
      </div>
      <div className=" u-width-100 u-flex u-justify-center part-modal-preview-footer-wrapper">
        {hasPartForPreviewQuantity && (
          <button
            className="btn-v2 ghost-btn-v2 parts-inc-dec-btn u-margin-r-2"
            onClick={() => handleRemovePartQuantity(partForModalPreview._id)}
          >
            <Trash size={16} />
          </button>
        )}
        <Counter
          count={selectedParts[partForModalPreview._id]}
          setCount={(val) => handleInput(val, partForModalPreview._id)}
          incrementCount={() => handleInc(partForModalPreview._id)}
          decrementCount={() => handleDec(partForModalPreview._id)}
          isReadOnlyInput={false}
        />
      </div>
    </>
  ) : (
    <>
      {!availableParts.length ? (
        <NotFoundComponent
          icon={<NoUserAssignedIcon />}
          title={messages["inventory"]["noPartsForAsset"]}
        />
      ) : (
        <>
          <section>
            <input
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder={messages["hierarchy"]["assets"]["searchParts"]}
              className="c-input machine-parts-search"
              value={searchValue}
            />
          </section>

          <section>
            {availableParts
              .filter(
                ({ part }) =>
                  part?.name
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase()) ||
                  part?.articleNumber
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase()),
              )
              .map(({ part }) => {
                const hasQuantity = selectedParts[part._id] > 0;

                return (
                  <div key={part._id}>
                    <PartListItem
                      articleNumber={part?.articleNumber}
                      name={part?.name}
                      thumbnail={getPartThumbnail(part)}
                      onButtonClick={() => onClickDetails(part)}
                      showDeleteButton={hasQuantity}
                      onDeleteButtonClick={() =>
                        handleRemovePartQuantity(part._id)
                      }
                      hideDetailsButton={!onClickDetails}
                      counterProps={{
                        count: selectedParts?.[part._id] || 0,
                        setCount: (val) => handleInput(val, part._id),
                        incrementCount: () => handleInc(part._id),
                        decrementCount: () => handleDec(part._id),
                        isReadOnlyInput: false,
                      }}
                    />
                  </div>
                );
              })}
          </section>
        </>
      )}
    </>
  );
};

export const AddedPartsComp = ({
  addedParts = [],
  handlePartRemove = () => {},
  allowPreview = false,
  isClosedTicket = false,
  partsCustomAdditionalFields = [],
  allowEdit = true,
  showAddedBy = true,
}) => {
  const { messages } = useIntl();
  const [showPartPreview, setShowPartPreview] = useState(false);
  const { user } = useAuth();

  const [showPartRemoveConfirmation, setShowPartRemoveConfirmation] = useState({
    show: false,
    name: "",
  });
  const tempRemovePartId = useRef(null);
  const partToPreview = useRef(null);

  const handlePartRemoveClick = (part) => {
    setShowPartRemoveConfirmation({ show: true, name: part.name });
    tempRemovePartId.current = part.id;
  };

  const openPreview = (part) => {
    if (!allowPreview) return;

    partToPreview.current = part;
    setShowPartPreview(true);
  };

  return (
    <>
      <div className="flex text-xs text-secondary capitalize font-manrope font-medium m-0 border-x-0 border-y border-solid border-primary py-md">
        <div className="u-width-7 px-xs">
          {messages?.inventory?.labels.name}
        </div>
        <div className="u-width-2">
          {messages?.inventory?.labels.articleNumber}
        </div>
        <div className="u-width-2">{messages?.tickets?.quantity}</div>
        <div className="u-width-1" />
      </div>
      {addedParts.map(({ part, quantity, addedBy }, index) => {
        const hasAddedBy =
          addedBy &&
          showAddedBy &&
          (addedBy.role.toLowerCase() === "user" ||
            addedBy?.oem?._id !== user?.oem?._id);
        return (
          <div key={index} className="mt-md">
            <div
              className={`parts-table__col u-flex u-items-center u-padding-0 ${
                allowPreview ? "u-cursor-pointer" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                openPreview(part);
              }}
            >
              <div className="u-width-7 u-flex">
                <div className="w-5xl h-4xl rounded-lg overflow-hidden mr-md shrink-0">
                  <img
                    src={getPartThumbnail(part)}
                    alt={part?.name}
                    className="w-5xl h-4xl object-cover object-center"
                  />
                </div>
                <div
                  className={`u-flex-grow-1 u-flex u-flex-column u-overflow-hidden ${
                    !hasAddedBy ? "u-justify-center" : ""
                  }`}
                >
                  <Label size={LABEL_SIZES.SMALL} className="truncate">
                    {part?.name}
                  </Label>
                  {hasAddedBy ? (
                    <span className="part-added-by-tag ">
                      <BodyText
                        size={BODY_TEXT_SIZES.X_SMALL}
                        color="text-brand"
                        className="uppercase text-center font-semibold"
                      >
                        {messages?.common?.customerOrder}
                      </BodyText>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="u-width-2">
                <div className="parts-table-item__name u-flex-grow-1 u-text-overflow">
                  <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="truncate">
                    {part?.articleNumber}
                  </BodyText>
                </div>
              </div>
              <div className="u-width-2">
                <div className="parts-table-item__name u-flex-grow-1">
                  <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="truncate">
                    {quantity}
                  </BodyText>
                </div>
              </div>
              {allowEdit && (
                <div className="u-width-1">
                  <div className="u-self-center u-flex u-justify-end u-items-center spare-part-list-item">
                    <span
                      data-tip={messages?.inventory?.unassignTooltipText}
                      className="u-cursor-pointer u-flex u-items-center"
                      data-for="added-part-unassign-tooltip"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isClosedTicket) return;
                        handlePartRemoveClick({
                          id: part?._id,
                          name: part?.name,
                        });
                      }}
                    >
                      <MinusCircle
                        size={16}
                        className={`${isClosedTicket && "text-disabled"}`}
                      />
                    </span>
                    <Tooltip id="added-part-unassign-tooltip" />
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}

      <AlertBox
        title={messages?.inventory?.unassignPartTitle}
        description={messages?.inventory?.unassignConfirmationFromWorkOrderTitle?.replace(
          "{{}}",
          showPartRemoveConfirmation?.name,
        )}
        isOpen={showPartRemoveConfirmation.show}
        acceptButtonText={
          messages?.inventory?.unassignConfirmationAcceptBtnText
        }
        cancelButtonText={
          messages?.inventory?.unassignConfirmationCencalBtnText
        }
        image={<RemoveGhostIcon width="130" height="130" />}
        onCancel={() => {
          setShowPartRemoveConfirmation({ show: false, name: "" });
          tempRemovePartId.current = null;
        }}
        onAccept={() => {
          handlePartRemove(tempRemovePartId.current);
          setShowPartRemoveConfirmation({ show: false, name: "" });
        }}
        overlay={true}
      />

      {showPartPreview && (
        <PartPreview
          data={partToPreview.current}
          onClose={() => {
            setShowPartPreview(false);
          }}
          customAdditionalFields={partsCustomAdditionalFields}
          isOpen={showPartPreview}
        />
      )}
    </>
  );
};

export const CreateTicketAddedPartsComp = ({
  addedParts = [],
  handlePartRemove,
  handlePartQuantity,
}) => {
  const { messages } = useIntl();

  const [showPartRemoveConfirmation, setShowPartRemoveConfirmation] = useState({
    show: false,
    name: "",
  });
  const tempRemovePartId = useRef(null);

  const handlePartRemoveClick = (part) => {
    setShowPartRemoveConfirmation({ show: true, name: part.name });
    tempRemovePartId.current = part._id;
  };

  return (
    <>
      {addedParts.map(({ part, quantity, addedBy }, index) => (
        <div key={index} className="mt-md">
          <PartListItem
            articleNumber={part?.articleNumber}
            name={part?.name}
            thumbnail={getPartThumbnail(part)}
            hideDetailsButton
            showDeleteButton
            onDeleteButtonClick={() => handlePartRemoveClick(part)}
            counterProps={{
              count: quantity,
              incrementCount: () => handlePartQuantity(part._id, quantity + 1),
              decrementCount: () => handlePartQuantity(part._id, quantity - 1),
            }}
          />
        </div>
      ))}

      <AlertBox
        title={messages?.inventory?.unassignPartTitle}
        description={messages?.inventory?.unassignConfirmationFromWorkOrderTitle?.replace(
          "{{}}",
          showPartRemoveConfirmation?.name,
        )}
        isOpen={showPartRemoveConfirmation.show}
        acceptButtonText={
          messages?.inventory?.unassignConfirmationAcceptBtnText
        }
        cancelButtonText={
          messages?.inventory?.unassignConfirmationCencalBtnText
        }
        image={<RemoveGhostIcon width="130" height="130" />}
        onCancel={() => {
          setShowPartRemoveConfirmation({ show: false, name: "" });
          tempRemovePartId.current = null;
        }}
        onAccept={() => {
          handlePartRemove(tempRemovePartId.current);
          setShowPartRemoveConfirmation({ show: false, name: "" });
        }}
        overlay={true}
      />
    </>
  );
};

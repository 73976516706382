import { PRODUCT_TYPES } from "@shared/constants/paidFeaturesHierarchy";
import ExportCSVIcon from "@shared/svg/export-csv.svg?react";
import BoardIcon from "@shared/svg/tabs-board-icon.svg?react";
import CalendarIcon from "@shared/svg/tabs-calendar-icon.svg?react";
import ListIcon from "@shared/svg/tabs-list-icon.svg?react";
import MapIcon from "@shared/svg/tabs-map-icon.svg?react";
import TableIcon from "@shared/svg/tabs-table-icon.svg?react";
import ActionPopup from "@shared/ui/ActionPopup";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import "leaflet/dist/leaflet.css";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { SearchInput, RadioButtonInput } from "@shared/ui/Inputs";
import AppModal from "@shared/ui/Modal";
import Tabs, { TAB_VARIANTS } from "@shared/ui/Tabs";
import Tooltip from "@shared/ui/Tooltip";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ContentLoader from "react-content-loader";
import { useIntl } from "react-intl";
import { useRecoilState } from "recoil";

import isPaidFeatureAvailable from "#/src/utils/isPaidFeatureAvailable";
import getEnum from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features.json";
import { sortAtom } from "~/atoms/_sort";
import AppHeaderWithSidebarOption from "~/components/AppHeaderWithSidebarOption";
import Filter from "~/components/filters";
import { useAuth } from "~/components/general";
import { NoTicketsLarge } from "~/components/general/_no-tickets";
import CreateTicketDrawer from "~/components/tickets/Create";
import {
  FILTER_WORK_ORDERS,
  INITIATE_ADD_WORK_ORDER,
  INITIATE_SEARCH_WORK_ORDERS,
  ITEMS_PER_PAGE,
  INITIATE_FILTERS_WORK_ORDERS,
  MIXPANEL_EVENT_PROPERTIES,
  SUBMIT_NEW_WORK_ORDER,
  VIEW_WORK_ORDERS,
  VIEW_WORK_ORDERS_BOARD,
  VIEW_WORK_ORDERS_CALENDAR,
  VIEW_WORK_ORDERS_LIST,
  VIEW_WORK_ORDERS_MAP,
  VIEW_WORK_ORDERS_TABLE,
  CSV_RESOURCES,
  VIEW_CMMS,
  VIEW_FIELD_SERVICE,
} from "~/constants";
import { SCHEDULED } from "~/constants/filters/filters";
import { WORK_ORDER } from "~/constants/filters/scopes";
import { SCROLL_SCOPE } from "~/constants/scrollScopes";
import {
  WORK_ORDERS_PAGE_VIEW,
  WORK_ORDER_BROADCAST_TOPICS,
} from "~/constants/workOrders";
import TicketsKanbanView from "~/containers/_TicketsKanbanView";
import {
  ListView,
  MapView,
  TableView,
  TicketCalendar,
} from "~/containers/ticket/tabs";
import { useFilter, useReorderWorkOrderList, useSearch } from "~/hooks";
import useCreateCsvExporter from "~/hooks/_useCreateCsvExporter";
import { useRoleManager } from "~/hooks/_useRoleManager";
import useScrollPersist from "~/hooks/_useScrollPersist";
import { useListOwnOemTickets } from "~/services";
import { broadcaster } from "~/utils/_appBroadcast";
import { registerMixpanelEvent } from "~/utils/_mixpanel";
import {
  useGetAppConfigRecoilValue,
  useIsGrowthLoopEnabled,
} from "~/utils/appFeatures";

const PAGE_VIEW_KEY = "openTicketPageView";

export const WORK_ORDER_MAP_BOUNDS = "WORK_ORDER_MAP_BOUNDS";
const paidFeaturesRef = getEnum(PAID_FEATURES, "reference");

const NOT_ALLOWED_FILTER_IDS = [SCHEDULED];

const WorkOrdersPage = (props) => {
  const { user } = useAuth();
  const appConfig = useGetAppConfigRecoilValue();
  const { isOemAdmin } = useRoleManager(user?.role);
  const { messages } = useIntl();
  const { searchValue, updateSearchTerm } = useSearch();
  const { clearScopeScroll } = useScrollPersist();
  const { exportCsv } = useCreateCsvExporter();
  const [showActionMenu, setShowActionMenu] = React.useState(false);
  const { productType } = props;

  const fetchingFirstRef = useRef(true);

  const {
    selectedFilters,
    unselectedFilters,
    activeFilters,
    toggleFilters,
    setToggleFilters,
    handleSelectFilter,
    handleUnselectFilter,
    handleActiveFilter,
    scope,
  } = useFilter({ scope: WORK_ORDER });

  const { handleReorderTicket } = useReorderWorkOrderList();

  const [selectedView, setSelectedView] = useState(
    localStorage.getItem(PAGE_VIEW_KEY)
      ? localStorage.getItem(PAGE_VIEW_KEY)
      : WORK_ORDERS_PAGE_VIEW.LIST_VIEW,
  );
  const [activeFilter, setActiveFilter] = useState("ticket");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportModalVal, setExportModalVal] = useState("");
  const [sort, setSort] = useRecoilState(sortAtom);

  const {
    loading,
    tickets: allTickets,
    handleFetchMore,
    totalCount,
  } = useListOwnOemTickets({
    activeFilters,
    searchQuery: searchValue,
    sort: selectedView === WORK_ORDERS_PAGE_VIEW.TABLE_VIEW ? sort.tickets : [],
  });

  const isGrowthLoopEnabled = useIsGrowthLoopEnabled();

  useEffect(() => {
    if (!isGrowthLoopEnabled) return;

    if (productType === PRODUCT_TYPES.CMMS) {
      registerMixpanelEvent(VIEW_CMMS);
    } else if (productType === PRODUCT_TYPES.FIELD_SERVICE) {
      registerMixpanelEvent(VIEW_FIELD_SERVICE);
    }
  }, [isGrowthLoopEnabled]);

  const handleScrollBottom = (event) => {
    if (
      allTickets.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above"
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: allTickets.length,
      });
    }
  };

  const registerPageViewEvent = (view) => {
    let event = null;
    if (view === WORK_ORDERS_PAGE_VIEW.LIST_VIEW) {
      event = VIEW_WORK_ORDERS_LIST;
    } else if (view === WORK_ORDERS_PAGE_VIEW.BOARD_VIEW) {
      event = VIEW_WORK_ORDERS_BOARD;
    } else if (view === WORK_ORDERS_PAGE_VIEW.MAP_VIEW) {
      event = VIEW_WORK_ORDERS_MAP;
    } else if (view === WORK_ORDERS_PAGE_VIEW.TABLE_VIEW) {
      event = VIEW_WORK_ORDERS_TABLE;
    } else if (view === WORK_ORDERS_PAGE_VIEW.PLAN_CAPACITY_VIEW) {
      event = VIEW_WORK_ORDERS_CALENDAR;
    }

    if (event !== null) {
      registerMixpanelEvent(event);
    }
  };

  const changePageView = useCallback(
    (view) => {
      if (selectedView === view) return;
      registerPageViewEvent(view);

      setSelectedView(view);
      localStorage.setItem(PAGE_VIEW_KEY, view);

      for (const sc of Object.keys(SCROLL_SCOPE.WORK_ORDERS)) {
        clearScopeScroll(SCROLL_SCOPE.WORK_ORDERS[sc]);
      }
    },
    [clearScopeScroll, selectedView],
  );

  const tabs = useMemo(
    () => [
      {
        id: WORK_ORDERS_PAGE_VIEW.LIST_VIEW,
        icon: <ListIcon size={16} />,
        label: messages?.tickets?.pageViews?.list,
        isActive: selectedView === WORK_ORDERS_PAGE_VIEW.LIST_VIEW,
      },
      {
        id: WORK_ORDERS_PAGE_VIEW.TABLE_VIEW,
        icon: <TableIcon size={16} />,
        label: messages?.tickets?.pageViews?.table,
        isActive: selectedView === WORK_ORDERS_PAGE_VIEW.TABLE_VIEW,
      },
      {
        id: WORK_ORDERS_PAGE_VIEW.BOARD_VIEW,
        icon: <BoardIcon size={16} />,
        label: messages?.tickets?.pageViews?.board,
        isActive: selectedView === WORK_ORDERS_PAGE_VIEW.BOARD_VIEW,
      },
      {
        id: WORK_ORDERS_PAGE_VIEW.MAP_VIEW,
        icon: <MapIcon size={16} />,
        label: messages?.tickets?.pageViews?.map,
        isActive: selectedView === WORK_ORDERS_PAGE_VIEW.MAP_VIEW,
      },
      {
        id: WORK_ORDERS_PAGE_VIEW.PLAN_CAPACITY_VIEW,
        icon: <CalendarIcon size={16} />,
        label: messages?.tickets?.pageViews?.calender,
        isActive: selectedView === WORK_ORDERS_PAGE_VIEW.PLAN_CAPACITY_VIEW,
      },
    ],
    [selectedView],
  );

  const isLoading = loading && !allTickets.length;
  const isFeaturePaid = isPaidFeatureAvailable(
    paidFeaturesRef.workManagement,
    user,
    appConfig,
  );

  const renderPageView = React.useCallback(() => {
    if (!isFeaturePaid)
      return (
        <NoTicketsLarge title={messages?.tickets?.openTicketsPage.noTickets} />
      );
    if (
      isLoading &&
      selectedView !== WORK_ORDERS_PAGE_VIEW.BOARD_VIEW &&
      selectedView !== WORK_ORDERS_PAGE_VIEW.MAP_VIEW &&
      selectedView !== WORK_ORDERS_PAGE_VIEW.PLAN_CAPACITY_VIEW
    ) {
      return (
        <div>
          <TicketLoader />
        </div>
      );
    }
    switch (selectedView) {
      case WORK_ORDERS_PAGE_VIEW.TABLE_VIEW: {
        return (
          <TableView
            tickets={allTickets}
            onScrollToBottom={handleScrollBottom}
            totalCount={totalCount}
            isLoading={loading}
            setSort={(value) => setSort({ ...sort, tickets: value })}
            sort={sort.tickets}
          />
        );
      }

      case WORK_ORDERS_PAGE_VIEW.BOARD_VIEW: {
        return (
          <TicketsKanbanView
            tickets={allTickets}
            handleAccessAlert={changePageView}
            activeFilters={activeFilters}
            searchQuery={searchValue}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            fetchingFirstRef={fetchingFirstRef}
          />
        );
      }
      case WORK_ORDERS_PAGE_VIEW.PLAN_CAPACITY_VIEW:
        return <TicketCalendar activeFilters={activeFilters} />;
      case WORK_ORDERS_PAGE_VIEW.MAP_VIEW:
        return <MapView tickets={allTickets} activeFilters={activeFilters} />;
      default:
        return (
          <ListView
            tickets={allTickets}
            intl={props.intl}
            onScrollToBottom={handleScrollBottom}
            totalCount={totalCount}
            loading={loading}
          />
        );
    }
  }, [
    allTickets,
    isLoading,
    props.intl,
    selectedView,
    activeFilter,
    loading,
    isOemAdmin,
  ]);

  useEffect(() => {
    if (localStorage.getItem(PAGE_VIEW_KEY)) {
      setSelectedView(localStorage.getItem(PAGE_VIEW_KEY));
    }
  }, [localStorage.getItem(PAGE_VIEW_KEY)]);

  useEffect(() => {
    const reorderList = (id, isNewTicket, isTicketDeleted, status) => {
      handleReorderTicket({
        allTickets,
        id,
        activeFilters,
        searchQuery: searchValue,
        isNewTicket,
        isTicketDeleted: isTicketDeleted,
        status,
      });
    };

    const unsubscribeReOrderList = broadcaster.subscribe(
      WORK_ORDER_BROADCAST_TOPICS.REORDER_WORK_ORDER_LIST,
      reorderList,
    );
    return () => {
      unsubscribeReOrderList(
        WORK_ORDER_BROADCAST_TOPICS.REORDER_WORK_ORDER_LIST,
        reorderList,
      );
    };
  }, [allTickets]); // due to reordering inside cache we can't set tickets length as dependancy

  useEffect(() => {
    registerMixpanelEvent(VIEW_WORK_ORDERS);
  }, []);

  const filteredSelectedFilters = React.useMemo(() => {
    if (selectedView !== WORK_ORDERS_PAGE_VIEW.PLAN_CAPACITY_VIEW)
      return selectedFilters;

    return {
      value: selectedFilters.value.filter(
        (filter) => !NOT_ALLOWED_FILTER_IDS.includes(filter.id),
      ),
    };
  }, [selectedFilters, selectedView]);

  const filteredUnselectedFilters = React.useMemo(() => {
    if (selectedView !== WORK_ORDERS_PAGE_VIEW.PLAN_CAPACITY_VIEW)
      return unselectedFilters;

    return {
      value: unselectedFilters.value.filter(
        (filter) => !NOT_ALLOWED_FILTER_IDS.includes(filter.id),
      ),
    };
  }, [unselectedFilters, selectedView]);

  const filteredActiveFilters = React.useMemo(() => {
    if (selectedView !== WORK_ORDERS_PAGE_VIEW.PLAN_CAPACITY_VIEW)
      return activeFilters;

    return Object.keys(activeFilters)
      .filter((key) => !NOT_ALLOWED_FILTER_IDS.includes(key))
      .reduce((acc, key) => {
        acc[key] = activeFilters[key];
        return acc;
      }, {});
  }, [activeFilters, selectedView]);

  const actions = [
    {
      label: messages.tickets.summaryExport,
      onClick: async () => {
        setShowActionMenu(false);
        await exportCsv({
          resource: CSV_RESOURCES.TICKET,
          searchQuery: searchValue,
          activeFilters: activeFilters,
        });
      },
      className: "!font-bold",
    },
    {
      label: messages.tickets.detailExport,
      onClick: async () => {
        setShowActionMenu(false);
        setIsExportModalOpen(true);
      },
      className: "!font-bold",
    },
  ];

  const EditModalContent = () => (
    <RadioButtonInput
      label={messages.tickets.detailExportModal.message}
      vertical
      options={[
        {
          value: "inventoryParts",
          name: messages.tickets.detailExportModal.option1.name,
          heading: messages.tickets.detailExportModal.option1.heading,
        },
        {
          value: "timeTrackerLogs",
          name: messages.tickets.detailExportModal.option2.name,
          heading: messages.tickets.detailExportModal.option2.heading,
        },

        {
          value: "procedures",
          name: messages.tickets.detailExportModal.option3.name,
          heading: messages.tickets.detailExportModal.option3.heading,
        },
      ]}
      value={exportModalVal}
      onChange={(e) => {
        setExportModalVal(e.target.value);
      }}
    />
  );

  return (
    <>
      <div className="makula-page-content-wrapper">
        <AppHeaderWithSidebarOption>
          <Headline size={HEADLINE_SIZES.SMALL}>
            {messages?.tickets?.openTicketsPage?.title}{" "}
            {isFeaturePaid ? `(${totalCount ?? 0})` : ""}
          </Headline>
          <div className="flex items-center">
            <SearchInput
              placeholder={messages?.tickets?.searchPlaceholder}
              value={searchValue}
              onChange={updateSearchTerm}
              onSearchActive={() =>
                registerMixpanelEvent(INITIATE_SEARCH_WORK_ORDERS)
              }
            />
            <div className="inline-block relative items-center">
              <Button
                className="ml-sm"
                variant={BUTTON_VARIANTS.OUTLINE}
                leadingIcon={<ExportCSVIcon width={20} height={20} />}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowActionMenu(true);
                }}
                disabled={loading || !allTickets.length}
                data-tip={messages.common["exportToCsv"]}
                data-for="export-to-csv-button"
              />
              {showActionMenu && (
                <ActionPopup
                  close={() => {
                    setShowActionMenu(false);
                  }}
                  actions={actions}
                  position="below-parent"
                  width="w-60"
                  style={{
                    transform: "translateX(-50%)",
                    left: "50%",
                    marginTop: "12px",
                  }}
                />
              )}
            </div>
            {!showActionMenu && <Tooltip id="export-to-csv-button" />}
            <CreateTicketDrawer
              onOpenDrawer={() =>
                registerMixpanelEvent(INITIATE_ADD_WORK_ORDER)
              }
              onSubmitSuccess={() =>
                registerMixpanelEvent(SUBMIT_NEW_WORK_ORDER, {
                  workOrderSource:
                    MIXPANEL_EVENT_PROPERTIES.workOrderSource.workOrders,
                })
              }
              onCreate={() => (fetchingFirstRef.current = true)}
            />
          </div>
        </AppHeaderWithSidebarOption>
        <div className="u-flex u-items-center u-justify-between">
          <div className="py-md">
            <Tabs
              variant={TAB_VARIANTS.PILLS}
              tabs={tabs}
              onTabChange={changePageView}
            />
          </div>
          <Filter
            registerOpenEvent={() =>
              registerMixpanelEvent(INITIATE_FILTERS_WORK_ORDERS)
            }
            registerSelectEvent={() =>
              registerMixpanelEvent(FILTER_WORK_ORDERS)
            }
            filterStates={{
              selectedFilters: filteredSelectedFilters,
              unselectedFilters: filteredUnselectedFilters,
              activeFilters: filteredActiveFilters,
              toggleFilters,
              handleSelectFilter,
              handleUnselectFilter,
              handleActiveFilter,
              setToggleFilters,
              scope,
            }}
            maxFilterAlertText={
              messages?.tickets?.openTicketsPage?.filters?.maxLimit
            }
          />
        </div>
        {renderPageView()}
      </div>
      <AppModal
        className="!w-[550px]"
        isOpen={isExportModalOpen}
        handleClose={() => {
          setIsExportModalOpen(false);
          setExportModalVal("");
        }}
        handleSubmit={async () => {
          setIsExportModalOpen(false);
          setExportModalVal("");
          await exportCsv({
            resource: CSV_RESOURCES.TICKET,
            searchQuery: searchValue,
            activeFilters: activeFilters,
            where: { detailExport: exportModalVal },
          });
        }}
        disableUpdate={!exportModalVal}
        updateButtonText={messages.tickets.detailExportModal.export}
        title={messages.tickets.detailExportModal.title}
        content={<EditModalContent />}
      />
    </>
  );
};

export const TicketLoader = (props) => (
  <ContentLoader
    speed={1.5}
    width={800}
    height={100}
    viewBox="0 0 800 100"
    backgroundcolor="#ededed"
    foregroundcolor="#dbdbdb"
    {...props}
  >
    <circle cx="44" cy="55" r="33" />
    <rect x="93" y="35" rx="0" ry="0" width="124" height="10" />
    <rect x="94" y="59" rx="0" ry="0" width="88" height="9" />
    <rect x="356" y="35" rx="0" ry="0" width="76" height="8" />
    <rect x="357" y="56" rx="0" ry="0" width="57" height="8" />
    <rect x="564" y="36" rx="0" ry="0" width="50" height="14" />
  </ContentLoader>
);

export default WorkOrdersPage;

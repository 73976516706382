import * as React from "react";
const SvgCmmsActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("rect", {
  width: 32,
  height: 32,
  rx: 6,
  fill: "#00B09B"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M17.85 7.946a6.324 6.324 0 0 1 5.302 5.302.835.835 0 0 1-.832.946h-4.584a.834.834 0 0 1-.834-.833V8.777c0-.505.449-.906.948-.831v0ZM21.987 20.657c.599-.809 1.009-1.766 1.165-2.807a.835.835 0 0 0-.832-.947h-3.406a.833.833 0 0 0-.589 1.423l2.41 2.409c.354.354.953.326 1.252-.078v0ZM13.258 11.555a6.327 6.327 0 0 0-5.26 7.518c.481 2.473 2.48 4.462 4.954 4.933 1.876.357 3.64-.116 4.993-1.115.405-.3.436-.9.08-1.255l-3.587-3.587a.833.833 0 0 1-.244-.589v-5.075c0-.5-.44-.903-.937-.83v0Z",
  fill: "#fff",
  stroke: "#fff",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgCmmsActive;

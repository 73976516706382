import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_SIZES, BUTTON_VARIANTS } from "@shared/ui/Button";
import { Input } from "@shared/ui/Inputs";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import useOnClickOutside from "use-onclickoutside";

import EmptySpace from "~/components/_emptySpace";

const CustomNumberField = ({
  field,
  handleTextFieldSave,
  isEditable = true,
  dropdownClassname = "",
}) => {
  const { messages } = useIntl();

  const ref = useRef();
  const [newValue, setNewValue] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const isSaveDisabled =
    !String(newValue?.[field?._id])?.length ||
    newValue?.[field?._id] === field?.value;

  const toggle = () => {
    setIsOpen((prev) => {
      if (!prev && !newValue[field?._id]?.length) {
        newValue[field?._id] = field?.value;
      }

      return !prev;
    });
  };

  const updateTextField = (textFields) => {
    newValue[field?._id] = textFields;
    toggle();
  };

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  return (
    <>
      <div className="u-flex u-items-center custom-field-row u-padding-b-1">
        <div className="u-width-2">
          <BodyText
            title={field?.label}
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
            className="overflow-hidden text-ellipsis no-styles"
          >
            {field?.label}:
          </BodyText>
        </div>
        <div className="u-width-8">
          <div
            ref={ref}
            className={`c-dropdown ${isOpen ? "c-dropdown--shown" : ""}`}
          >
            <button
              className={`c-textarea-btn u-width-100 ${
                !isEditable
                  ? "pointer-events-none !bg-transparent"
                  : "cursor-pointer"
              }`}
              onClick={() => {
                if (!isEditable) return;
                toggle();
              }}
            >
              <span className={field?.value ? "u-text-color-gray-v2-100" : ""}>
                {field?.value
                  ? field.value
                  : `${messages?.common?.emptyProperty}`}
              </span>
            </button>
            <div
              className={
                "c-dropdown-menu customize-field-dropdown property-userDropdownMenu flex-col " +
                dropdownClassname
              }
            >
              <div style={{ width: "100%" }}>
                <EmptySpace height="8px" />
                <Input
                  keyId={field?._id}
                  type="number"
                  placeholder={field?.label}
                  value={newValue[field?._id] || ""}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setNewValue({ [field?._id]: "" });
                      return;
                    }
                    const value =
                      e.target.value?.length <= 15
                        ? parseFloat(e.target.value)
                        : parseFloat(e.target.value?.slice(0, 15));
                    setNewValue({ [field?._id]: value });
                  }}
                />
                <EmptySpace height="8px" />
                <div className="flex justify-end">
                  <Button
                    variant={BUTTON_VARIANTS.LINK}
                    size={BUTTON_SIZES.SMALL}
                    text={messages?.common?.discard}
                    onClick={(e) => {
                      if (!field?.value) {
                        setIsOpen(false);
                        return;
                      }
                      handleTextFieldSave(
                        e,
                        { [field?._id]: "" },
                        field?._id,
                        updateTextField,
                      );
                    }}
                  />

                  <Button
                    size={BUTTON_SIZES.SMALL}
                    text={messages?.common?.save}
                    disabled={isSaveDisabled}
                    className="ml-md"
                    onClick={(e) => {
                      handleTextFieldSave(
                        e,
                        newValue,
                        field?._id,
                        updateTextField,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomNumberField;

import { useLazyQuery, useQuery } from "@apollo/client";

import {
  GET_DATA_CSV_QUERY,
  GET_OWN_OEM,
  GET_OWN_OEM_AI_ASSISTANT_USAGE_QUERY,
  GET_OWN_OEM_QUERY,
  LIST_OWN_OEM_PLANS_QUERY,
} from "~/api";
import type { QuotaInformationType } from "~/components/aiAssistant/QuotaIndicator";

export const useGetOwnOem = () => {
  const {
    data: { getOwnOem } = {},
    loading,
    error,
    refetch,
  } = useQuery(GET_OWN_OEM_QUERY);

  return { getOwnOem, loading, error, refetch };
};

export const useGetOwnOemAiAssistantUsage = () => {
  const { data, loading, refetch } = useQuery<{
    [GET_OWN_OEM]: QuotaInformationType;
  }>(GET_OWN_OEM_AI_ASSISTANT_USAGE_QUERY);

  return { data: data?.[GET_OWN_OEM], loading, refetch };
};

export const useGetDataCsv = () => {
  const [getDataCsv, { data, loading }] = useLazyQuery(GET_DATA_CSV_QUERY);

  const fetchCsvData = async (variables) => {
    await getDataCsv({
      variables,
    });
  };

  return {
    loading,
    csvLink: data?.getDataCsv,
    fetchCsvData,
  };
};

export const useListOwnOemPlans = () => {
  const {
    data: { listOwnOemPlans } = {},
    loading,
    error,
    refetch,
  } = useQuery(LIST_OWN_OEM_PLANS_QUERY);

  return { plans: listOwnOemPlans, loading, error, refetch };
};

import CalendarIcon from "@shared/svg/calendar-filled.svg?react";
import DisabledUnlink from "@shared/svg/wo-unlink-disabled.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import {
  ExternalIcon,
  EXTERNAL_ICON_SIZES,
  NotificationCounter,
  COUNTER_SIZES,
} from "@shared/ui/Indicators";
import Tag from "@shared/ui/Tag";
import moment from "moment-timezone";
import { Trash, WarningCircle } from "phosphor-react";
import React, { useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import usePopper from "react-overlays/usePopper";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Waypoint } from "react-waypoint";
import { useRecoilValue } from "recoil";
import useOnClickOutside from "use-onclickoutside";

import { COLOR, ITEMS_BEFORE_PAGE, MAX_TEXT_INPUT_LENGTH } from "../constants";

import ticketTypes from "$/settings/enums/ticket/_types.json";
import PAID_FEATURES from "$/settings/paid-features.json";
import { unreadChannelMessagesAtom } from "~/atoms/_chat";
import { useAuth } from "~/components/general";
import WorkOrderDeleteAlert from "~/components/tickets/_workOrderDeleteAlert";
import TicketItemAssigneesList from "~/components/tickets/ticketItemAssigneesList";
import WorkOrderTypeIcons from "~/components/tickets/WorkOrderTypeIcons";
import { TicketLoader } from "~/containers/ticket/WorkOrdersPage";
import useCheckPaidFeatureAvailable from "~/hooks/_useCheckPaidFeatureAvailable";
import { useRoleManager } from "~/hooks/_useRoleManager";
import useScrollPersist from "~/hooks/_useScrollPersist";
import {
  ExclamationIcon,
  ProcedureCheckCircle,
  ProcedureCrossCircle,
  SidebarProceduresIcon,
  UnlinkIcon,
} from "~/icons/icons";
import { formatDate, getEnums } from "~/utils";
import { generateStatusBackgroundColor } from "~/utils/_colors";
import { trimText } from "~/utils/_initials";
import { useIsGrowthLoopEnabled } from "~/utils/appFeatures";

const PaidFeatureEnums = getEnums(PAID_FEATURES, "reference");

const PreventiveMaintenanceDeleteIconStyles = {
  display: "inline-flex",
  alignItems: "center",
};

export const TicketItemOpen = ({
  data = [],
  path = "work/work-orders",
  ticketsLoading = false,
  onScrollToBottom = () => {},
  totalCount,
  loading,
}) => {
  const { user } = useAuth();
  const { setScopeData } = useScrollPersist();

  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);
  const deleteWorkOrderId = useRef(null);
  const { isOemAdmin, isOemTechnician } = useRoleManager(user.role);

  const handleDeleteClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    deleteWorkOrderId.current = id;
    setShowDeleteAlert(true);
  };

  const onCloseAlert = () => {
    setShowDeleteAlert(false);
  };

  return (
    <>
      <div className="ticket-list-container-v2">
        {data?.map((item, index) => {
          const isSelfCreatedTicket = item.createdBy?._id === user._id;
          return (
            <React.Fragment key={item._id}>
              <Link
                to={`/app/${path}/${item._id}`}
                className="c-listItem c-listItem--hoverable ticket-list-item-container"
              >
                <TicketListComponent
                  id={item._id}
                  title={item?.title}
                  ticketID={item?.ticketId}
                  ticketType={item?.ticketType}
                  facility={item?.facility?.name}
                  machine={item?.machine?.name}
                  machineSerialNumber={item?.machine?.serialNumber}
                  status={item?.status}
                  allStatuses={user?.oem?.statuses || []}
                  date={item?.createdAt}
                  totalComment={item?.comments?.length || 0}
                  hasOriginalEventBeenDeleted={
                    item?.hasOriginalEventBeenDeleted
                  }
                  ticketChannel={item?.ticketChatChannels?.[0]}
                  ticketInternalChannel={
                    item?.ticketInternalNotesChatChannels?.[0]
                  }
                  maintenanceOn={item?.maintenanceOn}
                  setScrollPos={setScopeData}
                  totalProcedures={item?.procedures?.length || 0}
                  assignees={item?.assignees}
                  showDelete={
                    isOemAdmin || (isSelfCreatedTicket && !isOemTechnician)
                  }
                  onDelete={(e) => handleDeleteClick(e, item._id)}
                  scheduleDate={item?.schedule}
                />
              </Link>
              {!ticketsLoading &&
                totalCount > data.length &&
                index === data.length - ITEMS_BEFORE_PAGE && (
                  <Waypoint onEnter={onScrollToBottom} />
                )}
            </React.Fragment>
          );
        })}
        {loading && data.length > 0 && <TicketLoader />}
      </div>
      <WorkOrderDeleteAlert
        showAlert={showDeleteAlert}
        onClose={onCloseAlert}
        workOrderId={deleteWorkOrderId.current}
      />
    </>
  );
};

export const TicketListComponent = React.memo(
  ({
    status,
    allStatuses,
    title,
    facility,
    machine,
    machineSerialNumber,
    date,
    ticketID,
    ticketType,
    ticketChannel,
    ticketInternalChannel,
    maintenanceOn,
    hasOriginalEventBeenDeleted,
    setScrollPos = () => {},
    totalProcedures,
    assignees = [],
    showDelete = false,
    onDelete = () => {},
    showUnlinkButton = false,
    onUnlink = () => {},
    compactTile = false,
    disabledUnlinkButton = false,
    hideUnlinkButton = false,
    scheduleDate = "",
  }) => {
    const { messages } = useIntl();
    const isGrowthLoopEnabled = useIsGrowthLoopEnabled();
    const unreadChannelMessages = useRecoilValue(unreadChannelMessagesAtom);
    const notificationCounter =
      unreadChannelMessages?.channels[ticketChannel] +
      unreadChannelMessages?.channels[ticketInternalChannel];
    const hasProcedureFeature = useCheckPaidFeatureAvailable(
      PaidFeatureEnums.procedures,
    );

    const [isHovered, setIsHovered] = useState(false);
    const styles = useMemo(() => {
      return {
        backgroundColor: isHovered
          ? ticketType.color
          : generateStatusBackgroundColor(ticketType?.color),
      };
    }, [isHovered, ticketType.color]);

    const statusItem = allStatuses?.find((item) => status === item._id);

    const handleMouseEnter = () => {
      setIsHovered(() => true);
      ReactTooltip.rebuild();
    };
    const handleMouseLeave = () => {
      setIsHovered(() => false);
    };

    const isClosedTicket =
      status === allStatuses?.[allStatuses?.length - 1]?._id;

    const scheduledDateText = scheduleDate?.startTime
      ? moment(scheduleDate.startTime).format("YYYY-MM-DD")
      : "";

    const ticketSecondaryDataJSX = !compactTile && (
      <>
        {scheduledDateText && (
          <div
            className="single !w-28 lg:!w-32"
            data-tip={messages?.common?.scheduleDate + ": " + scheduledDateText}
            data-for="global-tooltip"
            data-event="mouseenter"
            data-event-off="mouseleave"
          >
            <div className="flex items-center group">
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                color="text-tertiary"
                className="no-styles font-semibold bg-warning-lightest text-warning whitespace-nowrap py-2xs px-xs rounded-md flex items-center group-hover:bg-warning group-hover:text-white"
              >
                <span className="mr-2xs leading-none">
                  <CalendarIcon className="stroke-current" />
                </span>
                {scheduledDateText}
              </BodyText>
            </div>
          </div>
        )}
        {hasProcedureFeature && (
          <div className="procedure-count w-3xl lg:w-6xl flex items-center gap-2xs mr-lg">
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="no-styles"
            >
              {totalProcedures}
            </BodyText>
            <SidebarProceduresIcon width={20} />
          </div>
        )}
        <div className="ticket-meta-data !w-36 flex lg:block items-center space-x-4 lg:space-x-0">
          <TicketItemAssigneesList
            assignees={assignees}
            className="mb-0 lg:mb-xs "
          />

          <BodyText
            size={BODY_TEXT_SIZES.SMALL}
            color="text-secondary"
            className="no-styles whitespace-nowrap lg:whitespace-normal"
          >
            {date?.split("T")?.[0]}
          </BodyText>
        </div>
      </>
    );

    return (
      <div
        className={`ticket-item-wrapper${
          isClosedTicket ? " closed-ticket-wrapper" : ""
        }`}
        onClick={() => {
          setScrollPos({
            x: window.scrollX,
            y: window.scrollY,
            index: 0,
            isVirtualList: false,
          });
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={`left-side-content ${
            compactTile ? "" : "w-4/5 lg:w-[45%]"
          }`}
        >
          <div
            className="work-order-icon-container"
            style={styles}
            data-tip={
              ticketType?.isInternal
                ? ""
                : messages?.workOrderTypes?.externalWorkOrderTooltip
            }
            data-for="global-tooltip"
            data-event="mouseenter"
            data-event-off="mouseleave"
          >
            <WorkOrderTypeIcons
              size={20}
              icon={ticketType.icon}
              strokeColor={isHovered ? COLOR.$white_v2 : ticketType.color}
            />

            {ticketType.isInternal ? null : (
              <ExternalIcon
                size={EXTERNAL_ICON_SIZES.MEDIUM}
                className="absolute top-0 right-0"
              />
            )}

            {parseInt(notificationCounter) > 0 && (
              <NotificationCounter
                size={COUNTER_SIZES.LARGE}
                className="absolute -bottom-3xs -left-3xs border border-solid border-primary"
                count={notificationCounter}
                isSizedCounter
              />
            )}
          </div>
          <div className="title-wrapper u-flex-1 u-padding-r-5 u-overflow-hidden">
            <Headline
              size={HEADLINE_SIZES.X_SMALL}
              className="overflow-hidden text-ellipsis whitespace-nowrap"
            >
              <TicketTitleText
                title={title}
                ticketType={ticketType}
                maintenanceOn={maintenanceOn}
                hasOriginalEventBeenDeleted={hasOriginalEventBeenDeleted}
              />
            </Headline>

            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="flex items-center no-styles mt-sm lg:mt-0"
            >
              #{ticketID}
              {facility ? (
                <span className="inline-flex items-center">
                  <span>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
                  {facility}
                </span>
              ) : (
                <span className="inline-flex items-center">
                  <span>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
                  <ExclamationIcon />
                  &nbsp;&nbsp;
                  {
                    messages?.tickets?.[
                      isGrowthLoopEnabled
                        ? "deletedConnection"
                        : "deletedFacility"
                    ]
                  }
                </span>
              )}
              {machine ? (
                <span className="inline-flex items-center">
                  <span>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
                  {machine}
                </span>
              ) : (
                <span className="inline-flex items-center">
                  <span>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
                  <ExclamationIcon />
                  &nbsp;&nbsp;
                  {messages["hierarchy"]["assets"]["deletedAsset"]}
                </span>
              )}
              {machineSerialNumber && (
                <span className="inline-flex items-center">
                  <span>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
                  {machineSerialNumber}
                </span>
              )}
            </BodyText>
            <div className="flex items-center w-full lg:hidden mt-sm">
              {ticketSecondaryDataJSX}
            </div>
          </div>
        </div>

        <div className="right-side-content max-w-[50%] space-x-4">
          <div className="hidden lg:flex items-center w-full">
            {ticketSecondaryDataJSX}
          </div>
          <div
            className={`${
              compactTile ? "!w-fit" : ""
            }ticket-status-comment !w-24`}
          >
            <Tag
              label={statusItem?.label}
              style={{
                backgroundColor: isHovered
                  ? statusItem?.color
                  : generateStatusBackgroundColor(statusItem?.color),
                color: isHovered ? COLOR.$white_v2 : statusItem?.color,
              }}
              isHoverable={false}
            />
          </div>

          {showDelete && (
            <div className="u-padding-0 u-width-1 u-flex">
              <Button
                variant={BUTTON_VARIANTS.LINK}
                className="ml-2xl"
                leadingIcon={<Trash className="text-secondary" size={16} />}
                tooltipId="delete-ticket"
                tooltipText={messages?.common?.delete}
                onClick={onDelete}
              />
            </div>
          )}

          {showUnlinkButton && (
            <div className="flex p-0">
              {hideUnlinkButton ? (
                <div className="w-3xl h-lg"></div>
              ) : (
                <Button
                  variant={BUTTON_VARIANTS.LINK}
                  className={`flex ml-4 items-end ${
                    disabledUnlinkButton ? "pointer-events-none" : ""
                  }`}
                  leadingIcon={
                    disabledUnlinkButton ? (
                      <DisabledUnlink className="w-lg h-lg" />
                    ) : (
                      <UnlinkIcon size={16} />
                    )
                  }
                  tooltipText={messages?.tickets?.unlinkWorkOrder}
                  tooltipPosition="bottom"
                  tooltipId="unlink-ticket"
                  onClick={onUnlink}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export const ListItemBoxed = ({ children, className }) => {
  return <div className={`list-item--boxed ${className}`}>{children}</div>;
};

export const TicketTitleText = ({
  title,
  ticketType,
  hasOriginalEventBeenDeleted,
  maintenanceOn,
}) => {
  const { messages } = useIntl();
  const isPMTicket =
    ticketTypes[ticketType] === ticketTypes.PreventiveMaintenance;

  return (
    <>
      {trimText(title, 75)}

      {isPMTicket && hasOriginalEventBeenDeleted && (
        <span style={PreventiveMaintenanceDeleteIconStyles}>
          &nbsp; (<ExclamationIcon /> &nbsp;&nbsp;
          {messages?.tickets?.preventiveEventDeletedMessage})
        </span>
      )}

      {isPMTicket &&
        !hasOriginalEventBeenDeleted &&
        maintenanceOn &&
        !maintenanceOn?.includes("1970-01-01") && (
          <>
            ({messages?.tickets?.preventiveTicketTitlePart}{" "}
            {formatDate(maintenanceOn) || ""})
          </>
        )}
    </>
  );
};

export const EditTicketTitleText = ({
  title,
  ticketType,
  hasOriginalEventBeenDeleted,
  maintenanceOn,
  handleUpdateTitle,
  disabled = false,
}) => {
  const [newTitle, setNewTitle] = useState(title);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);

  const container = useRef();
  const toolTip = useRef();
  const inputRef = useRef(null);

  const { messages } = useIntl();

  const { styles, attributes } = usePopper(container.current, toolTip.current, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const isPMTicket =
    ticketTypes[ticketType] === ticketTypes.PreventiveMaintenance;

  const handleSubmit = () => {
    if (newTitle) {
      if (title !== newTitle) handleUpdateTitle(newTitle);
      setIsFocused(false);
      setError("");
    }
  };

  useOnClickOutside(inputRef, handleSubmit);

  return (
    <>
      <div ref={inputRef}>
        <div
          className={`edit-title-wrapper u-relative u-flex-column ${
            disabled ? "" : "hover:bg-accent"
          } ${isFocused ? "focus" : ""}`}
          ref={container}
          onMouseEnter={() => !disabled && !isFocused && setShowTooltip(true)}
          onMouseLeave={() => !disabled && setShowTooltip(false)}
        >
          <input
            disabled={disabled}
            onFocusCapture={() => {
              if (disabled) return;
              setIsFocused(true);
              setShowTooltip(false);
            }}
            className="w-full p-0 font-manrope text-lg font-bold bg-transparent border-0 focus:outline-none"
            value={newTitle}
            onChange={(e) => {
              const value = e.target.value;

              if (!value) setError(messages?.tickets?.errors?.titleRequired);
              else if (value.length > MAX_TEXT_INPUT_LENGTH) {
                setError(messages?.forms?.newTicketForm?.title?.lengthError);
                return;
              } else setError("");

              setNewTitle(e.target.value);
            }}
          />
          <div
            className={`tool-tip ${!showTooltip ? "tool-tip--hidden" : ""}`}
            ref={toolTip}
            style={styles.popper}
            {...attributes.popper}
          >
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-inverse">
              {messages?.tickets?.clickToEdit}
            </BodyText>
            <div className="arrow" data-popper-arrow />
          </div>
        </div>

        <div
          className={`u-items-center u-justify-between u-margin-t-1 ${
            isFocused ? "u-flex" : "u-hidden"
          }`}
        >
          <p className="input-v2-error-label u-padding-l-0 u-margin-r-1">
            {error && (
              <>
                <WarningCircle className="input-v2-error-icon" size={16} />
                {error}
              </>
            )}
          </p>
          <div className="u-flex">
            <ProcedureCheckCircle
              className={
                !newTitle ? "u-cursor-not-allowed" : "u-cursor-pointer"
              }
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit();
              }}
            />
            <ProcedureCrossCircle
              className="u-cursor-pointer u-margin-l-1 u-margin-r-1"
              onClick={(e) => {
                e.stopPropagation();
                setIsFocused(false);
                setNewTitle(title);
                setError("");
              }}
            />
          </div>
        </div>
      </div>

      {isPMTicket && hasOriginalEventBeenDeleted && (
        <span style={PreventiveMaintenanceDeleteIconStyles}>
          &nbsp; (<ExclamationIcon /> &nbsp;&nbsp;
          {messages?.tickets?.preventiveEventDeletedMessage})
        </span>
      )}

      {isPMTicket &&
        !hasOriginalEventBeenDeleted &&
        maintenanceOn &&
        !maintenanceOn?.includes("1970-01-01") && (
          <>
            ({messages?.tickets?.preventiveTicketTitlePart}{" "}
            {formatDate(maintenanceOn) || ""})
          </>
        )}
    </>
  );
};

import React from "react";

type ProgressPropsType = {
  value: number;
  max: number;
};

const Progress = ({ value, max }: ProgressPropsType) => {
  const completed = Math.min((value / max) * 100, 100);
  return (
    <div className="bg-accent h-sm w-full rounded-full">
      <div
        className={`h-sm rounded-full ${completed > max ? "w-0" : "w-full"} ${
          completed > 75 ? "bg-danger" : "bg-brand"
        }`}
        style={{ width: `${completed}%` }}
      />
    </div>
  );
};

export default Progress;

import { useMutation } from "@apollo/client";
import {
  CREATE_PREVENTIVE_MAINTENANCE_EVENT,
  UPDATE_PREVENTIVE_MAINTENANCE_EVENT,
  DELETE_PREVENTIVE_MAINTENANCE_EVENT,
  LIST_PREVENTIVE_MAINTENANCE_EVENTS,
} from "../../api";

export const createPreventiveMaintenanceEvent = () => {
  const [
    createPreventiveMaintenanceEvent,
    { error: { networkError } = {}, loading },
  ] = useMutation(CREATE_PREVENTIVE_MAINTENANCE_EVENT, {
    update: (
      cache,
      { data: { createPreventiveMaintenanceEvent: cpmEvent } },
    ) => {
      try {
        const { listPreventiveMaintenanceEvents } = cache.readQuery({
          query: LIST_PREVENTIVE_MAINTENANCE_EVENTS,
          variables: {
            params: {
              where: {
                oem_in: cpmEvent?.oem?._id,
                machine_in: cpmEvent?.machine?._id,
              },
            },
          },
        });

        cache.writeQuery({
          query: LIST_PREVENTIVE_MAINTENANCE_EVENTS,
          data: {
            listPreventiveMaintenanceEvents: [
              ...listPreventiveMaintenanceEvents,
              cpmEvent,
            ],
          },
          variables: {
            params: {
              where: {
                oem_in: cpmEvent?.oem?._id,
                machine_in: cpmEvent?.machine?._id,
              },
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });

  return {
    createEvent: (data) =>
      createPreventiveMaintenanceEvent({
        variables: {
          input: {
            ...data,
          },
        },
      }),
    loading,
    serverErrors: networkError,
  };
};

export const deletePreventiveMaintenanceEvent = (machineId) => {
  const [
    deletePreventiveMaintenanceEvent,
    { error: { networkError } = {}, loading },
  ] = useMutation(DELETE_PREVENTIVE_MAINTENANCE_EVENT, {
    update: (
      cache,
      { data: { deletePreventiveMaintenanceEvent: dpmEvent } },
    ) => {
      try {
        const { listPreventiveMaintenanceEvents } = cache.readQuery({
          query: LIST_PREVENTIVE_MAINTENANCE_EVENTS,
          variables: {
            params: {
              where: {
                oem_in: dpmEvent?.oem?._id,
                machine_in: dpmEvent?.machine?._id || machineId,
              },
            },
          },
        });

        cache.writeQuery({
          query: LIST_PREVENTIVE_MAINTENANCE_EVENTS,
          data: {
            listPreventiveMaintenanceEvents:
              listPreventiveMaintenanceEvents?.filter(
                (item) => item._id !== dpmEvent?._id,
              ),
          },
          variables: {
            params: {
              where: {
                oem_in: dpmEvent?.oem?._id,
                machine_in: dpmEvent?.machine?._id || machineId,
              },
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });

  return {
    deleteEvent: (id) =>
      deletePreventiveMaintenanceEvent({
        variables: {
          id,
        },
      }),
    loading,
    serverErrors: networkError,
  };
};

export const updatePreventiveMaintenanceEvent = () => {
  const [
    updatePreventiveMaintenanceEvent,
    { error: { networkError } = {}, loading },
  ] = useMutation(UPDATE_PREVENTIVE_MAINTENANCE_EVENT, {
    update: (
      cache,
      { data: { updatePreventiveMaintenanceEvent: upmEvent } },
    ) => {
      try {
        const { listPreventiveMaintenanceEvents } = cache.readQuery({
          query: LIST_PREVENTIVE_MAINTENANCE_EVENTS,
          variables: {
            params: {
              where: {
                oem_in: upmEvent?.oem?._id,
                machine_in: upmEvent?.machine?._id,
              },
            },
          },
        });

        cache.writeQuery({
          query: LIST_PREVENTIVE_MAINTENANCE_EVENTS,
          data: {
            listPreventiveMaintenanceEvents:
              listPreventiveMaintenanceEvents?.map((item) => {
                if (item._id === upmEvent?._id) {
                  return upmEvent;
                } else {
                  return item;
                }
              }),
          },
          variables: {
            params: {
              where: {
                oem_in: upmEvent?.oem?._id,
                machine_in: upmEvent?.machine?._id,
              },
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });

  return {
    updateEvent: (data) =>
      updatePreventiveMaintenanceEvent({
        variables: {
          input: {
            ...data,
          },
        },
      }),
    loading,
    serverErrors: networkError,
  };
};

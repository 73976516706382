import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";

import { generatePMEQueryWhereCond } from "#/src/utils/filter/assetsQueryCond";
import {
  LIST_PREVENTIVE_MAINTENANCE_EVENTS,
  LIST_ALL_PREVENTIVE_MAINTENANCE_EVENTS,
  LIST_SHARED_PREVENTIVE_MAINTENANCE_EVENTS,
} from "~/api";
import { useSearch } from "~/hooks";

/**
 *
 * @param query should contain an object with at least a key "where" and it's also an object with some specifice keys
 * See more details on this link: https://www.npmjs.com/package/mongo-search-parameters
 * @returns [PreventiveMaintenance] | []
 */
export const listAllPreventiveMaintenanceEvents = (
  query,
  skipCondition = false,
) => {
  const { data: { listPreventiveMaintenanceEvents } = {}, loading } = useQuery(
    LIST_PREVENTIVE_MAINTENANCE_EVENTS,
    {
      variables: {
        params: query,
      },
      skip: skipCondition,
    },
  );

  return {
    loading,
    events: listPreventiveMaintenanceEvents,
  };
};

export const useListSharedPreventiveMaintenanceEvents = (
  query,
  skipCondition = false,
) => {
  const { data: { listSharedPreventiveMaintenanceEvents } = {}, loading } =
    useQuery(LIST_SHARED_PREVENTIVE_MAINTENANCE_EVENTS, {
      variables: {
        params: query,
      },
      skip: skipCondition,
    });

  return {
    loading,
    events: listSharedPreventiveMaintenanceEvents,
  };
};

/**
 * Custom hook for fetching preventive maintenance events with FE-based pagination.
 * @param {string} oemId - ID of the OEM (required to fetch relevant events).
 * @param {number} pageSize - Number of events to fetch per page (default: 100).
 * @returns {object} - { events, loading, fetchNextPage, hasMore }
 */
export const usePreventiveMaintenanceEvents = (
  oemId,
  activeFilters = {},
  pageSize = 100,
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [allEvents, setAllEvents] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const { searchValue, handleSearch, updateSearchTerm } = useSearch();
  const [totalCount, setTotalCount] = useState(1);
  const filterConditions = generatePMEQueryWhereCond(activeFilters);
  const hasFilter = Object.keys(filterConditions)?.length;

  const { loading, fetchMore, refetch } = useQuery(
    LIST_ALL_PREVENTIVE_MAINTENANCE_EVENTS,
    {
      variables: {
        params: {
          where: {
            oem_in: oemId,
            searchQuery: searchValue || "",
            ...(hasFilter ? filterConditions : {}),
          },
          limit: pageSize,
          skip: 0,
        },
      },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        const fetchedEvents =
          data?.listAllPreventiveMaintenanceEvents?.events || [];
        const totalCount =
          data?.listAllPreventiveMaintenanceEvents?.totalCount || 0;
        setCurrentPage(1);
        setAllEvents(fetchedEvents);
        setTotalCount(totalCount);
        setHasMore(fetchedEvents.length === pageSize);
      },
    },
  );

  const fetchNextPage = () => {
    if (loading || !hasMore) return;

    fetchMore({
      variables: {
        params: {
          where: { oem_in: oemId, searchQuery: searchValue || "" },
          limit: pageSize,
          skip: currentPage * pageSize,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEvents =
          fetchMoreResult?.listAllPreventiveMaintenanceEvents?.events || [];
        if (newEvents.length < pageSize) setHasMore(false);
        setCurrentPage((prevPage) => prevPage + 1);
        setAllEvents((prev) => [...prev, ...newEvents]);
      },
    });
  };

  const refetchEvents = () => {
    // Reset pagination states when searchValue, oemId, or pageSize changes
    setCurrentPage(1);
    setHasMore(true);
    setAllEvents([]);

    refetch({
      params: {
        where: { oem_in: oemId, searchQuery: searchValue || "" },
        limit: pageSize,
        skip: 0,
      },
    });
  };

  useEffect(() => {
    refetchEvents();
  }, [searchValue, oemId, pageSize, refetch]);

  return {
    events: allEvents,
    loading,
    fetchNextPage,
    hasMore,
    handleSearch,
    searchValue,
    updateSearchTerm,
    refetchEvents,
    totalCount,
  };
};

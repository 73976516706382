import { bottom } from "@popperjs/core";
import SidebarOption from "@shared/svg/sidebar.svg?react";
import AppHeader, { AppHeaderPropsType } from "@shared/ui/AppHeader";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Overlay from "@shared/ui/Overlay";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useRecoilState } from "recoil";

import { setSidebarStateInStorage, sidebarAtom } from "~/atoms/sidebar";

const AppHeaderWithSidebarOption = ({
  children,
  ...rest
}: {
  children: React.ReactNode;
} & AppHeaderPropsType) => {
  const [showHamburgerOverlay, setShowHamburgerOverlay] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useRecoilState(sidebarAtom);
  const { messages } = useIntl();
  const nodeRef = useRef();

  return (
    <div
      className={`flex w-full items-center space-x-md sticky top-0 z-[5] ${
        rest.background || "bg-primary"
      } ${rest.padding || "py-lg"} border-0 border-b border-solid ${
        rest.borderColor || "border-primary"
      }`}
    >
      <div
        className="flex w-fit justify-center cursor-pointer"
        onClick={() => {
          setSidebarStateInStorage(!sidebarExpanded);
          setSidebarExpanded(!sidebarExpanded);
        }}
        onMouseEnter={() => setShowHamburgerOverlay(true)}
        onMouseLeave={() => setShowHamburgerOverlay(false)}
        ref={nodeRef}
      >
        <SidebarOption className="w-xl h-xl flex-shrink-0 cursor-pointer" />
      </div>
      <Overlay
        arrowClasses="before:bg-secondary before:shadow-alert"
        className="w-fit z-10 bg-secondary shadow-alert rounded"
        containerRef={document.body}
        offset={[0, 10]}
        placement={bottom}
        showOverlay={showHamburgerOverlay}
        targetRef={nodeRef}
      >
        <BodyText
          color="text-inverse"
          className="p-sm"
          size={BODY_TEXT_SIZES.X_SMALL}
        >
          {sidebarExpanded
            ? messages?.menus?.["hideMenu"]
            : messages?.menus?.["showMenu"]}
        </BodyText>
      </Overlay>
      <AppHeader
        {...rest}
        className={`w-full ${rest.className} border-0 border-b-0`}
        padding=""
      >
        {children}
      </AppHeader>
    </div>
  );
};

export default AppHeaderWithSidebarOption;

import Dropdown, { POSITIONS } from "@shared/ui/DropDown";
import React from "react";
import { useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";

import { NotFoundComponent } from "~/components/_notFoundMessage";
import DropdownFooter from "~/components/filters/_dropdownFooter";
import DropdownHeader from "~/components/filters/_dropdownHeader";
import { ITEMS_BEFORE_PAGE, ITEMS_PER_PAGE } from "~/constants";
import { NoUserAssignedIcon, TickIcon } from "~/icons/icons";
import { useListAllOwnOemCustomers } from "~/services";

const FacilityName = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  activeFilters,
  selectValues,
  handleSelectDeleteAll,
  dropDownPosition = POSITIONS.LEFT,
}) => {
  const { messages: msg } = useIntl();
  // states
  const [searchTerm, setSearchTerm] = React.useState("");

  // fetch facilities

  const {
    customers: facilities = [],
    loading,
    handleFetchMore,
    totalCount,
  } = useListAllOwnOemCustomers({
    searchQuery: searchTerm,
    limit: ITEMS_PER_PAGE,
    skip: 0,
  });

  const handleScrollBottom = (event) => {
    if (
      facilities.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above" &&
      totalCount > facilities.length
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: facilities.length,
      });
    }
  };

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
      position={dropDownPosition}
    >
      <DropdownHeader
        title={title}
        searchTerm={searchTerm}
        onSearchTermChange={setSearchTerm}
        unselectFilter={unselectFilter}
      />
      <div className="filter-facility-name filter-items">
        {!loading && !facilities?.length && (
          <NotFoundComponent
            icon={<NoUserAssignedIcon />}
            title={msg?.common?.filters.noFacility}
          />
        )}
        {facilities.map((item, index) => (
          <div
            className="filter-facility-name-item-container"
            key={index}
            onClick={() => selectValues(filterItem, item?._id)}
          >
            <span
              className={`filter-facility-name-item ${
                activeFilters[filterItem.id].includes(item?._id) ? "active" : ""
              }`}
            >
              {item.name}
              {index === facilities.length - ITEMS_BEFORE_PAGE ? (
                <Waypoint onEnter={handleScrollBottom} />
              ) : null}
            </span>
            {activeFilters[filterItem.id].includes(item?._id) && (
              <span className="active">
                <TickIcon />
              </span>
            )}
          </div>
        ))}
        {loading ? (
          <div className="no-value filter-facility-item-loading">
            {msg?.common?.loading}
          </div>
        ) : null}
      </div>
      <DropdownFooter
        deleteAll={() => handleSelectDeleteAll(filterItem, false)}
        disabled={!activeFilters[filterItem.id]?.length}
      />
    </Dropdown>
  );
};

export default FacilityName;

import { getBoxAccessToken } from "~/services";

export default (skip = false) => {
  const { user, loading } = getBoxAccessToken({ skip }) || {};
  const { foldersAccessToken: token } = user || {};
  return {
    token,
    loading,
  };
};

import * as React from "react";
const SvgMakulaLogoDark = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 85 104",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "m53.464 2.937 19.92 11.494a22.052 22.052 0 0 1 11.02 19.104v36.93c0 7.886-4.202 15.171-11.02 19.104l-19.92 11.494a21.93 21.93 0 0 1-21.927 0l-19.92-11.494A22.052 22.052 0 0 1 .596 70.465v-36.93c0-7.886 4.202-15.171 11.02-19.104l19.92-11.494a21.93 21.93 0 0 1 21.927 0ZM31.935 22.995l-.206.118-17.381 10.062A21.61 21.61 0 0 0 3.578 51.65v.469c0 7.64 4.023 14.704 10.57 18.589l.2.117 17.38 10.062a21.492 21.492 0 0 0 21.338.118l.206-.118 17.381-10.062a21.61 21.61 0 0 0 10.77-18.475v-.469c0-7.64-4.023-14.704-10.57-18.589l-.2-.117-17.38-10.062a21.492 21.492 0 0 0-21.338-.118Z",
  fill: "#172B4D"
}));
export default SvgMakulaLogoDark;

import * as React from "react";
const SvgCalendarFilled = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 12,
  height: 12,
  viewBox: "0 0 12 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M3.49896 8.50104L5.95248 8.51955",
  stroke: "current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M6 6.50021H8.50104",
  stroke: "current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.5019 4.49937H1.49811",
  stroke: "current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M3.99913 1.24802V2.74864",
  stroke: "current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.00084 1.24802V2.74864",
  stroke: "current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M9.00123 10.5019H2.99873C2.16989 10.5019 1.49811 9.83008 1.49811 9.00124V3.49895C1.49811 2.6701 2.16989 1.99832 2.99873 1.99832H9.00123C9.83008 1.99832 10.5019 2.6701 10.5019 3.49895V9.00124C10.5019 9.83008 9.83008 10.5019 9.00123 10.5019Z",
  stroke: "current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgCalendarFilled;

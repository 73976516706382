import * as React from "react";
const SvgTeams = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M13.862 6.35a1.349 1.349 0 1 1-1.908 1.906 1.349 1.349 0 0 1 1.908-1.907M9.468 3.942a2.077 2.077 0 1 1-2.936 2.937 2.077 2.077 0 0 1 2.936-2.937M4.046 6.35a1.349 1.349 0 1 1-1.908 1.906A1.349 1.349 0 0 1 4.046 6.35M15.333 12.667v-.73c0-.921-.746-1.667-1.666-1.667h-.534M.667 12.667v-.73c0-.921.746-1.667 1.666-1.667h.534M11.56 12.667v-1.068a2.333 2.333 0 0 0-2.334-2.333H6.773A2.333 2.333 0 0 0 4.44 11.6v1.068",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgTeams;

import * as React from "react";
const SvgExportCsv = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 20 20",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  d: "M14.167 10.833H5.833A3.333 3.333 0 0 0 2.5 14.167v0A3.333 3.333 0 0 0 5.833 17.5h8.334a3.333 3.333 0 0 0 3.333-3.333v0a3.333 3.333 0 0 0-3.333-3.334Z",
  clipRule: "evenodd"
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  d: "M9.167 14.167h5m-8.304-.03a.041.041 0 0 1 0 .06.041.041 0 0 1-.06 0 .041.041 0 0 1 0-.06.041.041 0 0 1 .06 0M7.5 5 10 2.5 12.5 5M10 8.333V2.5"
}));
export default SvgExportCsv;

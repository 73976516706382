import * as React from "react";
const SvgDeleteDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  fill: "none",
  viewBox: "0 0 96 114",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "m68.257 35.597-4.03-22.655-16.67-11.029-17.574 3.112a2.874 2.874 0 0 0-2.329 3.334l4.76 26.88-1.526.28-4.774-26.88a4.433 4.433 0 0 1 3.591-5.137L47.892.272l17.755 11.75 4.138 23.302-1.527.273Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#CDD1FE",
  d: "M16.091 35.727h67.954v9.455H16.091zm-2.954 17.137h11.818v5.318H13.137zm61.454 0h11.818v5.318H74.591z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#0517F8",
  d: "M64.383 12.79c-4.705.89-8.394 1.356-10.355 1.457-.667.033-1.827.055-2.787-.616a3.684 3.684 0 0 1-1.531-2.396v-.022c-.014-.074-.694-2.85-2.34-9.558"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M53.559 15.038a4.695 4.695 0 0 1-2.765-.763 4.546 4.546 0 0 1-1.416-1.611 4.417 4.417 0 0 1-.43-1.24v-.045c-.041-.184-.486-2.013-2.33-9.526l1.51-.371a2596.86 2596.86 0 0 1 2.334 9.535l.018.084c0 .04.02.103.038.178a2.932 2.932 0 0 0 1.176 1.718c.698.49 1.555.516 2.302.476 2.223-.113 6.061-.654 10.251-1.443l.287 1.53c-4.254.802-8.164 1.351-10.447 1.469-.182.002-.35.009-.529.009Zm-4.601-3.594v.026-.026Zm33.299 34.877H17.465a2.843 2.843 0 0 1-2.838-2.838V37.26a2.842 2.842 0 0 1 2.838-2.839h64.792a2.843 2.843 0 0 1 2.839 2.839v6.223a2.843 2.843 0 0 1-2.839 2.838ZM17.465 35.986a1.285 1.285 0 0 0-1.282 1.283v6.223a1.284 1.284 0 0 0 1.282 1.282h64.792a1.285 1.285 0 0 0 1.283-1.282v-6.223a1.285 1.285 0 0 0-1.283-1.283H17.465Zm60.085 77.741H21.91l-2.3-55.395 1.553-.064 2.24 53.903h52.661l2.396-54.06 1.554.068-2.463 55.548Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "m19.382 52.887-.338-8.122h61.563l-.343 7.742-1.553-.069.27-6.117H20.668l.268 6.502-1.553.064Zm30.48 53.23a4.146 4.146 0 0 1-4.14-4.141v-44.15a4.14 4.14 0 0 1 8.279 0v44.15a4.144 4.144 0 0 1-4.139 4.141Zm0-50.874a2.587 2.587 0 0 0-2.584 2.583v44.15a2.582 2.582 0 0 0 1.577 2.457 2.598 2.598 0 0 0 2.013 0 2.592 2.592 0 0 0 1.403-1.445 2.59 2.59 0 0 0 .174-1.012v-44.15a2.587 2.587 0 0 0-2.583-2.583Zm15.032 50.874a4.147 4.147 0 0 1-4.138-4.141v-44.15a4.139 4.139 0 1 1 8.277 0v44.15a4.144 4.144 0 0 1-4.139 4.141Zm0-50.874a2.587 2.587 0 0 0-2.582 2.583v44.15a2.581 2.581 0 0 0 4.409 1.826 2.582 2.582 0 0 0 .756-1.826v-44.15a2.587 2.587 0 0 0-2.583-2.583Zm-30.497 50.874a4.147 4.147 0 0 1-4.138-4.141v-44.15a4.139 4.139 0 1 1 8.277 0v44.15a4.144 4.144 0 0 1-4.139 4.141Zm0-50.874a2.587 2.587 0 0 0-2.582 2.583v44.15a2.581 2.581 0 0 0 4.409 1.826 2.582 2.582 0 0 0 .756-1.826v-44.15a2.587 2.587 0 0 0-2.583-2.583Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M26.087 59.224H15.37a3.644 3.644 0 1 1 0-7.284h10.717v7.284ZM15.37 53.496a2.086 2.086 0 1 0 0 4.172h9.161v-4.172H15.37Zm69.041 5.728h-10.72V51.94h10.72a3.643 3.643 0 0 1 0 7.284Zm-9.164-1.556h9.164a2.087 2.087 0 0 0 0-4.172h-9.164v4.172ZM84.82 29.5a.657.657 0 0 1-.593-.36.667.667 0 0 1-.042-.509l1.74-5.425a.668.668 0 0 1 1.272.407l-1.743 5.425a.667.667 0 0 1-.633.462Zm5.552 4.197a.667.667 0 0 1-.369-1.223l4.743-3.156a.67.67 0 0 1 .925.187.666.666 0 0 1-.187.924l-4.743 3.157a.667.667 0 0 1-.369.11Zm-1.318-3.679a.668.668 0 0 1-.529-1.071l3.452-4.532a.668.668 0 0 1 1.063.81l-3.454 4.531a.667.667 0 0 1-.532.262Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#FDE5F0",
  d: "M30.804 92.758c5.537-5.537 5.537-14.514 0-20.051-5.537-5.537-14.514-5.537-20.051 0-5.537 5.537-5.537 14.514 0 20.05 5.537 5.538 14.514 5.538 20.051 0Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M20.777 97.689a14.956 14.956 0 1 1 14.959-14.956 14.972 14.972 0 0 1-14.959 14.956Zm0-28.357a13.4 13.4 0 1 0 13.403 13.4 13.416 13.416 0 0 0-13.403-13.4Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#EE0064",
  d: "M27.704 87.335 16.801 77.089a1.317 1.317 0 1 0-1.804 1.92L25.9 89.254a1.317 1.317 0 0 0 1.803-1.92Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#EE0064",
  d: "m25.827 76.56-10.6 10.511a1.334 1.334 0 0 0 1.879 1.895l10.6-10.511a1.334 1.334 0 0 0-1.88-1.895Z"
}));
export default SvgDeleteDocument;

import Dropdown, { POSITIONS } from "@shared/ui/DropDown";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { NotFoundComponent } from "~/components/_notFoundMessage";
import DropdownFooter from "~/components/filters/_dropdownFooter";
import DropdownHeader from "~/components/filters/_dropdownHeader";
import { useAuth } from "~/components/general";
import { TickIcon, NoUserAssignedIcon } from "~/icons/icons";
import { UserType } from "~/types/user";

const AssetType = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  activeFilters,
  selectValues,
  handleSelectDeleteAll,
  dropDownPosition = POSITIONS.LEFT,
}) => {
  const { user } = useAuth() as { user: UserType };
  const { messages } = useIntl();

  const assetTypes = useMemo(
    () => user?.oem?.assetTypes || [],
    [user?.oem?.assetTypes],
  );

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
      position={dropDownPosition}
    >
      <DropdownHeader
        title={title}
        searchable={false}
        unselectFilter={unselectFilter}
      />
      {!assetTypes?.length ? (
        <NotFoundComponent
          className="flex flex-col px-2xl py-lg items-center space-y-2.5"
          icon={<NoUserAssignedIcon />}
          title={messages["hierarchy"]["assetTypes"]["noAssetType"]}
        />
      ) : (
        <div className="filter-facility-name filter-items">
          {assetTypes?.map((item, index) => (
            <div
              className="filter-facility-name-item-container"
              key={index}
              onClick={() => selectValues(filterItem, item?._id)}
            >
              <span
                className={`break-all filter-facility-name-item ${
                  activeFilters[filterItem.id].includes(item?._id)
                    ? "active"
                    : ""
                }`}
              >
                {item.name}
              </span>
              {activeFilters[filterItem.id].includes(item?._id) && (
                <span className="active">
                  <TickIcon />
                </span>
              )}
            </div>
          ))}
        </div>
      )}
      <DropdownFooter
        deleteAll={() => handleSelectDeleteAll(filterItem, false)}
        disabled={!activeFilters[filterItem.id]?.length}
      />
    </Dropdown>
  );
};

export default AssetType;

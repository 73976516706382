import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search.toString());
}

const ForgotPasswordSuccessForm = ({ callback = () => {} }) => {
  const [loading, setLoading] = useState(false);
  let query = useQuery();
  const history = useHistory();
  const { messages: msg } = useIntl();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: query.get("email"),
    },
  });

  const onSubmit = (data) => {
    setLoading(false);
    callback(data);
  };

  const onError = () => {
    setLoading(false);
  };

  const handleThankyouClick = () => {
    history.replace("/");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
      <div className="form-group auth-submit auth-submit--noMargin">
        <input hidden name="email" {...register("email", { required: true })} />
        <span
          className="c-button c-button--primary primary-icon-btn-v2 c-button--noHover u-cursor-pointer"
          onClick={handleThankyouClick}
        >
          {msg?.login?.loginLabel}
        </span>
        <div className="flex items-center mt-md">
          <BodyText size={BODY_TEXT_SIZES.SMALL} color="text-secondary">
            {msg?.login?.forgotPassword.resetSuccess.resend.didnotGet}&nbsp;
          </BodyText>
          <Button
            type="submit"
            variant={BUTTON_VARIANTS.LINK}
            text={
              loading
                ? `${msg?.login?.forgotPassword.resetSuccess.resend.resendEmailLoading}`
                : `${msg?.login?.forgotPassword.resetSuccess.resend.resendEmail}`
            }
            onClick={() => {
              setLoading(true);
              handleSubmit(onSubmit, onError);
            }}
          />
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordSuccessForm;

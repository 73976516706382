import TextEditor from "@shared/ui/TextEditor";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useIntl as useInternationalization } from "react-intl";
import { toast } from "react-toastify";

import useAuth from "~/components/general/_use-auth";
import { useUpdateAiNoteSummary } from "~/services";
import { UserType } from "~/types/user";

const Summary = ({
  isSelfCreated,
  noteId,
  setCopyEditorContent,
  summary,
}: {
  isSelfCreated: boolean;
  noteId: string;
  setCopyEditorContent: Dispatch<SetStateAction<() => void>>;
  summary: string;
}) => {
  const { user } = useAuth() as { user: UserType };
  const { messages } = useInternationalization();
  const tempSummary = useRef("");
  const [isFocused, setIsFocused] = useState(false);

  const { updateSummary } = useUpdateAiNoteSummary();

  const handleUpdateSummary = (updatedMessage: string) => {
    updateSummary({
      id: noteId,
      summary: updatedMessage || "",
    })
      .then(() => {
        toast(<Toast message={messages["aiNotetaker"]["note-updated"]} />, {
          closeButton: false,
        });
      })
      .catch((error) => {
        toast(
          <Toast
            message={
              error?.message || messages["aiNotetaker"]["note-update-failed"]
            }
            type={TOAST_TYPES.ERROR}
          />,
          {
            closeButton: false,
          },
        );
      })
      .finally(() => {
        setIsFocused(false);
      });
  };

  return (
    <>
      {isFocused ? (
        <div className="flex flex-col">
          <TextEditor
            keyId="notetaker-summary-open"
            className="border-brand mb-md bg-primary"
            content={summary}
            placeholder={messages["aiNotetaker"]["drawer-summary-placeholder"]}
            showActionButtons
            showCheckbox={false}
            handleClose={() => setIsFocused(false)}
            onUpdate={(newValue) => {
              tempSummary.current = newValue;
            }}
            handleSave={() => {
              const updatedMessage = tempSummary.current
                ? JSON.stringify(tempSummary.current)
                : summary;
              handleUpdateSummary(updatedMessage);
            }}
            autoFocus
            maxHeight="max-h-[380px]"
          />
        </div>
      ) : (
        <TextEditor
          setCopyHandler={(copyFn) => setCopyEditorContent(() => copyFn)}
          content={summary}
          keyId="notetaker-summary-closed"
          className="description-wrapper"
          placeholder={messages["aiNotetaker"]["drawer-summary-placeholder"]}
          readOnly
          onUpdate={() => {
            user && isSelfCreated && setIsFocused(true);
          }}
          maxHeight="max-h-[380px]"
        />
      )}
    </>
  );
};

export default Summary;

import { useMutation, useQuery } from "@apollo/client";

import {
  CREATE_REQUEST_MUTATION,
  GET_REQUEST_QUERY,
  LIST_REQUESTS_QUERY,
} from "~/api";
import client from "~/apollo/_client";
import { ITEMS_PER_PAGE } from "~/constants";
import {
  CREATE_REQUEST,
  GET_REQUEST,
  LIST_REQUESTS,
} from "~/services/request/constants";
import type { RequestType } from "~/types/request";

export const useCreateRequest = () => {
  const [create, { error, loading }] = useMutation<
    { [CREATE_REQUEST]: RequestType["_id"] },
    {
      input: { assetId: string; title: string; ticketType: string };
    }
  >(CREATE_REQUEST_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_REQUESTS,
      });
      cache.gc();
    },
  });

  return {
    createRequest: create,
    error,
    loading,
  };
};

export const clearRequestCache = () => {
  client.cache.evict({
    id: "ROOT_QUERY",
    fieldName: LIST_REQUESTS,
  });
  client.cache.gc();
};

export const useListRequests = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  closed = false,
  searchQuery = "",
  skipCondition = false,
}: {
  limit?: number;
  skip?: number;
  closed?: boolean;
  searchQuery?: string;
  skipCondition?: boolean;
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      where: {
        searchQuery,
        closed,
      },
    },
  };

  const { data, loading, refetch, fetchMore } = useQuery<
    {
      [LIST_REQUESTS]: {
        currentPage: number;
        limit: number;
        skip: number;
        totalCount: number;
        requests: RequestType[];
      };
    },
    {
      params: {
        limit: number;
        skip: number;
        where: {
          searchQuery: string;
        };
      };
    }
  >(LIST_REQUESTS_QUERY, {
    variables,
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    requests = [],
  } = data?.[LIST_REQUESTS] ?? {
    currentPage: 0,
    limit: 0,
    skip: 0,
    totalCount: 0,
    requests: [],
  };

  const handleFetchMore = ({
    limit,
    skip,
  }: {
    limit: number;
    skip: number;
  }): void => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          where: {
            searchQuery,
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedRequests = [
          ...previousResult[LIST_REQUESTS].requests,
          ...fetchMoreResult[LIST_REQUESTS].requests,
        ];

        return {
          ...previousResult,
          listRequests: {
            ...previousResult[LIST_REQUESTS],
            ...fetchMoreResult[LIST_REQUESTS],
            requests: mergedRequests,
          },
        };
      },
    });
  };

  return {
    requests,
    handleFetchMore,
    refetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
  };
};

export const useGetRequestById = ({
  id,
  skipCondition = false,
}: {
  id: string;
  skipCondition?: boolean;
}) => {
  const { data, error, loading } = useQuery<
    { [GET_REQUEST]: RequestType },
    { requestId: string }
  >(GET_REQUEST_QUERY, {
    variables: {
      requestId: id,
    },
    skip: !id || skipCondition,
  });

  return {
    error,
    loading,
    request: data?.[GET_REQUEST],
  };
};

import { bottom } from "@popperjs/core";
import AiSparklesIcon from "@shared/svg/ai-sparkles.svg?react";
import DeleteIcon from "@shared/svg/delete-document.svg?react";
import InternalMachineHistoryNoteIcon from "@shared/svg/internal-machine-history-note.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { ExternalIcon, EXTERNAL_ICON_SIZES } from "@shared/ui/Indicators";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import Overlay from "@shared/ui/Overlay";
import Tag, { TAG_COLORS } from "@shared/ui/Tag";
import TextEditor from "@shared/ui/TextEditor";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { isArray } from "lodash";
import moment from "moment-timezone";
import {
  ArrowsInSimple,
  ArrowsOutSimple,
  DotsThree,
  Plus,
} from "phosphor-react";
import React, { Fragment, useMemo, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Waypoint } from "react-waypoint";
import { useRecoilState } from "recoil";

import COLOR_PALETTE from "$/settings/color-palette.json";
import HISTORY_TYPES from "$/settings/enums/history/machineHistory.json";
import { imagePreviewAtom } from "~/atoms/_chat";
import EmptySpace from "~/components/_emptySpace";
import { PopupMenuV2 } from "~/components/_popupMenu";
import { useAuth } from "~/components/general";
import WorkOrderTypeIcons from "~/components/tickets/WorkOrderTypeIcons";
import {
  COLOR,
  DATE_FORMAT_DD_MM_YYYY,
  ITEMS_BEFORE_PAGE,
  ITEMS_PER_PAGE,
  TIME_FORMAT,
  WORK_ORDER_TYPE_ICONS,
} from "~/constants";
import { MachineHistoryLoading } from "~/containers/machine/loader/MachineHistoryLoading";
import { useUploadFiles } from "~/hooks";
import { useRoleManager } from "~/hooks/_useRoleManager";
import { PdfColoredIcon, SidebarMachinesIcon } from "~/icons/icons";
import {
  useCreateMachineHistoryNote,
  useDeleteMachineHistoryNote,
  useUpdateMachineHistoryNote,
  useListOwnOemMachineHistory,
  useListOwnOemSharedAssetHistory,
} from "~/services/asset";
import { getEnums } from "~/utils";
import { generateStatusBackgroundColor } from "~/utils/_colors";
import { trimText } from "~/utils/_initials";

const HISTORY_TYPES_ENUMS = getEnums(HISTORY_TYPES, "reference");

export const MachineHistory = ({ machine, isSharedAsset = false }) => {
  const { messages } = useIntl();
  const { user } = useAuth();
  const { months } = messages["common"];
  const noteText = useRef("");
  const deleteNoteId = useRef("");
  const [showAddNote, setShowAddNote] = useState(false);
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const fileUploader = useUploadFiles();

  const { mutation: createMachineHistoryNote } = useCreateMachineHistoryNote();
  const { removeHistoryNote, loading: deletingNote } =
    useDeleteMachineHistoryNote();

  const { history, ticketCount, loading, totalCount, handleFetchMore } = (
    isSharedAsset
      ? useListOwnOemSharedAssetHistory
      : useListOwnOemMachineHistory
  )({
    limit: ITEMS_PER_PAGE,
    skip: 0,
    where: {
      machine: machine?._id ?? null,
    },
  });

  const handleScrollBottom = (event) => {
    if (
      history.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above" &&
      totalCount > history.length
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: history.length,
      });
    }
  };

  const objectIdsToShowMonthWith = useMemo(() => {
    let currentMonth = -1;
    let currentYear = -1;

    return history
      .filter((item) => {
        if (item.month !== currentMonth || item.year !== currentYear) {
          currentMonth = item.month;
          currentYear = item.year;
          return true;
        }
        return false;
      })
      .map((item) => item._id);
  }, [history]);

  const processAttachment = async (attachments) => {
    const toastId = "uploading-attachments";
    toast(<Toast message={messages?.procedures.uploadingFieldAttachments} />, {
      closeButton: false,
      autoClose: false,
      toastId,
    });
    const files = [...attachments];

    if (!files || !isArray(files)) return [];
    const list = [];

    for await (const file of files) {
      if (file?.url) {
        list.push(file);
        continue;
      }

      try {
        const fileObj = { name: file.name, size: file.size, type: file.type };
        const uploadData = {
          folder: `oem/${machine?.oem?.slug}/machine/${
            machine?.slug ?? machine.serialNumber
          }/note`,
          files: [file],
          forceCustomPath: true,
        };

        const { urls } = await fileUploader(uploadData, false);
        if (urls[0]) {
          fileObj["url"] = urls[0];
          list.push(fileObj);
        }
      } catch (error) {
        //
      }
    }

    toast.update(toastId, {
      render: <Toast message={messages?.common.upload.success} />,
      autoClose: true,
    });

    return list;
  };

  const handleHistorySave = async (attachments, isCheckboxChecked) => {
    try {
      setIsSavingNote(true);
      let attachmentLinks = [];
      if (attachments.length) {
        attachmentLinks = await processAttachment(attachments);
      }
      await createMachineHistoryNote({
        note: {
          message: JSON.stringify(noteText.current),
          attachments: attachmentLinks,
          isInternal: !isCheckboxChecked,
        },
        machine: machine?._id,
      });
      setShowAddNote(false);
      toast(
        <Toast
          message={
            messages?.machines?.machineDetails.historyTab.noteMessages
              .createSuccess
          }
        />,
        {
          closeButton: false,
        },
      );
    } catch (error) {
      toast(<Toast message={error.message} type={TOAST_TYPES.ERROR} />, {
        closeButton: false,
      });
    } finally {
      setIsSavingNote(false);
    }
  };

  const getUrl = (item, isSharedAsset) => {
    switch (item.type) {
      case HISTORY_TYPES_ENUMS.FormSubmission:
        return item.url;
      case HISTORY_TYPES_ENUMS.Procedure:
        return isSharedAsset
          ? null
          : `/app/work/work-orders/${item?.ticket?._id}/procedure/${item?.resourceId}`;
      default:
        return isSharedAsset
          ? `/app/asset-hub/requests/${item?.resourceId}`
          : `/app/work/work-orders/${item?.resourceId}`;
    }
  };

  const prepareType = (history) => {
    switch (history.type) {
      case HISTORY_TYPES_ENUMS.Procedure:
        return {
          icon: WORK_ORDER_TYPE_ICONS.PROCEDURE,
          color: COLOR_PALETTE.red.primary,
          isInternal: !!history.ticket?.ticketType?.isInternal,
        };

      case HISTORY_TYPES_ENUMS.FormSubmission:
        return {
          icon: WORK_ORDER_TYPE_ICONS.FORM_SUBMISSION,
          color: COLOR_PALETTE.gray.primary,
          isInternal: history.ticket?.ticketType?.isInternal,
        };

      default:
        return (
          user?.oem?.ticketTypes?.find(
            (ticketType) => history.resource?.ticketType === ticketType._id,
          ) || {}
        );
    }
  };

  const handleDeleteNote = async () => {
    try {
      await removeHistoryNote(deleteNoteId.current, machine._id);
      toast(
        <Toast
          message={
            messages?.machines?.machineDetails.historyTab.noteMessages
              .deleteSuccess
          }
        />,
        {
          closeButton: false,
        },
      );
    } catch (error) {
      toast(<Toast message={error.message} type={TOAST_TYPES.ERROR} />, {
        closeButton: false,
      });
    } finally {
      deleteNoteId.current = "";
      setShowDeleteAlert(false);
    }
  };

  if (loading && !history.length) {
    return <MachineHistoryLoading />;
  }

  return (
    <>
      {!isSharedAsset && (
        <div className="u-flex u-width-100 u-margin-b-2">
          <div
            className={`add-machine-note-button ${
              !showAddNote ? "" : "u-border-color-blue-v2-100"
            }`}
          >
            <Plus size={16} className="u-text-color-gray-v2-60 u-margin-auto" />
          </div>
          <div className="add-machine-note-text-editor-container">
            {!showAddNote ? (
              <div
                className="add-machine-note-placeholder-container u-cursor-pointer"
                onClick={() => setShowAddNote(true)}
              >
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                  {messages.machines.machineDetails.historyTab.addNote}
                </BodyText>
              </div>
            ) : (
              <>
                <div className="u-width-100 u-relative">
                  <TextEditor
                    keyId="add-note-detail"
                    placeholder={
                      messages.machines.machineDetails.historyTab.addNote
                    }
                    className="flex flex-col-reverse w-full"
                    toolbarTopBorder
                    showAttachmentButton
                    showActionButtons
                    autoFocus={true}
                    onUpdate={(newValue) => (noteText.current = newValue)}
                    handleClose={() => setShowAddNote(false)}
                    isSaving={isSavingNote}
                    handleSave={handleHistorySave}
                    checkboxLabel={
                      messages?.machines?.machineDetails.historyTab
                        .showToFacility
                    }
                    defaultCheckboxValue={true}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <p className="c-infoNote text-sm">
        {ticketCount || 0} {messages["hierarchy"]["assets"]["ticketsRelated"]}
      </p>
      {history.map((item, index) => {
        const url = getUrl(item, isSharedAsset);
        const status =
          user?.oem?.statuses?.find(
            (statusItem) => item?.resource?.status === statusItem._id,
          ) || {};
        const type = prepareType(item);
        return (
          <Fragment key={item._id}>
            <div className="month-wise-ticket-wrapper" key={index}>
              {objectIdsToShowMonthWith.includes(item._id) && (
                <div className="absolute top-0 left-0 flex flex-col justify-center w-7 py-2xs bg-primary">
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    className="text-center"
                  >
                    {months[item?.month]}
                  </BodyText>{" "}
                  <Label
                    size={LABEL_SIZES.X_SMALL}
                    color="text-secondary"
                    className="text-center w-full font-medium"
                  >
                    {item?.year}
                  </Label>
                </div>
              )}
              {[HISTORY_TYPES_ENUMS.AI, HISTORY_TYPES_ENUMS.Note].includes(
                item.type,
              ) ? (
                <HistoryNoteItem
                  item={item}
                  msg={messages}
                  onDelete={() => {
                    deleteNoteId.current = item._id;
                    setShowDeleteAlert(true);
                  }}
                  processAttachment={processAttachment}
                  machine={machine}
                />
              ) : (
                <MachineHistoryItem
                  item={item}
                  status={status}
                  type={type}
                  url={url}
                  isSharedAsset={isSharedAsset}
                  machine={machine}
                />
              )}
            </div>
            {!loading &&
              totalCount > history.length &&
              index === history.length - ITEMS_BEFORE_PAGE && (
                <Waypoint onEnter={handleScrollBottom} />
              )}
          </Fragment>
        );
      })}
      {history.length > 0 && loading && <MachineHistoryLoading />}
      {totalCount === history.length && (
        <>
          <div className="month-wise-ticket-wrapper machine-info">
            <div className="absolute top-0 left-0 flex flex-col justify-center w-7 bg-primary">
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="text-center">
                {months[new Date(machine?.createdAt).getMonth()]}
              </BodyText>{" "}
              <Label
                size={LABEL_SIZES.X_SMALL}
                color="text-secondary"
                className="text-center w-full font-medium"
              >
                {new Date(machine?.createdAt).getFullYear()}
              </Label>
            </div>
            <Link to={"/app/"} className={""}>
              <div className="ticket-machine-info">
                <span className="machine-icon">
                  <SidebarMachinesIcon />
                </span>
                <span>
                  {messages["hierarchy"]["assets"]["creationDate"]} &nbsp;
                </span>{" "}
                <span className="machine-creation-date">
                  {moment(machine?.createdAt).format("DD.MM.YYYY")}
                </span>
              </div>
            </Link>
          </div>
        </>
      )}

      <AlertBox
        title={
          messages?.machines?.machineDetails.historyTab.noteMessages.deleteAlert
            .title
        }
        description={
          messages?.machines?.machineDetails.historyTab.noteMessages.deleteAlert
            .message
        }
        isOpen={showDeleteAlert}
        acceptButtonText={messages?.common?.delete}
        acceptButtonVariant={BUTTON_VARIANTS.DANGER}
        cancelButtonText={messages?.common?.cancel}
        disableAcceptButton={deletingNote}
        image={<DeleteIcon width="130" height="130" />}
        onAccept={handleDeleteNote}
        onCancel={() => setShowDeleteAlert(false)}
        overlay={true}
      />
    </>
  );
};

const MachineHistoryItem = ({
  item,
  status,
  url,
  type = {},
  machine,
  isSharedAsset = false,
}) => {
  const { messages } = useIntl();
  const [isHovered, setIsHovered] = useState(false);

  const iconStyles = useMemo(() => {
    return {
      backgroundColor: isHovered
        ? type.color
        : generateStatusBackgroundColor(type?.color),
    };
  }, [isHovered, type.color]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Link
      to={url}
      className="group"
      onClick={(e) => {
        if (
          (item?.type === HISTORY_TYPES_ENUMS.FormSubmission ||
            (item?.type === HISTORY_TYPES_ENUMS.Procedure && isSharedAsset)) &&
          (item?.resource.url || item?.resource.pdfUrl)
        ) {
          e.preventDefault();
          window.open(item?.resource.url || item?.resource.pdfUrl, "_blank");
        }
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="ticket-info-content">
        <span className="ticket-icon history" style={iconStyles}>
          <WorkOrderTypeIcons
            size={16}
            icon={type.icon}
            strokeColor={isHovered ? COLOR.$white_v2 : type.color}
          />
          {type.isInternal ? null : (
            <ExternalIcon
              size={EXTERNAL_ICON_SIZES.SMALL}
              className="absolute -top-[1px] -right-3xs"
            />
          )}
        </span>
        <div className="ticket-info">
          <Headline size={HEADLINE_SIZES.X_SMALL}>
            {trimText(item?.resource?.title ?? item?.resource?.name)}
          </Headline>
          {item?.type === HISTORY_TYPES_ENUMS.FormSubmission ? (
            <div className="ticket-meta">
              <span className="meta">
                <span className="meta-label">{item?.customer?.name}</span>
              </span>
              <span className="seperator"></span>
              <span className="meta">
                <span className="meta-label">{machine?.name}</span>
              </span>
              <span className="seperator"></span>
              <span className="meta">
                <span className="meta-label">{machine?.serialNumber}</span>
              </span>
            </div>
          ) : (
            <div className="ticket-meta flex-col">
              {item?.type === HISTORY_TYPES_ENUMS.Procedure && (
                <div className="flex w-full">
                  <span className="meta">
                    <span className="meta-label">
                      {messages?.facilities?.id}: {item?.resource.procedureId}
                    </span>
                    <span className="seperator"></span>
                    <div className="meta-label">
                      {messages?.procedures?.finalizedText}:{" "}
                      {moment(item?.resource.updatedAt).format(
                        DATE_FORMAT_DD_MM_YYYY,
                      )}
                    </div>
                  </span>
                </div>
              )}

              <div className="flex w-full">
                {" "}
                <span className="meta">
                  <span className="meta-label">
                    #{item?.resource?.id ?? item?.ticket?.ticketId}
                    &nbsp;
                  </span>
                  <span className="seperator"></span>
                  <span className="meta-label">
                    {item?.customer?.name ?? item?.ticket?.title}
                  </span>

                  {item.type !== HISTORY_TYPES_ENUMS.Procedure && (
                    <>
                      {" "}
                      <span className="seperator"></span>{" "}
                      <div className="meta-label">{machine?.name}</div>
                    </>
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      {item?.type !== HISTORY_TYPES_ENUMS.Procedure ? (
        <Tag
          label={status?.label}
          style={{
            color: isHovered ? COLOR.$white_v2 : status.color,
            backgroundColor: isHovered
              ? status.color
              : generateStatusBackgroundColor(status.color),
          }}
        />
      ) : (
        <Tag
          className={item?.status}
          label={item?.resource?.state}
          color={TAG_COLORS.GREEN}
        />
      )}
    </Link>
  );
};

const HistoryNoteItem = ({
  msg,
  item,
  machine,
  onDelete = () => {},
  processAttachment = async () => {},
}) => {
  const { messages } = useIntl();

  const [showMenu, setShowMenu] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [isEditorScrolling, setIsEditorScrolling] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAiNoteOvrelay, setShowAiNoteOvrelay] = useState(false);
  const [imagePreviewOptions, setImagePreview] =
    useRecoilState(imagePreviewAtom);

  const { user } = useAuth();
  const { isOemAdmin } = useRoleManager(user?.role);
  const isSelfCreated = item?.createdBy?._id === user._id;

  const { mutation: updateMachineHistoryNote } = useUpdateMachineHistoryNote();

  const noteText = useRef("");
  const aiIconRef = React.useRef();

  const onActionMenuClose = () => {
    setShowMenu(false);
  };

  const handleHistoryUpdate = async (
    updatedMessage,
    attachments = [],
    isInternal,
  ) => {
    try {
      setIsSavingNote(true);
      let attachmentLinks = [];
      const newAddedAttachments =
        attachments.filter((attachment) => !!attachment.path) ?? [];
      if (newAddedAttachments.length) {
        attachmentLinks = await processAttachment(
          attachments.filter((attachment) => !!attachment.path) ?? [],
        );
      }
      await updateMachineHistoryNote({
        note: {
          message: updatedMessage,
          attachments: [
            ...(attachments
              .filter((attachment) => !!attachment.url)
              .map(({ name, size, type, url }) => ({
                name,
                size,
                type,
                url,
              })) ?? []),
            ...attachmentLinks,
          ],
          isInternal,
        },
        machine: machine?._id,
        _id: item._id,
      });

      toast(
        <Toast
          message={
            msg?.machines?.machineDetails.historyTab.noteMessages.updateSuccess
          }
        />,
        {
          closeButton: false,
        },
      );
      setIsEditMode(false);
    } catch (error) {
      toast(<Toast message={error.message} type={TOAST_TYPES.ERROR} />, {
        closeButton: false,
      });
    } finally {
      setIsSavingNote(false);
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEditMode(true);
    setShowMenu(false);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onDelete();
    setShowMenu(false);
  };

  return (
    <>
      {!isEditMode ? (
        <div className="history-note-item-container">
          <div className="history-note-read-mode !gap-md">
            <span className="ticket-icon history">
              <InternalMachineHistoryNoteIcon className="h-3xl w-3xl m-auto" />
              {!item.note.isInternal && (
                <ExternalIcon
                  size={EXTERNAL_ICON_SIZES.SMALL}
                  className="absolute -top-[1px] -right-3xs"
                />
              )}
            </span>
            <div className="history-note-read-mode-details-container">
              <div className="flex items-center w-full">
                <div className="flex items-center">
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    color="text-secondary"
                  >
                    {moment(item.createdAt).format(DATE_FORMAT_DD_MM_YYYY)}{" "}
                    &nbsp;&#8226;&nbsp;
                    {moment(item.createdAt).format(TIME_FORMAT)}
                    &nbsp;&#8226;&nbsp;
                    {item.createdBy?.name}
                  </BodyText>
                </div>
                <div className="flex items-center ml-auto">
                  {isEditorScrolling && (
                    <>
                      {isExpanded ? (
                        <ArrowsInSimple
                          size={16}
                          className="u-text-color-gray-v2-60 u-margin-r-3 u-cursor-pointer"
                          onClick={() => setIsExpanded(false)}
                        />
                      ) : (
                        <ArrowsOutSimple
                          size={16}
                          className="u-text-color-gray-v2-60 u-margin-r-3 u-cursor-pointer"
                          onClick={() => setIsExpanded(true)}
                        />
                      )}
                    </>
                  )}
                  {item.type === HISTORY_TYPES_ENUMS.AI && (
                    <>
                      <div
                        onMouseEnter={() => setShowAiNoteOvrelay(true)}
                        onMouseLeave={() => setShowAiNoteOvrelay(false)}
                        ref={aiIconRef}
                        className="flex"
                      >
                        <AiSparklesIcon className="h-3xl w-3xl" />
                      </div>
                      {showAiNoteOvrelay && (
                        <Overlay
                          arrowClasses="before:bg-secondary before:shadow-xl"
                          className="bg-secondary rounded-md py-2xs px-sm shadow-xl w-fit z-10 max-w-96xl"
                          containerRef={document.body}
                          placement={bottom}
                          offset={[0, 8]}
                          showOverlay={showAiNoteOvrelay}
                          targetRef={aiIconRef}
                        >
                          <div
                            className="relative"
                            onMouseEnter={() => setShowAiNoteOvrelay(true)}
                            onMouseLeave={() => setShowAiNoteOvrelay(false)}
                          >
                            <BodyText
                              className="!text-inverse"
                              size={BODY_TEXT_SIZES.X_SMALL}
                            >
                              {
                                messages["aiNotetaker"][
                                  "history-note-overlay-text"
                                ]
                              }
                            </BodyText>
                          </div>
                        </Overlay>
                      )}
                    </>
                  )}
                  {(isOemAdmin || isSelfCreated) && (
                    <div
                      className="flex ml-auto cursor-pointer"
                      onClick={() => {
                        setShowMenu(true);
                      }}
                    >
                      <DotsThree size={20} />
                      {showMenu && (
                        <div className="u-relative">
                          <span className="parts-popup__container">
                            <PopupMenuV2
                              onDeleteClick={handleDeleteClick}
                              onEditClick={handleEditClick}
                              editLabel={
                                msg?.machines.machineDetails.historyTab.editNote
                              }
                              deleteLabel={
                                msg?.machines.machineDetails.historyTab
                                  .deleteNote
                              }
                              labelClassName="u-none"
                              close={onActionMenuClose}
                              showEditButton={isOemAdmin || isSelfCreated}
                              showDeleteButton={isOemAdmin || isSelfCreated}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <TextEditor
                keyId="note-detail-closed"
                mainEditorClassName={`${
                  isExpanded ? "max-h-max" : "overflow-y-hidden"
                }`}
                placeholder={
                  item?.note.attachments?.length > 0
                    ? " "
                    : msg.machines.machineDetails.historyTab.addNote
                }
                content={item?.note?.message ?? ""}
                onUpdate={() => {}}
                readOnly
                setIsScrolling={setIsEditorScrolling}
              />
              {item?.note.attachments?.length > 0 && (
                <>
                  <EmptySpace size="16px" />
                  <div className="history-note-read-mode-attachments-container">
                    {item?.note.attachments.map((attachment) => {
                      return (
                        <Fragment key={attachment.url}>
                          {attachment.type.includes("pdf") ? (
                            <div
                              className="history-note-read-mode-pdf-attachment u-cursor-pointer"
                              onClick={() =>
                                window.open(attachment.url, "_blank")
                              }
                            >
                              <div className="icon-wrapper">
                                <PdfColoredIcon className="icon" />
                              </div>
                              <Label
                                size={LABEL_SIZES.X_SMALL}
                                color="text-secondary"
                                className="font-medium truncate mx-2xs"
                              >
                                {attachment.name}
                              </Label>
                            </div>
                          ) : (
                            <img
                              src={attachment.url}
                              className="history-note-read-mode-image-attachment u-cursor-pointer"
                              onClick={(e) => {
                                setImagePreview({
                                  ...imagePreviewOptions,
                                  openedImage: e.target.src,
                                  isOpen: true,
                                });
                              }}
                            />
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="u-width-100">
            <div className="u-width-100 u-relative">
              <TextEditor
                keyId="edit-note-detail"
                placeholder={msg.machines.machineDetails.historyTab.addNote}
                className="flex flex-col-reverse w-full"
                toolbarTopBorder
                showAttachmentButton
                showActionButtons
                autoFocus={true}
                content={item?.note?.message ?? ""}
                defaultAttachments={item?.note.attachments}
                onUpdate={(newValue) => (noteText.current = newValue)}
                handleClose={() => setIsEditMode(false)}
                isSaving={isSavingNote}
                handleSave={async (attachments = [], isCheckboxChecked) => {
                  const updatedMessage = noteText.current
                    ? JSON.stringify(noteText.current)
                    : item?.note?.message;
                  await handleHistoryUpdate(
                    updatedMessage,
                    attachments,
                    !isCheckboxChecked,
                  );
                }}
                checkboxLabel={
                  msg?.machines?.machineDetails.historyTab.showToFacility
                }
                defaultCheckboxValue={!item?.note.isInternal}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

import * as React from "react";
const SvgCustomerPortalInviteAccept = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 131 149",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M49.484 17.355c2.587 1.194 3.697 2.126 5.097 4.27M96.316 14.602c-1.957.923-2.797 1.645-3.856 3.305M72.84 1.04c1.334 3.418 1.85 5.02 1.85 8.877M56.91 4.684c4.19 3.542 5.771 5.841 7.27 10.536M88.824 6.336c-2.186 4.504-4.561 5.65-6.234 10.286",
  stroke: "#172B4D",
  strokeWidth: 1.345,
  strokeLinecap: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M72.626 55.095c.065-.197 6.5-19.72 11.845-24.654 3.816-3.523.263 6.024-2.114 11.849-.028.067.03.088.06.022 2.816-6.154 8.315-17.085 11.972-17.381 2.328-.189.459 4.827-2.259 10.33.012-.02 8.459-13.908 10.523-9.228 1.027 2.328-3.276 8.396-6.474 14.325-1.803 3.345-3.266 6.642-4.207 8.905-.027.067.053.101.083.035 1.516-3.405 4.189-9.01 6.741-12.383 3.857-5.097 11.158-11.984 12.397-9.23.638 1.417-3.305 7.577-8.54 15.015-5.234 7.438-6.06 12.397-6.06 12.397s11.019-11.57 12.947-6.06c1.929 5.509-14.05 14.325-14.6 24.93-.53 10.181 5.541 17.063 6.029 17.599.02.02.016.05-.003.07L86.58 107.256a.055.055 0 0 1-.083-.002l-12.758-15.64a.055.055 0 0 1-.013-.032l-1.1-36.474c0-.007-.002-.005 0-.012Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M96.593 55.097s11.019-11.57 12.947-6.06c1.929 5.509-14.05 14.325-14.6 24.93-.53 10.181 5.541 17.063 6.029 17.599.02.02.016.05-.003.07L86.58 107.256a.055.055 0 0 1-.083-.002l-12.758-15.64a.055.055 0 0 1-.013-.032l-1.1-36.474c0-.007-.002-.005 0-.012.065-.197 6.5-19.72 11.845-24.654 3.816-3.523.263 6.024-2.114 11.849-.028.067.03.088.06.022 2.816-6.154 8.315-17.085 11.972-17.381 4.143-.336-5.004 15.804-8.571 21.854-.037.062-.107.021-.072-.042l6.383-11.48.001-.001c.012-.02 8.459-13.909 10.523-9.23 1.027 2.329-3.276 8.397-6.474 14.326-1.803 3.345-3.266 6.642-4.207 8.905-.027.067.053.101.083.035 1.516-3.405 4.189-9.01 6.741-12.383 3.857-5.097 11.158-11.984 12.397-9.23.638 1.417-3.305 7.577-8.54 15.015-5.234 7.438-6.06 12.397-6.06 12.397Zm0 0s-3.373 2.084-6.4 4.439c-3.028 2.354-3.656 5.045-3.656 5.045",
  stroke: "#172B4D",
  strokeWidth: 1.345
}), /* @__PURE__ */ React.createElement("path", {
  d: "M53.065 71.215c1.575 9.65-1.903 18.804-4.517 22.004a.054.054 0 0 0 .002.07l13.011 15.066c.023.026.059.032.08.005.85-1.095 16.56-21.406 18.973-29.983 2.48-8.815 3.857-27.962.826-28.1-3.03-.137-3.857 9.367-4.27 13.637-.38 3.932-3.213 4.828-3.66 4.945a.049.049 0 0 1-.063-.045c-.095-1.293-1.742-23.39-4.817-33.55-3.168-10.47-6.06-11.02-6.612-7.301-.35 2.36 1.964 15.208 3.701 24.28.014.072-.095.09-.111.018-.803-3.566-1.752-7.43-2.626-10.11-2.066-6.337-8.264-14.601-10.193-12.535-1.928 2.066 2.342 7.438 5.51 15.152 2.695 6.562 2.3 12.027 2.122 13.513-.008.064-.09.064-.097 0-.172-1.585-.897-7.536-2.438-11.447-1.79-4.546-8.403-11.708-9.78-9.091-1.377 2.617 3.581 8.402 4.959 12.397.993 2.88 1.628 8.335 1.905 11.157.007.07-.102.084-.113.014-.264-1.592-.684-3.828-1.103-4.835l-.022-.051c-.687-1.65-1.653-3.968-3.697-6.285-2.067-2.342-5.235-5.234-5.097-2.204.138 3.03 5.923 9.78 8.127 23.279Z",
  fill: "#fff",
  stroke: "#172B4D",
  strokeWidth: 1.345
}), /* @__PURE__ */ React.createElement("path", {
  d: "m46.108 90.871-4.721 5.232a.275.275 0 0 0-.003.366l16.987 19.413c.11.126.305.125.415 0l4.32-4.957a.275.275 0 0 0 .004-.358L46.523 90.878a.275.275 0 0 0-.416-.007Z",
  fill: "#E6E8FE",
  stroke: "#172B4D",
  strokeWidth: 1.345
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1.413 127.139c1.097-9.187 28.493-25.381 40.608-29.422a.265.265 0 0 1 .284.079l14.333 15.955a.278.278 0 0 1 .017.347c-8.087 11.161-16.483 15.58-26.04 19.102-16.371 6.034-30.304 3.168-29.202-6.061Z",
  fill: "#E6E8FE",
  stroke: "#172B4D",
  strokeWidth: 1.345
}), /* @__PURE__ */ React.createElement("mask", {
  id: "mask0_9329_18158",
  style: {
    maskType: "alpha"
  },
  maskUnits: "userSpaceOnUse",
  x: 1,
  y: 97,
  width: 56,
  height: 40
}, /* @__PURE__ */ React.createElement("path", {
  d: "M1.413 127.141c1.097-9.187 28.493-25.381 40.608-29.422a.265.265 0 0 1 .284.079l14.333 15.955a.276.276 0 0 1 .017.346c-8.087 11.162-16.483 15.58-26.04 19.103-16.371 6.034-30.304 3.168-29.202-6.061Z",
  fill: "#CDD1FE"
})), /* @__PURE__ */ React.createElement("g", {
  mask: "url(#mask0_9329_18158)",
  fill: "#172B4D"
}, /* @__PURE__ */ React.createElement("circle", {
  cx: 3.891,
  cy: 127.691,
  r: 2.617
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 17.391,
  cy: 136.232,
  r: 2.617
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 17.391,
  cy: 124.937,
  r: 2.617
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 20.145,
  cy: 111.162,
  r: 2.617
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 31.715,
  cy: 116.947,
  r: 2.617
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 47.418,
  cy: 122.457,
  r: 2.617
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 46.316,
  cy: 104.551,
  r: 2.617
})), /* @__PURE__ */ React.createElement("path", {
  d: "M101.437 86.335c1.894 2.866 19.798 29.967 22.651 34.377 3.013 4.658 11.475 20.748 0 26.161-19.179 9.047-42.003-39.269-43.531-42.563a.26.26 0 0 1 .074-.311l20.407-17.703c.125-.11.307-.1.399.04Z",
  fill: "#fff",
  stroke: "#172B4D",
  strokeWidth: 1.345
}), /* @__PURE__ */ React.createElement("mask", {
  id: "mask1_9329_18158",
  style: {
    maskType: "alpha"
  },
  maskUnits: "userSpaceOnUse",
  x: 80,
  y: 86,
  width: 50,
  height: 63
}, /* @__PURE__ */ React.createElement("path", {
  d: "M101.437 86.337c1.894 2.866 19.798 29.967 22.651 34.377 3.013 4.658 11.475 20.748 0 26.161-19.179 9.047-42.003-39.269-43.531-42.563a.26.26 0 0 1 .074-.311l20.407-17.703c.125-.11.307-.1.399.039Z",
  fill: "#E6E8FE"
})), /* @__PURE__ */ React.createElement("g", {
  mask: "url(#mask1_9329_18158)",
  stroke: "#172B4D",
  strokeWidth: 1.345
}, /* @__PURE__ */ React.createElement("path", {
  d: "M101.44 86.336c1.893 2.866 19.798 29.967 22.65 34.377 3.013 4.658 11.476 20.748 0 26.161-19.178 9.048-42.002-39.269-43.53-42.563-.052-.11-.019-.23.074-.31l20.406-17.704c.126-.11.308-.1.4.04Z",
  fill: "#E6E8FE"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M83.922 101.793s7.57 15.425 14.05 24.243c6.919 9.416 20.386 21.901 20.386 21.901M89.434 96.144s8.115 16.393 15.061 25.763c7.417 10.007 21.854 23.275 21.854 23.275M96.043 90.637s8.115 16.392 15.061 25.762c7.418 10.007 21.854 23.275 21.854 23.275M99.898 87.33s8.116 16.392 15.062 25.763c7.417 10.006 21.854 23.274 21.854 23.274"
})), /* @__PURE__ */ React.createElement("path", {
  d: "m28.177 36.24.859.55a2.05 2.05 0 0 0 2.21 0l.86-.55c.636-.407 1.38.337.973.974l-.55.86a2.05 2.05 0 0 0 0 2.21l.55.859c.407.636-.337 1.38-.973.973l-.86-.55a2.05 2.05 0 0 0-2.21 0l-.86.55c-.636.408-1.38-.337-.973-.973l.55-.86a2.05 2.05 0 0 0 0-2.21l-.55-.86c-.407-.636.337-1.38.974-.973ZM112.207 9.354l.389 1.197a1.5 1.5 0 0 0 1.426 1.036h1.259c.051 0 .077.014.093.027a.156.156 0 0 1 .053.08c.012.036.012.07.004.095a.145.145 0 0 1-.059.076l-1.019.74a1.498 1.498 0 0 0-.545 1.677l.389 1.197c.016.048.011.078.004.097a.166.166 0 0 1-.059.075.168.168 0 0 1-.09.033c-.021 0-.05-.003-.091-.033l-1.019-.74a1.498 1.498 0 0 0-1.762 0l-1.019.74a.144.144 0 0 1-.091.033.166.166 0 0 1-.09-.033.166.166 0 0 1-.059-.075.145.145 0 0 1 .003-.097l.389-1.197a1.5 1.5 0 0 0-.544-1.677l-1.019-.74a.143.143 0 0 1-.06-.076.166.166 0 0 1 .004-.096.17.17 0 0 1 .053-.079.143.143 0 0 1 .093-.027h1.26c.649 0 1.225-.418 1.425-1.036l.39-1.197a.14.14 0 0 1 .054-.08.162.162 0 0 1 .092-.027c.038 0 .07.012.092.026.017.012.038.033.054.08Z",
  fill: "#CDD1FE",
  stroke: "#172B4D",
  strokeWidth: 1.345
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 116.658,
  cy: 64.476,
  r: 1.912,
  stroke: "#172B4D",
  strokeWidth: 1.345
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 35.373,
  cy: 17.769,
  r: 1.912,
  stroke: "#172B4D",
  strokeWidth: 1.345
}));
export default SvgCustomerPortalInviteAccept;

import { ITEMS_BEFORE_PAGE } from "#/src/constants";
import { CaretLeft, CaretRight } from "phosphor-react";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import TicketCard from "~/components/tickets/TicketCard";

const TicketPopup = ({
  tickets,
  totalCount,
  handleLoadMore = () => {},
  loading,
}) => {
  const { messages } = useIntl();

  const [currentIndex, setCurrentIndex] = useState(0);

  if (loading) return null;

  return (
    <div>
      <div className="font-manrope font-medium text-xs text-secondary mb-md">
        {totalCount ?? tickets.length}{" "}
        {messages?.tickets?.ticketCreatedForFacility}
      </div>
      <div className="ticket-map-card map-box map-card-box multi">
        <TicketCard showStatus ticket={tickets[currentIndex]} />
      </div>
      <div className="ticket-meta-data-index">
        <div className="font-manrope font-medium text-xs text-secondary">
          {currentIndex + 1}/{totalCount ?? tickets?.length}
        </div>
        <div>
          <CaretLeft
            size={20}
            className={`prev u-cursor-pointer ${
              currentIndex === 0
                ? "u-text-color-gray-v2-40"
                : "u-text-color-gray-v2-100"
            }`}
            onClick={() =>
              setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0)
            }
          />
          <CaretRight
            size={20}
            className={`next u-cursor-pointer ${
              currentIndex === tickets.length - 1
                ? "u-text-color-gray-v2-40"
                : "u-text-color-gray-v2-100"
            }`}
            onClick={() => {
              setCurrentIndex(
                currentIndex < totalCount - 1
                  ? currentIndex + 1
                  : totalCount - 1,
              );
              currentIndex + ITEMS_BEFORE_PAGE === tickets.length &&
                handleLoadMore();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketPopup;

import * as React from "react";
const SvgWoUnlinkDisabled = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M11.008 12.935a.6.6 0 0 0-.789.904l.79-.904Zm5.155.452.395.452a.597.597 0 0 0 .03-.028l-.425-.424Zm-4.582-7.365a.6.6 0 1 0 .84.858l-.84-.858Zm1.412 5.044a.6.6 0 1 0 .789-.904l-.79.904Zm-5.155-.452-.395-.452a.588.588 0 0 0-.03.027l.425.425Zm4.582 7.364a.6.6 0 1 0-.84-.857l.84.857Zm-2.2-4.139a4.819 4.819 0 0 0 6.338 0l-.79-.904a3.619 3.619 0 0 1-4.76 0l-.789.904Zm6.367-.028 1.387-1.386-.849-.849-1.386 1.387.848.848Zm1.391-1.39a4.524 4.524 0 0 0-.033-6.365l-.849.849a3.324 3.324 0 0 1 .025 4.676l.857.84Zm-.033-6.365a4.524 4.524 0 0 0-6.364-.034l.84.858a3.324 3.324 0 0 1 4.675.025l.849-.849Zm-4.163 4.106a4.819 4.819 0 0 0-6.339 0l.79.904a3.619 3.619 0 0 1 4.76 0l.789-.904Zm-6.368.027-1.387 1.387.848.849 1.387-1.387-.848-.848Zm-1.392 1.392a4.524 4.524 0 0 0 .034 6.364l.849-.849a3.324 3.324 0 0 1-.025-4.676l-.858-.84Zm.034 6.364a4.524 4.524 0 0 0 6.364.033l-.84-.857a3.324 3.324 0 0 1-4.675-.025l-.849.849Z",
  fill: "#748094",
  fillOpacity: 0.4
}), /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_30856_230686)",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeOpacity: 0.4,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M6.469 2.531 2.53 6.47M6.469 6.469 2.53 2.53"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_30856_230686"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  transform: "translate(1 1)",
  d: "M0 0h7v7H0z"
}))));
export default SvgWoUnlinkDisabled;

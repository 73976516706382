import { PRODUCT_TYPES } from "@shared/constants/paidFeaturesHierarchy";

import type { UserType } from "~/types/user";

export const ROUTES_TO_FEATURE_FLAG_MAP = {
  "/app/work": {
    getFeatureDeniedMessage: (
      messages: {
        menus: {
          [key: string]: { featureAccessDeniedModalTitle: string };
        };
      },
      user?: UserType,
    ) =>
      messages.menus?.[PRODUCT_TYPES.CMMS][
        user?.oem?.installedProducts?.find((p) => p.type === PRODUCT_TYPES.CMMS)
          ? "featureAccessDeniedModalTitleCMMS"
          : "featureAccessDeniedModalTitleFieldService"
      ],
    getFlag: (user?: UserType) =>
      user?.oem?.installedProducts?.find((p) => p.type === PRODUCT_TYPES.CMMS)
        ? PRODUCT_TYPES.CMMS
        : PRODUCT_TYPES.FIELD_SERVICE,
  },
  "/app/asset-hub": {
    getFeatureDeniedMessage: (messages: {
      menus: {
        [key: string]: { featureAccessDeniedModalTitle: string };
      };
    }) =>
      messages.menus?.[PRODUCT_TYPES.ASSET_HUB].featureAccessDeniedModalTitle,
    flag: PRODUCT_TYPES.ASSET_HUB,
  },
  "/app/3d": {
    getFeatureDeniedMessage: (messages: {
      menus: {
        [key: string]: { featureAccessDeniedModalTitle: string };
      };
    }) => messages.menus?.[PRODUCT_TYPES.THREE_D].featureAccessDeniedModalTitle,
    flag: PRODUCT_TYPES.THREE_D,
  },
  "/app/ai": {
    getFeatureDeniedMessage: (messages: {
      menus: {
        [key: string]: { featureAccessDeniedModalTitle: string };
      };
    }) =>
      messages.menus?.[PRODUCT_TYPES.INDUSTRIAL_AI]
        .featureAccessDeniedModalTitle,
    flag: PRODUCT_TYPES.INDUSTRIAL_AI,
  },
};

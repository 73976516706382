import {
  PAID_FEATURES_HIERARCHY,
  PRODUCT_TYPE_TO_FEATURE_TYPE_MAP,
  TIER_TYPES,
  paidFeaturesHierarchy,
} from "@shared/constants/paidFeaturesHierarchy";

import PAID_FEATURES from "$/settings/paid-features.json";
import { AppConfigType } from "~/types/appConfig";
import type { UserType } from "~/types/user";

export const userHasFeatureAccess = (
  appConfig: AppConfigType,
  user: UserType,
  paidFeatureKey: keyof typeof PAID_FEATURES,
) => {
  if (!appConfig.features.includes("growthLoop")) return true;
  const allPossibleParentFeatures: string[] = [];
  Object.keys(PAID_FEATURES_HIERARCHY).forEach((key) => {
    // @ts-ignore
    if (PAID_FEATURES_HIERARCHY[key].includes(paidFeatureKey))
      allPossibleParentFeatures.push(key);
  });
  if (!allPossibleParentFeatures.length) return false;
  if (
    user.productAccess?.some((access) =>
      allPossibleParentFeatures.includes(access),
    )
  )
    return true;
  return false;
};

const isPaidFeatureAvailable = (
  paidFeatureKey: keyof typeof PAID_FEATURES,
  user: UserType,
  appConfig: AppConfigType,
  checkUserAccess: boolean = true,
) => {
  const oem = user?.oem;

  if (!oem) return false;

  if (!appConfig.features.includes("growthLoop"))
    return oem.paidFeatures.includes(paidFeatureKey);

  let paidFeatures = [];

  const reversePaidFeaturesMap = {};

  Object.entries(PAID_FEATURES).forEach(
    ([paidFeatureKey, paidFeatureValue]) => {
      reversePaidFeaturesMap[paidFeatureValue] = paidFeatureKey;
    },
  );

  oem.installedProducts.forEach((product) => {
    const relevantPlan = appConfig.plans.find(
      (plan) => plan.type === product.type,
    );
    const relevantTier = relevantPlan?.tiers.find(
      (tier) => tier.type === product.tier,
    );
    if (relevantTier.type === TIER_TYPES.ENTERPRISE) {
      const featureType = PRODUCT_TYPE_TO_FEATURE_TYPE_MAP[product.type];
      const subFeatures = paidFeaturesHierarchy.find(
        (paidFeature) => paidFeature.title === featureType,
      )?.subFeatures;
      if (oem.paidFeatures.includes(reversePaidFeaturesMap[featureType]))
        paidFeatures.push(reversePaidFeaturesMap[featureType]);
      if (subFeatures && subFeatures?.length) {
        subFeatures.forEach((subFeature) => {
          if (oem.paidFeatures.includes(reversePaidFeaturesMap[subFeature])) {
            paidFeatures.push(reversePaidFeaturesMap[subFeature]);
          }
        });
      }
    } else {
      paidFeatures = [...paidFeatures, ...relevantTier.allowedFeatures];
    }
  });

  return (
    paidFeatures.includes(paidFeatureKey) &&
    (checkUserAccess
      ? userHasFeatureAccess(appConfig, user, paidFeatureKey)
      : true)
  );
};

export default isPaidFeatureAvailable;

import * as React from "react";
const SvgLinkIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 21,
  height: 20,
  viewBox: "0 0 21 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7.57318 9.99998H14.2398",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.91566 13.3333H6.94816C4.99233 13.3333 3.40649 11.7475 3.40649 9.79167V9.79167C3.40649 7.83583 4.99233 6.25 6.94816 6.25H8.91566",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12.8973 13.3333H14.8648C16.8207 13.3333 18.4065 11.7475 18.4065 9.79167V9.79167C18.4065 7.83583 16.8207 6.25 14.8648 6.25H12.8973",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgLinkIcon;

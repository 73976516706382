import { updateInventoryPartCache } from "~/services";
import { updateAssetCache } from "~/services/asset";
import { updateAssetTemplateCache } from "~/services/assetTemplate";

export const documentFoldersListener = (m) => {
  if (!m?.message?.success) {
    return;
  }
  const payload = m?.message?.payload;
  const isMachine = m?.userMetadata?.isMachine;
  const isMachineTemplate = m?.userMetadata?.isMachineTemplate;
  const isInventoryPart = m?.userMetadata?.isInventoryPart;

  if (isMachine) {
    updateAssetCache(payload);
  }

  if (isMachineTemplate) {
    updateAssetTemplateCache(payload);
  }

  if (isInventoryPart) {
    updateInventoryPartCache(payload);
  }
};

import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import { PartListItem } from "@shared/ui/ListItemCards";
import AppModal from "@shared/ui/Modal";
import { Plus } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { PartDetailModalHeader } from "~/components/_partDetailModalComponents";
import AssetLoaderComponent from "~/components/assets/_assetLoaderComponent";
import { AddPartComp } from "~/components/tickets/_spareParts";
import { useGetAssetsPartsById } from "~/services/asset";
import { getPartThumbnail } from "~/utils";
import { registerMixpanelEvent } from "~/utils/_mixpanel";

export type EventData = {
  title: string;
  eventDate: Date | null;
  frequency: string;
  repeatInNumber: string;
  repeatIn: string;
  ticketCreationNumber: string;
  ticketCreationIn: string;
  description: string;
  inventoryParts: Array<{
    part: any;
    quantity: number;
    count: number;
  }>;
  procedures: Array<{
    procedureId: string;
    name: string;
  }>;
};

const PartsInstancePME = ({
  isClosedTicket,
  setEventCb,
  machineId,
  event,
  initiateEvent = "",
}: {
  isClosedTicket: boolean;
  setEventCb: (prev) => void;
  machineId: string;
  event: EventData;
  initiateEvent?: string;
}) => {
  const { messages } = useIntl();
  const [showAttachProcedureModal, setShowAttachProcedureModal] =
    useState(false);

  const [tempSelectedPartsCount, setTempSelectedPartsCount] = useState(0);
  const [addedParts, setAddedParts] = useState({});
  const [partForModalPreview, setPartForModalPreview] = useState(null);
  const tempSelectedParts = React.useRef({});

  const handleSelectedPartsCount = () => {
    setTempSelectedPartsCount(Object.keys(tempSelectedParts.current).length);
  };

  useEffect(() => {
    setAddedParts((prev) => ({
      ...prev,
      ...(event.inventoryParts || []).reduce((map, part) => {
        if (part?.part?._id) {
          map[part.part._id] = part.quantity;
        }
        return map;
      }, {}),
    }));
  }, [event]);

  const handleModalClose = () => {
    setShowAttachProcedureModal(false);
    setEventCb((prev) => {
      return {
        ...prev,
        inventoryParts: prev.inventoryParts,
      };
    });
    setAddedParts({});
  };

  const { inventoryParts, inventoryPartsLoading } = useGetAssetsPartsById({
    id: machineId,
  });

  const handleAddPart = (partId, qty) => {
    if (qty <= 0) {
      delete tempSelectedParts.current[partId];
    } else {
      tempSelectedParts.current[partId] = qty;
    }
    handleSelectedPartsCount();
    const partDetails = inventoryParts.find((part) => part.part._id === partId);
    if (partDetails) {
      setAddedParts((prev) => ({
        ...prev,
        ...{ [partId]: qty },
      }));
    }
  };

  const handleModalSubmit = () => {
    Object.entries(addedParts).map(([partId, partQty]) => {
      const partDetails = inventoryParts.find(
        (part) => part.part._id === partId,
      );
      if (partDetails) {
        setEventCb((prev) => {
          const existingIndex = prev.inventoryParts?.findIndex(
            (item) => item.part._id === partId,
          );

          if (existingIndex >= 0 && existingIndex !== -1) {
            const updatedParts = [...prev.inventoryParts];
            updatedParts[existingIndex] = {
              ...updatedParts[existingIndex],
              quantity: partQty,
            };
            return {
              ...prev,
              inventoryParts: updatedParts,
            };
          } else {
            return {
              ...prev,
              inventoryParts: [
                ...(prev.inventoryParts || []),
                { ...partDetails, quantity: partQty },
              ],
            };
          }
        });
      }
    });
    setAddedParts({});
    handleModalClose();
  };
  const handleIncrement = (partId, isFromSidebar = false) => {
    isFromSidebar
      ? setEventCb((prev) => ({
          ...prev,
          inventoryParts: [
            ...prev.inventoryParts.map((prevPart) =>
              prevPart.part._id === partId
                ? { ...prevPart, quantity: (prevPart.quantity || 0) + 1 }
                : prevPart,
            ),
          ],
        }))
      : setAddedParts((prev) => ({
          ...prev,
          ...{ [partId]: (prev?.[partId] || 0) + 1 },
        }));
  };

  const handleDecrement = (partId, isFromSidebar = false) => {
    isFromSidebar
      ? setEventCb((prev) => ({
          ...prev,
          inventoryParts: [
            ...prev.inventoryParts.map((prevPart) =>
              prevPart.part._id === partId
                ? {
                    ...prevPart,
                    quantity: prevPart.quantity > 1 ? prevPart.quantity - 1 : 0,
                  }
                : prevPart,
            ),
          ],
        }))
      : setAddedParts((prev) => ({
          ...prev,
          ...{ [partId]: (prev?.[partId] || 0) - 1 },
        }));
  };

  return (
    <section>
      <div className="flex items-center justify-between mt-lg">
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          color="text-secondary"
          className="mb-sm"
        >
          {messages?.preventiveMaintenance?.parts?.title}
        </BodyText>
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          color="text-secondary"
          className="mb-sm"
        >
          {(event?.inventoryParts || []).filter(
            ({ count, quantity }) => count > 0 || quantity > 0,
          )?.length || 0}{" "}
          {messages?.preventiveMaintenance?.parts?.added}
        </BodyText>
      </div>
      <Button
        className="ml-2xs mb-md mr-md w-full text-secondary py-md px-xl bg-transparent !border !border-dashed border-primary active:!text-brand active:border-brand hover:!text-brand hover:border-brand disabled:text-disabled disabled:border-primary"
        variant={BUTTON_VARIANTS.LINK}
        text={messages?.preventiveMaintenance?.parts?.add}
        // @ts-ignore
        leadingIcon={<Plus size={16} weight="bold" />}
        disabled={isClosedTicket}
        onClick={() => {
          if (isClosedTicket) return;
          initiateEvent && registerMixpanelEvent(initiateEvent);
          setShowAttachProcedureModal(true);
        }}
      />
      <div className="procedure-instances-container">
        {(event?.inventoryParts || [])
          .filter(({ count, quantity }) => count > 0 || quantity > 0)
          .map(({ part, count = 0, quantity = 0 }) => (
            <PartListItem
              articleNumber={part?.articleNumber}
              name={part?.name}
              showDeleteButton
              thumbnail={getPartThumbnail(part)}
              hideDetailsButton
              onDeleteButtonClick={() => {
                setEventCb((prev) => ({
                  ...prev,
                  inventoryParts: [
                    ...prev.inventoryParts.filter(
                      (prevPart) => prevPart.part._id !== part._id,
                    ),
                  ],
                }));
                setAddedParts((prev) => ({
                  ...prev,
                  ...{ [part._id]: 0 },
                }));
              }}
              counterProps={{
                count: count || quantity,
                incrementCount: () => handleIncrement(part._id, true),
                decrementCount: () => handleDecrement(part._id, true),
                isReadOnlyInput: false,
                setCount: (count) => {
                  setEventCb((prev) => ({
                    ...prev,
                    inventoryParts: [
                      ...prev.inventoryParts.map((prevPart) =>
                        prevPart.part._id === part._id
                          ? {
                              ...prevPart,
                              quantity: count,
                            }
                          : prevPart,
                      ),
                    ],
                  }));
                  setAddedParts((prev) => ({
                    ...prev,
                    ...{ [part._id]: count },
                  }));
                },
              }}
              customLabelStyles="max-w-40"
            />
          ))}
      </div>
      <AppModal
        isOpen={showAttachProcedureModal}
        className="!w-[75vw]"
        maxWidth="lg:!max-w-[650px]"
        disableCancel={false}
        overlayClasses="!z-10"
        // @ts-ignore
        updateButtonText={
          <>
            {tempSelectedPartsCount > 0 && (
              <div className="selected-parts-count-in-button">
                {tempSelectedPartsCount}
              </div>
            )}
            {messages?.preventiveMaintenance?.parts?.add}
          </>
        }
        disableUpdate={
          !Object.values(addedParts || {}).filter((val) => val > 0).length
        }
        handleClose={handleModalClose}
        handleSubmit={handleModalSubmit}
        title={
          partForModalPreview
            ? null
            : messages?.preventiveMaintenance?.parts?.add || ""
        }
        header={
          partForModalPreview ? (
            <div className="flex items-center py-xl px-2xl border-solid border-b border-t-0 border-x-0 border-primary">
              <PartDetailModalHeader
                // @ts-ignore
                title={messages.inventory.workOrderPartDetails}
                onBack={() => setPartForModalPreview(null)}
              />
            </div>
          ) : null
        }
        showFooter={!partForModalPreview}
        contentClassName={
          partForModalPreview ? "part-modal-preview-content-wrapper" : ""
        }
        content={
          inventoryPartsLoading ? (
            <AssetLoaderComponent />
          ) : (
            <AddPartComp
              addedParts={addedParts}
              allParts={inventoryParts || []}
              handleAddPart={handleAddPart}
              onClickDetails={(part) => setPartForModalPreview(part)}
              partForModalPreview={partForModalPreview}
              syncStates
            />
          )
        }
      />
    </section>
  );
};

export default PartsInstancePME;

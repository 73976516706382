import * as React from "react";
const SvgGlobeIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 12 12",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 0.8,
  d: "M6 10.502A4.502 4.502 0 1 1 10.502 6"
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 0.8,
  d: "M6 10.502A4.502 4.502 0 1 0 1.498 6m.257-1.5h8.437m-8.437 3H10"
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 0.8,
  d: "M8 6a7.741 7.741 0 0 0-1.084-3.972 1.058 1.058 0 0 0-1.833 0 7.817 7.817 0 0 0 0 7.944c.19.327.539.53.917.53"
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 0.8,
  d: "M3.999 6a7.742 7.742 0 0 1 1.084-3.972 1.058 1.058 0 0 1 1.833 0 7.818 7.818 0 0 1 0 7.944 1.06 1.06 0 0 1-.917.53"
}));
export default SvgGlobeIcon;

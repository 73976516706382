import MakulaLogo from "@shared/svg/makula-logo.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import React from "react";
import { useIntl } from "react-intl";

const AuthPageLayout = ({ children }: { children: React.ReactNode }) => {
  const { messages: msg } = useIntl();
  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <div className="w-full lg:w-1/2 bg-secondary lg:pl-3xl lg:pr-0 px-3xl flex flex-col items-center lg:items-start justify-center">
        <div className="mb-5xl max-w-md lg:max-w-full">
          <MakulaLogo className="w-fit h-3xl mb-xl mt-xl flex-shrink-0" />
          <Headline size={HEADLINE_SIZES.SMALL} color="text-inverse">
            {msg.login?.["heroTitleV2"]}
          </Headline>
          <BodyText
            size={BODY_TEXT_SIZES.SMALL}
            color="text-tertiary"
            className="mt-2xs lg:text-left"
          >
            {msg.login?.["heroSubtitleV2"]}
          </BodyText>
        </div>

        <div className="rounded-lg shadow-lg lg:block hidden overflow-hidden">
          <img
            src="/images/auth/login.png"
            alt="Dashboard Preview"
            className="w-full h-auto"
          />
        </div>
      </div>

      <div className="w-full lg:w-1/2 bg-white p-3xl flex flex-col justify-center">
        <div className="max-w-md mx-auto w-full">{children}</div>
      </div>
    </div>
  );
};

export default AuthPageLayout;

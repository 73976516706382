import { TIER_TYPES } from "@shared/constants/paidFeaturesHierarchy";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { Check } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";

import type { AppConfigType } from "~/types/appConfig";

const TierTile = ({
  planConfig,
  installedTier,
  tierType,
  onContactSales,
}: {
  planConfig: AppConfigType["plans"][0];
  installedTier: string;
  tierType: string;
  onContactSales: () => void;
}) => {
  const { messages } = useIntl();

  const relevantTier = planConfig.tiers.find((tier) => tier.type === tierType);

  const textClass = tierType === TIER_TYPES.PRO ? "text-white" : "";

  return (
    <div
      className={`p-lg flex flex-col rounded-lg space-y-lg ${
        tierType === TIER_TYPES.PRO
          ? "bg-secondary"
          : "border border-solid border-primary"
      }`}
    >
      <Headline size={HEADLINE_SIZES.SMALL} color={textClass}>
        {relevantTier.name}
      </Headline>
      <div className="flex flex-col space-y-2xs">
        <div className="flex space-x-2xs items-center">
          {relevantTier.price === -1 ? (
            <Headline size={HEADLINE_SIZES.LARGE} color={textClass}>
              {messages.plans["custom"]}
            </Headline>
          ) : (
            <>
              <Label size={LABEL_SIZES.MEDIUM} color={textClass}>
                €
              </Label>
              <Headline size={HEADLINE_SIZES.LARGE} color={textClass}>
                {relevantTier.price}
              </Headline>
            </>
          )}
        </div>
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          color={
            tierType === TIER_TYPES.PRO ? "text-tertiary" : "text-secondary"
          }
        >
          {tierType === TIER_TYPES.ENTERPRISE
            ? "‎"
            : messages.plans["billType"]}
        </BodyText>
      </div>
      <Button
        variant={
          tierType === TIER_TYPES.PRO
            ? BUTTON_VARIANTS.SECONDARY
            : BUTTON_VARIANTS.PRIMARY
        }
        text={
          installedTier === tierType
            ? messages.plans["currentPlan"]
            : messages.plans["contactSales"]
        }
        className="min-w-[248px]"
        disabled={installedTier === tierType}
        onClick={() => onContactSales()}
      />
      <div
        className={`border border-solid border-primary h-px w-full ${
          tierType === TIER_TYPES.PRO ? "opacity-20" : ""
        }`}
      />
      <BodyText
        size={BODY_TEXT_SIZES.X_SMALL}
        color={tierType === TIER_TYPES.PRO ? "text-tertiary" : "text-secondary"}
      >
        {tierType === TIER_TYPES.FREE
          ? messages.plans["whatsIncluded"]
          : tierType === TIER_TYPES.PRO
          ? messages.plans["proIncluded"]
          : messages.plans["enterpriseIncluded"]}
      </BodyText>
      <div className="space-y-sm">
        {Object.entries(messages.plans[planConfig.type]?.[tierType] || {}).map(
          ([, feature]) => (
            <>
              {feature?.["heading"] && (
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} color={textClass}>
                  {feature["heading"]}
                </BodyText>
              )}
              {Object.values(feature["points"]).map((point: string) => (
                <div className="flex space-x-md">
                  {/* @ts-ignore */}
                  <Check
                    size={20}
                    className={
                      tierType === TIER_TYPES.PRO
                        ? "text-tertiary"
                        : "text-brand"
                    }
                  />
                  <BodyText size={BODY_TEXT_SIZES.X_SMALL} color={textClass}>
                    {point}
                  </BodyText>
                </div>
              ))}
            </>
          ),
        )}
      </div>
    </div>
  );
};

export default TierTile;

import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_SIZES } from "@shared/ui/Button";
import { DateInput } from "@shared/ui/Inputs";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import useOnClickOutside from "use-onclickoutside";

import EmptySpace from "~/components/_emptySpace";

const CustomDateField = ({
  field,
  handleTextFieldSave,
  isEditable = true,
  dropdownClassname = "",
}) => {
  const { messages } = useIntl();
  const label = field?.isAdditionalField
    ? messages?.settings?.companyTabs?.customizeFields.additionalFields.labels[
        field?.slug
      ]
    : field?.label;
  const [formatedValue, setFormatedValue] = React.useState(field?.value);
  const ref = useRef();
  const cTextField = useRef({ [field?.id]: formatedValue });

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const updateTextField = (textFields) => {
    toggle();
  };

  useOnClickOutside(ref, () => {
    setIsOpen(false);
    setFormatedValue(field?.value);
  });

  useEffect(() => {
    if (field?.value) {
      setFormatedValue(field?.value);
      cTextField.current[field?._id] = field?.value;
    }
  }, [field?.value]);

  return (
    <>
      <div className="u-flex u-items-center custom-field-row u-padding-b-1">
        <div className="u-width-2">
          <BodyText
            title={label}
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
            className="overflow-hidden text-ellipsis no-styles"
          >
            {label}:
          </BodyText>
        </div>
        <div className="u-width-8">
          <div
            ref={ref}
            className={`c-dropdown ${isOpen ? "c-dropdown--shown" : ""}`}
          >
            <button
              className={`c-textarea-btn u-width-100 ${
                !isEditable
                  ? "pointer-events-none !bg-transparent"
                  : "cursor-pointer"
              }`}
              onClick={() => {
                if (!isEditable) return;
                toggle();
              }}
            >
              <span className={field?.value ? "u-text-color-gray-v2-100" : ""}>
                {field?.value
                  ? field.value
                  : `${messages?.common?.emptyProperty}`}
              </span>
            </button>
            <div
              className={
                "c-dropdown-menu customize-field-dropdown property-userDropdownMenu flex-col " +
                dropdownClassname
              }
            >
              <div style={{ width: "100%" }}>
                <EmptySpace height="8px" />
                <div className="input-v2-wrapper mb-sm">
                  <DateInput
                    value={
                      // For Safari Support
                      formatedValue
                        ? new Date(
                            moment(formatedValue, ["YYYY-MM-DD", "YYYY-M-D"]),
                          ).toDateString()
                        : ""
                    }
                    dropdownClassName={dropdownClassname}
                    showFormat
                    onChange={(v) => {
                      const formatedDate = moment(v).format("YYYY-MM-DD");
                      if (formatedValue === formatedDate) {
                        setFormatedValue("");
                        cTextField.current[field?._id] = "";
                      } else {
                        setFormatedValue(formatedDate);
                        cTextField.current[field?._id] = formatedDate;
                      }
                    }}
                  />
                </div>
                <div className="flex justify-end">
                  <Button
                    size={BUTTON_SIZES.SMALL}
                    text={messages?.common?.save}
                    onClick={(e) => {
                      handleTextFieldSave(
                        e,
                        cTextField.current,
                        field?._id,
                        updateTextField,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomDateField;

import Dropdown, { POSITIONS } from "@shared/ui/DropDown";
import Tag from "@shared/ui/Tag";
import React from "react";
import { useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";

import { useListTeams } from "#/src/services/team/_query";
import { getOptionBackgroundColor } from "#/src/utils";
import { NotFoundComponent } from "~/components/_notFoundMessage";
import DropdownFooter from "~/components/filters/_dropdownFooter";
import DropdownHeader from "~/components/filters/_dropdownHeader";
import { ITEMS_BEFORE_PAGE, ITEMS_PER_PAGE } from "~/constants";
import { NoUserAssignedIcon, TickIcon } from "~/icons/icons";

const TeamFilter = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  activeFilters,
  selectValues,
  handleSelectDeleteAll,
  dropDownPosition = POSITIONS.LEFT,
}) => {
  const { messages: msg } = useIntl();
  const [searchTerm, setSearchTerm] = React.useState("");

  const {
    teams,
    teamsLoading: loading,
    totalCount,
    handleFetchMore,
  } = useListTeams({
    searchQuery: searchTerm,
    limit: ITEMS_PER_PAGE,
    skip: 0,
  });

  const handleScrollBottom = (event) => {
    if (
      teams.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== Waypoint.above &&
      totalCount > teams.length
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: teams.length,
      });
    }
  };

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
      position={dropDownPosition}
    >
      <DropdownHeader
        title={title}
        searchTerm={searchTerm}
        onSearchTermChange={setSearchTerm}
        unselectFilter={unselectFilter}
        showPropsTitleOnly
      />
      <div className="max-h-56 flex flex-col overflow-y-scroll">
        {!loading && !teams?.length && (
          <NotFoundComponent
            icon={<NoUserAssignedIcon />}
            title={msg?.common?.["filters"].noTeam}
          />
        )}
        {teams.map((item, index) => (
          <div
            className="py-sm flex cursor-pointer justify-between"
            key={index}
            onClick={() => selectValues(filterItem, item?._id)}
          >
            <Tag
              label={item.name}
              className="max-h-7"
              style={{
                color: item.teamColor,
                background: getOptionBackgroundColor(item.teamColor),
              }}
            />
            {index === teams.length - ITEMS_BEFORE_PAGE ? (
              // @ts-ignore
              <Waypoint onEnter={handleScrollBottom} />
            ) : null}

            {activeFilters[filterItem.id].includes(item?._id) && (
              <span className="active">
                <TickIcon />
              </span>
            )}
          </div>
        ))}
        {loading ? (
          <div className="px-lg py-sm font-medium text-xs text-secondary">
            {msg?.common?.["loading"]}
          </div>
        ) : null}
      </div>
      <DropdownFooter
        deleteAll={() => handleSelectDeleteAll(filterItem, false)}
        disabled={!activeFilters[filterItem.id]?.length}
      />
    </Dropdown>
  );
};

export default TeamFilter;

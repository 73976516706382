import * as React from "react";
const SvgCmms = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M17.85 7.947a6.324 6.324 0 0 1 5.301 5.301.835.835 0 0 1-.831.947h-4.584a.834.834 0 0 1-.834-.833V8.777c0-.505.449-.905.948-.83v0ZM21.986 20.657c.599-.81 1.009-1.767 1.165-2.808a.835.835 0 0 0-.832-.947h-3.406a.833.833 0 0 0-.59 1.423l2.41 2.409c.355.354.953.326 1.253-.078v0ZM13.258 11.556a6.327 6.327 0 0 0-5.26 7.518c.481 2.472 2.48 4.461 4.954 4.932 1.876.357 3.64-.116 4.993-1.115.405-.299.436-.899.08-1.255L14.44 18.05a.832.832 0 0 1-.245-.59v-5.075c0-.5-.44-.903-.936-.83v0Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgCmms;

export const customFieldTypes = {
  machines: "machines",
  facilities: "facilities",
  ticket: "tickets",
  knowledgeBase: "knowledgeBase",
};

export const fieldType = {
  date: "date",
  singleSelect: "singleSelect",
  text: "text",
  number: "number",
  address: "address",
};

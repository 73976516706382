import { useMutation, gql } from "@apollo/client";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import { toastProgressStyles } from "./_useToast";

// config
const providers = ["s3"];

// mutation
const _safeS3SignMutation = gql`
  mutation (
    $filename: String!
    $filetype: String!
    $type: String
    $forceCustomPath: Boolean
  ) {
    _safeSignS3(
      filename: $filename
      filetype: $filetype
      type: $type
      forceCustomPath: $forceCustomPath
    ) {
      url
      signedRequest
    }
  }
`;

export default (args) => {
  const { toastRef } = args || {};
  const toastId = toastRef || useRef(0);
  const { messages } = useIntl();

  const startToast = () => {
    return !toastRef
      ? (toastId.current = toast(
          <Toast
            message={messages?.common?.uploadInProgress}
            type={TOAST_TYPES.UPLOADING}
          />,
          {
            autoClose: false,
            progress: 0,
            hideProgressBar: false,
            progressStyle: toastProgressStyles,
            closeButton: false,
          },
        ))
      : toast.update(toastId.current, {
          progress: 0,
          hideProgressBar: false,
          progressStyle: toastProgressStyles,
          autoClose: false,
          closeButton: false,
          render: (
            <Toast
              message={messages?.common?.uploadInProgress}
              type={TOAST_TYPES.UPLOADING}
            />
          ),
        });
  };

  const updateToast = (type, message, autoClose = false, options = {}) => {
    toast.update(toastId.current, {
      render: <Toast message={message} />,
      autoClose: autoClose,
      progressStyle: toastProgressStyles,
      closeButton: false,
      ...options,
    });
  };

  const endToast = () => {
    toast.done(toastId.current);
    return (toastId.current = toast.dismiss(toastId.current));
  };

  const [safeS3Sign] = useMutation(_safeS3SignMutation);
  const fileUploader = async (
    { files = [], type = "s3", folder, forceCustomPath },
    showToastMessage = true,
  ) => {
    if (!providers.includes(type))
      return {
        success: false,
        hasError: true,
        errors: [
          {
            message: `Provider [${type}] is not supported. Only supported providers are ${providers.join(
              ", ",
            )}`,
          },
        ],
        urls: [],
      };
    let allFiles = [];
    let errors = [];
    if (Array.isArray(files)) {
      const totalFiles = files.filter(
        (f) => !!f && typeof f !== "string",
      ).length;

      if (totalFiles > 0 && showToastMessage) {
        startToast();
      }

      allFiles = await files.reduce(async (arr, file, index) => {
        await arr;

        const currentFile = index + 1;
        const msg = totalFiles > 1 ? ` ${currentFile} of ${totalFiles}` : "";
        updateToast(TOAST_TYPES.UPLOADING, `Uploading${msg}`, {
          progress: index / totalFiles,
        });
        let attachmentUrl = file || null;
        if (attachmentUrl) {
          if (typeof attachmentUrl === "string")
            return [...(await arr), attachmentUrl];
          const { type, file: dataFile = file } = file;
          // const { size } = dataFile
          // if (size > maxSize) {
          //   toast(<Toast message={translations.messagesNewMessageErrorFileBig} type="error" />, { closeButton: false })
          //   return null
          // }
          try {
            const response = await safeS3Sign({
              variables: {
                filename: dataFile.name,
                filetype: dataFile.type || "text/html",
                type: folder,
                forceCustomPath,
              },
            });

            const { signedRequest, url: bucketUrl } = response.data._safeSignS3;
            const options = {
              headers: {
                "Content-Type": type,
              },
            };
            await new Promise(async (resolve, reject) => {
              await fetch(signedRequest, {
                method: "PUT",
                headers: options.headers,
                body: dataFile,
              })
                .then((data) => {
                  attachmentUrl = bucketUrl;
                  resolve(data);
                })
                .catch((e) => {
                  attachmentUrl = null;
                  errors.push(e);
                  reject(e);
                });
            });
          } catch (e) {
            errors.push(e);
            return [...(await arr)];
          }
          return [...(await arr), attachmentUrl];
        }
      }, []);

      if (!errors.length) {
        if (showToastMessage) {
          updateToast(
            TOAST_TYPES.SUCCESS,
            messages?.common?.uploadComplete,
            true,
            {
              hideProgressBar: true,
            },
          );
        }
      } else if (showToastMessage) {
        updateToast(TOAST_TYPES.ERROR, messages?.common?.uploadError, true, {
          progress: 1,
        });

        setTimeout(() => endToast(), 1000); // NOTE: this is an hack. We should figure out why the referenced toast won't close itself
      }
      return {
        toastId,
        success: !errors.length,
        hasError: errors.length,
        errors,
        urls: allFiles,
      };
    }
  };
  return fileUploader;
};

import { POSITIONS } from "@shared/ui/DropDown";
import { lowerCase, upperFirst } from "lodash";
import React, { useRef } from "react";
import { useIntl } from "react-intl";

import { FILTER_VARIANTS } from "~/components/filters";
import {
  SidebarFacilityIcon,
  SidebarMachinesIcon,
  SidebarOpenTicketsFolder,
} from "~/icons/icons";

const FilterItem = ({
  item,
  DropdownItem,
  handleFilterDropdown,
  showDropdown,
  activeFilters,
  unselectFilter,
  selectValues,
  handleSelectDeleteAll,
  scope,
  dropDownPosition = POSITIONS.LEFT,
  showTitle = true,
  filterVariant = FILTER_VARIANTS.EXACTLY_MATCHES,
}) => {
  const { messages } = useIntl();

  const dropdownRef = useRef();

  const title = React.useMemo(() => {
    if (item && Object.hasOwnProperty.call(item, "id")) {
      return messages?.filters?.[item.id]?.title;
    } else if (item.isAdditionalField) {
      return messages?.filters?.[item?.slug]?.title;
    }
    return item.label;
  }, [item]);

  const filterActive = React.useMemo(() => {
    const itemValue = activeFilters[item.id ?? item._id];
    if (typeof itemValue === "boolean") {
      return true;
    }
    return itemValue.length > 0;
  }, [activeFilters, item._id, item.id]);

  return (
    <span
      className={`filter-item-container ${
        dropDownPosition === POSITIONS.RIGHT ? "static" : "relative"
      }`}
    >
      <span
        className="filter-item"
        onClick={() => handleFilterDropdown(item, !showDropdown)}
      >
        <span
          className={`filter-icon ${
            showDropdown || filterActive ? "filter-icon-active" : ""
          }`}
        >
          {item.icon ? (
            <item.icon />
          ) : item.type === "machines" ? (
            <SidebarMachinesIcon />
          ) : item.type === "facilities" ? (
            <SidebarFacilityIcon />
          ) : (
            <SidebarOpenTicketsFolder />
          )}
          {filterActive && <span className="active" />}
        </span>
        {showTitle && (
          <span
            className={`filter-name ${
              filterActive ? "filter-name-active" : ""
            }`}
          >
            {title}
          </span>
        )}
      </span>
      {!DropdownItem ? null : (
        <DropdownItem
          show={showDropdown}
          onClose={() => handleFilterDropdown(item, false)}
          title={title}
          dropdownRef={dropdownRef}
          unselectFilter={() => unselectFilter(item)}
          filterItem={item?.id ? item : { ...item, id: item?._id }}
          activeFilters={activeFilters}
          selectValues={selectValues}
          scope={scope}
          handleSelectDeleteAll={handleSelectDeleteAll}
          dropDownPosition={dropDownPosition}
          filterVariant={filterVariant}
        />
      )}
    </span>
  );
};

export default FilterItem;

import * as React from "react";
const SvgAiOcrActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M5.83 17.503h-.833a2.501 2.501 0 0 1-2.5-2.501v-.834M14.168 2.498h.834A2.501 2.501 0 0 1 17.502 5v.834M2.496 5.833v-.834a2.501 2.501 0 0 1 2.501-2.5h.834M17.503 14.168v.834a2.501 2.501 0 0 1-2.501 2.5h-.834",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M7.501 4.998h5.002A2.501 2.501 0 0 1 15.004 7.5v1.667H5V7.5A2.501 2.501 0 0 1 7.501 5Z",
  fill: "#0517F8",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M15.004 11.668v.834a2.501 2.501 0 0 1-2.5 2.5H7.5A2.501 2.501 0 0 1 5 12.503v-.834M3.332 9.167h13.339",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgAiOcrActive;

import MachineTemplateIcon from "@shared/svg/machine-template-icon.svg?react";
import Tag, { TAG_COLORS } from "@shared/ui/Tag";
import { bool, number, string } from "prop-types";
import React from "react";
import { useIntl } from "react-intl";

import getEnums from "$/settings/enums";
import TEMPLATE_TYPES from "$/settings/enums/machineTemplate/index.json";

const templateTypes = getEnums(TEMPLATE_TYPES, "reference");

export const MachineTemplateLabel = ({
  type,
  showIcon,
  showLabel,
  label,
  iconSize,
  className = "",
}) => {
  const { messages } = useIntl();
  return (
    <Tag
      label={label || messages?.machines?.machineTemplateType[type]}
      className={`truncate max-w-[150px] group ${className}`}
      leadingIcon={
        showIcon ? (
          <div className="flex items-center justify-center">
            <MachineTemplateIcon
              width={iconSize}
              height={iconSize}
              className={`${
                type === templateTypes.lineTemplate
                  ? "text-mintBlue group-hover:text-inverse"
                  : "text-danger group-hover:text-inverse"
              }`}
            />
          </div>
        ) : null
      }
      color={
        type === templateTypes.machineTemplate
          ? TAG_COLORS.RED
          : TAG_COLORS.MINT
      }
    />
  );
};

MachineTemplateLabel.propTypes = {
  type: string.isRequired,
  label: string,
  showIcon: bool,
  showLabel: bool,
  iconSize: number,
};

MachineTemplateLabel.defaultProps = {
  showIcon: true,
  showLabel: true,
  iconSize: 14,
  label: "",
};

import ReloadPageImage from "@shared/svg/reload-page.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import Toast from "@shared/ui/Toast";
import { registerLicense } from "@syncfusion/ej2-base";
import PropTypes from "prop-types";
import React from "react";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";

import { globalAppVersionAtom, MaintenanceAtom } from "~/atoms/_global";
import { Authenticate, useAuth } from "~/components/general";
import { FlashMessageWrapper } from "~/containers/_flashMessageWrapper";
import { Layout } from "~/layout";

import "react-calendar/dist/Calendar.css";

if (import.meta.env.VITE_APP_SYNCFUSION_LICENSE_KEY) {
  registerLicense(import.meta.env.VITE_APP_SYNCFUSION_LICENSE_KEY);
}

const App = ({ match, location }) => {
  const { messages } = useIntl();
  const { state } = location;
  const history = useHistory();
  const { chat, user, refetch } = useAuth();
  const flashMessage = useRecoilValue(globalAppVersionAtom);
  const { isMaintenanceOn } = useRecoilValue(MaintenanceAtom);

  React.useEffect(() => {
    let tokenInterval;
    if (chat && user) {
      const SECONDS = 60;
      const MILLISECONDS = 1000;
      const timeOffset = SECONDS * MILLISECONDS;
      const { ttl, timestamp } = chat?.parseToken(user?.chatToken) || {};
      const tokenTime = new Date(timestamp * 1000 + ttl * 60 * 1000).getTime();

      tokenInterval = setInterval(() => {
        const now = new Date().getTime() + timeOffset;
        if (now > tokenTime) {
          refetch();
        }
      }, 10 * MILLISECONDS);
    }

    return () => clearInterval(tokenInterval);
  }, [user, chat]);

  React.useEffect(() => {
    if (state?.welcome) {
      toast(<Toast message={messages?.login?.welcome} />, {
        closeButton: false,
      });
      history.replace({ ...location, state: undefined });
    }
  }, []);

  return (
    <Authenticate>
      {isMaintenanceOn && (
        <AlertBox
          isOpen={isMaintenanceOn}
          overlay
          image={<ReloadPageImage width="130" height="130" />}
          title={messages["common"]["maintenanceMessage"]}
        />
      )}
      {flashMessage?.showFlashMessage &&
        createPortal(<FlashMessageWrapper />, document.body)}
      <Layout match={match} location={location} />
    </Authenticate>
  );
};
App.propTypes = {
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default App;

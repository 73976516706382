import * as React from "react";
const SvgIndustrialAiActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M0 6a6 6 0 0 1 6-6h20a6 6 0 0 1 6 6v20a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z",
  fill: "#8940E5"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M10.998 8.497a3.335 3.335 0 0 1-3.335 3.335 3.335 3.335 0 0 1 3.335 3.334 3.335 3.335 0 0 1 3.335-3.334 3.335 3.335 0 0 1-3.335-3.335ZM20.168 18.501A4.168 4.168 0 0 0 16 14.333a4.168 4.168 0 0 0 4.168-4.169 4.168 4.168 0 0 0 4.169 4.169 4.168 4.168 0 0 0-4.169 4.168Z",
  fill: "#fff",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20.168 18.501v.834M20.168 10.164v-.833M14.333 23.503v.834M14.333 17.667v-.833M10.998 15.166V16M10.998 7.663v.834",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M14.333 17.25a3.335 3.335 0 0 1-3.335 3.335 3.335 3.335 0 0 1 3.335 3.335 3.335 3.335 0 0 1 3.334-3.335 3.335 3.335 0 0 1-3.334-3.335Z",
  fill: "#fff",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgIndustrialAiActive;

import DisconnectIcon from "@shared/svg/disconnect.svg?react";
import LoadingIcon from "@shared/svg/loading.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import Tabs, { TAB_VARIANTS } from "@shared/ui/Tabs";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { Info } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import DocumentationDetachmentStatus from "$/settings/enums/machine/template-documentation-status.json";
import { NotFoundComponent } from "~/components/_notFoundMessage";
import BoxUIElement from "~/components/boxUIElement";
import ContentLoading from "~/components/ContentLoading";
import { useAuth } from "~/components/general";
import { DOCUMENTS_TABS } from "~/constants";
import { useRoleManager } from "~/hooks/_useRoleManager";
import { NoTicketsSmallIcon, DeleteTrashImage } from "~/icons/icons";
import { useDetachAssetDocumentation } from "~/services/asset";

const MachineDocuments = ({
  userFolderToken = "",
  machine,
  isDetached = false,
  isSharedAssetsPage = false,
}) => {
  const { messages } = useIntl();
  const [isExternalDocument, setIsExternalDocument] =
    React.useState(isSharedAssetsPage);
  const [showDisconnectModal, setShowDisconnectModal] = React.useState(false);

  const { mutation: detachOwnOemAssetDocumentation } =
    useDetachAssetDocumentation();

  const onDetachDocumentation = () => {
    setShowDisconnectModal(false);
    detachOwnOemAssetDocumentation(machine?._id).catch(() => {
      toast(
        <Toast
          type={TOAST_TYPES.ERROR}
          message={messages?.machines?.["detachDocumentationFail"]}
        />,
        {
          closeButton: false,
        },
      );
    });
  };

  const hasNoMachineDocuments =
    !machine.documentFolders?.internalId &&
    !machine.documentFolders?.externalId &&
    !machine.template?.documentFolders?.internalId &&
    !machine.template?.documentFolders?.externalId;

  const showDocumentsForMachineTemplate =
    machine?.template &&
    machine.detachedFromTemplate.documentation ===
      DocumentationDetachmentStatus.attached &&
    machine.template?.documentFolders?.internalId &&
    machine.template?.documentFolders?.externalId;

  const showDocumentsForMachine =
    (!machine?.template ||
      (machine?.template &&
        machine.detachedFromTemplate.documentation ===
          DocumentationDetachmentStatus.detached)) &&
    machine.documentFolders?.internalId &&
    machine.documentFolders?.externalId;

  const isCreatingBoxFolderIds = () => {
    if (machine.template) {
      return (
        !machine.template?.documentFolders?.internalId ||
        !machine.template?.documentFolders?.externalId
      );
    }
    return (
      !machine.documentFolders?.internalId ||
      !machine.documentFolders?.externalId
    );
  };

  const { user } = useAuth();
  const { isOemTechnician } = useRoleManager(user?.role);

  return (
    <>
      {isCreatingBoxFolderIds() ? (
        <ContentLoading />
      ) : (
        <>
          {isDetached && (
            <div className="flex items-center p-sm mb-lg text-primary font-medium text-xs rounded-lg bg-brand-lightest">
              <Info size={16} className="text-brand mr-md" />{" "}
              <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                {
                  messages["hierarchy"]["assets"]["detachedMessages"][
                    "documentation"
                  ]
                }
              </BodyText>
            </div>
          )}
          <div
            className={`flex u-margin-b-3 template-doc__buttons ${
              isSharedAssetsPage ? "!hidden" : ""
            }`}
          >
            <Tabs
              variant={TAB_VARIANTS.SEGMENT}
              tabs={[
                ...(isSharedAssetsPage
                  ? []
                  : [
                      {
                        id: DOCUMENTS_TABS.INTERNAL,
                        label: messages?.machines?.internalDocuments,
                        isActive: !isExternalDocument,
                      },
                    ]),
                {
                  id: DOCUMENTS_TABS.EXTERNAL,
                  label: messages?.machines?.externalDocuments,
                  isActive: isExternalDocument,
                },
              ]}
              onTabChange={(tabId) => {
                if (tabId === DOCUMENTS_TABS.EXTERNAL) {
                  setIsExternalDocument(true);
                } else {
                  setIsExternalDocument(false);
                }
              }}
            />
            {machine?.template &&
              machine.detachedFromTemplate.documentation ===
                DocumentationDetachmentStatus.attached && (
                <Button
                  className="ml-auto"
                  variant={BUTTON_VARIANTS.SECONDARY}
                  text={messages?.common.disconnect}
                  onClick={() => setShowDisconnectModal(true)}
                  leadingIcon={<DisconnectIcon />}
                />
              )}
          </div>

          {showDocumentsForMachineTemplate && !showDocumentsForMachine ? (
            <>
              {isExternalDocument ? (
                <BoxUIElement
                  key={machine.template?.documentFolders?.externalId}
                  token={userFolderToken}
                  entityId={machine.template?.documentFolders?.externalId}
                  rootFolderId={machine.template?.documentFolders?.externalId}
                  entityName={machine?.name}
                  canUpload={false}
                  canRename={false}
                  canDelete={false}
                  canCreateNewFolder={false}
                />
              ) : (
                <BoxUIElement
                  key={machine.template?.documentFolders?.internalId}
                  token={userFolderToken}
                  entityId={machine.template?.documentFolders?.internalId}
                  rootFolderId={machine.template?.documentFolders?.internalId}
                  entityName={machine?.name}
                  canUpload={false}
                  canRename={false}
                  canDelete={false}
                  canCreateNewFolder={false}
                />
              )}
            </>
          ) : null}

          {showDocumentsForMachine && !showDocumentsForMachineTemplate ? (
            <>
              {isExternalDocument ? (
                <BoxUIElement
                  key={machine.documentFolders?.externalId}
                  token={userFolderToken}
                  entityId={machine.documentFolders?.externalId}
                  rootFolderId={machine.documentFolders?.externalId}
                  entityName={machine?.name}
                  canUpload={!isOemTechnician && !isSharedAssetsPage}
                  canRename={!isOemTechnician && !isSharedAssetsPage}
                  canDelete={!isOemTechnician && !isSharedAssetsPage}
                  canCreateNewFolder={!isOemTechnician && !isSharedAssetsPage}
                />
              ) : (
                <BoxUIElement
                  key={machine.documentFolders?.internalId}
                  token={userFolderToken}
                  entityId={machine.documentFolders?.internalId}
                  rootFolderId={machine.documentFolders?.internalId}
                  entityName={machine?.name}
                  canUpload={!isOemTechnician}
                  canRename={!isOemTechnician}
                  canDelete={!isOemTechnician}
                  canCreateNewFolder={!isOemTechnician}
                />
              )}
            </>
          ) : null}

          {hasNoMachineDocuments ? (
            <NotFoundComponent
              key="not-found-any-machine-doc"
              icon={<DeleteTrashImage />}
              title={messages["hierarchy"]["assets"]["noDocuments"]}
            />
          ) : null}

          {machine.detachedFromTemplate.documentation ===
            DocumentationDetachmentStatus.detaching && (
            <div
              className={
                "alert-modal-overlay top-52 left-0 w-full h-full active absolute z-[5]"
              }
            >
              <div className={"alert-box-wrapper !w-[480px]"}>
                <div className="flex w-full flex-col items-center">
                  <span className="inline-flex animate-spin-slow mb-lg">
                    <LoadingIcon width="32" height="32" />
                  </span>
                  <Headline size={HEADLINE_SIZES.X_SMALL} className="mb-sm">
                    {messages.machines?.["disconnectDocumentations"]?.title}
                  </Headline>
                  <Label
                    size={LABEL_SIZES.MEDIUM}
                    color="text-secondary"
                    className="text-center font-medium"
                  >
                    {messages.hierarchy?.["assetDetachingDocumentationDesc"]}
                  </Label>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <AlertBox
        title={messages.machines?.["disconnectDocumentations"]?.question}
        description={
          <div className="flex flex-col space-y-sm">
            <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
              {messages.hierarchy?.["assetDetachDocumentationModalDesc1"]}
            </BodyText>
            <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
              {messages.hierarchy?.["assetDetachDocumentationModalDesc2"]}
            </BodyText>
            <div className="flex space-x-xs">
              <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                {messages.hierarchy?.["assetDetachDocumentationModalDesc3"]}
              </BodyText>
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="!font-bold">
                {messages.hierarchy?.["assetDetachDocumentationModalDesc4"]}
              </BodyText>
            </div>
          </div>
        }
        isOpen={!!showDisconnectModal}
        acceptButtonText={messages?.common?.["continue"]}
        acceptButtonVariant={BUTTON_VARIANTS.PRIMARY}
        cancelButtonText={messages?.common?.["cancel"]}
        image={<NoTicketsSmallIcon width="130" height="130" />}
        onAccept={onDetachDocumentation}
        onCancel={() => setShowDisconnectModal(null)}
        overlay={true}
      />
    </>
  );
};

export default MachineDocuments;

import * as React from "react";
const SvgResource = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7.923 12.085a1.208 1.208 0 1 1-1.709 1.708 1.208 1.208 0 0 1 1.709-1.708",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "m8.64 7.955.815.272a.458.458 0 0 1 .313.433v.782c0 .443.355.806.798.816l.74.016c.17.004.323.1.399.252l.384.768a.458.458 0 0 1-.085.528l-.553.553a.816.816 0 0 0-.013 1.14l.511.535c.118.123.158.3.104.46l-.272.816a.458.458 0 0 1-.434.313h-.782a.817.817 0 0 0-.816.798l-.016.74a.457.457 0 0 1-.252.399l-.768.384a.458.458 0 0 1-.528-.085l-.552-.553a.816.816 0 0 0-1.142-.013l-.534.511a.455.455 0 0 1-.46.103l-.816-.272a.458.458 0 0 1-.313-.433v-.782a.817.817 0 0 0-.798-.816l-.739-.016a.457.457 0 0 1-.399-.252l-.384-.768a.459.459 0 0 1 .086-.528l.553-.552a.816.816 0 0 0 .012-1.142l-.511-.534a.455.455 0 0 1-.103-.46l.272-.816a.458.458 0 0 1 .434-.313h.78a.816.816 0 0 0 .817-.798l.016-.74c.004-.17.1-.322.252-.399l.769-.384a.458.458 0 0 1 .528.086l.552.552a.816.816 0 0 0 1.141.013l.534-.511a.457.457 0 0 1 .46-.103ZM15.086 8.908l.707-.19a.378.378 0 0 0 .275-.305l.134-.826a.564.564 0 0 1 .466-.465l.825-.135a.377.377 0 0 0 .304-.274l.19-.708a.377.377 0 0 0-.126-.39l-.648-.53a.565.565 0 0 1-.17-.636l.297-.785a.377.377 0 0 0-.086-.4l-.518-.518a.378.378 0 0 0-.401-.086l-1.163.44-.786-.961a.378.378 0 0 0-.39-.126l-.708.19a.378.378 0 0 0-.274.304l-.135.826a.564.564 0 0 1-.465.466l-.825.134a.377.377 0 0 0-.304.275l-.19.707a.379.379 0 0 0 .125.39l.647.53c.187.153.256.41.17.636l-.298.784a.377.377 0 0 0 .086.4l.518.52a.379.379 0 0 0 .401.085l.785-.297a.562.562 0 0 1 .636.17l.53.648a.38.38 0 0 0 .39.127v0Z",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgResource;

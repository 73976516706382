import { Avatar } from "@shared/ui/Avatar";
import Dropdown, { POSITIONS } from "@shared/ui/DropDown";
import React from "react";
import { useIntl } from "react-intl";

import { usePMEMessages } from "#/src/containers/plannedEvents/helpers";
import { NotFoundComponent } from "~/components/_notFoundMessage";
import DropdownFooter from "~/components/filters/_dropdownFooter";
import DropdownHeader from "~/components/filters/_dropdownHeader";
import { NoUserAssignedIcon, TickIcon } from "~/icons/icons";
import { listSupportAccounts } from "~/services/tickets/_query";

const Assignee = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  activeFilters,
  selectValues,
  handleSelectDeleteAll,
  dropDownPosition = POSITIONS.LEFT,
}) => {
  const { messages: msg } = useIntl();
  // states
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResult, setSearchResult] = React.useState([]);
  const hasPMEListPageContext = Boolean(usePMEMessages());

  // Get All Current OEM Users Accounts Data
  const { listOwnOemSupportAccounts: assignees = [], loading } =
    listSupportAccounts();

  React.useEffect(() => {
    if (assignees.length > 0) {
      setSearchResult(assignees);
    }
  }, [assignees.length]);

  // apply search functionality
  React.useEffect(() => {
    if (assignees.length > 0) {
      if (searchTerm === "") {
        setSearchResult(assignees);
      } else {
        const newAssignees = assignees.filter((i) =>
          i.name.toLowerCase().includes(searchTerm.toLowerCase()),
        );
        setSearchResult(newAssignees);
      }
    }
  }, [searchTerm]);

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
      position={dropDownPosition}
    >
      <DropdownHeader
        title={title}
        searchTerm={searchTerm}
        onSearchTermChange={setSearchTerm}
        unselectFilter={unselectFilter}
        showPropsTitleOnly={hasPMEListPageContext}
      />
      <div className="filter-assignee filter-items">
        <div
          className="filter-assignee-item-container"
          onClick={() => selectValues(filterItem, null)}
        >
          {!hasPMEListPageContext && (
            <>
              <Avatar
                name={msg.common?.noAssignee}
                showName
                hasNoUser
                nameClassName={`!text-primary ${
                  activeFilters[filterItem.id].includes(null)
                    ? "!font-bold"
                    : ""
                }`}
              />
              {activeFilters[filterItem.id].includes(null) && (
                <span className="active">
                  <TickIcon />
                </span>
              )}
            </>
          )}
        </div>
        {loading ? null : !searchResult?.length ? (
          <NotFoundComponent
            icon={<NoUserAssignedIcon />}
            title={
              hasPMEListPageContext
                ? msg.common?.thereNoUser
                : msg.common?.thereNoAssignee
            }
          />
        ) : (
          searchResult.map((item, index) => (
            <div
              className="filter-assignee-item-container"
              key={index}
              onClick={() => selectValues(filterItem, item._id)}
            >
              <Avatar
                name={item.name}
                showName
                nameClassName={
                  activeFilters[filterItem.id].includes(item._id)
                    ? "!font-bold"
                    : ""
                }
              />
              {activeFilters[filterItem.id].includes(item._id) && (
                <span className="active">
                  <TickIcon />
                </span>
              )}
            </div>
          ))
        )}
      </div>
      <DropdownFooter
        deleteAll={() => handleSelectDeleteAll(filterItem, false)}
        disabled={!activeFilters[filterItem.id]?.length}
      />
    </Dropdown>
  );
};

export default Assignee;

import { useQuery } from "@apollo/client";

import {
  GET_AI_NOTE_QUERY,
  GET_OWN_AI_NOTE_QUERY,
  LIST_OWN_AI_NOTES_QUERY,
} from "~/api/_queries";
import { ITEMS_PER_PAGE } from "~/constants/global";
import { AiNoteType } from "~/types/aiNote";

export const useGetOwnAiNote = (aiNodeId: string, user = null) => {
  const { data, loading, refetch } = useQuery<{
    getOwnAiNote: AiNoteType;
  }>(user ? GET_OWN_AI_NOTE_QUERY : GET_AI_NOTE_QUERY, {
    variables: {
      id: aiNodeId,
    },
    skip: !aiNodeId,
  });

  return { aiNote: data?.getOwnAiNote, loading, refetch };
};

export const useListOwnAiNotes = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  skipCondition = false,
  sort = [],
  where = {},
}) => {
  const {
    data: { listOwnAiNotes } = { listOwnAiNotes: {} },
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery(LIST_OWN_AI_NOTES_QUERY, {
    variables: {
      params: {
        limit,
        skip,
        sort,
        where,
      },
    },
    skip: skipCondition,
  });

  const {
    aiNotes = [],
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
  } = listOwnAiNotes;

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          sort,
          where,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedAiNotes = [
          ...previousResult.listOwnAiNotes.aiNotes,
          ...fetchMoreResult.listOwnAiNotes.aiNotes,
        ];

        const updates = {
          ...previousResult,
          listOwnAiNotes: {
            ...previousResult.listOwnAiNotes,
            ...fetchMoreResult.listOwnAiNotes,
            aiNotes: mergedAiNotes,
          },
        };

        return updates;
      },
    });
  };

  return {
    aiNotes,
    currentPage,
    error,
    handleFetchMore,
    limit: itemLimit,
    loading,
    refetch,
    skip: itemSkip,
    totalCount,
  };
};

import {
  ITEMS_BEFORE_PAGE,
  TABLE_VIEW_ROW_HEIGHT,
} from "@shared/constants/index";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  MRT_ColumnPinningState,
} from "material-react-table";
import React, { useCallback, useEffect, useMemo, useRef } from "react";

import DataTableStyles from "../../css/data-table.module.css";
export { default as DataTableColumnResizer } from "./DataTableColumnResizer";

type DataTablePropsType = {
  isDataLoading: boolean;
  columns: MRT_ColumnDef[];
  data: any[];
  onScrollToBottom: () => void;
  totalCount: number;
  className?: string;
};

const DataTable = ({
  isDataLoading,
  columns,
  data,
  onScrollToBottom,
  totalCount,
  className = "",
}: DataTablePropsType) => {
  const tableRef = useRef();
  const tableContainerRef = useRef();

  const pinnedColumns: MRT_ColumnPinningState = useMemo(() => {
    const left = [];
    const right = [];

    columns?.forEach((column) => {
      if (Reflect.has(column, "pin")) {
        if (column.pin === "left")
          left.push(column.accessorKey || column.id || "");
        if (column.pin === "right")
          right.push(column.accessorKey || column.id || "");
      }
    });

    return { left, right };
  }, [columns?.length]);

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight <
            TABLE_VIEW_ROW_HEIGHT * ITEMS_BEFORE_PAGE &&
          !isDataLoading &&
          totalCount > data?.length
        ) {
          onScrollToBottom();
        }
      }
    },
    [onScrollToBottom, isDataLoading, data?.length, totalCount],
  );

  // a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  return (
    <div className={DataTableStyles.dataTable}>
      <MaterialReactTable
        enablePinning
        state={{
          isLoading: isDataLoading,
        }}
        enableColumnResizing
        enableStickyHeader
        enableRowVirtualization
        columnResizeMode="onChange"
        layoutMode="grid"
        enablePagination={false}
        enableColumnActions={false}
        enableSorting={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        columns={columns}
        data={data}
        rowVirtualizerInstanceRef={tableRef}
        rowVirtualizerProps={{ overscan: 5 }}
        initialState={{ columnPinning: pinnedColumns }}
        defaultColumn={{
          maxSize: 9999,
        }}
        muiTableContainerProps={{
          ref: tableContainerRef,
          onScroll: (event) => fetchMoreOnBottomReached(event.target),
        }}
        muiTablePaperProps={{
          elevation: 0,
          className: `table-view-table-wrapper ${className || ""}`,
        }}
        muiTableProps={() => ({
          className: "table-view table",
        })}
        muiTableHeadCellProps={() => ({
          className: "table-head group",
          sx: {
            ":hover": { zIndex: 5 },
          },
        })}
        muiTableBodyRowProps={() => ({
          className: "tbody-row",
        })}
        muiTableBodyCellProps={({ column, row }) => {
          return {
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              column.columnDef.onClick(row.original._id);
            },
            sx: {
              cursor: "pointer",
              borderBottomWidth: 0,
            },
            className: "table-cell",
          };
        }}
      />
    </div>
  );
};

export default DataTable;

import {
  LIMIT_TYPES,
  PRODUCT_TYPES,
  TIER_TYPES,
} from "@shared/constants/paidFeaturesHierarchy";

import {
  HD_THUMBNAIL_DIMENSIONS,
  LARGE_THUMBNAIL_DIMENSIONS,
  THUMBNAIL_DIMENSIONS,
} from "~/constants";
import type { AppConfigType } from "~/types/appConfig";
import type { AssetType } from "~/types/assetType";
import type { UserType } from "~/types/user";

export const getMachineThumbnail = (
  machine: AssetType,
  isPreview = false,
  isHD = false,
) => {
  const thumbnailDimensions = isHD
    ? HD_THUMBNAIL_DIMENSIONS
    : isPreview
    ? LARGE_THUMBNAIL_DIMENSIONS
    : THUMBNAIL_DIMENSIONS;
  if (machine?.image) {
    return machine?.image + thumbnailDimensions;
  } else if (machine?.oem?.logo) {
    return machine?.oem?.logo + thumbnailDimensions;
  } else if (!machine?.oem?.logo) {
    return "/images/default/thumbnail.svg";
  }
  return null;
};

export const isAssetCreationAllowed = (
  appConfig: AppConfigType,
  user: UserType,
  currentCount: number,
) => {
  const installedProduct = user?.oem?.installedProducts?.find(
    (product) => product.type === PRODUCT_TYPES.ASSET_HUB,
  );

  if (installedProduct?.tier === TIER_TYPES.ENTERPRISE) return true;

  const allowedDocuments =
    appConfig?.plans
      ?.find(({ type }) => type === PRODUCT_TYPES.ASSET_HUB)
      ?.tiers.find(
        ({ type }) =>
          type ===
          user?.oem?.installedProducts?.find(
            ({ type }) => type === PRODUCT_TYPES.ASSET_HUB,
          )?.tier,
      )
      ?.limits.find(({ type }) => type === LIMIT_TYPES.ASSET)?.value || 0;

  if (currentCount >= allowedDocuments) return false;

  return true;
};

// libs
import { Input } from "@shared/ui/Inputs";
import { lowerCase, upperFirst } from "lodash";
import { Trash } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";

// assets

// components
import Dropdown from "../_dropdown";

import { FILTER_VARIANTS } from "~/components/filters";

const NumberFilter = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  selectValues,
  activeFilters,
  filterVariant = FILTER_VARIANTS.EXACTLY_MATCHES,
}) => {
  const { messages: msg } = useIntl();

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
    >
      <div className="filter-dropdown-header">
        <div className="head">
          <span className="title">
            {upperFirst(lowerCase(title))}{" "}
            {filterVariant === FILTER_VARIANTS.EXACTLY_MATCHES
              ? msg?.common?.filters.matches
              : msg?.common?.filters.contains}
            :
          </span>
          <span className="delete-icon" onClick={unselectFilter}>
            <Trash size={20} className="u-text-color-gray-v2-60" />
          </span>
        </div>
        <Input
          type="number"
          placeholder={upperFirst(lowerCase(msg?.common?.filters.enterValue))}
          onChange={(e) => selectValues(filterItem, e.target.value, true)}
          value={activeFilters[filterItem._id]}
        />
      </div>
    </Dropdown>
  );
};

export default NumberFilter;

import * as React from "react";
const SvgMakulaLogo = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 26 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "m16.287 1.404 6.137 3.536a6.784 6.784 0 0 1 3.395 5.878v11.364a6.784 6.784 0 0 1-3.395 5.878l-6.137 3.536a6.764 6.764 0 0 1-6.755 0L3.395 28.06A6.784 6.784 0 0 1 0 22.182V10.818A6.784 6.784 0 0 1 3.395 4.94l6.137-3.536a6.764 6.764 0 0 1 6.755 0ZM9.655 7.575l-.064.037-5.355 3.096a6.648 6.648 0 0 0-3.318 5.684v.145c0 2.35 1.24 4.524 3.257 5.72l.061.035 5.355 3.096a6.628 6.628 0 0 0 6.573.037l.064-.037 5.355-3.096a6.648 6.648 0 0 0 3.318-5.684v-.145c0-2.35-1.24-4.524-3.257-5.72l-.061-.035-5.355-3.096a6.629 6.629 0 0 0-6.573-.037Z",
  fill: "#fff"
}));
export default SvgMakulaLogo;

import createReactClass from "create-react-class";
import * as React from "react";

import { useHandleBoxToken } from "~/hooks";

const BoxClass = createReactClass({
  componentDidMount: function () {
    // start the Explorer
    let contentExplorer = new Box.ContentExplorer();

    // Show the content explorer
    contentExplorer.show(this.props.rootFolderId, this.props.token, {
      container: `.content-explorer___${this.props.entityId}`,
      ...(this.props?.isMobile && {
        // [NOTE]: we need this hack because the prop documented by Box is not working
        // hide some action if is mobile
        canDownload: false, // hide link in drop-down menuy
        onPreview: () => {
          setTimeout(() => {
            // wait for print icon
            const controls = document.querySelector(
              ".bcpr-PreviewHeader-controls",
            );
            [...(controls?.children ?? [])].forEach(function (el) {
              // get headers buttons
              if (!el.classList.contains("bcpr-PreviewHeader-button-close"))
                el.style.display = "none"; // remove all actions but the close button
            });
          }, 100);
        },
      }),
      contentUploaderProps: {
        isFolderUploadEnabled: true,
        fileLimit: 500,
      },
      canShare: false,
      logoUrl: "",
      canDelete:
        this.props.canDelete === undefined ? true : this.props.canDelete,
      canRename:
        this.props.canRename === undefined ? true : this.props.canRename,
      canUpload:
        this.props.canUpload === undefined ? true : this.props.canUpload,
      canCreateNewFolder:
        this.props.canCreateNewFolder === undefined
          ? true
          : this.props.canCreateNewFolder,
      responseInterceptor: (response) => {
        const { data } = response;
        if (data.name && !data.parent?.etag && !data.parent?.sequence_id) {
          data.name = this.props.entityName;
        }

        if (data.modified_by) {
          data.modified_by = {};
        }

        if (data?.item_collection?.entries) {
          data.item_collection.entries = data.item_collection.entries.map(
            (item) => {
              if (item.modified_by) {
                item.modified_by = {};
              }

              if (item.parent.name) {
                item.parent.name = this.props.entityName;
              }

              if (item.path_collection?.entries[1]?.name) {
                item.path_collection.entries[1].name = this.props.entityName;
              }
              return item;
            },
          );
        }

        if (data.entries) {
          data.entries = data.entries.map((item) => {
            if (item.modified_by) {
              item.modified_by = {};
            }

            if (item.parent.name) {
              item.parent.name = this.props.entityName;
            }

            if (item.path_collection?.entries[1]?.name) {
              item.path_collection.entries[1].name = this.props.entityName;
            }

            return item;
          });
        }

        if (data.path_collection?.entries[1]?.name) {
          data.path_collection.entries[1].name = this.props.entityName;
        }

        return response;
      },
    });

    if (this.props.onSearchActive) {
      const searchElements = document.getElementsByClassName("be-search");
      if (searchElements?.length) {
        const inputChild = searchElements[0].firstChild;
        if (inputChild)
          inputChild.addEventListener("focus", this.props.onSearchActive);
      }
    }
  },

  render: function () {
    return (
      <div
        className={`content-explorer___${this.props.entityId} box-documents-container`}
      ></div>
    );
  },
});

export default (props) => {
  const [boxScriptLoaded, setBoxScriptLoaded] = React.useState(false);
  const { token, loading } = useHandleBoxToken(props?.token);
  const { isMobile } = props || {};

  React.useEffect(() => {
    const boxScriptLoadedInterval = setInterval(() => {
      if (
        typeof Box != typeof undefined &&
        "ContentExplorer" in (Box || {}) &&
        "Preview" in (Box || {})
      ) {
        setBoxScriptLoaded(true);
        clearInterval(boxScriptLoadedInterval);
      }
    }, 500);
    return () => {
      clearInterval(boxScriptLoadedInterval);
    };
  }, []);

  if ((!isMobile && ((!token && !props.token) || loading)) || !boxScriptLoaded)
    return <p className="text-sm">Connecting to folder....</p>;
  return <BoxClass key={props.token} {...props} token={props.token} />;
};

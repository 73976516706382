import AcceptInvite from "@shared/svg/customer-portal-invite-accept.svg?react";
import RemoveGhostIcon from "@shared/svg/remove-ghost.svg?react";
import AppHeader from "@shared/ui/AppHeader";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { Loading } from "~/components/general";
import { useApproveInvite, useDeclineInvite } from "~/services";

const CustomerPortalOwner = ({ accept }: { accept: boolean }) => {
  const location = useLocation();
  const history = useHistory();
  const token =
    new URLSearchParams(location.search).get("token") ||
    sessionStorage.getItem("searchParams");
  const { messages } = useIntl();
  const { approveInvite } = useApproveInvite();
  const { declineInvite } = useDeclineInvite();
  const [isInviteProcessed, setIsInviteProcessed] = useState(false);
  const [contactEmail, setContactEmail] = useState("");

  useEffect(() => {
    if (!token) return;
    (accept ? approveInvite : declineInvite)(token.replace("?token=", ""))
      .then((res) => {
        setContactEmail(res.data?.[accept ? "approveInvite" : "declineInvite"]);
        setIsInviteProcessed(true);
        sessionStorage.removeItem("searchParams");
        setTimeout(() => history.push("/oem"), 5000);
      })
      .catch((err) => {
        toast(<Toast message={err?.message} type={TOAST_TYPES.ERROR} />, {
          closeButton: false,
        });
        sessionStorage.removeItem("searchParams");
        history.push("/oem");
      });
  }, [token]);

  return (
    <div className="w-screen h-screen px-2xl">
      {!isInviteProcessed ? (
        <Loading />
      ) : (
        <>
          <AppHeader padding="py-2xl">
            <img
              src="https://workloads-alpha-makula-technology-gmbh.s3.eu-central-1.amazonaws.com/public/makula-logo-email.png"
              alt="company logo"
              className="max-h-3xl"
            />
          </AppHeader>
          <div className="flex m-auto w-full h-full items-center justify-center flex-col space-y-2xl">
            {accept ? (
              <>
                <AcceptInvite className="w-[128px] h-[128px]" />
                <div className="flex space-y-lg flex-col text-center max-w-[322px]">
                  <Headline size={HEADLINE_SIZES.X_SMALL}>
                    {messages?.connections["inviteAcceptHeading"]}
                  </Headline>
                  <BodyText size={BODY_TEXT_SIZES.SMALL} color="text-secondary">
                    {messages?.connections["inviteAcceptSubHeading"].replace(
                      "{contactEmail}",
                      contactEmail,
                    )}
                  </BodyText>
                  <BodyText size={BODY_TEXT_SIZES.SMALL} color="text-secondary">
                    {messages?.connections["inviteRedirect1"]}
                    <span
                      className="text-brand cursor-pointer"
                      onClick={() => history.push("/app")}
                    >
                      {messages?.connections["inviteRedirect2"]}
                    </span>
                    {messages?.connections["inviteRedirect3"]}
                  </BodyText>
                </div>
              </>
            ) : (
              <>
                <RemoveGhostIcon width="130" height="130" />
                <div className="flex space-y-lg flex-col text-center max-w-[322px]">
                  <Headline size={HEADLINE_SIZES.X_SMALL}>
                    {messages?.connections["inviteRejectHeading"]}
                  </Headline>
                  <BodyText size={BODY_TEXT_SIZES.SMALL} color="text-secondary">
                    {messages?.connections["inviteRejectSubHeading"].replace(
                      "{contactEmail}",
                      contactEmail,
                    )}
                  </BodyText>
                  <BodyText size={BODY_TEXT_SIZES.SMALL} color="text-secondary">
                    {messages?.connections["inviteRedirect1"]}
                    <span
                      className="text-brand cursor-pointer"
                      onClick={() => history.push("/app")}
                    >
                      {messages?.connections["inviteRedirect2"]}
                    </span>
                    {messages?.connections["inviteRedirect3"]}
                  </BodyText>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerPortalOwner;

// Events
export const LOGIN = "Login";
export const LOGIN_FAILED = "Login Failed";
export const FORGOT_PASSWORD = "Forgot Password";
export const FORGOT_PASSWORD_FAILED = "Forgot Password Failed";
export const VIEW_ANALYTICS = "View Analytics";
export const VIEW_DOCUMENTS = "View Documents";
export const VIEW_FACILITIES = "View Facilities";
export const VIEW_INVENTORY = "View Inventory";
export const VIEW_MY_WORK_ORDERS = "View My Work Orders";
export const VIEW_WORK_ORDERS = "View Work Orders";
export const VIEW_MAIN_SETTINGS = "View Main Settings";
export const VIEW_PROCEDURES = "View Procedures";
export const VIEW_EMAILS = "View Emails";

export const VIEW_MACHINES = "View Machines";
export const VIEW_PRODUCTION_LINES = "View Production Lines";
export const VIEW_COMPONENTS = "View Components";
export const VIEW_MACHINE_TEMPLATES = "View Machine Templates";
export const VIEW_ALL_TEAMS = "View All Teams";
export const VIEW_STATUS_CUSTOMIZATION = "View Status Customization";
export const VIEW_WORK_ORDER_CUSTOMIZATION = "View Work Order Customization";

export const INITIATE_SEARCH_MACHINES = "Initiate Search Machines";
export const INITIATE_SEARCH_MACHINE_TEMPLATES =
  "Initiate Search Machine Templates";

export const INITIATE_SEARCH_INVENTORY = "Initiate Search Inventory";

// Work Orders Events
export const VIEW_WORK_ORDERS_LIST = "View Work Orders List";
export const VIEW_WORK_ORDERS_BOARD = "View Work Orders Board";
export const VIEW_WORK_ORDERS_TABLE = "View Work Orders Table";
export const VIEW_WORK_ORDERS_MAP = "View Work Orders Map";
export const VIEW_WORK_ORDERS_CALENDAR = "View Work Orders Calendar";
export const INITIATE_FILTERS_WORK_ORDERS = "Initiate Filter Work Orders";
export const FILTER_WORK_ORDERS = "Filter Work Orders";
export const INITIATE_ADD_WORK_ORDER = "Initiate Add Work Order";
export const SUBMIT_NEW_WORK_ORDER = "Submit New Work Order";
export const INITIATE_SEARCH_WORK_ORDERS = "Initiate Search Work Orders";
export const INITIATE_SEARCH_MY_WORK_ORDERS = "Initiate Search My Work Orders";
export const VIEW_WORK_ORDER = "View Work Order";
export const VIEW_WORK_ORDER_OVERVIEW = "View Work Order Overview";
export const VIEW_WORK_ORDER_MACHINE_DOCUMENTATION =
  "View Work Order Machine Documentation";
export const INITIATE_SEARCH_WORK_ORDER_MACHINE_DOCUMENTATION =
  "Initiate Search Work Order Machine Documentation";
export const VIEW_WORK_ORDER_MACHINE_HISTORY =
  "View Work Order Machine History";
export const VIEW_WORK_ORDER_TIME_TRACKING = "View Work Order Time Tracking";
export const INITIATE_SCHEDULE_DATE_AND_TIME =
  "Initiate Schedule Date and Time";
export const SCHEDULE_DATE_AND_TIME = "Schedule Date and Time";
export const INITIATE_ADD_WORK_ORDER_NOTE = "Initiate Add Work Order Notes";
export const ADD_WORK_ORDER_NOTE = "Add Work Order Notes";
export const INITIATE_ATTACH_PROCEDURE_INSTANCE =
  "Initiate Attach Procedure Instance";
export const ATTACH_PROCEDURE_INSTANCE = "Attach Procedure Instance";
export const INITIATE_CHAT_MESSAGE = "Initiate Chat Message";
export const SENT_CHAT_MESSAGE = "Sent Chat Message";
export const OPEN_ATTACHED_PROCEDURE_INSTANCE =
  "Open Attached Procedure Instance";
export const SAVE_AS_DRAFT_PROCEDURE_INSTANCE =
  "Save As Draft Procedure Instance";
export const FINALIZE_PROCEDURE_INSTANCE = "Finalize Procedure Instance";
export const DELETE_ATTACHED_PROCEDURE_INSTANCE =
  "Delete Attached Procedure Instance";
export const INITIATE_CREATE_WORK_ORDERS_BOARD =
  "Initiate Create Work Orders Board";
export const CREATE_WORK_ORDERS_BOARD = "Create Work Orders Board";
export const REMOVE_WORK_ORDERS_BOARD = "Remove Work Orders Board";
export const INITIATE_SORT_WORK_ORDERS = "Initiate Sort Work Orders";
export const SORT_WORK_ORDERS = "Sort Work Orders";
export const VIEW_WORK_ORDER_EMAILS = "View Work Order Emails";
export const INITIATE_NEW_WORK_ORDER_EMAIL = "Initiate New Work Order Email";
export const SEND_WORK_ORDER_EMAIL = "Send Work Order Email";
export const VIEW_WORK_ORDER_EMAIL = "View Work Order Email";
export const INITIATE_REPLY_WORK_ORDER_EMAIL =
  "Initiate Reply Work Order Email";
export const INITIATE_REPLY_ALL_WORK_ORDER_EMAIL =
  "Initiate Reply All Work Order Email";
export const INITIATE_FORWARD_WORK_ORDER_EMAIL =
  "Initiate Forward Work Order Email";

// Machines Events
export const VIEW_MACHINES_LIST = "View Machines List";
export const VIEW_MACHINES_BOARD = "View Machines Board";
export const VIEW_MACHINES_TABLE = "View Machines Table";
export const INITIATE_FILTER_MACHINES = "Initiate Filter Machines";
export const INITIATE_FILTER_PME =
  "Initiate Filter Preventive Maintenance Events";
export const FILTER_MACHINES = "Filter Machines";
export const FILTER_PME = "Filter Preventive Maintenance Events";
export const INITIATE_ADD_MACHINE = "Initiate Add Machine";
export const SUBMIT_NEW_MACHINE = "Submit New Machine";
export const VIEW_MACHINE = "View Machine";
export const INITIATE_DELETE_MACHINE = "Initiate Delete Machine";
export const DELETE_MACHINE = "Delete Machine";
export const INITIATE_ADD_MACHINE_TEMPLATE = "Initiate Add Machine Template";
export const SUBMIT_NEW_MACHINE_TEMPLATE = "Submit New Machine Template";
export const VIEW_MACHINE_TEMPLATE = "View Machine Template";
export const INITIATE_CREATE_MACHINES_BOARD = "Initiate Create Machines Board";
export const CREATE_MACHINES_BOARD = "Create Machines Board";
export const INITIATE_SORT_MACHINES = "Initiate Sort Machines";
export const SORT_MACHINES = "Sort Machines";
export const INITIATE_SEARCH_EVENTS =
  "Initiate Preventive Maintenance Event Search ";
export const VIEW_PREVENTIVE_MAINTENANCE_EVENTS =
  "View Preventive Maintenance Events";
export const INITIATE_ADD_PREVENTIVE_MAITENANCE_EVENT =
  "Initiate Add Preventive Maintenance Event";
export const ADD_PREVENTIVE_MAITENANCE_EVENT =
  "Add Preventive Maintenance Event";
export const ADD_MACHINE_PARTS = "Add Machine Parts";
export const ADD_MACHINE_SUB_ASSETS = "Add Machine Sub Assets";
export const VIEW_3D_MODEL = "View 3D Model";
export const UPLOAD_3D_MODEL = "Upload 3D Model";
export const INITITATE_3D_SPARE_PART_REQUEST = "Initiate 3D Spare Part Request";
export const LAUNCH_3D_DEMO = "Launch 3D Demo";

// Facilities Events
export const VIEW_FACILITIES_LIST = "View Facilities List";
export const VIEW_FACILITIES_BOARD = "View Facilities Board";
export const VIEW_FACILITIES_TABLE = "View Facilities Table";
export const INITIATE_FILTER_FACILITIES = "Initiate Filter Facilities";
export const FILTER_FACILITIES = "Filter Facilities";
export const INITIATE_SEARCH_FACILITIES = "Initiate Search Facilities";
export const INITIATE_ADD_FACILITY = "Initiate Add Facility";
export const SUBMIT_NEW_FACILITY = "Submit New Facility";
export const VIEW_FACILITY = "View Facility";

// Procedures Events
export const INITIATE_SEARCH_PROCEDURES = "Initiate Search Procedures";
export const INITIATE_ADD_PROCEDURE = "Initiate Add Procedure";
export const SUBMIT_NEW_PROCEDURE = "Submit New Procedure";

// Emails Events
export const INITIATE_NEW_EMAIL = "Initiate New Email";
export const SEND_EMAIL = "Send Email";
export const VIEW_EMAIL = "View Email";
export const INITIATE_ADD_EMAIL_WORK_ORDER = "Initiate Add Email Work Order";
export const INITIATE_ASSIGN_WORK_ORDER_EMAIL =
  "Initiate Assign Work Order Email";
export const ASSIGN_WORK_ORDER_EMAIL = "Assign Work Order Email";
export const INITIATE_VIEW_EMAIL_FOLDERS = "Initiate View Email Folders";
export const VIEW_EMAIL_FOLDERS = "View Email Folders";
export const INITIATE_REPLY_EMAIL = "Initiate Reply Email";
export const INITIATE_REPLY_ALL_EMAIL = "Initiate Reply All Email";
export const INITIATE_FORWARD_EMAIL = "Initiate Forward Email";
export const SEND_EMAIL_FAILED = "Send Email Failed";

// Teams Events
export const INITIATE_ADD_NEW_TEAM = "Initiate Add New Team";
export const ADD_NEW_TEAM = "Add New Team";
export const VIEW_TEAM = "View Team";
export const INITIATE_EDIT_TEAM = "Initiate Edit Team";
export const EDIT_TEAM = "Edit Team";
export const INITIATE_DELETE_TEAM = "Initiate Delete Team";
export const DELETE_TEAM = "Delete Team";
export const VIEW_TEAM_MEMBERS = "View Team Members";
export const VIEW_TEAM_FACILITIES = "View Team Facilities";
export const VIEW_TEAM_PRODUCTION_LINES = "View Team Production Lines";
export const VIEW_TEAM_MACHINES = "View Team Machines";
export const INITIATE_ASSIGN_TEAM_MEMBER = "Initiate Assign Team Member";
export const ASSIGN_TEAM_MEMBER = "Assign Team Member";
export const INITIATE_UNASSIGN_TEAM_MEMBER = "Initiate Unassign Team Member";
export const UNASSIGN_TEAM_MEMBER = "Unassign Team Member";
export const INITIATE_ASSIGN_TEAM_FACILITY = "Initiate Assign Team Facility";
export const ASSIGN_TEAM_FACILITY = "Assign Team Facility";
export const INITIATE_UNASSIGN_TEAM_FACILITY =
  "Initiate Unassign Team Facility";
export const UNASSIGN_TEAM_FACILITY = "Unassign Team Facility";
export const INITIATE_ASSIGN_TEAM_PRODUCTION_LINE =
  "Initiate Assign Team Production Line";
export const ASSIGN_TEAM_PRODUCTION_LINE = "Assign Team Production Line";
export const INITIATE_UNASSIGN_TEAM_PRODUCTION_LINE =
  "Initiate Unassign Team Production Line";
export const UNASSIGN_TEAM_PRODUCTION_LINE = "Unassign Team Production Line";
export const INITIATE_ASSIGN_TEAM_MACHINE = "Initiate Assign Team Machine";
export const ASSIGN_TEAM_MACHINE = "Assign Team Machine";
export const INITIATE_UNASSIGN_TEAM_MACHINE = "Initiate Unassign Team Machine";
export const UNASSIGN_TEAM_MACHINE = "Unassign Team Machine";

// Analytics Events
export const INITIATE_CREATE_ANALYTICS_REPORT =
  "Initiate Create Analytics Report";
export const CREATE_ANALYTICS_REPORT = "Create Analytics Report";
export const SAVE_ANALYTICS_REPORT = "Save Analytics Report";
export const DISCARD_ANALYTICS_REPORT = "Discard Analytics Report";
export const DELETE_ANALYTICS_REPORT = "Delete Analytics Report";
export const DUPLICATE_ANALYTICS_REPORT = "Duplicate Analytics Report";

// Work Order Types Events
export const INITIATE_ADD_WORK_ORDER_TYPE = "Initiate Add Work Order Type";
export const ADD_WORK_ORDER_TYPE = "Add Work Order Type";
export const INITIATE_EDIT_WORK_ORDER_TYPE = "Initiate Edit Work Order Type";
export const EDIT_WORK_ORDER_TYPE = "Edit Work Order Type";
export const INITIATE_EDIT_WORK_ORDER_FORM = "Initiate Edit Work Order Form";
export const EDIT_WORK_ORDER_FORM = "Edit Work Order Form";
export const DELETE_WORK_ORDER_TYPE = "Delete Work Order Type";

// Work Order Status Events
export const ADD_WORK_ORDER_STATUS = "Add Work Order Status";
export const EDIT_WORK_ORDER_STATUS = "Edit Work Order Status";
export const DELETE_WORK_ORDER_STATUS = "Delete Work Order Status";

// AI Assistant
export const AI_ASSISTANT_EVENTS = {
  INITIATE_ADD_ASSISTANT: "Initiate Add Assistant",
  ADD_ASSISTANT: "Add Assistant",
  VIEW_ASSISTANT_CONTENT: "View Assistant Content",
  DELETE_ASSISTANT_DOCUMENT: "Delete Assistant Document",
  DELETE_ASSISTANT: "Delete Assistant",
  RENAME_ASSISTANT: "Rename Assistant",
};

// AI Notetaker
export const AI_NOTETAKER_EVENTS = {
  PUBLISHING_AI_NOTE: "Publishing AI Note",
};

// mixpanel event properties
export const MIXPANEL_EVENT_PROPERTIES = {
  workOrderSource: {
    workOrders: "Work Orders",
    email: "Email",
  },
};

// Scheduler
export const SCHEDULER_EVENTS = {
  PUBLISH_DATE_RANGE: "Publishing Schedule Date Range",
  ADD_NEW_SKILL: "Adding New Skills",
  SKILL_ASSIGNMENT: "Skills Assignment",
  ADD_RESOURCE: "Adding Resources",
  RESOURCE_ASSIGNMENT: "Resources Assignment",
};

// 3d
export const GUIDES_EVENTS = {
  CREATE_GUIDE: "Creating Guides",
  VIEW_GUIDE: "View Guides",
  VIEW_DEMO_GUIDE: "View Demo Guide",
};
export const THREE_D_MODEL_EVENTS = {
  CREATE_MODEL: "Creating Models",
  VIEW_MODEL: "View Model",
  VIEW_DEMO_MODEL: "View Demo Model",
};

///  GROWTH LOOP
// SHARED ASSETS
export const SIGN_UP = "Sign Up";
export const SIGN_UP_FAILED = "Sign Up Failed";
export const SHARED_ASSETS_PAGE_VIEW = "Shared Assets Page View";
export const SHARED_ASSETS_INITIATE_REQUEST = "Initiate Create Request";
export const SHARED_ASSETS_CREATE_REQUEST = "Create New Request";
export const SHARED_ASSETS_CREATE_NEW_ASSET = "Create New Asset";
export const SHARED_ASSETS_ORG_ASSETS = "View Organization Shared Assets";

export const SHARED_ASSETS_VIEW = "View Shared Asset";
export const SHARED_ASSETS_VIEW_LINK = "View Website link";
export const SHARED_ASSETS_VIEW_3D = "View Shared Asset 3D Model";
export const SHARED_ASSETS_GI = "View Shared Asset General Information";
export const SHARED_ASSETS_VIEW_DOCS = "View Shared Asset Documentation";

export const SHARED_ASSETS_VIEW_SUB_ASSETS = "View Shared Asset Sub Assets";
export const SHARED_ASSETS_VIEW_PARTS = "View Shared Asset Parts";
export const SHARED_ASSETS_VIEW_PME = "View Shared Asset PME";
export const SHARED_ASSETS_VIEW_HISTORY = "View Shared Asset History";
export const SHARED_ASSETS_VIEW_QR = "View Shared Asset QR Code";

// REQUESTS
export const VIEW_REQUESTS = "View Requests";
export const VIEW_ALL_OPEN_REQUESTS_LIST = "View All Open Requests List";
export const VIEW_ALL_CLOSED_REQUESTS_LIST = "View All Closed Requests List";
export const INITIATE_CREATE_REQUEST = "Initiate Create Requests";
export const CREATE_NEW_REQUEST = "Create New Request";
export const INITIATE_SEARCH_REQUESTS = "Initiate Search Requests";
export const VIEW_REQUEST = "View Request";
export const INITIATE_CHAT_MESSAGE_IN_REQUEST =
  "Initiate Chat Message in Requests";
export const SENT_CHAT_MESSAGE_IN_REQUEST = "Sent Chat Message in Requests";

// PRODUCTS AND PLANS
export const VIEW_PRODUCT_STORE = "View Product Store";
export const INITIATE_CONTACT_SALES_3D_STREAMS =
  "Initiate Contact Sales 3D Streams";
export const INITIATE_CONTACT_SALES_FIELD_SERVICE =
  "Initiate Contact Sales Field Service";
export const INITIATE_CONTACT_SALES_CMMS = "Initiate Contact Sales CMMS";
export const CONTACT_SALES_REQUEST_SENT = "Contact Sales Request sent";
export const VIEW_INDUSTRIAL_AI_PRICING_PLAN =
  "View Industrial AI Pricing Plan";
export const VIEW_ASSET_HUB_PRICING_PLAN = "View Asset Hub Pricing Plan";
export const INITIATE_CONTACT_SALES_INDUSTRIAL_AI =
  "Initiate Contact Sales Industrial AI";
export const INITIATE_CONTACT_SALES_ASSET_HUB =
  "Initiate Contact Sales Asset hub";
export const VIEW_ASSET_HUB = "View Asset Hub";
export const VIEW_INDUSTRIAL_AI = "View Industrial AI";
export const VIEW_3D_STREAMS = "View 3D Streams";
export const VIEW_CMMS = "View CMMS";
export const VIEW_FIELD_SERVICE = "View Field Service";

// CONNECTIONS
export const VIEW_CUSTOMER_PORTAL_TAB = "View Customer Portal Tab";
export const INITIATE_INVITE_CONTACTS = "Initiate Invite Contacts";
export const INVITED_CONTACT = "Invited Contact";
export const SHARED_ATTRIBUTES = "Shared Attributes";
export const VIEW_CONTACTS_TAB = "View Contacts Tab";
export const INITIATE_ADD_CONTACT = "Initiate Add Contact";
export const ADDED_CONTACT = "Added Contact";

// Knowledge Base
export const KNOWLEDGE_BASE_EVENTS = {
  INITIATE_FILTER_SEARCH_RESULTS: "Initiate Filter Search Results",
  FILTER_SEARCH_RESULTS: "Filter Search Results",
};

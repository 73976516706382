import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { DotsThree, MinusCircle, PencilSimple, Trash } from "phosphor-react";
import { object, oneOf } from "prop-types";
import React from "react";
import { useIntl } from "react-intl";

import getEnums from "$/settings/enums";
import TEMPLATE_TYPES from "$/settings/enums/machineTemplate/index.json";
import { ListItemBoxed } from "~/components/_listItem";
import { PopupMenuV2 } from "~/components/_popupMenu";
import { MachineTemplateLabel } from "~/components/machines/_machineTemplate";
import { QrCodeIcon } from "~/icons/icons";
import { getMachineThumbnail } from "~/utils";

const templateTypes = getEnums(TEMPLATE_TYPES, "reference");

const MachineListItem = ({ className, children }) => {
  return <ListItemBoxed className={className}>{children}</ListItemBoxed>;
};

const Thumbnail = ({ machine, variant, isSharedAssetsPage }) => {
  const thumbnail = getMachineThumbnail(machine, false, isSharedAssetsPage);

  return (
    <>
      {thumbnail && (
        <figure className={`machine-thumbnail machine-thumbnail--${variant}`}>
          <img
            src={thumbnail}
            alt={machine?.name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/images/default/thumbnail.svg";
            }}
          />
        </figure>
      )}
    </>
  );
};

const MachineName = ({
  machine,
  facilityName,
  showTag = true,
  showInlineTag = false,
  facilityClassname = "",
  isAssetQuery = false,
}) => {
  return (
    <div className="u-flex u-flex-column machine-list-item-title--container u-overflow-hidden u-text-overflow-ellipsis u-whitespace-nowrap u-padding-r-1">
      <div className="u-inline-flex u-items-center">
        <Headline
          size={HEADLINE_SIZES.X_SMALL}
          className="overflow-hidden text-ellipsis whitespace-nowrap"
          title={machine?.name}
        >
          {machine?.name}
        </Headline>
        {machine?.template && showTag && (
          <div className="u-margin-l-2">
            <MachineTemplateLabel
              showIcon
              type={machine?.template?.type || templateTypes.machineTemplate}
              label={machine?.template?.title}
              iconSize={12}
            />
          </div>
        )}
      </div>

      <div className="flex items-center">
        {machine?.template?.type && showInlineTag && (
          <>
            <MachineTemplateLabel
              showIcon
              type={machine?.template?.type}
              label={machine?.template?.title}
              iconSize={12}
            />
          </>
        )}

        {facilityName && (
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-brand"
            className={"flex items-center truncate " + facilityClassname}
            title={facilityName}
          >
            {showInlineTag && machine?.template && (
              <span className="text-secondary">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
            )}
            {facilityName}
          </BodyText>
        )}
        {isAssetQuery && !!machine.hierarchy?.length && (
          <>
            {facilityName && (
              <span className="text-secondary">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
            )}
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              className="truncate"
              color="text-secondary"
            >
              {machine.hierarchy?.map((item) => item.name).join(" > ")}
            </BodyText>
          </>
        )}
      </div>
    </div>
  );
};

const TemplateName = ({ template }) => {
  return (
    <div className="u-flex u-flex-column machine-list-item-title--container">
      <div className="u-inline-flex u-items-center">
        <Headline
          size={HEADLINE_SIZES.X_SMALL}
          className="overflow-hidden text-ellipsis whitespace-nowrap capitalize"
          title={template?.title}
        >
          {template?.title}
        </Headline>
        {template?.type && (
          <div className="u-margin-l-2">
            <MachineTemplateLabel
              showIcon
              type={template?.type}
              iconSize={12}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const PopUp = ({
  handleEditClick,
  isDelete = false,
  handleDeleteClick = () => {},
  handleUnassignClick = () => {},
  showEditButton = true,
  showDeleteButton = true,
  isSharedAssetsPage = false,
  widthClass = "w-1/5",
}) => {
  const { messages } = useIntl();
  const [showActionMenu, setShowActionMenu] = React.useState(false);

  return (
    <div
      className={`facility-user-list-column facility-user-list-column--action u-cursor-pointer ${widthClass}`}
      onClick={(e) => e.preventDefault()}
    >
      <DotsThree onClick={() => setShowActionMenu(true)} size={16} />
      {showActionMenu && (
        <div className="u-relative">
          <span className="parts-popup__container">
            <PopupMenuV2
              showEditButton={showEditButton}
              showDeleteButton={showDeleteButton}
              editLabel={
                isSharedAssetsPage ? (
                  messages["sharedAssets"]["viewDetails"]
                ) : (
                  <span className="u-flex u-items-center">
                    <PencilSimple size={20} className="u-margin-r-1" />
                    {messages["hierarchy"]["assets"]["popupMenu"]["edit"]}
                  </span>
                )
              }
              deleteLabel={
                <span className="u-flex u-items-center">
                  {isSharedAssetsPage ? (
                    <span className="!text-primary">
                      {messages["sharedAssets"]["createRequest"]}
                    </span>
                  ) : isDelete ? (
                    <>
                      <Trash size={20} className="u-margin-r-1" />
                      {messages["hierarchy"]["assets"]["popupMenu"]["delete"]}
                    </>
                  ) : (
                    <>
                      <MinusCircle size={20} className="u-margin-r-1" />
                      {messages["hierarchy"]["assets"]["popupMenu"]["unassign"]}
                    </>
                  )}
                </span>
              }
              onEditClick={() => {
                setShowActionMenu(false);
                handleEditClick();
              }}
              onDeleteClick={() => {
                setShowActionMenu(false);
                isDelete ? handleDeleteClick() : handleUnassignClick();
              }}
              close={() => setShowActionMenu(false)}
            />
          </span>
        </div>
      )}
    </div>
  );
};

const QRCode = ({ handleQRCodeClick, widthClass = null }) => (
  <div
    className={widthClass ?? "w-1/5"}
    onClick={(e) => {
      e.preventDefault();
      handleQRCodeClick();
    }}
  >
    <div className="u-inline-flex u-cursor-pointer u-padding-2 u-border-solid u-border-width-1 u-border-color-gray-v2-10 u-rounded-large">
      <QrCodeIcon color="#748094" />
    </div>
  </div>
);

const SerialNumber = ({ serialNumber, inline = false, label = "" }) => {
  const { messages } = useIntl();

  return (
    <div
      className={`machine-serial-number u-flex u-flex-column u-overflow-hidden u-text-overflow-ellipsis u-whitespace-nowrap ${
        inline ? "inline" : ""
      }`}
    >
      <Label
        size={LABEL_SIZES.X_SMALL}
        color="text-secondary"
        className="font-medium"
      >
        {label || messages?.machines?.machineDetails.detailsTab.serialNum}
        {inline && <>: &nbsp; &nbsp;</>}
      </Label>
      <BodyText
        size={BODY_TEXT_SIZES.X_SMALL}
        className="truncate"
        title={serialNumber}
      >
        {serialNumber}
      </BodyText>
    </div>
  );
};

const CreatedMachineTemplateId = ({ templateId }) => (
  <BodyText
    size={BODY_TEXT_SIZES.X_SMALL}
    className="min-w-36 max-w-36 mb-xs flex truncate"
  >
    <span className="text-secondary">ID:&nbsp;</span>
    <div className="truncate">{`#${templateId}`}</div>
  </BodyText>
);

const CreatedMachineCount = ({ machineCount }) => {
  const { messages } = useIntl();

  return (
    <BodyText
      size={BODY_TEXT_SIZES.X_SMALL}
      className="min-w-36 max-w-36 mb-xs flex"
    >
      <span className="text-secondary">
        {messages?.machines?.createdMachines}: &nbsp;
      </span>
      <div>{machineCount}</div>
    </BodyText>
  );
};

Thumbnail.propTypes = {
  machine: object,
  variant: oneOf(["large", "medium", "small", "x-small"]),
};

Thumbnail.defaultProps = {
  machine: {},
  variant: "small",
};

export default Object.assign(MachineListItem, {
  CreatedMachineCount,
  CreatedMachineTemplateId,
  MachineName,
  PopUp,
  SerialNumber,
  TemplateName,
  Thumbnail,
  QRCode,
});

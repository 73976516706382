import { CheckCircle, XCircle, WarningCircle } from "phosphor-react";
import React, { useRef } from "react";
import useOnClickOutside from "use-onclickoutside";

type InputPropsType = {
  label?: string;
  autoFocus?: boolean;
  value?: string | number;
  isInputDisabled?: boolean;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onDiscard: React.MouseEventHandler<HTMLButtonElement>;
  saveDisabled?: boolean;
  onSave: React.MouseEventHandler<HTMLButtonElement>;
  errorMessage?: string;
  clickOutsideEnabled?: boolean;
  handleClickOutside?: () => void;
};

const InputSaveAndDiscard = ({
  label = "",
  autoFocus = false,
  value = "",
  isInputDisabled = false,
  placeholder = "",
  onChange,
  onDiscard,
  saveDisabled = false,
  onSave,
  errorMessage = "",
  clickOutsideEnabled = false,
  handleClickOutside = () => {},
  ...props
}: React.ComponentPropsWithoutRef<"input"> & InputPropsType) => {
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const ref = useRef(null);
  const inputBgColor = value !== "" ? "bg-white" : "bg-gray-5";
  const inputClasses = `border-0 relative p-3 font-manrope font-medium text-xs text-gray-1000 ${inputBgColor} w-full rounded-lg transition-all duration-200 ease-in-out placeholder:text-gray-60 placeholder:opacity-100 disabled:text-gray-20 disabled:bg-gray-5 disabled:border-gray-5 disabled:opacity-100 read-only:text-gray-20 read-only:bg-gray-5 read-only:border-gray-5 read-only:opacity-100 focus:outline-0 focus:bg-white focus:border-blue-60 focus:text-gray-1000`;

  useOnClickOutside(ref, () => {
    if (clickOutsideEnabled) {
      handleClickOutside();
    }
  });

  return (
    <div className="block">
      {label && (
        <label className="block mb-2 font-manrope text-xs font-medium text-gray-60 cursor-auto">
          {label}
        </label>
      )}

      <>
        <div
          className={`relative flex w-full border-solid border ${
            isInputFocused ? "border-blue-100" : "border-gray-10"
          } rounded-lg`}
          ref={ref}
        >
          <input
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            autoFocus={autoFocus}
            value={value}
            disabled={isInputDisabled}
            placeholder={placeholder}
            className={inputClasses}
            onChange={onChange}
            {...props}
          />
          {((value && !isInputDisabled) || saveDisabled) && (
            <>
              <div className="flex items-center px-3 gap-2 border-solid border-l border-r-0 border-y-0 border-gray-10">
                <button
                  className="p-0 bg-transparent border-none text-blue-100"
                  onClick={onDiscard}
                >
                  <XCircle size={20} />
                </button>
                <button
                  className="p-0 bg-transparent border-none text-blue-100 disabled:cursor-default disabled:text-gray-20"
                  disabled={!!errorMessage || saveDisabled}
                  onClick={onSave}
                >
                  <CheckCircle size={20} weight="fill" />
                </button>
              </div>
            </>
          )}
        </div>
      </>

      {errorMessage && (
        <p className="flex items-center pl-sm mt-sm m-0 font-manrope text-xs font-medium text-red-100">
          <WarningCircle className="mr-2xs text-red-100" size={16} />
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default InputSaveAndDiscard;

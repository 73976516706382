import NoTicketsIcon from "@shared/svg/no-tickets.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import Button from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { WaitingJarIcon } from "../../icons/icons";
import useAuth from "../general/_use-auth";

import { INITIATE_ADD_PREVENTIVE_MAITENANCE_EVENT } from "#/src/constants";
import { registerMixpanelEvent } from "#/src/utils/_mixpanel";
import getEnum from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features";
import { usePMEMessages } from "~/containers/plannedEvents/helpers";
import { useGetAppConfigRecoilValue } from "~/utils/appFeatures";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

export default function NoPMScheduled({
  addEventCallback,
  isOemTechnician,
  isSharedAssetsPage,
}) {
  const {
    messages: { preventiveMaintenance: PM },
  } = useIntl();
  const [showAlert, setShowAlert] = useState(false);
  const { user } = useAuth();
  const PaidFeatures = getEnum(PAID_FEATURES, "reference");
  const appConfig = useGetAppConfigRecoilValue();

  const isFeatureAccessible = isPaidFeatureAvailable(
    PaidFeatures.preventiveMaintenance,
    user,
    appConfig,
  );

  const handleAlertAccept = () => {
    setShowAlert(false);
  };

  const { title = "", description = "" } = usePMEMessages();

  return (
    <div className="noPMScheduled-wrapper noPMScheduled">
      <div className="noPMScheduled-left">
        <h3 className="noPMScheduled-left-title">{title}</h3>
        {!isOemTechnician && !isSharedAssetsPage && description && (
          <>
            <p className="noPMScheduled-left-content">{description}</p>
            <Button
              text={PM?.noPreventiveMaintenanceAddButton}
              onClick={(e) => {
                registerMixpanelEvent(INITIATE_ADD_PREVENTIVE_MAITENANCE_EVENT);
                if (isFeatureAccessible) {
                  return addEventCallback(e);
                } else {
                  setShowAlert(true);
                }
              }}
            />
          </>
        )}
      </div>
      <div className="noPMScheduled-right">
        <WaitingJarIcon />
      </div>

      <AlertBox
        acceptButtonText={PM?.noPMAlertOkayButton}
        image={<NoTicketsIcon width="130" height="130" />}
        alertContent={
          <Headline size={HEADLINE_SIZES.X_SMALL}>
            {PM?.noPMAlertTitle}{" "}
            <a
              href={`mailto:${PM?.noPMAlertTitleEmail}`}
              className="text-brand"
            >
              {PM?.noPMAlertTitleEmail}
            </a>
          </Headline>
        }
        onAccept={handleAlertAccept}
        overlay
        isOpen={showAlert}
      />
    </div>
  );
}

import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
  useMap,
} from "react-leaflet";
import { useRecoilValue } from "recoil";
import { MAPBOX_API_KEY } from "~/apollo/_config";
import { unreadChannelMessagesAtom } from "~/atoms/_chat.jsx";
import TicketCard from "../../../components/tickets/TicketCard";
import { WORK_ORDER_MAP_BOUNDS } from "../WorkOrdersPage";
import TicketPopup from "./TicketPopup";
import markerOpenImg from "/images/marker-open.png";
import markerImg from "/images/marker.png";
import {
  useListOemCustomersInArea,
  useListOwnOemTickets,
} from "#/src/services";
import { ITEMS_PER_PAGE } from "#/src/constants";
import { MapCardLoaderComponent } from "#/src/components/skeletonLoaders/_mapCardLoader";
import { debounce, unionBy } from "lodash";

const MapView = ({ tickets = [], activeFilters }) => {
  // atoms
  const unreadChannelMessages = useRecoilValue(unreadChannelMessagesAtom);

  // local states
  const [bounds, setBounds] = React.useState(() => {
    const preBounds =
      localStorage.getItem(WORK_ORDER_MAP_BOUNDS) ||
      JSON.stringify([[52.52, 13.405]]);
    return JSON.parse(preBounds);
  });
  const [mapCoordinates, setMapCoordinates] = useState([]);
  const [mapData, setMapData] = useState([]);
  const { data, error, loading } = useListOemCustomersInArea({
    activeFilters,
    where: {
      coordinates: mapCoordinates,
    },
  });

  useEffect(() => {
    setMapData(data);
  }, [activeFilters]);

  useEffect(() => {
    if (!loading && !error) {
      setMapData((prev) => unionBy(data, prev, "_id"));
    }
  }, [data]);

  useEffect(() => {
    // Get the user's current location using browser geolocation API
    if (!localStorage.getItem(WORK_ORDER_MAP_BOUNDS)) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setBounds([
          [latitude, longitude],
          [latitude, longitude],
        ]);
      });
    }
  }, []);

  const markersRef = React.useRef([]);

  React.useEffect(() => {
    let isMounted = true;
    const notificationChannels = unreadChannelMessages?.channels || {};
    const notificationKeys = Object.keys(notificationChannels);
    const channel = {};

    if (notificationChannels) {
      notificationKeys.map((item) => {
        var splited = item.split("-");

        splited = splited[splited.length - 1];
        channel[splited] = notificationChannels[item];
      });
    }

    return () => {
      isMounted = false;
    };
  }, [tickets?.length, unreadChannelMessages?.channels]);

  const updateMapCoordinates = (mapBounds) => {
    const topLeft = mapBounds.getNorthWest().wrap();
    const topRight = mapBounds.getNorthEast().wrap();
    const bottomRight = mapBounds.getSouthEast().wrap();
    const bottomLeft = mapBounds.getSouthWest().wrap();
    setMapCoordinates([
      [topLeft.lng, topLeft.lat],
      [topRight.lng, topRight.lat],
      [bottomRight.lng, bottomRight.lat],
      [bottomLeft.lng, bottomRight.lat],
      [topLeft.lng, topLeft.lat],
    ]);
  };

  return (
    <div className="oem-map">
      <MapContainer
        key={JSON.stringify(bounds)}
        zoom={13}
        minZoom={3}
        zoomControl={true}
        scrollWheelZoom={true}
        bounds={bounds}
        style={{
          width: "100%",
          height: "auto",
          borderRadius: 0,
          zIndex: 0,
        }}
      >
        <MapComp
          data={mapData}
          updateMapCoordinates={updateMapCoordinates}
          activeFilters={activeFilters}
          ref={markersRef}
        />
      </MapContainer>
    </div>
  );
};

const CustomerMarkerTickets = ({ activeFilters, facility }) => {
  const {
    loading,
    tickets: allTickets,
    handleFetchMore,
    totalCount,
  } = useListOwnOemTickets({
    activeFilters,
    searchQuery: "",
    where: {
      facility: facility._id,
    },
  });

  const handleLoadMore = (event) => {
    if (allTickets.length >= ITEMS_PER_PAGE && !loading) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: allTickets.length,
      });
    }
  };

  return (
    <>
      {loading && <MapCardLoaderComponent />}
      {totalCount > 1 ? (
        <TicketPopup
          key={facility._id}
          tickets={allTickets}
          totalCount={totalCount}
          loading={loading}
          handleLoadMore={handleLoadMore}
        />
      ) : (
        <div>
          {!loading && (
            <div className="ticket-map-card map-box map-card-box">
              <TicketCard showStatus ticket={allTickets?.[0]} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const MapComp = React.forwardRef(
  ({ updateMapCoordinates, data, activeFilters }, markersRef) => {
    const map = useMap();
    useEffect(() => {
      // Set max bounds for the map
      map.setMaxBounds([
        [-90, -180],
        [90, 180],
      ]);
    }, [map]);
    useMapEvents({
      // on pane or zoom storing bounds
      move: (e) => {
        const { _northEast, _southWest } = e.target.getBounds();
        const north = [_northEast.lat, _northEast.lng];
        const south = [_southWest.lat, _southWest.lng];

        localStorage.setItem(
          WORK_ORDER_MAP_BOUNDS,
          JSON.stringify([north, south]),
        );
      },
      moveend: debounce((e) => {
        // Update map coordinates after the move ends
        updateMapCoordinates(e.target.getBounds());
      }, 500),
    });

    useEffect(() => {
      if (map) {
        updateMapCoordinates(map.getBounds());
      }
    }, [map]);

    const markerIcon = new Leaflet.Icon({
      iconUrl: markerImg,
      iconRetinaUrl: markerImg,
      iconAnchor: [18, 6],
      popupAnchor: [0, 0],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new Leaflet.Point(35, 35),
      className: "",
    });

    const markerOpenIcon = new Leaflet.Icon({
      iconUrl: markerOpenImg,
      iconRetinaUrl: markerOpenImg,
      iconAnchor: [18, 7],
      popupAnchor: [0, 0],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new Leaflet.Point(35, 38),
      className: "",
    });

    return (
      <>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={`https://api.mapbox.com/styles/v1/makulatechnology/cl4ms8b06001v14l5otni4von/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_API_KEY}`} // https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
        />
        {data?.map((item, index) => {
          const facilityLocation = {
            longitude: item.customerAddress.coordinates[0],
            latitude: item.customerAddress.coordinates[1],
          };

          if (
            !isNaN(facilityLocation?.latitude) &&
            !isNaN(facilityLocation?.longitude)
          ) {
            return (
              <Marker
                icon={markerIcon}
                key={item._id}
                position={[
                  facilityLocation?.latitude,
                  facilityLocation?.longitude,
                ]}
                ref={(el) => (markersRef.current[index] = el)}
                eventHandlers={{
                  popupclose: (e) => {
                    if (e.target?._icon) {
                      e.target.setIcon(markerIcon);
                    }
                  },
                  popupopen: (e) => {
                    e.target.setIcon(markerOpenIcon);
                  },
                }}
              >
                <Popup closeOnClick autoClose={false} closeButton="">
                  <>
                    <CustomerMarkerTickets
                      activeFilters={activeFilters}
                      facility={item}
                    />
                  </>
                </Popup>
              </Marker>
            );
          } else return null;
        })}
      </>
    );
  },
);

export default MapView;

import * as React from "react";
const Svg3D = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M11.797 14.46a1.3 1.3 0 0 1 1.247-.96c.73 0 1.32.59 1.32 1.32v0c0 .652-.529 1.181-1.182 1.181.653 0 1.182.529 1.182 1.181 0 .73-.59 1.32-1.32 1.32v0a1.3 1.3 0 0 1-1.247-.96",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M18.277 18.502h-1.41V13.5h1.41c1.063 0 1.926.862 1.926 1.926v1.151a1.927 1.927 0 0 1-1.926 1.925v0Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M24 12.182v7.636c0 .784-.407 1.496-1.042 1.82l-6.222 3.183c-.468.239-1.004.239-1.472 0l-6.222-3.182C8.407 21.314 8 20.602 8 19.819v-7.637c0-.784.407-1.496 1.042-1.82l6.222-3.183a1.6 1.6 0 0 1 1.472 0l6.222 3.182c.635.325 1.042 1.037 1.042 1.82Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default Svg3D;

import { useQuery } from "@apollo/client";

import {
  GET_BOX_FOLDER_ACCESS_TOKEN_QUERY,
  GET_OWN_CUSTOMER_ASSET_BY_UUID_QUERY,
  GET_OWN_CUSTOMER_ASSET_PARTS_BY_UUID_QUERY,
  GET_OWN_OEM_ASSET_BY_ID_QUERY,
  GET_OWN_OEM_ASSET_PARTS_BY_ID_QUERY,
  GET_SHARED_OEM_ASSET_BY_ID_QUERY,
  LIST_ALL_OWN_OEM_ASSETS_QUERY,
  LIST_OWN_OEM_KANBAN_ASSETS,
  LIST_OWN_SHARED_ASSETS_OEM,
  LIST_REQUESTABLE_ASSETS_QUERY,
  GET_MACHINE_3D_AUTH_TOKEN_QUERY,
  LIST_OWN_OEM_MACHINE_HISTORY,
  LIST_OWN_OEM_SHARED_ASSET_HISTORY,
} from "~/api";
import { ITEMS_PER_PAGE } from "~/constants";
import {
  defaultFilters,
  generateAssetsQueryWhereCond,
} from "~/utils/filter/assetsQueryCond";

export const useListOwnOemMachineHistory = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery = "",
  where = null,
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      where: {
        searchQuery,
        ...(where || {}),
      },
    },
  };

  const {
    data: { listOwnOemMachineHistory = {} } = {},
    loading,
    refetch,
    fetchMore,
  } = useQuery(LIST_OWN_OEM_MACHINE_HISTORY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    history = [],
    ticketCount,
  } = listOwnOemMachineHistory ?? {
    currentPage: 0,
    limit: 0,
    skip: 0,
    totalCount: 0,
    history: [],
    ticketCount: 0,
  };

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          where: {
            searchQuery,
            ...(where || {}),
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedHistory = [
          ...previousResult.listOwnOemMachineHistory.history,
          ...fetchMoreResult.listOwnOemMachineHistory.history,
        ];

        const updates = {
          ...previousResult,
          listOwnOemMachineHistory: {
            ...previousResult.listOwnOemMachineHistory,
            ...fetchMoreResult.listOwnOemMachineHistory,
            history: mergedHistory,
          },
        };

        return updates;
      },
    });
  };

  return {
    history,
    handleFetchMore,
    refetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
    ticketCount,
  };
};

export const useListOwnOemSharedAssetHistory = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery = "",
  where = null,
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      where: {
        searchQuery,
        ...(where || {}),
      },
    },
  };

  const {
    data: { listOwnOemSharedAssetHistory = {} } = {},
    loading,
    refetch,
    fetchMore,
  } = useQuery(LIST_OWN_OEM_SHARED_ASSET_HISTORY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    history = [],
    ticketCount,
  } = listOwnOemSharedAssetHistory ?? {
    currentPage: 0,
    limit: 0,
    skip: 0,
    totalCount: 0,
    history: [],
    ticketCount: 0,
  };

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          where: {
            searchQuery,
            ...(where || {}),
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedHistory = [
          ...previousResult.listOwnOemSharedAssetHistory.history,
          ...fetchMoreResult.listOwnOemSharedAssetHistory.history,
        ];

        const updates = {
          ...previousResult,
          listOwnOemSharedAssetHistory: {
            ...previousResult.listOwnOemSharedAssetHistory,
            ...fetchMoreResult.listOwnOemSharedAssetHistory,
            history: mergedHistory,
          },
        };

        return updates;
      },
    });
  };

  return {
    history,
    handleFetchMore,
    refetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
    ticketCount,
  };
};

export const useGetMachine3DAuthToken = (id) => {
  const { data: { getMachine3DAuthToken } = {}, loading } = useQuery(
    GET_MACHINE_3D_AUTH_TOKEN_QUERY,
    {
      variables: {
        id,
      },
      skip: !id,
      fetchPolicy: "network-only",
    },
  );

  return {
    loading,
    token: getMachine3DAuthToken,
  };
};

export const useGetAssetById = ({ id, skipCondition = false }) => {
  const {
    data: { getOwnOemAssetById } = {},
    error,
    loading,
  } = useQuery(GET_OWN_OEM_ASSET_BY_ID_QUERY, {
    variables: {
      id,
    },
    skip: !id || skipCondition,
  });

  return {
    error,
    loading,
    asset: getOwnOemAssetById,
  };
};

export const useGetAssetWithOrgById = ({ id, skipCondition = false }) => {
  const {
    data: { getOwnOemAssetById } = {},
    error,
    loading,
  } = useQuery(GET_SHARED_OEM_ASSET_BY_ID_QUERY, {
    variables: {
      id,
      isSharedAsset: true,
    },
    skip: !id || skipCondition,
  });

  return {
    error,
    loading,
    asset: getOwnOemAssetById,
  };
};

export const useGetAssetsPartsById = ({
  id,
  skipCondition = false,
  isSharedAsset = false,
}: {
  id: string;
  skipCondition?: boolean;
  isSharedAsset?: boolean;
}) => {
  const { data: { getOwnOemAssetById: asset = {} } = {}, loading } = useQuery(
    GET_OWN_OEM_ASSET_PARTS_BY_ID_QUERY,
    {
      variables: {
        id,
        isSharedAsset,
      },
      skip: !id || skipCondition,
    },
  );

  return {
    inventoryParts: asset?.inventoryParts,
    inventoryPartsLoading: loading,
  };
};

export const useGetAssetByUuid = ({
  uuid,
  skipCondition = false,
}: {
  uuid: string;
  skipCondition?: boolean;
}) => {
  const { data: { getOwnCustomerAssetByUuid: asset = {} } = {}, loading } =
    useQuery(GET_OWN_CUSTOMER_ASSET_BY_UUID_QUERY, {
      variables: {
        uuid,
      },
      skip: !uuid || skipCondition,
    });

  return { asset, loading };
};

export const useGetAssetPartsByUuid = ({
  uuid,
  skipCondition = false,
}: {
  uuid: string;
  skipCondition?: boolean;
}) => {
  const { data: { getOwnCustomerAssetByUuid: asset = {} } = {}, loading } =
    useQuery(GET_OWN_CUSTOMER_ASSET_PARTS_BY_UUID_QUERY, {
      variables: {
        uuid,
      },
      skip: !uuid || skipCondition,
    });

  return { assetParts: asset?.inventoryParts, loading };
};

export const useGetAssetFolderTokens = ({
  id,
  skipCondition = false,
}: {
  id: string;
  skipCondition?: boolean;
}) => {
  const { data: { getBoxFolderAccessToken } = "", loading } = useQuery(
    GET_BOX_FOLDER_ACCESS_TOKEN_QUERY,
    {
      variables: {
        assetId: id,
      },
      skip: !id || skipCondition,
    },
  );

  return {
    token: getBoxFolderAccessToken,
    loading,
  };
};

export const useListAllOwnOemAssets = ({
  activeFilters = defaultFilters,
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery = "",
  where = null,
  skipCondition = false,
  sort = [],
} = {}) => {
  const filterConditions = generateAssetsQueryWhereCond(activeFilters);
  const hasFilter = Object.keys(filterConditions)?.length;
  const variables = {
    ...(hasFilter
      ? {
          params: {
            where: {
              ...filterConditions,
              searchQuery,
              ...(where || {}),
            },
            limit,
            skip,
            sort,
          },
        }
      : {
          params: {
            limit,
            skip,
            sort,
            where: {
              searchQuery,
              ...(where || {}),
            },
          },
        }),
  };

  const {
    data: { listAllOwnOemAssets = {} } = {},
    loading: assetsListLoading,
    refetch: assetsRefetch,
    fetchMore,
    error,
  } = useQuery(LIST_ALL_OWN_OEM_ASSETS_QUERY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    assets = [],
  } = listAllOwnOemAssets;

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        ...(hasFilter
          ? {
              params: {
                where: {
                  ...filterConditions,
                  searchQuery,
                  ...(where || {}),
                },
                limit,
                skip,
                sort,
              },
            }
          : {
              params: {
                limit,
                skip,
                sort,
                where: {
                  searchQuery,
                  ...(where || {}),
                },
              },
            }),
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedAssets = [
          ...previousResult.listAllOwnOemAssets.assets,
          ...fetchMoreResult.listAllOwnOemAssets.assets,
        ];

        const updates = {
          ...previousResult,
          listAllOwnOemAssets: {
            ...previousResult.listAllOwnOemAssets,
            ...fetchMoreResult.listAllOwnOemAssets,
            assets: mergedAssets,
          },
        };

        return updates;
      },
    });
  };

  return {
    assets,
    handleFetchMore,
    assetsRefetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    assetsListLoading,
    error,
  };
};

export const useListRequestableAssets = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery = "",
  where = null,
  skipCondition = false,
  sort = [],
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      sort,
      where: {
        searchQuery,
        ...(where || {}),
      },
    },
  };

  const {
    data: { listRequestableAssets = {} } = {},
    loading: assetsListLoading,
    refetch: assetsRefetch,
    fetchMore,
  } = useQuery(LIST_REQUESTABLE_ASSETS_QUERY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    assets = [],
  } = listRequestableAssets;

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          sort,
          where: {
            searchQuery,
            ...(where || {}),
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedAssets = [
          ...previousResult.listRequestableAssets.assets,
          ...fetchMoreResult.listRequestableAssets.assets,
        ];

        const updates = {
          ...previousResult,
          listAllOwnOemAssets: {
            ...previousResult.listRequestableAssets,
            ...fetchMoreResult.listRequestableAssets,
            assets: mergedAssets,
          },
        };

        return updates;
      },
    });
  };

  return {
    assets,
    handleFetchMore,
    assetsRefetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    assetsListLoading,
  };
};

export const useListOwnOemKanbanAssets = ({
  activeFilters = defaultFilters,
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery = "",
  sort = null,
  where = null,
} = {}) => {
  const filterConditions = generateAssetsQueryWhereCond(activeFilters);
  const hasFilter = Object.keys(filterConditions)?.length;
  const variables = {
    ...(hasFilter
      ? {
          params: {
            where: {
              ...filterConditions,
              searchQuery,
              ...(where || {}),
            },
            limit,
            skip,
            ...(sort ? { sort } : {}),
          },
        }
      : {
          params: {
            limit,
            skip,
            ...(sort ? { sort } : {}),
            where: {
              searchQuery,
              ...(where || {}),
            },
          },
        }),
  };

  const {
    data: { listOwnOemKanbanAssets = {} } = {},
    loading,
    refetch: kanbanAssetsRefetch,
    fetchMore,
  } = useQuery(LIST_OWN_OEM_KANBAN_ASSETS, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    columns = [],
  } = listOwnOemKanbanAssets;

  return {
    columns,
    fetchMore,
    kanbanAssetsRefetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
  };
};

export const useListOwnSharedAssetsOem = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
} = {}) => {
  const {
    data: { listSharedAssetsOrganizations = {} } = {},
    loading,
    refetch,
    fetchMore,
  } = useQuery(LIST_OWN_SHARED_ASSETS_OEM, {
    variables: {
      params: {
        skip,
        limit,
      },
    },
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    oems = [],
  } = listSharedAssetsOrganizations;

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedOems = [
          ...previousResult.listSharedAssetsOrganizations.oems,
          ...fetchMoreResult.listSharedAssetsOrganizations.oems,
        ];

        const updates = {
          ...previousResult,
          listAllOwnOemAssets: {
            ...previousResult.listSharedAssetsOrganizations,
            ...fetchMoreResult.listSharedAssetsOrganizations,
            oems: mergedOems,
          },
        };

        return updates;
      },
    });
  };

  return {
    oems,
    fetchMore: handleFetchMore,
    refetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
  };
};

import React from "react";
import TicketCard from "~/components/tickets/TicketCard";

export const KanbanTicketCard = React.memo(
  React.forwardRef(
    ({ item, listeners, attributes, isDragging, isDragOverlay }, ref) => {
      return (
        <div
          className={`kanban-card-container ${
            isDragOverlay ? "kanban-drag-overlay" : ""
          } ${isDragging ? "dragging" : ""}`}
          ref={ref}
          {...listeners}
          {...attributes}
        >
          <TicketCard ticket={item} />
        </div>
      );
    },
  ),
  () => true,
);

import * as React from "react";
const SvgMachineTemplateIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 12 12",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  clipPath: "url(#a)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M3.937 7.015 2.215 8.737a.48.48 0 0 0 0 .678l1.72 1.72a.48.48 0 0 0 .679 0l1.721-1.72a.48.48 0 0 0 0-.68l-1.72-1.72a.48.48 0 0 0-.678 0ZM7.125 1.5v2.625H9.75"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.5 10.5h1.875a.375.375 0 0 0 .375-.375v-6L7.125 1.5h-4.5a.375.375 0 0 0-.375.375v4.5"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "a"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "currentColor",
  d: "M0 0h12v12H0z"
}))));
export default SvgMachineTemplateIcon;

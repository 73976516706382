import DeleteIcon from "@shared/svg/delete-document.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import { Input } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { upperFirst } from "lodash";
import { Plus, X } from "phosphor-react";
import React, { useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import useOnClickOutside from "use-onclickoutside";

import BoardTypes from "$/settings/enums/kanban/_type.json";
import SortingDropdown from "~/components/SortingDropdown";
import { RadioChecked, TickIcon } from "~/icons/icons";
import { getEnums } from "~/utils";

const boardTypesEnum = getEnums(BoardTypes, "reference");

export const BoardFilterTabs = ({
  data,
  boards,
  activeFilter,
  setActiveFilter,
  isAssetBoard,
  isTicketBoard,
  sorting = false,
  sortValue,
  updateSortValue,
  sortKeys,
  onOpenSortingDropdown = () => {},
  handleCreateBoardClick = () => {},
  onCreateBoardUpdate = () => {},
  onRemoveBoardClick = () => {},
}) => {
  const { messages } = useIntl();

  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const selectedItemId = useRef(null);

  const handleRemoveBoardClick = (event, itemId) => {
    event.preventDefault();
    event.stopPropagation();

    selectedItemId.current = itemId;
    onRemoveBoardClick();
    setShowRemoveAlert(true);
  };

  const handleRemoveAccept = () => {
    const itemIndex = data?.findIndex(
      (item) => item?._id === selectedItemId.current,
    );

    onCreateBoardUpdate(selectedItemId.current, true);
    setShowRemoveAlert(false);
    updateActiveBoard(itemIndex, selectedItemId.current);
    selectedItemId.current = null;
  };

  const handleRemoveAcceptFromDropdown = (selectedItemId) => {
    const itemIndex = data?.findIndex((item) => item?._id === selectedItemId);

    onCreateBoardUpdate(selectedItemId, true);
    updateActiveBoard(itemIndex, selectedItemId);
  };

  const handleRemoveCancel = () => {
    setShowRemoveAlert(false);
    selectedItemId.current = null;
  };

  const updateActiveBoard = (currentBoardIndex, currentBoardId) => {
    if (currentBoardId !== activeFilter?._id) return;

    if (isAssetBoard && currentBoardIndex === 0) {
      setActiveFilter(boardTypesEnum.assetType);
      return;
    }
    if (isTicketBoard && currentBoardIndex === 0) {
      setActiveFilter("ticket");
      return;
    }
    const nextItem = data[currentBoardIndex - 1];

    if (nextItem) {
      setActiveFilter(nextItem);
    } else {
      setActiveFilter(null);
    }
  };

  return (
    <>
      <div className="kanban-board-filters">
        <div className="page-view-tabs filter-tabs-view">
          {isTicketBoard && (
            <div
              onClick={() => setActiveFilter("ticket")}
              className="iconinfo-wrapper medium inline status group"
            >
              <div className="iconinfo-content">
                {activeFilter === "ticket" ? (
                  <Label size={LABEL_SIZES.SMALL}>
                    {messages?.tickets?.statusText}
                  </Label>
                ) : (
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    color="text-secondary"
                    className="group-hover:font-bold group-hover:text-primary"
                  >
                    {messages?.tickets?.statusText}
                  </BodyText>
                )}
              </div>
            </div>
          )}
          {isAssetBoard && (
            <div
              onClick={() => setActiveFilter(boardTypesEnum.assetType)}
              className="iconinfo-wrapper medium inline status group"
            >
              <div className="iconinfo-content">
                {activeFilter === boardTypesEnum.assetType ? (
                  <Label size={LABEL_SIZES.SMALL}>
                    {messages["hierarchy"]["assetTypes"]["nameText"]}
                  </Label>
                ) : (
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    color="text-secondary"
                    className="group-hover:font-bold group-hover:text-primary"
                  >
                    {messages["hierarchy"]["assetTypes"]["nameText"]}
                  </BodyText>
                )}
              </div>
            </div>
          )}
          {data?.map((item) => {
            return (
              <div
                key={item?._id}
                onClick={() => setActiveFilter(item)}
                className="iconinfo-wrapper medium inline"
              >
                <div className="iconinfo-content">
                  {activeFilter?._id === item?._id ? (
                    <Label
                      size={LABEL_SIZES.SMALL}
                      className="flex items-center justify-between group hover:bg-accent hover:rounded hover:py-3xs hover:pr-3xs hover:pl-xs"
                    >
                      {upperFirst(item?.label)}
                      <div
                        className="group-hover:flex hidden items-center justify-center w-lg h-lg ml-2xs bg-tertiary rounded-full hover:bg-disabled"
                        onClick={(e) => {
                          handleRemoveBoardClick(e, item?._id);
                        }}
                      >
                        <X size={12} weight="bold" className="text-primary" />
                      </div>
                    </Label>
                  ) : (
                    <BodyText
                      size={BODY_TEXT_SIZES.X_SMALL}
                      color="text-secondary"
                      className="flex items-center justify-between group hover:font-bold hover:text-primary hover:bg-accent hover:rounded hover:py-3xs hover:pr-3xs hover:pl-xs"
                    >
                      {upperFirst(item?.label)}
                      <div
                        className="group-hover:flex hidden items-center justify-center w-lg h-lg ml-2xs bg-tertiary rounded-full hover:bg-disabled"
                        onClick={(e) => {
                          handleRemoveBoardClick(e, item?._id);
                        }}
                      >
                        <X size={12} weight="bold" className="text-primary" />
                      </div>
                    </BodyText>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <CreateBoard
          showSeparator={isAssetBoard || isTicketBoard}
          options={boards}
          selectedBoards={data.map((item) => item?._id)}
          onCreateBoardUpdate={onCreateBoardUpdate}
          onBoardRemoveAccept={handleRemoveAcceptFromDropdown}
          onCreateBoardClick={handleCreateBoardClick}
        />
      </div>
      {sorting && (
        <SortingDropdown
          value={sortValue}
          onChange={updateSortValue}
          sortKeys={sortKeys}
          onOpenSortingDropdown={onOpenSortingDropdown}
        />
      )}

      <BoardRemoveAlert
        show={showRemoveAlert}
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </>
  );
};

const CreateBoard = ({
  options,
  selectedBoards,
  onCreateBoardUpdate,
  showSeparator = false,
  onBoardRemoveAccept,
  onCreateBoardClick = () => {},
}) => {
  const { messages } = useIntl();
  const [showDropdown, setShowDropdown] = useState(false);

  const createBordContainerRef = useRef();

  const handleCreateBoardClick = () => {
    onCreateBoardClick();
    setShowDropdown(true);
  };

  useOnClickOutside(createBordContainerRef, () => {
    setShowDropdown(false);
  });

  return (
    <div
      className={`u-relative create-board ${
        selectedBoards?.length || showSeparator ? "separator" : ""
      }`}
      ref={createBordContainerRef}
    >
      <Button
        variant={BUTTON_VARIANTS.LINK}
        text={messages?.common?.createBoardText}
        leadingIcon={<Plus size={12} weight="bold" />}
        onClick={handleCreateBoardClick}
      />
      {showDropdown && (
        <CreateBoardDropdown
          options={options}
          selectedBoards={selectedBoards}
          onCreateBoardUpdate={onCreateBoardUpdate}
          onBoardRemoveAccept={onBoardRemoveAccept}
        />
      )}
    </div>
  );
};

const CreateBoardDropdown = ({
  options,
  selectedBoards,
  onCreateBoardUpdate,
  onBoardRemoveAccept,
}) => {
  const { messages } = useIntl();

  const [searchTerm, setSearchTerm] = useState("");
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const selectedItemId = useRef(null);

  const handleRemoveAccept = () => {
    onBoardRemoveAccept(selectedItemId.current);
    setShowRemoveAlert(false);
    selectedItemId.current = null;
  };

  const handleRemoveCancel = () => {
    setShowRemoveAlert(false);
    selectedItemId.current = null;
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOptionClick = (event, itemId) => {
    event.preventDefault();
    event.stopPropagation();

    const itemIndex = selectedBoards.findIndex((board) => board === itemId);

    if (itemIndex >= 0) {
      selectedItemId.current = itemId;
      setShowRemoveAlert(true);
      return;
    }

    onCreateBoardUpdate(itemId);
  };

  const filteredOptions = useMemo(
    () =>
      (options || []).filter((option) =>
        option?.label?.toLowerCase()?.includes(searchTerm.toLowerCase()),
      ),
    [searchTerm, options],
  );

  return (
    <>
      <div className="create-board-dropdown-container">
        <div className="create-board-search mb-sm">
          <Input
            label={messages?.common?.selectBoardTypeText}
            keyId="select-board-type"
            value={searchTerm}
            onChange={handleSearch}
            placeholder={messages?.common?.searchText}
          />
        </div>

        {filteredOptions?.map((option) => (
          <div
            className="create-board-option"
            key={option?._id}
            onClick={(event) => handleOptionClick(event, option._id)}
          >
            <div className="create-board-option-label-container">
              <RadioChecked />
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="pl-sm">
                {upperFirst(option?.label)}
              </BodyText>
            </div>
            {selectedBoards?.includes(option._id) && <TickIcon />}
          </div>
        ))}
      </div>

      <BoardRemoveAlert
        show={showRemoveAlert}
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
        portalSelector=".create-board"
      />
    </>
  );
};

const BoardRemoveAlert = ({ show, onCancel, onAccept, portalSelector }) => {
  const { messages } = useIntl();

  return (
    <AlertBox
      isOpen={show}
      portalSelector={portalSelector}
      image={<DeleteIcon width="130" height="130" />}
      title={messages?.common?.boardRemoveAlertTitle}
      acceptButtonText={messages?.common?.delete}
      acceptButtonVariant={BUTTON_VARIANTS.DANGER}
      cancelButtonText={messages?.common?.cancel}
      onAccept={onAccept}
      onCancel={onCancel}
    />
  );
};

import * as React from "react";
const SvgConnectionLink = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 10 10",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("rect", {
  width: 10,
  height: 10,
  rx: 2.5,
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M3.75 5h2.5M4.253 6.25h-.737a1.328 1.328 0 0 1-1.329-1.328v0c0-.734.595-1.328 1.329-1.328h.737M5.746 6.25h.738c.733 0 1.328-.595 1.328-1.328v0c0-.734-.595-1.328-1.328-1.328h-.738",
  stroke: "#fff",
  strokeWidth: 0.75,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgConnectionLink;

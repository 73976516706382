import moment from "moment-timezone";

export const groupDataByMonth = <T extends Record<string, unknown>>(
  data: Array<T>,
  dateProperty: keyof T,
) => {
  if (!data) return [];

  const groupedData = data.reduce((acc, dataItem) => {
    const monthYear = moment(dataItem[dateProperty]).format("MMMM, YYYY");

    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }

    acc[monthYear].push(dataItem);

    return acc;
  }, {});

  return Object.keys(groupedData).map((key) => ({
    date: key,
    data: groupedData[key],
  }));
};

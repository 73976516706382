import {
  CalendarBlank,
  HashStraight,
  RadioButton,
  TextAlignLeft,
} from "phosphor-react";
import React from "react";

export const CUSTOM_FIELD_TYPE = {
  TEXT_AREA_FIELD: "text",
  NUMBER_FIELD: "number",
  DATE_FIELD: "date",
  SINGLE_SELECT: "singleSelect",
};

export const CUSTOM_FIELD_TYPE_ITEMS = [
  {
    value: CUSTOM_FIELD_TYPE.TEXT_AREA_FIELD,
    label: "Text",
    itemId: CUSTOM_FIELD_TYPE.TEXT_AREA_FIELD,
    icon: <TextAlignLeft className="u-text-color-green-v2-100 " size={16} />,
  },
  {
    value: CUSTOM_FIELD_TYPE.DATE_FIELD,
    label: "Date",
    itemId: CUSTOM_FIELD_TYPE.DATE_FIELD,
    icon: <CalendarBlank className="u-text-color-blue-v2-100 " size={16} />,
  },
  {
    value: CUSTOM_FIELD_TYPE.NUMBER_FIELD,
    label: "Number",
    itemId: CUSTOM_FIELD_TYPE.NUMBER_FIELD,
    icon: <HashStraight className="u-text-color-orange-v2-100 " size={16} />,
  },
  {
    value: CUSTOM_FIELD_TYPE.SINGLE_SELECT,
    label: "Single select",
    itemId: CUSTOM_FIELD_TYPE.SINGLE_SELECT,
    icon: (
      <RadioButton
        weight="fill"
        className="u-text-color-gray-v2-100 "
        size={16}
      />
    ),
  },
];

export const CUSTOMIZE_FIELDS_TABS_TYPE = {
  INVENTORY: "parts",
  FACILITY: "facilities",
  TICKET: "tickets",
  MACHINE: "machines",
  KNOWLEDGE_BASE: "knowledgeBase",
};

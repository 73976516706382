import * as React from "react";
const SvgArrowDescending = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M8.00016 12.6667L8.00016 3.33333",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M4.66667 9.33333L8 12.6667",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M11.3335 9.33333L8.00016 12.6667",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgArrowDescending;

import type { WebVisRefType } from "~/types/3d";
import { base64ToFile } from "~/utils/files";

export const saveSession = async (
  webVisComponent: React.RefObject<WebVisRefType>,
) => {
  try {
    if (!webVisComponent.current) {
      throw new Error("WebVis reference not found");
    }
    const myContext = await webVisComponent.current.requestContext();
    const sessionData = await myContext.storeSession();
    return sessionData;
  } catch (error) {
    console.error("Failed to save session:", error);
  }
};

export const getModelScreenshot = async (
  webVisComponent: React.RefObject<WebVisRefType>,
  guideName: string,
  guideId: string,
): Promise<{ file: File; snapshotName: string }> => {
  try {
    if (!webVisComponent.current) {
      throw new Error("WebVis reference not found");
    }
    const myContext = await webVisComponent.current.requestContext();
    const snapshot = await myContext
      .getViewer()
      .requestScreenshot(1280, 720, "image/png", true);
    const snaps = await myContext.getSnapshots();
    const snapshotData = await myContext.getSnapshotData(snaps[0]);
    const snapshotName = snapshotData.name.replace(/[^a-z0-9]+/gi, "");
    const file = base64ToFile(snapshot, {
      fileName: `${guideName}_${guideId}_${snapshotName}_${Date.now()}.png`,
    });
    return { file, snapshotName: snapshotName };
  } catch (error) {
    console.error("Failed to get model screenshot:", error);
  }
};

export const getSnapshotAttachment = async (
  webVisComponent: React.RefObject<WebVisRefType>,
  guideName: string,
  guideId: string,
): Promise<File> => {
  try {
    if (!webVisComponent.current) {
      throw new Error("WebVis reference not found");
    }
    const myContext = await webVisComponent.current.requestContext();
    const snaps = await myContext.getSnapshots();
    const snapshotData = await myContext.getSnapshotData(snaps[0]);
    const attachmentData = await myContext.getAttachmentData(
      snapshotData.attachmentID,
    );
    const file = base64ToFile(attachmentData, {
      fileName: `${guideName}_${guideId}_${
        snapshotData.attachmentID
      }_${Date.now()}.png`,
    });
    return file;
  } catch (error) {
    console.error("Failed to get snapshot attachment:", error);
  }
};

export const getSnapshotsLength = async (
  webVisComponent: React.RefObject<WebVisRefType>,
): Promise<number> => {
  const myContext = await webVisComponent.current.requestContext();
  const snaps = await myContext.getSnapshots();
  return snaps?.length || 0;
};

import {
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  defaultDropAnimationSideEffects,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Waypoint } from "react-waypoint";

import { useGetAppConfigRecoilValue } from "#/src/utils/appFeatures";
import isPaidFeatureAvailable from "#/src/utils/isPaidFeatureAvailable";
import getEnum from "$/settings/enums";
import BoardTypes from "$/settings/enums/kanban/_type.json";
import PAID_FEATURES from "$/settings/paid-features.json";
import { useAuth } from "~/components/general";
import KanbanColumn from "~/containers/kanban/_kanbanColumn";
import { useRoleManager } from "~/hooks/_useRoleManager";

const boardTypesEnum = getEnum(BoardTypes, "reference");

const paidFeatures = getEnum(PAID_FEATURES, "reference");

const dropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

const KanbanBoard = ({
  boardType,
  data = [],
  activeFilter,
  singleSelectFields,
  kanbanMutation,
  overlayCard: OverlayCard,
  sortingValue,
  handleScrollBottom,
  setKanbanColumns,
  isLoading,
  isFetchingMore,
  isAssetBoard,
}) => {
  const [activeCardInfo, setActiveCardInfo] = useState(null);
  const recentlyMovedToNewContainer = useRef(false);
  const kanbanRef = useRef();
  const { user } = useAuth();
  const role = user?.role;
  const appConfig = useGetAppConfigRecoilValue();
  const isTeamsPaid = isPaidFeatureAvailable(
    paidFeatures.teams,
    user,
    appConfig,
  );
  const { isOemTechnician } = useRoleManager(role);

  const offsetTop = useMemo(() => {
    if (!kanbanRef.current) return 0;

    return kanbanRef.current.offsetTop;
  }, [data.length, kanbanRef?.current]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  const onDragCancel = () => {
    setActiveCardInfo(null);
  };

  const onDragStart = ({ active }) => {
    if (!active.id) return;
    const activeCardInfo = active?.data?.current;
    const activeDragCardInfo = {
      id: active.id,
      index: activeCardInfo?.index,
      columnId: activeCardInfo?.columnId,
      item: activeCardInfo?.item,
    };
    setActiveCardInfo(activeDragCardInfo);
  };

  const onDragEnd = async ({ active, over }) => {
    const activeColumnId = active?.data?.current?.columnId;
    const activeColumn = active?.data?.current?.columnValue;
    const overColumn = over?.data?.current?.columnValue;
    if (!overColumn) return;
    if (over?.id === activeColumnId) return;
    if (activeFilter === "ticket") {
      const prevData = data;
      const updatedBoardData = data?.map((column) => {
        if (column?.column === activeColumn) {
          return {
            ...column,
            totalCount:
              column.totalCount >= 1
                ? column.totalCount - 1
                : column.totalCount,
            cards: column?.cards?.filter(
              (item) => item?._id !== activeCardInfo?.id,
            ),
          };
        } else if (column?.status?._id === overColumn) {
          return {
            ...column,
            totalCount:
              column.totalCount >= 0
                ? column.totalCount + 1
                : column.totalCount,
            cards: [
              {
                ...activeCardInfo?.item,
                updatedAt: new Date().toISOString(),
              },
              ...column?.cards,
            ],
          };
        }
        return column;
      });
      kanbanMutation(activeFilter, activeCardInfo, overColumn, prevData);
      setKanbanColumns(updatedBoardData);
      onDragCancel();
    } else {
      const prevData = data;
      const updatedBoardData = data?.map((column) => {
        if (column?.column === activeColumn) {
          return {
            ...column,
            totalCount:
              column.totalCount >= 1
                ? column.totalCount - 1
                : column.totalCount,
            cards: column?.cards?.filter(
              (item) => item?._id !== activeCardInfo?.id,
            ),
          };
        }
        if (column?.column === overColumn) {
          return {
            ...column,
            totalCount:
              column.totalCount >= 0
                ? column.totalCount + 1
                : column.totalCount,
            cards: [
              {
                ...activeCardInfo?.item,
                updatedAt: new Date().toISOString(),
              },
              ...column?.cards,
            ],
          };
        }
        return column;
      });
      kanbanMutation(activeFilter, activeCardInfo, overColumn, prevData);
      setKanbanColumns(updatedBoardData);
      onDragCancel();
    }
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [data]);

  if (isLoading && !isFetchingMore) return null;

  const KanbanColumns = () => (
    <div className="kanban-board">
      <div className="kanban-board-column-wrapper">
        {data?.map((column) => {
          const fieldInfo = singleSelectFields.find(
            (field) => column.customField === field._id,
          );
          const fieldColor = fieldInfo?.options?.find(
            (color) => color.value === column.column,
          );
          return (
            <KanbanColumn
              key={column._id}
              column={column}
              fieldColor={
                !["ticket", boardTypesEnum.assetType].includes(activeFilter)
                  ? fieldColor
                  : ""
              }
              sortingValue={sortingValue}
              boardType={boardType}
              isLoading={isFetchingMore}
              isTeamsPaid={isTeamsPaid}
              isAssetBoard={
                isAssetBoard && activeFilter === boardTypesEnum.assetType
              }
            />
          );
        })}
      </div>
    </div>
  );

  return (
    <>
      <div
        className={`kanban-board-wrapper ${boardType}`}
        ref={kanbanRef}
        style={{ height: `calc(100vh - ${offsetTop}px)` }}
      >
        {(isAssetBoard && activeFilter === boardTypesEnum.assetType) ||
        isOemTechnician ? (
          <KanbanColumns />
        ) : (
          <DndContext
            sensors={sensors}
            collisionDetection={pointerWithin}
            measuring={{
              droppable: {
                strategy: MeasuringStrategy.Always,
              },
            }}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragCancel={onDragCancel}
          >
            <KanbanColumns />
            {createPortal(
              <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
                {activeCardInfo?.id && (
                  <OverlayCard
                    item={activeCardInfo?.item}
                    isDragOverlay={true}
                    activeFilter={activeFilter}
                    ticketType={activeCardInfo?.item?.ticketType || ""}
                  />
                )}
              </DragOverlay>,
              document.body,
            )}
          </DndContext>
        )}
        {handleScrollBottom && <Waypoint onEnter={handleScrollBottom} />}
      </div>
    </>
  );
};

export default KanbanBoard;

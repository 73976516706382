import activityLog from "~/lang/de-DE/activityLog.json";
import aiAssistant from "~/lang/de-DE/ai-assistant.json";
import aiNotetaker from "~/lang/de-DE/ai-notetaker.json";
import analytics from "~/lang/de-DE/analytics";
import branding from "~/lang/de-DE/branding";
import chat from "~/lang/de-DE/chat";
import common from "~/lang/de-DE/common";
import components from "~/lang/de-DE/components.json";
import connections from "~/lang/de-DE/connections.json";
import dataManagement from "~/lang/de-DE/dataManagement";
import documents from "~/lang/de-DE/documents";
import emails from "~/lang/de-DE/emails.json";
import facilities from "~/lang/de-DE/facilities";
import filters from "~/lang/de-DE/filters";
import forms from "~/lang/de-DE/forms";
import hierarchy from "~/lang/de-DE/hierarchy.json";
import inventory from "~/lang/de-DE/inventory";
import knowledgeBase from "~/lang/de-DE/knowledgeBase.json";
import login from "~/lang/de-DE/login";
import machines from "~/lang/de-DE/machines";
import maintenanceDocuments from "~/lang/de-DE/maintenanceDocuments";
import menus from "~/lang/de-DE/menus";
import plans from "~/lang/de-DE/plans";
import preventiveMaintenance from "~/lang/de-DE/preventiveMaintenance";
import procedures from "~/lang/de-DE/procedures";
import productionLines from "~/lang/de-DE/productionLines.json";
import requests from "~/lang/de-DE/requests.json";
import resourcePlanning from "~/lang/de-DE/resourcePlanning";
import resources from "~/lang/de-DE/resources.json";
import scheduling from "~/lang/de-DE/scheduling.json";
import settings from "~/lang/de-DE/settings";
import sharedAssets from "~/lang/de-DE/sharedAssets.json";
import teams from "~/lang/de-DE/teams.json";
import tickets from "~/lang/de-DE/tickets";
import workOrderFormConfiguration from "~/lang/de-DE/workOrderFormConfiguration.json";
import workOrderTypes from "~/lang/de-DE/workOrderTypes.json";
import LOCALES from "~/lang/locales";

export default {
  [LOCALES[1].locale]: {
    analytics,
    chat,
    common,
    connections,
    dataManagement,
    documents,
    facilities,
    forms,
    inventory,
    login,
    machines,
    maintenanceDocuments,
    menus,
    preventiveMaintenance,
    resourcePlanning,
    settings,
    tickets,
    branding,
    procedures,
    filters,
    productionLines,
    components,
    teams,
    emails,
    workOrderTypes,
    workOrderFormConfiguration,
    activityLog,
    aiAssistant,
    aiNotetaker,
    hierarchy,
    scheduling,
    resources,
    knowledgeBase,
    sharedAssets,
    requests,
    plans,
  },
};

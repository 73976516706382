import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_SIZES } from "@shared/ui/Button";
import { Textarea } from "@shared/ui/Inputs";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import useOnClickOutside from "use-onclickoutside";

import { CUSTOM_FIELD_MAX_TEXT_CONTENT_LENGTH } from "#/src/constants";

const CustomTextField = ({
  field,
  handleTextFieldSave,
  isEditable = true,
  dropdownClassname = "",
}) => {
  const { messages } = useIntl();

  const ref = useRef();
  const cTextField = useRef({});

  const [isOpen, setIsOpen] = useState(false);
  const [charCount, setCharCount] = useState(field?.value?.length);

  const toggle = () => {
    setIsOpen((prev) => {
      if (!prev && !cTextField.current[field?._id]?.length) {
        cTextField.current[field?._id] = field?.value;
        setCharCount(field?.value?.length);
      }

      return !prev;
    });
  };

  const updateTextField = (textFields) => {
    cTextField.current = textFields;
    toggle();
  };

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (field) {
      setCharCount(field.value?.length || 0);
    }
  }, [field]);

  return (
    <>
      <div className="u-flex u-items-center custom-field-row u-padding-b-1">
        <div className="u-width-2">
          <BodyText
            title={field?.label}
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
            className="overflow-hidden text-ellipsis no-styles"
          >
            {field?.label}:
          </BodyText>
        </div>
        <div className="u-width-8">
          <div
            ref={ref}
            className={`c-dropdown ${isOpen ? "c-dropdown--shown" : ""}`}
          >
            <button
              className={`c-textarea-btn u-width-100 ${
                !isEditable
                  ? "pointer-events-none !bg-transparent"
                  : "cursor-pointer"
              }`}
              onClick={() => {
                if (!isEditable) return;
                toggle();
              }}
            >
              <span className={field?.value ? "u-text-color-gray-v2-100" : ""}>
                {field?.value
                  ? field.value
                  : `${messages?.common?.emptyProperty}`}
              </span>
            </button>
            <div
              className={
                "c-dropdown-menu customize-field-dropdown property-userDropdownMenu flex-col text-area-field-wrapper " +
                dropdownClassname
              }
            >
              <div>
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="mb-sm"
                >
                  {charCount || 0}/{CUSTOM_FIELD_MAX_TEXT_CONTENT_LENGTH}
                </BodyText>
                <div className="w-80">
                  <Textarea
                    maxLength={CUSTOM_FIELD_MAX_TEXT_CONTENT_LENGTH}
                    placeholder={messages?.tickets?.renderSchedule.addTextHere}
                    value={cTextField.current[field?._id] || ""}
                    onChange={(e) => {
                      const { value } = e.target;

                      cTextField.current[field?._id] = value;
                      setCharCount((prev) => value.length);
                    }}
                    className="h-6xl"
                  />
                </div>
                <div className="flex justify-end mt-md">
                  <Button
                    size={BUTTON_SIZES.SMALL}
                    text={messages?.common?.save}
                    onClick={(e) => {
                      handleTextFieldSave(
                        e,
                        cTextField.current,
                        field?._id,
                        updateTextField,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomTextField;

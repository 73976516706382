import Resource from "@shared/svg/resource.svg?react";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import React from "react";

const ResourcesPreview = ({ resources }) => (
  <div className="flex items-center space-x-md mt-md">
    <Resource className="flex-shrink-0 w-lg h-lg text-secondary" />
    <div className="flex flex-wrap gap-sm">
      {resources.map((resource) => (
        <Label
          className="bg-success-lightest hover:bg-success px-xs py-2xs rounded text-success hover:text-inverse text-ellipsis overflow-hidden whitespace-nowrap max-w-32"
          key={resource._id}
          size={LABEL_SIZES.SMALL}
          title={`${resource.name}${
            resource.resourceId ? ` - ${resource.resourceId}` : ""
          }`}
        >
          {resource.name}
          {resource.resourceId ? ` - ${resource.resourceId}` : ""}
        </Label>
      ))}
    </div>
  </div>
);

export default ResourcesPreview;

import * as React from "react";
const SvgTabsCalendarIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 16 16",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  d: "M4.999 1.998v2m6.002-2v2M1.997 6.333h12.005"
}), /* @__PURE__ */ React.createElement("rect", {
  width: 12.005,
  height: 11.005,
  x: 1.997,
  y: 2.998,
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  rx: 3
}));
export default SvgTabsCalendarIcon;

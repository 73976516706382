// libs
import React from "react";
import { useIntl } from "react-intl";

// assets
import DropdownHeader from "./_dropdownHeader";
import { NoUserAssignedIcon, TickIcon } from "../../icons/icons";

// utils
import { capitalizeFirstLetter } from "../../utils";

// components
import Dropdown from "../_dropdown";
import { NotFoundComponent } from "../_notFoundMessage";

const MachineAssignedFilter = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  activeFilters,
  selectValues,
  handleSelectDeleteAll,
}) => {
  const { messages: msg } = useIntl();

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
    >
      <DropdownHeader
        searchable={false}
        title={capitalizeFirstLetter(title)}
        unselectFilter={unselectFilter}
      />
      <div className="filter-facility-name filter-items">
        {!filterItem.options?.length ? (
          <NotFoundComponent
            icon={<NoUserAssignedIcon />}
            title={`${msg?.common?.filters.thereIsNo} ${title}.`}
          />
        ) : (
          filterItem.options?.map((item, index) => (
            <div
              className="filter-facility-name-item-container"
              key={index}
              onClick={() => {
                if (
                  activeFilters[filterItem?.id || filterItem?._id] ===
                  item?.value
                ) {
                  handleSelectDeleteAll(filterItem, false);
                  return;
                }
                selectValues(filterItem, item?.value, true);
              }}
            >
              <span
                className={`filter-facility-name-item ${
                  activeFilters[filterItem?.id || filterItem?._id] ===
                  item?.value
                    ? "active"
                    : ""
                }`}
              >
                {item?.label}
              </span>
              {activeFilters[filterItem?.id || filterItem?._id] ===
                item?.value && (
                <span className="active">
                  <TickIcon />
                </span>
              )}
            </div>
          ))
        )}
      </div>
    </Dropdown>
  );
};

export default MachineAssignedFilter;

import Accordion, { ACCORDION_VARIANTS } from "@shared/ui/Accordion";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Tooltip from "@shared/ui/Tooltip";
import { AlignLeft } from "phosphor-react";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import CustomDateField from "~/components/common/CustomDateField";
import CustomNumberField from "~/components/common/CustomNumberField";
import CustomSelectField from "~/components/common/CustomSelectField";
import CustomTextField from "~/components/common/CustomTextField";
import { fieldType } from "~/constants/filters/customFieldTypes";

const CustomFieldsAccordion = ({
  className = "",
  numberFieldDropdownClassName = "",
  dateFieldDropdownClassName = "",
  selectFieldClassName = "",
  textFieldDropdownClassName = "",
  customFieldList,
  handleTextFieldSave = () => {},
  handleSelectFieldSave = () => {},
  isExpanded = true,
  hasAccordion = true,
  isEditable = true,
  selectFieldValueClassname,
  selectFieldLabelClassname,
  dropDownPosition = "",
  title = "",
  icon = <AlignLeft size={16} />,
  hideEmptyFields = false,
  dataTipMessage = "",
  showEmptyFieldsButton = true,
}) => {
  const { messages } = useIntl();
  const [showEmptyFields, setShowEmptyFields] = useState(!hideEmptyFields);
  const actualCustomFieldList = customFieldList?.filter(
    (field) => field?.fieldType !== fieldType.address,
  );
  const disableEmptyFieldsButton = !actualCustomFieldList.length;
  const dataTip = !actualCustomFieldList.length
    ? dataTipMessage || messages.common.nonConfiguredCustomFields
    : "";

  const CustomField = ({ field }) => {
    const commonProps = {
      key: field?._id,
      field: field,
      handleTextFieldSave: handleTextFieldSave,
      isEditable: isEditable,
    };

    if (field?.fieldType === fieldType.address) {
      return null;
    } else if (field?.fieldType === fieldType.singleSelect) {
      return (
        <div
          key={field?._id}
          className={selectFieldClassName + " u-padding-b-1"}
        >
          <CustomSelectField
            field={field}
            handleSelectFieldSave={(fieldId, newValue) => {
              if (field?.value?.toLowerCase() === newValue.toLowerCase()) {
                handleSelectFieldSave(fieldId, null);
              } else {
                handleSelectFieldSave(fieldId, newValue);
              }
            }}
            dropDownPosition={dropDownPosition}
            isEditable={isEditable}
            selectFieldValueClassname={selectFieldValueClassname}
            selectFieldLabelClassname={selectFieldLabelClassname}
          />
        </div>
      );
    } else if (field?.fieldType === fieldType.number) {
      return (
        <CustomNumberField
          {...commonProps}
          dropdownClassname={numberFieldDropdownClassName}
        />
      );
    } else if (field?.fieldType === fieldType.date) {
      return (
        <CustomDateField
          {...commonProps}
          dropdownClassname={dateFieldDropdownClassName}
        />
      );
    } else {
      return (
        <CustomTextField
          {...commonProps}
          dropdownClassname={textFieldDropdownClassName}
        />
      );
    }
  };

  const showField = (field) => !!field.value?.toString() || !!showEmptyFields;
  const hasEmptyFields = customFieldList?.some(
    (field) =>
      field.type !== fieldType.address &&
      field.fieldType !== fieldType.address &&
      !field.value?.toString(),
  );

  const customFieldMapper = () =>
    customFieldList?.map((field) =>
      showField(field) ? <CustomField key={field?._id} field={field} /> : null,
    );

  return hasAccordion ? (
    <Accordion
      className={
        "custom-field-accordion mb-md st-accordion-content " + className
      }
      defaultExpanded={isExpanded}
      variant={ACCORDION_VARIANTS.CUSTOM_FIELDS}
      title={title || messages?.common?.customFieldsAccordion}
    >
      {customFieldMapper()}
      {showEmptyFieldsButton &&
        (hasEmptyFields || disableEmptyFieldsButton) && (
          <div className="flex">
            <Button
              variant={BUTTON_VARIANTS.LINK}
              text={
                showEmptyFields
                  ? messages.tickets.hideEmptyFields
                  : messages.tickets.showEmptyFields
              }
              onClick={() => setShowEmptyFields((prev) => !prev)}
              className="mt-md"
              disabled={disableEmptyFieldsButton}
              data-for="empty-fields-cta-tooltip"
              data-tip={dataTip}
            />

            <Tooltip id="empty-fields-cta-tooltip" place="top" />
          </div>
        )}
    </Accordion>
  ) : (
    customFieldMapper()
  );
};

export default CustomFieldsAccordion;

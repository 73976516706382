import React from "react";
import ContentLoader from "react-content-loader";

export const KanbanCardLoaderComponent = ({
  total = 2,
  width = 304,
  height = 76,
}) => {
  const loaders = [];
  for (let i = 0; i < total; i++) {
    loaders.push(
      <ContentLoader
        className="machine-content-loader"
        key={i}
        width={width}
        height={height}
      >
        <rect x="12" y="12" rx="0" ry="0" width="140" height="16" />
        <rect x="12" y="48" rx="0" ry="0" width="280" height="16" />
      </ContentLoader>,
    );
  }

  return loaders;
};

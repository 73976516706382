import { PRODUCT_TYPES } from "@shared/constants/paidFeaturesHierarchy";
import Sidebar3DIconFilledOld from "@shared/svg/3d-active-old.svg?react";
import Sidebar3DIconFilled from "@shared/svg/3d-active.svg?react";
import Sidebar3DIcon from "@shared/svg/3d.svg?react";
import AiIconFilled from "@shared/svg/ai-active.svg?react";
import AiIcon from "@shared/svg/ai-grey.svg?react";
import SidebarAnalyticsFilledIconV2 from "@shared/svg/analytics-active.svg?react";
import SidebarAnalyticsIconV2 from "@shared/svg/analytics.svg?react";
import AssetHubIconActive from "@shared/svg/asset-hub-active.svg?react";
import AssetHubIcon from "@shared/svg/asset-hub.svg?react";
import AssetsManagementActive from "@shared/svg/assets-management-active.svg?react";
import AssetsManagement from "@shared/svg/assets-management.svg?react";
import CalendarBlankFilled from "@shared/svg/calendar-blank-filled.svg?react";
import CalendarBlank from "@shared/svg/calendar-blank.svg?react";
import CMMSIconActive from "@shared/svg/cmms-active.svg?react";
import CMMSIcon from "@shared/svg/cmms.svg?react";
import SidebarFacilityFilledIconV2 from "@shared/svg/facilities-active.svg?react";
import SidebarFacilityIconV2 from "@shared/svg/facilities.svg?react";
import FieldServiceIconActive from "@shared/svg/field-service-active.svg?react";
import FieldServiceIcon from "@shared/svg/field-service.svg?react";
import IndustrialAiIconActive from "@shared/svg/industrial-ai-active.svg?react";
import IndustrialAiIcon from "@shared/svg/industrial-ai.svg?react";
import WorkManagementActive from "@shared/svg/work-management-active.svg?react";
import WorkManagement from "@shared/svg/work-management.svg?react";
import { getAllRoles } from "@shared/utils/getAllRoles";
import * as React from "react";
import { Redirect, useParams } from "react-router-dom";

import PAID_FEATURES from "$/settings/paid-features.json";
import { APP_FEATURES_ENUM, COLOR } from "~/constants";
import { isOemAdmin } from "~/hooks/_useRoleManager";
import {
  EnvelopFilled,
  EnvelopOutline,
  InventoryFilledIcon,
  InventoryIcon,
  SidebarAnalyticsFilledIcon,
  SidebarAnalyticsIcon,
  SidebarFacilityFilledIcon,
  SidebarFacilityIcon,
  SidebarMyTicketFilledIcon,
  SidebarMyTicketfolderIcon,
  SidebarProceduresFilledIcon,
  SidebarProceduresIcon,
  HourglassIcon,
} from "~/icons/icons";
import { getEnums } from "~/utils";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

const MyWorkOrdersPage = React.lazy(() =>
  import("~/containers/ticket/MyWorkOrdersPage"),
);
const WorkOrdersPage = React.lazy(() =>
  import("~/containers/ticket/WorkOrdersPage"),
);
const SingleSharedAssetOrganization = React.lazy(() =>
  import("~/containers/Assets/Shared/SingleSharedAssetOrganization"),
);
const SingleSharedAsset = React.lazy(() =>
  import("~/containers/Assets/Shared/SingleSharedAsset"),
);
const Assets = React.lazy(() => import("~/containers/Assets"));
const SharedAssets = React.lazy(() => import("~/containers/Assets/Shared"));
const Requests = React.lazy(() => import("~/containers/Requests"));
const Connections = React.lazy(() =>
  import("~/containers/Connections/AllConnections"),
);
const SingleConnection = React.lazy(() =>
  import("~/containers/Connections/SingleConnection"),
);
const AssetTemplates = React.lazy(() =>
  import("~/containers/Assets/AssetTemplates"),
);
const OemSingleTicket = React.lazy(() =>
  import("~/containers/OemSingleTicket"),
);
const SingleRequest = React.lazy(() => import("~/containers/Requests/Single"));
const MySingleTicket = React.lazy(() => import("~/containers/MySingleTicket"));
const AssetSingle = React.lazy(() => import("~/containers/AssetSingle"));
const AssetTemplateSingle = React.lazy(() =>
  import("~/containers/AssetTemplateSingle"),
);
const FacilityListPage = React.lazy(() =>
  import("~/containers/facility/FacilityListPage"),
);
const SingleFacility = React.lazy(() =>
  import("~/containers/facility/OemSingleFacility"),
);
const MainSettingsLayout = React.lazy(() =>
  import("~/layout/_mainSettingsLayout"),
);
const Analytics = React.lazy(() => import("~/containers/analytics/Analytics"));
const Inventory = React.lazy(() =>
  import("~/containers/inventory/InventoryListPage"),
);
const Procedures = React.lazy(() =>
  import("~/containers/procedures/template/Procedures"),
);
const ProcedureSingle = React.lazy(() =>
  import("~/containers/procedures/template/ProcedureSingle"),
);
const ProcedureInstanceSingle = React.lazy(() =>
  import("~/containers/procedures/instance/ProcedureInstanceSingle"),
);
const EmailLayout = React.lazy(() => import("~/containers/emails/EmailLayout"));
const AiAssistant = React.lazy(() => import("~/containers/AiAssistant"));
const ThreeDModel = React.lazy(() => import("~/containers/3d/Model"));
const ThreeDGuide = React.lazy(() => import("~/containers/3d/Guide"));
const ThreeDModels = React.lazy(() => import("~/containers/3d/Models"));
const ThreeDGuides = React.lazy(() => import("~/containers/3d/Guides"));
const AiNotetaker = React.lazy(() => import("~/containers/AiNotetaker"));
const AiNote = React.lazy(() => import("~/containers/AiNote"));
const ScheduleDispatch = React.lazy(() =>
  import("~/containers/scheduling/ScheduleDispatch"),
);
const PlannedEvents = React.lazy(() =>
  import("~/containers/plannedEvents/PlannedEvents"),
);
const KnowledgeBase = React.lazy(() => import("~/containers/KnowledgeBase"));
const AISearch = React.lazy(() => import("~/containers/AISearch"));
const AIOCR = React.lazy(() => import("~/containers/AIOCR"));

const ALL_ROLES = getAllRoles({
  getOEM: true,
  getCRM: false,
  getFacility: false,
});

const facilityRedirects = [
  {
    id: "app",
    path: "/app/facility/settings",
    exact: true,
    label: "Assets",
    Main: () => <Redirect to="/app/settings/personal/general" />,
  },
  {
    id: "app",
    path: "/app/facility/assets",
    exact: true,
    label: "Assets",
    Main: () => <Redirect to="/app/asset-hub/shared-assets" />,
  },
  {
    id: "app",
    path: "/app/facility/assets/all/:id",
    exact: true,
    label: "Assets",
    Main: () => <Redirect to="/app/asset-hub/shared-assets" />,
  },
  {
    id: "app",
    path: "/app/facility/work-orders",
    exact: true,
    label: "Assets",
    Main: () => <Redirect to="/app/asset-hub/requests" />,
  },
  {
    id: "app",
    path: "/app/facility/closed-work-orders",
    exact: true,
    label: "Assets",
    Main: () => <Redirect to="/app/asset-hub/requests" />,
  },
  {
    id: "app",
    path: "/app/facility/work-orders/:id",
    exact: true,
    label: "Assets",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/asset-hub/requests/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/facility/closed-work-orders/:id",
    exact: true,
    label: "Assets",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/asset-hub/requests/${params.id}`} />;
    },
  },
];

const routes = [
  {
    id: "app",
    path: "/app/work-orders",
    exact: true,
    label: "Tickets",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    key: "openTickets",
    Main: () => {
      return <Redirect to="/app/work/work-orders" />;
    },
  },
  {
    id: "app",
    path: "/app/work/work-orders",
    exact: true,
    label: "Tickets",
    iconOld: <WorkManagement className="icon h-3xl w-3xl flex-shrink-0" />,
    iconActiveOld: (
      <WorkManagementActive className="icon-active h-3xl w-3xl flex-shrink-0" />
    ),
    iconV2: <CMMSIcon className="icon h-3xl w-3xl flex-shrink-0" />,
    iconActiveV2: (
      <CMMSIconActive className="icon-active h-3xl w-3xl flex-shrink-0" />
    ),
    key: "openTickets",
    tooltipKey: "cmms",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) => installedProduct.type === PRODUCT_TYPES.CMMS,
      ),
    showInNavigation: true,
    Main: () => {
      return <WorkOrdersPage productType={PRODUCT_TYPES.CMMS} />;
    },
  },
  {
    id: "app",
    path: "/app/work/work-orders",
    exact: true,
    label: "Tickets",
    iconV2: <FieldServiceIcon className="icon h-3xl w-3xl flex-shrink-0" />,
    iconActiveV2: (
      <FieldServiceIconActive className="icon-active h-3xl w-3xl flex-shrink-0" />
    ),
    key: "openTickets",
    tooltipKey: "field-service",
    enableIf: (user, appFeatures) =>
      appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) &&
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    showInNavigation: true,
    Main: () => {
      return <WorkOrdersPage productType={PRODUCT_TYPES.FIELD_SERVICE} />;
    },
  },
  {
    id: "app",
    path: "/app/work-orders/:id",
    exact: true,
    label: "OEM Ticket",
    icon: "far fa-square",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/work/work-orders/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/work/work-orders/:id",
    exact: true,
    label: "OEM Ticket",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    icon: "far fa-square",
    Main: () => {
      return <OemSingleTicket />;
    },
  },
  {
    id: "app",
    path: "/app/my-work-orders",
    exact: true,
    key: "myTickets",
    label: "My Tickets",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    icon: <SidebarMyTicketfolderIcon className="icon" />,
    iconActive: <SidebarMyTicketFilledIcon className="icon-active" />,
    Main: () => {
      return <Redirect to="/app/work/my-work-orders" />;
    },
  },
  {
    id: "app",
    path: "/app/work/planned-events",
    exact: true,
    key: "myPlannedEvents",
    label: "My Planned Events",
    icon: <HourglassIcon width={"18"} height={"20"} />,
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    iconActive: (
      <HourglassIcon
        width={"18"}
        height={"20"}
        fillColor={COLOR.$blue_v2_100}
      />
    ),
    Main: () => <PlannedEvents />,
  },
  {
    id: "app",
    path: "/app/work/my-work-orders",
    exact: true,
    key: "myTickets",
    label: "My Tickets",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    icon: <SidebarMyTicketfolderIcon className="icon" />,
    iconActive: <SidebarMyTicketFilledIcon className="icon-active" />,
    Main: () => {
      return <MyWorkOrdersPage />;
    },
  },
  {
    id: "app",
    path: "/app/my-work-orders/:id",
    exact: true,
    label: "My Ticket",
    icon: "far fa-square",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/work/my-work-orders/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/work/my-work-orders/:id",
    exact: true,
    label: "My Ticket",
    icon: "far fa-square",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    Main: () => {
      return <MySingleTicket />;
    },
  },
  {
    id: "app",
    path: "/app/assets/machines",
    exact: true,
    label: "Machines",
    key: "machines",
    Main: ({ appFeatures }) =>
      appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ? (
        <Redirect to="/app/asset-hub/my-assets" />
      ) : (
        <Redirect to="/app/assets/all" />
      ),
  },
  {
    id: "app",
    path: "/app/assets/machines/templates",
    exact: true,
    label: "Machine Templates",
    key: "machines",
    Main: () => {
      return <Redirect to="/app/assets/templates" />;
    },
  },
  {
    id: "app",
    path: "/app/assets/templates",
    exact: true,
    label: "Machine Templates",
    key: "machines",
    Main: () => <AssetTemplates />,
  },
  {
    id: "app",
    path: "/app/assets",
    exact: true,
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop),
    label: "Assets",
    icon: <AssetHubIcon />,
    iconActive: <AssetHubIconActive className="icon-active" />,
    iconV2: <AssetHubIcon className="w-3xl h-3xl flex-shrink-0 icon" />,
    iconActiveV2: (
      <AssetHubIconActive className="icon-active h-3xl w-3xl flex-shrink-0" />
    ),
    iconOld: <AssetsManagement className="icon h-3xl w-3xl flex-shrink-0" />,
    iconActiveOld: (
      <AssetsManagementActive className="icon-active h-3xl w-3xl flex-shrink-0" />
    ),
    showInNavigation: true,
    key: "machines",
    Main: ({ appFeatures }) =>
      appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ? (
        <Redirect to="/app/asset-hub/my-assets" />
      ) : (
        <Redirect to="/app/assets/all" />
      ),
  },
  {
    id: "app",
    path: "/app/asset-hub",
    exact: true,
    enableIf: (user, appFeatures) =>
      appFeatures?.includes(APP_FEATURES_ENUM.growthLoop),
    label: "Assets",
    icon: <AssetHubIcon className="icon" />,
    iconActive: <AssetHubIconActive className="icon-active" />,
    iconV2: <AssetHubIcon className="w-3xl h-3xl flex-shrink-0 icon" />,
    iconActiveV2: (
      <AssetHubIconActive className="icon-active h-3xl w-3xl flex-shrink-0" />
    ),
    showInNavigation: true,
    key: "asset-hub",
    Main: () => <Redirect to="/app/asset-hub/my-assets" />,
  },
  {
    id: "app",
    path: "/app/assets/all",
    exact: true,
    label: "Assets",
    key: "machines",
    Main: ({ appFeatures }) =>
      appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ? (
        <Redirect to="/app/asset-hub" />
      ) : (
        <Assets />
      ),
  },
  {
    id: "app",
    path: "/app/assets/production-lines",
    exact: true,
    label: "Production Lines",
    key: "machines",
    Main: () => <Redirect to="/app/assets/all" />,
  },
  {
    id: "app",
    path: "/app/assets/components",
    exact: true,
    label: "Components",
    key: "machines",
    Main: () => <Redirect to="/app/assets/all" />,
  },
  {
    id: "app",
    path: "/app/assets/machines/templates/:id",
    exact: true,
    label: "Machine Template Details",
    key: "machineTemplateDetails",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/assets/templates/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/assets/templates/:id",
    exact: true,
    label: "Machine Template Details",
    key: "machineTemplateDetails",
    Main: () => <AssetTemplateSingle />,
  },
  {
    id: "app",
    path: "/app/assets/machines/:id",
    exact: true,
    label: "Machines",
    icon: "fas fa-cogs",
    Main: ({ appFeatures }) => {
      const params = useParams();

      return appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ? (
        <Redirect to={`/app/asset-hub/my-assets/${params.id}`} />
      ) : (
        <Redirect to={`/app/assets/all/${params.id}`} />
      );
    },
  },
  {
    id: "app",
    path: "/app/assets/all/:id",
    exact: true,
    label: "Assets",
    icon: "fas fa-cogs",
    Main: ({ appFeatures }) => {
      const params = useParams();
      return appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ? (
        <Redirect to={`/app/asset-hub/my-assets/${params.id}`} />
      ) : (
        <AssetSingle />
      );
    },
  },
  {
    id: "app",
    path: "/app/facilities",
    exact: true,
    label: "Facilities",
    icon: <SidebarFacilityIcon className="icon" />,
    iconActive: <SidebarFacilityFilledIcon className="icon-active" />,
    iconV2: (
      <SidebarFacilityIconV2 className="icon h-3xl w-3xl flex-shrink-0" />
    ),
    iconActiveV2: (
      <SidebarFacilityFilledIconV2 className="icon-active h-3xl w-3xl flex-shrink-0" />
    ),
    showInNavigation: true,
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop),
    key: "facilities",
    Main: ({ appFeatures }) => {
      return appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ? (
        <Redirect to="/app/asset-hub/connections" />
      ) : (
        <FacilityListPage />
      );
    },
  },
  {
    id: "app",
    path: "/app/facilities/:id",
    exact: true,
    label: "Facilities",
    icon: "fas fa-industry",
    Main: ({ appFeatures }) => {
      const params = useParams();
      return appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ? (
        <Redirect to={`/app/asset-hub/connections/${params.id}`} />
      ) : (
        <SingleFacility />
      );
    },
  },
  {
    id: "app",
    path: "/app/settings",
    exact: true,
    Main: () => {
      return <Redirect to="/app/settings/company/general" />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company",
    exact: true,
    Main: () => {
      return <Redirect to="/app/settings/company/general" />;
    },
  },
  {
    id: "app",
    path: "/app/settings/personal",
    exact: true,
    Main: () => {
      return <Redirect to="/app/settings/personal/general" />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/general",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/time-tracker",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/notifications",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/customize-fields",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/users",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/data-management",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/plans",
    exact: true,
    enableIf: (user, appFeatures) =>
      appFeatures?.includes(APP_FEATURES_ENUM.growthLoop),
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/access-permissions",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/asset-types",
    exact: true,
    Main: (props) => <MainSettingsLayout {...props} />,
  },
  {
    id: "app",
    path: "/app/settings/company/branding",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/status-customization",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/email-configuration",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/email-configuration/success",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/work-order-customization",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/resource-management",
    exact: true,
    Main: (props) => <MainSettingsLayout {...props} />,
  },
  {
    id: "app",
    path: "/app/settings/personal/general",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/personal/notifications",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    enableIf: () => true,
    exact: true,
    Main: (props) => <MainSettingsLayout {...props} />,
    path: "/app/settings/personal/calendar-sync",
  },
  {
    path: "/app/settings/personal/calendar-sync/success",
    exact: true,
    Main: (props) => <MainSettingsLayout {...props} />,
  },
  {
    id: "app",
    path: "/app/procedures",
    exact: true,
    label: "Procedures",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      return <Redirect to="/app/work/procedures" />;
    },
  },
  {
    id: "app",
    path: "/app/work/procedures",
    exact: true,
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    label: "Procedures",
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      return <Procedures />;
    },
  },
  {
    id: "app",
    path: "/app/procedures/:id",
    exact: true,
    label: "Procedures",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/work/procedures/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/work/procedures/:id",
    exact: true,
    label: "Procedures",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      return <ProcedureSingle />;
    },
  },
  {
    id: "app",
    path: "/app/work-orders/:ticketId/procedure/:procedureId",
    exact: true,
    label: "Procedures",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      const params = useParams();
      return (
        <Redirect
          to={`/app/work/work-orders/${params.ticketId}/procedure/${params.procedureId}`}
        />
      );
    },
  },
  {
    id: "app",
    path: "/app/work/work-orders/:ticketId/procedure/:procedureId",
    exact: true,
    label: "Procedures",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      return <ProcedureInstanceSingle />;
    },
  },
  {
    id: "app",
    path: "/app/inventory",
    exact: true,
    label: "Inventory",
    key: "inventory",
    icon: <InventoryIcon className="icon" />,
    iconActive: <InventoryFilledIcon className="icon-active" />,
    Main: () => <Redirect to="/app/assets/inventory" />,
  },
  {
    id: "app",
    path: "/app/assets/inventory",
    exact: true,
    label: "Inventory",
    icon: <InventoryIcon className="icon" />,
    iconActive: <InventoryFilledIcon className="icon-active" />,
    key: "inventory",
    Main: () => {
      return <Inventory />;
    },
  },
  {
    id: "app",
    path: "/app/assets/production-lines/:id",
    exact: true,
    key: "productionLineDetails",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/assets/all/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/assets/components/:id",
    exact: true,
    key: "componentDetails",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/assets/all/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/emails/:id",
    exact: true,
    label: "Emails",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    icon: <EnvelopOutline className="icon" />,
    iconActive: <EnvelopFilled className="icon-active" />,
    key: "emails",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/work/emails/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/work/emails/:id",
    exact: true,
    label: "Emails",
    enableIf: (user, appFeatures) =>
      user.role !== ALL_ROLES.TECHNICIAN.VALUE &&
      (!appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
        user?.oem?.installedProducts?.find(
          (installedProduct) =>
            installedProduct.type === PRODUCT_TYPES.CMMS ||
            installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
        )),
    icon: <EnvelopOutline className="icon" />,
    iconActive: <EnvelopFilled className="icon-active" />,
    key: "emails",
    Main: EmailLayout,
  },
  {
    id: "app",
    path: "/app/work/emails/:accountId/:id",
    exact: true,
    label: "Emails",
    enableIf: (user, appFeatures) =>
      user.role !== ALL_ROLES.TECHNICIAN.VALUE &&
      (!appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
        user?.oem?.installedProducts?.find(
          (installedProduct) =>
            installedProduct.type === PRODUCT_TYPES.CMMS ||
            installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
        )),
    icon: <EnvelopOutline className="icon" />,
    iconActive: <EnvelopFilled className="icon-active" />,
    key: "emails",
    Main: EmailLayout,
  },
  {
    id: "app",
    path: "/app/emails",
    exact: true,
    label: "Emails",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    icon: <EnvelopOutline className="icon" />,
    iconActive: <EnvelopFilled className="icon-active" />,
    key: "emails",
    Main: EmailLayout,
  },
  {
    id: "app",
    path: "/app/work/emails",
    enableIf: (user, appFeatures) =>
      user.role !== ALL_ROLES.TECHNICIAN.VALUE &&
      (!appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
        user?.oem?.installedProducts?.find(
          (installedProduct) =>
            installedProduct.type === PRODUCT_TYPES.CMMS ||
            installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
        )),
    exact: false,
    label: "Emails",
    icon: <EnvelopOutline className="icon" />,
    iconActive: <EnvelopFilled className="icon-active" />,
    key: "emails",
    Main: EmailLayout,
  },
  {
    id: "app",
    path: "/app/settings/company/users/accounts",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/users/teams",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    path: "/app/settings/company/users/skills",
    exact: true,
    Main: (props) => <MainSettingsLayout {...props} />,
  },
  {
    id: "app",
    path: "/app/settings/company/users/teams/:id",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/personal/email-signature",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    exact: true,
    key: "3d",
    path: "/app/3d",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) => installedProduct.type === PRODUCT_TYPES.THREE_D,
      ),
    Main: () => {
      return <Redirect to="/app/3d/models" />;
    },
  },
  {
    exact: true,
    key: "3d",
    Main: ThreeDModel,
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) => installedProduct.type === PRODUCT_TYPES.THREE_D,
      ),
    path: "/app/3d/models/:id",
  },
  {
    exact: true,
    key: "3d",
    Main: ThreeDGuide,
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) => installedProduct.type === PRODUCT_TYPES.THREE_D,
      ),
    path: "/app/3d/guides/:id",
  },
  {
    exact: true,
    key: "3d",
    Main: ThreeDModels,
    path: "/app/3d/models",
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) => installedProduct.type === PRODUCT_TYPES.THREE_D,
      ),
    showInNavigation: true,
    iconV2: <Sidebar3DIcon className="icon w-3xl h-3xl flex-shrink-0" />,
    iconActiveV2: (
      <Sidebar3DIconFilled className="icon-active w-3xl h-3xl flex-shrink-0" />
    ),
    iconActiveOld: (
      <Sidebar3DIconFilledOld className="icon-active w-3xl h-3xl flex-shrink-0" />
    ),
  },
  {
    exact: true,
    key: "3d",
    Main: ThreeDGuides,
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) => installedProduct.type === PRODUCT_TYPES.THREE_D,
      ),
    path: "/app/3d/guides",
  },
  {
    exact: true,
    key: "3d",
    Main: ThreeDGuides,
    enableIf: (user, appFeatures) =>
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
      user?.oem?.installedProducts?.find(
        (installedProduct) => installedProduct.type === PRODUCT_TYPES.THREE_D,
      ),
    path: "/app/3d/guides/new/:id",
  },
  {
    id: "app",
    path: "/app/ai",
    exact: true,
    label: "Industrial AI",
    iconOld: <AiIcon className="icon h-3xl w-3xl flex-shrink-0" />,
    iconActiveOld: (
      <AiIconFilled className="icon-active h-3xl w-3xl flex-shrink-0" />
    ),
    iconV2: <IndustrialAiIcon className="icon w-3xl h-3xl flex-shrink-0" />,
    iconActiveV2: (
      <IndustrialAiIconActive className="icon-active w-3xl h-3xl flex-shrink-0" />
    ),
    showInNavigation: true,
    key: "industrial-ai",
    Main: ({ user, appConfig }) => (
      <Redirect
        to={
          !isPaidFeatureAvailable(paidFeatures.aiAssistants, user, appConfig) &&
          isPaidFeatureAvailable(paidFeatures.aiNotetaker, user, appConfig)
            ? "/app/ai/notetaker"
            : "/app/ai/assistant"
        }
      />
    ),
  },
  {
    exact: true,
    key: "ai-assistant",
    Main: AiAssistant,
    path: "/app/ai/assistant",
  },
  {
    exact: true,
    key: "ai-assistant",
    Main: () => <Redirect to="/app/ai/assistant" />,
    path: "/app/ai-assistant",
  },
  {
    exact: true,
    key: "ai-notetaker",
    Main: AiNotetaker,
    path: "/app/ai/notetaker",
  },
  {
    enableIf: (user) =>
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.INDUSTRIAL_AI,
      ),
    exact: true,
    key: "knowledge-base",
    Main: KnowledgeBase,
    path: "/app/ai/knowledge-base",
  },
  {
    enableIf: (user) =>
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.INDUSTRIAL_AI,
      ),
    exact: true,
    key: "ai-search",
    Main: AISearch,
    path: "/app/ai/search",
  },
  {
    enableIf: (user) =>
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.INDUSTRIAL_AI,
      ),
    exact: true,
    key: "ai-ocr",
    Main: AIOCR,
    path: "/app/ai/ocr",
  },
  {
    exact: true,
    key: "ai-notetaker",
    Main: AiNote,
    path: "/app/ai/notetaker/:id",
  },
  {
    id: "app",
    enableIf: (user, appFeatures) =>
      isOemAdmin(user?.role) &&
      !appFeatures?.includes(APP_FEATURES_ENUM.growthLoop),
    path: "/app/analytics",
    showInNavigation: true,
    label: "Analytics",
    key: "analytics",
    icon: <SidebarAnalyticsIcon className="icon" />,
    iconActive: <SidebarAnalyticsFilledIcon className="icon-active" />,
    iconV2: (
      <SidebarAnalyticsIconV2 className="icon w-3xl h-3xl flex-shrink-0" />
    ),
    iconActiveV2: (
      <SidebarAnalyticsFilledIconV2 className="icon-active w-3xl h-3xl flex-shrink-0" />
    ),
    Main: () => <Analytics />,
  },
  {
    id: "app",
    enableIf: (user, appFeatures) =>
      isOemAdmin(user?.role) &&
      appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) &&
      user?.oem?.installedProducts?.find(
        (installedProduct) =>
          installedProduct.type === PRODUCT_TYPES.CMMS ||
          installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
      ),
    path: "/app/work/analytics",
    showInNavigation: false,
    label: "Analytics",
    key: "analytics",
    icon: <SidebarAnalyticsIcon className="icon" />,
    iconActive: <SidebarAnalyticsFilledIcon className="icon-active" />,
    iconV2: (
      <SidebarAnalyticsIconV2 className="icon w-3xl h-3xl flex-shrink-0" />
    ),
    iconActiveV2: (
      <SidebarAnalyticsFilledIconV2 className="icon-active w-3xl h-3xl flex-shrink-0" />
    ),
    Main: () => <Analytics />,
  },
  {
    exact: true,
    icon: <CalendarBlank className="icon w-5 h-5 text-secondary" />,
    iconActive: <CalendarBlankFilled className="icon-active w-5 h-5" />,
    enableIf: (user, appFeatures) =>
      user.role !== ALL_ROLES.TECHNICIAN.VALUE &&
      (!appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
        user?.oem?.installedProducts?.find(
          (installedProduct) =>
            installedProduct.type === PRODUCT_TYPES.CMMS ||
            installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
        )),
    key: "scheduler",
    Main: () => <ScheduleDispatch />,
    path: "/app/work/scheduler",
  },
  {
    exact: true,
    enableIf: (user, appFeatures) =>
      user.role !== ALL_ROLES.TECHNICIAN.VALUE &&
      (!appFeatures?.includes(APP_FEATURES_ENUM.growthLoop) ||
        user?.oem?.installedProducts?.find(
          (installedProduct) =>
            installedProduct.type === PRODUCT_TYPES.CMMS ||
            installedProduct.type === PRODUCT_TYPES.FIELD_SERVICE,
        )),
    icon: <CalendarBlank className="icon w-5 h-5 text-secondary" />,
    iconActive: <CalendarBlankFilled className="icon-active w-5 h-5" />,
    key: "scheduler",
    Main: () => {
      return <Redirect to="/app/work/scheduler" />;
    },
    path: "/app/scheduler",
  },
  {
    id: "app",
    path: "/app/asset-hub/my-assets",
    exact: true,
    label: "My Assets",
    key: "asset-hub",
    Main: () => <Assets />,
  },
  {
    id: "app",
    path: "/app/asset-hub/my-assets/:id",
    exact: true,
    label: "Assets",
    icon: "fas fa-cogs",
    Main: () => <AssetSingle />,
  },
  {
    id: "app",
    path: "/app/asset-hub/shared-assets",
    exact: true,
    label: "Shared Assets",
    key: "asset-hub",
    Main: () => <SharedAssets />,
  },
  {
    id: "app",
    path: "/app/asset-hub/shared-assets/:id",
    exact: true,
    label: "Shared Assets",
    key: "asset-hub",
    Main: () => <SingleSharedAssetOrganization />,
  },
  {
    id: "app",
    path: "/app/asset-hub/shared-assets/:connectionId/:assetId",
    exact: true,
    label: "Shared Asset",
    icon: "fas fa-industry",
    Main: () => {
      return <SingleSharedAsset />;
    },
  },
  {
    id: "app",
    path: "/app/asset-hub/requests",
    exact: true,
    label: "Requests",
    key: "asset-hub",
    Main: () => <Requests />,
  },
  {
    id: "app",
    path: "/app/asset-hub/requests/:id",
    exact: true,
    label: "Requests",
    key: "asset-hub",
    Main: () => <SingleRequest />,
  },
  {
    id: "app",
    path: "/app/asset-hub/connections",
    exact: true,
    label: "Connections",
    key: "asset-hub",
    Main: () => <Connections />,
  },
  {
    id: "app",
    path: "/app/asset-hub/connections/:id",
    exact: true,
    label: "Connections",
    icon: "fas fa-industry",
    Main: () => {
      return <SingleConnection />;
    },
  },
  {
    id: "app",
    path: "/app/asset-hub/inventory",
    exact: true,
    label: "Connections",
    key: "asset-hub",
    Main: () => <Inventory />,
  },
  {
    id: "app",
    path: "/app/asset-hub/asset-templates",
    exact: true,
    label: "Asset Templates",
    key: "asset-hub",
    Main: () => <AssetTemplates />,
  },
  {
    id: "app",
    path: "/app/asset-hub/asset-templates/:id",
    exact: true,
    label: "Asset Template Details",
    key: "asset-hub",
    Main: () => <AssetTemplateSingle />,
  },
  ...facilityRedirects,
];

export default (user, appFeatures) =>
  routes.filter(({ enableIf = () => true }) => enableIf(user, appFeatures));

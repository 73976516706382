import { useQuery } from "@apollo/client";

import { GET_TEAM_QUERY, LIST_TEAMS_QUERY } from "~/api";
import { ITEMS_PER_PAGE } from "~/constants";

export const useListTeams = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery,
  skipCondition = false,
  where = {},
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      where: {
        searchQuery,
        ...where,
      },
    },
  };

  const {
    data: { listTeams = [] } = {},
    loading: teamsLoading,
    refetch: teamsRefetch,
    fetchMore,
  } = useQuery(LIST_TEAMS_QUERY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    teams = [],
  } = listTeams;

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          where: {
            searchQuery,
            ...(where || {}),
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedTeams = [
          ...previousResult.listTeams.teams,
          ...fetchMoreResult.listTeams.teams,
        ];

        const updates = {
          ...previousResult,
          listTeams: {
            ...previousResult.listTeams,
            ...fetchMoreResult.listTeams,
            teams: mergedTeams,
          },
        };

        return updates;
      },
    });
  };

  return {
    teams,
    handleFetchMore,
    teamsRefetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    teamsLoading,
  };
};

export const useGetTeam = (id) => {
  const { data: { getTeam } = {}, loading } = useQuery(GET_TEAM_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  return {
    loading,
    team: getTeam,
  };
};

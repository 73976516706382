import { PRODUCT_TYPES } from "@shared/constants/paidFeaturesHierarchy";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import AppModal from "@shared/ui/Modal";
import { X } from "phosphor-react";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { registerMixpanelEvent } from "#/src/utils/_mixpanel";
import ContactSalesModal from "~/components/ContactSalesModal";
import { useAuth } from "~/components/general";
import { getProducts } from "~/components/header/ProductStoreMenu";
import TierTile from "~/components/PlanPricingModal/TierTile";
import {
  INITIATE_CONTACT_SALES_ASSET_HUB,
  INITIATE_CONTACT_SALES_INDUSTRIAL_AI,
} from "~/constants";
import { InfoIcon } from "~/icons/icons";
import type { UserType } from "~/types/user";
import { useGetAppConfigRecoilValue } from "~/utils/appFeatures";

const PlanPricingModal = ({
  productType,
  installedTier,
  onClose,
}: {
  productType: string;
  installedTier?: string;
  onClose: () => void;
}) => {
  const { messages } = useIntl();
  const { user } = useAuth() as { user: UserType };
  const appConfig = useGetAppConfigRecoilValue();
  const ALL_PRODUCTS = getProducts(messages, user, appConfig).available;
  const [showContactSalesModal, setShowContactSalesModal] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const relevantProduct = ALL_PRODUCTS.find(
    (product) => product.type === productType,
  );
  if (!installedTier) {
    installedTier = user.oem.installedProducts.find(
      (installedProduct) => installedProduct.type === productType,
    )?.tier;
  }

  return (
    <>
      <AppModal
        handleSubmit={() => {}}
        isOpen={showMenu}
        footer={<></>}
        maxWidth="min-w-[920px]"
        handleClose={() => {}}
        header={
          <div className="flex items-center justify-between py-xl px-2xl border-solid border-b border-t-0 border-x-0 border-primary">
            <div className="flex space-x-lg">
              <relevantProduct.icon className="w-5xl h-5xl flex-shrink-0" />
              <div className="flex flex-col">
                <Headline size={HEADLINE_SIZES.X_SMALL}>
                  {messages.branding?.["pricingModal"].header}
                </Headline>
                <BodyText size={BODY_TEXT_SIZES.SMALL} color="text-secondary">
                  {messages.branding?.["pricingModal"].subHeader}
                </BodyText>
              </div>
            </div>

            <button
              onClick={onClose}
              className="w-3xl h-3xl flex items-center justify-center bg-primary border border-solid border-primary rounded-lg cursor-pointer transition-all duration-200 ease-in-out hover:border-brand"
            >
              {/* @ts-ignore */}
              <X size={16} className="text-primary" />
            </button>
          </div>
        }
        content={
          <div className="flex flex-col items-center">
            <div className="bg-brand-lightest rounded-lg py-sm px-md flex items-center w-full">
              <InfoIcon
                width={20}
                height={20}
                className="stroke-brand mr-md"
                strokeColor="stroke-brand"
              />
              <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                {messages.branding["pricingModal"].contactSales}
              </BodyText>
            </div>
            <div className="flex space-x-lg mt-lg">
              {appConfig.plans
                .find((plan) => plan.type === productType)
                ?.tiers?.map((tier) => (
                  <TierTile
                    planConfig={appConfig.plans.find(
                      (plan) => plan.type === productType,
                    )}
                    installedTier={installedTier}
                    tierType={tier.type}
                    onContactSales={() => {
                      switch (productType) {
                        case PRODUCT_TYPES.ASSET_HUB:
                          registerMixpanelEvent(
                            INITIATE_CONTACT_SALES_ASSET_HUB,
                          );
                          break;
                        case PRODUCT_TYPES.INDUSTRIAL_AI:
                          registerMixpanelEvent(
                            INITIATE_CONTACT_SALES_INDUSTRIAL_AI,
                          );
                          break;
                        default:
                          break;
                      }
                      setShowContactSalesModal(true);
                      setShowMenu(false);
                    }}
                  />
                ))}
            </div>
          </div>
        }
      />
      {showContactSalesModal && (
        <ContactSalesModal
          onClose={(showMenu = true) => {
            setShowContactSalesModal(false);
            setShowMenu(!!showMenu);
          }}
          productType={productType}
        />
      )}
    </>
  );
};

export default PlanPricingModal;

import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import { CHECKBOX_SIZES, Checkbox, Input } from "@shared/ui/Inputs";
import AppModal from "@shared/ui/Modal";
import { MinusCircle, Plus, Spinner, MagnifyingGlass } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import PAID_FEATURES from "$/settings/paid-features.json";
import { useAuth } from "~/components/general";
import { FacilityLoaderComponent } from "~/components/skeletonLoaders/_facilityLoaders";
import { useGetAllProcedureTemplates } from "~/services/procedures/_query";
import type { UserType } from "~/types/user";
import { getEnums } from "~/utils";
import { registerMixpanelEvent } from "~/utils/_mixpanel";
import { useGetAppConfigRecoilValue } from "~/utils/appFeatures";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

const ProcedureInstancePME = ({
  isClosedTicket,
  setEventCb,
  event = {},
  initiateEvent = "",
}) => {
  const { messages } = useIntl();
  const [showAttachProcedureModal, setShowAttachProcedureModal] =
    useState(false);
  const [attachingProcedure, setAttachingProcedure] = useState(false);
  const [localModalState, setLocalModalState] = useState(event);

  const handleModalClose = () => {
    setShowAttachProcedureModal(false);
  };

  const handleModalSubmit = () => {
    setAttachingProcedure(false);
    setShowAttachProcedureModal(false);
    setEventCb((prev) => ({
      ...prev,
      procedures: [...(localModalState?.procedures || [])],
    }));
  };

  const { user } = useAuth() as { user: UserType };
  const appConfig = useGetAppConfigRecoilValue();
  const proceduresEnabled = isPaidFeatureAvailable(
    paidFeatures.procedures,
    user,
    appConfig,
  );

  if (!proceduresEnabled) return null;

  return (
    <section>
      <div className="flex items-center justify-between mt-lg">
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          color="text-secondary"
          className="mb-sm"
        >
          {messages?.preventiveMaintenance?.procedures?.title}
        </BodyText>
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          color="text-secondary"
          className="mb-sm"
        >
          {event?.procedures?.length || 0}{" "}
          {messages?.preventiveMaintenance?.procedures?.added}
        </BodyText>
      </div>
      <Button
        className="ml-2xs mb-md mr-md w-full text-secondary py-md px-xl bg-transparent !border !border-dashed border-primary active:!text-brand active:border-brand hover:!text-brand hover:border-brand disabled:text-disabled disabled:border-primary"
        variant={BUTTON_VARIANTS.LINK}
        text={messages?.preventiveMaintenance?.procedures?.add}
        leadingIcon={<Plus size={16} weight="bold" />}
        disabled={isClosedTicket}
        onClick={() => {
          if (isClosedTicket) return;
          initiateEvent && registerMixpanelEvent(initiateEvent);
          setShowAttachProcedureModal(true);
        }}
      />
      <div className="procedure-instances-container">
        {(event?.procedures || []).map((instance, index) => (
          <div className="flex justify-between relative items-center rounded-lg cursor-pointer transition-all duration-200 w-full text-secondary py-sm px-xl bg-transparent border border-solid border-primary mb-md">
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-primary"
              className=""
            >
              {instance?.procedure?.name}
            </BodyText>
            <Button
              variant={BUTTON_VARIANTS.LINK}
              leadingIcon={
                <MinusCircle size={16} className="u-text-color-gray-v2-60" />
              }
              tooltipText={messages?.preventiveMaintenance?.procedures?.remove}
              tooltipId="3D-Button"
              tooltipClassName="after:!top-3xs after:!right-3.5 after:!bottom-auto"
              tooltipOffset={{ bottom: 30, left: -37 }}
              onClick={() => {
                setEventCb((prev) => ({
                  ...prev,
                  procedures: [
                    ...(prev?.procedures?.filter(
                      (pr) => pr.procedure._id !== instance.procedure._id,
                    ) || []),
                  ],
                }));
              }}
            />
          </div>
        ))}
      </div>
      <AppModal
        className="procedure-instance-attach-modal min-h-[500px] !max-w-[530px]"
        isOpen={showAttachProcedureModal}
        title={messages?.preventiveMaintenance?.procedures?.add}
        contentClassName="flex-1"
        content={
          <ProcedureModalPME
            event={event}
            setLocalModalState={setLocalModalState}
            localModalState={localModalState}
          />
        }
        handleClose={handleModalClose}
        updateButtonContent={
          <Button
            text={messages?.preventiveMaintenance?.procedures?.assign}
            trailingIcon={
              attachingProcedure ? (
                <span className="u-inline-flex u-margin-l-2 spinning">
                  <Spinner size={16} />
                </span>
              ) : null
            }
            onClick={handleModalSubmit}
            disabled={!localModalState?.procedures?.length}
          />
        }
        disableCancel={false}
        overlayClasses="!z-10"
      />
    </section>
  );
};

const ProcedureModalPME = ({ event, setLocalModalState, localModalState }) => {
  const { messages } = useIntl();
  const [searchValue, setSearchValue] = useState("");
  const { allProcedures: procedures, fetchingProcedures: loading } =
    useGetAllProcedureTemplates();

  useEffect(() => {
    setLocalModalState(event);
  }, []);

  const filteredProcedures = procedures.filter((procedure) =>
    procedure.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const handleAttachProcedure = async (templateId, name) => {
    setLocalModalState((prev) => {
      const isPresent = prev?.procedures?.findIndex(
        (pr) => pr.procedure._id === templateId,
      );

      if (isPresent >= 0) {
        return {
          ...prev,
          procedures: prev.procedures.filter(
            (pr) => pr.procedure._id !== templateId,
          ),
        };
      } else {
        return {
          ...prev,
          procedures: [
            ...(prev?.procedures || []),
            { procedure: { _id: templateId, name } },
          ],
        };
      }
    });
  };

  if (loading) {
    return <FacilityLoaderComponent total={3} />;
  }

  return (
    <div className="bg-transparent pb-2xs relative">
      <Input
        type="text"
        placeholder={messages?.preventiveMaintenance?.procedures?.search}
        value={searchValue}
        className="mb-4 bg-tertiary pl-3xl"
        onChange={(e) => setSearchValue(e.target.value)}
        name="unlinked-work-orders-input"
        id="unlinked-work-orders-input"
        leadingIconConfig={{
          icon: <MagnifyingGlass size={16} />,
          className: "top-3",
        }}
      />
      <div className="space-y-3">
        {filteredProcedures?.map((procedure) => {
          const isChecked = localModalState?.procedures?.some((ep) => {
            return ep?.procedure?._id === procedure._id;
          });
          return (
            <label
              key={procedure.id}
              className="flex items-center justify-between p-md border rounded-lg cursor-pointer border-primary border-solid"
              htmlFor="procedure"
              onClick={() => {
                handleAttachProcedure(procedure._id, procedure.name);
              }}
            >
              <div className="flex items-center gap-2 leading-none">
                <Checkbox checked={isChecked} size={CHECKBOX_SIZES.NORMAL} />
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-primary"
                  className=""
                >
                  {procedure.name}
                </BodyText>
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default ProcedureInstancePME;

import { useMemo } from "react";

import useAuth from "~/components/general/_use-auth";
import { useGetAppConfigRecoilValue } from "~/utils/appFeatures";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const useCheckPaidFeatureAvailable = (paidFeatureKey) => {
  const { user } = useAuth();
  const appConfig = useGetAppConfigRecoilValue();

  const hasFeature = useMemo(
    () => isPaidFeatureAvailable(paidFeatureKey, user, appConfig),
    [user?.oem?.paidFeatures, appConfig?.plans],
  );

  return hasFeature;
};

export default useCheckPaidFeatureAvailable;

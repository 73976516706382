import * as React from "react";
const SvgFieldService = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "m13.838 22.193-2.671 1.895a1.045 1.045 0 0 0-.387 1.182l1.004 3.015c.141.427.54.715.99.715h3.336c.45 0 .849-.288.991-.715l1.004-3.015a1.045 1.045 0 0 0-.387-1.182l-2.671-1.895a1.044 1.044 0 0 0-1.209 0Z",
  fill: "#fff",
  stroke: "#748094",
  strokeWidth: 1.5,
  transform: "scale(1.1),translate(-)"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M12.191 11.5h4.5a1 1 0 0 1 1 1V17a1 1 0 0 1-1 1h-4.5a1 1 0 0 1-1-1v-4.5a1 1 0 0 1 1-1ZM25.633 22a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM24.604 12.07l-2.547 4.076A1.212 1.212 0 0 0 23.084 18h5.095a1.211 1.211 0 0 0 1.027-1.854L26.66 12.07a1.212 1.212 0 0 0-2.056 0Z",
  fill: "#fff",
  stroke: "#748094",
  strokeWidth: 1.5,
  transform: "scale(1.1),translate(-)",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgFieldService;

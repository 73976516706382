import * as React from "react";
const SvgSubAssets = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 12,
  height: 14,
  viewBox: "0 0 12 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("ellipse", {
  cx: 2.33329,
  cy: 11.3334,
  rx: 1.66667,
  ry: 1.66667,
  stroke: "#8940E5",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("ellipse", {
  cx: 5.99992,
  cy: 2.66667,
  rx: 1.66667,
  ry: 1.66667,
  stroke: "#8940E5",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("ellipse", {
  cx: 9.66667,
  cy: 11.3334,
  rx: 1.66667,
  ry: 1.66667,
  stroke: "#8940E5",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.66659 9.66667V8.33333C9.66659 7.59695 9.06963 7 8.33325 7H3.66659C2.93021 7 2.33325 7.59695 2.33325 8.33333V9.66667",
  stroke: "#8940E5",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M5.99996 4.33331V6.99998",
  stroke: "#8940E5",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgSubAssets;

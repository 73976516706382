import Teams from "@shared/svg/teams.svg?react";
import User from "@shared/svg/user.svg?react";
import { AvatarGroup } from "@shared/ui/Avatar";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { Internationalization } from "@syncfusion/ej2-base";
import {
  Inject,
  ScheduleComponent,
  Month,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import moment from "moment-timezone";
import { CalendarBlank } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useGetAppConfigRecoilValue } from "#/src/utils/appFeatures";
import isPaidFeatureAvailable from "#/src/utils/isPaidFeatureAvailable";
import getEnum from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features.json";
import AssignedTeamsWithPreview from "~/components/_assignedTeamsWithPreview";
import ResourcesPreview from "~/components/common/ResourcesPreview";
import ContentLoading from "~/components/ContentLoading";
import { Loading, useAuth } from "~/components/general";
import { COLOR } from "~/constants/colors";
import { SCROLL_SCOPE } from "~/constants/scrollScopes";
import CalendarStyles from "~/css/components/ticket-calendar.module.css";
import useGetClosedStatus from "~/hooks/_useGetClosedStatus";
import useScrollPersist from "~/hooks/_useScrollPersist";
import useSyncFusionCSSLoader from "~/hooks/SyncFusionCSSLoader";
import {
  ExclamationIcon,
  SidebarFacilityIcon,
  ViewFullScreenIcon,
  SidebarMachinesIcon,
} from "~/icons/icons";
import { languageAtom } from "~/lang/provider";
import { useListOwnOemTickets, useListOwnOemResources } from "~/services";
import { generateStatusBackgroundColor } from "~/utils/_colors";

const paidFeaturesRef = getEnum(PAID_FEATURES, "reference");

const getViewStartEnd = (date) => {
  return {
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  };
};

export const QuickInfoHeader = ({ data }) => {
  const { messages } = useIntl();
  const { setScopeData } = useScrollPersist(SCROLL_SCOPE.WORK_ORDERS.CALENDAR);

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
          #{data.TicketNumber}
        </BodyText>
        <div className="flex items-center justify-end">
          <Link
            to={`/app/work/work-orders/${data.TicketId}`}
            onClick={() => setScopeData(data.StartTime)}
          >
            <div className="flex items-center space-x-2">
              <ViewFullScreenIcon />
              <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                {messages?.resourcePlanning?.labels.viewTicket}
              </BodyText>
            </div>
          </Link>
        </div>
      </div>
      <div
        className="p-3 font-manrope rounded-lg capitalize"
        style={{
          backgroundColor: generateStatusBackgroundColor(data?.Status?.color),
        }}
      >
        <Headline size={HEADLINE_SIZES.SMALL}>{data.Subject}</Headline>
      </div>
    </>
  );
};

export const QuickInfoContent = ({
  data,
  machine,
  facility,
  resourcesMap,
  isSchedulerPaid,
}) => {
  const { messages } = useIntl();
  const locale = useRecoilValue(languageAtom);
  const startAt = moment(data.StartTime);
  const endAt = moment(data.EndTime);
  const isSameDay = endAt.diff(startAt, "day") === 0;

  return (
    <div className="appointment-quickInfo-content">
      <div className="ticket-machine">
        {machine ? (
          <>
            <SidebarMachinesIcon className="mr-2" />
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="flex flex-wrap items-center overflow-hidden w-full max-w-full"
            >
              <span className="max-w-full flex items-center">
                <a
                  href={`/app/assets/machines/${machine._id}`}
                  className="text-brand truncate"
                  target="_blank"
                  rel="noreferrer"
                >
                  {machine.name}
                </a>
                <span className="truncate">
                  &nbsp;&nbsp;{"•"}&nbsp;&nbsp;
                  {machine.serialNumber}
                </span>
              </span>
            </BodyText>
          </>
        ) : (
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
            className="flex flex-wrap items-center overflow-hidden max-w-full"
          >
            <span className="inline-flex justify-center items-center mr-2xs">
              <ExclamationIcon />{" "}
              <span className="ml-2xs">
                {messages["hierarchy"]["assets"]["deletedAsset"]}
              </span>
            </span>
          </BodyText>
        )}
      </div>
      <div className="ticket-facility">
        {facility ? (
          <>
            <SidebarFacilityIcon strokeColor={COLOR.$gray_v2_60} />
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="ml-sm truncate"
            >
              <a
                href={`/app/facilities/${facility._id}`}
                className="text-brand"
                target="_blank"
                rel="noreferrer"
              >
                {facility.name}
              </a>
            </BodyText>
          </>
        ) : (
          <span className="inline-flex justify-center items-center mr-1">
            <ExclamationIcon />{" "}
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="ml-2xs"
            >
              {messages?.resourcePlanning?.deletedFacility}
            </BodyText>
          </span>
        )}
      </div>
      <div className="event-dates">
        <CalendarBlank size={16} className="mr-2 text-gray-60" />
        {data.IsAllDay && (
          <div className="date-time-wrapper">
            <div className="event-date">
              {new Date(data.StartTime).toLocaleDateString(locale.lang.locale, {
                weekday: "long",
                month: "long",
                day: "numeric",
              })}
              <span>&nbsp;</span>
            </div>
            <div className="event-time text-gray-60">
              ({messages?.resourcePlanning?.labels.allDay})
            </div>
          </div>
        )}
        {isSameDay && !data.IsAllDay && (
          <div className="date-time-wrapper">
            <div className="event-date">
              {new Date(data.StartTime).toLocaleDateString(locale.lang.locale, {
                weekday: "long",
                month: "long",
                day: "numeric",
              })}
              <span>&nbsp;&nbsp;{"•"}&nbsp;&nbsp;</span>
            </div>
            <div className="event-time text-gray-60">
              {moment(data.StartTime).format("hh:mm A")} -{" "}
              {moment(data.EndTime).format("hh:mm A")}
            </div>
          </div>
        )}
        {!isSameDay && !data.IsAllDay && (
          <div className="flex flex-col">
            <div className="date-time-wrapper">
              <div className="event-date">
                <span className="text-gray-60">
                  {messages?.resourcePlanning?.labels.from}:{" "}
                </span>
                {new Date(data.StartTime).toLocaleDateString("en-US", {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                })}
                <span>&nbsp;&nbsp;{"•"}&nbsp;&nbsp;</span>
              </div>
              <div className="event-time text-gray-60">
                {moment(data.StartTime).format("hh:mm A")}
              </div>
            </div>

            <div className="date-time-wrapper">
              <div className="event-date">
                <span className="text-gray-60">
                  {messages?.resourcePlanning?.labels.to}:{" "}
                </span>
                {new Date(data.EndTime).toLocaleDateString(locale.lang.locale, {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                })}
                <span>&nbsp;&nbsp;{"•"}&nbsp;&nbsp;</span>
              </div>
              <div className="event-time text-gray-60">
                {moment(data.EndTime).format("hh:mm A")}
              </div>
            </div>
          </div>
        )}
      </div>
      {data.assignees?.length > 0 && (
        <div className="flex items-center space-x-md mt-md">
          <User className="w-lg h-lg text-secondary" />
          <div className="u-flex">
            <AvatarGroup
              names={data.assignees.map(
                (item) => item.name || `${messages.common?.noName}`,
              )}
            />
          </div>
        </div>
      )}
      {data.teams?.length > 0 && (
        <div className="flex items-center space-x-md mt-md">
          <Teams className="w-lg h-lg text-secondary" />
          <AssignedTeamsWithPreview
            containerClassName="flex-wrap"
            tagClassName="overflow-hidden overflow-ellipsis whitespace-nowrap"
            teams={data.teams}
            showAllWIthoutPreview
          />
        </div>
      )}
      {isSchedulerPaid && resourcesMap && data.resources?.length > 0 && (
        <ResourcesPreview
          resources={data.resources.map((resource) => ({
            _id: resource,
            name: resourcesMap[resource].name,
            resourceId: resourcesMap[resource].resourceId,
          }))}
        />
      )}
    </div>
  );
};

const TicketCalendar = ({ activeFilters }) => {
  const { getScopeData } = useScrollPersist(SCROLL_SCOPE.WORK_ORDERS.CALENDAR);

  const selectedDate = React.useMemo(() => {
    const date = getScopeData();
    return date ? new Date(date) : new Date();
  }, []);

  const [state, setState] = React.useState(
    getViewStartEnd(new Date(selectedDate)),
  );

  const { user } = useAuth();
  const appConfig = useGetAppConfigRecoilValue();
  const isSchedulerPaid = isPaidFeatureAvailable(
    paidFeaturesRef.scheduler,
    user,
    appConfig,
  );
  const { closedStatus } = useGetClosedStatus();

  const { tickets, loading } = useListOwnOemTickets({
    activeFilters,
    limit: -1,
    where: {
      excludeStatuses: [closedStatus?._id],
      scheduledDate: { startDate: state.startDate, endDate: state.endDate },
    },
  });
  const { resources } = useListOwnOemResources({
    skipCondition: !isSchedulerPaid,
  });

  const mounted = React.useRef(false);
  const schedulerRef = React.useRef(null);
  const scheduleTicketRef = React.useRef(null);
  const allTicketsMap = React.useMemo(() => {
    return (
      tickets.reduce((acc, ticket) => {
        acc[ticket._id] = ticket;

        return acc;
      }, {}) || {}
    );
  }, [tickets]);

  const statuses = React.useMemo(
    () =>
      user?.oem?.statuses?.reduce((acc, status) => {
        acc[status._id] = status;
        return acc;
      }, {}) || {},
    [user],
  );

  const resourcesMap = React.useMemo(
    () =>
      resources?.reduce((acc, resource) => {
        acc[resource._id] = resource;

        return acc;
      }, {}) || {},
    [resources],
  );

  React.useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const calendarData = () => {
    const calendarDataTickets = [];

    tickets
      .filter((ticket) => ticket.assignees.length)
      .forEach((item) => {
        calendarDataTickets.push({
          Id: item._id,
          TicketId: item._id,
          TicketNumber: item.ticketId,
          Subject: item.title,
          Description: item.description,
          StartTime: item.schedule.startTime,
          EndTime: item.schedule.endTime,
          StartTimezone: item.schedule.startTimezone,
          EndTimezone: item.schedule.endTimezone,
          IsAllDay: item.schedule.isAllDay,
          IsTimezoneEnabled: item.schedule.isTimezoneEnabled,
          AgentId: item.assignees.map((assignee) => assignee._id),
          Type: item.ticketType,
          Status: statuses[item?.status],
          assignees: item.assignees,
          resources: (item.resources || []).map((resource) => resource._id),
          teams: item.teams,
        });
      });

    return calendarDataTickets;
  };

  const onActionBegin = (args) => {
    if (args.requestType === "toolbarItemRendering") {
      const [item0, item1, item2] = args.items;

      args.items[0] = item0;
      args.items[1] = item2;
      args.items[2] = item1;
      args.items[3] = {
        ...args.items[3],
        cssClass: "e-today !hidden",
      };
    }
  };

  const onNavigation = (navigationProps) => {
    setState(getViewStartEnd(navigationProps.currentDate));
  };

  const onSelect = (selectEvent) => {
    if (!selectEvent.element.classList.contains("e-work-cells")) {
      const selectedAppointmentTicket =
        allTicketsMap[selectEvent.data.TicketId];
      scheduleTicketRef.current = selectedAppointmentTicket;
    }
  };

  const renderQuickInfoHeader = (headerArgs) => (
    <QuickInfoHeader data={headerArgs} />
  );

  const renderQuickInfoContent = React.useCallback(
    (contentArgs) => (
      <QuickInfoContent
        data={contentArgs}
        machine={scheduleTicketRef.current?.machine}
        facility={scheduleTicketRef.current?.facility}
        resourcesMap={resourcesMap}
        isSchedulerPaid={isSchedulerPaid}
      />
    ),
    [isSchedulerPaid, resourcesMap],
  );

  const { loaded: cssLoaded } = useSyncFusionCSSLoader();

  if (!cssLoaded) {
    return <Loading />;
  }

  return (
    <div
      className={`ticketCalendar ${CalendarStyles.ticketCalendar} flex justify-center border-0 border-t border-solid border-gray-10 py-4`}
    >
      {!mounted.current && loading ? (
        <ContentLoading />
      ) : (
        <div>
          <Scheduler
            onActionBegin={onActionBegin}
            onNavigation={onNavigation}
            onSelect={onSelect}
            ref={schedulerRef}
            renderQuickInfoHeader={renderQuickInfoHeader}
            renderQuickInfoContent={renderQuickInfoContent}
            scheduleData={calendarData()}
          />
        </div>
      )}
    </div>
  );
};

const EventTemplate = (props) => (
  <BodyText
    className="h-full pl-sm rounded content-center border-0 border-l-2 border-solid overflow-hidden overflow-ellipsis whitespace-nowrap"
    size={BODY_TEXT_SIZES.X_SMALL}
    style={{
      borderColor: props?.Status?.color,
      backgroundColor: generateStatusBackgroundColor(props?.Status?.color),
    }}
  >
    {props.Subject}
  </BodyText>
);

const onPopupOpen = (args) => {
  if (args.type === "EventContainer") {
    const instance = new Internationalization();
    const day = instance.formatDate(args.data.date, { format: "EEEE" });
    const date = instance.formatDate(args.data.date, { format: "d MMM" });

    args.element.querySelector(".e-header-day").innerText = `${day},`;
    args.element.querySelector(".e-header-date").innerText = date;
  }
};

const Scheduler = React.forwardRef(
  (
    {
      onActionBegin,
      onNavigation,
      onSelect,
      renderQuickInfoHeader,
      renderQuickInfoContent,
      scheduleData,
    },
    schedulerRef,
  ) => {
    const { getScopeData } = useScrollPersist(
      SCROLL_SCOPE.WORK_ORDERS.CALENDAR,
    );

    const selectedDate = React.useMemo(() => {
      const date = getScopeData();
      return date ? new Date(date) : new Date();
    }, []);

    return (
      <ScheduleComponent
        actionBegin={onActionBegin}
        cssClass="ticket-calendar !overflow-visible"
        currentView="Month"
        delayUpdate
        eventSettings={{
          dataSource: scheduleData,
          template: EventTemplate,
        }}
        firstDayOfWeek={1}
        navigating={onNavigation}
        popupOpen={onPopupOpen}
        quickInfoTemplates={{
          header: renderQuickInfoHeader,
          content: renderQuickInfoContent,
          footer: () => <></>,
        }}
        readonly
        ref={schedulerRef}
        select={onSelect}
        selectedDate={selectedDate}
        timezone={moment.tz.guess()}
        views={["Month"]}
        workDays={[1, 2, 3, 4, 5]}
        workHours={{ highlight: true, start: "00:00", end: "23:59" }}
      >
        <ViewsDirective>
          <ViewDirective option="Month" eventTemplate={EventTemplate} />
        </ViewsDirective>
        <Inject services={[Month]} />
      </ScheduleComponent>
    );
  },
);

export default TicketCalendar;

import CustomFieldIcon from "@shared/svg/custom-field.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { DataTableColumnResizer } from "@shared/ui/DataTable";
import Tag from "@shared/ui/Tag";
import React from "react";

import { getOptionBackgroundColor } from "./_customFields";
import { TABLE_VIEW_COLUMN_MIN_WIDTH } from "../constants";
import { fieldType } from "../constants/filters/customFieldTypes";

export const generateCustomFieldColumns = (
  columns,
  customAdditionalFields,
  onCellClick,
  sizeConfigs = {},
  scope,
  padding = "",
) => {
  const dynamicColumns = [...columns];
  if (customAdditionalFields?.length) {
    for (const customAdditionalField of customAdditionalFields) {
      if (
        customAdditionalField.isAdditionalField &&
        (customAdditionalField.fieldType === fieldType?.address ||
          customAdditionalField.fieldType === fieldType?.date)
      ) {
        continue;
      }

      dynamicColumns.push({
        accessorFn: (row) => {
          const { customFields } = row;
          if (customFields?.length) {
            return (
              customFields?.find(
                (customField) =>
                  customField?.fieldId?._id === customAdditionalField._id,
              )?.value || ""
            );
          }
        },
        id: customAdditionalField._id,
        accessorKey: customAdditionalField._id,
        size: sizeConfigs[customAdditionalField._id],
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        onClick: (id) => {
          onCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item text-ellipsis">
            <span className="u-flex u-items-center">
              <CustomFieldIcon
                width={16}
                height={16}
                className="text-secondary"
              />
            </span>
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="max-w-full truncate"
            >
              {customAdditionalField.label}
            </BodyText>
            <DataTableColumnResizer header={header} scope={scope} />
          </div>
        ),
        Cell: (info) => {
          const selectedOption = customAdditionalField?.options?.find(
            (op) => op.value === info.cell.getValue(),
          );
          const optionColor = selectedOption?.color ?? "";

          return (
            <div className={`overflow-hidden ${padding}`}>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                className="max-w-full truncate"
              >
                {customAdditionalField?.fieldType === fieldType.singleSelect &&
                  (info.cell.getValue()?.length > 0 ? (
                    <Tag
                      label={selectedOption ? info.cell.getValue() : ""}
                      className="whitespace-nowrap"
                      style={{
                        color: optionColor,
                        backgroundColor: getOptionBackgroundColor(optionColor),
                      }}
                    />
                  ) : (
                    "-"
                  ))}
                {customAdditionalField?.fieldType !==
                  fieldType.singleSelect && (
                  <span className="max-w-full truncate">
                    {info.cell.getValue() || "-"}
                  </span>
                )}
              </BodyText>
            </div>
          );
        },
      });
    }
  }

  return dynamicColumns;
};

import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import FeatureAccess from "~/components/common/FeatureAccess";

export default ({ intl, routes = [], DefaultRoute, user, appConfig }) => (
  <Switch>
    {routes.map((route) => (
      <Route key={route.key} path={route.path} exact={route.exact}>
        <FeatureAccess>
          <Main>
            <route.Main
              intl={intl}
              user={user}
              appFeatures={appConfig?.features}
              appConfig={appConfig}
            />
          </Main>
        </FeatureAccess>
      </Route>
    ))}
    {/* default route if no path matches */}
    <Redirect to={`${DefaultRoute.path}`} />
  </Switch>
);

export const Main = ({ children }) => {
  const history = useHistory();

  return (
    <main
      className={`w-full h-screen overflow-y-auto ${
        history.location.pathname === "/app/work/scheduler"
          ? "no-scrollbar"
          : ""
      }`}
    >
      {children}
    </main>
  );
};

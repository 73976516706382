import * as React from "react";
const SvgHelp = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M21.833 23.5H10.167c-.921 0-1.667-.746-1.667-1.667V10.167c0-.921.746-1.667 1.667-1.667h11.666c.921 0 1.667.746 1.667 1.667v11.666c0 .921-.746 1.667-1.667 1.667Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M15.999 17.041v-.208c0-.68.42-1.05.842-1.333.412-.278.824-.64.824-1.306a1.666 1.666 0 1 0-3.333 0M15.998 19.333a.208.208 0 1 0 .002.417.208.208 0 0 0-.002-.417",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgHelp;

import * as React from "react";
const SvgAiSearch = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_12721_35131)",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M11 2.79A6.562 6.562 0 1 0 15.334 11M13.703 13.703 17.5 17.5"
}), /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M15.001 8.502a3.001 3.001 0 0 1 3.002-3A3.001 3.001 0 0 1 15 2.5 3.001 3.001 0 0 1 12 5.501a3.001 3.001 0 0 1 3.001 3.001Z"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_12721_35131"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgAiSearch;

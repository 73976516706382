import { useMutation, useQuery } from "@apollo/client";

import {
  CREATE_GUIDE_MUTATION,
  DELETE_GUIDE_MUTATION,
  GET_GUIDE_BY_ID_QUERY,
  LIST_GUIDES_QUERY,
  UPDATE_GUIDE_MUTATION,
} from "~/api";
import { ITEMS_PER_PAGE } from "~/constants";
import {
  GET_GUIDE_BY_ID,
  LIST_GUIDES,
  CREATE_GUIDE,
  UPDATE_GUIDE,
  DELETE_GUIDE,
} from "~/services/guides/constants";
import type { GuideType } from "~/types/guide";

export const useCreateGuide = () => {
  const [create, { error, loading }] = useMutation<
    { [CREATE_GUIDE]: GuideType["_id"] },
    {
      input: GuideType;
    }
  >(CREATE_GUIDE_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_GUIDES,
      });
      cache.gc();
    },
  });

  return {
    createGuide: create,
    error,
    loading,
  };
};

export const useUpdateGuide = () => {
  const [update, { error, loading }] = useMutation<
    { [UPDATE_GUIDE]: GuideType },
    {
      input: { image: string; _id: string; sessionId: string };
    }
  >(UPDATE_GUIDE_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_GUIDES,
      });
      cache.gc();
    },
  });

  return {
    updateGuide: update,
    error,
    loading,
  };
};

export const useDeleteGuide = () => {
  const [deleteGuide, { error, loading }] = useMutation<
    { [DELETE_GUIDE]: GuideType["_id"] },
    {
      id: string;
    }
  >(DELETE_GUIDE_MUTATION, {
    update(cache) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: LIST_GUIDES,
      });
      cache.gc();
    },
  });

  return {
    deleteGuide,
    error,
    loading,
  };
};

export const useListGuides = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery = "",
  skipCondition = false,
}: {
  limit?: number;
  skip?: number;
  searchQuery?: string;
  skipCondition?: boolean;
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      where: {
        searchQuery,
      },
    },
  };

  const { data, loading, refetch, fetchMore } = useQuery<
    {
      [LIST_GUIDES]: {
        currentPage: number;
        limit: number;
        skip: number;
        totalCount: number;
        guides: GuideType[];
      };
    },
    {
      params: {
        limit: number;
        skip: number;
        where: {
          searchQuery: string;
        };
      };
    }
  >(LIST_GUIDES_QUERY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    guides = [],
  } = data?.[LIST_GUIDES] ?? {
    currentPage: 0,
    limit: 0,
    skip: 0,
    totalCount: 0,
    guides: [],
  };

  const handleFetchMore = ({
    limit,
    skip,
  }: {
    limit: number;
    skip: number;
  }): void => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          where: {
            searchQuery,
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedGuides = [
          ...previousResult[LIST_GUIDES].guides,
          ...fetchMoreResult[LIST_GUIDES].guides,
        ];

        return {
          ...previousResult,
          list3dGuides: {
            ...previousResult[LIST_GUIDES],
            ...fetchMoreResult[LIST_GUIDES],
            guides: mergedGuides,
          },
        };
      },
    });
  };

  return {
    guides,
    handleFetchMore,
    refetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
  };
};

export const useGetGuideById = ({
  id,
  skipCondition = false,
}: {
  id: string;
  skipCondition?: boolean;
}) => {
  const { data, error, loading } = useQuery<
    { [GET_GUIDE_BY_ID]: GuideType },
    { id: string }
  >(GET_GUIDE_BY_ID_QUERY, {
    variables: {
      id,
    },
    skip: !id || skipCondition,
  });

  return {
    error,
    loading,
    guide: data?.[GET_GUIDE_BY_ID],
  };
};

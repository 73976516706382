import ArrowAscending from "@shared/svg/arrow-ascending.svg?react";
import ArrowDescending from "@shared/svg/arrow-descending.svg?react";
import ArrowsUpDownIcon from "@shared/svg/arrows-up-down.svg?react";
import React, { useMemo } from "react";

import { SORTING_ORDER } from "../constants";

type Props = {
  identifier: string;
  onClick: (val: string[]) => void;
  sort: string[];
};

const SortingArrows = ({ sort = [], identifier = "", onClick }: Props) => {
  const isAscedning = useMemo(() => {
    if (sort.length) {
      if (
        sort?.[0]?.split(":")?.[0]?.includes(identifier) &&
        sort?.[0]?.split(":")?.[1]?.includes(SORTING_ORDER.ASCENDING)
      )
        return true;
    }
    return false;
  }, [sort, identifier]);

  const isDescedning = useMemo(() => {
    if (sort.length) {
      if (
        sort?.[0]?.split(":")?.[0]?.includes(identifier) &&
        sort?.[0]?.split(":")?.[1]?.includes(SORTING_ORDER.DESCENDING)
      )
        return true;
    }
    return false;
  }, [sort, identifier]);

  const onClickIcons = () => {
    if (isAscedning) {
      onClick([
        sort?.[0]?.replace(SORTING_ORDER.ASCENDING, SORTING_ORDER.DESCENDING),
      ]);
      return;
    } else if (isDescedning) {
      onClick([]);
      return;
    }

    return onClick([`${identifier}:${SORTING_ORDER.ASCENDING}`]);
  };

  return (
    <div className="flex cursor-pointer" onClick={onClickIcons}>
      {!isAscedning && !isDescedning && <ArrowsUpDownIcon />}
      {isAscedning && <ArrowAscending />}
      {isDescedning && <ArrowDescending />}
    </div>
  );
};

export default SortingArrows;

import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { Info } from "phosphor-react";
import { oneOf, string } from "prop-types";
import React from "react";
import { useIntl } from "react-intl";

export const ExcerptedParagraph = ({ content = "", limit = 300 }) => {
  const { messages } = useIntl();
  const [showReadMore, setShowReadMore] = React.useState(true);
  const excerptedContent = React.useMemo(
    () => content.substring(0, +limit + 1),
    [content, limit],
  );

  return (
    <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
      {showReadMore ? excerptedContent : content}
      {content.length > limit && (
        <span
          className="u-cursor-pointer u-text-color-blue-v2-100 u-font-bold"
          onClick={(e) => {
            e.stopPropagation();
            setShowReadMore((p) => !p);
          }}
        >
          {showReadMore
            ? ` ...${messages?.common?.readMore}`
            : ` ${messages?.common?.showLess}`}
        </span>
      )}
    </BodyText>
  );
};

export const HelperText = ({ className, type = "info", children }) => {
  const classes = ["helper-text", type || "", className || ""].join(" ");

  return (
    <p className={classes}>
      <span className="icon">
        <Info size={16} />
      </span>
      {children}
    </p>
  );
};

// Types can be `info`, `success`, `warning`, `error`, `disabled`
export const HelperTag = ({
  label = "disabled",
  type = "disabled",
  className,
  ...props
}) => {
  const classes = ["helper-tag", type || "", className || ""].join(" ");

  return (
    <span className={classes} {...props}>
      {label}
    </span>
  );
};

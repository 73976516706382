import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { SingleSelectDropdown } from "@shared/ui/Dropdowns";
import { Input, Textarea } from "@shared/ui/Inputs";
import AppModal from "@shared/ui/Modal";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import { registerMixpanelEvent } from "#/src/utils/_mixpanel";
import { CONTACT_SALES_REQUEST_SENT } from "~/constants";
import { useRaiseOemSupportQuery } from "~/services";

const ContactSalesModal = ({
  onClose,
  productType,
}: {
  onClose: (val?: boolean) => void;
  productType: string;
}) => {
  const { messages } = useIntl();

  const [formData, setFormData] = React.useState({
    phone: "",
    companySize: "",
    request: "",
  });

  const { loading, raiseOemSupportQuery } = useRaiseOemSupportQuery();

  return (
    <AppModal
      handleSubmit={() => {
        raiseOemSupportQuery(
          formData.companySize,
          formData.phone,
          false,
          formData.request,
          productType,
        )
          .then(() => {
            toast(
              <Toast message={messages?.plans?.["supportRequestRaised"]} />,
              {
                closeButton: false,
              },
            );
            onClose(false);
            registerMixpanelEvent(CONTACT_SALES_REQUEST_SENT);
          })
          .catch((error) => {
            toast(
              <Toast
                message={
                  error.message || messages.common["errors"].somethingWrong
                }
                type={TOAST_TYPES.ERROR}
              />,
              {
                closeButton: false,
              },
            );
          });
      }}
      isOpen
      hideCancelButton
      updateButtonText={
        messages.menus["productStore"].contactSalesModal.submitRequest
      }
      disableUpdate={loading || !formData.companySize || !formData.phone}
      className="h-[520px] w-[560px]"
      title={messages.menus["productStore"].contactSales}
      handleClose={() => onClose()}
      content={
        <div className="flex flex-col space-y-2xl">
          <BodyText size={BODY_TEXT_SIZES.SMALL} color="text-secondary">
            {messages.menus["productStore"].contactSalesModal.subHeading}
          </BodyText>
          <Input
            keyId="phone"
            label={messages.menus["productStore"].contactSalesModal.phone}
            placeholder="+01 000 0000 000"
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                phone: e.target.value,
              }));
            }}
            value={formData.phone}
          />
          <SingleSelectDropdown
            keyId="company-size"
            label={messages.menus["productStore"].contactSalesModal.companySize}
            placeholder={
              messages.menus["productStore"].contactSalesModal
                .companySizePlaceholder
            }
            options={[
              {
                value: "1-10",
                label: "1-10",
              },
              {
                value: "10-50",
                label: "10-50",
              },
              {
                value: "50-100",
                label: "50-100",
              },
              {
                value: "100-500",
                label: "100-500",
              },
            ]}
            onChange={(selected) =>
              setFormData((prev) => ({
                ...prev,
                companySize: selected?.[0]?.value,
              }))
            }
            values={[
              ...(formData.companySize
                ? [
                    {
                      value: formData.companySize,
                      label: formData.companySize,
                    },
                  ]
                : []),
            ]}
          />
          <Textarea
            keyId="phone"
            label={messages.menus["productStore"].contactSalesModal.furtherHelp}
            className="h-9xl"
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                request: e.target.value,
              }));
            }}
            value={formData.request}
          />
        </div>
      }
    />
  );
};

export default ContactSalesModal;

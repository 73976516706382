// libs
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import React from "react";
import { useIntl } from "react-intl";

const DropdownFooter = ({ deleteAll, disabled }) => {
  const { messages: msg } = useIntl();
  return (
    <div className="filter-dropdown-footer mt-md">
      <Button
        variant={BUTTON_VARIANTS.LINK}
        disabled={disabled}
        text={msg.common?.filters.removeAll}
        className="no-styles"
        onClick={() => {
          if (disabled) return;
          deleteAll();
        }}
      />
    </div>
  );
};

export default DropdownFooter;

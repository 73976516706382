import TeamsFilterIcon from "@shared/svg/teams-filter.svg?react";

import {
  OpenFolderIcon,
  SidebarFacilityIcon,
  SidebarMachinesIcon,
  UserIcon,
  QrCodeIcon,
} from "~/icons/icons";

export const TICKET_STATUS = "TICKET_STATUS";
export const ASSIGNEE = "ASSIGNEE";
export const TICKET_TYPE = "TICKET_TYPE";
export const FACILITY_NAME = "FACILITY_NAME";
export const CONNECTION_NAME = "CONNECTION_NAME";
export const DATE_RANGE = "DATE_RANGE";
export const SCHEDULED = "SCHEDULED";
export const TEAMS = "TEAMS";
export const ASSET_NAME = "ASSET_NAME";
export const ASSET_ASSIGNED = "ASSET_ASSIGNED";
export const ASSET_QR_CODE_ACCESS = "ASSET_QR_CODE_ACCESS";
export const ASSET_TYPE = "ASSET_TYPE";
export const CREATED_BY = "CREATED_BY";
export const DUE_DATE = "DUE_DATE";

export const TicketStatusFilterObj = {
  id: TICKET_STATUS,
  name: "Ticket Status",
  icon: OpenFolderIcon,
};

export const AssigneeFilterObj = {
  id: ASSIGNEE,
  name: "Assignee",
  icon: UserIcon,
};

export const ScheduleFilterObj = {
  id: SCHEDULED,
  name: "Scheduled",
  icon: OpenFolderIcon,
  options: [{ value: "Yes" }, { value: "No" }],
};

export const TicketTypeFilterObj = {
  id: TICKET_TYPE,
  name: "Ticket Type",
  icon: OpenFolderIcon,
};

export const FacilityFilterObj = {
  id: FACILITY_NAME,
  name: "Facility Name",
  icon: SidebarFacilityIcon,
};

export const CreatedByFilterObj = {
  id: CREATED_BY,
  name: "Created By",
  icon: UserIcon,
};

export const DueDateFilterObj = {
  id: DUE_DATE,
  name: "Due Date",
  icon: OpenFolderIcon,
};

export const FacilityFilterObjV2 = {
  id: CONNECTION_NAME,
  name: "Connection Name",
  icon: SidebarFacilityIcon,
};

export const getFacilityFilterObj = (isGrowthLoopEnabled) => {
  return isGrowthLoopEnabled ? FacilityFilterObjV2 : FacilityFilterObj;
};

export const AssetFilterObj = {
  id: ASSET_NAME,
  name: "Asset Name",
  icon: SidebarMachinesIcon,
};

export const DateRangeFilterObj = {
  id: DATE_RANGE,
  name: "Date Range",
  icon: OpenFolderIcon,
};

export const AssetAssignedFilterObj = {
  id: ASSET_ASSIGNED,
  name: "Asset assigned",
  icon: SidebarMachinesIcon,
  options: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
};

export const AssetQRAccessFilterObj = {
  id: ASSET_QR_CODE_ACCESS,
  name: "QR code enabled",
  icon: QrCodeIcon,
  options: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
};

export const AssetTypeFilterObj = {
  id: ASSET_TYPE,
  name: "Asset Type",
  icon: SidebarMachinesIcon,
};

export const TeamsFilterObj = {
  id: TEAMS,
  name: "Teams",
  icon: TeamsFilterIcon,
};

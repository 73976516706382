import {
  ASSET_ASSIGNED,
  ASSET_QR_CODE_ACCESS,
  ASSET_TYPE,
  CREATED_BY,
  DUE_DATE,
  CONNECTION_NAME,
  FACILITY_NAME,
  TEAMS,
  ASSET_NAME,
} from "~/constants/filters/filters";

export const defaultFilters = {
  [ASSET_ASSIGNED]: [],
  [ASSET_QR_CODE_ACCESS]: [],
  [ASSET_TYPE]: [],
  [FACILITY_NAME]: [],
  [CONNECTION_NAME]: [],
  [TEAMS]: [],
};

const applyFilters = (activeFilters = defaultFilters, _filter) => {
  if (
    activeFilters[_filter]?.length > 0 ||
    typeof activeFilters[_filter] === "boolean"
  ) {
    return activeFilters[_filter];
  } else {
    return undefined;
  }
};

export const generateAssetsQueryWhereCond = (
  activeFilters = defaultFilters,
) => {
  const customFields = [];

  for (const filter of Object.keys(activeFilters)) {
    if (!defaultFilters[filter] && activeFilters[filter]?.length) {
      customFields.push({
        fieldId: filter,
        value: activeFilters[filter],
      });
    }
  }

  const assetTypes = applyFilters(activeFilters, ASSET_TYPE);
  const assetAssigned = applyFilters(activeFilters, ASSET_ASSIGNED);
  const customer_in = applyFilters(activeFilters, FACILITY_NAME);
  const connection_in = applyFilters(activeFilters, CONNECTION_NAME);
  const teams_in = applyFilters(activeFilters, TEAMS);
  const isQRCodeEnabled_in = applyFilters(activeFilters, ASSET_QR_CODE_ACCESS);

  return {
    ...(assetTypes !== undefined ? { assetTypes } : {}),
    ...(assetAssigned !== undefined
      ? { filterAssetAssigned: assetAssigned }
      : {}),
    ...(isQRCodeEnabled_in !== undefined
      ? { isQRCodeEnabled_in: [isQRCodeEnabled_in] }
      : {}),
    ...(customer_in
      ? { customer_in }
      : connection_in
      ? { customer_in: connection_in }
      : {}),
    ...(teams_in ? { teams_in } : {}),
    ...(customFields?.length ? { customFields } : {}),
  };
};

export const generatePMEQueryWhereCond = (activeFilters = defaultFilters) => {
  const customer_in = applyFilters(activeFilters, FACILITY_NAME);
  const connection_in = applyFilters(activeFilters, CONNECTION_NAME);
  const machine_in = applyFilters(activeFilters, ASSET_NAME);
  const createdBy_in = applyFilters(activeFilters, CREATED_BY);
  const dueDate = applyFilters(activeFilters, DUE_DATE);

  return {
    ...(customer_in ? { customer_in } : {}),
    ...(connection_in ? { customer_in: connection_in } : {}),
    ...(machine_in ? { machine_in } : {}),
    ...(createdBy_in ? { createdBy_in } : {}),
    ...(dueDate ? { dueDate } : {}),
  };
};

/* eslint-disable react-hooks/rules-of-hooks */
import { useLazyQuery, useQuery } from "@apollo/client";

import {
  GET_OWN_OEM_TICKETS_BY_STATUS,
  GET_OWN_OEM_TICKETS_BY_TYPES_QUERY,
  GET_OWN_OEM_TICKET_BY_ID,
  GET_SINGLE_LIST_TICKET_BY_ID_QUERY,
  LIST_OEM_CUSTOMERS_IN_AREA,
  LIST_OWN_OEM_ALL_TICKETS_QUERY,
  LIST_OWN_OEM_CUSTOM_ADDITIONAL_FIELDS_QUERY,
  LIST_OWN_OEM_KANBAN_TICKETS,
  LIST_OWN_OEM_OPEN_TICKETS_QUERY,
  LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
  LIST_OWN_OEM_TICKETS_QUERY,
  LIST_OWN_OEM_USER_CLOSED_TICKETS_QUERY,
  LIST_OWN_OEM_USER_OPEN_TICKETS_QUERY,
  LIST_OWN_OEM_ASSIGNEES_QUERY,
  LIST_OWN_TICKET_MENTIONABLE_USERS_QUERY,
  DOWNLOAD_WO_PDF_BY_ID,
  LIST_ALL_OEM_CUSTOMERS_IN_AREA,
} from "~/api";
import { queryTracker } from "~/atoms";
import { ITEMS_PER_PAGE } from "~/constants";
import { ticketTransformer } from "~/transformers";
import {
  generateListOwnOemTicketsVariables,
  generateOpenTicketQueryWhereCond,
} from "~/utils/filter/_openTicketQueryCond";

export const getTicketById = (id, skipCondition = false) => {
  const {
    data: { getOwnOemTicketById } = {},
    loading,
    refetch: refetchGetOwnOemTicketById,
  } = useQuery(GET_OWN_OEM_TICKET_BY_ID, {
    variables: {
      id,
    },
    skip: !id || skipCondition,
    fetchPolicy: "network-only",
  });

  return {
    groupedByDate: ticketTransformer.groupedByDate(getOwnOemTicketById),
    ticket: getOwnOemTicketById,
    loading,
    refetch: refetchGetOwnOemTicketById,
  };
};

export const listCustomAdditionalFields = () => {
  const {
    data: { listOwnOemCustomFields: customAdditionalFields = [] } = {},
    loading,
  } = useQuery(LIST_OWN_OEM_CUSTOM_ADDITIONAL_FIELDS_QUERY, {
    variables: {
      type: "tickets",
    },
    fetchPolicy: "network-only",
  });

  return {
    customAdditionalFields,
    loading,
  };
};

export const listAssignees = ({ skipCondition = false }) => {
  const {
    data: { listOwnOemSupportAccounts = [] } = {},
    loading,
    error,
  } = useQuery(LIST_OWN_OEM_ASSIGNEES_QUERY, { skip: skipCondition });

  return {
    error,
    loading,
    listOwnOemSupportAccounts,
  };
};

export const listSupportAccounts = (variables) => {
  const {
    data: { listOwnOemSupportAccounts = [] } = {},
    loading,
    error,
  } = useQuery(LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY, {
    variables,
  });

  return {
    error,
    loading,
    listOwnOemSupportAccounts,
    supportAccountOptions: ticketTransformer.makeOptions(
      listOwnOemSupportAccounts,
    ),
  };
};

export const listTicketMentionableUsers = (
  variables,
  skipCondition = false,
) => {
  const {
    data: { listOwnTicketMentionableUsers } = {
      facilityUsers: [],
      oemUsers: [],
    },
    loading,
    error,
  } = useQuery(LIST_OWN_TICKET_MENTIONABLE_USERS_QUERY, {
    variables,
    skip: skipCondition,
  });

  return {
    error,
    loading,
    listOwnTicketMentionableUsers,
  };
};

// LIST_OEM_CUSTOMERS_IN_AREA

export const useListOemCustomersInArea = ({ where = {}, activeFilters }) => {
  const variables = generateListOwnOemTicketsVariables({
    activeFilters,
    where,
  });

  const {
    data: { listOemCustomersInArea: data = [] } = {},
    loading,
    error,
    refetch,
  } = useQuery(LIST_OEM_CUSTOMERS_IN_AREA, {
    variables,
    fetchPolicy: "network-only",
  });
  return {
    loading,
    error,
    data,
    refetch,
  };
};

export const listOwnOemAllTickets = (activeFilters) => {
  const filterConditions = generateOpenTicketQueryWhereCond(activeFilters);
  const hasFilter = Object.keys(filterConditions)?.length;
  const variables = {
    ...(hasFilter
      ? {
          params: {
            where: filterConditions,
          },
        }
      : {}),
  };
  queryTracker.add([{ key: "LIST_OWN_OEM_ALL_TICKETS_QUERY", variables }]);

  const {
    data: { listOwnOemAllTickets: data = [] } = {},
    loading,
    error,
    refetch,
  } = useQuery(LIST_OWN_OEM_ALL_TICKETS_QUERY, {
    variables,
  });
  return {
    loading,
    error,
    data,
    refetch,
  };
};

// LIST_ALL_OEM_CUSTOMERS_IN_AREA

export const useListAllOemCustomersInArea = ({ where = {}, activeFilters }) => {
  const variables = generateListOwnOemTicketsVariables({
    activeFilters,
    where,
  });

  const {
    data: { listAllOemCustomersInArea: data = [] } = {},
    loading,
    error,
    refetch,
  } = useQuery(LIST_ALL_OEM_CUSTOMERS_IN_AREA, {
    variables,
    fetchPolicy: "cache-and-network",
  });
  return {
    loading,
    error,
    data,
    refetch,
  };
};

export const listOwnUserOemOpenTickets = () => {
  const variables = {};
  queryTracker.add([
    { key: "LIST_OWN_OEM_USER_OPEN_TICKETS_QUERY", variables },
  ]);

  const { data: { listOwnOemUserOpenTickets: data = [] } = {}, loading } =
    useQuery(LIST_OWN_OEM_USER_OPEN_TICKETS_QUERY, {
      variables,
    });

  return {
    loading,
    data,
  };
};

export const listOwnUserOemClosedTickets = () => {
  const variables = {};
  queryTracker.add([
    { key: "LIST_OWN_OEM_USER_CLOSED_TICKETS_QUERY", variables },
  ]);
  const { data: { listOwnOemUserClosedTickets: data = [] } = {}, loading } =
    useQuery(LIST_OWN_OEM_USER_CLOSED_TICKETS_QUERY, { variables });

  return {
    loading,
    data,
  };
};

export const useListOwnOemOpenTickets = ({ activeFilters }) => {
  const filterConditions = generateOpenTicketQueryWhereCond(activeFilters);
  const hasFilter = Object.keys(filterConditions)?.length;
  const variables = {
    ...(hasFilter
      ? {
          params: {
            where: filterConditions,
          },
        }
      : {}),
  };
  const {
    data: { listOwnOemOpenTickets = [] } = {},
    loading,
    error,
  } = useQuery(LIST_OWN_OEM_OPEN_TICKETS_QUERY, { variables });
  return {
    loading,
    error,
    listOwnOemOpenTickets,
  };
};

export const useCheckTicketHasStatus = () => {
  const [getTickets, { loading, error }] = useLazyQuery(
    GET_OWN_OEM_TICKETS_BY_STATUS,
  );

  return {
    loading,
    error,
    checkIfStatusIsUsed: async (statusId) => {
      const { data: { checkTicketHasStatus: isUsed } = {} } = await getTickets({
        variables: {
          statusId,
        },
        fetchPolicy: "network-only",
      });

      return isUsed;
    },
  };
};

export const useListOwnOemTickets = ({
  activeFilters,
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery,
  where,
  skipCondition,
  sort = [],
  notifyOnNetworkStatusChange = true,
} = {}) => {
  const variables = generateListOwnOemTicketsVariables({
    activeFilters,
    limit,
    skip,
    searchQuery,
    where,
    sort,
  });
  queryTracker.add([{ key: "LIST_OWN_OEM_TICKETS_QUERY", variables }]);

  const {
    data: { listOwnOemTickets = {} } = {},
    loading,
    refetch,
    fetchMore,
  } = useQuery(LIST_OWN_OEM_TICKETS_QUERY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange,
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    tickets = [],
  } = listOwnOemTickets;

  const handleFetchMore = ({ limit, skip }) => {
    const variables = generateListOwnOemTicketsVariables({
      activeFilters,
      limit,
      skip,
      searchQuery,
      where,
      sort,
    });
    fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedTickets = [
          ...previousResult.listOwnOemTickets.tickets,
          ...fetchMoreResult.listOwnOemTickets.tickets,
        ];

        const updates = {
          ...previousResult,
          listOwnOemTickets: {
            ...previousResult.listOwnOemTickets,
            ...fetchMoreResult.listOwnOemTickets,
            tickets: mergedTickets,
          },
        };

        return updates;
      },
    });
  };

  return {
    tickets,
    handleFetchMore,
    refetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
  };
};

export const useListOwnOemKanbanTickets = ({
  activeFilters,
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery,
  sort,
  where,
  skipCondition = false,
} = {}) => {
  const filterConditions = generateOpenTicketQueryWhereCond(activeFilters);
  const hasFilter = Object.keys(filterConditions)?.length;
  const variables = {
    ...(hasFilter
      ? {
          params: {
            where: {
              ...filterConditions,
              searchQuery,
              ...(where || {}),
            },
            limit,
            skip,
            ...(sort ? { sort } : {}),
          },
        }
      : {
          params: {
            limit,
            skip,
            ...(sort ? { sort } : {}),
            where: {
              searchQuery,
              ...(where || {}),
            },
          },
        }),
  };

  const {
    data: { listOwnOemKanbanTickets = {} } = {},
    loading,
    refetch: ticketsRefetch,
    fetchMore,
  } = useQuery(LIST_OWN_OEM_KANBAN_TICKETS, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    skip: skipCondition,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    columns = [],
  } = listOwnOemKanbanTickets;

  return {
    columns,
    fetchMore,
    ticketsRefetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
  };
};

export const useCheckTicketHasWorkOrderType = () => {
  const [checkTickets, { loading, error }] = useLazyQuery(
    GET_OWN_OEM_TICKETS_BY_TYPES_QUERY,
  );
  return {
    loading,
    error,
    checkIfWorkOrderTypeUsed: async (ticketTypeId) => {
      const { data: { checkIfTicketsExistForType } = {} } = await checkTickets({
        variables: { ticketTypeId },
      });
      return !!checkIfTicketsExistForType;
    },
  };
};

export const useGetTicketByIdForList = () => {
  const [getTicket] = useLazyQuery(GET_SINGLE_LIST_TICKET_BY_ID_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  return { getTicketById: async (id) => getTicket({ variables: { id } }) };
};

export const useGetWOPdfById = () => {
  const [download, { data: { downloadWOPDF = "" } = {}, loading, error }] =
    useLazyQuery(DOWNLOAD_WO_PDF_BY_ID);

  return {
    downloadingPdf: loading,
    pdfDownloadError: error,
    url: downloadWOPDF,
    getWOPdf: async (id, procedureIdsToInclude, sectionsToShow, uuid) =>
      download({
        variables: {
          input: { id, procedureIdsToInclude, sectionsToShow, uuid },
        },
        fetchPolicy: "network-only",
      }),
  };
};

import * as React from "react";
const SvgTabsMapIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 16 16",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  d: "M6 12.811V2m4 1.189v10.81"
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  d: "M14 3.778v8c0 .573-.367 1.083-.912 1.264l-2.666.89c-.274.09-.57.09-.844 0l-3.156-1.053a1.334 1.334 0 0 0-.844 0l-1.823.608A1.334 1.334 0 0 1 2 12.222v-8c0-.574.367-1.083.912-1.265l2.666-.889c.274-.09.57-.09.844 0l3.156 1.053c.274.091.57.091.844 0l1.823-.608A1.333 1.333 0 0 1 14 3.778Z",
  clipRule: "evenodd"
}));
export default SvgTabsMapIcon;

import React from "react";

const MessageSpinner = ({ size = "w-5xl h-5xl" }) => (
  <div className="w-full h-9xl flex items-center justify-center">
    <div
      className={`inline-block border-4 border-brand ${size} border-solid rounded-full border-t-white animate-spin`}
    />
  </div>
);

export default MessageSpinner;

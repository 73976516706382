import { Avatar } from "@shared/ui/Avatar";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { Input as SharedInput } from "@shared/ui/Inputs";
import Tag from "@shared/ui/Tag";
import { CaretDown, Check, WarningCircle } from "phosphor-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";
import useOnClickOutside from "use-onclickoutside";

import { COLOR, ITEMS_BEFORE_PAGE } from "#/src/constants";
import { getOptionBackgroundColor } from "#/src/utils";
import { generateStatusBackgroundColor } from "#/src/utils/_colors";
import DropdownFooter from "~/components/filters/_dropdownFooter";
import { trimText } from "~/utils";

const DROPDOWN_ITEM_CHAR_LIMIT = 30;

export function Input({
  uppercaseLabel,
  register,
  name,
  label,
  placeholder,
  help,
  error,
  type,
  readOnly,
  clearErrors,
  keyId,
  defaultValue,
}) {
  const [hasValue, setHasValue] = useState(false);
  const classes = [
    "c-input",
    error && "c-input--error",
    hasValue && "has-value",
  ].join(" ");

  React.useEffect(() => {
    if (defaultValue?.length) {
      setHasValue(true);
    }
  }, [defaultValue]);

  return (
    <>
      {label && (
        <label
          htmlFor={keyId}
          className={`c-label ${uppercaseLabel ? "c-label--uppercase" : ""}`}
        >
          {label}
        </label>
      )}
      <input
        defaultValue={defaultValue}
        onKeyUp={() => clearErrors?.(name)}
        id={keyId}
        readOnly={readOnly && readOnly}
        type={type}
        className={classes}
        {...register}
        name={name}
        placeholder={placeholder}
      />
      <p className="form-group-validationFeedback">
        <WarningCircle className="form-error-icon" size={16} />
        {error?.message}
      </p>
      {help && <p className="form-groupHelper">{help}</p>}
    </>
  );
}

export function Textarea({
  register,
  name,
  label,
  placeholder,
  error,
  defaultValue,
}) {
  return (
    <>
      <label className="c-label">{label}</label>
      <textarea
        className={`c-input c-input--textarea ${error && "c-input--error"}`}
        {...register}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
      ></textarea>
      <p className="form-group-validationFeedback">{error?.message}</p>
    </>
  );
}

export function Select({
  register,
  options,
  name,
  label,
  error,
  defaultValue,
  placeholder,
  disabled,
}) {
  const withPlaceholder = placeholder
    ? [{ value: "", label: placeholder }]
    : [];
  return (
    <div>
      <label className="c-label">{label}</label>
      <select
        disabled={disabled}
        defaultValue={defaultValue}
        name={name}
        className={`c-input c-input--select c-input--noAppearance ${
          error && "c-input--error"
        }`}
        {...register}
      >
        {[...withPlaceholder, ...options].map((item) => (
          <option key={item.value} value={item.value} disabled={item.disabled}>
            {item.label}
          </option>
        ))}
      </select>
      <p className="form-group-validationFeedback">{error?.message}</p>
    </div>
  );
}

export function Select3({
  avatar,
  register,
  options,
  customAvatar,
  name,
  label,
  error,
  defaultValue,
  placeholder,
  disabled,
  getValues,
  status,
  buttonModifier,
  singleSelect,
  customColor,
  styledValue,
  onChange = (args) => {},
  value,
  search = false,
  dropdownTitle,
  labelModifier,
  uppercaseLabel = false,
  onToggle = () => {},
  selectedTextTransform = "capitalize",
  onScrollToBottom = () => {},
  isLoading,
  setSearchQuery = () => {},
  valueClassname,
  areGroupedOptions = false,
  groupedItemsKey = "",
  groupedItemsHeadingClassname = "",
  dropdownTitleClassName = "",
  buttonClassName = "",
  dropdownClassName = "",
  position = "",
}) {
  const { messages } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  // Use for trigger render so we can collect values and rerender placeholder.
  const [__, setChangeHappend] = useState(Math.random());

  const subscribe = useRef(false);

  const ref = useRef(null);

  const toggle = (event) => {
    if (disabled) return;
    if (isOpen) {
      setIsOpen(false);
      onToggle(false);
    } else {
      setIsOpen(true);
      onToggle(true);
    }
    event.stopPropagation();
    event.preventDefault();
  };

  useEffect(() => {
    subscribe.current = true;
  }, []);

  const close = () => {
    setIsOpen(false);
    onToggle(false);
  };

  useOnClickOutside(ref, close);

  const handleClick = () => {
    setChangeHappend(Math.random());
  };

  const filteredOptions = useMemo(() => {
    return areGroupedOptions
      ? options.map((groupedOption) => {
          return {
            ...groupedOption,
            [groupedItemsKey]: groupedOption[groupedItemsKey].filter((item) =>
              item.label.toLowerCase().includes(searchValue?.toLowerCase()),
            ),
          };
        })
      : options?.filter((item) =>
          item.label.toLowerCase().includes(searchValue?.toLowerCase()),
        );
  }, [options, searchValue]);

  const hasGroupedSearchResults = useMemo(() => {
    if (!areGroupedOptions) return false;

    return filteredOptions.some(
      (filteredOption) => filteredOption[groupedItemsKey].length > 0,
    );
  }, [filteredOptions, searchValue, options]);

  const isPropsPlaceHolderReturned = () => {
    if (!subscribe.current && !defaultValue) {
      return true;
    } else {
      if (subscribe.current && !defaultValue?.length) {
        return true;
      }
    }
    return false;
  };
  const renderPlaceholder = () => {
    if (!subscribe.current && !defaultValue) {
      return placeholder;
    } else {
      if (subscribe.current && !defaultValue?.length) {
        return placeholder;
      }
    }

    let buttonLabel = "";

    if (getValues(name)) {
      if (areGroupedOptions) {
        for (const group of options) {
          for (const item of group[groupedItemsKey]) {
            if (getValues(name)?.toLowerCase() === item?.value?.toLowerCase()) {
              buttonLabel = item?.label;
              break;
            }
          }
          if (buttonLabel) break;
        }
      } else {
        for (const item of options) {
          if (getValues(name)?.toLowerCase() === item?.value?.toLowerCase()) {
            buttonLabel = item?.label;
            break;
          }
        }
      }
    } else {
      for (const item of options) {
        if (item.value === defaultValue) {
          buttonLabel = item.label;
          break;
        }
      }
    }

    return buttonLabel.length > DROPDOWN_ITEM_CHAR_LIMIT
      ? `${trimText(buttonLabel, DROPDOWN_ITEM_CHAR_LIMIT)}`
      : buttonLabel;
  };

  const defaultColor = options?.find(
    (option) => option?.value === defaultValue,
  )?.color;

  const renderPlaceholderStyle = {
    textTransform: isPropsPlaceHolderReturned()
      ? "capitalize"
      : selectedTextTransform,
  };

  const renderButton = () => {
    const singleItem = options?.find((option) => {
      if (getValues) {
        return option.value === getValues(name);
      } else {
        return option.label === renderPlaceholder();
      }
    });

    return !status ? (
      <button
        type="button select-custom-field-selected-value"
        disabled={disabled}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={
          !singleSelect && defaultColor && !areGroupedOptions
            ? {
                backgroundColor: isHovered
                  ? defaultColor
                  : getOptionBackgroundColor(defaultColor),
                color: isHovered ? COLOR.$white_v2 : `${defaultColor}`,
              }
            : {}
        }
        className={`c-dropdown-button c-dropdown-button--small ${
          singleSelect
            ? "c-dropdown-button--select3"
            : "c-dropdown-button--small"
        } ${error && "c-input--error"} ${buttonModifier || ""} ${
          disabled ? "cursor-default" : "cursor-pointer"
        } ${
          !defaultColor && disabled ? "!bg-transparent !p-0 !border-0" : ""
        } ${buttonClassName}`}
        onClick={toggle}
      >
        {styledValue ? (
          <span
            className={`tag-info ${valueClassname}`}
            style={{
              ...renderPlaceholderStyle,
              backgroundColor: singleItem?.color,
            }}
          >
            {renderPlaceholder()}
          </span>
        ) : (
          <span
            className={`${
              !defaultColor && disabled
                ? "font-medium text-secondary text-xs font-manrope"
                : ""
            }`}
            style={renderPlaceholderStyle}
          >
            {renderPlaceholder()}
          </span>
        )}
        {!disabled && (
          <div
            className="c-dropdown-buttonHandle"
            style={
              !singleSelect && defaultColor && !areGroupedOptions
                ? {
                    color: isHovered ? COLOR.$white_v2 : `${defaultColor}`,
                  }
                : {}
            }
          >
            <CaretDown size={12} weight="bold" />
          </div>
        )}
      </button>
    ) : (
      <button
        type="button"
        disabled={disabled}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          backgroundColor:
            isOpen || isHovered
              ? singleItem?.color
              : generateStatusBackgroundColor(singleItem?.color),
          color: isOpen || isHovered ? COLOR.$white_v2 : singleItem?.color,
        }}
        className={`c-dropdown-button c-dropdown-button--small  ${
          singleSelect
            ? "c-dropdown-button--select3"
            : "c-dropdown-button--small"
        } ${error && "c-input--error"} ${buttonModifier || ""}`}
        onClick={toggle}
      >
        {styledValue ? (
          <span
            className="tag-info"
            style={{ backgroundColor: singleItem?.color }}
          >
            {renderPlaceholder()}
          </span>
        ) : (
          renderPlaceholder()
        )}
        <div className="c-dropdown-buttonHandle">
          <CaretDown size={12} weight="bold" />
        </div>
      </button>
    );
  };

  const renderOption = (item, index) => {
    let found = singleSelect
      ? item?.value === value
      : item?.value === defaultValue;
    return (
      <React.Fragment key={item.value}>
        <label
          onClick={handleClick}
          className={"c-dropdown-menuItem c-dropdown-menuItem--v3"}
          htmlFor={item.value}
        >
          <input
            id={item.value}
            key={item.value}
            value={item.value}
            disabled={item.disabled}
            onClick={(e) => {
              e.stopPropagation();
              onChange(item.value);
              setIsOpen(false);
            }}
            type="radio"
            defaultChecked={defaultValue === item.value}
            className="c-checkboxInput c-dropdown-menuItemInput color-pallet-select3"
            {...register}
          />
          {avatar ? (
            customAvatar ? (
              customAvatar(item)
            ) : (
              <Avatar name={item.name || item.label} className="mr-sm" />
            )
          ) : null}
          {status ? (
            <Tag
              label={
                item.label.length > DROPDOWN_ITEM_CHAR_LIMIT
                  ? `${trimText(item.label, DROPDOWN_ITEM_CHAR_LIMIT)}`
                  : item.label
              }
              style={{
                color: item?.color,
                backgroundColor: generateStatusBackgroundColor(item?.color),
              }}
              className="z-10"
            />
          ) : customColor ? (
            <Tag
              label={
                item.label.length > DROPDOWN_ITEM_CHAR_LIMIT
                  ? `${trimText(item.label, DROPDOWN_ITEM_CHAR_LIMIT)}`
                  : item.label
              }
              className="mr-sm !mb-0 z-10"
              style={{
                color: item?.color,
                backgroundColor: getOptionBackgroundColor(item?.color),
              }}
            />
          ) : (
            <span>
              {item.label.length > DROPDOWN_ITEM_CHAR_LIMIT
                ? `${trimText(item.label, DROPDOWN_ITEM_CHAR_LIMIT)}`
                : item.label}
            </span>
          )}
          {found && (
            <span className="c-dropdown-menuItemIcon">
              <Check size={16} />
            </span>
          )}
        </label>
        {index === options.length - ITEMS_BEFORE_PAGE && (
          <Waypoint onEnter={onScrollToBottom} />
        )}
      </React.Fragment>
    );
  };

  return (
    <div ref={ref}>
      {label && (
        <label
          className={`c-label ${uppercaseLabel ? "c-label--uppercase" : ""} ${
            labelModifier || ""
          }`}
        >
          {label}
        </label>
      )}

      <div
        className={`c-dropdown ${isOpen ? "c-dropdown--shown" : ""} ${
          error ? "c-dropdown--error" : ""
        }`}
      >
        {renderButton()}

        <div
          className={`c-dropdown-menu c-dropdown-menu--v3 ${dropdownClassName} ${
            position === "middle" ? "!-left-1/2" : ""
          }`}
        >
          <input
            hidden
            id={`hidden-${name}`}
            key={`hidden-${name}`}
            value=""
            type="radio"
            {...register}
          />
          {dropdownTitle && (
            <div className="makula-dropdown-title u-width-100">
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                color="text-secondary"
                className={`capitalize break-all ${dropdownTitleClassName}`}
              >
                {dropdownTitle}
              </BodyText>
            </div>
          )}
          {search && (
            <div className="makula-search-in-dropdown">
              <input
                type="search"
                placeholder={messages?.tickets?.search}
                onChange={(e) => {
                  setSearchValue(e?.target?.value);
                  setSearchQuery(e?.target?.value);
                }}
              />
            </div>
          )}
          <div className="dropdown-items-wrapper u-width-100 u-flex-1 u-overflow-auto">
            {areGroupedOptions &&
              hasGroupedSearchResults &&
              filteredOptions?.map((groupOption) => {
                if (!groupOption[groupedItemsKey].length) return null;

                return (
                  <React.Fragment key={groupOption._id}>
                    <div
                      style={{
                        color: groupOption.color,
                        background: getOptionBackgroundColor(groupOption.color),
                      }}
                      className={`${groupedItemsHeadingClassname} grouped-dropdown-item-heading`}
                    >
                      {groupOption.name}
                    </div>
                    {groupOption[groupedItemsKey].map((item, index) =>
                      renderOption(item, index),
                    )}
                  </React.Fragment>
                );
              })}
            {filteredOptions.length > 0 &&
              !areGroupedOptions &&
              filteredOptions?.map((item, index) => renderOption(item, index))}
            {((!isLoading && !filteredOptions.length) ||
              (areGroupedOptions && !hasGroupedSearchResults)) && (
              <div className="empty-dropdown u-text-center">
                No available options!
              </div>
            )}
            {isLoading && (
              <span className="select3-dropdown-loading-text u-margin-l-3 u-margin-t-3">
                {messages?.common?.loading}
              </span>
            )}
          </div>
        </div>
      </div>
      <p className="form-group-validationFeedback">{error?.message}</p>
    </div>
  );
}

export function Select3Portal({
  avatar,
  register,
  options,
  name,
  label,
  error,
  defaultValue,
  placeholder,
  disabled,
  getValues,
  status,
  buttonModifier,
  singleSelect,
  customColor,
  styledValue,
  onChange = (args) => {},
  value,
  search = false,
  dropdownTitle,
  labelModifier,
  uppercaseLabel = false,
  onToggle = () => {},
  selectedTextTransform = "capitalize",
  onScrollToBottom = () => {},
  isLoading,
  setSearchQuery = () => {},
  containerRef = null,
  dropdownModifiers = "",
  areGroupedOptions = false,
  groupedItemsKey = "",
  groupedItemsHeadingClassname = "",
  colorKey = "color",
}) {
  const { messages } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  // Use for trigger render so we can collect values and rerender placeholder.
  const [__, setChangeHappend] = useState(Math.random());

  const subscribe = useRef(false);

  const ref = useRef(null);
  const dropdownRef = useRef(null);

  const toggle = (event) => {
    if (disabled) return;
    if (isOpen) {
      setIsOpen(false);
      onToggle(false);
    } else {
      setIsOpen(true);
      onToggle(true);
    }
    const position = calcDropdownPosition();
    setDropdownPosition(position);
    event.stopPropagation();
    event.preventDefault();
  };

  useEffect(() => {
    subscribe.current = true;
  }, []);

  const close = () => {
    setIsOpen(false);
    onToggle(false);
  };

  useOnClickOutside(dropdownRef, close);

  const handleClick = () => {
    setChangeHappend(Math.random());
  };

  const filteredOptions = useMemo(() => {
    return areGroupedOptions
      ? options.map((groupedOption) => {
          return {
            ...groupedOption,
            [groupedItemsKey]: groupedOption[groupedItemsKey].filter((item) =>
              item.label?.toLowerCase().includes(searchValue?.toLowerCase()),
            ),
          };
        })
      : options?.filter((item) =>
          item.label?.toLowerCase().includes(searchValue?.toLowerCase()),
        );
  }, [options, searchValue]);

  const hasGroupedSearchResults = useMemo(() => {
    if (!areGroupedOptions) return false;

    return filteredOptions.some(
      (filteredOption) => filteredOption[groupedItemsKey].length > 0,
    );
  }, [filteredOptions, searchValue, options]);

  const isPropsPlaceHolderReturned = () => {
    if (!subscribe.current && !defaultValue) {
      return true;
    } else {
      if (subscribe.current && !defaultValue?.length) {
        return true;
      }
    }
    return false;
  };
  const renderPlaceholder = () => {
    if (!subscribe.current && !defaultValue) {
      return placeholder;
    } else {
      if (subscribe.current && !defaultValue?.length) {
        return placeholder;
      }
    }

    let buttonLabel = "";

    if (areGroupedOptions) {
      for (const group of options) {
        for (const item of group[groupedItemsKey]) {
          if (defaultValue === item?.value) {
            buttonLabel = item?.label;
            break;
          }
        }
        if (buttonLabel) return buttonLabel;
      }
    }

    if (getValues(name)) {
      for (const item of options) {
        if (getValues(name)?.toLowerCase() === item?.value?.toLowerCase()) {
          buttonLabel = item?.label;
          break;
        }
      }
    } else {
      for (const item of options) {
        if (item.value === defaultValue) {
          buttonLabel = item.label;
          break;
        }
      }
    }

    return buttonLabel.length > DROPDOWN_ITEM_CHAR_LIMIT
      ? `${trimText(buttonLabel, DROPDOWN_ITEM_CHAR_LIMIT)}`
      : buttonLabel;
  };

  const defaultColor = options?.find(
    (option) => option?.value === defaultValue,
  )?.color;

  const renderPlaceholderStyle = {
    textTransform: isPropsPlaceHolderReturned()
      ? "capitalize"
      : selectedTextTransform,
  };

  const renderButton = () => {
    const singleItem = options?.find((option) => {
      if (getValues) {
        return option.value === getValues(name);
      } else {
        return option.label === renderPlaceholder();
      }
    });
    return !status ? (
      <button
        type="button select-custom-field-selected-value"
        disabled={disabled}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={
          !singleSelect && defaultColor && !areGroupedOptions
            ? {
                backgroundColor: isHovered
                  ? defaultColor
                  : getOptionBackgroundColor(defaultColor),
                color: isHovered ? COLOR.$white_v2 : `${defaultColor}`,
              }
            : {}
        }
        className={`c-dropdown-button c-dropdown-button--small  ${
          singleSelect
            ? "c-dropdown-button--select3"
            : "c-dropdown-button--small"
        } ${error && "c-input--error"} ${buttonModifier || ""}`}
        onClick={toggle}
      >
        {styledValue ? (
          <span
            className="tag-info"
            style={{
              ...renderPlaceholderStyle,
              backgroundColor: singleItem?.color,
            }}
          >
            {renderPlaceholder()}
          </span>
        ) : (
          <span style={renderPlaceholderStyle}>{renderPlaceholder()}</span>
        )}
        <div
          className="c-dropdown-buttonHandle"
          style={
            !singleSelect && defaultColor && !areGroupedOptions
              ? {
                  color: isHovered ? COLOR.$white_v2 : `${defaultColor}`,
                }
              : {}
          }
        >
          <CaretDown size={12} weight="bold" />
        </div>
      </button>
    ) : (
      <button
        type="button"
        disabled={disabled}
        style={{
          backgroundColor: isOpen
            ? singleItem?.color
            : generateStatusBackgroundColor(singleItem?.color),
          color: isOpen ? COLOR.$white_v2 : singleItem?.color,
        }}
        className={`c-dropdown-button c-dropdown-button--small  ${
          singleSelect
            ? "c-dropdown-button--select3"
            : "c-dropdown-button--small"
        } ${error && "c-input--error"} ${buttonModifier || ""}`}
        onClick={toggle}
      >
        {styledValue ? (
          <span
            className="tag-info"
            style={{ backgroundColor: singleItem?.color }}
          >
            {renderPlaceholder()}
          </span>
        ) : (
          renderPlaceholder()
        )}
        <div className="c-dropdown-buttonHandle">
          <CaretDown size={12} weight="bold" />
        </div>
      </button>
    );
  };

  const calcDropdownPosition = () => {
    const container = containerRef?.current || ref.current;
    if (container) {
      const { top, left, height } = container.getBoundingClientRect();
      return { top: top + height, left };
    }
    return { top: 0, left: 0 };
  };

  const renderOption = (item, index) => {
    let found = singleSelect
      ? item?.value === value
      : item?.value === defaultValue;
    return (
      <React.Fragment>
        <label
          onClick={handleClick}
          className={"c-dropdown-menuItem c-dropdown-menuItem--v3"}
          htmlFor={item.value}
        >
          <input
            id={item.value}
            key={item.value}
            value={item.value}
            disabled={item.disabled}
            onClick={(e) => {
              e.stopPropagation();
              onChange(item.value);
              setIsOpen(false);
            }}
            type="radio"
            defaultChecked={defaultValue === item.value}
            className="c-checkboxInput c-dropdown-menuItemInput color-pallet-select3"
            {...register}
          />
          {avatar && (
            <Avatar name={item.name || item.label} className="mr-sm" />
          )}
          {status ? (
            <Tag
              label={
                item.label.length > DROPDOWN_ITEM_CHAR_LIMIT
                  ? `${trimText(item.label, DROPDOWN_ITEM_CHAR_LIMIT)}`
                  : item.label
              }
              style={{
                color: item?.color,
                backgroundColor: generateStatusBackgroundColor(item?.color),
              }}
              className="z-10"
            />
          ) : customColor ? (
            <span
              className="color-pallet-select3 custom-field-select-option"
              style={{
                color: item?.color,
                backgroundColor: getOptionBackgroundColor(item?.color),
              }}
            >
              {item.label.length > DROPDOWN_ITEM_CHAR_LIMIT
                ? `${trimText(item.label, DROPDOWN_ITEM_CHAR_LIMIT)}`
                : item.label}
            </span>
          ) : (
            <span>
              {item.label.length > DROPDOWN_ITEM_CHAR_LIMIT
                ? `${trimText(item.label, DROPDOWN_ITEM_CHAR_LIMIT)}`
                : item.label}
            </span>
          )}
          {found && (
            <span className="c-dropdown-menuItemIcon">
              <Check size={16} />
            </span>
          )}
        </label>
        {index === options.length - ITEMS_BEFORE_PAGE && (
          <Waypoint onEnter={onScrollToBottom} />
        )}
      </React.Fragment>
    );
  };

  return (
    <div ref={ref}>
      {label && (
        <label
          className={`c-label ${uppercaseLabel ? "c-label--uppercase" : ""} ${
            labelModifier || ""
          }`}
        >
          {label}
        </label>
      )}

      <div
        className={`c-dropdown ${isOpen ? "c-dropdown--shown" : ""} ${
          error ? "c-dropdown--error" : ""
        }`}
      >
        {renderButton()}

        {isOpen &&
          ReactDOM.createPortal(
            <div
              ref={dropdownRef}
              className={`c-dropdown-menu c-dropdown-menu--v3 u-inline-flex ${dropdownModifiers}`}
              style={{ ...dropdownPosition }}
            >
              <input
                hidden
                id={`hidden-${name}`}
                key={`hidden-${name}`}
                value=""
                type="radio"
                {...register}
              />
              {dropdownTitle && (
                <div className="makula-dropdown-title u-width-100">
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    color="text-secondary"
                    className="capitalize break-all"
                  >
                    {dropdownTitle}
                  </BodyText>
                </div>
              )}
              {search && (
                <div className="makula-search-in-dropdown">
                  <input
                    type="search"
                    placeholder={messages?.tickets?.search}
                    onChange={(e) => {
                      setSearchValue(e?.target?.value);
                      setSearchQuery(e?.target?.value);
                    }}
                  />
                </div>
              )}
              <div className="dropdown-items-wrapper u-width-100 u-flex-1 u-overflow-auto u-padding-b-1">
                {areGroupedOptions &&
                  hasGroupedSearchResults &&
                  filteredOptions?.map((groupOption) => {
                    if (!groupOption[groupedItemsKey].length) return null;

                    return (
                      <React.Fragment key={groupOption._id}>
                        <div
                          style={{
                            color: groupOption[colorKey],
                            background: getOptionBackgroundColor(
                              groupOption[colorKey],
                            ),
                          }}
                          className={`${groupedItemsHeadingClassname} grouped-dropdown-item-heading`}
                        >
                          {groupOption.name}
                        </div>
                        {groupOption[groupedItemsKey].map((item, index) =>
                          renderOption(item, index),
                        )}
                      </React.Fragment>
                    );
                  })}
                {filteredOptions.length > 0 &&
                  !areGroupedOptions &&
                  filteredOptions?.map((item, index) =>
                    renderOption(item, index),
                  )}
                {((!isLoading && !filteredOptions.length) ||
                  (areGroupedOptions && !hasGroupedSearchResults)) && (
                  <div className="empty-dropdown u-text-center">
                    {messages.common.noAvailableOptions}
                  </div>
                )}
                {isLoading && (
                  <span className="select3-dropdown-loading-text u-margin-l-3 u-margin-t-3">
                    {messages?.common?.loading}
                  </span>
                )}
              </div>
            </div>,
            document.querySelector("body"),
          )}
      </div>
      <p className="form-group-validationFeedback">{error?.message}</p>
    </div>
  );
}

export function Select3Multi({
  avatar,
  options,
  label,
  error,
  defaultValues: defaultValuesProp,
  placeholder,
  disabled,
  status,
  buttonModifier,
  containerTitle,
  searchable,
  isOpenDropdown,
  setIsOpenDropdown,
  parentToggler = false,
  searchPlaceholder = "",
  isRemoveOption = false,
  removeAll,
  areGroupedOptions,
  groupedItemsKey = "",
  groupedItemsHeadingClassname = "",
  colorKey = "color",
  onClick,
  renderSelectedOptions,
  getObjectOnSelect = false,
  itemClasses = () => "",
  dropdownClassName = "",
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const defaultValues =
    defaultValuesProp?.map((item) => item.value ?? item._id) || [];

  const subscribe = useRef(false);

  const ref = useRef(null);

  const selectedItems = useMemo(() => {
    const defaultValuesMod = [...defaultValues]; // ?.map(item => item._id);
    return areGroupedOptions
      ? options.flatMap((groupedOption) => {
          const selected = groupedOption[groupedItemsKey]?.filter((option) =>
            defaultValuesMod.includes(option.value),
          );
          return selected;
        })
      : options.filter((option) => defaultValuesMod.includes(option.value));
  }, [defaultValues, options, searchTerm]);

  const leftoverItems = useMemo(() => {
    return areGroupedOptions
      ? options.map((groupedOption) => {
          return {
            ...groupedOption,
            [groupedItemsKey]: groupedOption[groupedItemsKey].filter((item) =>
              item.label.toLowerCase().includes(searchTerm?.toLowerCase()),
            ),
          };
        })
      : options
          .sort((a, b) => a?.label.localeCompare(b?.label))
          .filter((option) =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase()),
          );
  }, [options, searchTerm]);

  const hasGroupedSearchResults = useMemo(() => {
    if (!areGroupedOptions) return false;

    return leftoverItems.some(
      (leftoverItem) => leftoverItem[groupedItemsKey].length > 0,
    );
  }, [leftoverItems, searchTerm, options]);

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    subscribe.current = true;
  }, []);

  const close = () => {
    if (isOpenDropdown) {
      setIsOpenDropdown(false);
    }
    setIsOpen(false);
  };

  useOnClickOutside(ref, close);

  const onSearchTermChange = (value) => {
    setSearchTerm(value);
  };

  const renderPlaceholder = () => {
    if (!defaultValues?.length) {
      return placeholder;
    }
    var items = [];
    let selectedValues = [];
    options.map((item) => {
      if (areGroupedOptions) {
        options.forEach((groupedOption) => {
          const selected = groupedOption[groupedItemsKey]?.filter((option) =>
            defaultValues.includes(option.value),
          );
          items.push(
            ...selected
              .filter((item) => !selectedValues.includes(item.value))
              .map((item) => item.label),
          );
          selectedValues.push(...selected.map((item) => item.value));
        });
      } else if (defaultValues.includes(item.value)) {
        items.push(item.label);
      }
    });

    if (renderSelectedOptions) return renderSelectedOptions(items);

    return trimText(items.join(", "), 20);
  };

  const renderButton = () => {
    return (
      <button
        type="button"
        disabled={disabled}
        className={`c-dropdown-button c-dropdown-button--small ${
          error && "c-input--error"
        } ${buttonModifier || ""}`}
        onClick={toggle}
      >
        {renderPlaceholder()}
        <div className="c-dropdown-buttonHandle">
          <CaretDown size={12} />
        </div>
      </button>
    );
  };
  const renderCheckbox = (item) => {
    const defaultChecked = defaultValues?.find((value) => value === item.value);
    return (
      <input
        id={item.value}
        key={item.value}
        value={item.value}
        disabled={item.disabled}
        type="checkbox"
        defaultChecked={defaultChecked}
        className="c-checkboxInput c-dropdown-menuItemInput"
        onClick={() => onClick(getObjectOnSelect ? item : item.value)}
      />
    );
  };

  const renderOptionItems = (item, isSelectedItem = false) => {
    return (
      <label
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`c-dropdown-menuItem c-dropdown-menuItem--v3 ${itemClasses(
          item,
        )}`}
        key={item.value}
      >
        {renderCheckbox(item)}
        {avatar && <Avatar name={item.name || item.label} className="mr-sm" />}
        <div style={{ flexGrow: 1 }}>
          {status ? (
            <span className={`status-bar ${item?.value}`}>{item.label}</span>
          ) : (
            item?.label
          )}
        </div>

        {isSelectedItem && (
          <div className="c-dropdown-menuItemIcon-container">
            <span
              className="c-dropdown-menuItemIcon"
              style={{ marginRight: "10px" }}
            >
              <Check size={16} />
            </span>
          </div>
        )}
      </label>
    );
  };

  const renderLeftoverItems = () => {
    if (areGroupedOptions && hasGroupedSearchResults)
      return leftoverItems?.map((groupOption) => {
        if (!groupOption[groupedItemsKey].length) return null;

        return (
          <React.Fragment key={groupOption._id}>
            <div
              style={{
                color: groupOption[colorKey],
                background: getOptionBackgroundColor(groupOption[colorKey]),
              }}
              className={`${groupedItemsHeadingClassname} grouped-dropdown-item-heading`}
            >
              {groupOption.name}
            </div>
            {groupOption[groupedItemsKey].map((item, index) =>
              renderOptionItems(item, defaultValues.includes(item?.value)),
            )}
          </React.Fragment>
        );
      });

    if (!areGroupedOptions)
      return leftoverItems.map((item, index) => {
        return renderOptionItems(item, defaultValues.includes(item?.value));
      });

    return null;
  };

  if (!options.length) return null;

  return (
    <div ref={ref}>
      {!parentToggler && (
        <label className="c-label c-label--uppercase">{label}</label>
      )}

      <div
        className={`c-dropdown ${
          isOpenDropdown || isOpen ? "c-dropdown--shown" : ""
        } ${error && "c-dropdown--error"}`}
      >
        {!parentToggler && renderButton()}
        <div
          className={`c-dropdown-menu c-dropdown-menu--v3 ${dropdownClassName}`}
        >
          {containerTitle && (
            <div className="c-dropdown-menu--pad u-flex">
              {containerTitle && (
                <h2 className="c-dropdown-menu-title">{containerTitle}</h2>
              )}
            </div>
          )}
          {searchable && (
            <div className="c-dropdown-menu--pad">
              <SharedInput
                type="text"
                placeholder={searchPlaceholder}
                value={searchTerm}
                onChange={(e) => onSearchTermChange(e.target.value)}
                name="ticket-status-filter"
                id="ticket-status-filter"
              />
            </div>
          )}
          <div className="dropdown-items-wrapper u-width-100 u-flex-1 u-overflow-auto u-padding-b-1">
            {renderLeftoverItems()}
          </div>
          {isRemoveOption && (
            <DropdownFooter
              deleteAll={removeAll}
              disabled={!selectedItems?.length}
            />
          )}
        </div>
      </div>
      <p className="form-group-validationFeedback">{error?.message}</p>
    </div>
  );
}

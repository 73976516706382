import React from "react";

import {
  LOADER_ELEMENT_ID,
  LOADER_ELEMENT_MEDIA,
  LOADER_ELEMENT_NAME,
  LOADER_ELEMENT_REL,
  SYNC_FUSION_CSS_LINK,
} from "~/hooks/SyncFusionCSSLoader/constants";

const useSyncFusionCSSLoader = ({
  skipCondition = false,
}: {
  skipCondition?: boolean;
} = {}) => {
  const [isLoaded, setIsLoaded] = React.useState(skipCondition);
  React.useEffect(() => {
    if (!skipCondition) {
      const headElement = document.getElementsByTagName("head")[0];
      if (document.getElementById(LOADER_ELEMENT_ID)) {
        setIsLoaded(true);
        return;
      }
      const loaderElement = document.createElement(LOADER_ELEMENT_NAME);
      loaderElement.href = SYNC_FUSION_CSS_LINK;
      loaderElement.rel = LOADER_ELEMENT_REL;
      loaderElement.media = LOADER_ELEMENT_MEDIA;
      loaderElement.id = LOADER_ELEMENT_ID;
      loaderElement.onload = () => {
        setIsLoaded(true);
      };
      headElement.appendChild(loaderElement);
    }
  }, [skipCondition]);
  return { loaded: isLoaded };
};

export default useSyncFusionCSSLoader;

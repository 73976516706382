import { GET_OWN_OEM_TICKET_BY_ID, LIST_OWN_OEM_TICKETS_QUERY } from "../api";
import client from "../apollo/_client";
import { useGetTicketByIdForList } from "../services";
import { generateListOwnOemTicketsVariables } from "../utils/filter/_openTicketQueryCond";

const useReorderWorkOrderList = () => {
  const { getTicketById } = useGetTicketByIdForList();

  const reOrderWithinList = ({ allTickets, variables, position, status }) => {
    client.cache.updateQuery(
      {
        query: LIST_OWN_OEM_TICKETS_QUERY,
        variables,
      },
      ({ listOwnOemTickets, ...rest }) => {
        const tickets = [...(listOwnOemTickets?.tickets || allTickets)];
        const ticket = {
          ...tickets[position],
          status: status || tickets[position].status,
        };
        tickets.splice(position, 1);

        return {
          ...rest,
          listOwnOemTickets: {
            ...listOwnOemTickets,
            tickets: [ticket, ...tickets],
          },
        };
      },
    );
  };

  const removeFromList = ({ allTickets, variables, position }) => {
    client.cache.updateQuery(
      {
        query: LIST_OWN_OEM_TICKETS_QUERY,
        variables,
      },
      ({ listOwnOemTickets, ...rest }) => {
        const tickets = [...(listOwnOemTickets?.tickets || allTickets)];
        tickets.splice(position, 1);
        return {
          ...rest,
          listOwnOemTickets: {
            ...listOwnOemTickets,
            tickets,
          },
        };
      },
    );
  };

  const appendTicketOnTop = ({
    id,
    variables,
    allTickets,
    isNewTicket = false,
  }) => {
    getTicketById(id).then(({ data: { getOwnOemTicketById: ticket } = {} }) => {
      if (!ticket) return;
      client.cache.updateQuery(
        {
          query: LIST_OWN_OEM_TICKETS_QUERY,
          variables,
        },
        (data) => {
          if (!data) return;

          const { listOwnOemTickets, ...rest } = data;
          const tickets = [...(listOwnOemTickets?.tickets || allTickets)];
          let newTicketList = tickets;
          if (tickets[0]._id !== id) {
            newTicketList = [ticket, ...newTicketList];
          }

          return {
            ...rest,
            listOwnOemTickets: {
              ...listOwnOemTickets,
              ...(isNewTicket && {
                totalCount: listOwnOemTickets.totalCount + 1,
              }),
              tickets: newTicketList,
            },
          };
        },
      );
    });
  };

  const handleReorderTicket = ({
    allTickets,
    id,
    activeFilters,
    searchQuery,
    where,
    isNewTicket,
    isTicketDeleted,
    status,
  }) => {
    const index = allTickets.findIndex((ticket) => ticket._id === id);

    const variables = generateListOwnOemTicketsVariables({
      activeFilters,
      where,
      searchQuery,
    });

    // work order is already on top
    if (index === 0 && !isTicketDeleted) {
      // work order is on top but status is updated
      if (status) {
        reOrderWithinList({ position: index, allTickets, variables, status });
      }
      return;
    }

    if (isTicketDeleted && index >= 0) {
      removeFromList({
        position: index,
        allTickets,
        variables,
      });
      return;
    }

    // work order exists somewhere in the list
    if (index > 0) {
      reOrderWithinList({ position: index, allTickets, variables, status });

      return;
    }

    // work order does not exist in the list
    if (!isTicketDeleted && !where?.myWorkOrders)
      appendTicketOnTop({
        id,
        variables,
        allTickets,
        isNewTicket,
      });
  };

  const handleUpdateTicketStatus = ({ status, id }) => {
    const { getOwnOemTicketById } = client.cache.readQuery({
      query: GET_OWN_OEM_TICKET_BY_ID,
      variables: {
        id,
      },
    });
    client.cache.writeQuery({
      query: GET_OWN_OEM_TICKET_BY_ID,
      variables: {
        id: id,
      },
      data: {
        getOwnOemTicketById: {
          ...getOwnOemTicketById,
          status,
        },
      },
    });
  };
  return { handleReorderTicket, handleUpdateTicketStatus };
};

export default useReorderWorkOrderList;

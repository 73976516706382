import * as React from "react";
const SvgTextLeft = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M2.664 4.333h10.667M2.664 8.333h6.667M2.664 12.333h9.333",
  stroke: "currentColor",
  strokeWidth: 1.252,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgTextLeft;

import React from "react";

const ProgressBar = ({
  bgcolor,
  completed,
  containerBGColor = undefined,
  showLabel = undefined,
  progressBarFillerClassName = "",
}) => {
  return (
    <div
      className="progress-bar progress-bar-container"
      style={{ backgroundColor: containerBGColor }}
    >
      <div
        className={"progress-bar-filler " + progressBarFillerClassName}
        style={{
          width: `${completed}%`,
          backgroundColor: bgcolor,
        }}
      >
        {showLabel && (
          <span className="progress-bar-label">{`${completed}%`}</span>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;

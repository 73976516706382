import React from "react";

type ToggleSwitchPropsType = {
  isOn: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  name: string;
  disabled?: boolean;
};

const ToggleSwitch = ({
  isOn,
  onChange,
  name,
  disabled = false,
}: ToggleSwitchPropsType) => (
  <label className="relative inline-block w-3xl h-xl">
    <input
      type="checkbox"
      onChange={onChange}
      checked={isOn}
      name={name}
      disabled={disabled}
      className="opacity-0 w-0 h-0"
    />
    <span
      className={`absolute top-0 left-0 right-0 bottom-0 transition-all duration-400 rounded-full ${
        isOn ? "bg-success" : "bg-tertiary"
      } ${disabled ? "cursor-default" : "cursor-pointer"}`}
    />
    <span
      className={`absolute bottom-3xs left-3xs w-lg h-lg bg-primary transition-transform duration-400 rounded-full ${
        isOn ? "transform translate-x-md" : ""
      } ${disabled ? "cursor-default" : "cursor-pointer"}`}
    />
  </label>
);

export default ToggleSwitch;

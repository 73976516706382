import mixpanel from "mixpanel-browser";

import { isTrackingDisabled } from ".";

export const isMixpanelEnabled = () => {
  return (
    import.meta.env.VITE_APP_MIXPANEL_TOKEN &&
    import.meta.env.MODE !== "development" &&
    (import.meta.env.MODE === "production" || !isTrackingDisabled())
  );
};

/**
 * Tracks an event in both Mixpanel and Intercom.
 *
 * Ensures the event is logged in Mixpanel first. If Intercom is available in the window,
 * it also logs the same event in Intercom.
 *
 * @param {string} event - The name of the event to track.
 * @param {Record<string, any>} [properties={}] - Optional event properties.
 * @returns {Promise<void>} - Returns a promise that resolves after tracking.
 */
export const registerMixpanelEvent = async (event, properties = {}) => {
  if (isMixpanelEnabled()) {
    mixpanel.track(event, { ...properties });

    if (window.Intercom) {
      window.Intercom("trackEvent", event, properties);
    }
  }
};

import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Dropdown, { POSITIONS } from "@shared/ui/DropDown";
import React, { useMemo, useRef } from "react";
import { useIntl } from "react-intl";

import EmptySpace from "~/components/_emptySpace";
import {
  SidebarFacilityIcon,
  SidebarMachinesIcon,
  SidebarOpenTicketsFolder,
} from "~/icons/icons";

const UnselectedFilters = ({
  unselectedFilters,
  show,
  onClose,
  selectFilter,
  dropDownPosition = POSITIONS.LEFT,
}) => {
  const ref = useRef();
  const { messages } = useIntl();

  const getTitle = React.useCallback((item) => {
    if (item && Object.hasOwnProperty.call(item, "id")) {
      return messages?.filters?.[item.id]?.title;
    } else if (item.isAdditionalField) {
      return messages?.filters?.[item?.slug]?.title;
    }
    return item.label;
  }, []);

  const sortedUnSelectedFilters = useMemo(() => {
    return unselectedFilters.sort((a, b) =>
      getTitle(a).toLowerCase() < getTitle(b).toLowerCase() ? -1 : 1,
    );
  }, [unselectedFilters]);

  return (
    <Dropdown
      show={show}
      onClose={onClose}
      dropdownRef={ref}
      position={dropDownPosition}
    >
      <div className="unselected-filters">
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          color="text-secondary"
          className="capitalize"
        >
          {messages?.filters?.addFilter}:
        </BodyText>
        <EmptySpace height="12px" />
        {sortedUnSelectedFilters.map((item, index) => (
          <span
            key={index}
            onClick={() => selectFilter(item)}
            className="unselected-filters-item"
          >
            {item.icon ? (
              <item.icon />
            ) : item.type === "machines" ? (
              <SidebarMachinesIcon />
            ) : item.type === "facilities" ? (
              <SidebarFacilityIcon />
            ) : (
              <SidebarOpenTicketsFolder />
            )}
            {getTitle(item)}
          </span>
        ))}
      </div>
    </Dropdown>
  );
};

export default UnselectedFilters;

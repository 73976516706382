import { Avatar } from "@shared/ui/Avatar";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { ListItemBoxed } from "~/components/_listItem";
import { formatDate } from "~/utils";

const EventName = ({
  eventName,
  machineName,
  machineFacilityName,
  machineFacilityId,
  machineId,
}: {
  eventName: string;
  machineName: string;
  machineFacilityName: string;
  machineId: string;
  machineFacilityId: string;
}) => {
  const history = useHistory();

  return (
    <div className="u-flex u-flex-column machine-list-item-title--container u-overflow-hidden u-text-overflow-ellipsis u-whitespace-nowrap u-padding-r-1 basis-2/4">
      <Headline
        size={HEADLINE_SIZES.X_SMALL}
        className="overflow-hidden text-ellipsis whitespace-nowrap capitalize"
        title={eventName}
      >
        {eventName}
      </Headline>
      <div className="flex items-center">
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          color="text-brand"
          className={"truncate mt-xs max-w-48 hover:cursor-pointer"}
          title={machineFacilityName}
          onClick={() => history.push(`/app/facilities/${machineFacilityId}`)}
        >
          {machineFacilityName}
        </BodyText>
        <span className="leading-none h-3">&nbsp; • &nbsp;</span>
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          color="text-brand"
          className={"truncate mt-xs hover:cursor-pointer "}
          title={machineName}
          onClick={() => history.push(`/app/assets/all/${machineId}`)}
        >
          {machineName}
        </BodyText>
      </div>
    </div>
  );
};

const EvenDetail = ({
  eventId,
  label,
  isDate = true,
}: {
  eventId: string;
  label: string;
  isDate: boolean;
}) => (
  <div
    className={
      "flex overflow-hidden text-overflow-ellipsis whitespace-nowrap mr-14"
    }
  >
    <Label
      size={LABEL_SIZES.SMALL}
      color="text-secondary"
      className="font-medium"
    >
      {label}
      <>: &nbsp; </>
    </Label>
    <BodyText
      size={BODY_TEXT_SIZES.X_SMALL}
      className="truncate"
      title={eventId}
    >
      {isDate ? <span className="date">{formatDate(eventId)}</span> : eventId}
    </BodyText>
  </div>
);

const EventCreator = ({ creator }: { creator: { name: string } }) => {
  const { messages } = useIntl();
  return (
    <div className="w-44 md:w-56 lg:w-72 ml-14">
      <Avatar
        name={creator?.name || messages?.common?.noAssignee}
        showName
        hasNoUser={!creator?.name}
      />
    </div>
  );
};

const EventTile = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) => <ListItemBoxed className={className}>{children}</ListItemBoxed>;

export default Object.assign(EventTile, {
  EvenDetail,
  EventCreator,
  EventName,
});

import { Check, X } from "phosphor-react";
import React, { useRef } from "react";
import { ErrorMessage, InputLabel } from "@shared/ui/Inputs";
import useOnClickOutside from "use-onclickoutside";

type TextareaPropsType = {
  label: string;
  keyId: string;
  uppercaseLabel: boolean;
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  errorMessage: string;
  showActionButtons: boolean;
  onSaveAction: () => void;
  onCancelAction: () => void;
};

const Textarea = ({
  label = "",
  keyId = "",
  uppercaseLabel = false,
  value = "",
  onChange = () => {},
  errorMessage = "",
  showActionButtons = false,
  onSaveAction = () => {},
  onCancelAction = () => {},
  className = "",
  ...props
}: React.ComponentPropsWithoutRef<"textarea"> & Partial<TextareaPropsType>) => {
  const ref = useRef<HTMLDivElement>();

  const classes: string = [
    "w-full h-28 resize-none p-md rounded-lg font-manrope font-medium text-xs text-primary border border-solid transition-all duration-200 ease-in-out",
    "placeholder:text-secondary placeholder:opacity-100 disabled:placeholder:text-disabled disabled:placeholder:opacity-100",
    "focus:bg-primary focus:border-brand focus:outline-0",
    "disabled:text-disabled disabled:bg-accent disabled:border-transparent disabled:opacity-100 read-only:text-disabled read-only:bg-accent read-only:border-transparent read-only:opacity-100",
    errorMessage
      ? "bg-primary border-danger focus:bg-primary focus:border-danger"
      : value
      ? "bg-primary border-primary"
      : "bg-accent border-transparent",
    className,
  ].join(" ");

  useOnClickOutside(ref, onSaveAction);

  return (
    <div className="relative flex flex-col gap-sm" ref={ref}>
      {label && (
        <div className="w-fit">
          <InputLabel
            label={label}
            keyId={keyId}
            uppercaseLabel={uppercaseLabel}
          />
        </div>
      )}

      <textarea
        id={keyId}
        value={value}
        className={classes}
        onChange={onChange}
        {...props}
      />

      {errorMessage && <ErrorMessage message={errorMessage} />}

      {showActionButtons && (
        <div className="flex items-center justify-end">
          <span
            className="flex items-center justify-center w-3xl h-3xl text-inverse bg-brand cursor-pointer rounded-full"
            onClick={(e) => {
              e.stopPropagation();
              onSaveAction();
            }}
          >
            <Check size={16} />
          </span>
          <span
            className="flex items-center justify-center w-3xl h-3xl text-inverse bg-brand cursor-pointer rounded-full ml-md"
            onClick={(e) => {
              e.stopPropagation();
              onCancelAction();
            }}
          >
            <X size={16} />
          </span>
        </div>
      )}
    </div>
  );
};

export default Textarea;

import { atom } from "recoil";

export const queryAtoms = {};
export const queryTracker = {
  add: (array = []) => {
    array.forEach(function (element) {
      const { key, variables = {}, options = {} } = element || {};
      const itemKey = key + JSON.stringify(variables);
      const itemKeyExists = queryAtoms?.[key]?.find(
        (atom) => atom.key === itemKey,
      );
      if (key && !itemKeyExists) {
        queryAtoms[key] = [
          ...new Set([
            ...(queryAtoms[key] || []),
            atom({
              key: itemKey,
              default: variables,
              ...options, // may ovveride default
            }),
          ]),
        ];
      }
    });
  },
};

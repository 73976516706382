import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import AssignItemModal from "~/components/_assignItemModal";
import { MachineLoaderComponent } from "~/components/skeletonLoaders/_machineLoders";
import { ADD_MACHINE_SUB_ASSETS } from "~/constants";
import { useAssignAssetsToParent } from "~/services/asset/mutation";
import { getMachineThumbnail } from "~/utils";
import { registerMixpanelEvent } from "~/utils/_mixpanel";

const AssignAssetsToParent = ({
  isOpen,
  handleClose,
  allAssets,
  allAssetsLoading,
  handleScrollBottomAllAssets,
  searchQuery,
  setSearchQuery,
}) => {
  const { messages } = useIntl();
  const params = useParams() as { id: string };
  const { mutation: assignAssets } = useAssignAssetsToParent();
  const [selectedAssets, setSelectedAssets] = useState([]);

  const handleCloseInternal = () => {
    setSelectedAssets([]);
    handleClose();
  };

  const handleSubmit = async () => {
    registerMixpanelEvent(ADD_MACHINE_SUB_ASSETS);
    try {
      await assignAssets({
        assetIds: selectedAssets,
        parentId: params.id,
      });
      toast(
        <Toast
          message={
            selectedAssets.length +
            " " +
            messages["hierarchy"]["assets"].assign.successMessage
          }
        />,
        {
          closeButton: false,
        },
      );
      handleCloseInternal();
    } catch (error) {
      toast(
        <Toast
          type={TOAST_TYPES.ERROR}
          message={
            error?.message ||
            messages["hierarchy"]["assets"].assign.failureMessage
          }
        />,
        {
          closeButton: false,
        },
      );
    }
  };

  const getFilteredAssets = () => allAssets;

  const LoaderComponent = () => (
    // @ts-ignore
    <MachineLoaderComponent total={1} />
  );

  useEffect(() => {
    isOpen && setSearchQuery("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <AssignItemModal
      handleSubmit={handleSubmit}
      isOpen={isOpen}
      handleClose={handleCloseInternal}
      disableUpdate={!selectedAssets.length}
      updateBtnText={messages["hierarchy"]["assets"].assign.text}
      headerText={messages["hierarchy"]["assets"].assign.text}
      selectedItems={selectedAssets}
      setSelectedItems={setSelectedAssets}
      getFilteredItems={getFilteredAssets}
      getThumbnail={getMachineThumbnail}
      getTitle={(asset) => asset?.name ?? ""}
      getId={(asset) => asset?.serialNumber ?? ""}
      nameHeading={messages["hierarchy"]["assets"].assign.assetName}
      idHeading={messages["hierarchy"]["assets"].assign.assetSerialNumber}
      onScrollToBottom={handleScrollBottomAllAssets}
      itemsLoading={allAssetsLoading}
      loaderComponent={LoaderComponent}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
};

export default AssignAssetsToParent;

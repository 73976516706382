import { TABLE_VIEW_COLUMN_SIZE_SUFFIX } from "@shared/constants/index";
import { ArrowsHorizontal } from "phosphor-react";
import { useEffect } from "react";

type DataTableColumnResizerPropsType = {
  header: any;
  scope: string;
};

const DataTableColumnResizer = ({
  header,
  scope,
}: DataTableColumnResizerPropsType) => {
  useEffect(() => {
    if (!header) return;
    const localStorageKey = `${scope}${TABLE_VIEW_COLUMN_SIZE_SUFFIX}`;
    const columnSizes = localStorage.getItem(localStorageKey);
    let columnSizeConfig = {};
    if (columnSizes) {
      const existingColumnSizes = JSON.parse(columnSizes);
      columnSizeConfig = { ...existingColumnSizes };
      columnSizeConfig[header.column.id] = header.getSize();
    } else {
      columnSizeConfig[header.column.id] = header.getSize();
    }
    localStorage.setItem(localStorageKey, JSON.stringify(columnSizeConfig));
  }, [header?.column?.getIsResizing()]);

  return (
    <>
      {header.column.getCanResize() ? (
        <div
          {...{
            onMouseDown: header.getResizeHandler(),
            onTouchStart: header.getResizeHandler(),
            className: `absolute top-0 -right-[10px] z-[1] hidden group-hover:block w-px h-[1000px] touch-none cursor-col-resize select-none bg-brand-medium ${
              header.column.getIsResizing() ? "bg-brand-dark opacity-100" : ""
            }`,
          }}
        >
          <div
            className={`absolute top-lg left-1/2 flex items-center justify-center w-xl h-xl cursor-pointer rounded-lg -translate-x-1/2 -translate-y-1/2 border border-solid ${
              header.column.getIsResizing()
                ? "border-brand-light"
                : "border-brand"
            } bg-brand-lightest`}
          >
            <ArrowsHorizontal size={10} className="text-brand" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DataTableColumnResizer;

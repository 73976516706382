// libs
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { ExternalIcon, EXTERNAL_ICON_SIZES } from "@shared/ui/Indicators";
import React, { useMemo, useState } from "react";

// assets
import { useIntl } from "react-intl";

import DropdownFooter from "./_dropdownFooter";
import DropdownHeader from "./_dropdownHeader";
import { NoUserAssignedIcon, TickIcon } from "../../icons/icons";

// components
import Dropdown from "../_dropdown";
import { NotFoundComponent } from "../_notFoundMessage";
import WorkOrderTypeIcons from "../tickets/WorkOrderTypeIcons";

import { COLOR } from "#/src/constants";
import { useGetWorkOrderTypes } from "#/src/services";
import { generateStatusBackgroundColor } from "#/src/utils/_colors";

const TicketType = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  activeFilters,
  selectValues,
  handleSelectDeleteAll,
}) => {
  const { messages: msg } = useIntl();

  // states
  const [searchTerm, setSearchTerm] = React.useState("");
  const ticketTypesList = useGetWorkOrderTypes({ includeSystem: true });

  const filteredTypes = useMemo(
    () =>
      (ticketTypesList || []).filter((i) =>
        i.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [ticketTypesList, searchTerm],
  );

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
    >
      <DropdownHeader
        title={title}
        searchTerm={searchTerm}
        onSearchTermChange={setSearchTerm}
        unselectFilter={unselectFilter}
      />
      <div className="filter-facility-name filter-items">
        {!filteredTypes?.length ? (
          <NotFoundComponent
            icon={<NoUserAssignedIcon />}
            title={msg?.common?.filters.noTicketType}
          />
        ) : (
          filteredTypes.map((ticketType, index) => (
            <TicketTypeItem
              key={ticketType._id}
              selectValues={selectValues}
              ticketType={ticketType}
              activeFilters={activeFilters}
              filterItem={filterItem}
            />
          ))
        )}
      </div>
      <DropdownFooter
        deleteAll={() => handleSelectDeleteAll(filterItem, false)}
        disabled={!activeFilters[filterItem.id]?.length}
      />
    </Dropdown>
  );
};

export default TicketType;

export const TicketTypeItem = ({
  selectValues,
  ticketType,
  activeFilters,
  filterItem,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const styles = useMemo(() => {
    return {
      backgroundColor: isHovered
        ? ticketType.color
        : generateStatusBackgroundColor(ticketType?.color),
    };
  }, [isHovered, ticketType.color]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="work-order-type-card filter"
      onClick={() => selectValues(filterItem, ticketType._id)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="icon-label-container">
        <div className="icon-container" style={styles}>
          <WorkOrderTypeIcons
            icon={ticketType.icon}
            strokeColor={isHovered ? COLOR.$white_v2 : ticketType.color}
          />

          {ticketType.isInternal ? null : (
            <ExternalIcon
              size={EXTERNAL_ICON_SIZES.SMALL}
              className="absolute -top-[1px] -right-3xs"
            />
          )}
        </div>

        <BodyText size={BODY_TEXT_SIZES.X_SMALL}>{ticketType.name}</BodyText>
      </div>
      {activeFilters[filterItem.id].includes(ticketType._id) && (
        <span className="active">
          <TickIcon />
        </span>
      )}
    </div>
  );
};

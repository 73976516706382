import { useMutation } from "@apollo/client";

import {
  AssetTemplate,
  ASSIGN_OWN_OEM_INVENTORY_PARTS_TO_ASSET_TEMPLATE_MUTATION,
  CREATE_OWN_OEM_ASSET_TEMPLATE_MUTATION,
  DELETE_OWN_OEM_ASSET_TEMPLATE_MUTATION,
  GET_OWN_OEM_ASSET_TEMPLATE_BY_ID,
  REMOVE_OWN_OEM_INVENTORY_PART_FROM_ASSET_TEMPLATE_MUTATION,
  UPDATE_OWN_OEM_ASSET_TEMPLATE_MUTATION,
  GET_OWN_OEM_ASSET_TEMPLATE_BY_ID,
} from "~/api";
import client from "~/apollo/_client";
import { errorsHandler } from "~/transformers";

export const updateAssetTemplateCache = (payload) => {
  const assetTemplate = client.readQuery({
    query: GET_OWN_OEM_ASSET_TEMPLATE_BY_ID,
    variables: { templateId: payload._id },
  });

  if (!assetTemplate) return;

  const { getOwnOemAssetTemplate: cachedAssetTemplate } = assetTemplate;

  if (cachedAssetTemplate) {
    const cloned = { ...cachedAssetTemplate };
    cloned.documentFolders = {
      ...(cloned.documentFolders ? cloned.documentFolders : {}),
      ...payload.documentFolders,
    };

    client.writeQuery({
      query: GET_OWN_OEM_ASSET_TEMPLATE_BY_ID,
      variables: { templateId: payload._id },
      data: {
        getOwnOemAssetTemplate: cloned,
      },
    });
  }
};

export const useAssignOwnOemInventoryPartsToAssetTemplate = () => {
  const [add, { loading, error }] = useMutation(
    ASSIGN_OWN_OEM_INVENTORY_PARTS_TO_ASSET_TEMPLATE_MUTATION,
  );

  return {
    addNewParts: async (input) =>
      add({
        variables: { input },
        // @ts-ignore
        skip: !input?.templateId,
        update(
          cache,
          { data: { assignOwnOemInventoryPartsToAssetTemplate: ref } },
        ) {
          cache.modify({
            id: cache.identify({ __typename: "AssetTemplate", id: ref._id }),
            fields: {
              getOwnOemAssetTemplate(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: AssetTemplate.fragments.AssetTemplateFullData,
                  fragmentName: "AssetTemplateFullData",
                });
                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
    loading,
    error,
  };
};

export const useCreateOwnOemAssetTemplate = () => {
  const [create, { loading, error }] = useMutation(
    CREATE_OWN_OEM_ASSET_TEMPLATE_MUTATION,
  );

  return {
    loading,
    error,
    createAssetTemplate: async (input) =>
      new Promise((resolve, reject) => {
        return create({
          variables: { input },
          update(cache, { data: { createOwnOemAssetTemplate: ref } }) {
            cache.modify({
              fields: {
                listOwnOemAssetTemplates(existingRef = []) {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: AssetTemplate.fragments.AssetTemplateData,
                    fragmentName: "AssetTemplateData",
                  });

                  return [...existingRef, newRef];
                },
              },
            });
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

export const useDeleteAssetTemplate = () => {
  const [remove, { loading, error }] = useMutation(
    DELETE_OWN_OEM_ASSET_TEMPLATE_MUTATION,
  );

  return {
    loading,
    error,
    deleteTemplate: async (templateId) =>
      remove({
        variables: { templateId },
        update(cache) {
          cache.modify({
            fields: {
              listOwnOemAssetTemplates(existingRefs = [], { readField }) {
                return [...existingRefs].filter((ref) => {
                  return readField("_id", ref) !== templateId;
                });
              },
            },
          });
        },
      }),
  };
};

export const useRemoveOwnOemInventoryPartFromAssetTemplate = () => {
  const [remove, { loading, error }] = useMutation(
    REMOVE_OWN_OEM_INVENTORY_PART_FROM_ASSET_TEMPLATE_MUTATION,
  );

  return {
    removePart: async (input) =>
      remove({
        variables: { input },
        // @ts-ignore
        skip: !input?.templateId || !input?.partId,
        update(
          cache,
          { data: { removeOwnOemInventoryPartFromAssetTemplate: ref } },
        ) {
          cache.modify({
            id: cache.identify({ __typename: "AssetTemplate", id: ref._id }),
            fields: {
              getOwnOemAssetTemplate(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: AssetTemplate.fragments.AssetTemplateFullData,
                  fragmentName: "AssetTemplateFullData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
    loading,
    error,
  };
};

export const useUpdateAssetTemplate = () => {
  const [updateAssetTemplate, { loading, error }] = useMutation(
    UPDATE_OWN_OEM_ASSET_TEMPLATE_MUTATION,
  );

  return {
    loading,
    error,
    updateTemplate: async (input) =>
      new Promise((resolve, reject) => {
        return updateAssetTemplate({
          variables: { input },
          update(cache, { data: { updateOwnOemAssetTemplate: ref } }) {
            cache.modify({
              id: cache.identify({
                __typename: "AssetTemplate",
                ref: ref._id,
              }),
              fields: {
                getOwnOemAssetTemplate() {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: AssetTemplate.fragments.AssetTemplateData,
                    fragmentName: "AssetTemplateData",
                  });

                  return newRef;
                },
              },
            });
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

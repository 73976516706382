// libs
import { Input } from "@shared/ui/Inputs";
import { lowerCase, upperFirst } from "lodash";
import { Trash } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";

// components
import Dropdown from "../_dropdown";

import { FILTER_VARIANTS } from "~/components/filters";

const TextFilter = ({
  show,
  onClose,
  title,
  dropdownRef,
  unselectFilter,
  filterItem,
  activeFilters,
  selectValues,
  filterVariant = FILTER_VARIANTS.EXACTLY_MATCHES,
}) => {
  const { messages: msg } = useIntl();
  const timer = React.useRef(null);

  const handleChange = (e) => {
    const value = e.target.value;
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      selectValues(filterItem, value, true);
    }, 200);
  };

  return (
    <Dropdown
      className="w-80"
      show={show}
      onClose={onClose}
      dropdownRef={dropdownRef}
    >
      <div className="filter-dropdown-header">
        <div className="head">
          <span className="title">
            {upperFirst(lowerCase(title))}{" "}
            {filterVariant === FILTER_VARIANTS.EXACTLY_MATCHES
              ? msg?.common?.filters.matches
              : msg?.common?.filters.contains}
            :
          </span>
          <span className="delete-icon" onClick={unselectFilter}>
            <Trash size={20} className="u-text-color-gray-v2-60" />
          </span>
        </div>

        <Input
          type="text"
          placeholder={upperFirst(lowerCase(msg?.common?.filters.enterKeyword))}
          onChange={handleChange}
          value={activeFilters[filterItem._id]}
        />
      </div>
    </Dropdown>
  );
};

export default TextFilter;

import * as React from "react";
const SvgTabsListIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 16 16",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  d: "M6 4.294h7.333m0 3.705H6m0 3.708h7.333M3 3.96a.333.333 0 1 0 0 .668.333.333 0 0 0 0-.667m0 3.705a.333.333 0 1 0 0 .667.333.333 0 0 0 0-.667m0 3.707a.333.333 0 1 0 0 .667.333.333 0 0 0 0-.667"
}));
export default SvgTabsListIcon;

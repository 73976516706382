import * as React from "react";
const SvgNoSkill = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 207,
  height: 153,
  viewBox: "0 0 207 153",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "m114.657 88.813-.118.126 13.448 12.578.119-.126-13.449-12.578Z",
  fill: "#0C266D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M169.034 32.086H11.966a4.147 4.147 0 0 0-4.153 4.14v91.979c0 2.286 1.86 4.14 4.153 4.14h157.068a4.147 4.147 0 0 0 4.154-4.14V36.226c0-2.286-1.86-4.14-4.154-4.14Z",
  fill: "#E8EAED"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M169.035 133.549H11.967a5.38 5.38 0 0 1-3.793-1.567 5.344 5.344 0 0 1-1.572-3.78V36.237c0-1.418.566-2.778 1.572-3.78a5.378 5.378 0 0 1 3.793-1.568h157.068c1.423.001 2.787.565 3.793 1.567a5.342 5.342 0 0 1 1.572 3.78v91.976a5.34 5.34 0 0 1-1.576 3.773 5.376 5.376 0 0 1-3.789 1.564ZM11.967 33.294c-.78 0-1.528.31-2.08.86a2.93 2.93 0 0 0-.863 2.072v91.976c.001.777.312 1.522.863 2.072.552.55 1.3.859 2.08.86h157.068a2.948 2.948 0 0 0 2.079-.86 2.93 2.93 0 0 0 .863-2.072V36.237a2.93 2.93 0 0 0-.863-2.073 2.949 2.949 0 0 0-2.079-.86l-157.068-.01Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M70.638 122.716a4.883 4.883 0 0 1-3.06-1.118 6.008 6.008 0 0 1-2.11-3.653L55.238 60.386a6.137 6.137 0 0 1 .727-4.14 4.898 4.898 0 0 1 3.267-2.308l20.924-3.705 20.768 15.87 7.871 44.159a5.708 5.708 0 0 1-.346 4.216 4.403 4.403 0 0 1-3.693 2.308l-33.309 5.865a4.676 4.676 0 0 1-.81.065Zm9.073-70.651-20.176 3.574a3.144 3.144 0 0 0-2.077 1.494 4.472 4.472 0 0 0-.506 2.963l10.218 57.559a4.28 4.28 0 0 0 1.496 2.608 3.08 3.08 0 0 0 2.474.69l33.454-5.906h.076a2.692 2.692 0 0 0 2.257-1.408 4.014 4.014 0 0 0 .215-2.925l-.031-.114-7.771-43.552-19.63-14.983Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M21.257 44.471a2.28 2.28 0 0 0 2.285-2.277 2.28 2.28 0 0 0-2.285-2.277 2.28 2.28 0 0 0-2.284 2.277 2.28 2.28 0 0 0 2.284 2.277ZM29.183 44.471a2.28 2.28 0 0 0 2.284-2.277 2.28 2.28 0 0 0-2.284-2.277 2.28 2.28 0 0 0-2.285 2.277 2.28 2.28 0 0 0 2.285 2.277ZM37.663 44.471a2.28 2.28 0 0 0 2.285-2.277 2.28 2.28 0 0 0-2.285-2.277 2.28 2.28 0 0 0-2.284 2.277 2.28 2.28 0 0 0 2.284 2.277Z",
  fill: "#0517F8"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m115.955 118-38.307 6.762a4.834 4.834 0 0 1-3.6-.793 4.807 4.807 0 0 1-1.983-3.099L61.82 63.298a4.787 4.787 0 0 1 .797-3.59 4.811 4.811 0 0 1 3.11-1.975l23.575-4.174 22.707 14.976 7.829 43.879a4.36 4.36 0 0 1-.408 3.623c-1.173 1.863-3.323 1.942-3.475 1.963Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M76.782 125.683a5.707 5.707 0 0 1-3.643-1.331 5.674 5.674 0 0 1-1.943-3.347L60.967 63.45a5.654 5.654 0 0 1 .945-4.227 5.689 5.689 0 0 1 3.663-2.328l23.92-4.233 23.309 15.372 7.882 44.17a5.225 5.225 0 0 1-.519 4.295c-1.326 2.091-3.631 2.319-4.137 2.343l-38.244 6.748c-.331.06-.667.091-1.004.093ZM89.12 54.466l-23.243 4.116a3.958 3.958 0 0 0-2.55 1.62 3.928 3.928 0 0 0-.652 2.944l10.228 57.555a3.925 3.925 0 0 0 1.628 2.543 3.959 3.959 0 0 0 2.955.648l38.369-6.772h.069a3.584 3.584 0 0 0 2.769-1.542 3.435 3.435 0 0 0 .319-2.881l-.032-.131-7.75-43.517-22.11-14.583Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M111.282 68.935c-6.203 1.166-11.076 1.78-13.655 1.915-.886.044-2.423.072-3.676-.811a4.883 4.883 0 0 1-1.533-1.753 4.823 4.823 0 0 1-.416-1.104c-.03-.127-.048-.227-.059-.276v-.03c-.017-.097-.91-3.747-3.084-12.569",
  fill: "#CDD1FE"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M97.01 71.73a6.068 6.068 0 0 1-3.555-.994 5.726 5.726 0 0 1-2.374-3.688c-.038-.17-.6-2.477-3.081-12.544l1.682-.414c3.064 12.44 3.084 12.554 3.115 12.623.032.07.028.152.052.256a4.016 4.016 0 0 0 1.617 2.352c1.09.76 2.523.69 3.136.656 2.945-.152 8.009-.863 13.54-1.901l.322 1.697c-5.607 1.035-10.754 1.773-13.776 1.925a7.44 7.44 0 0 1-.678.031ZM181 152.889H0v-9.387c0-1.418.566-2.777 1.572-3.78a5.376 5.376 0 0 1 3.793-1.567h170.27a5.376 5.376 0 0 1 3.793 1.567 5.342 5.342 0 0 1 1.572 3.78v9.387ZM2.423 150.474h176.154v-6.972a2.928 2.928 0 0 0-.863-2.072 2.948 2.948 0 0 0-2.079-.86H5.365a2.95 2.95 0 0 0-2.08.86 2.93 2.93 0 0 0-.862 2.072v6.972ZM92.12 112.073c-3.354 0-6.633-.991-9.422-2.849a16.918 16.918 0 0 1-6.247-7.586 16.854 16.854 0 0 1-.965-9.768 16.887 16.887 0 0 1 4.642-8.655 16.978 16.978 0 0 1 8.684-4.627c3.29-.652 6.7-.317 9.8.963a16.952 16.952 0 0 1 7.611 6.226 16.87 16.87 0 0 1 2.858 9.391 16.896 16.896 0 0 1-4.972 11.949 17.01 17.01 0 0 1-11.988 4.956Zm0-32.084c-3.011 0-5.956.89-8.46 2.558a15.191 15.191 0 0 0-5.61 6.812 15.133 15.133 0 0 0-.867 8.77 15.166 15.166 0 0 0 4.168 7.773 15.273 15.273 0 0 0 16.597 3.291 15.224 15.224 0 0 0 6.836-5.591 15.15 15.15 0 0 0 2.567-8.434 15.175 15.175 0 0 0-4.465-10.73 15.272 15.272 0 0 0-10.765-4.453v.004Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 92,
  cy: 95,
  r: 15,
  fill: "#E6E8FE"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m98.886 97.612-11.394-7.33c-.621-.4-1.45-.221-1.851.398a1.332 1.332 0 0 0 .397 1.846l11.395 7.33c.621.4 1.45.221 1.851-.398a1.332 1.332 0 0 0-.398-1.846Z",
  fill: "#0517F8"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m95.431 88.335-7.907 11.31a1.197 1.197 0 0 0 .299 1.67 1.206 1.206 0 0 0 1.675-.3l7.907-11.31a1.197 1.197 0 0 0-.299-1.67 1.206 1.206 0 0 0-1.675.3Z",
  fill: "#0517F8"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M119.253 23.47a.95.95 0 0 1-.137 0l-8.458-1.152a.994.994 0 0 1-.869-.73 1.013 1.013 0 0 1 .106-.777.998.998 0 0 1 1.033-.471l8.458 1.152a1.002 1.002 0 0 1-.133 1.998v-.02ZM123.991 14.183a1.001 1.001 0 0 1-.722-.31L117.375 7.7a.993.993 0 0 1-.237-1.12 1 1 0 0 1 1.679-.259l5.897 6.174a1 1 0 0 1-.723 1.688ZM118.905 17.086c-.159 0-.315-.038-.457-.11l-7.588-3.912a1 1 0 0 1 .916-1.775l7.588 3.91a1 1 0 0 1 .515 1.125.997.997 0 0 1-.974.762Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m176.55 59.159 27.568 26.155a3.991 3.991 0 0 1 .991 4.34c-.189.49-.472.94-.835 1.321l-1.378 1.455a4.002 4.002 0 0 1-2.798 1.254 3.997 3.997 0 0 1-2.863-1.097l-27.637-26.093 6.952-7.335Z",
  fill: "#CDD1FE"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M199.989 94.828a5.116 5.116 0 0 1-3.543-1.409l-28.473-26.892 8.531-9.017 28.416 26.972a5.172 5.172 0 0 1 .2 7.295l-1.379 1.456a5.12 5.12 0 0 1-3.606 1.605l-.146-.01Zm-28.72-28.394 26.779 25.307a2.845 2.845 0 0 0 3.995-.107l1.375-1.455a2.831 2.831 0 0 0-.109-3.996L176.587 60.83l-5.318 5.604Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m176.551 59.158-6.929 7.326a3.99 3.99 0 0 1-2.799 1.252 4 4 0 0 1-2.862-1.099 3.992 3.992 0 0 1-.988-4.34c.188-.49.472-.94.835-1.32l6.929-7.326a3.995 3.995 0 0 1 5.661-.153 3.99 3.99 0 0 1 1.252 2.798 3.993 3.993 0 0 1-1.099 2.862Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M166.716 68.891a5.159 5.159 0 0 1-3.746-8.707l6.93-7.326a5.162 5.162 0 1 1 7.502 7.096l-6.93 7.326a5.135 5.135 0 0 1-3.756 1.611Zm6.933-15.317h-.08a2.802 2.802 0 0 0-1.978.88l-6.929 7.325a2.83 2.83 0 1 0 4.109 3.892l6.933-7.325a2.829 2.829 0 0 0-.496-4.31 2.825 2.825 0 0 0-1.559-.462Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M169.57 60.57c11.151-11.15 11.151-29.23 0-40.38-11.15-11.15-29.229-11.15-40.38 0-11.151 11.15-11.151 29.23 0 40.38 11.151 11.151 29.23 11.151 40.38 0Z",
  fill: "#CDD1FE"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M149.374 70.084a29.695 29.695 0 1 1 21.587-9.274 29.608 29.608 0 0 1-21.587 9.274Zm0-57.076a27.359 27.359 0 0 0-26.855 22.224 27.357 27.357 0 0 0 16.766 30.562 27.361 27.361 0 0 0 28.909-45.304 27.307 27.307 0 0 0-18.807-7.486l-.013.004Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M164.366 55.186c8.277-8.277 8.277-21.696 0-29.973-8.277-8.277-21.696-8.277-29.973 0-8.277 8.277-8.277 21.696 0 29.973 8.277 8.277 21.696 8.277 29.973 0Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M149.366 62.562a22.366 22.366 0 0 1-18.496-9.82 22.354 22.354 0 0 1-2.257-20.82 22.364 22.364 0 0 1 15.961-13.556 22.359 22.359 0 1 1 5.421 44.186l-.629.01Zm.03-42.392h-.563a20.029 20.029 0 1 0 14.318 5.474 19.883 19.883 0 0 0-13.755-5.474Z",
  fill: "#172B4D"
}));
export default SvgNoSkill;

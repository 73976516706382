import * as React from "react";
const SvgCopy = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("rect", {
  x: 2,
  y: 4.667,
  width: 9.333,
  height: 9.333,
  rx: 3,
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M4.667 2.586A1.994 1.994 0 0 1 6.08 2H12a2 2 0 0 1 2 2v5.92c0 .53-.21 1.039-.586 1.413",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgCopy;

import * as React from "react";
const SvgFacilitiesSubMenu = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_8930_59989)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M11.262 7.429h6.015V16h-6.015V7.43Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("rect", {
  x: 13.066,
  y: 9.265,
  width: 2.406,
  height: 1,
  rx: 0.5,
  fill: "#748094"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M2.844 4h8.42v12h-8.42V4Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("rect", {
  x: 5.367,
  y: 6.571,
  width: 3.368,
  height: 1,
  rx: 0.5,
  fill: "#748094"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M2 16h16",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_8930_59989"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgFacilitiesSubMenu;

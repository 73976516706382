import DeleteIcon from "@shared/svg/delete-document.svg?react";
import { AlertBox, IMAGE_POSITIONS } from "@shared/ui/AlertBox";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { Textarea } from "@shared/ui/Inputs";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

import EmptySpace from "../_emptySpace";

import { useDeleteOwnOemTicket } from "#/src/services";

const WorkOrderDeleteAlert = ({
  showAlert,
  workOrderId,
  onClose = () => {},
  goToListOnSuccess = false,
}) => {
  const { messages } = useIntl();
  const [reason, setReason] = useState("");
  const history = useHistory();

  const { removeTicket, loading } = useDeleteOwnOemTicket();

  const onDelete = async () => {
    try {
      await removeTicket({
        ticketId: workOrderId,
        reason,
      });
      toast(
        <Toast message={messages?.tickets.deleteAlert.deleteSuccessMessage} />,
        {
          closeButton: false,
        },
      );
      onClose();
      setReason("");
      goToListOnSuccess && history.push("/app/work-orders");
    } catch (e) {
      toast(
        <Toast
          type={TOAST_TYPES.ERROR}
          message={
            messages?.facilities?.facilityDetails.userAccountsTab.deleteError
          }
        />,
        {
          closeButton: false,
        },
      );
    }
  };

  return (
    <>
      <AlertBox
        alertContent={
          <>
            <Headline size={HEADLINE_SIZES.X_SMALL}>
              {messages.tickets.deleteAlert.title}
            </Headline>
            <EmptySpace height="12px" />
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
              {messages.tickets.deleteAlert.message}
            </BodyText>
            <EmptySpace height="16px" />

            <Textarea
              placeholder={messages.tickets.deleteAlert.reasonPlaceholder}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </>
        }
        isOpen={showAlert}
        acceptButtonText={messages?.common.deleteAcceptButtonText}
        acceptButtonVariant={BUTTON_VARIANTS.DANGER}
        cancelButtonText={messages?.common.cancel}
        image={<DeleteIcon width="130" height="130" />}
        imagePosition={IMAGE_POSITIONS.TOP}
        onAccept={onDelete}
        onCancel={() => {
          setReason("");
          onClose();
        }}
        overlay={true}
        disableAcceptButton={loading}
        disableCancelButton={loading}
      />
    </>
  );
};

export default WorkOrderDeleteAlert;

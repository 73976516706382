import AiIcon from "@shared/svg/ai.svg?react";
import CMMSIcon from "@shared/svg/cmms.svg?react";
import CopyIcon from "@shared/svg/copy.svg?react";
import GlobeIcon from "@shared/svg/globe-icon.svg?react";
import LinkIcon from "@shared/svg/link-icon.svg?react";
import MicrosoftDynamicsIcon from "@shared/svg/ms-dynamics.svg?react";
import MyAssetsIcon from "@shared/svg/my-assets.svg?react";
import SalesforceIcon from "@shared/svg/salesforce.svg?react";
import { Avatar } from "@shared/ui/Avatar";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Drawer from "@shared/ui/Drawer";
import {
  AsyncMultiSelectDropdown,
  SingleSelectDropdown,
} from "@shared/ui/Dropdowns";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { Input } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import AppModal from "@shared/ui/Modal";
import Tag, { TAG_COLORS } from "@shared/ui/Tag";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import moment from "moment-timezone";
import { MinusCircle, Plus } from "phosphor-react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useIntl as useInternationalization } from "react-intl";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Waypoint } from "react-waypoint";
import { useRecoilState } from "recoil";

import { sidebarAtom } from "~/atoms/sidebar";
import Summary from "~/components/aiNotetaker/Summary";
import Title from "~/components/aiNotetaker/Title";
import ContentLoading from "~/components/ContentLoading";
import useAuth from "~/components/general/_use-auth";
import MachineListItem from "~/components/machines/MachineListItem";
import UnderlineTabsWrapper from "~/components/UnderlineTabsWrapper";
import { LANGUAGE_CODE_TO_LANGUAGE_NAME_MAP } from "~/constants/aiNote";
import { ITEMS_PER_PAGE } from "~/constants/global";
import { AI_NOTETAKER_EVENTS } from "~/constants/mixpanelEvents";
import {
  usePublishAiNote,
  useTranslateAiNoteSummary,
} from "~/services/ai-note";
import {
  useListAllOwnOemAssets,
  useDeleteMachineHistoryNote,
} from "~/services/asset";
import { AiNoteType, PublishedMachineType } from "~/types/aiNote";
import { Asset } from "~/types/asset";
import { UserType } from "~/types/user";
import { WaypointEventType } from "~/types/waypointEvent";
import { getMachineThumbnail } from "~/utils/_machines";
import { registerMixpanelEvent } from "~/utils/_mixpanel";
import { groupDataByMonth } from "~/utils/group";

const PUBLISH_LOCATIONS = [
  {
    id: "asset-hub",
    icon: MyAssetsIcon,
    isComingSoon: false,
  },
  { id: "cmms", icon: CMMSIcon, isComingSoon: true },
  { id: "salesforce", icon: SalesforceIcon, isComingSoon: true },
  {
    id: "microsoft-dynamics",
    icon: MicrosoftDynamicsIcon,
    isComingSoon: true,
  },
];

const Component = ({ aiNote }: { aiNote: AiNoteType }) => {
  const history = useHistory();
  const { messages } = useInternationalization();
  const { user } = useAuth() as { user: UserType };

  const [sidebarExpanded] = useRecoilState(sidebarAtom);

  const publishLocationOptions = useMemo(
    () =>
      PUBLISH_LOCATIONS.map((location) => ({
        ...location,
        disabled: location.isComingSoon,
        icon: location.icon,
        isComingSoon: location.isComingSoon,
        label: messages.aiNotetaker["publish-locations"][location.id],
        value: location.id,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [state, setState] = useState({
    machines: [],
    publishLocation: publishLocationOptions[0],
    publishModalOpen: false,
    publishModalSearchValue: "",
  });

  const isSelfCreated = aiNote.createdBy?._id === user?._id;

  const {
    assets,
    handleFetchMore: handleAssetsFetchMore,
    assetsListLoading,
    totalCount: assetsTotalCount,
  } = useListAllOwnOemAssets({
    limit: ITEMS_PER_PAGE,
    searchQuery: state.publishModalSearchValue,
    skip: 0,
    skipCondition: !isSelfCreated,
  });

  const { removeHistoryNote, loading: isDeletingMachineNote } =
    useDeleteMachineHistoryNote();
  const { publishNote, isPublishing } = usePublishAiNote();

  const groupedPublishedMachines = useMemo(() => {
    const sortedPublishedMachines = aiNote?.publishedMachines
      ?.filter((publishedMachine) => publishedMachine.machine)
      ?.sort(
        (a, b) =>
          new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime(),
      );

    return groupDataByMonth(sortedPublishedMachines, "publishedAt");
  }, [aiNote?.publishedMachines]);

  const allMachineOptions = assets?.map((machine: Asset) => {
    return {
      ...machine,
      value: machine._id,
      label: machine.name,
      description: machine?.serialNumber,
      thumbnail: getMachineThumbnail({
        oem: machine.oem,
        image: machine.image,
      }),
    };
  });

  const handleMachineHistoryNoteDelete = (
    machineHistoryNoteId: string,
    machineId: string,
  ) => {
    removeHistoryNote(machineHistoryNoteId, machineId, aiNote._id)
      .then(() => {
        toast(
          <Toast message={messages["aiNotetaker"]["delete-note-success"]} />,
          {
            closeButton: false,
          },
        );
      })
      .catch((error) => {
        toast(
          <Toast
            message={
              error?.message || messages["aiNotetaker"]["delete-note-failed"]
            }
            type={TOAST_TYPES.ERROR}
          />,
          {
            closeButton: false,
          },
        );
      });
  };

  const handleMachinesScrollBottom = (event: WaypointEventType) =>
    assets.length >= ITEMS_PER_PAGE &&
    !assetsListLoading &&
    event.previousPosition !== Waypoint.above &&
    assetsTotalCount > assets.length &&
    handleAssetsFetchMore({
      limit: ITEMS_PER_PAGE,
      skip: assets.length,
    });

  const handlePublishSummary = () => {
    registerMixpanelEvent(AI_NOTETAKER_EVENTS.PUBLISHING_AI_NOTE);
    publishNote({
      id: aiNote._id,
      machines: state.machines?.map((machine) => machine._id),
    })
      .then(() => {
        resetPublishModalState();
        toast(<Toast message={messages["aiNotetaker"]["note-published"]} />, {
          closeButton: false,
        });
      })
      .catch((error) => {
        toast(
          <Toast
            message={
              error?.message || messages["aiNotetaker"]["publish-failed"]
            }
            type={TOAST_TYPES.ERROR}
          />,
          {
            closeButton: false,
          },
        );
      });
  };

  const resetPublishModalState = () => {
    setState((prevState) => ({
      ...prevState,
      machines: [],
      publishModalOpen: false,
      publishModalSearchValue: "",
    }));
  };

  const handleSearchQuery = (value: string) => {
    if (state.publishModalSearchValue !== value) {
      setState((prevState) => ({
        ...prevState,
        publishModalSearchValue: value,
      }));
    }
  };

  return (
    <div className="flex-col space-y-lg hidden lg:flex w-4/12">
      <audio src={aiNote.audioUrl} controls className="w-full" />

      {user && isSelfCreated && (
        <Fragment>
          <div className="flex flex-col space-y-md p-lg border border-solid border-primary rounded-lg h-full">
            <Label size={LABEL_SIZES.SMALL}>
              {messages.aiNotetaker["published-to"]}
            </Label>
            <Button
              className="w-fit"
              // @ts-ignore
              leadingIcon={<Plus size={16} weight="bold" />}
              onClick={() =>
                setState((prev) => ({ ...prev, publishModalOpen: true }))
              }
              text={messages.aiNotetaker["drawer-publish"]}
              variant={BUTTON_VARIANTS.LINK}
            />
            <div className="flex flex-col max-h-[310px] overflow-y-auto no-scrollbar">
              {!groupedPublishedMachines.length ? (
                <BodyText
                  className="text-center"
                  color="text-secondary"
                  size={BODY_TEXT_SIZES.X_SMALL}
                >
                  {messages.aiNotetaker["drawer-published-tab-empty"]}
                </BodyText>
              ) : (
                groupedPublishedMachines.map(
                  ({
                    date,
                    data,
                  }: {
                    date: string;
                    data: Array<PublishedMachineType>;
                  }) => (
                    <div key={date} className="flex flex-col w-full space-y-sm">
                      <div className="flex items-center space-x-sm pt-sm pb-xs">
                        <p className="m-0 text-disabled">&#8226;</p>
                        <Label
                          className="text-secondary"
                          size={LABEL_SIZES.SMALL}
                        >
                          {date}
                        </Label>
                      </div>
                      <div className="flex flex-col space-y-md">
                        {data
                          .sort(
                            (a, b) =>
                              moment(b.publishedAt).valueOf() -
                              moment(a.publishedAt).valueOf(),
                          )
                          .map(
                            ({
                              machine,
                              machineHistoryNoteId,
                              publishedAt,
                            }) => (
                              <div
                                className="flex items-center space-x-sm p-md border border-solid border-primary rounded-lg cursor-pointer"
                                key={machineHistoryNoteId}
                                onClick={() =>
                                  history.push(
                                    `/app/assets/machines/${machine._id}`,
                                  )
                                }
                              >
                                <div className="shrink-0">
                                  <MachineListItem.Thumbnail
                                    machine={{
                                      ...machine,
                                      oem: user?.oem,
                                    }}
                                    variant="x-small"
                                  />
                                </div>
                                <div
                                  className={`flex flex-col ${
                                    sidebarExpanded
                                      ? "lg:w-3/5 xl:w-[73%]"
                                      : "lg:w-8/12 xl:w-4/5"
                                  } space-y-2xs`}
                                >
                                  <Headline
                                    className="w-full truncate"
                                    size={HEADLINE_SIZES.X_SMALL}
                                  >
                                    {machine.name}
                                  </Headline>
                                  <BodyText
                                    color="text-secondary w-full truncate"
                                    size={BODY_TEXT_SIZES.X_SMALL}
                                  >
                                    {moment(publishedAt).format(
                                      "MMM D, YYYY • h:mm A",
                                    )}{" "}
                                    &#8226; SN: {machine.serialNumber}
                                  </BodyText>
                                </div>
                                <div className="flex-shrink-0 flex-grow flex items-center">
                                  {/* @ts-ignore */}
                                  <MinusCircle
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      !isDeletingMachineNote &&
                                        handleMachineHistoryNoteDelete(
                                          machineHistoryNoteId,
                                          machine._id,
                                        );
                                    }}
                                    className={`h-2xl w-2xl ml-auto ${
                                      isDeletingMachineNote
                                        ? "text-disabled cursor-not-allowed"
                                        : "text-tertiary cursor-pointer"
                                    }`}
                                  />
                                </div>
                              </div>
                            ),
                          )}
                      </div>
                    </div>
                  ),
                )
              )}
            </div>
          </div>

          <Drawer
            isOpen={state.publishModalOpen}
            onClose={() => resetPublishModalState()}
            onCancel={() => resetPublishModalState()}
            onSubmit={() => handlePublishSummary()}
            title={messages.aiNotetaker["modal-publish-summary"]}
            contentClassName="flex flex-col space-y-2xl"
            submitButtonDisabled={isPublishing || state.machines.length === 0}
            cancelButtonText={messages.common["cancel"]}
            submitButtonText={messages.common["linkInsert"]["confirm"]}
          >
            <div className="flex flex-col space-y-md">
              <SingleSelectDropdown
                keyId="publish-location"
                label={messages.aiNotetaker["select-publish-location"]}
                options={publishLocationOptions}
                onChange={(selected) =>
                  setState((prev) => ({
                    ...prev,
                    publishLocation: selected[0],
                  }))
                }
                searchable
                // @ts-ignore
                searchBy="label"
                values={state.publishLocation ? [state.publishLocation] : []}
                customOptionItem={(Option) => {
                  const Icon = Option.icon;

                  return (
                    <div className="flex items-center space-x-sm">
                      <Icon className="w-2xl h-2xl" />
                      <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                        {Option.label}
                      </BodyText>
                      {Option.isComingSoon && (
                        <Tag
                          color={TAG_COLORS.GREY}
                          isUpperCase={false}
                          isHoverable={false}
                          label={messages.aiNotetaker["coming-soon"]}
                        />
                      )}
                    </div>
                  );
                }}
              />

              <AsyncMultiSelectDropdown
                placeholder={
                  messages["hierarchy"]["assets"]["dropDown"]["placeholder"]
                }
                label={`${messages["hierarchy"]["assets"]["dropDown"]["label"]} *`}
                dropdownTitle={
                  messages["teams"]["createTeamDrawer"]["selectMachine"][
                    "placeholderAsset"
                  ]
                }
                showSearchInput
                searchPlaceholder={messages["common"]["searchText"]}
                options={allMachineOptions}
                allowMulti
                showOnlyPlaceHolder
                setSelectedData={(data) =>
                  setState((prev) => ({
                    ...prev,
                    machines: data ?? [],
                  }))
                }
                setSearchQuery={handleSearchQuery}
                onScrollToBottom={handleMachinesScrollBottom}
                isLoading={assetsListLoading}
                showSelectedOptionsPreviewList
                showRemoveAllButton
              />
            </div>
          </Drawer>
        </Fragment>
      )}
    </div>
  );
};

const AiNote = ({ aiNote }: { aiNote: AiNoteType }) => {
  const { messages } = useInternationalization();
  const { user } = useAuth() as { user: UserType };

  const [state, setState] = useState<{
    shareModalOpen: boolean;
    summary: string;
    targetLanguageCode: {
      label: string;
      value: string;
    };
  }>({
    shareModalOpen: false,
    summary: aiNote.summary,
    targetLanguageCode: null,
  });
  const [copyEditorContent, setCopyEditorContent] = useState<
    (() => void) | null
  >(null);

  const isSelfCreated = aiNote.createdBy?._id === user?._id;

  const { translateSummary, isTranslating } = useTranslateAiNoteSummary();

  useEffect(() => {
    setState((prev) => ({ ...prev, summary: aiNote.summary }));
  }, [aiNote.summary]);

  useEffect(() => {
    state.targetLanguageCode &&
      translateSummary({
        aiNoteId: aiNote._id,
        targetLanguageCode: state.targetLanguageCode.value,
      })
        .then((summary: { data: { translateAiNoteSummary: string } }) => {
          setState((prev) => ({
            ...prev,
            summary: summary.data.translateAiNoteSummary,
          }));
        })
        .catch((error) => {
          setState((prev) => ({
            ...prev,
            targetLanguageCode: null,
          }));
          toast(
            <Toast
              message={
                error?.message || messages["aiNotetaker"]["translation-failed"]
              }
              type={TOAST_TYPES.ERROR}
            />,
            {
              closeButton: false,
            },
          );
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.targetLanguageCode]);

  const languageOptions = useMemo(
    () =>
      Object.keys(LANGUAGE_CODE_TO_LANGUAGE_NAME_MAP).map((languageCode) => ({
        label: LANGUAGE_CODE_TO_LANGUAGE_NAME_MAP[languageCode],
        value: languageCode,
      })),
    [],
  );

  return (
    <div className="p-2xl space-y-lg">
      <div className="flex w-full items-center space-x-sm">
        <div className="flex-shrink-0 flex items-center justify-center rounded-full w-5xl h-5xl bg-violet-lightest">
          <AiIcon className="h-3xl w-3xl text-violet" />
        </div>
        <Title
          isSelfCreated={isSelfCreated}
          noteId={aiNote._id}
          title={aiNote.title}
        />
        <Button
          className="hidden sm:flex"
          leadingIcon={<LinkIcon className="-rotate-45 text-inverse" />}
          onClick={() =>
            setState((prev) => ({ ...prev, shareModalOpen: true }))
          }
          text={messages.aiNotetaker["share"]}
        />
        <Button
          className="flex sm:hidden"
          leadingIcon={<LinkIcon className="-rotate-45 text-inverse" />}
          onClick={() =>
            setState((prev) => ({ ...prev, shareModalOpen: true }))
          }
        />
      </div>
      <div className="flex flex-col sm:flex-row space-y-md sm:space-y-0 sm:space-x-md">
        <div className="flex justify-between sm:justify-normal items-center sm:items-start sm:flex-col sm:space-y-md">
          <BodyText color="text-secondary" size={BODY_TEXT_SIZES.X_SMALL}>
            {messages.aiNotetaker["drawer-created-on"]}:
          </BodyText>
          <BodyText
            className="flex items-center h-2xl"
            size={BODY_TEXT_SIZES.X_SMALL}
          >
            {moment(aiNote.createdAt).format("YYYY/MM/DD")}
          </BodyText>
        </div>
        <div className="flex justify-between sm:justify-normal items-center sm:items-start sm:flex-col sm:space-y-md">
          <BodyText color="text-secondary" size={BODY_TEXT_SIZES.X_SMALL}>
            {messages.aiNotetaker["drawer-created-by"]}:
          </BodyText>
          <div className="flex items-center space-x-md">
            <Avatar name={aiNote.createdBy.name} />
            <BodyText>{aiNote.createdBy.name}</BodyText>
          </div>
        </div>
      </div>
      <audio src={aiNote.audioUrl} controls className="flex w-full lg:hidden" />
      <UnderlineTabsWrapper
        className="max-lg:!px-0"
        tabsClassName="flex flex-col pb-0"
        tabs={[
          {
            id: "summary",
            className: "max-lg:!px-0",
            label: messages.aiNotetaker["drawer-summary"],
            content: (
              <div className="flex w-full space-x-xl">
                <div className="flex flex-col space-y-md border border-solid border-primary rounded-lg p-lg w-full lg:w-8/12">
                  <div className="flex flex-col sm:flex-row sm:items-center space-y-sm sm:space-y-0 sm:space-x-sm">
                    <Button
                      className="w-fit"
                      disabled={state.summary.length === 0 || isTranslating}
                      leadingIcon={
                        <CopyIcon
                          className={
                            state.summary.length === 0 || isTranslating
                              ? "text-inverse"
                              : "text-brand"
                          }
                        />
                      }
                      onClick={() => {
                        copyEditorContent?.();
                        toast(
                          <Toast
                            message={messages.aiNotetaker["summary-copied"]}
                            type={TOAST_TYPES.SUCCESS}
                          />,
                          {
                            closeButton: false,
                          },
                        );
                      }}
                      text={messages.aiNotetaker["copy-summary"]}
                      variant={BUTTON_VARIANTS.SECONDARY}
                    />
                    {user && (
                      <div className="w-44xl">
                        <SingleSelectDropdown
                          keyId="deepl-language"
                          placeholder={messages.aiNotetaker["translate-into"]}
                          options={languageOptions}
                          searchable
                          disabled={isTranslating || !user}
                          // @ts-ignore
                          searchBy="label"
                          values={
                            state.targetLanguageCode
                              ? [state.targetLanguageCode]
                              : []
                          }
                          onChange={(val: { label: string; value: string }[]) =>
                            setState((prev) => ({
                              ...prev,
                              targetLanguageCode: val[0],
                            }))
                          }
                        />
                      </div>
                    )}
                  </div>
                  {isTranslating ? (
                    <div className="flex items-center justify-center w-full h-full">
                      <ContentLoading />
                    </div>
                  ) : (
                    <Summary
                      key={state.summary.length}
                      isSelfCreated={isSelfCreated}
                      noteId={aiNote._id}
                      setCopyEditorContent={setCopyEditorContent}
                      summary={state.summary}
                    />
                  )}
                </div>
                <Component aiNote={aiNote} />
              </div>
            ),
          },
          {
            id: "transcript",
            className: "max-lg:!px-0",
            label: messages.aiNotetaker["drawer-transcript"],
            content: (
              <div className="flex w-full space-x-xl">
                <div className="flex flex-col space-y-md border border-solid border-primary rounded-lg p-lg w-full lg:w-8/12">
                  <Button
                    className="w-fit"
                    leadingIcon={<CopyIcon className="text-brand" />}
                    onClick={() => {
                      navigator.clipboard.writeText(aiNote.transcript);
                      toast(
                        <Toast
                          message={messages.aiNotetaker["transcript-copied"]}
                          type={TOAST_TYPES.SUCCESS}
                        />,
                        {
                          closeButton: false,
                        },
                      );
                    }}
                    text={messages.aiNotetaker["copy-transcript"]}
                    variant={BUTTON_VARIANTS.SECONDARY}
                  />
                  <BodyText
                    className="max-h-[380px] overflow-y-auto"
                    size={BODY_TEXT_SIZES.X_SMALL}
                  >
                    {aiNote.transcript}
                  </BodyText>
                </div>
                <Component aiNote={aiNote} />
              </div>
            ),
          },
        ]}
      />

      <AppModal
        content={
          <div className="flex flex-col space-y-2xs">
            <div className="flex items-center w-full space-x-md">
              <Input
                wrapperClasses="flex-grow"
                value={`${window.location.origin}/ai/note/${aiNote._id}`}
                disabled
              />
              <Button
                leadingIcon={
                  <CopyIcon className="flex-shrink-0 text-inverse" />
                }
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/ai/note/${aiNote._id}`,
                  );
                  toast(
                    <Toast
                      message={messages.aiNotetaker["link-copied"]}
                      type={TOAST_TYPES.SUCCESS}
                    />,
                    {
                      closeButton: false,
                    },
                  );
                }}
                text={messages.aiNotetaker["copy-link"]}
              />
            </div>
            <div className="flex items-center space-x-2xs">
              <GlobeIcon className="w-lg h-lg text-secondary" />
              <BodyText color="text-secondary" size={BODY_TEXT_SIZES.X_SMALL}>
                {messages.aiNotetaker["copy-link-description"]}
              </BodyText>
            </div>
          </div>
        }
        footer={<></>}
        handleClose={() =>
          setState((prev) => ({ ...prev, shareModalOpen: false }))
        }
        isOpen={state.shareModalOpen}
        maxWidth="!max-w-xl"
        title={messages.aiNotetaker["share"]}
      />
    </div>
  );
};

export default AiNote;
